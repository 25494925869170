import { AvistaAppBar } from "@avista-components/avista-app-bar";
import { AvistaAvatar } from "@avista-components/avista-avatar";
import { makeStyles, Paper } from "@material-ui/core";
import React from "react";
import { loginHostUrl } from "./consts";

const useStyles = makeStyles(() => ({
  paper: {
    minHeight: "100vh",
    height: "100%",
    width: "100%",
    backgroundColor: "#eceff1",
  },
}));

export const Layout: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <AvistaAppBar
        children={{
          mainChildrenItems: [],
          rightChildren: (
            <>
              <AvistaAvatar
                loginServerUrl={loginHostUrl || "http://login.avaval.com"}
                myProfileUrl=""
              />
            </>
          ),
        }}
      />
      {children}
    </Paper>
  );
};
