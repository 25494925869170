export type Base = {
  id: string;
  label: string;
};
export type landUsageThirdLevel = Base;

export type landUsageSecondLevel = Base & {
  children?: landUsageThirdLevel[];
};

export type landUsageFirstLevel = Base & {
  children: landUsageSecondLevel[];
};

export const uses: landUsageFirstLevel[] = [
  {
    id: "new",
    label: "新版用途",
    children: [
      {
        id: "05",
        label: "商服用地",
        children: [
          { id: "0501", label: "零售商业用地" },
          { id: "0502", label: "批发市场用地" },
          { id: "0503", label: "餐饮用地" },
          { id: "0504", label: "旅馆用地" },
          { id: "0505", label: "商务金融用地" },
          { id: "0506", label: "娱乐用地" },
          { id: "0507", label: "其他商服用地" },
        ],
      },
      {
        id: "06",
        label: "工矿仓储用地",
        children: [
          { id: "0601", label: "工业用地" },
          { id: "0602", label: "采矿用地" },
          { id: "0603", label: "盐田" },
          { id: "0604", label: "仓储用地" },
        ],
      },
      {
        id: "07",
        label: "住宅用地",
        children: [
          { id: "0702", label: "农村宅基地" },
          { id: "0703", label: "城镇住宅-普通商品住房用地" },
          { id: "0704", label: "城镇住宅-经济适用住房用地" },
          { id: "0705", label: "城镇住宅-公共租赁住房用地" },
          { id: "0708", label: "城镇住宅-租赁型商品住房用地" },
          { id: "0709", label: "城镇住宅-共有产权住房用地" },
        ],
      },
      {
        id: "08",
        label: "公共管理与公共服务用地",
        children: [
          { id: "0801", label: "机关团体用地" },
          { id: "0802", label: "新闻出版用地" },
          { id: "0803", label: "教育用地" },
          { id: "0804", label: "科研用地" },
          { id: "0805", label: "医疗卫生用地" },
          { id: "0806", label: "社会福利用地" },
          { id: "0807", label: "文化设施用地" },
          { id: "0808", label: "体育用地" },
          { id: "0809", label: "公用设施用地" },
          { id: "0810", label: "公园与绿地" },
        ],
      },
      {
        id: "09",
        label: "特殊用地",
        children: [
          { id: "0901", label: "军事设施用地" },
          { id: "0902", label: "使领馆用地" },
          { id: "0903", label: "监教场所用地" },
          { id: "0904", label: "宗教用地" },
          { id: "0905", label: "殡葬用地" },
          { id: "0906", label: "风景名胜设施用地" },
        ],
      },
      {
        id: "10",
        label: "交通运输用地",
        children: [
          { id: "1001", label: "铁路用地" },
          { id: "1002", label: "轨道交通用地" },
          { id: "1003", label: "公路用地" },
          { id: "1004", label: "城镇村道路用地" },
          { id: "1005", label: "交通服务场站用地" },
          { id: "1006", label: "农村道路" },
          { id: "1007", label: "机场用地" },
          { id: "1008", label: "港口码头用地" },
          { id: "1009", label: "管道运输用地" },
        ],
      },
      {
        id: "11",
        label: "水域及水利设施用地",
        children: [
          { id: "1101", label: "河流水面" },
          { id: "1102", label: "湖泊水面" },
          { id: "1103", label: "水库水面" },
          { id: "1104", label: "坑塘水面" },
          { id: "1105", label: "沿海滩涂" },
          { id: "1106", label: "内陆滩涂" },
          { id: "1107", label: "沟渠" },
          { id: "1108", label: "沼泽地" },
          { id: "1109", label: "水工建筑用地" },
          { id: "1110", label: "冰川及永久积雪" },
        ],
      },
      {
        id: "12",
        label: "其他土地",
        children: [
          { id: "1201", label: "空闲地" },
          { id: "1202", label: "设施农用地" },
          { id: "1203", label: "田坎" },
          { id: "1204", label: "盐碱地" },
          { id: "1205", label: "沙地" },
          { id: "1206", label: "裸土地" },
          { id: "1207", label: "裸岩石砾地" },
        ],
      },
      { id: "98", label: "商住综合" },
      {
        id: "shangy",
        label: "商业",
        children: [
          { id: "shangy000", label: "商业" },
          { id: "shangy000", label: "商业" },
        ],
      },
    ],
  },
  {
    id: "old",
    label: "旧版用途",
    children: [
      {
        id: "Y05",
        label: "商服用地",
        children: [
          { id: "051", label: "批发零售用地" },
          { id: "052", label: "住宿餐饮用地" },
          { id: "053", label: "商务金融用地" },
          { id: "054", label: "其他商服用地" },
        ],
      },
      {
        id: "Y06",
        label: "工矿仓储用地",
        children: [
          { id: "061", label: "工业用地" },
          { id: "062", label: "采矿用地" },
          { id: "063", label: "仓储用地" },
        ],
      },
      {
        id: "Y07",
        label: "住宅用地",
        children: [
          { id: "071", label: "高档住宅用地" },
          { id: "072", label: "中低价位、中小套型普通商品住房用地" },
          { id: "073", label: "其他普通商品住房用地" },
          { id: "074", label: "经济适用住房用地" },
          { id: "075", label: "廉租住房用地" },
          { id: "076", label: "其他住房用地" },
          { id: "077", label: "公共租赁住房用地" },
        ],
      },
      {
        id: "Y08",
        label: "公共管理与公共服务用地",
        children: [
          { id: "081", label: "机关团体用地" },
          { id: "082", label: "新闻出版用地" },
          { id: "083", label: "科教用地" },
          { id: "084", label: "医卫慈善用地" },
          { id: "085", label: "文体娱乐用地" },
          { id: "086", label: "公共设施用地" },
          { id: "087", label: "公园与绿地" },
          { id: "088", label: "风景名胜设施用地" },
        ],
      },
      {
        id: "Y09",
        label: "特殊用地",
        children: [
          { id: "091", label: "军事设施用地" },
          { id: "092", label: "使领馆用地" },
          { id: "093", label: "监教场所用地" },
          { id: "094", label: "宗教用地" },
          { id: "095", label: "殡葬用地" },
        ],
      },
      {
        id: "Y10",
        label: "交通运输用地",
        children: [
          { id: "101", label: "铁路用地" },
          { id: "102", label: "公路用地" },
          { id: "103", label: "街巷用地" },
          { id: "104", label: "农村道路" },
          { id: "105", label: "机场用地" },
          { id: "106", label: "港口码头用地" },
          { id: "107", label: "管道运输用地" },
        ],
      },
      {
        id: "Y11",
        label: "水域及水利设施用地",
        children: [
          { id: "113", label: "水库水面" },
          { id: "114", label: "坑塘水面" },
          { id: "115", label: "沿海滩涂" },
          { id: "116", label: "内陆滩涂" },
          { id: "117", label: "沟渠" },
          { id: "118", label: "水工建筑用地" },
          { id: "119", label: "冰川及永久积雪" },
        ],
      },
      {
        id: "Y12",
        label: "其他土地",
        children: [
          { id: "121", label: "空闲地" },
          { id: "122", label: "设施农用地" },
          { id: "123", label: "田坎" },
          { id: "124", label: "盐碱地" },
          { id: "125", label: "沼泽地" },
          { id: "126", label: "沙地" },
          { id: "127", label: "裸地" },
        ],
      },
    ],
  },
];
