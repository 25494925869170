export type Base = {
  enumValue: string;
  enumName: string;
  [key: string]: any;
};

export type Area = Base;

export type District = Base & {
  children: Area[];
};

export type Province = Base & {
  children: District[];
};

export const districts: Province[] = [
  {
    enumValue: "11",
    enumName: "北京市",
    enumDesc: "zdcs",
    children: [
      {
        fldEnumId: 13386,
        enumGroupGuid: "1",
        parentEnumValue: "11",
        enumValue: "1101",
        enumName: "北京市市辖区",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        enumNameother: "市辖区",
        children: [
          {
            fldEnumId: 16133,
            enumGroupGuid: "1",
            parentEnumValue: "1101",
            enumValue: "110100",
            enumName: "北京市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130124,
            enumGroupGuid: "1",
            parentEnumValue: "1101",
            enumValue: "110228",
            enumName: "密云区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130125,
            enumGroupGuid: "1",
            parentEnumValue: "1101",
            enumValue: "110229",
            enumName: "延庆区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 13388,
            enumGroupGuid: "1",
            parentEnumValue: "1101",
            enumValue: "110101",
            enumName: "东城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "东城区",
          },
          {
            fldEnumId: 13392,
            enumGroupGuid: "1",
            parentEnumValue: "1101",
            enumValue: "110102",
            enumName: "西城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "西城区",
          },
          {
            fldEnumId: 13396,
            enumGroupGuid: "1",
            parentEnumValue: "1101",
            enumValue: "110103",
            enumName: "崇文区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 2,
            enumNameother: "崇文区",
          },
          {
            fldEnumId: 13401,
            enumGroupGuid: "1",
            parentEnumValue: "1101",
            enumValue: "110104",
            enumName: "宣武区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 3,
            enumNameother: "宣武区",
          },
          {
            fldEnumId: 13405,
            enumGroupGuid: "1",
            parentEnumValue: "1101",
            enumValue: "110105",
            enumName: "朝阳区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 4,
            enumNameother: "朝阳区",
          },
          {
            fldEnumId: 13408,
            enumGroupGuid: "1",
            parentEnumValue: "1101",
            enumValue: "110106",
            enumName: "丰台区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 5,
            enumNameother: "丰台区",
          },
          {
            fldEnumId: 13413,
            enumGroupGuid: "1",
            parentEnumValue: "1101",
            enumValue: "110107",
            enumName: "石景山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 6,
            enumNameother: "石景山区",
          },
          {
            fldEnumId: 13417,
            enumGroupGuid: "1",
            parentEnumValue: "1101",
            enumValue: "110108",
            enumName: "海淀区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 7,
            enumNameother: "海淀区",
          },
          {
            fldEnumId: 13422,
            enumGroupGuid: "1",
            parentEnumValue: "1101",
            enumValue: "110109",
            enumName: "门头沟区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 8,
            enumNameother: "门头沟区",
          },
          {
            fldEnumId: 13426,
            enumGroupGuid: "1",
            parentEnumValue: "1101",
            enumValue: "110111",
            enumName: "房山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 9,
            enumNameother: "房山区",
          },
          {
            fldEnumId: 13429,
            enumGroupGuid: "1",
            parentEnumValue: "1101",
            enumValue: "110112",
            enumName: "通州区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 10,
            enumNameother: "通州区",
          },
          {
            fldEnumId: 13434,
            enumGroupGuid: "1",
            parentEnumValue: "1101",
            enumValue: "110113",
            enumName: "顺义区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 11,
            enumNameother: "顺义区",
          },
          {
            fldEnumId: 13437,
            enumGroupGuid: "1",
            parentEnumValue: "1101",
            enumValue: "110114",
            enumName: "昌平区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 12,
            enumNameother: "昌平区",
          },
          {
            fldEnumId: 13441,
            enumGroupGuid: "1",
            parentEnumValue: "1101",
            enumValue: "110115",
            enumName: "大兴区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 13,
            enumNameother: "大兴区",
          },
          {
            fldEnumId: 13445,
            enumGroupGuid: "1",
            parentEnumValue: "1101",
            enumValue: "110116",
            enumName: "怀柔区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 14,
            enumNameother: "怀柔区",
          },
          {
            fldEnumId: 13449,
            enumGroupGuid: "1",
            parentEnumValue: "1101",
            enumValue: "110117",
            enumName: "平谷区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 15,
            enumNameother: "平谷区",
          },
        ],
      },
    ],
  },
  {
    enumValue: "66",
    enumName: "新疆兵团",
    children: [
      {
        fldEnumId: 15678,
        enumGroupGuid: "1",
        parentEnumValue: "66",
        enumValue: "6601",
        enumName: "农一师",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 218,
        children: [
          {
            fldEnumId: 16180,
            enumGroupGuid: "1",
            parentEnumValue: "6601",
            enumValue: "660100",
            enumName: "农一师本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130285,
            enumGroupGuid: "1",
            parentEnumValue: "6601",
            enumValue: "660124",
            enumName: "阿拉尔市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 15679,
            enumGroupGuid: "1",
            parentEnumValue: "6601",
            enumValue: "660101",
            enumName: "1团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 218,
          },
          {
            fldEnumId: 15681,
            enumGroupGuid: "1",
            parentEnumValue: "6601",
            enumValue: "660102",
            enumName: "2团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 219,
          },
          {
            fldEnumId: 15683,
            enumGroupGuid: "1",
            parentEnumValue: "6601",
            enumValue: "660103",
            enumName: "3团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 220,
          },
          {
            fldEnumId: 15685,
            enumGroupGuid: "1",
            parentEnumValue: "6601",
            enumValue: "660104",
            enumName: "4团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 221,
          },
          {
            fldEnumId: 15687,
            enumGroupGuid: "1",
            parentEnumValue: "6601",
            enumValue: "660105",
            enumName: "5团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 222,
          },
          {
            fldEnumId: 15689,
            enumGroupGuid: "1",
            parentEnumValue: "6601",
            enumValue: "660106",
            enumName: "6团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 223,
          },
          {
            fldEnumId: 15691,
            enumGroupGuid: "1",
            parentEnumValue: "6601",
            enumValue: "660107",
            enumName: "7团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 224,
          },
          {
            fldEnumId: 15693,
            enumGroupGuid: "1",
            parentEnumValue: "6601",
            enumValue: "660108",
            enumName: "8团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 225,
          },
          {
            fldEnumId: 15695,
            enumGroupGuid: "1",
            parentEnumValue: "6601",
            enumValue: "660109",
            enumName: "9团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 226,
          },
          {
            fldEnumId: 15697,
            enumGroupGuid: "1",
            parentEnumValue: "6601",
            enumValue: "660110",
            enumName: "10团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 227,
          },
          {
            fldEnumId: 15699,
            enumGroupGuid: "1",
            parentEnumValue: "6601",
            enumValue: "660111",
            enumName: "11团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 228,
          },
          {
            fldEnumId: 15701,
            enumGroupGuid: "1",
            parentEnumValue: "6601",
            enumValue: "660112",
            enumName: "12团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 229,
          },
          {
            fldEnumId: 15703,
            enumGroupGuid: "1",
            parentEnumValue: "6601",
            enumValue: "660113",
            enumName: "13团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 230,
          },
          {
            fldEnumId: 15705,
            enumGroupGuid: "1",
            parentEnumValue: "6601",
            enumValue: "660114",
            enumName: "14团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 231,
          },
          {
            fldEnumId: 15707,
            enumGroupGuid: "1",
            parentEnumValue: "6601",
            enumValue: "660115",
            enumName: "15团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 232,
          },
          {
            fldEnumId: 15709,
            enumGroupGuid: "1",
            parentEnumValue: "6601",
            enumValue: "660116",
            enumName: "16团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 233,
          },
          {
            fldEnumId: 15711,
            enumGroupGuid: "1",
            parentEnumValue: "6601",
            enumValue: "660117",
            enumName: "建安总公司",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 234,
          },
          {
            fldEnumId: 15713,
            enumGroupGuid: "1",
            parentEnumValue: "6601",
            enumValue: "660118",
            enumName: "水电工程处",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 235,
          },
          {
            fldEnumId: 15715,
            enumGroupGuid: "1",
            parentEnumValue: "6601",
            enumValue: "660119",
            enumName: "塔水处",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 236,
          },
          {
            fldEnumId: 15717,
            enumGroupGuid: "1",
            parentEnumValue: "6601",
            enumValue: "660120",
            enumName: "沙水处",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 237,
          },
          {
            fldEnumId: 15719,
            enumGroupGuid: "1",
            parentEnumValue: "6601",
            enumValue: "660121",
            enumName: "阿管局",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 238,
          },
          {
            fldEnumId: 15721,
            enumGroupGuid: "1",
            parentEnumValue: "6601",
            enumValue: "660122",
            enumName: "沙管局",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 239,
          },
          {
            fldEnumId: 15723,
            enumGroupGuid: "1",
            parentEnumValue: "6601",
            enumValue: "660123",
            enumName: "西工区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 240,
          },
        ],
      },
      {
        fldEnumId: 15725,
        enumGroupGuid: "1",
        parentEnumValue: "66",
        enumValue: "6602",
        enumName: "农二师",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 219,
        children: [
          {
            fldEnumId: 16182,
            enumGroupGuid: "1",
            parentEnumValue: "6602",
            enumValue: "660200",
            enumName: "农二师本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130369,
            enumGroupGuid: "1",
            parentEnumValue: "6602",
            enumValue: "660225",
            enumName: "铁门关市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 15726,
            enumGroupGuid: "1",
            parentEnumValue: "6602",
            enumValue: "660201",
            enumName: "21团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 241,
          },
          {
            fldEnumId: 15728,
            enumGroupGuid: "1",
            parentEnumValue: "6602",
            enumValue: "660202",
            enumName: "22团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 242,
          },
          {
            fldEnumId: 15730,
            enumGroupGuid: "1",
            parentEnumValue: "6602",
            enumValue: "660203",
            enumName: "23团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 243,
          },
          {
            fldEnumId: 15732,
            enumGroupGuid: "1",
            parentEnumValue: "6602",
            enumValue: "660204",
            enumName: "24团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 244,
          },
          {
            fldEnumId: 15734,
            enumGroupGuid: "1",
            parentEnumValue: "6602",
            enumValue: "660205",
            enumName: "25团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 245,
          },
          {
            fldEnumId: 15736,
            enumGroupGuid: "1",
            parentEnumValue: "6602",
            enumValue: "660206",
            enumName: "26团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 246,
          },
          {
            fldEnumId: 15738,
            enumGroupGuid: "1",
            parentEnumValue: "6602",
            enumValue: "660207",
            enumName: "27团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 247,
          },
          {
            fldEnumId: 15740,
            enumGroupGuid: "1",
            parentEnumValue: "6602",
            enumValue: "660208",
            enumName: "28团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 248,
          },
          {
            fldEnumId: 15742,
            enumGroupGuid: "1",
            parentEnumValue: "6602",
            enumValue: "660209",
            enumName: "29团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 249,
          },
          {
            fldEnumId: 15744,
            enumGroupGuid: "1",
            parentEnumValue: "6602",
            enumValue: "660210",
            enumName: "30团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 250,
          },
          {
            fldEnumId: 15746,
            enumGroupGuid: "1",
            parentEnumValue: "6602",
            enumValue: "660211",
            enumName: "31团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 251,
          },
          {
            fldEnumId: 15748,
            enumGroupGuid: "1",
            parentEnumValue: "6602",
            enumValue: "660212",
            enumName: "32团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 252,
          },
          {
            fldEnumId: 15750,
            enumGroupGuid: "1",
            parentEnumValue: "6602",
            enumValue: "660213",
            enumName: "33团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 253,
          },
          {
            fldEnumId: 15752,
            enumGroupGuid: "1",
            parentEnumValue: "6602",
            enumValue: "660214",
            enumName: "34团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 254,
          },
          {
            fldEnumId: 15754,
            enumGroupGuid: "1",
            parentEnumValue: "6602",
            enumValue: "660215",
            enumName: "35团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 255,
          },
          {
            fldEnumId: 15756,
            enumGroupGuid: "1",
            parentEnumValue: "6602",
            enumValue: "660216",
            enumName: "36团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 256,
          },
          {
            fldEnumId: 15758,
            enumGroupGuid: "1",
            parentEnumValue: "6602",
            enumValue: "660217",
            enumName: "223团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 257,
          },
          {
            fldEnumId: 15760,
            enumGroupGuid: "1",
            parentEnumValue: "6602",
            enumValue: "660218",
            enumName: "师直",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 258,
          },
          {
            fldEnumId: 15762,
            enumGroupGuid: "1",
            parentEnumValue: "6602",
            enumValue: "660219",
            enumName: "塔里木水管处",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 259,
          },
          {
            fldEnumId: 15764,
            enumGroupGuid: "1",
            parentEnumValue: "6602",
            enumValue: "660220",
            enumName: "且末支队",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 260,
          },
          {
            fldEnumId: 15766,
            enumGroupGuid: "1",
            parentEnumValue: "6602",
            enumValue: "660221",
            enumName: "18团水管处",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 261,
          },
          {
            fldEnumId: 15768,
            enumGroupGuid: "1",
            parentEnumValue: "6602",
            enumValue: "660222",
            enumName: "38团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 262,
          },
          {
            fldEnumId: 15770,
            enumGroupGuid: "1",
            parentEnumValue: "6602",
            enumValue: "660223",
            enumName: "工二团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 263,
          },
          {
            fldEnumId: 15772,
            enumGroupGuid: "1",
            parentEnumValue: "6602",
            enumValue: "660224",
            enumName: "工四团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 264,
          },
        ],
      },
      {
        fldEnumId: 15774,
        enumGroupGuid: "1",
        parentEnumValue: "66",
        enumValue: "6603",
        enumName: "农三师",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 220,
        children: [
          {
            fldEnumId: 16184,
            enumGroupGuid: "1",
            parentEnumValue: "6603",
            enumValue: "660300",
            enumName: "农三师本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130371,
            enumGroupGuid: "1",
            parentEnumValue: "6603",
            enumValue: "660325",
            enumName: "图木舒克市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130295,
            enumGroupGuid: "1",
            parentEnumValue: "6603",
            enumValue: "660326",
            enumName: "54团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 15775,
            enumGroupGuid: "1",
            parentEnumValue: "6603",
            enumValue: "660301",
            enumName: "41团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 265,
          },
          {
            fldEnumId: 15777,
            enumGroupGuid: "1",
            parentEnumValue: "6603",
            enumValue: "660302",
            enumName: "42团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 266,
          },
          {
            fldEnumId: 15779,
            enumGroupGuid: "1",
            parentEnumValue: "6603",
            enumValue: "660303",
            enumName: "43团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 267,
          },
          {
            fldEnumId: 15781,
            enumGroupGuid: "1",
            parentEnumValue: "6603",
            enumValue: "660304",
            enumName: "44团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 268,
          },
          {
            fldEnumId: 15783,
            enumGroupGuid: "1",
            parentEnumValue: "6603",
            enumValue: "660305",
            enumName: "45团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 269,
          },
          {
            fldEnumId: 15785,
            enumGroupGuid: "1",
            parentEnumValue: "6603",
            enumValue: "660306",
            enumName: "46团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 270,
          },
          {
            fldEnumId: 15787,
            enumGroupGuid: "1",
            parentEnumValue: "6603",
            enumValue: "660308",
            enumName: "48团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 271,
          },
          {
            fldEnumId: 15789,
            enumGroupGuid: "1",
            parentEnumValue: "6603",
            enumValue: "660309",
            enumName: "49团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 272,
          },
          {
            fldEnumId: 15791,
            enumGroupGuid: "1",
            parentEnumValue: "6603",
            enumValue: "660310",
            enumName: "50团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 273,
          },
          {
            fldEnumId: 15793,
            enumGroupGuid: "1",
            parentEnumValue: "6603",
            enumValue: "660311",
            enumName: "51团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 274,
          },
          {
            fldEnumId: 15795,
            enumGroupGuid: "1",
            parentEnumValue: "6603",
            enumValue: "660312",
            enumName: "52团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 275,
          },
          {
            fldEnumId: 15797,
            enumGroupGuid: "1",
            parentEnumValue: "6603",
            enumValue: "660313",
            enumName: "53团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 276,
          },
          {
            fldEnumId: 15799,
            enumGroupGuid: "1",
            parentEnumValue: "6603",
            enumValue: "660314",
            enumName: "工程团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 277,
          },
          {
            fldEnumId: 15801,
            enumGroupGuid: "1",
            parentEnumValue: "6603",
            enumValue: "660315",
            enumName: "小海子水管处",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 278,
          },
          {
            fldEnumId: 15803,
            enumGroupGuid: "1",
            parentEnumValue: "6603",
            enumValue: "660316",
            enumName: "东风农场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 279,
          },
          {
            fldEnumId: 15805,
            enumGroupGuid: "1",
            parentEnumValue: "6603",
            enumValue: "660317",
            enumName: "师直",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 280,
          },
          {
            fldEnumId: 15807,
            enumGroupGuid: "1",
            parentEnumValue: "6603",
            enumValue: "660318",
            enumName: "红旗农场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 281,
          },
          {
            fldEnumId: 15809,
            enumGroupGuid: "1",
            parentEnumValue: "6603",
            enumValue: "660319",
            enumName: "前进水管处",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 282,
          },
          {
            fldEnumId: 15811,
            enumGroupGuid: "1",
            parentEnumValue: "6603",
            enumValue: "660320",
            enumName: "莎车农场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 283,
          },
          {
            fldEnumId: 15813,
            enumGroupGuid: "1",
            parentEnumValue: "6603",
            enumValue: "660321",
            enumName: "托运牧场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 284,
          },
          {
            fldEnumId: 15815,
            enumGroupGuid: "1",
            parentEnumValue: "6603",
            enumValue: "660322",
            enumName: "叶城牧场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 285,
          },
          {
            fldEnumId: 15817,
            enumGroupGuid: "1",
            parentEnumValue: "6603",
            enumValue: "660323",
            enumName: "伽师总场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 286,
          },
          {
            fldEnumId: 15819,
            enumGroupGuid: "1",
            parentEnumValue: "6603",
            enumValue: "660324",
            enumName: "永安坝",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 287,
          },
        ],
      },
      {
        fldEnumId: 15821,
        enumGroupGuid: "1",
        parentEnumValue: "66",
        enumValue: "6604",
        enumName: "农四师",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 221,
        children: [
          {
            fldEnumId: 16300,
            enumGroupGuid: "1",
            parentEnumValue: "6604",
            enumValue: "660400",
            enumName: "农四师本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130287,
            enumGroupGuid: "1",
            parentEnumValue: "6604",
            enumValue: "660424",
            enumName: "可克达拉市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 15822,
            enumGroupGuid: "1",
            parentEnumValue: "6604",
            enumValue: "660401",
            enumName: "61团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 288,
          },
          {
            fldEnumId: 15824,
            enumGroupGuid: "1",
            parentEnumValue: "6604",
            enumValue: "660402",
            enumName: "62团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 289,
          },
          {
            fldEnumId: 15826,
            enumGroupGuid: "1",
            parentEnumValue: "6604",
            enumValue: "660403",
            enumName: "63团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 290,
          },
          {
            fldEnumId: 15828,
            enumGroupGuid: "1",
            parentEnumValue: "6604",
            enumValue: "660404",
            enumName: "64团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 291,
          },
          {
            fldEnumId: 15830,
            enumGroupGuid: "1",
            parentEnumValue: "6604",
            enumValue: "660405",
            enumName: "65团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 292,
          },
          {
            fldEnumId: 15832,
            enumGroupGuid: "1",
            parentEnumValue: "6604",
            enumValue: "660406",
            enumName: "66团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 293,
          },
          {
            fldEnumId: 15834,
            enumGroupGuid: "1",
            parentEnumValue: "6604",
            enumValue: "660407",
            enumName: "67团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 294,
          },
          {
            fldEnumId: 15836,
            enumGroupGuid: "1",
            parentEnumValue: "6604",
            enumValue: "660408",
            enumName: "68团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 295,
          },
          {
            fldEnumId: 15838,
            enumGroupGuid: "1",
            parentEnumValue: "6604",
            enumValue: "660409",
            enumName: "69团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 296,
          },
          {
            fldEnumId: 15840,
            enumGroupGuid: "1",
            parentEnumValue: "6604",
            enumValue: "660410",
            enumName: "70团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 297,
          },
          {
            fldEnumId: 15842,
            enumGroupGuid: "1",
            parentEnumValue: "6604",
            enumValue: "660411",
            enumName: "71团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 298,
          },
          {
            fldEnumId: 15844,
            enumGroupGuid: "1",
            parentEnumValue: "6604",
            enumValue: "660412",
            enumName: "72团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 299,
          },
          {
            fldEnumId: 15846,
            enumGroupGuid: "1",
            parentEnumValue: "6604",
            enumValue: "660413",
            enumName: "73团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 300,
          },
          {
            fldEnumId: 15848,
            enumGroupGuid: "1",
            parentEnumValue: "6604",
            enumValue: "660414",
            enumName: "74团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 301,
          },
          {
            fldEnumId: 15850,
            enumGroupGuid: "1",
            parentEnumValue: "6604",
            enumValue: "660415",
            enumName: "75团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 302,
          },
          {
            fldEnumId: 15852,
            enumGroupGuid: "1",
            parentEnumValue: "6604",
            enumValue: "660416",
            enumName: "76团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 303,
          },
          {
            fldEnumId: 15854,
            enumGroupGuid: "1",
            parentEnumValue: "6604",
            enumValue: "660417",
            enumName: "77团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 304,
          },
          {
            fldEnumId: 15856,
            enumGroupGuid: "1",
            parentEnumValue: "6604",
            enumValue: "660418",
            enumName: "78团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 305,
          },
          {
            fldEnumId: 15858,
            enumGroupGuid: "1",
            parentEnumValue: "6604",
            enumValue: "660419",
            enumName: "79团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 306,
          },
          {
            fldEnumId: 15860,
            enumGroupGuid: "1",
            parentEnumValue: "6604",
            enumValue: "660420",
            enumName: "工矿厂",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 307,
          },
          {
            fldEnumId: 15862,
            enumGroupGuid: "1",
            parentEnumValue: "6604",
            enumValue: "660421",
            enumName: "拜什墩农场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 308,
          },
          {
            fldEnumId: 15864,
            enumGroupGuid: "1",
            parentEnumValue: "6604",
            enumValue: "660422",
            enumName: "良繁场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 309,
          },
          {
            fldEnumId: 15866,
            enumGroupGuid: "1",
            parentEnumValue: "6604",
            enumValue: "660423",
            enumName: "师直",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 310,
          },
        ],
      },
      {
        fldEnumId: 15868,
        enumGroupGuid: "1",
        parentEnumValue: "66",
        enumValue: "6605",
        enumName: "农五师",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 222,
        children: [
          {
            fldEnumId: 16302,
            enumGroupGuid: "1",
            parentEnumValue: "6605",
            enumValue: "660500",
            enumName: "农五师本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130373,
            enumGroupGuid: "1",
            parentEnumValue: "6605",
            enumValue: "660513",
            enumName: "双河市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 15869,
            enumGroupGuid: "1",
            parentEnumValue: "6605",
            enumValue: "660501",
            enumName: "81团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 311,
          },
          {
            fldEnumId: 15871,
            enumGroupGuid: "1",
            parentEnumValue: "6605",
            enumValue: "660502",
            enumName: "82团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 312,
          },
          {
            fldEnumId: 15873,
            enumGroupGuid: "1",
            parentEnumValue: "6605",
            enumValue: "660503",
            enumName: "83团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 313,
          },
          {
            fldEnumId: 15875,
            enumGroupGuid: "1",
            parentEnumValue: "6605",
            enumValue: "660504",
            enumName: "84团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 314,
          },
          {
            fldEnumId: 15877,
            enumGroupGuid: "1",
            parentEnumValue: "6605",
            enumValue: "660505",
            enumName: "85团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 315,
          },
          {
            fldEnumId: 15879,
            enumGroupGuid: "1",
            parentEnumValue: "6605",
            enumValue: "660506",
            enumName: "86团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 316,
          },
          {
            fldEnumId: 15881,
            enumGroupGuid: "1",
            parentEnumValue: "6605",
            enumValue: "660507",
            enumName: "87团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 317,
          },
          {
            fldEnumId: 15883,
            enumGroupGuid: "1",
            parentEnumValue: "6605",
            enumValue: "660508",
            enumName: "88团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 318,
          },
          {
            fldEnumId: 15885,
            enumGroupGuid: "1",
            parentEnumValue: "6605",
            enumValue: "660509",
            enumName: "89团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 319,
          },
          {
            fldEnumId: 15887,
            enumGroupGuid: "1",
            parentEnumValue: "6605",
            enumValue: "660510",
            enumName: "90团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 320,
          },
          {
            fldEnumId: 15889,
            enumGroupGuid: "1",
            parentEnumValue: "6605",
            enumValue: "660511",
            enumName: "91团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 321,
          },
          {
            fldEnumId: 15891,
            enumGroupGuid: "1",
            parentEnumValue: "6605",
            enumValue: "660512",
            enumName: "师直",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 322,
          },
        ],
      },
      {
        fldEnumId: 15893,
        enumGroupGuid: "1",
        parentEnumValue: "66",
        enumValue: "6606",
        enumName: "农六师",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 223,
        children: [
          {
            fldEnumId: 16186,
            enumGroupGuid: "1",
            parentEnumValue: "6606",
            enumValue: "660600",
            enumName: "农六师本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130374,
            enumGroupGuid: "1",
            parentEnumValue: "6606",
            enumValue: "660621",
            enumName: "五家渠市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 15894,
            enumGroupGuid: "1",
            parentEnumValue: "6606",
            enumValue: "660601",
            enumName: "101团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 323,
          },
          {
            fldEnumId: 15896,
            enumGroupGuid: "1",
            parentEnumValue: "6606",
            enumValue: "660602",
            enumName: "102团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 324,
          },
          {
            fldEnumId: 15898,
            enumGroupGuid: "1",
            parentEnumValue: "6606",
            enumValue: "660603",
            enumName: "103团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 325,
          },
          {
            fldEnumId: 15900,
            enumGroupGuid: "1",
            parentEnumValue: "6606",
            enumValue: "660604",
            enumName: "105团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 326,
          },
          {
            fldEnumId: 15902,
            enumGroupGuid: "1",
            parentEnumValue: "6606",
            enumValue: "660605",
            enumName: "106团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 327,
          },
          {
            fldEnumId: 15904,
            enumGroupGuid: "1",
            parentEnumValue: "6606",
            enumValue: "660606",
            enumName: "107团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 328,
          },
          {
            fldEnumId: 15906,
            enumGroupGuid: "1",
            parentEnumValue: "6606",
            enumValue: "660607",
            enumName: "108团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 329,
          },
          {
            fldEnumId: 15908,
            enumGroupGuid: "1",
            parentEnumValue: "6606",
            enumValue: "660608",
            enumName: "109团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 330,
          },
          {
            fldEnumId: 15910,
            enumGroupGuid: "1",
            parentEnumValue: "6606",
            enumValue: "660609",
            enumName: "110团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 331,
          },
          {
            fldEnumId: 15912,
            enumGroupGuid: "1",
            parentEnumValue: "6606",
            enumValue: "660610",
            enumName: "111团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 332,
          },
          {
            fldEnumId: 15914,
            enumGroupGuid: "1",
            parentEnumValue: "6606",
            enumValue: "660611",
            enumName: "新湖农场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 333,
          },
          {
            fldEnumId: 15916,
            enumGroupGuid: "1",
            parentEnumValue: "6606",
            enumValue: "660612",
            enumName: "芳草湖农场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 334,
          },
          {
            fldEnumId: 15918,
            enumGroupGuid: "1",
            parentEnumValue: "6606",
            enumValue: "660613",
            enumName: "军户农场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 335,
          },
          {
            fldEnumId: 15920,
            enumGroupGuid: "1",
            parentEnumValue: "6606",
            enumValue: "660614",
            enumName: "共青团农场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 336,
          },
          {
            fldEnumId: 15922,
            enumGroupGuid: "1",
            parentEnumValue: "6606",
            enumValue: "660615",
            enumName: "六运湖农场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 337,
          },
          {
            fldEnumId: 15924,
            enumGroupGuid: "1",
            parentEnumValue: "6606",
            enumValue: "660616",
            enumName: "土墩子农场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 338,
          },
          {
            fldEnumId: 15926,
            enumGroupGuid: "1",
            parentEnumValue: "6606",
            enumValue: "660617",
            enumName: "红旗农场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 339,
          },
          {
            fldEnumId: 15928,
            enumGroupGuid: "1",
            parentEnumValue: "6606",
            enumValue: "660618",
            enumName: "奇台农场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 340,
          },
          {
            fldEnumId: 15930,
            enumGroupGuid: "1",
            parentEnumValue: "6606",
            enumValue: "660619",
            enumName: "北塔山农场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 341,
          },
          {
            fldEnumId: 15932,
            enumGroupGuid: "1",
            parentEnumValue: "6606",
            enumValue: "660620",
            enumName: "师直",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 342,
          },
        ],
      },
      {
        fldEnumId: 15934,
        enumGroupGuid: "1",
        parentEnumValue: "66",
        enumValue: "6607",
        enumName: "农七师",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 224,
        children: [
          {
            fldEnumId: 16188,
            enumGroupGuid: "1",
            parentEnumValue: "6607",
            enumValue: "660700",
            enumName: "农七师本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130289,
            enumGroupGuid: "1",
            parentEnumValue: "6607",
            enumValue: "660714",
            enumName: "胡杨河市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 15935,
            enumGroupGuid: "1",
            parentEnumValue: "6607",
            enumValue: "660701",
            enumName: "123团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 343,
          },
          {
            fldEnumId: 15937,
            enumGroupGuid: "1",
            parentEnumValue: "6607",
            enumValue: "660702",
            enumName: "124团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 344,
          },
          {
            fldEnumId: 15939,
            enumGroupGuid: "1",
            parentEnumValue: "6607",
            enumValue: "660703",
            enumName: "125团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 345,
          },
          {
            fldEnumId: 15941,
            enumGroupGuid: "1",
            parentEnumValue: "6607",
            enumValue: "660704",
            enumName: "126团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 346,
          },
          {
            fldEnumId: 15943,
            enumGroupGuid: "1",
            parentEnumValue: "6607",
            enumValue: "660705",
            enumName: "127团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 347,
          },
          {
            fldEnumId: 15945,
            enumGroupGuid: "1",
            parentEnumValue: "6607",
            enumValue: "660706",
            enumName: "128团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 348,
          },
          {
            fldEnumId: 15947,
            enumGroupGuid: "1",
            parentEnumValue: "6607",
            enumValue: "660707",
            enumName: "129团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 349,
          },
          {
            fldEnumId: 15949,
            enumGroupGuid: "1",
            parentEnumValue: "6607",
            enumValue: "660708",
            enumName: "130团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 350,
          },
          {
            fldEnumId: 15951,
            enumGroupGuid: "1",
            parentEnumValue: "6607",
            enumValue: "660709",
            enumName: "131团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 351,
          },
          {
            fldEnumId: 15953,
            enumGroupGuid: "1",
            parentEnumValue: "6607",
            enumValue: "660710",
            enumName: "137团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 352,
          },
          {
            fldEnumId: 15955,
            enumGroupGuid: "1",
            parentEnumValue: "6607",
            enumValue: "660711",
            enumName: "师直",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 353,
          },
          {
            fldEnumId: 15957,
            enumGroupGuid: "1",
            parentEnumValue: "6607",
            enumValue: "660712",
            enumName: "奎屯天北新区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 354,
          },
          {
            fldEnumId: 15959,
            enumGroupGuid: "1",
            parentEnumValue: "6607",
            enumValue: "660713",
            enumName: "红山煤矿",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 355,
          },
        ],
      },
      {
        fldEnumId: 15961,
        enumGroupGuid: "1",
        parentEnumValue: "66",
        enumValue: "6608",
        enumName: "农八师",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 225,
        children: [
          {
            fldEnumId: 16190,
            enumGroupGuid: "1",
            parentEnumValue: "6608",
            enumValue: "660800",
            enumName: "农八师本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130291,
            enumGroupGuid: "1",
            parentEnumValue: "6608",
            enumValue: "660826",
            enumName: "石河子市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 15962,
            enumGroupGuid: "1",
            parentEnumValue: "6608",
            enumValue: "660801",
            enumName: "121团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 356,
          },
          {
            fldEnumId: 15964,
            enumGroupGuid: "1",
            parentEnumValue: "6608",
            enumValue: "660802",
            enumName: "122团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 357,
          },
          {
            fldEnumId: 15966,
            enumGroupGuid: "1",
            parentEnumValue: "6608",
            enumValue: "660803",
            enumName: "132团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 358,
          },
          {
            fldEnumId: 15968,
            enumGroupGuid: "1",
            parentEnumValue: "6608",
            enumValue: "660804",
            enumName: "133团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 359,
          },
          {
            fldEnumId: 15970,
            enumGroupGuid: "1",
            parentEnumValue: "6608",
            enumValue: "660805",
            enumName: "134团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 360,
          },
          {
            fldEnumId: 15972,
            enumGroupGuid: "1",
            parentEnumValue: "6608",
            enumValue: "660806",
            enumName: "135团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 361,
          },
          {
            fldEnumId: 15974,
            enumGroupGuid: "1",
            parentEnumValue: "6608",
            enumValue: "660807",
            enumName: "136团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 362,
          },
          {
            fldEnumId: 15976,
            enumGroupGuid: "1",
            parentEnumValue: "6608",
            enumValue: "660808",
            enumName: "141团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 363,
          },
          {
            fldEnumId: 15978,
            enumGroupGuid: "1",
            parentEnumValue: "6608",
            enumValue: "660809",
            enumName: "142团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 364,
          },
          {
            fldEnumId: 15980,
            enumGroupGuid: "1",
            parentEnumValue: "6608",
            enumValue: "660810",
            enumName: "143团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 365,
          },
          {
            fldEnumId: 15982,
            enumGroupGuid: "1",
            parentEnumValue: "6608",
            enumValue: "660811",
            enumName: "144团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 366,
          },
          {
            fldEnumId: 15984,
            enumGroupGuid: "1",
            parentEnumValue: "6608",
            enumValue: "660812",
            enumName: "石总场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 367,
          },
          {
            fldEnumId: 15986,
            enumGroupGuid: "1",
            parentEnumValue: "6608",
            enumValue: "660813",
            enumName: "147团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 368,
          },
          {
            fldEnumId: 15988,
            enumGroupGuid: "1",
            parentEnumValue: "6608",
            enumValue: "660814",
            enumName: "148团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 369,
          },
          {
            fldEnumId: 15990,
            enumGroupGuid: "1",
            parentEnumValue: "6608",
            enumValue: "660815",
            enumName: "149团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 370,
          },
          {
            fldEnumId: 15992,
            enumGroupGuid: "1",
            parentEnumValue: "6608",
            enumValue: "660816",
            enumName: "150团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 371,
          },
          {
            fldEnumId: 15994,
            enumGroupGuid: "1",
            parentEnumValue: "6608",
            enumValue: "660817",
            enumName: "151团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 372,
          },
          {
            fldEnumId: 15996,
            enumGroupGuid: "1",
            parentEnumValue: "6608",
            enumValue: "660818",
            enumName: "152团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 373,
          },
          {
            fldEnumId: 15998,
            enumGroupGuid: "1",
            parentEnumValue: "6608",
            enumValue: "660819",
            enumName: "南山矿区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 374,
          },
          {
            fldEnumId: 16000,
            enumGroupGuid: "1",
            parentEnumValue: "6608",
            enumValue: "660820",
            enumName: "玛管处",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 375,
          },
          {
            fldEnumId: 16002,
            enumGroupGuid: "1",
            parentEnumValue: "6608",
            enumValue: "660821",
            enumName: "巴管处",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 376,
          },
          {
            fldEnumId: 16004,
            enumGroupGuid: "1",
            parentEnumValue: "6608",
            enumValue: "660822",
            enumName: "其他单位",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 377,
          },
          {
            fldEnumId: 16006,
            enumGroupGuid: "1",
            parentEnumValue: "6608",
            enumValue: "660823",
            enumName: "红旗农场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 378,
          },
          {
            fldEnumId: 16008,
            enumGroupGuid: "1",
            parentEnumValue: "6608",
            enumValue: "660824",
            enumName: "东城",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 379,
          },
          {
            fldEnumId: 16010,
            enumGroupGuid: "1",
            parentEnumValue: "6608",
            enumValue: "660825",
            enumName: "南区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 380,
          },
        ],
      },
      {
        fldEnumId: 16012,
        enumGroupGuid: "1",
        parentEnumValue: "66",
        enumValue: "6609",
        enumName: "农九师",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 226,
        children: [
          {
            fldEnumId: 16192,
            enumGroupGuid: "1",
            parentEnumValue: "6609",
            enumValue: "660900",
            enumName: "农九师本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 16013,
            enumGroupGuid: "1",
            parentEnumValue: "6609",
            enumValue: "660901",
            enumName: "师直",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 381,
          },
          {
            fldEnumId: 16015,
            enumGroupGuid: "1",
            parentEnumValue: "6609",
            enumValue: "660902",
            enumName: "161团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 382,
          },
          {
            fldEnumId: 16017,
            enumGroupGuid: "1",
            parentEnumValue: "6609",
            enumValue: "660903",
            enumName: "162团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 383,
          },
          {
            fldEnumId: 16019,
            enumGroupGuid: "1",
            parentEnumValue: "6609",
            enumValue: "660904",
            enumName: "163团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 384,
          },
          {
            fldEnumId: 16021,
            enumGroupGuid: "1",
            parentEnumValue: "6609",
            enumValue: "660905",
            enumName: "164团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 385,
          },
          {
            fldEnumId: 16023,
            enumGroupGuid: "1",
            parentEnumValue: "6609",
            enumValue: "660906",
            enumName: "165团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 386,
          },
          {
            fldEnumId: 16025,
            enumGroupGuid: "1",
            parentEnumValue: "6609",
            enumValue: "660907",
            enumName: "166团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 387,
          },
          {
            fldEnumId: 16027,
            enumGroupGuid: "1",
            parentEnumValue: "6609",
            enumValue: "660908",
            enumName: "167团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 388,
          },
          {
            fldEnumId: 16029,
            enumGroupGuid: "1",
            parentEnumValue: "6609",
            enumValue: "660909",
            enumName: "168团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 389,
          },
          {
            fldEnumId: 16031,
            enumGroupGuid: "1",
            parentEnumValue: "6609",
            enumValue: "660910",
            enumName: "169团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 390,
          },
          {
            fldEnumId: 16033,
            enumGroupGuid: "1",
            parentEnumValue: "6609",
            enumValue: "660911",
            enumName: "170团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 391,
          },
          {
            fldEnumId: 16035,
            enumGroupGuid: "1",
            parentEnumValue: "6609",
            enumValue: "660912",
            enumName: "团结农场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 392,
          },
        ],
      },
      {
        fldEnumId: 16037,
        enumGroupGuid: "1",
        parentEnumValue: "66",
        enumValue: "6610",
        enumName: "农十师",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 227,
        children: [
          {
            fldEnumId: 130293,
            enumGroupGuid: "1",
            parentEnumValue: "6610",
            enumValue: "659005",
            enumName: "北屯市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 16194,
            enumGroupGuid: "1",
            parentEnumValue: "6610",
            enumValue: "661000",
            enumName: "农十师本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 16038,
            enumGroupGuid: "1",
            parentEnumValue: "6610",
            enumValue: "661001",
            enumName: "181团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 393,
          },
          {
            fldEnumId: 16040,
            enumGroupGuid: "1",
            parentEnumValue: "6610",
            enumValue: "661002",
            enumName: "182团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 394,
          },
          {
            fldEnumId: 16042,
            enumGroupGuid: "1",
            parentEnumValue: "6610",
            enumValue: "661003",
            enumName: "183团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 395,
          },
          {
            fldEnumId: 16044,
            enumGroupGuid: "1",
            parentEnumValue: "6610",
            enumValue: "661004",
            enumName: "184团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 396,
          },
          {
            fldEnumId: 16046,
            enumGroupGuid: "1",
            parentEnumValue: "6610",
            enumValue: "661005",
            enumName: "185团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 397,
          },
          {
            fldEnumId: 16048,
            enumGroupGuid: "1",
            parentEnumValue: "6610",
            enumValue: "661006",
            enumName: "186团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 398,
          },
          {
            fldEnumId: 16050,
            enumGroupGuid: "1",
            parentEnumValue: "6610",
            enumValue: "661007",
            enumName: "187团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 399,
          },
          {
            fldEnumId: 16052,
            enumGroupGuid: "1",
            parentEnumValue: "6610",
            enumValue: "661008",
            enumName: "188团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 400,
          },
          {
            fldEnumId: 16054,
            enumGroupGuid: "1",
            parentEnumValue: "6610",
            enumValue: "661009",
            enumName: "189团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 401,
          },
          {
            fldEnumId: 16056,
            enumGroupGuid: "1",
            parentEnumValue: "6610",
            enumValue: "661010",
            enumName: "190团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 402,
          },
          {
            fldEnumId: 16058,
            enumGroupGuid: "1",
            parentEnumValue: "6610",
            enumValue: "661011",
            enumName: "青河农场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 403,
          },
          {
            fldEnumId: 16060,
            enumGroupGuid: "1",
            parentEnumValue: "6610",
            enumValue: "661012",
            enumName: "煤矿",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 404,
          },
          {
            fldEnumId: 16062,
            enumGroupGuid: "1",
            parentEnumValue: "6610",
            enumValue: "661013",
            enumName: "师直北屯",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 405,
          },
          {
            fldEnumId: 16064,
            enumGroupGuid: "1",
            parentEnumValue: "6610",
            enumValue: "661014",
            enumName: "一矿",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 406,
          },
          {
            fldEnumId: 16066,
            enumGroupGuid: "1",
            parentEnumValue: "6610",
            enumValue: "661015",
            enumName: "二矿",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 407,
          },
          {
            fldEnumId: 16068,
            enumGroupGuid: "1",
            parentEnumValue: "6610",
            enumValue: "661016",
            enumName: "三矿",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 408,
          },
          {
            fldEnumId: 16070,
            enumGroupGuid: "1",
            parentEnumValue: "6610",
            enumValue: "661017",
            enumName: "四矿",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 409,
          },
        ],
      },
      {
        fldEnumId: 16072,
        enumGroupGuid: "1",
        parentEnumValue: "66",
        enumValue: "6611",
        enumName: "建工师",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 228,
        children: [
          {
            fldEnumId: 16141,
            enumGroupGuid: "1",
            parentEnumValue: "6611",
            enumValue: "661100",
            enumName: "建工师本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
          },
        ],
      },
      {
        fldEnumId: 16073,
        enumGroupGuid: "1",
        parentEnumValue: "66",
        enumValue: "6612",
        enumName: "农十二师",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 229,
        children: [
          {
            fldEnumId: 16196,
            enumGroupGuid: "1",
            parentEnumValue: "6612",
            enumValue: "661200",
            enumName: "农十二师本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130148,
            enumGroupGuid: "1",
            parentEnumValue: "6612",
            enumValue: "661501",
            enumName: "222团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 16074,
            enumGroupGuid: "1",
            parentEnumValue: "6612",
            enumValue: "661201",
            enumName: "104团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 410,
          },
          {
            fldEnumId: 16076,
            enumGroupGuid: "1",
            parentEnumValue: "6612",
            enumValue: "661202",
            enumName: "五一农场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 411,
          },
          {
            fldEnumId: 16078,
            enumGroupGuid: "1",
            parentEnumValue: "6612",
            enumValue: "661203",
            enumName: "三坪农场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 412,
          },
          {
            fldEnumId: 16080,
            enumGroupGuid: "1",
            parentEnumValue: "6612",
            enumValue: "661204",
            enumName: "头屯河农场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 413,
          },
          {
            fldEnumId: 16082,
            enumGroupGuid: "1",
            parentEnumValue: "6612",
            enumValue: "661205",
            enumName: "养禽场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 414,
          },
          {
            fldEnumId: 16084,
            enumGroupGuid: "1",
            parentEnumValue: "6612",
            enumValue: "661206",
            enumName: "西山牧场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 415,
          },
          {
            fldEnumId: 16086,
            enumGroupGuid: "1",
            parentEnumValue: "6612",
            enumValue: "661207",
            enumName: "221团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 416,
          },
          {
            fldEnumId: 16088,
            enumGroupGuid: "1",
            parentEnumValue: "6612",
            enumValue: "661208",
            enumName: "合作分区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 417,
          },
          {
            fldEnumId: 16090,
            enumGroupGuid: "1",
            parentEnumValue: "6612",
            enumValue: "661209",
            enumName: "红岩水库",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 418,
          },
        ],
      },
      {
        fldEnumId: 16092,
        enumGroupGuid: "1",
        parentEnumValue: "66",
        enumValue: "6613",
        enumName: "农十三师",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 230,
        children: [
          {
            fldEnumId: 16198,
            enumGroupGuid: "1",
            parentEnumValue: "6613",
            enumValue: "661300",
            enumName: "农十三师本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 16093,
            enumGroupGuid: "1",
            parentEnumValue: "6613",
            enumValue: "661301",
            enumName: "红星一场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 419,
          },
          {
            fldEnumId: 16095,
            enumGroupGuid: "1",
            parentEnumValue: "6613",
            enumValue: "661302",
            enumName: "红星二场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 420,
          },
          {
            fldEnumId: 16097,
            enumGroupGuid: "1",
            parentEnumValue: "6613",
            enumValue: "661303",
            enumName: "红星三场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 421,
          },
          {
            fldEnumId: 16099,
            enumGroupGuid: "1",
            parentEnumValue: "6613",
            enumValue: "661304",
            enumName: "红星四场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 422,
          },
          {
            fldEnumId: 16101,
            enumGroupGuid: "1",
            parentEnumValue: "6613",
            enumValue: "661305",
            enumName: "黄田农场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 423,
          },
          {
            fldEnumId: 16103,
            enumGroupGuid: "1",
            parentEnumValue: "6613",
            enumValue: "661306",
            enumName: "火箭农场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 424,
          },
          {
            fldEnumId: 16105,
            enumGroupGuid: "1",
            parentEnumValue: "6613",
            enumValue: "661307",
            enumName: "柳树泉农场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 425,
          },
          {
            fldEnumId: 16107,
            enumGroupGuid: "1",
            parentEnumValue: "6613",
            enumValue: "661308",
            enumName: "红山农场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 426,
          },
          {
            fldEnumId: 16109,
            enumGroupGuid: "1",
            parentEnumValue: "6613",
            enumValue: "661309",
            enumName: "淖毛湖农场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 427,
          },
          {
            fldEnumId: 16111,
            enumGroupGuid: "1",
            parentEnumValue: "6613",
            enumValue: "661310",
            enumName: "红星一牧场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 428,
          },
          {
            fldEnumId: 16113,
            enumGroupGuid: "1",
            parentEnumValue: "6613",
            enumValue: "661311",
            enumName: "红星二牧场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 429,
          },
          {
            fldEnumId: 16115,
            enumGroupGuid: "1",
            parentEnumValue: "6613",
            enumValue: "661312",
            enumName: "师直",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 430,
          },
        ],
      },
      {
        fldEnumId: 16117,
        enumGroupGuid: "1",
        parentEnumValue: "66",
        enumValue: "6614",
        enumName: "农十四师",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 231,
        children: [
          {
            fldEnumId: 16200,
            enumGroupGuid: "1",
            parentEnumValue: "6614",
            enumValue: "661400",
            enumName: "农十四师本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130294,
            enumGroupGuid: "1",
            parentEnumValue: "6614",
            enumValue: "661405",
            enumName: "昆玉市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130380,
            enumGroupGuid: "1",
            parentEnumValue: "6614",
            enumValue: "661406",
            enumName: "225团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 16118,
            enumGroupGuid: "1",
            parentEnumValue: "6614",
            enumValue: "661401",
            enumName: "皮山农场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 431,
          },
          {
            fldEnumId: 16120,
            enumGroupGuid: "1",
            parentEnumValue: "6614",
            enumValue: "661402",
            enumName: "47团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 432,
          },
          {
            fldEnumId: 16122,
            enumGroupGuid: "1",
            parentEnumValue: "6614",
            enumValue: "661403",
            enumName: "一牧场",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 433,
          },
          {
            fldEnumId: 16124,
            enumGroupGuid: "1",
            parentEnumValue: "6614",
            enumValue: "661404",
            enumName: "224团",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 434,
          },
        ],
      },
      {
        fldEnumId: 16126,
        enumGroupGuid: "1",
        parentEnumValue: "66",
        enumValue: "6615",
        enumName: "兵直",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 232,
        children: [
          {
            fldEnumId: 16202,
            enumGroupGuid: "1",
            parentEnumValue: "6615",
            enumValue: "661500",
            enumName: "兵直本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
        ],
      },
    ],
  },
  {
    enumValue: "12",
    enumName: "天津市",
    enumDesc: "zdcs",
    children: [
      {
        fldEnumId: 13465,
        enumGroupGuid: "1",
        parentEnumValue: "12",
        enumValue: "1201",
        enumName: "天津市市辖区",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        enumNameother: "市辖区",
        children: [
          {
            fldEnumId: 16135,
            enumGroupGuid: "1",
            parentEnumValue: "1201",
            enumValue: "120100",
            enumName: "天津市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130126,
            enumGroupGuid: "1",
            parentEnumValue: "1201",
            enumValue: "120116",
            enumName: "滨海新区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130127,
            enumGroupGuid: "1",
            parentEnumValue: "1201",
            enumValue: "120221",
            enumName: "宁河区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130128,
            enumGroupGuid: "1",
            parentEnumValue: "1201",
            enumValue: "120223",
            enumName: "静海区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 13467,
            enumGroupGuid: "1",
            parentEnumValue: "1201",
            enumValue: "120101",
            enumName: "和平区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "和平区",
          },
          {
            fldEnumId: 13471,
            enumGroupGuid: "1",
            parentEnumValue: "1201",
            enumValue: "120102",
            enumName: "河东区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "河东区",
          },
          {
            fldEnumId: 13474,
            enumGroupGuid: "1",
            parentEnumValue: "1201",
            enumValue: "120103",
            enumName: "河西区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 2,
            enumNameother: "河西区",
          },
          {
            fldEnumId: 13478,
            enumGroupGuid: "1",
            parentEnumValue: "1201",
            enumValue: "120104",
            enumName: "南开区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 3,
            enumNameother: "南开区",
          },
          {
            fldEnumId: 13482,
            enumGroupGuid: "1",
            parentEnumValue: "1201",
            enumValue: "120105",
            enumName: "河北区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 4,
            enumNameother: "河北区",
          },
          {
            fldEnumId: 13486,
            enumGroupGuid: "1",
            parentEnumValue: "1201",
            enumValue: "120106",
            enumName: "红桥区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 5,
            enumNameother: "红桥区",
          },
          {
            fldEnumId: 13490,
            enumGroupGuid: "1",
            parentEnumValue: "1201",
            enumValue: "120107",
            enumName: "塘沽区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 6,
            enumNameother: "塘沽区",
          },
          {
            fldEnumId: 13494,
            enumGroupGuid: "1",
            parentEnumValue: "1201",
            enumValue: "120108",
            enumName: "汉沽区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 7,
            enumNameother: "汉沽区",
          },
          {
            fldEnumId: 13498,
            enumGroupGuid: "1",
            parentEnumValue: "1201",
            enumValue: "120109",
            enumName: "大港区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 8,
            enumNameother: "大港区",
          },
          {
            fldEnumId: 13502,
            enumGroupGuid: "1",
            parentEnumValue: "1201",
            enumValue: "120110",
            enumName: "东丽区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 9,
            enumNameother: "东丽区",
          },
          {
            fldEnumId: 13506,
            enumGroupGuid: "1",
            parentEnumValue: "1201",
            enumValue: "120111",
            enumName: "西青区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 10,
            enumNameother: "西青区",
          },
          {
            fldEnumId: 13510,
            enumGroupGuid: "1",
            parentEnumValue: "1201",
            enumValue: "120112",
            enumName: "津南区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 11,
            enumNameother: "津南区",
          },
          {
            fldEnumId: 13514,
            enumGroupGuid: "1",
            parentEnumValue: "1201",
            enumValue: "120113",
            enumName: "北辰区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 12,
            enumNameother: "北辰区",
          },
          {
            fldEnumId: 13518,
            enumGroupGuid: "1",
            parentEnumValue: "1201",
            enumValue: "120114",
            enumName: "武清区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 13,
            enumNameother: "武清区",
          },
          {
            fldEnumId: 13522,
            enumGroupGuid: "1",
            parentEnumValue: "1201",
            enumValue: "120115",
            enumName: "宝坻区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 14,
            enumNameother: "宝坻区",
          },
        ],
      },
      {
        fldEnumId: 13526,
        enumGroupGuid: "1",
        parentEnumValue: "12",
        enumValue: "1202",
        enumName: "天津市所辖县",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 1,
        enumNameother: "所辖县",
        children: [
          {
            fldEnumId: 13536,
            enumGroupGuid: "1",
            parentEnumValue: "1202",
            enumValue: "120225",
            enumName: "蓟县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 17,
            enumNameother: "蓟县",
          },
        ],
      },
    ],
  },
  {
    enumValue: "13",
    enumName: "河北省",
    children: [
      {
        fldEnumId: 13542,
        enumGroupGuid: "1",
        parentEnumValue: "13",
        enumValue: "1301",
        enumName: "石家庄市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        enumNameother: "石家庄市",
        children: [
          {
            fldEnumId: 13544,
            enumGroupGuid: "1",
            parentEnumValue: "1301",
            enumValue: "130100",
            enumName: "石家庄市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
            enumNameother: "石家庄市",
          },
          {
            fldEnumId: 13548,
            enumGroupGuid: "1",
            parentEnumValue: "1301",
            enumValue: "130102",
            enumName: "长安区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "长安区",
          },
          {
            fldEnumId: 13552,
            enumGroupGuid: "1",
            parentEnumValue: "1301",
            enumValue: "130103",
            enumName: "桥东区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 2,
            enumNameother: "桥东区",
          },
          {
            fldEnumId: 13556,
            enumGroupGuid: "1",
            parentEnumValue: "1301",
            enumValue: "130104",
            enumName: "桥西区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 3,
            enumNameother: "桥西区",
          },
          {
            fldEnumId: 13560,
            enumGroupGuid: "1",
            parentEnumValue: "1301",
            enumValue: "130105",
            enumName: "新华区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 4,
            enumNameother: "新华区",
          },
          {
            fldEnumId: 13564,
            enumGroupGuid: "1",
            parentEnumValue: "1301",
            enumValue: "130107",
            enumName: "井陉矿区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 5,
            enumNameother: "井陉矿区",
          },
          {
            fldEnumId: 13568,
            enumGroupGuid: "1",
            parentEnumValue: "1301",
            enumValue: "130108",
            enumName: "裕华区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 6,
            enumNameother: "裕华区",
          },
          {
            fldEnumId: 13572,
            enumGroupGuid: "1",
            parentEnumValue: "1301",
            enumValue: "130121",
            enumName: "井陉县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 7,
            enumNameother: "井陉县",
          },
          {
            fldEnumId: 13576,
            enumGroupGuid: "1",
            parentEnumValue: "1301",
            enumValue: "130123",
            enumName: "正定县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 8,
            enumNameother: "正定县",
          },
          {
            fldEnumId: 13581,
            enumGroupGuid: "1",
            parentEnumValue: "1301",
            enumValue: "130124",
            enumName: "栾城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 9,
            enumNameother: "栾城县",
          },
          {
            fldEnumId: 13585,
            enumGroupGuid: "1",
            parentEnumValue: "1301",
            enumValue: "130125",
            enumName: "行唐县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 10,
            enumNameother: "行唐县",
          },
          {
            fldEnumId: 13589,
            enumGroupGuid: "1",
            parentEnumValue: "1301",
            enumValue: "130126",
            enumName: "灵寿县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 11,
            enumNameother: "灵寿县",
          },
          {
            fldEnumId: 13593,
            enumGroupGuid: "1",
            parentEnumValue: "1301",
            enumValue: "130127",
            enumName: "高邑县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 12,
            enumNameother: "高邑县",
          },
          {
            fldEnumId: 13597,
            enumGroupGuid: "1",
            parentEnumValue: "1301",
            enumValue: "130128",
            enumName: "深泽县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 13,
            enumNameother: "深泽县",
          },
          {
            fldEnumId: 13601,
            enumGroupGuid: "1",
            parentEnumValue: "1301",
            enumValue: "130129",
            enumName: "赞皇县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 14,
            enumNameother: "赞皇县",
          },
          {
            fldEnumId: 13605,
            enumGroupGuid: "1",
            parentEnumValue: "1301",
            enumValue: "130130",
            enumName: "无极县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 15,
            enumNameother: "无极县",
          },
          {
            fldEnumId: 13609,
            enumGroupGuid: "1",
            parentEnumValue: "1301",
            enumValue: "130131",
            enumName: "平山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 16,
            enumNameother: "平山县",
          },
          {
            fldEnumId: 13613,
            enumGroupGuid: "1",
            parentEnumValue: "1301",
            enumValue: "130132",
            enumName: "元氏县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 17,
            enumNameother: "元氏县",
          },
          {
            fldEnumId: 13616,
            enumGroupGuid: "1",
            parentEnumValue: "1301",
            enumValue: "130133",
            enumName: "赵县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 18,
            enumNameother: "赵县",
          },
          {
            fldEnumId: 13620,
            enumGroupGuid: "1",
            parentEnumValue: "1301",
            enumValue: "130181",
            enumName: "辛集市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 19,
            enumNameother: "辛集市",
          },
          {
            fldEnumId: 13624,
            enumGroupGuid: "1",
            parentEnumValue: "1301",
            enumValue: "130182",
            enumName: "藁城市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 20,
            enumNameother: "藁城市",
          },
          {
            fldEnumId: 13628,
            enumGroupGuid: "1",
            parentEnumValue: "1301",
            enumValue: "130183",
            enumName: "晋州市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 21,
            enumNameother: "晋州市",
          },
          {
            fldEnumId: 13632,
            enumGroupGuid: "1",
            parentEnumValue: "1301",
            enumValue: "130184",
            enumName: "新乐市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 22,
            enumNameother: "新乐市",
          },
          {
            fldEnumId: 13636,
            enumGroupGuid: "1",
            parentEnumValue: "1301",
            enumValue: "130185",
            enumName: "鹿泉市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 23,
            enumNameother: "鹿泉市",
          },
        ],
      },
      {
        fldEnumId: 130367,
        enumGroupGuid: "1",
        parentEnumValue: "13",
        enumValue: "1399",
        enumName: "雄安新区",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        children: [
          {
            fldEnumId: 130279,
            enumGroupGuid: "1",
            parentEnumValue: "1399",
            enumValue: "139900",
            enumName: "雄安新区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
        ],
      },
      {
        fldEnumId: 13640,
        enumGroupGuid: "1",
        parentEnumValue: "13",
        enumValue: "1302",
        enumName: "唐山市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 1,
        enumNameother: "唐山市",
        children: [
          {
            fldEnumId: 130129,
            enumGroupGuid: "1",
            parentEnumValue: "1302",
            enumValue: "130209",
            enumName: "曹妃甸区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 13642,
            enumGroupGuid: "1",
            parentEnumValue: "1302",
            enumValue: "130200",
            enumName: "唐山市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 24,
            enumNameother: "唐山市",
          },
          {
            fldEnumId: 13646,
            enumGroupGuid: "1",
            parentEnumValue: "1302",
            enumValue: "130202",
            enumName: "路南区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 25,
            enumNameother: "路南区",
          },
          {
            fldEnumId: 13651,
            enumGroupGuid: "1",
            parentEnumValue: "1302",
            enumValue: "130203",
            enumName: "路北区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 26,
            enumNameother: "路北区",
          },
          {
            fldEnumId: 13655,
            enumGroupGuid: "1",
            parentEnumValue: "1302",
            enumValue: "130204",
            enumName: "古冶区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 27,
            enumNameother: "古冶区",
          },
          {
            fldEnumId: 13659,
            enumGroupGuid: "1",
            parentEnumValue: "1302",
            enumValue: "130205",
            enumName: "开平区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 28,
            enumNameother: "开平区",
          },
          {
            fldEnumId: 13663,
            enumGroupGuid: "1",
            parentEnumValue: "1302",
            enumValue: "130207",
            enumName: "丰南区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 29,
            enumNameother: "丰南区",
          },
          {
            fldEnumId: 13666,
            enumGroupGuid: "1",
            parentEnumValue: "1302",
            enumValue: "130208",
            enumName: "丰润区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 30,
            enumNameother: "丰润区",
          },
          {
            fldEnumId: 13670,
            enumGroupGuid: "1",
            parentEnumValue: "1302",
            enumValue: "130223",
            enumName: "滦县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 31,
            enumNameother: "滦县",
          },
          {
            fldEnumId: 13674,
            enumGroupGuid: "1",
            parentEnumValue: "1302",
            enumValue: "130224",
            enumName: "滦南县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 32,
            enumNameother: "滦南县",
          },
          {
            fldEnumId: 13677,
            enumGroupGuid: "1",
            parentEnumValue: "1302",
            enumValue: "130225",
            enumName: "乐亭县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 33,
            enumNameother: "乐亭县",
          },
          {
            fldEnumId: 13681,
            enumGroupGuid: "1",
            parentEnumValue: "1302",
            enumValue: "130227",
            enumName: "迁西县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 34,
            enumNameother: "迁西县",
          },
          {
            fldEnumId: 13685,
            enumGroupGuid: "1",
            parentEnumValue: "1302",
            enumValue: "130229",
            enumName: "玉田县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 35,
            enumNameother: "玉田县",
          },
          {
            fldEnumId: 13689,
            enumGroupGuid: "1",
            parentEnumValue: "1302",
            enumValue: "130230",
            enumName: "唐海县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 36,
            enumNameother: "唐海县",
          },
          {
            fldEnumId: 13693,
            enumGroupGuid: "1",
            parentEnumValue: "1302",
            enumValue: "130281",
            enumName: "遵化市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 37,
            enumNameother: "遵化市",
          },
          {
            fldEnumId: 13697,
            enumGroupGuid: "1",
            parentEnumValue: "1302",
            enumValue: "130283",
            enumName: "迁安市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 38,
            enumNameother: "迁安市",
          },
        ],
      },
      {
        fldEnumId: 13701,
        enumGroupGuid: "1",
        parentEnumValue: "13",
        enumValue: "1303",
        enumName: "秦皇岛市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 2,
        enumNameother: "秦皇岛市",
        children: [
          {
            fldEnumId: 13703,
            enumGroupGuid: "1",
            parentEnumValue: "1303",
            enumValue: "130300",
            enumName: "秦皇岛市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 39,
            enumNameother: "秦皇岛市",
          },
          {
            fldEnumId: 13707,
            enumGroupGuid: "1",
            parentEnumValue: "1303",
            enumValue: "130302",
            enumName: "海港区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 40,
            enumNameother: "海港区",
          },
          {
            fldEnumId: 13711,
            enumGroupGuid: "1",
            parentEnumValue: "1303",
            enumValue: "130303",
            enumName: "山海关区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 41,
            enumNameother: "山海关区",
          },
          {
            fldEnumId: 13714,
            enumGroupGuid: "1",
            parentEnumValue: "1303",
            enumValue: "130304",
            enumName: "北戴河区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 42,
            enumNameother: "北戴河区",
          },
          {
            fldEnumId: 13718,
            enumGroupGuid: "1",
            parentEnumValue: "1303",
            enumValue: "130321",
            enumName: "青龙满族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 43,
            enumNameother: "青龙满族自治县",
          },
          {
            fldEnumId: 13722,
            enumGroupGuid: "1",
            parentEnumValue: "1303",
            enumValue: "130322",
            enumName: "昌黎县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 44,
            enumNameother: "昌黎县",
          },
          {
            fldEnumId: 13726,
            enumGroupGuid: "1",
            parentEnumValue: "1303",
            enumValue: "130323",
            enumName: "抚宁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 45,
            enumNameother: "抚宁县",
          },
          {
            fldEnumId: 13731,
            enumGroupGuid: "1",
            parentEnumValue: "1303",
            enumValue: "130324",
            enumName: "卢龙县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 46,
            enumNameother: "卢龙县",
          },
        ],
      },
      {
        fldEnumId: 13734,
        enumGroupGuid: "1",
        parentEnumValue: "13",
        enumValue: "1304",
        enumName: "邯郸市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 3,
        enumNameother: "邯郸市",
        children: [
          {
            fldEnumId: 13736,
            enumGroupGuid: "1",
            parentEnumValue: "1304",
            enumValue: "130400",
            enumName: "邯郸市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 47,
            enumNameother: "邯郸市",
          },
          {
            fldEnumId: 13740,
            enumGroupGuid: "1",
            parentEnumValue: "1304",
            enumValue: "130402",
            enumName: "邯山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 48,
            enumNameother: "邯山区",
          },
          {
            fldEnumId: 13744,
            enumGroupGuid: "1",
            parentEnumValue: "1304",
            enumValue: "130403",
            enumName: "丛台区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 49,
            enumNameother: "丛台区",
          },
          {
            fldEnumId: 13748,
            enumGroupGuid: "1",
            parentEnumValue: "1304",
            enumValue: "130404",
            enumName: "复兴区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 50,
            enumNameother: "复兴区",
          },
          {
            fldEnumId: 13752,
            enumGroupGuid: "1",
            parentEnumValue: "1304",
            enumValue: "130406",
            enumName: "峰峰矿区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 51,
            enumNameother: "峰峰矿区",
          },
          {
            fldEnumId: 13756,
            enumGroupGuid: "1",
            parentEnumValue: "1304",
            enumValue: "130421",
            enumName: "邯郸县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 52,
            enumNameother: "邯郸县",
          },
          {
            fldEnumId: 13760,
            enumGroupGuid: "1",
            parentEnumValue: "1304",
            enumValue: "130423",
            enumName: "临漳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 53,
            enumNameother: "临漳县",
          },
          {
            fldEnumId: 13765,
            enumGroupGuid: "1",
            parentEnumValue: "1304",
            enumValue: "130424",
            enumName: "成安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 54,
            enumNameother: "成安县",
          },
          {
            fldEnumId: 13769,
            enumGroupGuid: "1",
            parentEnumValue: "1304",
            enumValue: "130425",
            enumName: "大名县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 55,
            enumNameother: "大名县",
          },
          {
            fldEnumId: 13773,
            enumGroupGuid: "1",
            parentEnumValue: "1304",
            enumValue: "130426",
            enumName: "涉县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 56,
            enumNameother: "涉县",
          },
          {
            fldEnumId: 13777,
            enumGroupGuid: "1",
            parentEnumValue: "1304",
            enumValue: "130427",
            enumName: "磁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 57,
            enumNameother: "磁县",
          },
          {
            fldEnumId: 13781,
            enumGroupGuid: "1",
            parentEnumValue: "1304",
            enumValue: "130428",
            enumName: "肥乡县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 58,
            enumNameother: "肥乡县",
          },
          {
            fldEnumId: 13785,
            enumGroupGuid: "1",
            parentEnumValue: "1304",
            enumValue: "130429",
            enumName: "永年县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 59,
            enumNameother: "永年县",
          },
          {
            fldEnumId: 13789,
            enumGroupGuid: "1",
            parentEnumValue: "1304",
            enumValue: "130430",
            enumName: "邱县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 60,
            enumNameother: "邱县",
          },
          {
            fldEnumId: 13793,
            enumGroupGuid: "1",
            parentEnumValue: "1304",
            enumValue: "130431",
            enumName: "鸡泽县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 61,
            enumNameother: "鸡泽县",
          },
          {
            fldEnumId: 13797,
            enumGroupGuid: "1",
            parentEnumValue: "1304",
            enumValue: "130432",
            enumName: "广平县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 62,
            enumNameother: "广平县",
          },
          {
            fldEnumId: 13801,
            enumGroupGuid: "1",
            parentEnumValue: "1304",
            enumValue: "130433",
            enumName: "馆陶县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 63,
            enumNameother: "馆陶县",
          },
          {
            fldEnumId: 13805,
            enumGroupGuid: "1",
            parentEnumValue: "1304",
            enumValue: "130434",
            enumName: "魏县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 64,
            enumNameother: "魏县",
          },
          {
            fldEnumId: 13809,
            enumGroupGuid: "1",
            parentEnumValue: "1304",
            enumValue: "130435",
            enumName: "曲周县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 65,
            enumNameother: "曲周县",
          },
          {
            fldEnumId: 13813,
            enumGroupGuid: "1",
            parentEnumValue: "1304",
            enumValue: "130481",
            enumName: "武安市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 66,
            enumNameother: "武安市",
          },
        ],
      },
      {
        fldEnumId: 13817,
        enumGroupGuid: "1",
        parentEnumValue: "13",
        enumValue: "1305",
        enumName: "邢台市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 4,
        enumNameother: "邢台市",
        children: [
          {
            fldEnumId: 13819,
            enumGroupGuid: "1",
            parentEnumValue: "1305",
            enumValue: "130500",
            enumName: "邢台市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 67,
            enumNameother: "邢台市",
          },
          {
            fldEnumId: 13823,
            enumGroupGuid: "1",
            parentEnumValue: "1305",
            enumValue: "130502",
            enumName: "桥东区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 68,
            enumNameother: "桥东区",
          },
          {
            fldEnumId: 13826,
            enumGroupGuid: "1",
            parentEnumValue: "1305",
            enumValue: "130503",
            enumName: "桥西区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 69,
            enumNameother: "桥西区",
          },
          {
            fldEnumId: 13830,
            enumGroupGuid: "1",
            parentEnumValue: "1305",
            enumValue: "130521",
            enumName: "邢台县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 70,
            enumNameother: "邢台县",
          },
          {
            fldEnumId: 13834,
            enumGroupGuid: "1",
            parentEnumValue: "1305",
            enumValue: "130522",
            enumName: "临城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 71,
            enumNameother: "临城县",
          },
          {
            fldEnumId: 13839,
            enumGroupGuid: "1",
            parentEnumValue: "1305",
            enumValue: "130523",
            enumName: "内丘县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 72,
            enumNameother: "内丘县",
          },
          {
            fldEnumId: 13843,
            enumGroupGuid: "1",
            parentEnumValue: "1305",
            enumValue: "130524",
            enumName: "柏乡县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 73,
            enumNameother: "柏乡县",
          },
          {
            fldEnumId: 13847,
            enumGroupGuid: "1",
            parentEnumValue: "1305",
            enumValue: "130525",
            enumName: "隆尧县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 74,
            enumNameother: "隆尧县",
          },
          {
            fldEnumId: 13851,
            enumGroupGuid: "1",
            parentEnumValue: "1305",
            enumValue: "130526",
            enumName: "任县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 75,
            enumNameother: "任县",
          },
          {
            fldEnumId: 13855,
            enumGroupGuid: "1",
            parentEnumValue: "1305",
            enumValue: "130527",
            enumName: "南和县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 76,
            enumNameother: "南和县",
          },
          {
            fldEnumId: 13859,
            enumGroupGuid: "1",
            parentEnumValue: "1305",
            enumValue: "130528",
            enumName: "宁晋县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 77,
            enumNameother: "宁晋县",
          },
          {
            fldEnumId: 13863,
            enumGroupGuid: "1",
            parentEnumValue: "1305",
            enumValue: "130529",
            enumName: "巨鹿县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 78,
            enumNameother: "巨鹿县",
          },
          {
            fldEnumId: 13867,
            enumGroupGuid: "1",
            parentEnumValue: "1305",
            enumValue: "130530",
            enumName: "新河县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 79,
            enumNameother: "新河县",
          },
          {
            fldEnumId: 13871,
            enumGroupGuid: "1",
            parentEnumValue: "1305",
            enumValue: "130531",
            enumName: "广宗县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 80,
            enumNameother: "广宗县",
          },
          {
            fldEnumId: 13875,
            enumGroupGuid: "1",
            parentEnumValue: "1305",
            enumValue: "130532",
            enumName: "平乡县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 81,
            enumNameother: "平乡县",
          },
          {
            fldEnumId: 13879,
            enumGroupGuid: "1",
            parentEnumValue: "1305",
            enumValue: "130533",
            enumName: "威县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 82,
            enumNameother: "威县",
          },
          {
            fldEnumId: 13883,
            enumGroupGuid: "1",
            parentEnumValue: "1305",
            enumValue: "130534",
            enumName: "清河县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 83,
            enumNameother: "清河县",
          },
          {
            fldEnumId: 13887,
            enumGroupGuid: "1",
            parentEnumValue: "1305",
            enumValue: "130535",
            enumName: "临西县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 84,
            enumNameother: "临西县",
          },
          {
            fldEnumId: 13891,
            enumGroupGuid: "1",
            parentEnumValue: "1305",
            enumValue: "130581",
            enumName: "南宫市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 85,
            enumNameother: "南宫市",
          },
          {
            fldEnumId: 13895,
            enumGroupGuid: "1",
            parentEnumValue: "1305",
            enumValue: "130582",
            enumName: "沙河市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 86,
            enumNameother: "沙河市",
          },
        ],
      },
      {
        fldEnumId: 13899,
        enumGroupGuid: "1",
        parentEnumValue: "13",
        enumValue: "1306",
        enumName: "保定市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 5,
        enumNameother: "保定市",
        children: [
          {
            fldEnumId: 13901,
            enumGroupGuid: "1",
            parentEnumValue: "1306",
            enumValue: "130600",
            enumName: "保定市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 87,
            enumNameother: "保定市",
          },
          {
            fldEnumId: 13905,
            enumGroupGuid: "1",
            parentEnumValue: "1306",
            enumValue: "130602",
            enumName: "新市区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 88,
            enumNameother: "新市区",
          },
          {
            fldEnumId: 13909,
            enumGroupGuid: "1",
            parentEnumValue: "1306",
            enumValue: "130603",
            enumName: "北市区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 89,
            enumNameother: "北市区",
          },
          {
            fldEnumId: 13913,
            enumGroupGuid: "1",
            parentEnumValue: "1306",
            enumValue: "130604",
            enumName: "南市区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 90,
            enumNameother: "南市区",
          },
          {
            fldEnumId: 13917,
            enumGroupGuid: "1",
            parentEnumValue: "1306",
            enumValue: "130621",
            enumName: "满城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 91,
            enumNameother: "满城县",
          },
          {
            fldEnumId: 13921,
            enumGroupGuid: "1",
            parentEnumValue: "1306",
            enumValue: "130622",
            enumName: "清苑县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 92,
            enumNameother: "清苑县",
          },
          {
            fldEnumId: 13925,
            enumGroupGuid: "1",
            parentEnumValue: "1306",
            enumValue: "130623",
            enumName: "涞水县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 93,
            enumNameother: "涞水县",
          },
          {
            fldEnumId: 13929,
            enumGroupGuid: "1",
            parentEnumValue: "1306",
            enumValue: "130624",
            enumName: "阜平县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 94,
            enumNameother: "阜平县",
          },
          {
            fldEnumId: 13933,
            enumGroupGuid: "1",
            parentEnumValue: "1306",
            enumValue: "130625",
            enumName: "徐水县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 95,
            enumNameother: "徐水县",
          },
          {
            fldEnumId: 13937,
            enumGroupGuid: "1",
            parentEnumValue: "1306",
            enumValue: "130626",
            enumName: "定兴县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 96,
            enumNameother: "定兴县",
          },
          {
            fldEnumId: 13941,
            enumGroupGuid: "1",
            parentEnumValue: "1306",
            enumValue: "130627",
            enumName: "唐县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 97,
            enumNameother: "唐县",
          },
          {
            fldEnumId: 13945,
            enumGroupGuid: "1",
            parentEnumValue: "1306",
            enumValue: "130628",
            enumName: "高阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 98,
            enumNameother: "高阳县",
          },
          {
            fldEnumId: 13949,
            enumGroupGuid: "1",
            parentEnumValue: "1306",
            enumValue: "130629",
            enumName: "容城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 99,
            enumNameother: "容城县",
          },
          {
            fldEnumId: 13953,
            enumGroupGuid: "1",
            parentEnumValue: "1306",
            enumValue: "130630",
            enumName: "涞源县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 100,
            enumNameother: "涞源县",
          },
          {
            fldEnumId: 13957,
            enumGroupGuid: "1",
            parentEnumValue: "1306",
            enumValue: "130631",
            enumName: "望都县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 101,
            enumNameother: "望都县",
          },
          {
            fldEnumId: 13961,
            enumGroupGuid: "1",
            parentEnumValue: "1306",
            enumValue: "130632",
            enumName: "安新县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 102,
            enumNameother: "安新县",
          },
          {
            fldEnumId: 13965,
            enumGroupGuid: "1",
            parentEnumValue: "1306",
            enumValue: "130633",
            enumName: "易县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 103,
            enumNameother: "易县",
          },
          {
            fldEnumId: 13968,
            enumGroupGuid: "1",
            parentEnumValue: "1306",
            enumValue: "130634",
            enumName: "曲阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 104,
            enumNameother: "曲阳县",
          },
          {
            fldEnumId: 13972,
            enumGroupGuid: "1",
            parentEnumValue: "1306",
            enumValue: "130635",
            enumName: "蠡县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 105,
            enumNameother: "蠡县",
          },
          {
            fldEnumId: 13976,
            enumGroupGuid: "1",
            parentEnumValue: "1306",
            enumValue: "130636",
            enumName: "顺平县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 106,
            enumNameother: "顺平县",
          },
          {
            fldEnumId: 13981,
            enumGroupGuid: "1",
            parentEnumValue: "1306",
            enumValue: "130637",
            enumName: "博野县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 107,
            enumNameother: "博野县",
          },
          {
            fldEnumId: 13985,
            enumGroupGuid: "1",
            parentEnumValue: "1306",
            enumValue: "130638",
            enumName: "雄县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 108,
            enumNameother: "雄县",
          },
          {
            fldEnumId: 13989,
            enumGroupGuid: "1",
            parentEnumValue: "1306",
            enumValue: "130681",
            enumName: "涿州市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 109,
            enumNameother: "涿州市",
          },
          {
            fldEnumId: 13992,
            enumGroupGuid: "1",
            parentEnumValue: "1306",
            enumValue: "130682",
            enumName: "定州市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 110,
            enumNameother: "定州市",
          },
          {
            fldEnumId: 13996,
            enumGroupGuid: "1",
            parentEnumValue: "1306",
            enumValue: "130683",
            enumName: "安国市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 111,
            enumNameother: "安国市",
          },
          {
            fldEnumId: 14000,
            enumGroupGuid: "1",
            parentEnumValue: "1306",
            enumValue: "130684",
            enumName: "高碑店市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 112,
            enumNameother: "高碑店市",
          },
        ],
      },
      {
        fldEnumId: 14004,
        enumGroupGuid: "1",
        parentEnumValue: "13",
        enumValue: "1307",
        enumName: "张家口市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 6,
        enumNameother: "张家口市",
        children: [
          {
            fldEnumId: 14005,
            enumGroupGuid: "1",
            parentEnumValue: "1307",
            enumValue: "130700",
            enumName: "张家口市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 113,
            enumNameother: "张家口市",
          },
          {
            fldEnumId: 14009,
            enumGroupGuid: "1",
            parentEnumValue: "1307",
            enumValue: "130702",
            enumName: "桥东区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 114,
            enumNameother: "桥东区",
          },
          {
            fldEnumId: 14013,
            enumGroupGuid: "1",
            parentEnumValue: "1307",
            enumValue: "130703",
            enumName: "桥西区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 115,
            enumNameother: "桥西区",
          },
          {
            fldEnumId: 14017,
            enumGroupGuid: "1",
            parentEnumValue: "1307",
            enumValue: "130705",
            enumName: "宣化区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 116,
            enumNameother: "宣化区",
          },
          {
            fldEnumId: 14021,
            enumGroupGuid: "1",
            parentEnumValue: "1307",
            enumValue: "130706",
            enumName: "下花园区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 117,
            enumNameother: "下花园区",
          },
          {
            fldEnumId: 14025,
            enumGroupGuid: "1",
            parentEnumValue: "1307",
            enumValue: "130721",
            enumName: "宣化县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 118,
            enumNameother: "宣化县",
          },
          {
            fldEnumId: 14029,
            enumGroupGuid: "1",
            parentEnumValue: "1307",
            enumValue: "130722",
            enumName: "张北县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 119,
            enumNameother: "张北县",
          },
          {
            fldEnumId: 14033,
            enumGroupGuid: "1",
            parentEnumValue: "1307",
            enumValue: "130723",
            enumName: "康保县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 120,
            enumNameother: "康保县",
          },
          {
            fldEnumId: 14036,
            enumGroupGuid: "1",
            parentEnumValue: "1307",
            enumValue: "130724",
            enumName: "沽源县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 121,
            enumNameother: "沽源县",
          },
          {
            fldEnumId: 14040,
            enumGroupGuid: "1",
            parentEnumValue: "1307",
            enumValue: "130725",
            enumName: "尚义县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 122,
            enumNameother: "尚义县",
          },
          {
            fldEnumId: 14044,
            enumGroupGuid: "1",
            parentEnumValue: "1307",
            enumValue: "130726",
            enumName: "蔚县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 123,
            enumNameother: "蔚县",
          },
          {
            fldEnumId: 14048,
            enumGroupGuid: "1",
            parentEnumValue: "1307",
            enumValue: "130727",
            enumName: "阳原县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 124,
            enumNameother: "阳原县",
          },
          {
            fldEnumId: 14052,
            enumGroupGuid: "1",
            parentEnumValue: "1307",
            enumValue: "130728",
            enumName: "怀安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 125,
            enumNameother: "怀安县",
          },
          {
            fldEnumId: 14055,
            enumGroupGuid: "1",
            parentEnumValue: "1307",
            enumValue: "130729",
            enumName: "万全县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 126,
            enumNameother: "万全县",
          },
          {
            fldEnumId: 14059,
            enumGroupGuid: "1",
            parentEnumValue: "1307",
            enumValue: "130730",
            enumName: "怀来县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 127,
            enumNameother: "怀来县",
          },
          {
            fldEnumId: 14063,
            enumGroupGuid: "1",
            parentEnumValue: "1307",
            enumValue: "130731",
            enumName: "涿鹿县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 128,
            enumNameother: "涿鹿县",
          },
          {
            fldEnumId: 14067,
            enumGroupGuid: "1",
            parentEnumValue: "1307",
            enumValue: "130732",
            enumName: "赤城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 129,
            enumNameother: "赤城县",
          },
          {
            fldEnumId: 14071,
            enumGroupGuid: "1",
            parentEnumValue: "1307",
            enumValue: "130733",
            enumName: "崇礼县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 130,
            enumNameother: "崇礼县",
          },
        ],
      },
      {
        fldEnumId: 14074,
        enumGroupGuid: "1",
        parentEnumValue: "13",
        enumValue: "1308",
        enumName: "承德市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 7,
        enumNameother: "承德市",
        children: [
          {
            fldEnumId: 14076,
            enumGroupGuid: "1",
            parentEnumValue: "1308",
            enumValue: "130800",
            enumName: "承德市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 131,
            enumNameother: "承德市",
          },
          {
            fldEnumId: 14080,
            enumGroupGuid: "1",
            parentEnumValue: "1308",
            enumValue: "130802",
            enumName: "双桥区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 132,
            enumNameother: "双桥区",
          },
          {
            fldEnumId: 14084,
            enumGroupGuid: "1",
            parentEnumValue: "1308",
            enumValue: "130803",
            enumName: "双滦区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 133,
            enumNameother: "双滦区",
          },
          {
            fldEnumId: 14088,
            enumGroupGuid: "1",
            parentEnumValue: "1308",
            enumValue: "130804",
            enumName: "鹰手营子矿区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 134,
            enumNameother: "鹰手营子矿区",
          },
          {
            fldEnumId: 14091,
            enumGroupGuid: "1",
            parentEnumValue: "1308",
            enumValue: "130821",
            enumName: "承德县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 135,
            enumNameother: "承德县",
          },
          {
            fldEnumId: 14095,
            enumGroupGuid: "1",
            parentEnumValue: "1308",
            enumValue: "130822",
            enumName: "兴隆县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 136,
            enumNameother: "兴隆县",
          },
          {
            fldEnumId: 14099,
            enumGroupGuid: "1",
            parentEnumValue: "1308",
            enumValue: "130823",
            enumName: "平泉县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 137,
            enumNameother: "平泉县",
          },
          {
            fldEnumId: 14103,
            enumGroupGuid: "1",
            parentEnumValue: "1308",
            enumValue: "130824",
            enumName: "滦平县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 138,
            enumNameother: "滦平县",
          },
          {
            fldEnumId: 14107,
            enumGroupGuid: "1",
            parentEnumValue: "1308",
            enumValue: "130825",
            enumName: "隆化县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 139,
            enumNameother: "隆化县",
          },
          {
            fldEnumId: 14111,
            enumGroupGuid: "1",
            parentEnumValue: "1308",
            enumValue: "130826",
            enumName: "丰宁满族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 140,
            enumNameother: "丰宁满族自治县",
          },
          {
            fldEnumId: 14115,
            enumGroupGuid: "1",
            parentEnumValue: "1308",
            enumValue: "130827",
            enumName: "宽城满族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 141,
            enumNameother: "宽城满族自治县",
          },
          {
            fldEnumId: 14119,
            enumGroupGuid: "1",
            parentEnumValue: "1308",
            enumValue: "130828",
            enumName: "围场满族蒙古族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 142,
            enumNameother: "围场满族蒙古族自治县",
          },
        ],
      },
      {
        fldEnumId: 14123,
        enumGroupGuid: "1",
        parentEnumValue: "13",
        enumValue: "1309",
        enumName: "沧州市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 8,
        enumNameother: "沧州市",
        children: [
          {
            fldEnumId: 14125,
            enumGroupGuid: "1",
            parentEnumValue: "1309",
            enumValue: "130900",
            enumName: "沧州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 143,
            enumNameother: "沧州市",
          },
          {
            fldEnumId: 14130,
            enumGroupGuid: "1",
            parentEnumValue: "1309",
            enumValue: "130902",
            enumName: "新华区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 144,
            enumNameother: "新华区",
          },
          {
            fldEnumId: 14134,
            enumGroupGuid: "1",
            parentEnumValue: "1309",
            enumValue: "130903",
            enumName: "运河区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 145,
            enumNameother: "运河区",
          },
          {
            fldEnumId: 14138,
            enumGroupGuid: "1",
            parentEnumValue: "1309",
            enumValue: "130921",
            enumName: "沧县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 146,
            enumNameother: "沧县",
          },
          {
            fldEnumId: 14142,
            enumGroupGuid: "1",
            parentEnumValue: "1309",
            enumValue: "130922",
            enumName: "青县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 147,
            enumNameother: "青县",
          },
          {
            fldEnumId: 14146,
            enumGroupGuid: "1",
            parentEnumValue: "1309",
            enumValue: "130923",
            enumName: "东光县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 148,
            enumNameother: "东光县",
          },
          {
            fldEnumId: 14150,
            enumGroupGuid: "1",
            parentEnumValue: "1309",
            enumValue: "130924",
            enumName: "海兴县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 149,
            enumNameother: "海兴县",
          },
          {
            fldEnumId: 14155,
            enumGroupGuid: "1",
            parentEnumValue: "1309",
            enumValue: "130925",
            enumName: "盐山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 150,
            enumNameother: "盐山县",
          },
          {
            fldEnumId: 14160,
            enumGroupGuid: "1",
            parentEnumValue: "1309",
            enumValue: "130926",
            enumName: "肃宁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 151,
            enumNameother: "肃宁县",
          },
          {
            fldEnumId: 14163,
            enumGroupGuid: "1",
            parentEnumValue: "1309",
            enumValue: "130927",
            enumName: "南皮县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 152,
            enumNameother: "南皮县",
          },
          {
            fldEnumId: 14167,
            enumGroupGuid: "1",
            parentEnumValue: "1309",
            enumValue: "130928",
            enumName: "吴桥县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 153,
            enumNameother: "吴桥县",
          },
          {
            fldEnumId: 14172,
            enumGroupGuid: "1",
            parentEnumValue: "1309",
            enumValue: "130929",
            enumName: "献县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 154,
            enumNameother: "献县",
          },
          {
            fldEnumId: 14176,
            enumGroupGuid: "1",
            parentEnumValue: "1309",
            enumValue: "130930",
            enumName: "孟村回族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 155,
            enumNameother: "孟村回族自治县",
          },
          {
            fldEnumId: 14180,
            enumGroupGuid: "1",
            parentEnumValue: "1309",
            enumValue: "130981",
            enumName: "泊头市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 156,
            enumNameother: "泊头市",
          },
          {
            fldEnumId: 14184,
            enumGroupGuid: "1",
            parentEnumValue: "1309",
            enumValue: "130982",
            enumName: "任丘市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 157,
            enumNameother: "任丘市",
          },
          {
            fldEnumId: 14187,
            enumGroupGuid: "1",
            parentEnumValue: "1309",
            enumValue: "130983",
            enumName: "黄骅市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 158,
            enumNameother: "黄骅市",
          },
          {
            fldEnumId: 14191,
            enumGroupGuid: "1",
            parentEnumValue: "1309",
            enumValue: "130984",
            enumName: "河间市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 159,
            enumNameother: "河间市",
          },
        ],
      },
      {
        fldEnumId: 14195,
        enumGroupGuid: "1",
        parentEnumValue: "13",
        enumValue: "1310",
        enumName: "廊坊市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 9,
        enumNameother: "廊坊市",
        children: [
          {
            fldEnumId: 14197,
            enumGroupGuid: "1",
            parentEnumValue: "1310",
            enumValue: "131000",
            enumName: "廊坊市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 160,
            enumNameother: "廊坊市",
          },
          {
            fldEnumId: 14202,
            enumGroupGuid: "1",
            parentEnumValue: "1310",
            enumValue: "131002",
            enumName: "安次区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 161,
            enumNameother: "安次区",
          },
          {
            fldEnumId: 14206,
            enumGroupGuid: "1",
            parentEnumValue: "1310",
            enumValue: "131003",
            enumName: "广阳区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 162,
            enumNameother: "广阳区",
          },
          {
            fldEnumId: 14209,
            enumGroupGuid: "1",
            parentEnumValue: "1310",
            enumValue: "131022",
            enumName: "固安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 163,
            enumNameother: "固安县",
          },
          {
            fldEnumId: 14213,
            enumGroupGuid: "1",
            parentEnumValue: "1310",
            enumValue: "131023",
            enumName: "永清县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 164,
            enumNameother: "永清县",
          },
          {
            fldEnumId: 14217,
            enumGroupGuid: "1",
            parentEnumValue: "1310",
            enumValue: "131024",
            enumName: "香河县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 165,
            enumNameother: "香河县",
          },
          {
            fldEnumId: 14221,
            enumGroupGuid: "1",
            parentEnumValue: "1310",
            enumValue: "131025",
            enumName: "大城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 166,
            enumNameother: "大城县",
          },
          {
            fldEnumId: 14225,
            enumGroupGuid: "1",
            parentEnumValue: "1310",
            enumValue: "131026",
            enumName: "文安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 167,
            enumNameother: "文安县",
          },
          {
            fldEnumId: 14228,
            enumGroupGuid: "1",
            parentEnumValue: "1310",
            enumValue: "131028",
            enumName: "大厂回族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 168,
            enumNameother: "大厂回族自治县",
          },
          {
            fldEnumId: 14232,
            enumGroupGuid: "1",
            parentEnumValue: "1310",
            enumValue: "131081",
            enumName: "霸州市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 169,
            enumNameother: "霸州市",
          },
          {
            fldEnumId: 14237,
            enumGroupGuid: "1",
            parentEnumValue: "1310",
            enumValue: "131082",
            enumName: "三河市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 170,
            enumNameother: "三河市",
          },
        ],
      },
      {
        fldEnumId: 14241,
        enumGroupGuid: "1",
        parentEnumValue: "13",
        enumValue: "1311",
        enumName: "衡水市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 10,
        enumNameother: "衡水市",
        children: [
          {
            fldEnumId: 14243,
            enumGroupGuid: "1",
            parentEnumValue: "1311",
            enumValue: "131100",
            enumName: "衡水市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 171,
            enumNameother: "衡水市",
          },
          {
            fldEnumId: 14247,
            enumGroupGuid: "1",
            parentEnumValue: "1311",
            enumValue: "131102",
            enumName: "桃城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 172,
            enumNameother: "桃城区",
          },
          {
            fldEnumId: 14250,
            enumGroupGuid: "1",
            parentEnumValue: "1311",
            enumValue: "131121",
            enumName: "枣强县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 173,
            enumNameother: "枣强县",
          },
          {
            fldEnumId: 14254,
            enumGroupGuid: "1",
            parentEnumValue: "1311",
            enumValue: "131122",
            enumName: "武邑县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 174,
            enumNameother: "武邑县",
          },
          {
            fldEnumId: 14257,
            enumGroupGuid: "1",
            parentEnumValue: "1311",
            enumValue: "131123",
            enumName: "武强县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 175,
            enumNameother: "武强县",
          },
          {
            fldEnumId: 14261,
            enumGroupGuid: "1",
            parentEnumValue: "1311",
            enumValue: "131124",
            enumName: "饶阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 176,
            enumNameother: "饶阳县",
          },
          {
            fldEnumId: 14265,
            enumGroupGuid: "1",
            parentEnumValue: "1311",
            enumValue: "131125",
            enumName: "安平县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 177,
            enumNameother: "安平县",
          },
          {
            fldEnumId: 14269,
            enumGroupGuid: "1",
            parentEnumValue: "1311",
            enumValue: "131126",
            enumName: "故城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 178,
            enumNameother: "故城县",
          },
          {
            fldEnumId: 14274,
            enumGroupGuid: "1",
            parentEnumValue: "1311",
            enumValue: "131127",
            enumName: "景县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 179,
            enumNameother: "景县",
          },
          {
            fldEnumId: 14278,
            enumGroupGuid: "1",
            parentEnumValue: "1311",
            enumValue: "131128",
            enumName: "阜城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 180,
            enumNameother: "阜城县",
          },
          {
            fldEnumId: 14281,
            enumGroupGuid: "1",
            parentEnumValue: "1311",
            enumValue: "131181",
            enumName: "冀州市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 181,
            enumNameother: "冀州市",
          },
          {
            fldEnumId: 14285,
            enumGroupGuid: "1",
            parentEnumValue: "1311",
            enumValue: "131182",
            enumName: "深州市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 182,
            enumNameother: "深州市",
          },
        ],
      },
    ],
  },
  {
    enumValue: "14",
    enumName: "山西省",
    children: [
      {
        fldEnumId: 14291,
        enumGroupGuid: "1",
        parentEnumValue: "14",
        enumValue: "1401",
        enumName: "太原市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        enumNameother: "太原市",
        children: [
          {
            fldEnumId: 14292,
            enumGroupGuid: "1",
            parentEnumValue: "1401",
            enumValue: "140100",
            enumName: "太原市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
            enumNameother: "太原市",
          },
          {
            fldEnumId: 14296,
            enumGroupGuid: "1",
            parentEnumValue: "1401",
            enumValue: "140105",
            enumName: "小店区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "小店区",
          },
          {
            fldEnumId: 14300,
            enumGroupGuid: "1",
            parentEnumValue: "1401",
            enumValue: "140106",
            enumName: "迎泽区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 2,
            enumNameother: "迎泽区",
          },
          {
            fldEnumId: 14304,
            enumGroupGuid: "1",
            parentEnumValue: "1401",
            enumValue: "140107",
            enumName: "杏花岭区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 3,
            enumNameother: "杏花岭区",
          },
          {
            fldEnumId: 14308,
            enumGroupGuid: "1",
            parentEnumValue: "1401",
            enumValue: "140108",
            enumName: "尖草坪区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 4,
            enumNameother: "尖草坪区",
          },
          {
            fldEnumId: 14312,
            enumGroupGuid: "1",
            parentEnumValue: "1401",
            enumValue: "140109",
            enumName: "万柏林区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 5,
            enumNameother: "万柏林区",
          },
          {
            fldEnumId: 14316,
            enumGroupGuid: "1",
            parentEnumValue: "1401",
            enumValue: "140110",
            enumName: "晋源区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 6,
            enumNameother: "晋源区",
          },
          {
            fldEnumId: 14320,
            enumGroupGuid: "1",
            parentEnumValue: "1401",
            enumValue: "140121",
            enumName: "清徐县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 7,
            enumNameother: "清徐县",
          },
          {
            fldEnumId: 14324,
            enumGroupGuid: "1",
            parentEnumValue: "1401",
            enumValue: "140122",
            enumName: "阳曲县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 8,
            enumNameother: "阳曲县",
          },
          {
            fldEnumId: 14328,
            enumGroupGuid: "1",
            parentEnumValue: "1401",
            enumValue: "140123",
            enumName: "娄烦县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 9,
            enumNameother: "娄烦县",
          },
          {
            fldEnumId: 14332,
            enumGroupGuid: "1",
            parentEnumValue: "1401",
            enumValue: "140181",
            enumName: "古交市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 10,
            enumNameother: "古交市",
          },
        ],
      },
      {
        fldEnumId: 14336,
        enumGroupGuid: "1",
        parentEnumValue: "14",
        enumValue: "1402",
        enumName: "大同市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 1,
        enumNameother: "大同市",
        children: [
          {
            fldEnumId: 130324,
            enumGroupGuid: "1",
            parentEnumValue: "1402",
            enumValue: "140215",
            enumName: "云州区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 14338,
            enumGroupGuid: "1",
            parentEnumValue: "1402",
            enumValue: "140200",
            enumName: "大同市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 11,
            enumNameother: "大同市",
          },
          {
            fldEnumId: 14342,
            enumGroupGuid: "1",
            parentEnumValue: "1402",
            enumValue: "140202",
            enumName: "城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 12,
            enumNameother: "城区",
          },
          {
            fldEnumId: 14346,
            enumGroupGuid: "1",
            parentEnumValue: "1402",
            enumValue: "140203",
            enumName: "矿区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 13,
            enumNameother: "矿区",
          },
          {
            fldEnumId: 14349,
            enumGroupGuid: "1",
            parentEnumValue: "1402",
            enumValue: "140211",
            enumName: "南郊区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 14,
            enumNameother: "南郊区",
          },
          {
            fldEnumId: 14353,
            enumGroupGuid: "1",
            parentEnumValue: "1402",
            enumValue: "140212",
            enumName: "新荣区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 15,
            enumNameother: "新荣区",
          },
          {
            fldEnumId: 14357,
            enumGroupGuid: "1",
            parentEnumValue: "1402",
            enumValue: "140221",
            enumName: "阳高县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 16,
            enumNameother: "阳高县",
          },
          {
            fldEnumId: 14362,
            enumGroupGuid: "1",
            parentEnumValue: "1402",
            enumValue: "140222",
            enumName: "天镇县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 17,
            enumNameother: "天镇县",
          },
          {
            fldEnumId: 14366,
            enumGroupGuid: "1",
            parentEnumValue: "1402",
            enumValue: "140223",
            enumName: "广灵县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 18,
            enumNameother: "广灵县",
          },
          {
            fldEnumId: 14370,
            enumGroupGuid: "1",
            parentEnumValue: "1402",
            enumValue: "140224",
            enumName: "灵丘县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 19,
            enumNameother: "灵丘县",
          },
          {
            fldEnumId: 14374,
            enumGroupGuid: "1",
            parentEnumValue: "1402",
            enumValue: "140225",
            enumName: "浑源县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 20,
            enumNameother: "浑源县",
          },
          {
            fldEnumId: 14378,
            enumGroupGuid: "1",
            parentEnumValue: "1402",
            enumValue: "140226",
            enumName: "左云县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 21,
            enumNameother: "左云县",
          },
          {
            fldEnumId: 14382,
            enumGroupGuid: "1",
            parentEnumValue: "1402",
            enumValue: "140227",
            enumName: "大同县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 22,
            enumNameother: "大同县",
          },
        ],
      },
      {
        fldEnumId: 14386,
        enumGroupGuid: "1",
        parentEnumValue: "14",
        enumValue: "1403",
        enumName: "阳泉市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 2,
        enumNameother: "阳泉市",
        children: [
          {
            fldEnumId: 14388,
            enumGroupGuid: "1",
            parentEnumValue: "1403",
            enumValue: "140300",
            enumName: "阳泉市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 23,
            enumNameother: "阳泉市",
          },
          {
            fldEnumId: 14392,
            enumGroupGuid: "1",
            parentEnumValue: "1403",
            enumValue: "140302",
            enumName: "城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 24,
            enumNameother: "城区",
          },
          {
            fldEnumId: 14396,
            enumGroupGuid: "1",
            parentEnumValue: "1403",
            enumValue: "140303",
            enumName: "矿区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 25,
            enumNameother: "矿区",
          },
          {
            fldEnumId: 14400,
            enumGroupGuid: "1",
            parentEnumValue: "1403",
            enumValue: "140311",
            enumName: "郊区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 26,
            enumNameother: "郊区",
          },
          {
            fldEnumId: 14404,
            enumGroupGuid: "1",
            parentEnumValue: "1403",
            enumValue: "140321",
            enumName: "平定县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 27,
            enumNameother: "平定县",
          },
          {
            fldEnumId: 14408,
            enumGroupGuid: "1",
            parentEnumValue: "1403",
            enumValue: "140322",
            enumName: "盂县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 28,
            enumNameother: "盂县",
          },
        ],
      },
      {
        fldEnumId: 14412,
        enumGroupGuid: "1",
        parentEnumValue: "14",
        enumValue: "1404",
        enumName: "长治市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 3,
        enumNameother: "长治市",
        children: [
          {
            fldEnumId: 14414,
            enumGroupGuid: "1",
            parentEnumValue: "1404",
            enumValue: "140400",
            enumName: "长治市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 29,
            enumNameother: "长治市",
          },
          {
            fldEnumId: 14418,
            enumGroupGuid: "1",
            parentEnumValue: "1404",
            enumValue: "140402",
            enumName: "城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 30,
            enumNameother: "城区",
          },
          {
            fldEnumId: 14422,
            enumGroupGuid: "1",
            parentEnumValue: "1404",
            enumValue: "140411",
            enumName: "郊区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 31,
            enumNameother: "郊区",
          },
          {
            fldEnumId: 14426,
            enumGroupGuid: "1",
            parentEnumValue: "1404",
            enumValue: "140421",
            enumName: "长治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 32,
            enumNameother: "长治县",
          },
          {
            fldEnumId: 14430,
            enumGroupGuid: "1",
            parentEnumValue: "1404",
            enumValue: "140423",
            enumName: "襄垣县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 33,
            enumNameother: "襄垣县",
          },
          {
            fldEnumId: 14434,
            enumGroupGuid: "1",
            parentEnumValue: "1404",
            enumValue: "140424",
            enumName: "屯留县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 34,
            enumNameother: "屯留县",
          },
          {
            fldEnumId: 14438,
            enumGroupGuid: "1",
            parentEnumValue: "1404",
            enumValue: "140425",
            enumName: "平顺县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 35,
            enumNameother: "平顺县",
          },
          {
            fldEnumId: 14442,
            enumGroupGuid: "1",
            parentEnumValue: "1404",
            enumValue: "140426",
            enumName: "黎城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 36,
            enumNameother: "黎城县",
          },
          {
            fldEnumId: 14446,
            enumGroupGuid: "1",
            parentEnumValue: "1404",
            enumValue: "140427",
            enumName: "壶关县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 37,
            enumNameother: "壶关县",
          },
          {
            fldEnumId: 14450,
            enumGroupGuid: "1",
            parentEnumValue: "1404",
            enumValue: "140428",
            enumName: "长子县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 38,
            enumNameother: "长子县",
          },
          {
            fldEnumId: 14454,
            enumGroupGuid: "1",
            parentEnumValue: "1404",
            enumValue: "140429",
            enumName: "武乡县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 39,
            enumNameother: "武乡县",
          },
          {
            fldEnumId: 14458,
            enumGroupGuid: "1",
            parentEnumValue: "1404",
            enumValue: "140430",
            enumName: "沁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 40,
            enumNameother: "沁县",
          },
          {
            fldEnumId: 14462,
            enumGroupGuid: "1",
            parentEnumValue: "1404",
            enumValue: "140431",
            enumName: "沁源县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 41,
            enumNameother: "沁源县",
          },
          {
            fldEnumId: 14466,
            enumGroupGuid: "1",
            parentEnumValue: "1404",
            enumValue: "140481",
            enumName: "潞城市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 42,
            enumNameother: "潞城市",
          },
        ],
      },
      {
        fldEnumId: 14470,
        enumGroupGuid: "1",
        parentEnumValue: "14",
        enumValue: "1405",
        enumName: "晋城市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 4,
        enumNameother: "晋城市",
        children: [
          {
            fldEnumId: 14472,
            enumGroupGuid: "1",
            parentEnumValue: "1405",
            enumValue: "140500",
            enumName: "晋城市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 43,
            enumNameother: "晋城市",
          },
          {
            fldEnumId: 14476,
            enumGroupGuid: "1",
            parentEnumValue: "1405",
            enumValue: "140502",
            enumName: "城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 44,
            enumNameother: "城区",
          },
          {
            fldEnumId: 14480,
            enumGroupGuid: "1",
            parentEnumValue: "1405",
            enumValue: "140521",
            enumName: "沁水县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 45,
            enumNameother: "沁水县",
          },
          {
            fldEnumId: 14484,
            enumGroupGuid: "1",
            parentEnumValue: "1405",
            enumValue: "140522",
            enumName: "阳城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 46,
            enumNameother: "阳城县",
          },
          {
            fldEnumId: 14488,
            enumGroupGuid: "1",
            parentEnumValue: "1405",
            enumValue: "140524",
            enumName: "陵川县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 47,
            enumNameother: "陵川县",
          },
          {
            fldEnumId: 14492,
            enumGroupGuid: "1",
            parentEnumValue: "1405",
            enumValue: "140525",
            enumName: "泽州县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 48,
            enumNameother: "泽州县",
          },
          {
            fldEnumId: 14496,
            enumGroupGuid: "1",
            parentEnumValue: "1405",
            enumValue: "140581",
            enumName: "高平市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 49,
            enumNameother: "高平市",
          },
        ],
      },
      {
        fldEnumId: 14500,
        enumGroupGuid: "1",
        parentEnumValue: "14",
        enumValue: "1406",
        enumName: "朔州市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 5,
        enumNameother: "朔州市",
        children: [
          {
            fldEnumId: 14502,
            enumGroupGuid: "1",
            parentEnumValue: "1406",
            enumValue: "140600",
            enumName: "朔州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 50,
            enumNameother: "朔州市",
          },
          {
            fldEnumId: 14506,
            enumGroupGuid: "1",
            parentEnumValue: "1406",
            enumValue: "140602",
            enumName: "朔城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 51,
            enumNameother: "朔城区",
          },
          {
            fldEnumId: 14510,
            enumGroupGuid: "1",
            parentEnumValue: "1406",
            enumValue: "140603",
            enumName: "平鲁区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 52,
            enumNameother: "平鲁区",
          },
          {
            fldEnumId: 14514,
            enumGroupGuid: "1",
            parentEnumValue: "1406",
            enumValue: "140621",
            enumName: "山阴县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 53,
            enumNameother: "山阴县",
          },
          {
            fldEnumId: 14518,
            enumGroupGuid: "1",
            parentEnumValue: "1406",
            enumValue: "140622",
            enumName: "应县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 54,
            enumNameother: "应县",
          },
          {
            fldEnumId: 14522,
            enumGroupGuid: "1",
            parentEnumValue: "1406",
            enumValue: "140623",
            enumName: "右玉县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 55,
            enumNameother: "右玉县",
          },
          {
            fldEnumId: 14527,
            enumGroupGuid: "1",
            parentEnumValue: "1406",
            enumValue: "140624",
            enumName: "怀仁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 56,
            enumNameother: "怀仁县",
          },
        ],
      },
      {
        fldEnumId: 14531,
        enumGroupGuid: "1",
        parentEnumValue: "14",
        enumValue: "1407",
        enumName: "晋中市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 6,
        enumNameother: "晋中市",
        children: [
          {
            fldEnumId: 14533,
            enumGroupGuid: "1",
            parentEnumValue: "1407",
            enumValue: "140700",
            enumName: "晋中市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 57,
            enumNameother: "晋中市",
          },
          {
            fldEnumId: 14537,
            enumGroupGuid: "1",
            parentEnumValue: "1407",
            enumValue: "140702",
            enumName: "榆次区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 58,
            enumNameother: "榆次区",
          },
          {
            fldEnumId: 14541,
            enumGroupGuid: "1",
            parentEnumValue: "1407",
            enumValue: "140721",
            enumName: "榆社县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 59,
            enumNameother: "榆社县",
          },
          {
            fldEnumId: 14545,
            enumGroupGuid: "1",
            parentEnumValue: "1407",
            enumValue: "140722",
            enumName: "左权县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 60,
            enumNameother: "左权县",
          },
          {
            fldEnumId: 14549,
            enumGroupGuid: "1",
            parentEnumValue: "1407",
            enumValue: "140723",
            enumName: "和顺县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 61,
            enumNameother: "和顺县",
          },
          {
            fldEnumId: 14552,
            enumGroupGuid: "1",
            parentEnumValue: "1407",
            enumValue: "140724",
            enumName: "昔阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 62,
            enumNameother: "昔阳县",
          },
          {
            fldEnumId: 14556,
            enumGroupGuid: "1",
            parentEnumValue: "1407",
            enumValue: "140725",
            enumName: "寿阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 63,
            enumNameother: "寿阳县",
          },
          {
            fldEnumId: 14560,
            enumGroupGuid: "1",
            parentEnumValue: "1407",
            enumValue: "140726",
            enumName: "太谷县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 64,
            enumNameother: "太谷县",
          },
          {
            fldEnumId: 14564,
            enumGroupGuid: "1",
            parentEnumValue: "1407",
            enumValue: "140727",
            enumName: "祁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 65,
            enumNameother: "祁县",
          },
          {
            fldEnumId: 14569,
            enumGroupGuid: "1",
            parentEnumValue: "1407",
            enumValue: "140728",
            enumName: "平遥县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 66,
            enumNameother: "平遥县",
          },
          {
            fldEnumId: 14573,
            enumGroupGuid: "1",
            parentEnumValue: "1407",
            enumValue: "140729",
            enumName: "灵石县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 67,
            enumNameother: "灵石县",
          },
          {
            fldEnumId: 14577,
            enumGroupGuid: "1",
            parentEnumValue: "1407",
            enumValue: "140781",
            enumName: "介休市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 68,
            enumNameother: "介休市",
          },
        ],
      },
      {
        fldEnumId: 14581,
        enumGroupGuid: "1",
        parentEnumValue: "14",
        enumValue: "1408",
        enumName: "运城市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 7,
        enumNameother: "运城市",
        children: [
          {
            fldEnumId: 14583,
            enumGroupGuid: "1",
            parentEnumValue: "1408",
            enumValue: "140800",
            enumName: "运城市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 69,
            enumNameother: "运城市",
          },
          {
            fldEnumId: 14587,
            enumGroupGuid: "1",
            parentEnumValue: "1408",
            enumValue: "140802",
            enumName: "盐湖区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 70,
            enumNameother: "盐湖区",
          },
          {
            fldEnumId: 14591,
            enumGroupGuid: "1",
            parentEnumValue: "1408",
            enumValue: "140821",
            enumName: "临猗县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 71,
            enumNameother: "临猗县",
          },
          {
            fldEnumId: 14596,
            enumGroupGuid: "1",
            parentEnumValue: "1408",
            enumValue: "140822",
            enumName: "万荣县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 72,
            enumNameother: "万荣县",
          },
          {
            fldEnumId: 14600,
            enumGroupGuid: "1",
            parentEnumValue: "1408",
            enumValue: "140823",
            enumName: "闻喜县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 73,
            enumNameother: "闻喜县",
          },
          {
            fldEnumId: 14604,
            enumGroupGuid: "1",
            parentEnumValue: "1408",
            enumValue: "140824",
            enumName: "稷山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 74,
            enumNameother: "稷山县",
          },
          {
            fldEnumId: 14608,
            enumGroupGuid: "1",
            parentEnumValue: "1408",
            enumValue: "140825",
            enumName: "新绛县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 75,
            enumNameother: "新绛县",
          },
          {
            fldEnumId: 14612,
            enumGroupGuid: "1",
            parentEnumValue: "1408",
            enumValue: "140826",
            enumName: "绛县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 76,
            enumNameother: "绛县",
          },
          {
            fldEnumId: 14615,
            enumGroupGuid: "1",
            parentEnumValue: "1408",
            enumValue: "140827",
            enumName: "垣曲县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 77,
            enumNameother: "垣曲县",
          },
          {
            fldEnumId: 14619,
            enumGroupGuid: "1",
            parentEnumValue: "1408",
            enumValue: "140828",
            enumName: "夏县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 78,
            enumNameother: "夏县",
          },
          {
            fldEnumId: 14623,
            enumGroupGuid: "1",
            parentEnumValue: "1408",
            enumValue: "140829",
            enumName: "平陆县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 79,
            enumNameother: "平陆县",
          },
          {
            fldEnumId: 14626,
            enumGroupGuid: "1",
            parentEnumValue: "1408",
            enumValue: "140830",
            enumName: "芮城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 80,
            enumNameother: "芮城县",
          },
          {
            fldEnumId: 14630,
            enumGroupGuid: "1",
            parentEnumValue: "1408",
            enumValue: "140881",
            enumName: "永济市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 81,
            enumNameother: "永济市",
          },
          {
            fldEnumId: 14634,
            enumGroupGuid: "1",
            parentEnumValue: "1408",
            enumValue: "140882",
            enumName: "河津市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 82,
            enumNameother: "河津市",
          },
        ],
      },
      {
        fldEnumId: 14638,
        enumGroupGuid: "1",
        parentEnumValue: "14",
        enumValue: "1409",
        enumName: "忻州市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 8,
        enumNameother: "忻州市",
        children: [
          {
            fldEnumId: 14640,
            enumGroupGuid: "1",
            parentEnumValue: "1409",
            enumValue: "140900",
            enumName: "忻州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 83,
            enumNameother: "忻州市",
          },
          {
            fldEnumId: 14644,
            enumGroupGuid: "1",
            parentEnumValue: "1409",
            enumValue: "140902",
            enumName: "忻府区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 84,
            enumNameother: "忻府区",
          },
          {
            fldEnumId: 14648,
            enumGroupGuid: "1",
            parentEnumValue: "1409",
            enumValue: "140921",
            enumName: "定襄县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 85,
            enumNameother: "定襄县",
          },
          {
            fldEnumId: 14652,
            enumGroupGuid: "1",
            parentEnumValue: "1409",
            enumValue: "140922",
            enumName: "五台县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 86,
            enumNameother: "五台县",
          },
          {
            fldEnumId: 14656,
            enumGroupGuid: "1",
            parentEnumValue: "1409",
            enumValue: "140923",
            enumName: "代县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 87,
            enumNameother: "代县",
          },
          {
            fldEnumId: 14660,
            enumGroupGuid: "1",
            parentEnumValue: "1409",
            enumValue: "140924",
            enumName: "繁峙县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 88,
            enumNameother: "繁峙县",
          },
          {
            fldEnumId: 14663,
            enumGroupGuid: "1",
            parentEnumValue: "1409",
            enumValue: "140925",
            enumName: "宁武县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 89,
            enumNameother: "宁武县",
          },
          {
            fldEnumId: 14667,
            enumGroupGuid: "1",
            parentEnumValue: "1409",
            enumValue: "140926",
            enumName: "静乐县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 90,
            enumNameother: "静乐县",
          },
          {
            fldEnumId: 14671,
            enumGroupGuid: "1",
            parentEnumValue: "1409",
            enumValue: "140927",
            enumName: "神池县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 91,
            enumNameother: "神池县",
          },
          {
            fldEnumId: 14675,
            enumGroupGuid: "1",
            parentEnumValue: "1409",
            enumValue: "140928",
            enumName: "五寨县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 92,
            enumNameother: "五寨县",
          },
          {
            fldEnumId: 14678,
            enumGroupGuid: "1",
            parentEnumValue: "1409",
            enumValue: "140929",
            enumName: "岢岚县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 93,
            enumNameother: "岢岚县",
          },
          {
            fldEnumId: 14683,
            enumGroupGuid: "1",
            parentEnumValue: "1409",
            enumValue: "140930",
            enumName: "河曲县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 94,
            enumNameother: "河曲县",
          },
          {
            fldEnumId: 14687,
            enumGroupGuid: "1",
            parentEnumValue: "1409",
            enumValue: "140931",
            enumName: "保德县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 95,
            enumNameother: "保德县",
          },
          {
            fldEnumId: 14691,
            enumGroupGuid: "1",
            parentEnumValue: "1409",
            enumValue: "140932",
            enumName: "偏关县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 96,
            enumNameother: "偏关县",
          },
          {
            fldEnumId: 14694,
            enumGroupGuid: "1",
            parentEnumValue: "1409",
            enumValue: "140981",
            enumName: "原平市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 97,
            enumNameother: "原平市",
          },
        ],
      },
      {
        fldEnumId: 14698,
        enumGroupGuid: "1",
        parentEnumValue: "14",
        enumValue: "1410",
        enumName: "临汾市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 9,
        enumNameother: "临汾市",
        children: [
          {
            fldEnumId: 14700,
            enumGroupGuid: "1",
            parentEnumValue: "1410",
            enumValue: "141000",
            enumName: "临汾市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 98,
            enumNameother: "临汾市",
          },
          {
            fldEnumId: 14704,
            enumGroupGuid: "1",
            parentEnumValue: "1410",
            enumValue: "141002",
            enumName: "尧都区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 99,
            enumNameother: "尧都区",
          },
          {
            fldEnumId: 14708,
            enumGroupGuid: "1",
            parentEnumValue: "1410",
            enumValue: "141021",
            enumName: "曲沃县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 100,
            enumNameother: "曲沃县",
          },
          {
            fldEnumId: 14712,
            enumGroupGuid: "1",
            parentEnumValue: "1410",
            enumValue: "141022",
            enumName: "翼城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 101,
            enumNameother: "翼城县",
          },
          {
            fldEnumId: 14716,
            enumGroupGuid: "1",
            parentEnumValue: "1410",
            enumValue: "141023",
            enumName: "襄汾县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 102,
            enumNameother: "襄汾县",
          },
          {
            fldEnumId: 14720,
            enumGroupGuid: "1",
            parentEnumValue: "1410",
            enumValue: "141024",
            enumName: "洪洞县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 103,
            enumNameother: "洪洞县",
          },
          {
            fldEnumId: 14724,
            enumGroupGuid: "1",
            parentEnumValue: "1410",
            enumValue: "141025",
            enumName: "古县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 104,
            enumNameother: "古县",
          },
          {
            fldEnumId: 14728,
            enumGroupGuid: "1",
            parentEnumValue: "1410",
            enumValue: "141026",
            enumName: "安泽县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 105,
            enumNameother: "安泽县",
          },
          {
            fldEnumId: 14733,
            enumGroupGuid: "1",
            parentEnumValue: "1410",
            enumValue: "141027",
            enumName: "浮山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 106,
            enumNameother: "浮山县",
          },
          {
            fldEnumId: 14737,
            enumGroupGuid: "1",
            parentEnumValue: "1410",
            enumValue: "141028",
            enumName: "吉县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 107,
            enumNameother: "吉县",
          },
          {
            fldEnumId: 14741,
            enumGroupGuid: "1",
            parentEnumValue: "1410",
            enumValue: "141029",
            enumName: "乡宁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 108,
            enumNameother: "乡宁县",
          },
          {
            fldEnumId: 14745,
            enumGroupGuid: "1",
            parentEnumValue: "1410",
            enumValue: "141030",
            enumName: "大宁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 109,
            enumNameother: "大宁县",
          },
          {
            fldEnumId: 14749,
            enumGroupGuid: "1",
            parentEnumValue: "1410",
            enumValue: "141031",
            enumName: "隰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 110,
            enumNameother: "隰县",
          },
          {
            fldEnumId: 14753,
            enumGroupGuid: "1",
            parentEnumValue: "1410",
            enumValue: "141032",
            enumName: "永和县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 111,
            enumNameother: "永和县",
          },
          {
            fldEnumId: 14757,
            enumGroupGuid: "1",
            parentEnumValue: "1410",
            enumValue: "141033",
            enumName: "蒲县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 112,
            enumNameother: "蒲县",
          },
          {
            fldEnumId: 14761,
            enumGroupGuid: "1",
            parentEnumValue: "1410",
            enumValue: "141034",
            enumName: "汾西县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 113,
            enumNameother: "汾西县",
          },
          {
            fldEnumId: 14765,
            enumGroupGuid: "1",
            parentEnumValue: "1410",
            enumValue: "141081",
            enumName: "侯马市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 114,
            enumNameother: "侯马市",
          },
          {
            fldEnumId: 14769,
            enumGroupGuid: "1",
            parentEnumValue: "1410",
            enumValue: "141082",
            enumName: "霍州市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 115,
            enumNameother: "霍州市",
          },
        ],
      },
      {
        fldEnumId: 14773,
        enumGroupGuid: "1",
        parentEnumValue: "14",
        enumValue: "1411",
        enumName: "吕梁市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 10,
        enumNameother: "吕梁市",
        children: [
          {
            fldEnumId: 14774,
            enumGroupGuid: "1",
            parentEnumValue: "1411",
            enumValue: "141100",
            enumName: "吕梁市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 116,
            enumNameother: "吕梁市",
          },
          {
            fldEnumId: 14779,
            enumGroupGuid: "1",
            parentEnumValue: "1411",
            enumValue: "141102",
            enumName: "离石区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 117,
            enumNameother: "离石区",
          },
          {
            fldEnumId: 14782,
            enumGroupGuid: "1",
            parentEnumValue: "1411",
            enumValue: "141121",
            enumName: "文水县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 118,
            enumNameother: "文水县",
          },
          {
            fldEnumId: 14785,
            enumGroupGuid: "1",
            parentEnumValue: "1411",
            enumValue: "141122",
            enumName: "交城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 119,
            enumNameother: "交城县",
          },
          {
            fldEnumId: 14789,
            enumGroupGuid: "1",
            parentEnumValue: "1411",
            enumValue: "141123",
            enumName: "兴县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 120,
            enumNameother: "兴县",
          },
          {
            fldEnumId: 14793,
            enumGroupGuid: "1",
            parentEnumValue: "1411",
            enumValue: "141124",
            enumName: "临县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 121,
            enumNameother: "临县",
          },
          {
            fldEnumId: 14797,
            enumGroupGuid: "1",
            parentEnumValue: "1411",
            enumValue: "141125",
            enumName: "柳林县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 122,
            enumNameother: "柳林县",
          },
          {
            fldEnumId: 14802,
            enumGroupGuid: "1",
            parentEnumValue: "1411",
            enumValue: "141126",
            enumName: "石楼县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 123,
            enumNameother: "石楼县",
          },
          {
            fldEnumId: 14806,
            enumGroupGuid: "1",
            parentEnumValue: "1411",
            enumValue: "141127",
            enumName: "岚县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 124,
            enumNameother: "岚县",
          },
          {
            fldEnumId: 14810,
            enumGroupGuid: "1",
            parentEnumValue: "1411",
            enumValue: "141128",
            enumName: "方山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 125,
            enumNameother: "方山县",
          },
          {
            fldEnumId: 14814,
            enumGroupGuid: "1",
            parentEnumValue: "1411",
            enumValue: "141129",
            enumName: "中阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 126,
            enumNameother: "中阳县",
          },
          {
            fldEnumId: 14818,
            enumGroupGuid: "1",
            parentEnumValue: "1411",
            enumValue: "141130",
            enumName: "交口县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 127,
            enumNameother: "交口县",
          },
          {
            fldEnumId: 14822,
            enumGroupGuid: "1",
            parentEnumValue: "1411",
            enumValue: "141181",
            enumName: "孝义市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 128,
            enumNameother: "孝义市",
          },
          {
            fldEnumId: 14826,
            enumGroupGuid: "1",
            parentEnumValue: "1411",
            enumValue: "141182",
            enumName: "汾阳市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 129,
            enumNameother: "汾阳市",
          },
        ],
      },
    ],
  },
  {
    enumValue: "15",
    enumName: "内蒙古",
    children: [
      {
        fldEnumId: 14832,
        enumGroupGuid: "1",
        parentEnumValue: "15",
        enumValue: "1501",
        enumName: "呼和浩特市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        enumNameother: "呼和浩特市",
        children: [
          {
            fldEnumId: 14834,
            enumGroupGuid: "1",
            parentEnumValue: "1501",
            enumValue: "150100",
            enumName: "呼和浩特市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
            enumNameother: "呼和浩特市",
          },
          {
            fldEnumId: 14839,
            enumGroupGuid: "1",
            parentEnumValue: "1501",
            enumValue: "150102",
            enumName: "新城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "新城区",
          },
          {
            fldEnumId: 14843,
            enumGroupGuid: "1",
            parentEnumValue: "1501",
            enumValue: "150103",
            enumName: "回民区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 2,
            enumNameother: "回民区",
          },
          {
            fldEnumId: 14847,
            enumGroupGuid: "1",
            parentEnumValue: "1501",
            enumValue: "150104",
            enumName: "玉泉区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 3,
            enumNameother: "玉泉区",
          },
          {
            fldEnumId: 14850,
            enumGroupGuid: "1",
            parentEnumValue: "1501",
            enumValue: "150105",
            enumName: "赛罕区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 4,
            enumNameother: "赛罕区",
          },
          {
            fldEnumId: 14854,
            enumGroupGuid: "1",
            parentEnumValue: "1501",
            enumValue: "150121",
            enumName: "土默特左旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 5,
            enumNameother: "土默特左旗",
          },
          {
            fldEnumId: 14858,
            enumGroupGuid: "1",
            parentEnumValue: "1501",
            enumValue: "150122",
            enumName: "托克托县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 6,
            enumNameother: "托克托县",
          },
          {
            fldEnumId: 14861,
            enumGroupGuid: "1",
            parentEnumValue: "1501",
            enumValue: "150123",
            enumName: "和林格尔县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 7,
            enumNameother: "和林格尔县",
          },
          {
            fldEnumId: 14865,
            enumGroupGuid: "1",
            parentEnumValue: "1501",
            enumValue: "150124",
            enumName: "清水河县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 8,
            enumNameother: "清水河县",
          },
          {
            fldEnumId: 14869,
            enumGroupGuid: "1",
            parentEnumValue: "1501",
            enumValue: "150125",
            enumName: "武川县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 9,
            enumNameother: "武川县",
          },
        ],
      },
      {
        fldEnumId: 14873,
        enumGroupGuid: "1",
        parentEnumValue: "15",
        enumValue: "1502",
        enumName: "包头市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 1,
        enumNameother: "包头市",
        children: [
          {
            fldEnumId: 14875,
            enumGroupGuid: "1",
            parentEnumValue: "1502",
            enumValue: "150200",
            enumName: "包头市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 10,
            enumNameother: "包头市",
          },
          {
            fldEnumId: 14879,
            enumGroupGuid: "1",
            parentEnumValue: "1502",
            enumValue: "150202",
            enumName: "东河区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 11,
            enumNameother: "东河区",
          },
          {
            fldEnumId: 14883,
            enumGroupGuid: "1",
            parentEnumValue: "1502",
            enumValue: "150203",
            enumName: "昆都仑区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 12,
            enumNameother: "昆都仑区",
          },
          {
            fldEnumId: 14887,
            enumGroupGuid: "1",
            parentEnumValue: "1502",
            enumValue: "150204",
            enumName: "青山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 13,
            enumNameother: "青山区",
          },
          {
            fldEnumId: 14890,
            enumGroupGuid: "1",
            parentEnumValue: "1502",
            enumValue: "150205",
            enumName: "石拐区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 14,
            enumNameother: "石拐区",
          },
          {
            fldEnumId: 14894,
            enumGroupGuid: "1",
            parentEnumValue: "1502",
            enumValue: "150206",
            enumName: "白云矿区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 15,
            enumNameother: "白云矿区",
          },
          {
            fldEnumId: 14898,
            enumGroupGuid: "1",
            parentEnumValue: "1502",
            enumValue: "150207",
            enumName: "九原区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 16,
            enumNameother: "九原区",
          },
          {
            fldEnumId: 14902,
            enumGroupGuid: "1",
            parentEnumValue: "1502",
            enumValue: "150221",
            enumName: "土默特右旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 17,
            enumNameother: "土默特右旗",
          },
          {
            fldEnumId: 14907,
            enumGroupGuid: "1",
            parentEnumValue: "1502",
            enumValue: "150222",
            enumName: "固阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 18,
            enumNameother: "固阳县",
          },
          {
            fldEnumId: 14911,
            enumGroupGuid: "1",
            parentEnumValue: "1502",
            enumValue: "150223",
            enumName: "达尔罕茂明安联合旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 19,
            enumNameother: "达尔罕茂明安联合旗",
          },
        ],
      },
      {
        fldEnumId: 14914,
        enumGroupGuid: "1",
        parentEnumValue: "15",
        enumValue: "1503",
        enumName: "乌海市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 2,
        enumNameother: "乌海市",
        children: [
          {
            fldEnumId: 14916,
            enumGroupGuid: "1",
            parentEnumValue: "1503",
            enumValue: "150300",
            enumName: "乌海市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 20,
            enumNameother: "乌海市",
          },
          {
            fldEnumId: 14920,
            enumGroupGuid: "1",
            parentEnumValue: "1503",
            enumValue: "150302",
            enumName: "海勃湾区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 21,
            enumNameother: "海勃湾区",
          },
          {
            fldEnumId: 14924,
            enumGroupGuid: "1",
            parentEnumValue: "1503",
            enumValue: "150303",
            enumName: "海南区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 22,
            enumNameother: "海南区",
          },
          {
            fldEnumId: 14928,
            enumGroupGuid: "1",
            parentEnumValue: "1503",
            enumValue: "150304",
            enumName: "乌达区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 23,
            enumNameother: "乌达区",
          },
        ],
      },
      {
        fldEnumId: 14932,
        enumGroupGuid: "1",
        parentEnumValue: "15",
        enumValue: "1504",
        enumName: "赤峰市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 3,
        enumNameother: "赤峰市",
        children: [
          {
            fldEnumId: 14933,
            enumGroupGuid: "1",
            parentEnumValue: "1504",
            enumValue: "150400",
            enumName: "赤峰市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 24,
            enumNameother: "赤峰市",
          },
          {
            fldEnumId: 14938,
            enumGroupGuid: "1",
            parentEnumValue: "1504",
            enumValue: "150402",
            enumName: "红山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 25,
            enumNameother: "红山区",
          },
          {
            fldEnumId: 14942,
            enumGroupGuid: "1",
            parentEnumValue: "1504",
            enumValue: "150403",
            enumName: "元宝山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 26,
            enumNameother: "元宝山区",
          },
          {
            fldEnumId: 14946,
            enumGroupGuid: "1",
            parentEnumValue: "1504",
            enumValue: "150404",
            enumName: "松山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 27,
            enumNameother: "松山区",
          },
          {
            fldEnumId: 14949,
            enumGroupGuid: "1",
            parentEnumValue: "1504",
            enumValue: "150421",
            enumName: "阿鲁科尔沁旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 28,
            enumNameother: "阿鲁科尔沁旗",
          },
          {
            fldEnumId: 14953,
            enumGroupGuid: "1",
            parentEnumValue: "1504",
            enumValue: "150422",
            enumName: "巴林左旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 29,
            enumNameother: "巴林左旗",
          },
          {
            fldEnumId: 14958,
            enumGroupGuid: "1",
            parentEnumValue: "1504",
            enumValue: "150423",
            enumName: "巴林右旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 30,
            enumNameother: "巴林右旗",
          },
          {
            fldEnumId: 14961,
            enumGroupGuid: "1",
            parentEnumValue: "1504",
            enumValue: "150424",
            enumName: "林西县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 31,
            enumNameother: "林西县",
          },
          {
            fldEnumId: 14965,
            enumGroupGuid: "1",
            parentEnumValue: "1504",
            enumValue: "150425",
            enumName: "克什克腾旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 32,
            enumNameother: "克什克腾旗",
          },
          {
            fldEnumId: 14970,
            enumGroupGuid: "1",
            parentEnumValue: "1504",
            enumValue: "150426",
            enumName: "翁牛特旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 33,
            enumNameother: "翁牛特旗",
          },
          {
            fldEnumId: 14973,
            enumGroupGuid: "1",
            parentEnumValue: "1504",
            enumValue: "150428",
            enumName: "喀喇沁旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 34,
            enumNameother: "喀喇沁旗",
          },
          {
            fldEnumId: 14978,
            enumGroupGuid: "1",
            parentEnumValue: "1504",
            enumValue: "150429",
            enumName: "宁城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 35,
            enumNameother: "宁城县",
          },
          {
            fldEnumId: 14982,
            enumGroupGuid: "1",
            parentEnumValue: "1504",
            enumValue: "150430",
            enumName: "敖汉旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 36,
            enumNameother: "敖汉旗",
          },
        ],
      },
      {
        fldEnumId: 14986,
        enumGroupGuid: "1",
        parentEnumValue: "15",
        enumValue: "1505",
        enumName: "通辽市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 4,
        enumNameother: "通辽市",
        children: [
          {
            fldEnumId: 14987,
            enumGroupGuid: "1",
            parentEnumValue: "1505",
            enumValue: "150500",
            enumName: "通辽市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 37,
            enumNameother: "通辽市",
          },
          {
            fldEnumId: 14990,
            enumGroupGuid: "1",
            parentEnumValue: "1505",
            enumValue: "150502",
            enumName: "科尔沁区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 38,
            enumNameother: "科尔沁区",
          },
          {
            fldEnumId: 14994,
            enumGroupGuid: "1",
            parentEnumValue: "1505",
            enumValue: "150521",
            enumName: "科尔沁左翼中旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 39,
            enumNameother: "科尔沁左翼中旗",
          },
          {
            fldEnumId: 14998,
            enumGroupGuid: "1",
            parentEnumValue: "1505",
            enumValue: "150522",
            enumName: "科尔沁左翼后旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 40,
            enumNameother: "科尔沁左翼后旗",
          },
          {
            fldEnumId: 15003,
            enumGroupGuid: "1",
            parentEnumValue: "1505",
            enumValue: "150523",
            enumName: "开鲁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 41,
            enumNameother: "开鲁县",
          },
          {
            fldEnumId: 15007,
            enumGroupGuid: "1",
            parentEnumValue: "1505",
            enumValue: "150524",
            enumName: "库伦旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 42,
            enumNameother: "库伦旗",
          },
          {
            fldEnumId: 15011,
            enumGroupGuid: "1",
            parentEnumValue: "1505",
            enumValue: "150525",
            enumName: "奈曼旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 43,
            enumNameother: "奈曼旗",
          },
          {
            fldEnumId: 15014,
            enumGroupGuid: "1",
            parentEnumValue: "1505",
            enumValue: "150526",
            enumName: "扎鲁特旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 44,
            enumNameother: "扎鲁特旗",
          },
          {
            fldEnumId: 15018,
            enumGroupGuid: "1",
            parentEnumValue: "1505",
            enumValue: "150581",
            enumName: "霍林郭勒市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 45,
            enumNameother: "霍林郭勒市",
          },
        ],
      },
      {
        fldEnumId: 15022,
        enumGroupGuid: "1",
        parentEnumValue: "15",
        enumValue: "1506",
        enumName: "鄂尔多斯市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 5,
        enumNameother: "鄂尔多斯市",
        children: [
          {
            fldEnumId: 130331,
            enumGroupGuid: "1",
            parentEnumValue: "1506",
            enumValue: "150603",
            enumName: "康巴什区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 15024,
            enumGroupGuid: "1",
            parentEnumValue: "1506",
            enumValue: "150600",
            enumName: "鄂尔多斯市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 46,
            enumNameother: "鄂尔多斯市",
          },
          {
            fldEnumId: 15028,
            enumGroupGuid: "1",
            parentEnumValue: "1506",
            enumValue: "150602",
            enumName: "东胜区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 47,
            enumNameother: "东胜区",
          },
          {
            fldEnumId: 15033,
            enumGroupGuid: "1",
            parentEnumValue: "1506",
            enumValue: "150621",
            enumName: "达拉特旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 48,
            enumNameother: "达拉特旗",
          },
          {
            fldEnumId: 15037,
            enumGroupGuid: "1",
            parentEnumValue: "1506",
            enumValue: "150622",
            enumName: "准格尔旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 49,
            enumNameother: "准格尔旗",
          },
          {
            fldEnumId: 15040,
            enumGroupGuid: "1",
            parentEnumValue: "1506",
            enumValue: "150623",
            enumName: "鄂托克前旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 50,
            enumNameother: "鄂托克前旗",
          },
          {
            fldEnumId: 15044,
            enumGroupGuid: "1",
            parentEnumValue: "1506",
            enumValue: "150624",
            enumName: "鄂托克旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 51,
            enumNameother: "鄂托克旗",
          },
          {
            fldEnumId: 15049,
            enumGroupGuid: "1",
            parentEnumValue: "1506",
            enumValue: "150625",
            enumName: "杭锦旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 52,
            enumNameother: "杭锦旗",
          },
          {
            fldEnumId: 15053,
            enumGroupGuid: "1",
            parentEnumValue: "1506",
            enumValue: "150626",
            enumName: "乌审旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 53,
            enumNameother: "乌审旗",
          },
          {
            fldEnumId: 15057,
            enumGroupGuid: "1",
            parentEnumValue: "1506",
            enumValue: "150627",
            enumName: "伊金霍洛旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 54,
            enumNameother: "伊金霍洛旗",
          },
        ],
      },
      {
        fldEnumId: 15060,
        enumGroupGuid: "1",
        parentEnumValue: "15",
        enumValue: "1507",
        enumName: "呼伦贝尔市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 6,
        enumNameother: "呼伦贝尔市",
        children: [
          {
            fldEnumId: 15062,
            enumGroupGuid: "1",
            parentEnumValue: "1507",
            enumValue: "150700",
            enumName: "呼伦贝尔市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 55,
            enumNameother: "呼伦贝尔市",
          },
          {
            fldEnumId: 15066,
            enumGroupGuid: "1",
            parentEnumValue: "1507",
            enumValue: "150702",
            enumName: "海拉尔区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 56,
            enumNameother: "海拉尔区",
          },
          {
            fldEnumId: 15071,
            enumGroupGuid: "1",
            parentEnumValue: "1507",
            enumValue: "150721",
            enumName: "阿荣旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 57,
            enumNameother: "阿荣旗",
          },
          {
            fldEnumId: 15074,
            enumGroupGuid: "1",
            parentEnumValue: "1507",
            enumValue: "150722",
            enumName: "莫力达瓦达斡尔族自治旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 58,
            enumNameother: "莫力达瓦达斡尔族自治旗",
          },
          {
            fldEnumId: 15079,
            enumGroupGuid: "1",
            parentEnumValue: "1507",
            enumValue: "150723",
            enumName: "鄂伦春自治旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 59,
            enumNameother: "鄂伦春自治旗",
          },
          {
            fldEnumId: 15082,
            enumGroupGuid: "1",
            parentEnumValue: "1507",
            enumValue: "150724",
            enumName: "鄂温克族自治旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 60,
            enumNameother: "鄂温克族自治旗",
          },
          {
            fldEnumId: 15086,
            enumGroupGuid: "1",
            parentEnumValue: "1507",
            enumValue: "150725",
            enumName: "陈巴尔虎旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 61,
            enumNameother: "陈巴尔虎旗",
          },
          {
            fldEnumId: 15090,
            enumGroupGuid: "1",
            parentEnumValue: "1507",
            enumValue: "150726",
            enumName: "新巴尔虎左旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 62,
            enumNameother: "新巴尔虎左旗",
          },
          {
            fldEnumId: 15095,
            enumGroupGuid: "1",
            parentEnumValue: "1507",
            enumValue: "150727",
            enumName: "新巴尔虎右旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 63,
            enumNameother: "新巴尔虎右旗",
          },
          {
            fldEnumId: 15098,
            enumGroupGuid: "1",
            parentEnumValue: "1507",
            enumValue: "150781",
            enumName: "满洲里市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 64,
            enumNameother: "满洲里市",
          },
          {
            fldEnumId: 15101,
            enumGroupGuid: "1",
            parentEnumValue: "1507",
            enumValue: "150782",
            enumName: "牙克石市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 65,
            enumNameother: "牙克石市",
          },
          {
            fldEnumId: 15103,
            enumGroupGuid: "1",
            parentEnumValue: "1507",
            enumValue: "150783",
            enumName: "扎兰屯市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 66,
            enumNameother: "扎兰屯市",
          },
          {
            fldEnumId: 15105,
            enumGroupGuid: "1",
            parentEnumValue: "1507",
            enumValue: "150784",
            enumName: "额尔古纳市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 67,
            enumNameother: "额尔古纳市",
          },
          {
            fldEnumId: 15107,
            enumGroupGuid: "1",
            parentEnumValue: "1507",
            enumValue: "150785",
            enumName: "根河市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 68,
            enumNameother: "根河市",
          },
        ],
      },
      {
        fldEnumId: 15109,
        enumGroupGuid: "1",
        parentEnumValue: "15",
        enumValue: "1508",
        enumName: "巴彦淖尔市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 7,
        enumNameother: "巴彦淖尔市",
        children: [
          {
            fldEnumId: 15110,
            enumGroupGuid: "1",
            parentEnumValue: "1508",
            enumValue: "150800",
            enumName: "巴彦淖尔市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 69,
            enumNameother: "巴彦淖尔市",
          },
          {
            fldEnumId: 15112,
            enumGroupGuid: "1",
            parentEnumValue: "1508",
            enumValue: "150802",
            enumName: "临河区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 70,
            enumNameother: "临河区",
          },
          {
            fldEnumId: 15114,
            enumGroupGuid: "1",
            parentEnumValue: "1508",
            enumValue: "150821",
            enumName: "五原县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 71,
            enumNameother: "五原县",
          },
          {
            fldEnumId: 15116,
            enumGroupGuid: "1",
            parentEnumValue: "1508",
            enumValue: "150822",
            enumName: "磴口县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 72,
            enumNameother: "磴口县",
          },
          {
            fldEnumId: 15118,
            enumGroupGuid: "1",
            parentEnumValue: "1508",
            enumValue: "150823",
            enumName: "乌拉特前旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 73,
            enumNameother: "乌拉特前旗",
          },
          {
            fldEnumId: 15120,
            enumGroupGuid: "1",
            parentEnumValue: "1508",
            enumValue: "150824",
            enumName: "乌拉特中旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 74,
            enumNameother: "乌拉特中旗",
          },
          {
            fldEnumId: 15122,
            enumGroupGuid: "1",
            parentEnumValue: "1508",
            enumValue: "150825",
            enumName: "乌拉特后旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 75,
            enumNameother: "乌拉特后旗",
          },
          {
            fldEnumId: 15124,
            enumGroupGuid: "1",
            parentEnumValue: "1508",
            enumValue: "150826",
            enumName: "杭锦后旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 76,
            enumNameother: "杭锦后旗",
          },
        ],
      },
      {
        fldEnumId: 15126,
        enumGroupGuid: "1",
        parentEnumValue: "15",
        enumValue: "1509",
        enumName: "乌兰察布市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 8,
        enumNameother: "乌兰察布市",
        children: [
          {
            fldEnumId: 15127,
            enumGroupGuid: "1",
            parentEnumValue: "1509",
            enumValue: "150900",
            enumName: "乌兰察布市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 77,
            enumNameother: "乌兰察布市",
          },
          {
            fldEnumId: 15129,
            enumGroupGuid: "1",
            parentEnumValue: "1509",
            enumValue: "150902",
            enumName: "集宁区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 78,
            enumNameother: "集宁区",
          },
          {
            fldEnumId: 15131,
            enumGroupGuid: "1",
            parentEnumValue: "1509",
            enumValue: "150921",
            enumName: "卓资县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 79,
            enumNameother: "卓资县",
          },
          {
            fldEnumId: 15133,
            enumGroupGuid: "1",
            parentEnumValue: "1509",
            enumValue: "150922",
            enumName: "化德县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 80,
            enumNameother: "化德县",
          },
          {
            fldEnumId: 15135,
            enumGroupGuid: "1",
            parentEnumValue: "1509",
            enumValue: "150923",
            enumName: "商都县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 81,
            enumNameother: "商都县",
          },
          {
            fldEnumId: 15137,
            enumGroupGuid: "1",
            parentEnumValue: "1509",
            enumValue: "150924",
            enumName: "兴和县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 82,
            enumNameother: "兴和县",
          },
          {
            fldEnumId: 15139,
            enumGroupGuid: "1",
            parentEnumValue: "1509",
            enumValue: "150925",
            enumName: "凉城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 83,
            enumNameother: "凉城县",
          },
          {
            fldEnumId: 15141,
            enumGroupGuid: "1",
            parentEnumValue: "1509",
            enumValue: "150926",
            enumName: "察哈尔右翼前旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 84,
            enumNameother: "察哈尔右翼前旗",
          },
          {
            fldEnumId: 15143,
            enumGroupGuid: "1",
            parentEnumValue: "1509",
            enumValue: "150927",
            enumName: "察哈尔右翼中旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 85,
            enumNameother: "察哈尔右翼中旗",
          },
          {
            fldEnumId: 15145,
            enumGroupGuid: "1",
            parentEnumValue: "1509",
            enumValue: "150928",
            enumName: "察哈尔右翼后旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 86,
            enumNameother: "察哈尔右翼后旗",
          },
          {
            fldEnumId: 15147,
            enumGroupGuid: "1",
            parentEnumValue: "1509",
            enumValue: "150929",
            enumName: "四子王旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 87,
            enumNameother: "四子王旗",
          },
          {
            fldEnumId: 15149,
            enumGroupGuid: "1",
            parentEnumValue: "1509",
            enumValue: "150981",
            enumName: "丰镇市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 88,
            enumNameother: "丰镇市",
          },
        ],
      },
      {
        fldEnumId: 15151,
        enumGroupGuid: "1",
        parentEnumValue: "15",
        enumValue: "1522",
        enumName: "兴安盟",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 9,
        enumNameother: "兴安盟",
        children: [
          {
            fldEnumId: 16224,
            enumGroupGuid: "1",
            parentEnumValue: "1522",
            enumValue: "152200",
            enumName: "兴安盟本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 15152,
            enumGroupGuid: "1",
            parentEnumValue: "1522",
            enumValue: "152201",
            enumName: "乌兰浩特市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 89,
            enumNameother: "乌兰浩特市",
          },
          {
            fldEnumId: 15154,
            enumGroupGuid: "1",
            parentEnumValue: "1522",
            enumValue: "152202",
            enumName: "阿尔山市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 90,
            enumNameother: "阿尔山市",
          },
          {
            fldEnumId: 15156,
            enumGroupGuid: "1",
            parentEnumValue: "1522",
            enumValue: "152221",
            enumName: "科尔沁右翼前旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 91,
            enumNameother: "科尔沁右翼前旗",
          },
          {
            fldEnumId: 15158,
            enumGroupGuid: "1",
            parentEnumValue: "1522",
            enumValue: "152222",
            enumName: "科尔沁右翼中旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 92,
            enumNameother: "科尔沁右翼中旗",
          },
          {
            fldEnumId: 15160,
            enumGroupGuid: "1",
            parentEnumValue: "1522",
            enumValue: "152223",
            enumName: "扎赉特旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 93,
            enumNameother: "扎赉特旗",
          },
          {
            fldEnumId: 15162,
            enumGroupGuid: "1",
            parentEnumValue: "1522",
            enumValue: "152224",
            enumName: "突泉县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 94,
            enumNameother: "突泉县",
          },
        ],
      },
      {
        fldEnumId: 15164,
        enumGroupGuid: "1",
        parentEnumValue: "15",
        enumValue: "1525",
        enumName: "锡林郭勒盟",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 10,
        enumNameother: "锡林郭勒盟",
        children: [
          {
            fldEnumId: 16226,
            enumGroupGuid: "1",
            parentEnumValue: "1525",
            enumValue: "152500",
            enumName: "锡林郭勒盟本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 15165,
            enumGroupGuid: "1",
            parentEnumValue: "1525",
            enumValue: "152501",
            enumName: "二连浩特市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 95,
            enumNameother: "二连浩特市",
          },
          {
            fldEnumId: 15167,
            enumGroupGuid: "1",
            parentEnumValue: "1525",
            enumValue: "152502",
            enumName: "锡林浩特市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 96,
            enumNameother: "锡林浩特市",
          },
          {
            fldEnumId: 15169,
            enumGroupGuid: "1",
            parentEnumValue: "1525",
            enumValue: "152522",
            enumName: "阿巴嘎旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 97,
            enumNameother: "阿巴嘎旗",
          },
          {
            fldEnumId: 15171,
            enumGroupGuid: "1",
            parentEnumValue: "1525",
            enumValue: "152523",
            enumName: "苏尼特左旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 98,
            enumNameother: "苏尼特左旗",
          },
          {
            fldEnumId: 15173,
            enumGroupGuid: "1",
            parentEnumValue: "1525",
            enumValue: "152524",
            enumName: "苏尼特右旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 99,
            enumNameother: "苏尼特右旗",
          },
          {
            fldEnumId: 15175,
            enumGroupGuid: "1",
            parentEnumValue: "1525",
            enumValue: "152525",
            enumName: "东乌珠穆沁旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 100,
            enumNameother: "东乌珠穆沁旗",
          },
          {
            fldEnumId: 15177,
            enumGroupGuid: "1",
            parentEnumValue: "1525",
            enumValue: "152526",
            enumName: "西乌珠穆沁旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 101,
            enumNameother: "西乌珠穆沁旗",
          },
          {
            fldEnumId: 15179,
            enumGroupGuid: "1",
            parentEnumValue: "1525",
            enumValue: "152527",
            enumName: "太仆寺旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 102,
            enumNameother: "太仆寺旗",
          },
          {
            fldEnumId: 15181,
            enumGroupGuid: "1",
            parentEnumValue: "1525",
            enumValue: "152528",
            enumName: "镶黄旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 103,
            enumNameother: "镶黄旗",
          },
          {
            fldEnumId: 15183,
            enumGroupGuid: "1",
            parentEnumValue: "1525",
            enumValue: "152529",
            enumName: "正镶白旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 104,
            enumNameother: "正镶白旗",
          },
          {
            fldEnumId: 15185,
            enumGroupGuid: "1",
            parentEnumValue: "1525",
            enumValue: "152530",
            enumName: "正蓝旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 105,
            enumNameother: "正蓝旗",
          },
          {
            fldEnumId: 15187,
            enumGroupGuid: "1",
            parentEnumValue: "1525",
            enumValue: "152531",
            enumName: "多伦县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 106,
            enumNameother: "多伦县",
          },
        ],
      },
      {
        fldEnumId: 15189,
        enumGroupGuid: "1",
        parentEnumValue: "15",
        enumValue: "1529",
        enumName: "阿拉善盟",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 11,
        enumNameother: "阿拉善盟",
        children: [
          {
            fldEnumId: 16228,
            enumGroupGuid: "1",
            parentEnumValue: "1529",
            enumValue: "152900",
            enumName: "阿拉善盟本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 15190,
            enumGroupGuid: "1",
            parentEnumValue: "1529",
            enumValue: "152921",
            enumName: "阿拉善左旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 107,
            enumNameother: "阿拉善左旗",
          },
          {
            fldEnumId: 15192,
            enumGroupGuid: "1",
            parentEnumValue: "1529",
            enumValue: "152922",
            enumName: "阿拉善右旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 108,
            enumNameother: "阿拉善右旗",
          },
          {
            fldEnumId: 15194,
            enumGroupGuid: "1",
            parentEnumValue: "1529",
            enumValue: "152923",
            enumName: "额济纳旗",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 109,
            enumNameother: "额济纳旗",
          },
        ],
      },
    ],
  },
  {
    enumValue: "21",
    enumName: "辽宁省",
    children: [
      {
        fldEnumId: 15197,
        enumGroupGuid: "1",
        parentEnumValue: "21",
        enumValue: "2101",
        enumName: "沈阳市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        enumNameother: "沈阳市",
        children: [
          {
            fldEnumId: 15198,
            enumGroupGuid: "1",
            parentEnumValue: "2101",
            enumValue: "210100",
            enumName: "沈阳市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
            enumNameother: "沈阳市",
          },
          {
            fldEnumId: 15200,
            enumGroupGuid: "1",
            parentEnumValue: "2101",
            enumValue: "210102",
            enumName: "和平区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "和平区",
          },
          {
            fldEnumId: 15202,
            enumGroupGuid: "1",
            parentEnumValue: "2101",
            enumValue: "210103",
            enumName: "沈河区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 2,
            enumNameother: "沈河区",
          },
          {
            fldEnumId: 15204,
            enumGroupGuid: "1",
            parentEnumValue: "2101",
            enumValue: "210104",
            enumName: "大东区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 3,
            enumNameother: "大东区",
          },
          {
            fldEnumId: 15206,
            enumGroupGuid: "1",
            parentEnumValue: "2101",
            enumValue: "210105",
            enumName: "皇姑区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 4,
            enumNameother: "皇姑区",
          },
          {
            fldEnumId: 15208,
            enumGroupGuid: "1",
            parentEnumValue: "2101",
            enumValue: "210106",
            enumName: "铁西区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 5,
            enumNameother: "铁西区",
          },
          {
            fldEnumId: 15210,
            enumGroupGuid: "1",
            parentEnumValue: "2101",
            enumValue: "210111",
            enumName: "苏家屯区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 6,
            enumNameother: "苏家屯区",
          },
          {
            fldEnumId: 15212,
            enumGroupGuid: "1",
            parentEnumValue: "2101",
            enumValue: "210112",
            enumName: "东陵区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 7,
            enumNameother: "东陵区",
          },
          {
            fldEnumId: 15214,
            enumGroupGuid: "1",
            parentEnumValue: "2101",
            enumValue: "210113",
            enumName: "沈北新区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 8,
            enumNameother: "沈北新区",
          },
          {
            fldEnumId: 15216,
            enumGroupGuid: "1",
            parentEnumValue: "2101",
            enumValue: "210114",
            enumName: "于洪区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 9,
            enumNameother: "于洪区",
          },
          {
            fldEnumId: 15218,
            enumGroupGuid: "1",
            parentEnumValue: "2101",
            enumValue: "210122",
            enumName: "辽中县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 10,
            enumNameother: "辽中县",
          },
          {
            fldEnumId: 15220,
            enumGroupGuid: "1",
            parentEnumValue: "2101",
            enumValue: "210123",
            enumName: "康平县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 11,
            enumNameother: "康平县",
          },
          {
            fldEnumId: 15222,
            enumGroupGuid: "1",
            parentEnumValue: "2101",
            enumValue: "210124",
            enumName: "法库县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 12,
            enumNameother: "法库县",
          },
          {
            fldEnumId: 15224,
            enumGroupGuid: "1",
            parentEnumValue: "2101",
            enumValue: "210181",
            enumName: "新民市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 13,
            enumNameother: "新民市",
          },
        ],
      },
      {
        fldEnumId: 15226,
        enumGroupGuid: "1",
        parentEnumValue: "21",
        enumValue: "2102",
        enumName: "大连市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 1,
        enumNameother: "大连市",
        children: [
          {
            fldEnumId: 15227,
            enumGroupGuid: "1",
            parentEnumValue: "2102",
            enumValue: "210200",
            enumName: "大连市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 14,
            enumNameother: "大连市",
          },
          {
            fldEnumId: 15229,
            enumGroupGuid: "1",
            parentEnumValue: "2102",
            enumValue: "210202",
            enumName: "中山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 15,
            enumNameother: "中山区",
          },
          {
            fldEnumId: 15231,
            enumGroupGuid: "1",
            parentEnumValue: "2102",
            enumValue: "210203",
            enumName: "西岗区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 16,
            enumNameother: "西岗区",
          },
          {
            fldEnumId: 15233,
            enumGroupGuid: "1",
            parentEnumValue: "2102",
            enumValue: "210204",
            enumName: "沙河口区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 17,
            enumNameother: "沙河口区",
          },
          {
            fldEnumId: 15235,
            enumGroupGuid: "1",
            parentEnumValue: "2102",
            enumValue: "210211",
            enumName: "甘井子区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 18,
            enumNameother: "甘井子区",
          },
          {
            fldEnumId: 15237,
            enumGroupGuid: "1",
            parentEnumValue: "2102",
            enumValue: "210212",
            enumName: "旅顺口区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 19,
            enumNameother: "旅顺口区",
          },
          {
            fldEnumId: 15239,
            enumGroupGuid: "1",
            parentEnumValue: "2102",
            enumValue: "210213",
            enumName: "金州区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 20,
            enumNameother: "金州区",
          },
          {
            fldEnumId: 15241,
            enumGroupGuid: "1",
            parentEnumValue: "2102",
            enumValue: "210224",
            enumName: "长海县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 21,
            enumNameother: "长海县",
          },
          {
            fldEnumId: 15243,
            enumGroupGuid: "1",
            parentEnumValue: "2102",
            enumValue: "210281",
            enumName: "瓦房店市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 22,
            enumNameother: "瓦房店市",
          },
          {
            fldEnumId: 15245,
            enumGroupGuid: "1",
            parentEnumValue: "2102",
            enumValue: "210282",
            enumName: "普兰店市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 23,
            enumNameother: "普兰店市",
          },
          {
            fldEnumId: 15247,
            enumGroupGuid: "1",
            parentEnumValue: "2102",
            enumValue: "210283",
            enumName: "庄河市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 24,
            enumNameother: "庄河市",
          },
        ],
      },
      {
        fldEnumId: 15249,
        enumGroupGuid: "1",
        parentEnumValue: "21",
        enumValue: "2103",
        enumName: "鞍山市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 2,
        enumNameother: "鞍山市",
        children: [
          {
            fldEnumId: 15250,
            enumGroupGuid: "1",
            parentEnumValue: "2103",
            enumValue: "210300",
            enumName: "鞍山市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 25,
            enumNameother: "鞍山市",
          },
          {
            fldEnumId: 15252,
            enumGroupGuid: "1",
            parentEnumValue: "2103",
            enumValue: "210302",
            enumName: "铁东区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 26,
            enumNameother: "铁东区",
          },
          {
            fldEnumId: 15254,
            enumGroupGuid: "1",
            parentEnumValue: "2103",
            enumValue: "210303",
            enumName: "铁西区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 27,
            enumNameother: "铁西区",
          },
          {
            fldEnumId: 15256,
            enumGroupGuid: "1",
            parentEnumValue: "2103",
            enumValue: "210304",
            enumName: "立山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 28,
            enumNameother: "立山区",
          },
          {
            fldEnumId: 15258,
            enumGroupGuid: "1",
            parentEnumValue: "2103",
            enumValue: "210311",
            enumName: "千山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 29,
            enumNameother: "千山区",
          },
          {
            fldEnumId: 15260,
            enumGroupGuid: "1",
            parentEnumValue: "2103",
            enumValue: "210321",
            enumName: "台安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 30,
            enumNameother: "台安县",
          },
          {
            fldEnumId: 15262,
            enumGroupGuid: "1",
            parentEnumValue: "2103",
            enumValue: "210323",
            enumName: "岫岩满族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 31,
            enumNameother: "岫岩满族自治县",
          },
          {
            fldEnumId: 15264,
            enumGroupGuid: "1",
            parentEnumValue: "2103",
            enumValue: "210381",
            enumName: "海城市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 32,
            enumNameother: "海城市",
          },
        ],
      },
      {
        fldEnumId: 15266,
        enumGroupGuid: "1",
        parentEnumValue: "21",
        enumValue: "2104",
        enumName: "抚顺市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 3,
        enumNameother: "抚顺市",
        children: [
          {
            fldEnumId: 15267,
            enumGroupGuid: "1",
            parentEnumValue: "2104",
            enumValue: "210400",
            enumName: "抚顺市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 33,
            enumNameother: "抚顺市",
          },
          {
            fldEnumId: 15269,
            enumGroupGuid: "1",
            parentEnumValue: "2104",
            enumValue: "210402",
            enumName: "新抚区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 34,
            enumNameother: "新抚区",
          },
          {
            fldEnumId: 15271,
            enumGroupGuid: "1",
            parentEnumValue: "2104",
            enumValue: "210403",
            enumName: "东洲区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 35,
            enumNameother: "东洲区",
          },
          {
            fldEnumId: 15273,
            enumGroupGuid: "1",
            parentEnumValue: "2104",
            enumValue: "210404",
            enumName: "望花区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 36,
            enumNameother: "望花区",
          },
          {
            fldEnumId: 15275,
            enumGroupGuid: "1",
            parentEnumValue: "2104",
            enumValue: "210411",
            enumName: "顺城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 37,
            enumNameother: "顺城区",
          },
          {
            fldEnumId: 15277,
            enumGroupGuid: "1",
            parentEnumValue: "2104",
            enumValue: "210421",
            enumName: "抚顺县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 38,
            enumNameother: "抚顺县",
          },
          {
            fldEnumId: 15279,
            enumGroupGuid: "1",
            parentEnumValue: "2104",
            enumValue: "210422",
            enumName: "新宾满族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 39,
            enumNameother: "新宾满族自治县",
          },
          {
            fldEnumId: 15281,
            enumGroupGuid: "1",
            parentEnumValue: "2104",
            enumValue: "210423",
            enumName: "清原满族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 40,
            enumNameother: "清原满族自治县",
          },
        ],
      },
      {
        fldEnumId: 15283,
        enumGroupGuid: "1",
        parentEnumValue: "21",
        enumValue: "2105",
        enumName: "本溪市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 4,
        enumNameother: "本溪市",
        children: [
          {
            fldEnumId: 15284,
            enumGroupGuid: "1",
            parentEnumValue: "2105",
            enumValue: "210500",
            enumName: "本溪市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 41,
            enumNameother: "本溪市",
          },
          {
            fldEnumId: 15286,
            enumGroupGuid: "1",
            parentEnumValue: "2105",
            enumValue: "210502",
            enumName: "平山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 42,
            enumNameother: "平山区",
          },
          {
            fldEnumId: 15288,
            enumGroupGuid: "1",
            parentEnumValue: "2105",
            enumValue: "210503",
            enumName: "溪湖区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 43,
            enumNameother: "溪湖区",
          },
          {
            fldEnumId: 15290,
            enumGroupGuid: "1",
            parentEnumValue: "2105",
            enumValue: "210504",
            enumName: "明山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 44,
            enumNameother: "明山区",
          },
          {
            fldEnumId: 15292,
            enumGroupGuid: "1",
            parentEnumValue: "2105",
            enumValue: "210505",
            enumName: "南芬区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 45,
            enumNameother: "南芬区",
          },
          {
            fldEnumId: 15294,
            enumGroupGuid: "1",
            parentEnumValue: "2105",
            enumValue: "210521",
            enumName: "本溪满族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 46,
            enumNameother: "本溪满族自治县",
          },
          {
            fldEnumId: 15296,
            enumGroupGuid: "1",
            parentEnumValue: "2105",
            enumValue: "210522",
            enumName: "桓仁满族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 47,
            enumNameother: "桓仁满族自治县",
          },
        ],
      },
      {
        fldEnumId: 15298,
        enumGroupGuid: "1",
        parentEnumValue: "21",
        enumValue: "2106",
        enumName: "丹东市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 5,
        enumNameother: "丹东市",
        children: [
          {
            fldEnumId: 15299,
            enumGroupGuid: "1",
            parentEnumValue: "2106",
            enumValue: "210600",
            enumName: "丹东市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 48,
            enumNameother: "丹东市",
          },
          {
            fldEnumId: 15301,
            enumGroupGuid: "1",
            parentEnumValue: "2106",
            enumValue: "210602",
            enumName: "元宝区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 49,
            enumNameother: "元宝区",
          },
          {
            fldEnumId: 15303,
            enumGroupGuid: "1",
            parentEnumValue: "2106",
            enumValue: "210603",
            enumName: "振兴区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 50,
            enumNameother: "振兴区",
          },
          {
            fldEnumId: 15305,
            enumGroupGuid: "1",
            parentEnumValue: "2106",
            enumValue: "210604",
            enumName: "振安区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 51,
            enumNameother: "振安区",
          },
          {
            fldEnumId: 15307,
            enumGroupGuid: "1",
            parentEnumValue: "2106",
            enumValue: "210624",
            enumName: "宽甸满族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 52,
            enumNameother: "宽甸满族自治县",
          },
          {
            fldEnumId: 15309,
            enumGroupGuid: "1",
            parentEnumValue: "2106",
            enumValue: "210681",
            enumName: "东港市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 53,
            enumNameother: "东港市",
          },
          {
            fldEnumId: 15311,
            enumGroupGuid: "1",
            parentEnumValue: "2106",
            enumValue: "210682",
            enumName: "凤城市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 54,
            enumNameother: "凤城市",
          },
        ],
      },
      {
        fldEnumId: 15313,
        enumGroupGuid: "1",
        parentEnumValue: "21",
        enumValue: "2107",
        enumName: "锦州市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 6,
        enumNameother: "锦州市",
        children: [
          {
            fldEnumId: 15314,
            enumGroupGuid: "1",
            parentEnumValue: "2107",
            enumValue: "210700",
            enumName: "锦州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 55,
            enumNameother: "锦州市",
          },
          {
            fldEnumId: 15316,
            enumGroupGuid: "1",
            parentEnumValue: "2107",
            enumValue: "210702",
            enumName: "古塔区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 56,
            enumNameother: "古塔区",
          },
          {
            fldEnumId: 15318,
            enumGroupGuid: "1",
            parentEnumValue: "2107",
            enumValue: "210703",
            enumName: "凌河区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 57,
            enumNameother: "凌河区",
          },
          {
            fldEnumId: 15320,
            enumGroupGuid: "1",
            parentEnumValue: "2107",
            enumValue: "210711",
            enumName: "太和区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 58,
            enumNameother: "太和区",
          },
          {
            fldEnumId: 15322,
            enumGroupGuid: "1",
            parentEnumValue: "2107",
            enumValue: "210726",
            enumName: "黑山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 59,
            enumNameother: "黑山县",
          },
          {
            fldEnumId: 15324,
            enumGroupGuid: "1",
            parentEnumValue: "2107",
            enumValue: "210727",
            enumName: "义县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 60,
            enumNameother: "义县",
          },
          {
            fldEnumId: 15326,
            enumGroupGuid: "1",
            parentEnumValue: "2107",
            enumValue: "210781",
            enumName: "凌海市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 61,
            enumNameother: "凌海市",
          },
          {
            fldEnumId: 15328,
            enumGroupGuid: "1",
            parentEnumValue: "2107",
            enumValue: "210782",
            enumName: "北镇市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 62,
            enumNameother: "北镇市",
          },
        ],
      },
      {
        fldEnumId: 15330,
        enumGroupGuid: "1",
        parentEnumValue: "21",
        enumValue: "2108",
        enumName: "营口市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 7,
        enumNameother: "营口市",
        children: [
          {
            fldEnumId: 15331,
            enumGroupGuid: "1",
            parentEnumValue: "2108",
            enumValue: "210800",
            enumName: "营口市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 63,
            enumNameother: "营口市",
          },
          {
            fldEnumId: 15333,
            enumGroupGuid: "1",
            parentEnumValue: "2108",
            enumValue: "210802",
            enumName: "站前区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 64,
            enumNameother: "站前区",
          },
          {
            fldEnumId: 15335,
            enumGroupGuid: "1",
            parentEnumValue: "2108",
            enumValue: "210803",
            enumName: "西市区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 65,
            enumNameother: "西市区",
          },
          {
            fldEnumId: 15337,
            enumGroupGuid: "1",
            parentEnumValue: "2108",
            enumValue: "210804",
            enumName: "鲅鱼圈区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 66,
            enumNameother: "鲅鱼圈区",
          },
          {
            fldEnumId: 15339,
            enumGroupGuid: "1",
            parentEnumValue: "2108",
            enumValue: "210811",
            enumName: "老边区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 67,
            enumNameother: "老边区",
          },
          {
            fldEnumId: 15341,
            enumGroupGuid: "1",
            parentEnumValue: "2108",
            enumValue: "210881",
            enumName: "盖州市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 68,
            enumNameother: "盖州市",
          },
          {
            fldEnumId: 15343,
            enumGroupGuid: "1",
            parentEnumValue: "2108",
            enumValue: "210882",
            enumName: "大石桥市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 69,
            enumNameother: "大石桥市",
          },
        ],
      },
      {
        fldEnumId: 15345,
        enumGroupGuid: "1",
        parentEnumValue: "21",
        enumValue: "2109",
        enumName: "阜新市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 8,
        enumNameother: "阜新市",
        children: [
          {
            fldEnumId: 15346,
            enumGroupGuid: "1",
            parentEnumValue: "2109",
            enumValue: "210900",
            enumName: "阜新市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 70,
            enumNameother: "阜新市",
          },
          {
            fldEnumId: 15348,
            enumGroupGuid: "1",
            parentEnumValue: "2109",
            enumValue: "210902",
            enumName: "海州区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 71,
            enumNameother: "海州区",
          },
          {
            fldEnumId: 15350,
            enumGroupGuid: "1",
            parentEnumValue: "2109",
            enumValue: "210903",
            enumName: "新邱区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 72,
            enumNameother: "新邱区",
          },
          {
            fldEnumId: 15352,
            enumGroupGuid: "1",
            parentEnumValue: "2109",
            enumValue: "210904",
            enumName: "太平区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 73,
            enumNameother: "太平区",
          },
          {
            fldEnumId: 15354,
            enumGroupGuid: "1",
            parentEnumValue: "2109",
            enumValue: "210905",
            enumName: "清河门区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 74,
            enumNameother: "清河门区",
          },
          {
            fldEnumId: 15356,
            enumGroupGuid: "1",
            parentEnumValue: "2109",
            enumValue: "210911",
            enumName: "细河区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 75,
            enumNameother: "细河区",
          },
          {
            fldEnumId: 15358,
            enumGroupGuid: "1",
            parentEnumValue: "2109",
            enumValue: "210921",
            enumName: "阜新蒙古族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 76,
            enumNameother: "阜新蒙古族自治县",
          },
          {
            fldEnumId: 15360,
            enumGroupGuid: "1",
            parentEnumValue: "2109",
            enumValue: "210922",
            enumName: "彰武县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 77,
            enumNameother: "彰武县",
          },
        ],
      },
      {
        fldEnumId: 15362,
        enumGroupGuid: "1",
        parentEnumValue: "21",
        enumValue: "2110",
        enumName: "辽阳市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 9,
        enumNameother: "辽阳市",
        children: [
          {
            fldEnumId: 15363,
            enumGroupGuid: "1",
            parentEnumValue: "2110",
            enumValue: "211000",
            enumName: "辽阳市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 78,
            enumNameother: "辽阳市",
          },
          {
            fldEnumId: 15365,
            enumGroupGuid: "1",
            parentEnumValue: "2110",
            enumValue: "211002",
            enumName: "白塔区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 79,
            enumNameother: "白塔区",
          },
          {
            fldEnumId: 15367,
            enumGroupGuid: "1",
            parentEnumValue: "2110",
            enumValue: "211003",
            enumName: "文圣区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 80,
            enumNameother: "文圣区",
          },
          {
            fldEnumId: 15369,
            enumGroupGuid: "1",
            parentEnumValue: "2110",
            enumValue: "211004",
            enumName: "宏伟区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 81,
            enumNameother: "宏伟区",
          },
          {
            fldEnumId: 15371,
            enumGroupGuid: "1",
            parentEnumValue: "2110",
            enumValue: "211005",
            enumName: "弓长岭区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 82,
            enumNameother: "弓长岭区",
          },
          {
            fldEnumId: 15373,
            enumGroupGuid: "1",
            parentEnumValue: "2110",
            enumValue: "211011",
            enumName: "太子河区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 83,
            enumNameother: "太子河区",
          },
          {
            fldEnumId: 15375,
            enumGroupGuid: "1",
            parentEnumValue: "2110",
            enumValue: "211021",
            enumName: "辽阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 84,
            enumNameother: "辽阳县",
          },
          {
            fldEnumId: 15377,
            enumGroupGuid: "1",
            parentEnumValue: "2110",
            enumValue: "211081",
            enumName: "灯塔市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 85,
            enumNameother: "灯塔市",
          },
        ],
      },
      {
        fldEnumId: 15379,
        enumGroupGuid: "1",
        parentEnumValue: "21",
        enumValue: "2111",
        enumName: "盘锦市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 10,
        enumNameother: "盘锦市",
        children: [
          {
            fldEnumId: 15380,
            enumGroupGuid: "1",
            parentEnumValue: "2111",
            enumValue: "211100",
            enumName: "盘锦市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 86,
            enumNameother: "盘锦市",
          },
          {
            fldEnumId: 15382,
            enumGroupGuid: "1",
            parentEnumValue: "2111",
            enumValue: "211102",
            enumName: "双台子区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 87,
            enumNameother: "双台子区",
          },
          {
            fldEnumId: 15384,
            enumGroupGuid: "1",
            parentEnumValue: "2111",
            enumValue: "211103",
            enumName: "兴隆台区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 88,
            enumNameother: "兴隆台区",
          },
          {
            fldEnumId: 15386,
            enumGroupGuid: "1",
            parentEnumValue: "2111",
            enumValue: "211121",
            enumName: "大洼县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 89,
            enumNameother: "大洼县",
          },
          {
            fldEnumId: 15388,
            enumGroupGuid: "1",
            parentEnumValue: "2111",
            enumValue: "211122",
            enumName: "盘山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 90,
            enumNameother: "盘山县",
          },
        ],
      },
      {
        fldEnumId: 15390,
        enumGroupGuid: "1",
        parentEnumValue: "21",
        enumValue: "2112",
        enumName: "铁岭市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 11,
        enumNameother: "铁岭市",
        children: [
          {
            fldEnumId: 15391,
            enumGroupGuid: "1",
            parentEnumValue: "2112",
            enumValue: "211200",
            enumName: "铁岭市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 91,
            enumNameother: "铁岭市",
          },
          {
            fldEnumId: 15393,
            enumGroupGuid: "1",
            parentEnumValue: "2112",
            enumValue: "211202",
            enumName: "银州区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 92,
            enumNameother: "银州区",
          },
          {
            fldEnumId: 15395,
            enumGroupGuid: "1",
            parentEnumValue: "2112",
            enumValue: "211204",
            enumName: "清河区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 93,
            enumNameother: "清河区",
          },
          {
            fldEnumId: 15397,
            enumGroupGuid: "1",
            parentEnumValue: "2112",
            enumValue: "211221",
            enumName: "铁岭县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 94,
            enumNameother: "铁岭县",
          },
          {
            fldEnumId: 15399,
            enumGroupGuid: "1",
            parentEnumValue: "2112",
            enumValue: "211223",
            enumName: "西丰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 95,
            enumNameother: "西丰县",
          },
          {
            fldEnumId: 15401,
            enumGroupGuid: "1",
            parentEnumValue: "2112",
            enumValue: "211224",
            enumName: "昌图县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 96,
            enumNameother: "昌图县",
          },
          {
            fldEnumId: 15403,
            enumGroupGuid: "1",
            parentEnumValue: "2112",
            enumValue: "211281",
            enumName: "调兵山市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 97,
            enumNameother: "调兵山市",
          },
          {
            fldEnumId: 15405,
            enumGroupGuid: "1",
            parentEnumValue: "2112",
            enumValue: "211282",
            enumName: "开原市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 98,
            enumNameother: "开原市",
          },
        ],
      },
      {
        fldEnumId: 15407,
        enumGroupGuid: "1",
        parentEnumValue: "21",
        enumValue: "2113",
        enumName: "朝阳市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 12,
        enumNameother: "朝阳市",
        children: [
          {
            fldEnumId: 15408,
            enumGroupGuid: "1",
            parentEnumValue: "2113",
            enumValue: "211300",
            enumName: "朝阳市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 99,
            enumNameother: "朝阳市",
          },
          {
            fldEnumId: 15410,
            enumGroupGuid: "1",
            parentEnumValue: "2113",
            enumValue: "211302",
            enumName: "双塔区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 100,
            enumNameother: "双塔区",
          },
          {
            fldEnumId: 15412,
            enumGroupGuid: "1",
            parentEnumValue: "2113",
            enumValue: "211303",
            enumName: "龙城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 101,
            enumNameother: "龙城区",
          },
          {
            fldEnumId: 15414,
            enumGroupGuid: "1",
            parentEnumValue: "2113",
            enumValue: "211321",
            enumName: "朝阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 102,
            enumNameother: "朝阳县",
          },
          {
            fldEnumId: 15416,
            enumGroupGuid: "1",
            parentEnumValue: "2113",
            enumValue: "211322",
            enumName: "建平县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 103,
            enumNameother: "建平县",
          },
          {
            fldEnumId: 15418,
            enumGroupGuid: "1",
            parentEnumValue: "2113",
            enumValue: "211324",
            enumName: "喀喇沁左翼蒙古族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 104,
            enumNameother: "喀喇沁左翼蒙古族自治县",
          },
          {
            fldEnumId: 15420,
            enumGroupGuid: "1",
            parentEnumValue: "2113",
            enumValue: "211381",
            enumName: "北票市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 105,
            enumNameother: "北票市",
          },
          {
            fldEnumId: 15422,
            enumGroupGuid: "1",
            parentEnumValue: "2113",
            enumValue: "211382",
            enumName: "凌源市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 106,
            enumNameother: "凌源市",
          },
        ],
      },
      {
        fldEnumId: 15424,
        enumGroupGuid: "1",
        parentEnumValue: "21",
        enumValue: "2114",
        enumName: "葫芦岛市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 13,
        enumNameother: "葫芦岛市",
        children: [
          {
            fldEnumId: 15425,
            enumGroupGuid: "1",
            parentEnumValue: "2114",
            enumValue: "211400",
            enumName: "葫芦岛市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 107,
            enumNameother: "葫芦岛市",
          },
          {
            fldEnumId: 15427,
            enumGroupGuid: "1",
            parentEnumValue: "2114",
            enumValue: "211402",
            enumName: "连山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 108,
            enumNameother: "连山区",
          },
          {
            fldEnumId: 15429,
            enumGroupGuid: "1",
            parentEnumValue: "2114",
            enumValue: "211403",
            enumName: "龙港区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 109,
            enumNameother: "龙港区",
          },
          {
            fldEnumId: 15431,
            enumGroupGuid: "1",
            parentEnumValue: "2114",
            enumValue: "211404",
            enumName: "南票区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 110,
            enumNameother: "南票区",
          },
          {
            fldEnumId: 15433,
            enumGroupGuid: "1",
            parentEnumValue: "2114",
            enumValue: "211421",
            enumName: "绥中县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 111,
            enumNameother: "绥中县",
          },
          {
            fldEnumId: 15435,
            enumGroupGuid: "1",
            parentEnumValue: "2114",
            enumValue: "211422",
            enumName: "建昌县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 112,
            enumNameother: "建昌县",
          },
          {
            fldEnumId: 15437,
            enumGroupGuid: "1",
            parentEnumValue: "2114",
            enumValue: "211481",
            enumName: "兴城市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 113,
            enumNameother: "兴城市",
          },
        ],
      },
    ],
  },
  {
    enumValue: "22",
    enumName: "吉林省",
    children: [
      {
        fldEnumId: 15440,
        enumGroupGuid: "1",
        parentEnumValue: "22",
        enumValue: "2201",
        enumName: "长春市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        enumNameother: "长春市",
        children: [
          {
            fldEnumId: 15441,
            enumGroupGuid: "1",
            parentEnumValue: "2201",
            enumValue: "220100",
            enumName: "长春市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
            enumNameother: "长春市",
          },
          {
            fldEnumId: 15443,
            enumGroupGuid: "1",
            parentEnumValue: "2201",
            enumValue: "220102",
            enumName: "南关区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "南关区",
          },
          {
            fldEnumId: 15445,
            enumGroupGuid: "1",
            parentEnumValue: "2201",
            enumValue: "220103",
            enumName: "宽城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 2,
            enumNameother: "宽城区",
          },
          {
            fldEnumId: 15447,
            enumGroupGuid: "1",
            parentEnumValue: "2201",
            enumValue: "220104",
            enumName: "朝阳区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 3,
            enumNameother: "朝阳区",
          },
          {
            fldEnumId: 15449,
            enumGroupGuid: "1",
            parentEnumValue: "2201",
            enumValue: "220105",
            enumName: "二道区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 4,
            enumNameother: "二道区",
          },
          {
            fldEnumId: 15451,
            enumGroupGuid: "1",
            parentEnumValue: "2201",
            enumValue: "220106",
            enumName: "绿园区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 5,
            enumNameother: "绿园区",
          },
          {
            fldEnumId: 15453,
            enumGroupGuid: "1",
            parentEnumValue: "2201",
            enumValue: "220112",
            enumName: "双阳区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 6,
            enumNameother: "双阳区",
          },
          {
            fldEnumId: 15455,
            enumGroupGuid: "1",
            parentEnumValue: "2201",
            enumValue: "220122",
            enumName: "农安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 7,
            enumNameother: "农安县",
          },
          {
            fldEnumId: 15457,
            enumGroupGuid: "1",
            parentEnumValue: "2201",
            enumValue: "220181",
            enumName: "九台市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 8,
            enumNameother: "九台市",
          },
          {
            fldEnumId: 15459,
            enumGroupGuid: "1",
            parentEnumValue: "2201",
            enumValue: "220182",
            enumName: "榆树市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 9,
            enumNameother: "榆树市",
          },
          {
            fldEnumId: 15461,
            enumGroupGuid: "1",
            parentEnumValue: "2201",
            enumValue: "220183",
            enumName: "德惠市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 10,
            enumNameother: "德惠市",
          },
        ],
      },
      {
        fldEnumId: 15463,
        enumGroupGuid: "1",
        parentEnumValue: "22",
        enumValue: "2202",
        enumName: "吉林市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 1,
        enumNameother: "吉林市",
        children: [
          {
            fldEnumId: 15464,
            enumGroupGuid: "1",
            parentEnumValue: "2202",
            enumValue: "220200",
            enumName: "吉林市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 11,
            enumNameother: "吉林市",
          },
          {
            fldEnumId: 15466,
            enumGroupGuid: "1",
            parentEnumValue: "2202",
            enumValue: "220202",
            enumName: "昌邑区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 12,
            enumNameother: "昌邑区",
          },
          {
            fldEnumId: 15468,
            enumGroupGuid: "1",
            parentEnumValue: "2202",
            enumValue: "220203",
            enumName: "龙潭区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 13,
            enumNameother: "龙潭区",
          },
          {
            fldEnumId: 15470,
            enumGroupGuid: "1",
            parentEnumValue: "2202",
            enumValue: "220204",
            enumName: "船营区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 14,
            enumNameother: "船营区",
          },
          {
            fldEnumId: 15472,
            enumGroupGuid: "1",
            parentEnumValue: "2202",
            enumValue: "220211",
            enumName: "丰满区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 15,
            enumNameother: "丰满区",
          },
          {
            fldEnumId: 15474,
            enumGroupGuid: "1",
            parentEnumValue: "2202",
            enumValue: "220221",
            enumName: "永吉县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 16,
            enumNameother: "永吉县",
          },
          {
            fldEnumId: 15476,
            enumGroupGuid: "1",
            parentEnumValue: "2202",
            enumValue: "220281",
            enumName: "蛟河市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 17,
            enumNameother: "蛟河市",
          },
          {
            fldEnumId: 15478,
            enumGroupGuid: "1",
            parentEnumValue: "2202",
            enumValue: "220282",
            enumName: "桦甸市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 18,
            enumNameother: "桦甸市",
          },
          {
            fldEnumId: 15480,
            enumGroupGuid: "1",
            parentEnumValue: "2202",
            enumValue: "220283",
            enumName: "舒兰市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 19,
            enumNameother: "舒兰市",
          },
          {
            fldEnumId: 15482,
            enumGroupGuid: "1",
            parentEnumValue: "2202",
            enumValue: "220284",
            enumName: "磐石市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 20,
            enumNameother: "磐石市",
          },
        ],
      },
      {
        fldEnumId: 15484,
        enumGroupGuid: "1",
        parentEnumValue: "22",
        enumValue: "2203",
        enumName: "四平市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 2,
        enumNameother: "四平市",
        children: [
          {
            fldEnumId: 15485,
            enumGroupGuid: "1",
            parentEnumValue: "2203",
            enumValue: "220300",
            enumName: "四平市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 21,
            enumNameother: "四平市",
          },
          {
            fldEnumId: 15487,
            enumGroupGuid: "1",
            parentEnumValue: "2203",
            enumValue: "220302",
            enumName: "铁西区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 22,
            enumNameother: "铁西区",
          },
          {
            fldEnumId: 15489,
            enumGroupGuid: "1",
            parentEnumValue: "2203",
            enumValue: "220303",
            enumName: "铁东区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 23,
            enumNameother: "铁东区",
          },
          {
            fldEnumId: 15491,
            enumGroupGuid: "1",
            parentEnumValue: "2203",
            enumValue: "220322",
            enumName: "梨树县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 24,
            enumNameother: "梨树县",
          },
          {
            fldEnumId: 15493,
            enumGroupGuid: "1",
            parentEnumValue: "2203",
            enumValue: "220323",
            enumName: "伊通满族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 25,
            enumNameother: "伊通满族自治县",
          },
          {
            fldEnumId: 15495,
            enumGroupGuid: "1",
            parentEnumValue: "2203",
            enumValue: "220381",
            enumName: "公主岭市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 26,
            enumNameother: "公主岭市",
          },
          {
            fldEnumId: 15497,
            enumGroupGuid: "1",
            parentEnumValue: "2203",
            enumValue: "220382",
            enumName: "双辽市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 27,
            enumNameother: "双辽市",
          },
        ],
      },
      {
        fldEnumId: 15499,
        enumGroupGuid: "1",
        parentEnumValue: "22",
        enumValue: "2204",
        enumName: "辽源市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 3,
        enumNameother: "辽源市",
        children: [
          {
            fldEnumId: 15500,
            enumGroupGuid: "1",
            parentEnumValue: "2204",
            enumValue: "220400",
            enumName: "辽源市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 28,
            enumNameother: "辽源市",
          },
          {
            fldEnumId: 15502,
            enumGroupGuid: "1",
            parentEnumValue: "2204",
            enumValue: "220402",
            enumName: "龙山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 29,
            enumNameother: "龙山区",
          },
          {
            fldEnumId: 15504,
            enumGroupGuid: "1",
            parentEnumValue: "2204",
            enumValue: "220403",
            enumName: "西安区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 30,
            enumNameother: "西安区",
          },
          {
            fldEnumId: 15506,
            enumGroupGuid: "1",
            parentEnumValue: "2204",
            enumValue: "220421",
            enumName: "东丰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 31,
            enumNameother: "东丰县",
          },
          {
            fldEnumId: 15508,
            enumGroupGuid: "1",
            parentEnumValue: "2204",
            enumValue: "220422",
            enumName: "东辽县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 32,
            enumNameother: "东辽县",
          },
        ],
      },
      {
        fldEnumId: 15510,
        enumGroupGuid: "1",
        parentEnumValue: "22",
        enumValue: "2205",
        enumName: "通化市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 4,
        enumNameother: "通化市",
        children: [
          {
            fldEnumId: 15511,
            enumGroupGuid: "1",
            parentEnumValue: "2205",
            enumValue: "220500",
            enumName: "通化市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 33,
            enumNameother: "通化市",
          },
          {
            fldEnumId: 8843,
            enumGroupGuid: "1",
            parentEnumValue: "2205",
            enumValue: "220502",
            enumName: "东昌区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 34,
            enumNameother: "东昌区",
          },
          {
            fldEnumId: 8845,
            enumGroupGuid: "1",
            parentEnumValue: "2205",
            enumValue: "220503",
            enumName: "二道江区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 35,
            enumNameother: "二道江区",
          },
          {
            fldEnumId: 8847,
            enumGroupGuid: "1",
            parentEnumValue: "2205",
            enumValue: "220521",
            enumName: "通化县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 36,
            enumNameother: "通化县",
          },
          {
            fldEnumId: 8849,
            enumGroupGuid: "1",
            parentEnumValue: "2205",
            enumValue: "220523",
            enumName: "辉南县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 37,
            enumNameother: "辉南县",
          },
          {
            fldEnumId: 8851,
            enumGroupGuid: "1",
            parentEnumValue: "2205",
            enumValue: "220524",
            enumName: "柳河县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 38,
            enumNameother: "柳河县",
          },
          {
            fldEnumId: 8853,
            enumGroupGuid: "1",
            parentEnumValue: "2205",
            enumValue: "220581",
            enumName: "梅河口市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 39,
            enumNameother: "梅河口市",
          },
          {
            fldEnumId: 8855,
            enumGroupGuid: "1",
            parentEnumValue: "2205",
            enumValue: "220582",
            enumName: "集安市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 40,
            enumNameother: "集安市",
          },
        ],
      },
      {
        fldEnumId: 8857,
        enumGroupGuid: "1",
        parentEnumValue: "22",
        enumValue: "2206",
        enumName: "白山市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 5,
        enumNameother: "白山市",
        children: [
          {
            fldEnumId: 8858,
            enumGroupGuid: "1",
            parentEnumValue: "2206",
            enumValue: "220600",
            enumName: "白山市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 41,
            enumNameother: "白山市",
          },
          {
            fldEnumId: 8860,
            enumGroupGuid: "1",
            parentEnumValue: "2206",
            enumValue: "220602",
            enumName: "八道江区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 42,
            enumNameother: "八道江区",
          },
          {
            fldEnumId: 8862,
            enumGroupGuid: "1",
            parentEnumValue: "2206",
            enumValue: "220605",
            enumName: "江源区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 43,
            enumNameother: "江源区",
          },
          {
            fldEnumId: 8864,
            enumGroupGuid: "1",
            parentEnumValue: "2206",
            enumValue: "220621",
            enumName: "抚松县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 44,
            enumNameother: "抚松县",
          },
          {
            fldEnumId: 8866,
            enumGroupGuid: "1",
            parentEnumValue: "2206",
            enumValue: "220622",
            enumName: "靖宇县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 45,
            enumNameother: "靖宇县",
          },
          {
            fldEnumId: 8868,
            enumGroupGuid: "1",
            parentEnumValue: "2206",
            enumValue: "220623",
            enumName: "长白朝鲜族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 46,
            enumNameother: "长白朝鲜族自治县",
          },
          {
            fldEnumId: 8870,
            enumGroupGuid: "1",
            parentEnumValue: "2206",
            enumValue: "220681",
            enumName: "临江市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 47,
            enumNameother: "临江市",
          },
        ],
      },
      {
        fldEnumId: 8872,
        enumGroupGuid: "1",
        parentEnumValue: "22",
        enumValue: "2207",
        enumName: "松原市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 6,
        enumNameother: "松原市",
        children: [
          {
            fldEnumId: 8873,
            enumGroupGuid: "1",
            parentEnumValue: "2207",
            enumValue: "220700",
            enumName: "松原市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 48,
            enumNameother: "松原市",
          },
          {
            fldEnumId: 8875,
            enumGroupGuid: "1",
            parentEnumValue: "2207",
            enumValue: "220702",
            enumName: "宁江区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 49,
            enumNameother: "宁江区",
          },
          {
            fldEnumId: 8877,
            enumGroupGuid: "1",
            parentEnumValue: "2207",
            enumValue: "220721",
            enumName: "前郭尔罗斯蒙古族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 50,
            enumNameother: "前郭尔罗斯蒙古族自治县",
          },
          {
            fldEnumId: 8879,
            enumGroupGuid: "1",
            parentEnumValue: "2207",
            enumValue: "220722",
            enumName: "长岭县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 51,
            enumNameother: "长岭县",
          },
          {
            fldEnumId: 8881,
            enumGroupGuid: "1",
            parentEnumValue: "2207",
            enumValue: "220723",
            enumName: "乾安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 52,
            enumNameother: "乾安县",
          },
          {
            fldEnumId: 8883,
            enumGroupGuid: "1",
            parentEnumValue: "2207",
            enumValue: "220724",
            enumName: "扶余县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 53,
            enumNameother: "扶余县",
          },
        ],
      },
      {
        fldEnumId: 8885,
        enumGroupGuid: "1",
        parentEnumValue: "22",
        enumValue: "2208",
        enumName: "白城市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 7,
        enumNameother: "白城市",
        children: [
          {
            fldEnumId: 8886,
            enumGroupGuid: "1",
            parentEnumValue: "2208",
            enumValue: "220800",
            enumName: "白城市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 54,
            enumNameother: "白城市",
          },
          {
            fldEnumId: 8888,
            enumGroupGuid: "1",
            parentEnumValue: "2208",
            enumValue: "220802",
            enumName: "洮北区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 55,
            enumNameother: "洮北区",
          },
          {
            fldEnumId: 8890,
            enumGroupGuid: "1",
            parentEnumValue: "2208",
            enumValue: "220821",
            enumName: "镇赉县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 56,
            enumNameother: "镇赉县",
          },
          {
            fldEnumId: 8892,
            enumGroupGuid: "1",
            parentEnumValue: "2208",
            enumValue: "220822",
            enumName: "通榆县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 57,
            enumNameother: "通榆县",
          },
          {
            fldEnumId: 8894,
            enumGroupGuid: "1",
            parentEnumValue: "2208",
            enumValue: "220881",
            enumName: "洮南市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 58,
            enumNameother: "洮南市",
          },
          {
            fldEnumId: 8896,
            enumGroupGuid: "1",
            parentEnumValue: "2208",
            enumValue: "220882",
            enumName: "大安市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 59,
            enumNameother: "大安市",
          },
        ],
      },
      {
        fldEnumId: 8898,
        enumGroupGuid: "1",
        parentEnumValue: "22",
        enumValue: "2224",
        enumName: "延边朝鲜族自治州",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 8,
        enumNameother: "延边朝鲜族自治州",
        children: [
          {
            fldEnumId: 16170,
            enumGroupGuid: "1",
            parentEnumValue: "2224",
            enumValue: "222400",
            enumName: "延边朝鲜族自治州本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 8899,
            enumGroupGuid: "1",
            parentEnumValue: "2224",
            enumValue: "222401",
            enumName: "延吉市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 60,
            enumNameother: "延吉市",
          },
          {
            fldEnumId: 8901,
            enumGroupGuid: "1",
            parentEnumValue: "2224",
            enumValue: "222402",
            enumName: "图们市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 61,
            enumNameother: "图们市",
          },
          {
            fldEnumId: 8903,
            enumGroupGuid: "1",
            parentEnumValue: "2224",
            enumValue: "222403",
            enumName: "敦化市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 62,
            enumNameother: "敦化市",
          },
          {
            fldEnumId: 8905,
            enumGroupGuid: "1",
            parentEnumValue: "2224",
            enumValue: "222404",
            enumName: "珲春市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 63,
            enumNameother: "珲春市",
          },
          {
            fldEnumId: 8907,
            enumGroupGuid: "1",
            parentEnumValue: "2224",
            enumValue: "222405",
            enumName: "龙井市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 64,
            enumNameother: "龙井市",
          },
          {
            fldEnumId: 8909,
            enumGroupGuid: "1",
            parentEnumValue: "2224",
            enumValue: "222406",
            enumName: "和龙市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 65,
            enumNameother: "和龙市",
          },
          {
            fldEnumId: 8911,
            enumGroupGuid: "1",
            parentEnumValue: "2224",
            enumValue: "222424",
            enumName: "汪清县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 66,
            enumNameother: "汪清县",
          },
          {
            fldEnumId: 8913,
            enumGroupGuid: "1",
            parentEnumValue: "2224",
            enumValue: "222426",
            enumName: "安图县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 67,
            enumNameother: "安图县",
          },
        ],
      },
    ],
  },
  {
    enumValue: "23",
    enumName: "黑龙江省",
    children: [
      {
        fldEnumId: 8916,
        enumGroupGuid: "1",
        parentEnumValue: "23",
        enumValue: "2301",
        enumName: "哈尔滨市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        enumNameother: "哈尔滨市",
        children: [
          {
            fldEnumId: 8917,
            enumGroupGuid: "1",
            parentEnumValue: "2301",
            enumValue: "230100",
            enumName: "哈尔滨市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
            enumNameother: "哈尔滨市",
          },
          {
            fldEnumId: 8919,
            enumGroupGuid: "1",
            parentEnumValue: "2301",
            enumValue: "230102",
            enumName: "道里区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "道里区",
          },
          {
            fldEnumId: 8921,
            enumGroupGuid: "1",
            parentEnumValue: "2301",
            enumValue: "230103",
            enumName: "南岗区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 2,
            enumNameother: "南岗区",
          },
          {
            fldEnumId: 8923,
            enumGroupGuid: "1",
            parentEnumValue: "2301",
            enumValue: "230104",
            enumName: "道外区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 3,
            enumNameother: "道外区",
          },
          {
            fldEnumId: 8925,
            enumGroupGuid: "1",
            parentEnumValue: "2301",
            enumValue: "230108",
            enumName: "平房区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 4,
            enumNameother: "平房区",
          },
          {
            fldEnumId: 8927,
            enumGroupGuid: "1",
            parentEnumValue: "2301",
            enumValue: "230109",
            enumName: "松北区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 5,
            enumNameother: "松北区",
          },
          {
            fldEnumId: 8929,
            enumGroupGuid: "1",
            parentEnumValue: "2301",
            enumValue: "230110",
            enumName: "香坊区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 6,
            enumNameother: "香坊区",
          },
          {
            fldEnumId: 8931,
            enumGroupGuid: "1",
            parentEnumValue: "2301",
            enumValue: "230111",
            enumName: "呼兰区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 7,
            enumNameother: "呼兰区",
          },
          {
            fldEnumId: 8933,
            enumGroupGuid: "1",
            parentEnumValue: "2301",
            enumValue: "230112",
            enumName: "阿城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 8,
            enumNameother: "阿城区",
          },
          {
            fldEnumId: 8935,
            enumGroupGuid: "1",
            parentEnumValue: "2301",
            enumValue: "230123",
            enumName: "依兰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 9,
            enumNameother: "依兰县",
          },
          {
            fldEnumId: 8937,
            enumGroupGuid: "1",
            parentEnumValue: "2301",
            enumValue: "230124",
            enumName: "方正县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 10,
            enumNameother: "方正县",
          },
          {
            fldEnumId: 8939,
            enumGroupGuid: "1",
            parentEnumValue: "2301",
            enumValue: "230125",
            enumName: "宾县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 11,
            enumNameother: "宾县",
          },
          {
            fldEnumId: 8941,
            enumGroupGuid: "1",
            parentEnumValue: "2301",
            enumValue: "230126",
            enumName: "巴彦县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 12,
            enumNameother: "巴彦县",
          },
          {
            fldEnumId: 8943,
            enumGroupGuid: "1",
            parentEnumValue: "2301",
            enumValue: "230127",
            enumName: "木兰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 13,
            enumNameother: "木兰县",
          },
          {
            fldEnumId: 8945,
            enumGroupGuid: "1",
            parentEnumValue: "2301",
            enumValue: "230128",
            enumName: "通河县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 14,
            enumNameother: "通河县",
          },
          {
            fldEnumId: 8947,
            enumGroupGuid: "1",
            parentEnumValue: "2301",
            enumValue: "230129",
            enumName: "延寿县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 15,
            enumNameother: "延寿县",
          },
          {
            fldEnumId: 8949,
            enumGroupGuid: "1",
            parentEnumValue: "2301",
            enumValue: "230182",
            enumName: "双城市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 16,
            enumNameother: "双城市",
          },
          {
            fldEnumId: 8951,
            enumGroupGuid: "1",
            parentEnumValue: "2301",
            enumValue: "230183",
            enumName: "尚志市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 17,
            enumNameother: "尚志市",
          },
          {
            fldEnumId: 8953,
            enumGroupGuid: "1",
            parentEnumValue: "2301",
            enumValue: "230184",
            enumName: "五常市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 18,
            enumNameother: "五常市",
          },
        ],
      },
      {
        fldEnumId: 130120,
        enumGroupGuid: "1",
        parentEnumValue: "23",
        enumValue: "2391",
        enumName: "黑龙江农垦总局",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        children: [
          {
            fldEnumId: 130130,
            enumGroupGuid: "1",
            parentEnumValue: "2391",
            enumValue: "239101",
            enumName: "宝泉岭分局(撤)",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130131,
            enumGroupGuid: "1",
            parentEnumValue: "2391",
            enumValue: "239102",
            enumName: "国土资源局(撤)",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130132,
            enumGroupGuid: "1",
            parentEnumValue: "2391",
            enumValue: "239103",
            enumName: "红兴隆分局(撤)",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130133,
            enumGroupGuid: "1",
            parentEnumValue: "2391",
            enumValue: "239104",
            enumName: "建三江分局(撤)",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130134,
            enumGroupGuid: "1",
            parentEnumValue: "2391",
            enumValue: "239105",
            enumName: "牡丹江分局(撤)",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130135,
            enumGroupGuid: "1",
            parentEnumValue: "2391",
            enumValue: "239106",
            enumName: "北安分局(撤)",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130136,
            enumGroupGuid: "1",
            parentEnumValue: "2391",
            enumValue: "239107",
            enumName: "九三分局(撤)",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130137,
            enumGroupGuid: "1",
            parentEnumValue: "2391",
            enumValue: "239108",
            enumName: "齐齐哈尔分局(撤)",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130138,
            enumGroupGuid: "1",
            parentEnumValue: "2391",
            enumValue: "239109",
            enumName: "绥化分局(撤)",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130139,
            enumGroupGuid: "1",
            parentEnumValue: "2391",
            enumValue: "239110",
            enumName: "哈尔滨分局(撤)",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130140,
            enumGroupGuid: "1",
            parentEnumValue: "2391",
            enumValue: "239111",
            enumName: "直属佳南分局(撤)",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
        ],
      },
      {
        fldEnumId: 130121,
        enumGroupGuid: "1",
        parentEnumValue: "23",
        enumValue: "2392",
        enumName: "黑龙江森工总局(撤)",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        children: [
          {
            fldEnumId: 130141,
            enumGroupGuid: "1",
            parentEnumValue: "2392",
            enumValue: "239201",
            enumName: "国土资源局(撤)",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130142,
            enumGroupGuid: "1",
            parentEnumValue: "2392",
            enumValue: "239202",
            enumName: "松花江分局(撤)",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130143,
            enumGroupGuid: "1",
            parentEnumValue: "2392",
            enumValue: "239203",
            enumName: "牡丹江分局(撤)",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130144,
            enumGroupGuid: "1",
            parentEnumValue: "2392",
            enumValue: "239204",
            enumName: "合江分局(撤)",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
        ],
      },
      {
        fldEnumId: 130122,
        enumGroupGuid: "1",
        parentEnumValue: "23",
        enumValue: "2393",
        enumName: "友谊国土资源局(撤)",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        children: [
          {
            fldEnumId: 130145,
            enumGroupGuid: "1",
            parentEnumValue: "2393",
            enumValue: "239300",
            enumName: "友谊国土资源局(撤)",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
        ],
      },
      {
        fldEnumId: 130123,
        enumGroupGuid: "1",
        parentEnumValue: "23",
        enumValue: "2394",
        enumName: "五大连池风景名胜区(撤)",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        children: [
          {
            fldEnumId: 130146,
            enumGroupGuid: "1",
            parentEnumValue: "2394",
            enumValue: "239400",
            enumName: "五大连池风景名胜区(撤)",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
        ],
      },
      {
        fldEnumId: 8955,
        enumGroupGuid: "1",
        parentEnumValue: "23",
        enumValue: "2302",
        enumName: "齐齐哈尔市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 1,
        enumNameother: "齐齐哈尔市",
        children: [
          {
            fldEnumId: 8956,
            enumGroupGuid: "1",
            parentEnumValue: "2302",
            enumValue: "230200",
            enumName: "齐齐哈尔市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 19,
            enumNameother: "齐齐哈尔市",
          },
          {
            fldEnumId: 8958,
            enumGroupGuid: "1",
            parentEnumValue: "2302",
            enumValue: "230202",
            enumName: "龙沙区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 20,
            enumNameother: "龙沙区",
          },
          {
            fldEnumId: 8960,
            enumGroupGuid: "1",
            parentEnumValue: "2302",
            enumValue: "230203",
            enumName: "建华区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 21,
            enumNameother: "建华区",
          },
          {
            fldEnumId: 8962,
            enumGroupGuid: "1",
            parentEnumValue: "2302",
            enumValue: "230204",
            enumName: "铁锋区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 22,
            enumNameother: "铁锋区",
          },
          {
            fldEnumId: 8964,
            enumGroupGuid: "1",
            parentEnumValue: "2302",
            enumValue: "230205",
            enumName: "昂昂溪区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 23,
            enumNameother: "昂昂溪区",
          },
          {
            fldEnumId: 8966,
            enumGroupGuid: "1",
            parentEnumValue: "2302",
            enumValue: "230206",
            enumName: "富拉尔基区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 24,
            enumNameother: "富拉尔基区",
          },
          {
            fldEnumId: 8968,
            enumGroupGuid: "1",
            parentEnumValue: "2302",
            enumValue: "230207",
            enumName: "碾子山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 25,
            enumNameother: "碾子山区",
          },
          {
            fldEnumId: 8970,
            enumGroupGuid: "1",
            parentEnumValue: "2302",
            enumValue: "230208",
            enumName: "梅里斯达斡尔族区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 26,
            enumNameother: "梅里斯达斡尔族区",
          },
          {
            fldEnumId: 8972,
            enumGroupGuid: "1",
            parentEnumValue: "2302",
            enumValue: "230221",
            enumName: "龙江县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 27,
            enumNameother: "龙江县",
          },
          {
            fldEnumId: 8974,
            enumGroupGuid: "1",
            parentEnumValue: "2302",
            enumValue: "230223",
            enumName: "依安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 28,
            enumNameother: "依安县",
          },
          {
            fldEnumId: 8976,
            enumGroupGuid: "1",
            parentEnumValue: "2302",
            enumValue: "230224",
            enumName: "泰来县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 29,
            enumNameother: "泰来县",
          },
          {
            fldEnumId: 8978,
            enumGroupGuid: "1",
            parentEnumValue: "2302",
            enumValue: "230225",
            enumName: "甘南县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 30,
            enumNameother: "甘南县",
          },
          {
            fldEnumId: 8980,
            enumGroupGuid: "1",
            parentEnumValue: "2302",
            enumValue: "230227",
            enumName: "富裕县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 31,
            enumNameother: "富裕县",
          },
          {
            fldEnumId: 8982,
            enumGroupGuid: "1",
            parentEnumValue: "2302",
            enumValue: "230229",
            enumName: "克山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 32,
            enumNameother: "克山县",
          },
          {
            fldEnumId: 8984,
            enumGroupGuid: "1",
            parentEnumValue: "2302",
            enumValue: "230230",
            enumName: "克东县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 33,
            enumNameother: "克东县",
          },
          {
            fldEnumId: 8986,
            enumGroupGuid: "1",
            parentEnumValue: "2302",
            enumValue: "230231",
            enumName: "拜泉县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 34,
            enumNameother: "拜泉县",
          },
          {
            fldEnumId: 8988,
            enumGroupGuid: "1",
            parentEnumValue: "2302",
            enumValue: "230281",
            enumName: "讷河市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 35,
            enumNameother: "讷河市",
          },
        ],
      },
      {
        fldEnumId: 8990,
        enumGroupGuid: "1",
        parentEnumValue: "23",
        enumValue: "2303",
        enumName: "鸡西市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 2,
        enumNameother: "鸡西市",
        children: [
          {
            fldEnumId: 8991,
            enumGroupGuid: "1",
            parentEnumValue: "2303",
            enumValue: "230300",
            enumName: "鸡西市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 36,
            enumNameother: "鸡西市",
          },
          {
            fldEnumId: 8993,
            enumGroupGuid: "1",
            parentEnumValue: "2303",
            enumValue: "230302",
            enumName: "鸡冠区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 37,
            enumNameother: "鸡冠区",
          },
          {
            fldEnumId: 8995,
            enumGroupGuid: "1",
            parentEnumValue: "2303",
            enumValue: "230303",
            enumName: "恒山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 38,
            enumNameother: "恒山区",
          },
          {
            fldEnumId: 8997,
            enumGroupGuid: "1",
            parentEnumValue: "2303",
            enumValue: "230304",
            enumName: "滴道区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 39,
            enumNameother: "滴道区",
          },
          {
            fldEnumId: 8999,
            enumGroupGuid: "1",
            parentEnumValue: "2303",
            enumValue: "230305",
            enumName: "梨树区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 40,
            enumNameother: "梨树区",
          },
          {
            fldEnumId: 9001,
            enumGroupGuid: "1",
            parentEnumValue: "2303",
            enumValue: "230306",
            enumName: "城子河区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 41,
            enumNameother: "城子河区",
          },
          {
            fldEnumId: 9003,
            enumGroupGuid: "1",
            parentEnumValue: "2303",
            enumValue: "230307",
            enumName: "麻山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 42,
            enumNameother: "麻山区",
          },
          {
            fldEnumId: 9005,
            enumGroupGuid: "1",
            parentEnumValue: "2303",
            enumValue: "230321",
            enumName: "鸡东县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 43,
            enumNameother: "鸡东县",
          },
          {
            fldEnumId: 9007,
            enumGroupGuid: "1",
            parentEnumValue: "2303",
            enumValue: "230381",
            enumName: "虎林市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 44,
            enumNameother: "虎林市",
          },
          {
            fldEnumId: 9009,
            enumGroupGuid: "1",
            parentEnumValue: "2303",
            enumValue: "230382",
            enumName: "密山市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 45,
            enumNameother: "密山市",
          },
        ],
      },
      {
        fldEnumId: 9011,
        enumGroupGuid: "1",
        parentEnumValue: "23",
        enumValue: "2304",
        enumName: "鹤岗市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 3,
        enumNameother: "鹤岗市",
        children: [
          {
            fldEnumId: 9012,
            enumGroupGuid: "1",
            parentEnumValue: "2304",
            enumValue: "230400",
            enumName: "鹤岗市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 46,
            enumNameother: "鹤岗市",
          },
          {
            fldEnumId: 9014,
            enumGroupGuid: "1",
            parentEnumValue: "2304",
            enumValue: "230402",
            enumName: "向阳区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 47,
            enumNameother: "向阳区",
          },
          {
            fldEnumId: 9016,
            enumGroupGuid: "1",
            parentEnumValue: "2304",
            enumValue: "230403",
            enumName: "工农区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 48,
            enumNameother: "工农区",
          },
          {
            fldEnumId: 9018,
            enumGroupGuid: "1",
            parentEnumValue: "2304",
            enumValue: "230404",
            enumName: "南山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 49,
            enumNameother: "南山区",
          },
          {
            fldEnumId: 9020,
            enumGroupGuid: "1",
            parentEnumValue: "2304",
            enumValue: "230405",
            enumName: "兴安区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 50,
            enumNameother: "兴安区",
          },
          {
            fldEnumId: 9022,
            enumGroupGuid: "1",
            parentEnumValue: "2304",
            enumValue: "230406",
            enumName: "东山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 51,
            enumNameother: "东山区",
          },
          {
            fldEnumId: 9024,
            enumGroupGuid: "1",
            parentEnumValue: "2304",
            enumValue: "230407",
            enumName: "兴山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 52,
            enumNameother: "兴山区",
          },
          {
            fldEnumId: 9026,
            enumGroupGuid: "1",
            parentEnumValue: "2304",
            enumValue: "230421",
            enumName: "萝北县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 53,
            enumNameother: "萝北县",
          },
          {
            fldEnumId: 9028,
            enumGroupGuid: "1",
            parentEnumValue: "2304",
            enumValue: "230422",
            enumName: "绥滨县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 54,
            enumNameother: "绥滨县",
          },
        ],
      },
      {
        fldEnumId: 9030,
        enumGroupGuid: "1",
        parentEnumValue: "23",
        enumValue: "2305",
        enumName: "双鸭山市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 4,
        enumNameother: "双鸭山市",
        children: [
          {
            fldEnumId: 9031,
            enumGroupGuid: "1",
            parentEnumValue: "2305",
            enumValue: "230500",
            enumName: "双鸭山市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 55,
            enumNameother: "双鸭山市",
          },
          {
            fldEnumId: 9033,
            enumGroupGuid: "1",
            parentEnumValue: "2305",
            enumValue: "230502",
            enumName: "尖山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 56,
            enumNameother: "尖山区",
          },
          {
            fldEnumId: 9035,
            enumGroupGuid: "1",
            parentEnumValue: "2305",
            enumValue: "230503",
            enumName: "岭东区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 57,
            enumNameother: "岭东区",
          },
          {
            fldEnumId: 9037,
            enumGroupGuid: "1",
            parentEnumValue: "2305",
            enumValue: "230505",
            enumName: "四方台区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 58,
            enumNameother: "四方台区",
          },
          {
            fldEnumId: 9039,
            enumGroupGuid: "1",
            parentEnumValue: "2305",
            enumValue: "230506",
            enumName: "宝山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 59,
            enumNameother: "宝山区",
          },
          {
            fldEnumId: 9041,
            enumGroupGuid: "1",
            parentEnumValue: "2305",
            enumValue: "230521",
            enumName: "集贤县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 60,
            enumNameother: "集贤县",
          },
          {
            fldEnumId: 9043,
            enumGroupGuid: "1",
            parentEnumValue: "2305",
            enumValue: "230522",
            enumName: "友谊县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 61,
            enumNameother: "友谊县",
          },
          {
            fldEnumId: 9045,
            enumGroupGuid: "1",
            parentEnumValue: "2305",
            enumValue: "230523",
            enumName: "宝清县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 62,
            enumNameother: "宝清县",
          },
          {
            fldEnumId: 9047,
            enumGroupGuid: "1",
            parentEnumValue: "2305",
            enumValue: "230524",
            enumName: "饶河县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 63,
            enumNameother: "饶河县",
          },
        ],
      },
      {
        fldEnumId: 9049,
        enumGroupGuid: "1",
        parentEnumValue: "23",
        enumValue: "2306",
        enumName: "大庆市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 5,
        enumNameother: "大庆市",
        children: [
          {
            fldEnumId: 9050,
            enumGroupGuid: "1",
            parentEnumValue: "2306",
            enumValue: "230600",
            enumName: "大庆市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 64,
            enumNameother: "大庆市",
          },
          {
            fldEnumId: 9052,
            enumGroupGuid: "1",
            parentEnumValue: "2306",
            enumValue: "230602",
            enumName: "萨尔图区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 65,
            enumNameother: "萨尔图区",
          },
          {
            fldEnumId: 9054,
            enumGroupGuid: "1",
            parentEnumValue: "2306",
            enumValue: "230603",
            enumName: "龙凤区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 66,
            enumNameother: "龙凤区",
          },
          {
            fldEnumId: 9056,
            enumGroupGuid: "1",
            parentEnumValue: "2306",
            enumValue: "230604",
            enumName: "让胡路区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 67,
            enumNameother: "让胡路区",
          },
          {
            fldEnumId: 9058,
            enumGroupGuid: "1",
            parentEnumValue: "2306",
            enumValue: "230605",
            enumName: "红岗区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 68,
            enumNameother: "红岗区",
          },
          {
            fldEnumId: 9060,
            enumGroupGuid: "1",
            parentEnumValue: "2306",
            enumValue: "230606",
            enumName: "大同区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 69,
            enumNameother: "大同区",
          },
          {
            fldEnumId: 9062,
            enumGroupGuid: "1",
            parentEnumValue: "2306",
            enumValue: "230621",
            enumName: "肇州县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 70,
            enumNameother: "肇州县",
          },
          {
            fldEnumId: 9064,
            enumGroupGuid: "1",
            parentEnumValue: "2306",
            enumValue: "230622",
            enumName: "肇源县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 71,
            enumNameother: "肇源县",
          },
          {
            fldEnumId: 9066,
            enumGroupGuid: "1",
            parentEnumValue: "2306",
            enumValue: "230623",
            enumName: "林甸县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 72,
            enumNameother: "林甸县",
          },
          {
            fldEnumId: 9068,
            enumGroupGuid: "1",
            parentEnumValue: "2306",
            enumValue: "230624",
            enumName: "杜尔伯特蒙古族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 73,
            enumNameother: "杜尔伯特蒙古族自治县",
          },
        ],
      },
      {
        fldEnumId: 9070,
        enumGroupGuid: "1",
        parentEnumValue: "23",
        enumValue: "2307",
        enumName: "伊春市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 6,
        enumNameother: "伊春市",
        children: [
          {
            fldEnumId: 9071,
            enumGroupGuid: "1",
            parentEnumValue: "2307",
            enumValue: "230700",
            enumName: "伊春市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 74,
            enumNameother: "伊春市",
          },
          {
            fldEnumId: 9073,
            enumGroupGuid: "1",
            parentEnumValue: "2307",
            enumValue: "230702",
            enumName: "伊春区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 75,
            enumNameother: "伊春区",
          },
          {
            fldEnumId: 9075,
            enumGroupGuid: "1",
            parentEnumValue: "2307",
            enumValue: "230703",
            enumName: "南岔区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 76,
            enumNameother: "南岔区",
          },
          {
            fldEnumId: 9077,
            enumGroupGuid: "1",
            parentEnumValue: "2307",
            enumValue: "230704",
            enumName: "友好区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 77,
            enumNameother: "友好区",
          },
          {
            fldEnumId: 9079,
            enumGroupGuid: "1",
            parentEnumValue: "2307",
            enumValue: "230705",
            enumName: "西林区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 78,
            enumNameother: "西林区",
          },
          {
            fldEnumId: 9081,
            enumGroupGuid: "1",
            parentEnumValue: "2307",
            enumValue: "230706",
            enumName: "翠峦区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 79,
            enumNameother: "翠峦区",
          },
          {
            fldEnumId: 9083,
            enumGroupGuid: "1",
            parentEnumValue: "2307",
            enumValue: "230707",
            enumName: "新青区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 80,
            enumNameother: "新青区",
          },
          {
            fldEnumId: 9085,
            enumGroupGuid: "1",
            parentEnumValue: "2307",
            enumValue: "230708",
            enumName: "美溪区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 81,
            enumNameother: "美溪区",
          },
          {
            fldEnumId: 9087,
            enumGroupGuid: "1",
            parentEnumValue: "2307",
            enumValue: "230709",
            enumName: "金山屯区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 82,
            enumNameother: "金山屯区",
          },
          {
            fldEnumId: 9089,
            enumGroupGuid: "1",
            parentEnumValue: "2307",
            enumValue: "230710",
            enumName: "五营区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 83,
            enumNameother: "五营区",
          },
          {
            fldEnumId: 9091,
            enumGroupGuid: "1",
            parentEnumValue: "2307",
            enumValue: "230711",
            enumName: "乌马河区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 84,
            enumNameother: "乌马河区",
          },
          {
            fldEnumId: 9093,
            enumGroupGuid: "1",
            parentEnumValue: "2307",
            enumValue: "230712",
            enumName: "汤旺河区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 85,
            enumNameother: "汤旺河区",
          },
          {
            fldEnumId: 9095,
            enumGroupGuid: "1",
            parentEnumValue: "2307",
            enumValue: "230713",
            enumName: "带岭区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 86,
            enumNameother: "带岭区",
          },
          {
            fldEnumId: 9097,
            enumGroupGuid: "1",
            parentEnumValue: "2307",
            enumValue: "230714",
            enumName: "乌伊岭区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 87,
            enumNameother: "乌伊岭区",
          },
          {
            fldEnumId: 9099,
            enumGroupGuid: "1",
            parentEnumValue: "2307",
            enumValue: "230715",
            enumName: "红星区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 88,
            enumNameother: "红星区",
          },
          {
            fldEnumId: 9101,
            enumGroupGuid: "1",
            parentEnumValue: "2307",
            enumValue: "230716",
            enumName: "上甘岭区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 89,
            enumNameother: "上甘岭区",
          },
          {
            fldEnumId: 9103,
            enumGroupGuid: "1",
            parentEnumValue: "2307",
            enumValue: "230722",
            enumName: "嘉荫县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 90,
            enumNameother: "嘉荫县",
          },
          {
            fldEnumId: 9105,
            enumGroupGuid: "1",
            parentEnumValue: "2307",
            enumValue: "230781",
            enumName: "铁力市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 91,
            enumNameother: "铁力市",
          },
        ],
      },
      {
        fldEnumId: 9107,
        enumGroupGuid: "1",
        parentEnumValue: "23",
        enumValue: "2308",
        enumName: "佳木斯市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 7,
        enumNameother: "佳木斯市",
        children: [
          {
            fldEnumId: 9108,
            enumGroupGuid: "1",
            parentEnumValue: "2308",
            enumValue: "230800",
            enumName: "佳木斯市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 92,
            enumNameother: "佳木斯市",
          },
          {
            fldEnumId: 9110,
            enumGroupGuid: "1",
            parentEnumValue: "2308",
            enumValue: "230803",
            enumName: "向阳区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 93,
            enumNameother: "向阳区",
          },
          {
            fldEnumId: 9112,
            enumGroupGuid: "1",
            parentEnumValue: "2308",
            enumValue: "230804",
            enumName: "前进区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 94,
            enumNameother: "前进区",
          },
          {
            fldEnumId: 9114,
            enumGroupGuid: "1",
            parentEnumValue: "2308",
            enumValue: "230805",
            enumName: "东风区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 95,
            enumNameother: "东风区",
          },
          {
            fldEnumId: 9116,
            enumGroupGuid: "1",
            parentEnumValue: "2308",
            enumValue: "230811",
            enumName: "郊区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 96,
            enumNameother: "郊区",
          },
          {
            fldEnumId: 9118,
            enumGroupGuid: "1",
            parentEnumValue: "2308",
            enumValue: "230822",
            enumName: "桦南县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 97,
            enumNameother: "桦南县",
          },
          {
            fldEnumId: 9120,
            enumGroupGuid: "1",
            parentEnumValue: "2308",
            enumValue: "230826",
            enumName: "桦川县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 98,
            enumNameother: "桦川县",
          },
          {
            fldEnumId: 9122,
            enumGroupGuid: "1",
            parentEnumValue: "2308",
            enumValue: "230828",
            enumName: "汤原县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 99,
            enumNameother: "汤原县",
          },
          {
            fldEnumId: 9124,
            enumGroupGuid: "1",
            parentEnumValue: "2308",
            enumValue: "230833",
            enumName: "抚远县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 100,
            enumNameother: "抚远县",
          },
          {
            fldEnumId: 9126,
            enumGroupGuid: "1",
            parentEnumValue: "2308",
            enumValue: "230881",
            enumName: "同江市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 101,
            enumNameother: "同江市",
          },
          {
            fldEnumId: 9128,
            enumGroupGuid: "1",
            parentEnumValue: "2308",
            enumValue: "230882",
            enumName: "富锦市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 102,
            enumNameother: "富锦市",
          },
        ],
      },
      {
        fldEnumId: 9130,
        enumGroupGuid: "1",
        parentEnumValue: "23",
        enumValue: "2309",
        enumName: "七台河市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 8,
        enumNameother: "七台河市",
        children: [
          {
            fldEnumId: 9131,
            enumGroupGuid: "1",
            parentEnumValue: "2309",
            enumValue: "230900",
            enumName: "七台河市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 103,
            enumNameother: "七台河市",
          },
          {
            fldEnumId: 9133,
            enumGroupGuid: "1",
            parentEnumValue: "2309",
            enumValue: "230902",
            enumName: "新兴区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 104,
            enumNameother: "新兴区",
          },
          {
            fldEnumId: 9135,
            enumGroupGuid: "1",
            parentEnumValue: "2309",
            enumValue: "230903",
            enumName: "桃山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 105,
            enumNameother: "桃山区",
          },
          {
            fldEnumId: 9137,
            enumGroupGuid: "1",
            parentEnumValue: "2309",
            enumValue: "230904",
            enumName: "茄子河区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 106,
            enumNameother: "茄子河区",
          },
          {
            fldEnumId: 9139,
            enumGroupGuid: "1",
            parentEnumValue: "2309",
            enumValue: "230921",
            enumName: "勃利县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 107,
            enumNameother: "勃利县",
          },
        ],
      },
      {
        fldEnumId: 9141,
        enumGroupGuid: "1",
        parentEnumValue: "23",
        enumValue: "2310",
        enumName: "牡丹江市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 9,
        enumNameother: "牡丹江市",
        children: [
          {
            fldEnumId: 9142,
            enumGroupGuid: "1",
            parentEnumValue: "2310",
            enumValue: "231000",
            enumName: "牡丹江市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 108,
            enumNameother: "牡丹江市",
          },
          {
            fldEnumId: 9144,
            enumGroupGuid: "1",
            parentEnumValue: "2310",
            enumValue: "231002",
            enumName: "东安区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 109,
            enumNameother: "东安区",
          },
          {
            fldEnumId: 9146,
            enumGroupGuid: "1",
            parentEnumValue: "2310",
            enumValue: "231003",
            enumName: "阳明区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 110,
            enumNameother: "阳明区",
          },
          {
            fldEnumId: 9148,
            enumGroupGuid: "1",
            parentEnumValue: "2310",
            enumValue: "231004",
            enumName: "爱民区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 111,
            enumNameother: "爱民区",
          },
          {
            fldEnumId: 9150,
            enumGroupGuid: "1",
            parentEnumValue: "2310",
            enumValue: "231005",
            enumName: "西安区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 112,
            enumNameother: "西安区",
          },
          {
            fldEnumId: 9152,
            enumGroupGuid: "1",
            parentEnumValue: "2310",
            enumValue: "231024",
            enumName: "东宁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 113,
            enumNameother: "东宁县",
          },
          {
            fldEnumId: 9154,
            enumGroupGuid: "1",
            parentEnumValue: "2310",
            enumValue: "231025",
            enumName: "林口县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 114,
            enumNameother: "林口县",
          },
          {
            fldEnumId: 9156,
            enumGroupGuid: "1",
            parentEnumValue: "2310",
            enumValue: "231081",
            enumName: "绥芬河市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 115,
            enumNameother: "绥芬河市",
          },
          {
            fldEnumId: 9158,
            enumGroupGuid: "1",
            parentEnumValue: "2310",
            enumValue: "231083",
            enumName: "海林市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 116,
            enumNameother: "海林市",
          },
          {
            fldEnumId: 9160,
            enumGroupGuid: "1",
            parentEnumValue: "2310",
            enumValue: "231084",
            enumName: "宁安市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 117,
            enumNameother: "宁安市",
          },
          {
            fldEnumId: 9162,
            enumGroupGuid: "1",
            parentEnumValue: "2310",
            enumValue: "231085",
            enumName: "穆棱市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 118,
            enumNameother: "穆棱市",
          },
        ],
      },
      {
        fldEnumId: 9164,
        enumGroupGuid: "1",
        parentEnumValue: "23",
        enumValue: "2311",
        enumName: "黑河市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 10,
        enumNameother: "黑河市",
        children: [
          {
            fldEnumId: 9165,
            enumGroupGuid: "1",
            parentEnumValue: "2311",
            enumValue: "231100",
            enumName: "黑河市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 119,
            enumNameother: "黑河市",
          },
          {
            fldEnumId: 9167,
            enumGroupGuid: "1",
            parentEnumValue: "2311",
            enumValue: "231102",
            enumName: "爱辉区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 120,
            enumNameother: "爱辉区",
          },
          {
            fldEnumId: 9169,
            enumGroupGuid: "1",
            parentEnumValue: "2311",
            enumValue: "231121",
            enumName: "嫩江县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 121,
            enumNameother: "嫩江县",
          },
          {
            fldEnumId: 9171,
            enumGroupGuid: "1",
            parentEnumValue: "2311",
            enumValue: "231123",
            enumName: "逊克县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 122,
            enumNameother: "逊克县",
          },
          {
            fldEnumId: 9173,
            enumGroupGuid: "1",
            parentEnumValue: "2311",
            enumValue: "231124",
            enumName: "孙吴县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 123,
            enumNameother: "孙吴县",
          },
          {
            fldEnumId: 9175,
            enumGroupGuid: "1",
            parentEnumValue: "2311",
            enumValue: "231181",
            enumName: "北安市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 124,
            enumNameother: "北安市",
          },
          {
            fldEnumId: 9177,
            enumGroupGuid: "1",
            parentEnumValue: "2311",
            enumValue: "231182",
            enumName: "五大连池市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 125,
            enumNameother: "五大连池市",
          },
        ],
      },
      {
        fldEnumId: 9179,
        enumGroupGuid: "1",
        parentEnumValue: "23",
        enumValue: "2312",
        enumName: "绥化市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 11,
        enumNameother: "绥化市",
        children: [
          {
            fldEnumId: 9180,
            enumGroupGuid: "1",
            parentEnumValue: "2312",
            enumValue: "231200",
            enumName: "绥化市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 126,
            enumNameother: "绥化市",
          },
          {
            fldEnumId: 9182,
            enumGroupGuid: "1",
            parentEnumValue: "2312",
            enumValue: "231202",
            enumName: "北林区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 127,
            enumNameother: "北林区",
          },
          {
            fldEnumId: 9184,
            enumGroupGuid: "1",
            parentEnumValue: "2312",
            enumValue: "231221",
            enumName: "望奎县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 128,
            enumNameother: "望奎县",
          },
          {
            fldEnumId: 9186,
            enumGroupGuid: "1",
            parentEnumValue: "2312",
            enumValue: "231222",
            enumName: "兰西县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 129,
            enumNameother: "兰西县",
          },
          {
            fldEnumId: 9188,
            enumGroupGuid: "1",
            parentEnumValue: "2312",
            enumValue: "231223",
            enumName: "青冈县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 130,
            enumNameother: "青冈县",
          },
          {
            fldEnumId: 9190,
            enumGroupGuid: "1",
            parentEnumValue: "2312",
            enumValue: "231224",
            enumName: "庆安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 131,
            enumNameother: "庆安县",
          },
          {
            fldEnumId: 9192,
            enumGroupGuid: "1",
            parentEnumValue: "2312",
            enumValue: "231225",
            enumName: "明水县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 132,
            enumNameother: "明水县",
          },
          {
            fldEnumId: 9194,
            enumGroupGuid: "1",
            parentEnumValue: "2312",
            enumValue: "231226",
            enumName: "绥棱县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 133,
            enumNameother: "绥棱县",
          },
          {
            fldEnumId: 9196,
            enumGroupGuid: "1",
            parentEnumValue: "2312",
            enumValue: "231281",
            enumName: "安达市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 134,
            enumNameother: "安达市",
          },
          {
            fldEnumId: 9198,
            enumGroupGuid: "1",
            parentEnumValue: "2312",
            enumValue: "231282",
            enumName: "肇东市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 135,
            enumNameother: "肇东市",
          },
          {
            fldEnumId: 9200,
            enumGroupGuid: "1",
            parentEnumValue: "2312",
            enumValue: "231283",
            enumName: "海伦市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 136,
            enumNameother: "海伦市",
          },
        ],
      },
      {
        fldEnumId: 9202,
        enumGroupGuid: "1",
        parentEnumValue: "23",
        enumValue: "2327",
        enumName: "大兴安岭地区",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 12,
        enumNameother: "大兴安岭地区",
        children: [
          {
            fldEnumId: 16296,
            enumGroupGuid: "1",
            parentEnumValue: "2327",
            enumValue: "232700",
            enumName: "大兴安岭地区本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 9203,
            enumGroupGuid: "1",
            parentEnumValue: "2327",
            enumValue: "232701",
            enumName: "加格达奇区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 137,
            enumNameother: "加格达奇区",
          },
          {
            fldEnumId: 9205,
            enumGroupGuid: "1",
            parentEnumValue: "2327",
            enumValue: "232702",
            enumName: "松岭区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 138,
            enumNameother: "松岭区",
          },
          {
            fldEnumId: 9207,
            enumGroupGuid: "1",
            parentEnumValue: "2327",
            enumValue: "232703",
            enumName: "新林区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 139,
            enumNameother: "新林区",
          },
          {
            fldEnumId: 9209,
            enumGroupGuid: "1",
            parentEnumValue: "2327",
            enumValue: "232704",
            enumName: "呼中区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 140,
            enumNameother: "呼中区",
          },
          {
            fldEnumId: 9211,
            enumGroupGuid: "1",
            parentEnumValue: "2327",
            enumValue: "232721",
            enumName: "呼玛县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 141,
            enumNameother: "呼玛县",
          },
          {
            fldEnumId: 9213,
            enumGroupGuid: "1",
            parentEnumValue: "2327",
            enumValue: "232722",
            enumName: "塔河县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 142,
            enumNameother: "塔河县",
          },
          {
            fldEnumId: 9215,
            enumGroupGuid: "1",
            parentEnumValue: "2327",
            enumValue: "232723",
            enumName: "漠河县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 143,
            enumNameother: "漠河县",
          },
        ],
      },
    ],
  },
  {
    enumValue: "31",
    enumName: "上海市",
    enumDesc: "zdcs",
    children: [
      {
        fldEnumId: 9218,
        enumGroupGuid: "1",
        parentEnumValue: "31",
        enumValue: "3101",
        enumName: "上海市市辖区",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        enumNameother: "市辖区",
        children: [
          {
            fldEnumId: 16137,
            enumGroupGuid: "1",
            parentEnumValue: "3101",
            enumValue: "310100",
            enumName: "上海市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 9219,
            enumGroupGuid: "1",
            parentEnumValue: "3101",
            enumValue: "310101",
            enumName: "黄浦区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
            enumNameother: "黄浦区",
          },
          {
            fldEnumId: 130348,
            enumGroupGuid: "1",
            parentEnumValue: "3101",
            enumValue: "310151",
            enumName: "崇明区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130147,
            enumGroupGuid: "1",
            parentEnumValue: "3101",
            enumValue: "310230",
            enumName: "崇明区(撤)",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 9221,
            enumGroupGuid: "1",
            parentEnumValue: "3101",
            enumValue: "310103",
            enumName: "卢湾区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "卢湾区",
          },
          {
            fldEnumId: 9223,
            enumGroupGuid: "1",
            parentEnumValue: "3101",
            enumValue: "310104",
            enumName: "徐汇区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 2,
            enumNameother: "徐汇区",
          },
          {
            fldEnumId: 9225,
            enumGroupGuid: "1",
            parentEnumValue: "3101",
            enumValue: "310105",
            enumName: "长宁区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 3,
            enumNameother: "长宁区",
          },
          {
            fldEnumId: 9227,
            enumGroupGuid: "1",
            parentEnumValue: "3101",
            enumValue: "310106",
            enumName: "静安区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 4,
            enumNameother: "静安区",
          },
          {
            fldEnumId: 9229,
            enumGroupGuid: "1",
            parentEnumValue: "3101",
            enumValue: "310107",
            enumName: "普陀区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 5,
            enumNameother: "普陀区",
          },
          {
            fldEnumId: 9231,
            enumGroupGuid: "1",
            parentEnumValue: "3101",
            enumValue: "310108",
            enumName: "闸北区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 6,
            enumNameother: "闸北区",
          },
          {
            fldEnumId: 9233,
            enumGroupGuid: "1",
            parentEnumValue: "3101",
            enumValue: "310109",
            enumName: "虹口区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 7,
            enumNameother: "虹口区",
          },
          {
            fldEnumId: 9235,
            enumGroupGuid: "1",
            parentEnumValue: "3101",
            enumValue: "310110",
            enumName: "杨浦区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 8,
            enumNameother: "杨浦区",
          },
          {
            fldEnumId: 9237,
            enumGroupGuid: "1",
            parentEnumValue: "3101",
            enumValue: "310112",
            enumName: "闵行区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 9,
            enumNameother: "闵行区",
          },
          {
            fldEnumId: 9239,
            enumGroupGuid: "1",
            parentEnumValue: "3101",
            enumValue: "310113",
            enumName: "宝山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 10,
            enumNameother: "宝山区",
          },
          {
            fldEnumId: 9241,
            enumGroupGuid: "1",
            parentEnumValue: "3101",
            enumValue: "310114",
            enumName: "嘉定区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 11,
            enumNameother: "嘉定区",
          },
          {
            fldEnumId: 9243,
            enumGroupGuid: "1",
            parentEnumValue: "3101",
            enumValue: "310115",
            enumName: "浦东新区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 12,
            enumNameother: "浦东新区",
          },
          {
            fldEnumId: 9245,
            enumGroupGuid: "1",
            parentEnumValue: "3101",
            enumValue: "310116",
            enumName: "金山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 13,
            enumNameother: "金山区",
          },
          {
            fldEnumId: 9247,
            enumGroupGuid: "1",
            parentEnumValue: "3101",
            enumValue: "310117",
            enumName: "松江区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 14,
            enumNameother: "松江区",
          },
          {
            fldEnumId: 9249,
            enumGroupGuid: "1",
            parentEnumValue: "3101",
            enumValue: "310118",
            enumName: "青浦区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 15,
            enumNameother: "青浦区",
          },
          {
            fldEnumId: 9251,
            enumGroupGuid: "1",
            parentEnumValue: "3101",
            enumValue: "310119",
            enumName: "南汇区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 16,
            enumNameother: "南汇区",
          },
          {
            fldEnumId: 9253,
            enumGroupGuid: "1",
            parentEnumValue: "3101",
            enumValue: "310120",
            enumName: "奉贤区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 17,
            enumNameother: "奉贤区",
          },
        ],
      },
    ],
  },
  {
    enumValue: "32",
    enumName: "江苏省",
    children: [
      {
        fldEnumId: 9259,
        enumGroupGuid: "1",
        parentEnumValue: "32",
        enumValue: "3201",
        enumName: "南京市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        enumNameother: "南京市",
        children: [
          {
            fldEnumId: 9260,
            enumGroupGuid: "1",
            parentEnumValue: "3201",
            enumValue: "320100",
            enumName: "南京市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
            enumNameother: "南京市",
          },
          {
            fldEnumId: 9262,
            enumGroupGuid: "1",
            parentEnumValue: "3201",
            enumValue: "320102",
            enumName: "玄武区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "玄武区",
          },
          {
            fldEnumId: 9264,
            enumGroupGuid: "1",
            parentEnumValue: "3201",
            enumValue: "320103",
            enumName: "白下区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 2,
            enumNameother: "白下区",
          },
          {
            fldEnumId: 9266,
            enumGroupGuid: "1",
            parentEnumValue: "3201",
            enumValue: "320104",
            enumName: "秦淮区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 3,
            enumNameother: "秦淮区",
          },
          {
            fldEnumId: 9268,
            enumGroupGuid: "1",
            parentEnumValue: "3201",
            enumValue: "320105",
            enumName: "建邺区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 4,
            enumNameother: "建邺区",
          },
          {
            fldEnumId: 9270,
            enumGroupGuid: "1",
            parentEnumValue: "3201",
            enumValue: "320106",
            enumName: "鼓楼区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 5,
            enumNameother: "鼓楼区",
          },
          {
            fldEnumId: 9272,
            enumGroupGuid: "1",
            parentEnumValue: "3201",
            enumValue: "320107",
            enumName: "下关区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 6,
            enumNameother: "下关区",
          },
          {
            fldEnumId: 9274,
            enumGroupGuid: "1",
            parentEnumValue: "3201",
            enumValue: "320111",
            enumName: "浦口区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 7,
            enumNameother: "浦口区",
          },
          {
            fldEnumId: 9276,
            enumGroupGuid: "1",
            parentEnumValue: "3201",
            enumValue: "320113",
            enumName: "栖霞区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 8,
            enumNameother: "栖霞区",
          },
          {
            fldEnumId: 9278,
            enumGroupGuid: "1",
            parentEnumValue: "3201",
            enumValue: "320114",
            enumName: "雨花台区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 9,
            enumNameother: "雨花台区",
          },
          {
            fldEnumId: 9280,
            enumGroupGuid: "1",
            parentEnumValue: "3201",
            enumValue: "320115",
            enumName: "江宁区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 10,
            enumNameother: "江宁区",
          },
          {
            fldEnumId: 9282,
            enumGroupGuid: "1",
            parentEnumValue: "3201",
            enumValue: "320116",
            enumName: "六合区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 11,
            enumNameother: "六合区",
          },
          {
            fldEnumId: 9284,
            enumGroupGuid: "1",
            parentEnumValue: "3201",
            enumValue: "320124",
            enumName: "溧水县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 12,
            enumNameother: "溧水县",
          },
          {
            fldEnumId: 9286,
            enumGroupGuid: "1",
            parentEnumValue: "3201",
            enumValue: "320125",
            enumName: "高淳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 13,
            enumNameother: "高淳县",
          },
        ],
      },
      {
        fldEnumId: 9288,
        enumGroupGuid: "1",
        parentEnumValue: "32",
        enumValue: "3202",
        enumName: "无锡市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 1,
        enumNameother: "无锡市",
        children: [
          {
            fldEnumId: 130185,
            enumGroupGuid: "1",
            parentEnumValue: "3202",
            enumValue: "320213",
            enumName: "梁溪区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130186,
            enumGroupGuid: "1",
            parentEnumValue: "3202",
            enumValue: "320214",
            enumName: "新吴区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 9289,
            enumGroupGuid: "1",
            parentEnumValue: "3202",
            enumValue: "320200",
            enumName: "无锡市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 14,
            enumNameother: "无锡市",
          },
          {
            fldEnumId: 9291,
            enumGroupGuid: "1",
            parentEnumValue: "3202",
            enumValue: "320202",
            enumName: "崇安区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 15,
            enumNameother: "崇安区",
          },
          {
            fldEnumId: 9293,
            enumGroupGuid: "1",
            parentEnumValue: "3202",
            enumValue: "320203",
            enumName: "南长区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 16,
            enumNameother: "南长区",
          },
          {
            fldEnumId: 9295,
            enumGroupGuid: "1",
            parentEnumValue: "3202",
            enumValue: "320204",
            enumName: "北塘区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 17,
            enumNameother: "北塘区",
          },
          {
            fldEnumId: 9297,
            enumGroupGuid: "1",
            parentEnumValue: "3202",
            enumValue: "320205",
            enumName: "锡山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 18,
            enumNameother: "锡山区",
          },
          {
            fldEnumId: 9299,
            enumGroupGuid: "1",
            parentEnumValue: "3202",
            enumValue: "320206",
            enumName: "惠山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 19,
            enumNameother: "惠山区",
          },
          {
            fldEnumId: 9301,
            enumGroupGuid: "1",
            parentEnumValue: "3202",
            enumValue: "320211",
            enumName: "滨湖区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 20,
            enumNameother: "滨湖区",
          },
          {
            fldEnumId: 9303,
            enumGroupGuid: "1",
            parentEnumValue: "3202",
            enumValue: "320281",
            enumName: "江阴市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 21,
            enumNameother: "江阴市",
          },
          {
            fldEnumId: 9305,
            enumGroupGuid: "1",
            parentEnumValue: "3202",
            enumValue: "320282",
            enumName: "宜兴市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 22,
            enumNameother: "宜兴市",
          },
        ],
      },
      {
        fldEnumId: 9307,
        enumGroupGuid: "1",
        parentEnumValue: "32",
        enumValue: "3203",
        enumName: "徐州市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 2,
        enumNameother: "徐州市",
        children: [
          {
            fldEnumId: 130335,
            enumGroupGuid: "1",
            parentEnumValue: "3203",
            enumValue: "320371",
            enumName: "徐州经济开发区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 9308,
            enumGroupGuid: "1",
            parentEnumValue: "3203",
            enumValue: "320300",
            enumName: "徐州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 23,
            enumNameother: "徐州市",
          },
          {
            fldEnumId: 9310,
            enumGroupGuid: "1",
            parentEnumValue: "3203",
            enumValue: "320302",
            enumName: "鼓楼区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 24,
            enumNameother: "鼓楼区",
          },
          {
            fldEnumId: 9312,
            enumGroupGuid: "1",
            parentEnumValue: "3203",
            enumValue: "320303",
            enumName: "云龙区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 25,
            enumNameother: "云龙区",
          },
          {
            fldEnumId: 9314,
            enumGroupGuid: "1",
            parentEnumValue: "3203",
            enumValue: "320304",
            enumName: "九里区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 26,
            enumNameother: "九里区",
          },
          {
            fldEnumId: 9316,
            enumGroupGuid: "1",
            parentEnumValue: "3203",
            enumValue: "320305",
            enumName: "贾汪区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 27,
            enumNameother: "贾汪区",
          },
          {
            fldEnumId: 9318,
            enumGroupGuid: "1",
            parentEnumValue: "3203",
            enumValue: "320311",
            enumName: "泉山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 28,
            enumNameother: "泉山区",
          },
          {
            fldEnumId: 9320,
            enumGroupGuid: "1",
            parentEnumValue: "3203",
            enumValue: "320321",
            enumName: "丰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 29,
            enumNameother: "丰县",
          },
          {
            fldEnumId: 9322,
            enumGroupGuid: "1",
            parentEnumValue: "3203",
            enumValue: "320322",
            enumName: "沛县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 30,
            enumNameother: "沛县",
          },
          {
            fldEnumId: 9324,
            enumGroupGuid: "1",
            parentEnumValue: "3203",
            enumValue: "320323",
            enumName: "铜山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 31,
            enumNameother: "铜山县",
          },
          {
            fldEnumId: 9326,
            enumGroupGuid: "1",
            parentEnumValue: "3203",
            enumValue: "320324",
            enumName: "睢宁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 32,
            enumNameother: "睢宁县",
          },
          {
            fldEnumId: 9328,
            enumGroupGuid: "1",
            parentEnumValue: "3203",
            enumValue: "320381",
            enumName: "新沂市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 33,
            enumNameother: "新沂市",
          },
          {
            fldEnumId: 9330,
            enumGroupGuid: "1",
            parentEnumValue: "3203",
            enumValue: "320382",
            enumName: "邳州市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 34,
            enumNameother: "邳州市",
          },
        ],
      },
      {
        fldEnumId: 9332,
        enumGroupGuid: "1",
        parentEnumValue: "32",
        enumValue: "3204",
        enumName: "常州市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 3,
        enumNameother: "常州市",
        children: [
          {
            fldEnumId: 9333,
            enumGroupGuid: "1",
            parentEnumValue: "3204",
            enumValue: "320400",
            enumName: "常州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 35,
            enumNameother: "常州市",
          },
          {
            fldEnumId: 9335,
            enumGroupGuid: "1",
            parentEnumValue: "3204",
            enumValue: "320402",
            enumName: "天宁区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 36,
            enumNameother: "天宁区",
          },
          {
            fldEnumId: 9337,
            enumGroupGuid: "1",
            parentEnumValue: "3204",
            enumValue: "320404",
            enumName: "钟楼区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 37,
            enumNameother: "钟楼区",
          },
          {
            fldEnumId: 9339,
            enumGroupGuid: "1",
            parentEnumValue: "3204",
            enumValue: "320405",
            enumName: "戚墅堰区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 38,
            enumNameother: "戚墅堰区",
          },
          {
            fldEnumId: 9341,
            enumGroupGuid: "1",
            parentEnumValue: "3204",
            enumValue: "320411",
            enumName: "新北区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 39,
            enumNameother: "新北区",
          },
          {
            fldEnumId: 9343,
            enumGroupGuid: "1",
            parentEnumValue: "3204",
            enumValue: "320412",
            enumName: "武进区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 40,
            enumNameother: "武进区",
          },
          {
            fldEnumId: 9345,
            enumGroupGuid: "1",
            parentEnumValue: "3204",
            enumValue: "320481",
            enumName: "溧阳市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 41,
            enumNameother: "溧阳市",
          },
          {
            fldEnumId: 9347,
            enumGroupGuid: "1",
            parentEnumValue: "3204",
            enumValue: "320482",
            enumName: "金坛市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 42,
            enumNameother: "金坛市",
          },
        ],
      },
      {
        fldEnumId: 9349,
        enumGroupGuid: "1",
        parentEnumValue: "32",
        enumValue: "3205",
        enumName: "苏州市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 4,
        enumNameother: "苏州市",
        children: [
          {
            fldEnumId: 130188,
            enumGroupGuid: "1",
            parentEnumValue: "3205",
            enumValue: "320508",
            enumName: "姑苏区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130338,
            enumGroupGuid: "1",
            parentEnumValue: "3205",
            enumValue: "320571",
            enumName: "苏州工业园区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 9350,
            enumGroupGuid: "1",
            parentEnumValue: "3205",
            enumValue: "320500",
            enumName: "苏州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 43,
            enumNameother: "苏州市",
          },
          {
            fldEnumId: 9352,
            enumGroupGuid: "1",
            parentEnumValue: "3205",
            enumValue: "320502",
            enumName: "沧浪区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 44,
            enumNameother: "沧浪区",
          },
          {
            fldEnumId: 9354,
            enumGroupGuid: "1",
            parentEnumValue: "3205",
            enumValue: "320503",
            enumName: "平江区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 45,
            enumNameother: "平江区",
          },
          {
            fldEnumId: 9356,
            enumGroupGuid: "1",
            parentEnumValue: "3205",
            enumValue: "320504",
            enumName: "金阊区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 46,
            enumNameother: "金阊区",
          },
          {
            fldEnumId: 9358,
            enumGroupGuid: "1",
            parentEnumValue: "3205",
            enumValue: "320505",
            enumName: "虎丘区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 47,
            enumNameother: "虎丘区",
          },
          {
            fldEnumId: 9360,
            enumGroupGuid: "1",
            parentEnumValue: "3205",
            enumValue: "320506",
            enumName: "吴中区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 48,
            enumNameother: "吴中区",
          },
          {
            fldEnumId: 9362,
            enumGroupGuid: "1",
            parentEnumValue: "3205",
            enumValue: "320507",
            enumName: "相城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 49,
            enumNameother: "相城区",
          },
          {
            fldEnumId: 9364,
            enumGroupGuid: "1",
            parentEnumValue: "3205",
            enumValue: "320581",
            enumName: "常熟市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 50,
            enumNameother: "常熟市",
          },
          {
            fldEnumId: 9366,
            enumGroupGuid: "1",
            parentEnumValue: "3205",
            enumValue: "320582",
            enumName: "张家港市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 51,
            enumNameother: "张家港市",
          },
          {
            fldEnumId: 9368,
            enumGroupGuid: "1",
            parentEnumValue: "3205",
            enumValue: "320583",
            enumName: "昆山市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 52,
            enumNameother: "昆山市",
          },
          {
            fldEnumId: 9370,
            enumGroupGuid: "1",
            parentEnumValue: "3205",
            enumValue: "320584",
            enumName: "吴江市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 53,
            enumNameother: "吴江市",
          },
          {
            fldEnumId: 9372,
            enumGroupGuid: "1",
            parentEnumValue: "3205",
            enumValue: "320585",
            enumName: "太仓市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 54,
            enumNameother: "太仓市",
          },
        ],
      },
      {
        fldEnumId: 9374,
        enumGroupGuid: "1",
        parentEnumValue: "32",
        enumValue: "3206",
        enumName: "南通市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 5,
        enumNameother: "南通市",
        children: [
          {
            fldEnumId: 9375,
            enumGroupGuid: "1",
            parentEnumValue: "3206",
            enumValue: "320600",
            enumName: "南通市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 55,
            enumNameother: "南通市",
          },
          {
            fldEnumId: 9377,
            enumGroupGuid: "1",
            parentEnumValue: "3206",
            enumValue: "320602",
            enumName: "崇川区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 56,
            enumNameother: "崇川区",
          },
          {
            fldEnumId: 9379,
            enumGroupGuid: "1",
            parentEnumValue: "3206",
            enumValue: "320611",
            enumName: "港闸区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 57,
            enumNameother: "港闸区",
          },
          {
            fldEnumId: 9381,
            enumGroupGuid: "1",
            parentEnumValue: "3206",
            enumValue: "320621",
            enumName: "海安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 58,
            enumNameother: "海安县",
          },
          {
            fldEnumId: 9383,
            enumGroupGuid: "1",
            parentEnumValue: "3206",
            enumValue: "320623",
            enumName: "如东县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 59,
            enumNameother: "如东县",
          },
          {
            fldEnumId: 9385,
            enumGroupGuid: "1",
            parentEnumValue: "3206",
            enumValue: "320681",
            enumName: "启东市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 60,
            enumNameother: "启东市",
          },
          {
            fldEnumId: 9387,
            enumGroupGuid: "1",
            parentEnumValue: "3206",
            enumValue: "320682",
            enumName: "如皋市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 61,
            enumNameother: "如皋市",
          },
          {
            fldEnumId: 9389,
            enumGroupGuid: "1",
            parentEnumValue: "3206",
            enumValue: "320683",
            enumName: "通州市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 62,
            enumNameother: "通州市",
          },
          {
            fldEnumId: 9391,
            enumGroupGuid: "1",
            parentEnumValue: "3206",
            enumValue: "320684",
            enumName: "海门市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 63,
            enumNameother: "海门市",
          },
        ],
      },
      {
        fldEnumId: 9393,
        enumGroupGuid: "1",
        parentEnumValue: "32",
        enumValue: "3207",
        enumName: "连云港市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 6,
        enumNameother: "连云港市",
        children: [
          {
            fldEnumId: 9394,
            enumGroupGuid: "1",
            parentEnumValue: "3207",
            enumValue: "320700",
            enumName: "连云港市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 64,
            enumNameother: "连云港市",
          },
          {
            fldEnumId: 9396,
            enumGroupGuid: "1",
            parentEnumValue: "3207",
            enumValue: "320703",
            enumName: "连云区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 65,
            enumNameother: "连云区",
          },
          {
            fldEnumId: 9398,
            enumGroupGuid: "1",
            parentEnumValue: "3207",
            enumValue: "320705",
            enumName: "新浦区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 66,
            enumNameother: "新浦区",
          },
          {
            fldEnumId: 9400,
            enumGroupGuid: "1",
            parentEnumValue: "3207",
            enumValue: "320706",
            enumName: "海州区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 67,
            enumNameother: "海州区",
          },
          {
            fldEnumId: 9402,
            enumGroupGuid: "1",
            parentEnumValue: "3207",
            enumValue: "320721",
            enumName: "赣榆县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 68,
            enumNameother: "赣榆县",
          },
          {
            fldEnumId: 9404,
            enumGroupGuid: "1",
            parentEnumValue: "3207",
            enumValue: "320722",
            enumName: "东海县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 69,
            enumNameother: "东海县",
          },
          {
            fldEnumId: 9406,
            enumGroupGuid: "1",
            parentEnumValue: "3207",
            enumValue: "320723",
            enumName: "灌云县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 70,
            enumNameother: "灌云县",
          },
          {
            fldEnumId: 9408,
            enumGroupGuid: "1",
            parentEnumValue: "3207",
            enumValue: "320724",
            enumName: "灌南县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 71,
            enumNameother: "灌南县",
          },
        ],
      },
      {
        fldEnumId: 9410,
        enumGroupGuid: "1",
        parentEnumValue: "32",
        enumValue: "3208",
        enumName: "淮安市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 7,
        enumNameother: "淮安市",
        children: [
          {
            fldEnumId: 130189,
            enumGroupGuid: "1",
            parentEnumValue: "3208",
            enumValue: "320812",
            enumName: "清江浦区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 9411,
            enumGroupGuid: "1",
            parentEnumValue: "3208",
            enumValue: "320800",
            enumName: "淮安市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 72,
            enumNameother: "淮安市",
          },
          {
            fldEnumId: 9413,
            enumGroupGuid: "1",
            parentEnumValue: "3208",
            enumValue: "320802",
            enumName: "清河区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 73,
            enumNameother: "清河区",
          },
          {
            fldEnumId: 9415,
            enumGroupGuid: "1",
            parentEnumValue: "3208",
            enumValue: "320803",
            enumName: "楚州区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 74,
            enumNameother: "楚州区",
          },
          {
            fldEnumId: 9417,
            enumGroupGuid: "1",
            parentEnumValue: "3208",
            enumValue: "320804",
            enumName: "淮阴区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 75,
            enumNameother: "淮阴区",
          },
          {
            fldEnumId: 9419,
            enumGroupGuid: "1",
            parentEnumValue: "3208",
            enumValue: "320811",
            enumName: "清浦区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 76,
            enumNameother: "清浦区",
          },
          {
            fldEnumId: 9421,
            enumGroupGuid: "1",
            parentEnumValue: "3208",
            enumValue: "320826",
            enumName: "涟水县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 77,
            enumNameother: "涟水县",
          },
          {
            fldEnumId: 9423,
            enumGroupGuid: "1",
            parentEnumValue: "3208",
            enumValue: "320829",
            enumName: "洪泽县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 78,
            enumNameother: "洪泽县",
          },
          {
            fldEnumId: 9425,
            enumGroupGuid: "1",
            parentEnumValue: "3208",
            enumValue: "320830",
            enumName: "盱眙县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 79,
            enumNameother: "盱眙县",
          },
          {
            fldEnumId: 9427,
            enumGroupGuid: "1",
            parentEnumValue: "3208",
            enumValue: "320831",
            enumName: "金湖县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 80,
            enumNameother: "金湖县",
          },
        ],
      },
      {
        fldEnumId: 9429,
        enumGroupGuid: "1",
        parentEnumValue: "32",
        enumValue: "3209",
        enumName: "盐城市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 8,
        enumNameother: "盐城市",
        children: [
          {
            fldEnumId: 9430,
            enumGroupGuid: "1",
            parentEnumValue: "3209",
            enumValue: "320900",
            enumName: "盐城市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 81,
            enumNameother: "盐城市",
          },
          {
            fldEnumId: 9432,
            enumGroupGuid: "1",
            parentEnumValue: "3209",
            enumValue: "320902",
            enumName: "亭湖区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 82,
            enumNameother: "亭湖区",
          },
          {
            fldEnumId: 9434,
            enumGroupGuid: "1",
            parentEnumValue: "3209",
            enumValue: "320903",
            enumName: "盐都区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 83,
            enumNameother: "盐都区",
          },
          {
            fldEnumId: 9436,
            enumGroupGuid: "1",
            parentEnumValue: "3209",
            enumValue: "320921",
            enumName: "响水县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 84,
            enumNameother: "响水县",
          },
          {
            fldEnumId: 9438,
            enumGroupGuid: "1",
            parentEnumValue: "3209",
            enumValue: "320922",
            enumName: "滨海县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 85,
            enumNameother: "滨海县",
          },
          {
            fldEnumId: 9440,
            enumGroupGuid: "1",
            parentEnumValue: "3209",
            enumValue: "320923",
            enumName: "阜宁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 86,
            enumNameother: "阜宁县",
          },
          {
            fldEnumId: 9442,
            enumGroupGuid: "1",
            parentEnumValue: "3209",
            enumValue: "320924",
            enumName: "射阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 87,
            enumNameother: "射阳县",
          },
          {
            fldEnumId: 9444,
            enumGroupGuid: "1",
            parentEnumValue: "3209",
            enumValue: "320925",
            enumName: "建湖县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 88,
            enumNameother: "建湖县",
          },
          {
            fldEnumId: 9446,
            enumGroupGuid: "1",
            parentEnumValue: "3209",
            enumValue: "320981",
            enumName: "东台市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 89,
            enumNameother: "东台市",
          },
          {
            fldEnumId: 9448,
            enumGroupGuid: "1",
            parentEnumValue: "3209",
            enumValue: "320982",
            enumName: "大丰市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 90,
            enumNameother: "大丰市",
          },
        ],
      },
      {
        fldEnumId: 9450,
        enumGroupGuid: "1",
        parentEnumValue: "32",
        enumValue: "3210",
        enumName: "扬州市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 9,
        enumNameother: "扬州市",
        children: [
          {
            fldEnumId: 9451,
            enumGroupGuid: "1",
            parentEnumValue: "3210",
            enumValue: "321000",
            enumName: "扬州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 91,
            enumNameother: "扬州市",
          },
          {
            fldEnumId: 9453,
            enumGroupGuid: "1",
            parentEnumValue: "3210",
            enumValue: "321002",
            enumName: "广陵区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 92,
            enumNameother: "广陵区",
          },
          {
            fldEnumId: 9455,
            enumGroupGuid: "1",
            parentEnumValue: "3210",
            enumValue: "321003",
            enumName: "邗江区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 93,
            enumNameother: "邗江区",
          },
          {
            fldEnumId: 9457,
            enumGroupGuid: "1",
            parentEnumValue: "3210",
            enumValue: "321011",
            enumName: "维扬区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 94,
            enumNameother: "维扬区",
          },
          {
            fldEnumId: 9459,
            enumGroupGuid: "1",
            parentEnumValue: "3210",
            enumValue: "321023",
            enumName: "宝应县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 95,
            enumNameother: "宝应县",
          },
          {
            fldEnumId: 9461,
            enumGroupGuid: "1",
            parentEnumValue: "3210",
            enumValue: "321081",
            enumName: "仪征市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 96,
            enumNameother: "仪征市",
          },
          {
            fldEnumId: 9463,
            enumGroupGuid: "1",
            parentEnumValue: "3210",
            enumValue: "321084",
            enumName: "高邮市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 97,
            enumNameother: "高邮市",
          },
          {
            fldEnumId: 9465,
            enumGroupGuid: "1",
            parentEnumValue: "3210",
            enumValue: "321088",
            enumName: "江都市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 98,
            enumNameother: "江都市",
          },
        ],
      },
      {
        fldEnumId: 9467,
        enumGroupGuid: "1",
        parentEnumValue: "32",
        enumValue: "3211",
        enumName: "镇江市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 10,
        enumNameother: "镇江市",
        children: [
          {
            fldEnumId: 9468,
            enumGroupGuid: "1",
            parentEnumValue: "3211",
            enumValue: "321100",
            enumName: "镇江市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 99,
            enumNameother: "镇江市",
          },
          {
            fldEnumId: 9470,
            enumGroupGuid: "1",
            parentEnumValue: "3211",
            enumValue: "321102",
            enumName: "京口区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 100,
            enumNameother: "京口区",
          },
          {
            fldEnumId: 9472,
            enumGroupGuid: "1",
            parentEnumValue: "3211",
            enumValue: "321111",
            enumName: "润州区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 101,
            enumNameother: "润州区",
          },
          {
            fldEnumId: 9474,
            enumGroupGuid: "1",
            parentEnumValue: "3211",
            enumValue: "321112",
            enumName: "丹徒区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 102,
            enumNameother: "丹徒区",
          },
          {
            fldEnumId: 9476,
            enumGroupGuid: "1",
            parentEnumValue: "3211",
            enumValue: "321181",
            enumName: "丹阳市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 103,
            enumNameother: "丹阳市",
          },
          {
            fldEnumId: 9478,
            enumGroupGuid: "1",
            parentEnumValue: "3211",
            enumValue: "321182",
            enumName: "扬中市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 104,
            enumNameother: "扬中市",
          },
          {
            fldEnumId: 9480,
            enumGroupGuid: "1",
            parentEnumValue: "3211",
            enumValue: "321183",
            enumName: "句容市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 105,
            enumNameother: "句容市",
          },
        ],
      },
      {
        fldEnumId: 9482,
        enumGroupGuid: "1",
        parentEnumValue: "32",
        enumValue: "3212",
        enumName: "泰州市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 11,
        enumNameother: "泰州市",
        children: [
          {
            fldEnumId: 9483,
            enumGroupGuid: "1",
            parentEnumValue: "3212",
            enumValue: "321200",
            enumName: "泰州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 106,
            enumNameother: "泰州市",
          },
          {
            fldEnumId: 9485,
            enumGroupGuid: "1",
            parentEnumValue: "3212",
            enumValue: "321202",
            enumName: "海陵区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 107,
            enumNameother: "海陵区",
          },
          {
            fldEnumId: 9487,
            enumGroupGuid: "1",
            parentEnumValue: "3212",
            enumValue: "321203",
            enumName: "高港区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 108,
            enumNameother: "高港区",
          },
          {
            fldEnumId: 9489,
            enumGroupGuid: "1",
            parentEnumValue: "3212",
            enumValue: "321281",
            enumName: "兴化市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 109,
            enumNameother: "兴化市",
          },
          {
            fldEnumId: 9491,
            enumGroupGuid: "1",
            parentEnumValue: "3212",
            enumValue: "321282",
            enumName: "靖江市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 110,
            enumNameother: "靖江市",
          },
          {
            fldEnumId: 9493,
            enumGroupGuid: "1",
            parentEnumValue: "3212",
            enumValue: "321283",
            enumName: "泰兴市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 111,
            enumNameother: "泰兴市",
          },
          {
            fldEnumId: 9495,
            enumGroupGuid: "1",
            parentEnumValue: "3212",
            enumValue: "321284",
            enumName: "姜堰市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 112,
            enumNameother: "姜堰市",
          },
        ],
      },
      {
        fldEnumId: 9497,
        enumGroupGuid: "1",
        parentEnumValue: "32",
        enumValue: "3213",
        enumName: "宿迁市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 12,
        enumNameother: "宿迁市",
        children: [
          {
            fldEnumId: 9498,
            enumGroupGuid: "1",
            parentEnumValue: "3213",
            enumValue: "321300",
            enumName: "宿迁市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 113,
            enumNameother: "宿迁市",
          },
          {
            fldEnumId: 9500,
            enumGroupGuid: "1",
            parentEnumValue: "3213",
            enumValue: "321302",
            enumName: "宿城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 114,
            enumNameother: "宿城区",
          },
          {
            fldEnumId: 9502,
            enumGroupGuid: "1",
            parentEnumValue: "3213",
            enumValue: "321311",
            enumName: "宿豫区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 115,
            enumNameother: "宿豫区",
          },
          {
            fldEnumId: 9504,
            enumGroupGuid: "1",
            parentEnumValue: "3213",
            enumValue: "321322",
            enumName: "沭阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 116,
            enumNameother: "沭阳县",
          },
          {
            fldEnumId: 9506,
            enumGroupGuid: "1",
            parentEnumValue: "3213",
            enumValue: "321323",
            enumName: "泗阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 117,
            enumNameother: "泗阳县",
          },
          {
            fldEnumId: 9508,
            enumGroupGuid: "1",
            parentEnumValue: "3213",
            enumValue: "321324",
            enumName: "泗洪县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 118,
            enumNameother: "泗洪县",
          },
        ],
      },
    ],
  },
  {
    enumValue: "33",
    enumName: "浙江省",
    children: [
      {
        fldEnumId: 9511,
        enumGroupGuid: "1",
        parentEnumValue: "33",
        enumValue: "3301",
        enumName: "杭州市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        enumNameother: "杭州市",
        children: [
          {
            fldEnumId: 9512,
            enumGroupGuid: "1",
            parentEnumValue: "3301",
            enumValue: "330100",
            enumName: "杭州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
            enumNameother: "杭州市",
          },
          {
            fldEnumId: 9514,
            enumGroupGuid: "1",
            parentEnumValue: "3301",
            enumValue: "330102",
            enumName: "上城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "上城区",
          },
          {
            fldEnumId: 9516,
            enumGroupGuid: "1",
            parentEnumValue: "3301",
            enumValue: "330103",
            enumName: "下城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 2,
            enumNameother: "下城区",
          },
          {
            fldEnumId: 9518,
            enumGroupGuid: "1",
            parentEnumValue: "3301",
            enumValue: "330104",
            enumName: "江干区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 3,
            enumNameother: "江干区",
          },
          {
            fldEnumId: 9520,
            enumGroupGuid: "1",
            parentEnumValue: "3301",
            enumValue: "330105",
            enumName: "拱墅区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 4,
            enumNameother: "拱墅区",
          },
          {
            fldEnumId: 9522,
            enumGroupGuid: "1",
            parentEnumValue: "3301",
            enumValue: "330106",
            enumName: "西湖区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 5,
            enumNameother: "西湖区",
          },
          {
            fldEnumId: 9524,
            enumGroupGuid: "1",
            parentEnumValue: "3301",
            enumValue: "330108",
            enumName: "滨江区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 6,
            enumNameother: "滨江区",
          },
          {
            fldEnumId: 9526,
            enumGroupGuid: "1",
            parentEnumValue: "3301",
            enumValue: "330109",
            enumName: "萧山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 7,
            enumNameother: "萧山区",
          },
          {
            fldEnumId: 130109,
            enumGroupGuid: "1",
            parentEnumValue: "3301",
            enumValue: "330181",
            enumName: "萧山区",
            enumLevel: 0,
            enumStatus: 2,
            useFrequency: 0,
            enumOrder: 7,
            enumNameother: "萧山区",
          },
          {
            fldEnumId: 9528,
            enumGroupGuid: "1",
            parentEnumValue: "3301",
            enumValue: "330110",
            enumName: "余杭区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 8,
            enumNameother: "余杭区",
          },
          {
            fldEnumId: 9530,
            enumGroupGuid: "1",
            parentEnumValue: "3301",
            enumValue: "330122",
            enumName: "桐庐县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 9,
            enumNameother: "桐庐县",
          },
          {
            fldEnumId: 9532,
            enumGroupGuid: "1",
            parentEnumValue: "3301",
            enumValue: "330127",
            enumName: "淳安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 10,
            enumNameother: "淳安县",
          },
          {
            fldEnumId: 9534,
            enumGroupGuid: "1",
            parentEnumValue: "3301",
            enumValue: "330182",
            enumName: "建德市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 11,
            enumNameother: "建德市",
          },
          {
            fldEnumId: 9536,
            enumGroupGuid: "1",
            parentEnumValue: "3301",
            enumValue: "330183",
            enumName: "富阳市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 12,
            enumNameother: "富阳市",
          },
          {
            fldEnumId: 9538,
            enumGroupGuid: "1",
            parentEnumValue: "3301",
            enumValue: "330185",
            enumName: "临安市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 13,
            enumNameother: "临安市",
          },
        ],
      },
      {
        fldEnumId: 9540,
        enumGroupGuid: "1",
        parentEnumValue: "33",
        enumValue: "3302",
        enumName: "宁波市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 1,
        enumNameother: "宁波市",
        children: [
          {
            fldEnumId: 9541,
            enumGroupGuid: "1",
            parentEnumValue: "3302",
            enumValue: "330200",
            enumName: "宁波市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 14,
            enumNameother: "宁波市",
          },
          {
            fldEnumId: 9543,
            enumGroupGuid: "1",
            parentEnumValue: "3302",
            enumValue: "330203",
            enumName: "海曙区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 15,
            enumNameother: "海曙区",
          },
          {
            fldEnumId: 9545,
            enumGroupGuid: "1",
            parentEnumValue: "3302",
            enumValue: "330204",
            enumName: "江东区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 16,
            enumNameother: "江东区",
          },
          {
            fldEnumId: 9547,
            enumGroupGuid: "1",
            parentEnumValue: "3302",
            enumValue: "330205",
            enumName: "江北区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 17,
            enumNameother: "江北区",
          },
          {
            fldEnumId: 9549,
            enumGroupGuid: "1",
            parentEnumValue: "3302",
            enumValue: "330206",
            enumName: "北仑区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 18,
            enumNameother: "北仑区",
          },
          {
            fldEnumId: 9551,
            enumGroupGuid: "1",
            parentEnumValue: "3302",
            enumValue: "330211",
            enumName: "镇海区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 19,
            enumNameother: "镇海区",
          },
          {
            fldEnumId: 9553,
            enumGroupGuid: "1",
            parentEnumValue: "3302",
            enumValue: "330212",
            enumName: "鄞州区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 20,
            enumNameother: "鄞州区",
          },
          {
            fldEnumId: 9555,
            enumGroupGuid: "1",
            parentEnumValue: "3302",
            enumValue: "330225",
            enumName: "象山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 21,
            enumNameother: "象山县",
          },
          {
            fldEnumId: 9557,
            enumGroupGuid: "1",
            parentEnumValue: "3302",
            enumValue: "330226",
            enumName: "宁海县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 22,
            enumNameother: "宁海县",
          },
          {
            fldEnumId: 9559,
            enumGroupGuid: "1",
            parentEnumValue: "3302",
            enumValue: "330281",
            enumName: "余姚市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 23,
            enumNameother: "余姚市",
          },
          {
            fldEnumId: 9561,
            enumGroupGuid: "1",
            parentEnumValue: "3302",
            enumValue: "330282",
            enumName: "慈溪市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 24,
            enumNameother: "慈溪市",
          },
          {
            fldEnumId: 9563,
            enumGroupGuid: "1",
            parentEnumValue: "3302",
            enumValue: "330283",
            enumName: "奉化市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 25,
            enumNameother: "奉化市",
          },
        ],
      },
      {
        fldEnumId: 9565,
        enumGroupGuid: "1",
        parentEnumValue: "33",
        enumValue: "3303",
        enumName: "温州市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 2,
        enumNameother: "温州市",
        children: [
          {
            fldEnumId: 9566,
            enumGroupGuid: "1",
            parentEnumValue: "3303",
            enumValue: "330300",
            enumName: "温州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 26,
            enumNameother: "温州市",
          },
          {
            fldEnumId: 9568,
            enumGroupGuid: "1",
            parentEnumValue: "3303",
            enumValue: "330302",
            enumName: "鹿城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 27,
            enumNameother: "鹿城区",
          },
          {
            fldEnumId: 9570,
            enumGroupGuid: "1",
            parentEnumValue: "3303",
            enumValue: "330303",
            enumName: "龙湾区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 28,
            enumNameother: "龙湾区",
          },
          {
            fldEnumId: 9572,
            enumGroupGuid: "1",
            parentEnumValue: "3303",
            enumValue: "330304",
            enumName: "瓯海区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 29,
            enumNameother: "瓯海区",
          },
          {
            fldEnumId: 9574,
            enumGroupGuid: "1",
            parentEnumValue: "3303",
            enumValue: "330322",
            enumName: "洞头县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 30,
            enumNameother: "洞头县",
          },
          {
            fldEnumId: 9576,
            enumGroupGuid: "1",
            parentEnumValue: "3303",
            enumValue: "330324",
            enumName: "永嘉县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 31,
            enumNameother: "永嘉县",
          },
          {
            fldEnumId: 9578,
            enumGroupGuid: "1",
            parentEnumValue: "3303",
            enumValue: "330326",
            enumName: "平阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 32,
            enumNameother: "平阳县",
          },
          {
            fldEnumId: 9580,
            enumGroupGuid: "1",
            parentEnumValue: "3303",
            enumValue: "330327",
            enumName: "苍南县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 33,
            enumNameother: "苍南县",
          },
          {
            fldEnumId: 9582,
            enumGroupGuid: "1",
            parentEnumValue: "3303",
            enumValue: "330328",
            enumName: "文成县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 34,
            enumNameother: "文成县",
          },
          {
            fldEnumId: 9584,
            enumGroupGuid: "1",
            parentEnumValue: "3303",
            enumValue: "330329",
            enumName: "泰顺县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 35,
            enumNameother: "泰顺县",
          },
          {
            fldEnumId: 9586,
            enumGroupGuid: "1",
            parentEnumValue: "3303",
            enumValue: "330381",
            enumName: "瑞安市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 36,
            enumNameother: "瑞安市",
          },
          {
            fldEnumId: 9588,
            enumGroupGuid: "1",
            parentEnumValue: "3303",
            enumValue: "330382",
            enumName: "乐清市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 37,
            enumNameother: "乐清市",
          },
          {
            fldEnumId: 130106,
            enumGroupGuid: "1",
            parentEnumValue: "3303",
            enumValue: "330383",
            enumName: "龙港市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 38,
            enumNameother: "龙港市",
          },
        ],
      },
      {
        fldEnumId: 9590,
        enumGroupGuid: "1",
        parentEnumValue: "33",
        enumValue: "3304",
        enumName: "嘉兴市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 3,
        enumNameother: "嘉兴市",
        children: [
          {
            fldEnumId: 9591,
            enumGroupGuid: "1",
            parentEnumValue: "3304",
            enumValue: "330400",
            enumName: "嘉兴市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 38,
            enumNameother: "嘉兴市",
          },
          {
            fldEnumId: 9593,
            enumGroupGuid: "1",
            parentEnumValue: "3304",
            enumValue: "330402",
            enumName: "南湖区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 39,
            enumNameother: "南湖区",
          },
          {
            fldEnumId: 9595,
            enumGroupGuid: "1",
            parentEnumValue: "3304",
            enumValue: "330411",
            enumName: "秀洲区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 40,
            enumNameother: "秀洲区",
          },
          {
            fldEnumId: 9597,
            enumGroupGuid: "1",
            parentEnumValue: "3304",
            enumValue: "330421",
            enumName: "嘉善县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 41,
            enumNameother: "嘉善县",
          },
          {
            fldEnumId: 9599,
            enumGroupGuid: "1",
            parentEnumValue: "3304",
            enumValue: "330424",
            enumName: "海盐县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 42,
            enumNameother: "海盐县",
          },
          {
            fldEnumId: 9601,
            enumGroupGuid: "1",
            parentEnumValue: "3304",
            enumValue: "330481",
            enumName: "海宁市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 43,
            enumNameother: "海宁市",
          },
          {
            fldEnumId: 9603,
            enumGroupGuid: "1",
            parentEnumValue: "3304",
            enumValue: "330482",
            enumName: "平湖市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 44,
            enumNameother: "平湖市",
          },
          {
            fldEnumId: 9605,
            enumGroupGuid: "1",
            parentEnumValue: "3304",
            enumValue: "330483",
            enumName: "桐乡市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 45,
            enumNameother: "桐乡市",
          },
        ],
      },
      {
        fldEnumId: 9607,
        enumGroupGuid: "1",
        parentEnumValue: "33",
        enumValue: "3305",
        enumName: "湖州市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 4,
        enumNameother: "湖州市",
        children: [
          {
            fldEnumId: 130336,
            enumGroupGuid: "1",
            parentEnumValue: "3305",
            enumValue: "330501",
            enumName: "市辖区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 9608,
            enumGroupGuid: "1",
            parentEnumValue: "3305",
            enumValue: "330500",
            enumName: "湖州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 46,
            enumNameother: "湖州市",
          },
          {
            fldEnumId: 130108,
            enumGroupGuid: "1",
            parentEnumValue: "3305",
            enumValue: "330504",
            enumName: "湖州市",
            enumLevel: 0,
            enumStatus: 2,
            useFrequency: 0,
            enumOrder: 46,
            enumNameother: "湖州市",
          },
          {
            fldEnumId: 9610,
            enumGroupGuid: "1",
            parentEnumValue: "3305",
            enumValue: "330502",
            enumName: "吴兴区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 47,
            enumNameother: "吴兴区",
          },
          {
            fldEnumId: 9612,
            enumGroupGuid: "1",
            parentEnumValue: "3305",
            enumValue: "330503",
            enumName: "南浔区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 48,
            enumNameother: "南浔区",
          },
          {
            fldEnumId: 9614,
            enumGroupGuid: "1",
            parentEnumValue: "3305",
            enumValue: "330521",
            enumName: "德清县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 49,
            enumNameother: "德清县",
          },
          {
            fldEnumId: 9616,
            enumGroupGuid: "1",
            parentEnumValue: "3305",
            enumValue: "330522",
            enumName: "长兴县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 50,
            enumNameother: "长兴县",
          },
          {
            fldEnumId: 9618,
            enumGroupGuid: "1",
            parentEnumValue: "3305",
            enumValue: "330523",
            enumName: "安吉县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 51,
            enumNameother: "安吉县",
          },
        ],
      },
      {
        fldEnumId: 9620,
        enumGroupGuid: "1",
        parentEnumValue: "33",
        enumValue: "3306",
        enumName: "绍兴市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 5,
        enumNameother: "绍兴市",
        children: [
          {
            fldEnumId: 130282,
            enumGroupGuid: "1",
            parentEnumValue: "3306",
            enumValue: "330603",
            enumName: "柯桥区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 9621,
            enumGroupGuid: "1",
            parentEnumValue: "3306",
            enumValue: "330600",
            enumName: "绍兴市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 52,
            enumNameother: "绍兴市",
          },
          {
            fldEnumId: 9623,
            enumGroupGuid: "1",
            parentEnumValue: "3306",
            enumValue: "330602",
            enumName: "越城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 53,
            enumNameother: "越城区",
          },
          {
            fldEnumId: 9625,
            enumGroupGuid: "1",
            parentEnumValue: "3306",
            enumValue: "330621",
            enumName: "绍兴县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 54,
            enumNameother: "绍兴县",
          },
          {
            fldEnumId: 9627,
            enumGroupGuid: "1",
            parentEnumValue: "3306",
            enumValue: "330624",
            enumName: "新昌县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 55,
            enumNameother: "新昌县",
          },
          {
            fldEnumId: 9629,
            enumGroupGuid: "1",
            parentEnumValue: "3306",
            enumValue: "330681",
            enumName: "诸暨市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 56,
            enumNameother: "诸暨市",
          },
          {
            fldEnumId: 9631,
            enumGroupGuid: "1",
            parentEnumValue: "3306",
            enumValue: "330682",
            enumName: "上虞市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 57,
            enumNameother: "上虞市",
          },
          {
            fldEnumId: 9633,
            enumGroupGuid: "1",
            parentEnumValue: "3306",
            enumValue: "330683",
            enumName: "嵊州市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 58,
            enumNameother: "嵊州市",
          },
        ],
      },
      {
        fldEnumId: 9635,
        enumGroupGuid: "1",
        parentEnumValue: "33",
        enumValue: "3307",
        enumName: "金华市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 6,
        enumNameother: "金华市",
        children: [
          {
            fldEnumId: 9636,
            enumGroupGuid: "1",
            parentEnumValue: "3307",
            enumValue: "330700",
            enumName: "金华市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 59,
            enumNameother: "金华市",
          },
          {
            fldEnumId: 9638,
            enumGroupGuid: "1",
            parentEnumValue: "3307",
            enumValue: "330702",
            enumName: "婺城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 60,
            enumNameother: "婺城区",
          },
          {
            fldEnumId: 9640,
            enumGroupGuid: "1",
            parentEnumValue: "3307",
            enumValue: "330703",
            enumName: "金东区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 61,
            enumNameother: "金东区",
          },
          {
            fldEnumId: 130110,
            enumGroupGuid: "1",
            parentEnumValue: "3307",
            enumValue: "330721",
            enumName: "金东区",
            enumLevel: 0,
            enumStatus: 2,
            useFrequency: 0,
            enumOrder: 61,
            enumNameother: "金东区",
          },
          {
            fldEnumId: 9642,
            enumGroupGuid: "1",
            parentEnumValue: "3307",
            enumValue: "330723",
            enumName: "武义县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 62,
            enumNameother: "武义县",
          },
          {
            fldEnumId: 9644,
            enumGroupGuid: "1",
            parentEnumValue: "3307",
            enumValue: "330726",
            enumName: "浦江县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 63,
            enumNameother: "浦江县",
          },
          {
            fldEnumId: 9646,
            enumGroupGuid: "1",
            parentEnumValue: "3307",
            enumValue: "330727",
            enumName: "磐安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 64,
            enumNameother: "磐安县",
          },
          {
            fldEnumId: 9648,
            enumGroupGuid: "1",
            parentEnumValue: "3307",
            enumValue: "330781",
            enumName: "兰溪市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 65,
            enumNameother: "兰溪市",
          },
          {
            fldEnumId: 9650,
            enumGroupGuid: "1",
            parentEnumValue: "3307",
            enumValue: "330782",
            enumName: "义乌市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 66,
            enumNameother: "义乌市",
          },
          {
            fldEnumId: 9652,
            enumGroupGuid: "1",
            parentEnumValue: "3307",
            enumValue: "330783",
            enumName: "东阳市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 67,
            enumNameother: "东阳市",
          },
          {
            fldEnumId: 9654,
            enumGroupGuid: "1",
            parentEnumValue: "3307",
            enumValue: "330784",
            enumName: "永康市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 68,
            enumNameother: "永康市",
          },
        ],
      },
      {
        fldEnumId: 9656,
        enumGroupGuid: "1",
        parentEnumValue: "33",
        enumValue: "3308",
        enumName: "衢州市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 7,
        enumNameother: "衢州市",
        children: [
          {
            fldEnumId: 130107,
            enumGroupGuid: "1",
            parentEnumValue: "3308",
            enumValue: "330821",
            enumName: "衢江区",
            enumLevel: 0,
            enumStatus: 2,
            useFrequency: 0,
            enumOrder: 38,
            enumNameother: "衢江区",
          },
          {
            fldEnumId: 9657,
            enumGroupGuid: "1",
            parentEnumValue: "3308",
            enumValue: "330800",
            enumName: "衢州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 69,
            enumNameother: "衢州市",
          },
          {
            fldEnumId: 9659,
            enumGroupGuid: "1",
            parentEnumValue: "3308",
            enumValue: "330802",
            enumName: "柯城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 70,
            enumNameother: "柯城区",
          },
          {
            fldEnumId: 9661,
            enumGroupGuid: "1",
            parentEnumValue: "3308",
            enumValue: "330803",
            enumName: "衢江区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 71,
            enumNameother: "衢江区",
          },
          {
            fldEnumId: 9663,
            enumGroupGuid: "1",
            parentEnumValue: "3308",
            enumValue: "330822",
            enumName: "常山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 72,
            enumNameother: "常山县",
          },
          {
            fldEnumId: 9665,
            enumGroupGuid: "1",
            parentEnumValue: "3308",
            enumValue: "330824",
            enumName: "开化县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 73,
            enumNameother: "开化县",
          },
          {
            fldEnumId: 9667,
            enumGroupGuid: "1",
            parentEnumValue: "3308",
            enumValue: "330825",
            enumName: "龙游县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 74,
            enumNameother: "龙游县",
          },
          {
            fldEnumId: 9669,
            enumGroupGuid: "1",
            parentEnumValue: "3308",
            enumValue: "330881",
            enumName: "江山市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 75,
            enumNameother: "江山市",
          },
        ],
      },
      {
        fldEnumId: 9671,
        enumGroupGuid: "1",
        parentEnumValue: "33",
        enumValue: "3309",
        enumName: "舟山市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 8,
        enumNameother: "舟山市",
        children: [
          {
            fldEnumId: 9672,
            enumGroupGuid: "1",
            parentEnumValue: "3309",
            enumValue: "330900",
            enumName: "舟山市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 76,
            enumNameother: "舟山市",
          },
          {
            fldEnumId: 9674,
            enumGroupGuid: "1",
            parentEnumValue: "3309",
            enumValue: "330902",
            enumName: "定海区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 77,
            enumNameother: "定海区",
          },
          {
            fldEnumId: 9676,
            enumGroupGuid: "1",
            parentEnumValue: "3309",
            enumValue: "330903",
            enumName: "普陀区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 78,
            enumNameother: "普陀区",
          },
          {
            fldEnumId: 9678,
            enumGroupGuid: "1",
            parentEnumValue: "3309",
            enumValue: "330921",
            enumName: "岱山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 79,
            enumNameother: "岱山县",
          },
          {
            fldEnumId: 9680,
            enumGroupGuid: "1",
            parentEnumValue: "3309",
            enumValue: "330922",
            enumName: "嵊泗县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 80,
            enumNameother: "嵊泗县",
          },
        ],
      },
      {
        fldEnumId: 9682,
        enumGroupGuid: "1",
        parentEnumValue: "33",
        enumValue: "3310",
        enumName: "台州市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 9,
        enumNameother: "台州市",
        children: [
          {
            fldEnumId: 130112,
            enumGroupGuid: "1",
            parentEnumValue: "3310",
            enumValue: "331099",
            enumName: "台州经济开发区(撤)",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 9683,
            enumGroupGuid: "1",
            parentEnumValue: "3310",
            enumValue: "331000",
            enumName: "台州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 81,
            enumNameother: "台州市",
          },
          {
            fldEnumId: 9685,
            enumGroupGuid: "1",
            parentEnumValue: "3310",
            enumValue: "331002",
            enumName: "椒江区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 82,
            enumNameother: "椒江区",
          },
          {
            fldEnumId: 9687,
            enumGroupGuid: "1",
            parentEnumValue: "3310",
            enumValue: "331003",
            enumName: "黄岩区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 83,
            enumNameother: "黄岩区",
          },
          {
            fldEnumId: 9689,
            enumGroupGuid: "1",
            parentEnumValue: "3310",
            enumValue: "331004",
            enumName: "路桥区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 84,
            enumNameother: "路桥区",
          },
          {
            fldEnumId: 9691,
            enumGroupGuid: "1",
            parentEnumValue: "3310",
            enumValue: "331021",
            enumName: "玉环县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 85,
            enumNameother: "玉环县",
          },
          {
            fldEnumId: 9693,
            enumGroupGuid: "1",
            parentEnumValue: "3310",
            enumValue: "331022",
            enumName: "三门县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 86,
            enumNameother: "三门县",
          },
          {
            fldEnumId: 9695,
            enumGroupGuid: "1",
            parentEnumValue: "3310",
            enumValue: "331023",
            enumName: "天台县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 87,
            enumNameother: "天台县",
          },
          {
            fldEnumId: 9697,
            enumGroupGuid: "1",
            parentEnumValue: "3310",
            enumValue: "331024",
            enumName: "仙居县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 88,
            enumNameother: "仙居县",
          },
          {
            fldEnumId: 9699,
            enumGroupGuid: "1",
            parentEnumValue: "3310",
            enumValue: "331081",
            enumName: "温岭市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 89,
            enumNameother: "温岭市",
          },
          {
            fldEnumId: 9701,
            enumGroupGuid: "1",
            parentEnumValue: "3310",
            enumValue: "331082",
            enumName: "临海市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 90,
            enumNameother: "临海市",
          },
        ],
      },
      {
        fldEnumId: 9703,
        enumGroupGuid: "1",
        parentEnumValue: "33",
        enumValue: "3311",
        enumName: "丽水市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 10,
        enumNameother: "丽水市",
        children: [
          {
            fldEnumId: 9704,
            enumGroupGuid: "1",
            parentEnumValue: "3311",
            enumValue: "331100",
            enumName: "丽水市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 91,
            enumNameother: "丽水市",
          },
          {
            fldEnumId: 9706,
            enumGroupGuid: "1",
            parentEnumValue: "3311",
            enumValue: "331102",
            enumName: "莲都区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 92,
            enumNameother: "莲都区",
          },
          {
            fldEnumId: 9708,
            enumGroupGuid: "1",
            parentEnumValue: "3311",
            enumValue: "331121",
            enumName: "青田县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 93,
            enumNameother: "青田县",
          },
          {
            fldEnumId: 9710,
            enumGroupGuid: "1",
            parentEnumValue: "3311",
            enumValue: "331122",
            enumName: "缙云县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 94,
            enumNameother: "缙云县",
          },
          {
            fldEnumId: 9712,
            enumGroupGuid: "1",
            parentEnumValue: "3311",
            enumValue: "331123",
            enumName: "遂昌县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 95,
            enumNameother: "遂昌县",
          },
          {
            fldEnumId: 9714,
            enumGroupGuid: "1",
            parentEnumValue: "3311",
            enumValue: "331124",
            enumName: "松阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 96,
            enumNameother: "松阳县",
          },
          {
            fldEnumId: 9716,
            enumGroupGuid: "1",
            parentEnumValue: "3311",
            enumValue: "331125",
            enumName: "云和县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 97,
            enumNameother: "云和县",
          },
          {
            fldEnumId: 9718,
            enumGroupGuid: "1",
            parentEnumValue: "3311",
            enumValue: "331126",
            enumName: "庆元县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 98,
            enumNameother: "庆元县",
          },
          {
            fldEnumId: 9720,
            enumGroupGuid: "1",
            parentEnumValue: "3311",
            enumValue: "331127",
            enumName: "景宁畲族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 99,
            enumNameother: "景宁畲族自治县",
          },
          {
            fldEnumId: 9722,
            enumGroupGuid: "1",
            parentEnumValue: "3311",
            enumValue: "331181",
            enumName: "龙泉市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 100,
            enumNameother: "龙泉市",
          },
        ],
      },
    ],
  },
  {
    enumValue: "34",
    enumName: "安徽省",
    children: [
      {
        fldEnumId: 9725,
        enumGroupGuid: "1",
        parentEnumValue: "34",
        enumValue: "3401",
        enumName: "合肥市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        enumNameother: "合肥市",
        children: [
          {
            fldEnumId: 9726,
            enumGroupGuid: "1",
            parentEnumValue: "3401",
            enumValue: "340100",
            enumName: "合肥市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
            enumNameother: "合肥市",
          },
          {
            fldEnumId: 130113,
            enumGroupGuid: "1",
            parentEnumValue: "3401",
            enumValue: "341421",
            enumName: "庐江县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 9728,
            enumGroupGuid: "1",
            parentEnumValue: "3401",
            enumValue: "340102",
            enumName: "瑶海区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "瑶海区",
          },
          {
            fldEnumId: 9730,
            enumGroupGuid: "1",
            parentEnumValue: "3401",
            enumValue: "340103",
            enumName: "庐阳区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 2,
            enumNameother: "庐阳区",
          },
          {
            fldEnumId: 9732,
            enumGroupGuid: "1",
            parentEnumValue: "3401",
            enumValue: "340104",
            enumName: "蜀山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 3,
            enumNameother: "蜀山区",
          },
          {
            fldEnumId: 9734,
            enumGroupGuid: "1",
            parentEnumValue: "3401",
            enumValue: "340111",
            enumName: "包河区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 4,
            enumNameother: "包河区",
          },
          {
            fldEnumId: 9736,
            enumGroupGuid: "1",
            parentEnumValue: "3401",
            enumValue: "340121",
            enumName: "长丰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 5,
            enumNameother: "长丰县",
          },
          {
            fldEnumId: 9738,
            enumGroupGuid: "1",
            parentEnumValue: "3401",
            enumValue: "340122",
            enumName: "肥东县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 6,
            enumNameother: "肥东县",
          },
          {
            fldEnumId: 9740,
            enumGroupGuid: "1",
            parentEnumValue: "3401",
            enumValue: "340123",
            enumName: "肥西县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 7,
            enumNameother: "肥西县",
          },
        ],
      },
      {
        fldEnumId: 9742,
        enumGroupGuid: "1",
        parentEnumValue: "34",
        enumValue: "3402",
        enumName: "芜湖市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 1,
        enumNameother: "芜湖市",
        children: [
          {
            fldEnumId: 130114,
            enumGroupGuid: "1",
            parentEnumValue: "3402",
            enumValue: "341422",
            enumName: "无为县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 9743,
            enumGroupGuid: "1",
            parentEnumValue: "3402",
            enumValue: "340200",
            enumName: "芜湖市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 8,
            enumNameother: "芜湖市",
          },
          {
            fldEnumId: 9745,
            enumGroupGuid: "1",
            parentEnumValue: "3402",
            enumValue: "340202",
            enumName: "镜湖区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 9,
            enumNameother: "镜湖区",
          },
          {
            fldEnumId: 9747,
            enumGroupGuid: "1",
            parentEnumValue: "3402",
            enumValue: "340203",
            enumName: "弋江区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 10,
            enumNameother: "弋江区",
          },
          {
            fldEnumId: 9749,
            enumGroupGuid: "1",
            parentEnumValue: "3402",
            enumValue: "340207",
            enumName: "鸠江区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 11,
            enumNameother: "鸠江区",
          },
          {
            fldEnumId: 9751,
            enumGroupGuid: "1",
            parentEnumValue: "3402",
            enumValue: "340208",
            enumName: "三山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 12,
            enumNameother: "三山区",
          },
          {
            fldEnumId: 9753,
            enumGroupGuid: "1",
            parentEnumValue: "3402",
            enumValue: "340221",
            enumName: "芜湖县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 13,
            enumNameother: "芜湖县",
          },
          {
            fldEnumId: 9755,
            enumGroupGuid: "1",
            parentEnumValue: "3402",
            enumValue: "340222",
            enumName: "繁昌县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 14,
            enumNameother: "繁昌县",
          },
          {
            fldEnumId: 9757,
            enumGroupGuid: "1",
            parentEnumValue: "3402",
            enumValue: "340223",
            enumName: "南陵县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 15,
            enumNameother: "南陵县",
          },
        ],
      },
      {
        fldEnumId: 9759,
        enumGroupGuid: "1",
        parentEnumValue: "34",
        enumValue: "3403",
        enumName: "蚌埠市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 2,
        enumNameother: "蚌埠市",
        children: [
          {
            fldEnumId: 9760,
            enumGroupGuid: "1",
            parentEnumValue: "3403",
            enumValue: "340300",
            enumName: "蚌埠市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 16,
            enumNameother: "蚌埠市",
          },
          {
            fldEnumId: 9762,
            enumGroupGuid: "1",
            parentEnumValue: "3403",
            enumValue: "340302",
            enumName: "龙子湖区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 17,
            enumNameother: "龙子湖区",
          },
          {
            fldEnumId: 9764,
            enumGroupGuid: "1",
            parentEnumValue: "3403",
            enumValue: "340303",
            enumName: "蚌山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 18,
            enumNameother: "蚌山区",
          },
          {
            fldEnumId: 9766,
            enumGroupGuid: "1",
            parentEnumValue: "3403",
            enumValue: "340304",
            enumName: "禹会区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 19,
            enumNameother: "禹会区",
          },
          {
            fldEnumId: 9768,
            enumGroupGuid: "1",
            parentEnumValue: "3403",
            enumValue: "340311",
            enumName: "淮上区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 20,
            enumNameother: "淮上区",
          },
          {
            fldEnumId: 9770,
            enumGroupGuid: "1",
            parentEnumValue: "3403",
            enumValue: "340321",
            enumName: "怀远县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 21,
            enumNameother: "怀远县",
          },
          {
            fldEnumId: 9772,
            enumGroupGuid: "1",
            parentEnumValue: "3403",
            enumValue: "340322",
            enumName: "五河县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 22,
            enumNameother: "五河县",
          },
          {
            fldEnumId: 9774,
            enumGroupGuid: "1",
            parentEnumValue: "3403",
            enumValue: "340323",
            enumName: "固镇县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 23,
            enumNameother: "固镇县",
          },
        ],
      },
      {
        fldEnumId: 9776,
        enumGroupGuid: "1",
        parentEnumValue: "34",
        enumValue: "3404",
        enumName: "淮南市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 3,
        enumNameother: "淮南市",
        children: [
          {
            fldEnumId: 130115,
            enumGroupGuid: "1",
            parentEnumValue: "3404",
            enumValue: "341521",
            enumName: "寿县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 9777,
            enumGroupGuid: "1",
            parentEnumValue: "3404",
            enumValue: "340400",
            enumName: "淮南市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 24,
            enumNameother: "淮南市",
          },
          {
            fldEnumId: 9779,
            enumGroupGuid: "1",
            parentEnumValue: "3404",
            enumValue: "340402",
            enumName: "大通区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 25,
            enumNameother: "大通区",
          },
          {
            fldEnumId: 9781,
            enumGroupGuid: "1",
            parentEnumValue: "3404",
            enumValue: "340403",
            enumName: "田家庵区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 26,
            enumNameother: "田家庵区",
          },
          {
            fldEnumId: 9783,
            enumGroupGuid: "1",
            parentEnumValue: "3404",
            enumValue: "340404",
            enumName: "谢家集区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 27,
            enumNameother: "谢家集区",
          },
          {
            fldEnumId: 9785,
            enumGroupGuid: "1",
            parentEnumValue: "3404",
            enumValue: "340405",
            enumName: "八公山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 28,
            enumNameother: "八公山区",
          },
          {
            fldEnumId: 9787,
            enumGroupGuid: "1",
            parentEnumValue: "3404",
            enumValue: "340406",
            enumName: "潘集区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 29,
            enumNameother: "潘集区",
          },
          {
            fldEnumId: 9789,
            enumGroupGuid: "1",
            parentEnumValue: "3404",
            enumValue: "340421",
            enumName: "凤台县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 30,
            enumNameother: "凤台县",
          },
        ],
      },
      {
        fldEnumId: 9791,
        enumGroupGuid: "1",
        parentEnumValue: "34",
        enumValue: "3405",
        enumName: "马鞍山市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 4,
        enumNameother: "马鞍山市",
        children: [
          {
            fldEnumId: 130116,
            enumGroupGuid: "1",
            parentEnumValue: "3405",
            enumValue: "341423",
            enumName: "含山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130117,
            enumGroupGuid: "1",
            parentEnumValue: "3405",
            enumValue: "341424",
            enumName: "和县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 9792,
            enumGroupGuid: "1",
            parentEnumValue: "3405",
            enumValue: "340500",
            enumName: "马鞍山市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 31,
            enumNameother: "马鞍山市",
          },
          {
            fldEnumId: 9794,
            enumGroupGuid: "1",
            parentEnumValue: "3405",
            enumValue: "340502",
            enumName: "金家庄区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 32,
            enumNameother: "金家庄区",
          },
          {
            fldEnumId: 9796,
            enumGroupGuid: "1",
            parentEnumValue: "3405",
            enumValue: "340503",
            enumName: "花山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 33,
            enumNameother: "花山区",
          },
          {
            fldEnumId: 9798,
            enumGroupGuid: "1",
            parentEnumValue: "3405",
            enumValue: "340504",
            enumName: "雨山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 34,
            enumNameother: "雨山区",
          },
          {
            fldEnumId: 9800,
            enumGroupGuid: "1",
            parentEnumValue: "3405",
            enumValue: "340521",
            enumName: "当涂县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 35,
            enumNameother: "当涂县",
          },
        ],
      },
      {
        fldEnumId: 9802,
        enumGroupGuid: "1",
        parentEnumValue: "34",
        enumValue: "3406",
        enumName: "淮北市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 5,
        enumNameother: "淮北市",
        children: [
          {
            fldEnumId: 9803,
            enumGroupGuid: "1",
            parentEnumValue: "3406",
            enumValue: "340600",
            enumName: "淮北市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 36,
            enumNameother: "淮北市",
          },
          {
            fldEnumId: 9805,
            enumGroupGuid: "1",
            parentEnumValue: "3406",
            enumValue: "340602",
            enumName: "杜集区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 37,
            enumNameother: "杜集区",
          },
          {
            fldEnumId: 9807,
            enumGroupGuid: "1",
            parentEnumValue: "3406",
            enumValue: "340603",
            enumName: "相山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 38,
            enumNameother: "相山区",
          },
          {
            fldEnumId: 9809,
            enumGroupGuid: "1",
            parentEnumValue: "3406",
            enumValue: "340604",
            enumName: "烈山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 39,
            enumNameother: "烈山区",
          },
          {
            fldEnumId: 9811,
            enumGroupGuid: "1",
            parentEnumValue: "3406",
            enumValue: "340621",
            enumName: "濉溪县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 40,
            enumNameother: "濉溪县",
          },
        ],
      },
      {
        fldEnumId: 9813,
        enumGroupGuid: "1",
        parentEnumValue: "34",
        enumValue: "3407",
        enumName: "铜陵市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 6,
        enumNameother: "铜陵市",
        children: [
          {
            fldEnumId: 130118,
            enumGroupGuid: "1",
            parentEnumValue: "3407",
            enumValue: "340823",
            enumName: "枞阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 9814,
            enumGroupGuid: "1",
            parentEnumValue: "3407",
            enumValue: "340700",
            enumName: "铜陵市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 41,
            enumNameother: "铜陵市",
          },
          {
            fldEnumId: 9816,
            enumGroupGuid: "1",
            parentEnumValue: "3407",
            enumValue: "340702",
            enumName: "铜官山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 42,
            enumNameother: "铜官山区",
          },
          {
            fldEnumId: 9818,
            enumGroupGuid: "1",
            parentEnumValue: "3407",
            enumValue: "340703",
            enumName: "狮子山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 43,
            enumNameother: "狮子山区",
          },
          {
            fldEnumId: 9820,
            enumGroupGuid: "1",
            parentEnumValue: "3407",
            enumValue: "340711",
            enumName: "郊区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 44,
            enumNameother: "郊区",
          },
          {
            fldEnumId: 9822,
            enumGroupGuid: "1",
            parentEnumValue: "3407",
            enumValue: "340721",
            enumName: "铜陵县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 45,
            enumNameother: "铜陵县",
          },
        ],
      },
      {
        fldEnumId: 9824,
        enumGroupGuid: "1",
        parentEnumValue: "34",
        enumValue: "3408",
        enumName: "安庆市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 7,
        enumNameother: "安庆市",
        children: [
          {
            fldEnumId: 130356,
            enumGroupGuid: "1",
            parentEnumValue: "3408",
            enumValue: "340882",
            enumName: "潜山市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 9825,
            enumGroupGuid: "1",
            parentEnumValue: "3408",
            enumValue: "340800",
            enumName: "安庆市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 46,
            enumNameother: "安庆市",
          },
          {
            fldEnumId: 9827,
            enumGroupGuid: "1",
            parentEnumValue: "3408",
            enumValue: "340802",
            enumName: "迎江区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 47,
            enumNameother: "迎江区",
          },
          {
            fldEnumId: 9829,
            enumGroupGuid: "1",
            parentEnumValue: "3408",
            enumValue: "340803",
            enumName: "大观区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 48,
            enumNameother: "大观区",
          },
          {
            fldEnumId: 9831,
            enumGroupGuid: "1",
            parentEnumValue: "3408",
            enumValue: "340811",
            enumName: "宜秀区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 49,
            enumNameother: "宜秀区",
          },
          {
            fldEnumId: 9833,
            enumGroupGuid: "1",
            parentEnumValue: "3408",
            enumValue: "340822",
            enumName: "怀宁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 50,
            enumNameother: "怀宁县",
          },
          {
            fldEnumId: 9837,
            enumGroupGuid: "1",
            parentEnumValue: "3408",
            enumValue: "340824",
            enumName: "潜山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 52,
            enumNameother: "潜山县",
          },
          {
            fldEnumId: 9839,
            enumGroupGuid: "1",
            parentEnumValue: "3408",
            enumValue: "340825",
            enumName: "太湖县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 53,
            enumNameother: "太湖县",
          },
          {
            fldEnumId: 9841,
            enumGroupGuid: "1",
            parentEnumValue: "3408",
            enumValue: "340826",
            enumName: "宿松县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 54,
            enumNameother: "宿松县",
          },
          {
            fldEnumId: 9843,
            enumGroupGuid: "1",
            parentEnumValue: "3408",
            enumValue: "340827",
            enumName: "望江县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 55,
            enumNameother: "望江县",
          },
          {
            fldEnumId: 9845,
            enumGroupGuid: "1",
            parentEnumValue: "3408",
            enumValue: "340828",
            enumName: "岳西县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 56,
            enumNameother: "岳西县",
          },
          {
            fldEnumId: 9847,
            enumGroupGuid: "1",
            parentEnumValue: "3408",
            enumValue: "340881",
            enumName: "桐城市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 57,
            enumNameother: "桐城市",
          },
        ],
      },
      {
        fldEnumId: 9849,
        enumGroupGuid: "1",
        parentEnumValue: "34",
        enumValue: "3410",
        enumName: "黄山市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 8,
        enumNameother: "黄山市",
        children: [
          {
            fldEnumId: 9850,
            enumGroupGuid: "1",
            parentEnumValue: "3410",
            enumValue: "341000",
            enumName: "黄山市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 58,
            enumNameother: "黄山市",
          },
          {
            fldEnumId: 9852,
            enumGroupGuid: "1",
            parentEnumValue: "3410",
            enumValue: "341002",
            enumName: "屯溪区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 59,
            enumNameother: "屯溪区",
          },
          {
            fldEnumId: 9854,
            enumGroupGuid: "1",
            parentEnumValue: "3410",
            enumValue: "341003",
            enumName: "黄山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 60,
            enumNameother: "黄山区",
          },
          {
            fldEnumId: 9856,
            enumGroupGuid: "1",
            parentEnumValue: "3410",
            enumValue: "341004",
            enumName: "徽州区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 61,
            enumNameother: "徽州区",
          },
          {
            fldEnumId: 9858,
            enumGroupGuid: "1",
            parentEnumValue: "3410",
            enumValue: "341021",
            enumName: "歙县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 62,
            enumNameother: "歙县",
          },
          {
            fldEnumId: 9860,
            enumGroupGuid: "1",
            parentEnumValue: "3410",
            enumValue: "341022",
            enumName: "休宁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 63,
            enumNameother: "休宁县",
          },
          {
            fldEnumId: 9862,
            enumGroupGuid: "1",
            parentEnumValue: "3410",
            enumValue: "341023",
            enumName: "黟县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 64,
            enumNameother: "黟县",
          },
          {
            fldEnumId: 9864,
            enumGroupGuid: "1",
            parentEnumValue: "3410",
            enumValue: "341024",
            enumName: "祁门县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 65,
            enumNameother: "祁门县",
          },
        ],
      },
      {
        fldEnumId: 9866,
        enumGroupGuid: "1",
        parentEnumValue: "34",
        enumValue: "3411",
        enumName: "滁州市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 9,
        enumNameother: "滁州市",
        children: [
          {
            fldEnumId: 9867,
            enumGroupGuid: "1",
            parentEnumValue: "3411",
            enumValue: "341100",
            enumName: "滁州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 66,
            enumNameother: "滁州市",
          },
          {
            fldEnumId: 9869,
            enumGroupGuid: "1",
            parentEnumValue: "3411",
            enumValue: "341102",
            enumName: "琅琊区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 67,
            enumNameother: "琅琊区",
          },
          {
            fldEnumId: 9871,
            enumGroupGuid: "1",
            parentEnumValue: "3411",
            enumValue: "341103",
            enumName: "南谯区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 68,
            enumNameother: "南谯区",
          },
          {
            fldEnumId: 9873,
            enumGroupGuid: "1",
            parentEnumValue: "3411",
            enumValue: "341122",
            enumName: "来安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 69,
            enumNameother: "来安县",
          },
          {
            fldEnumId: 9875,
            enumGroupGuid: "1",
            parentEnumValue: "3411",
            enumValue: "341124",
            enumName: "全椒县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 70,
            enumNameother: "全椒县",
          },
          {
            fldEnumId: 9877,
            enumGroupGuid: "1",
            parentEnumValue: "3411",
            enumValue: "341125",
            enumName: "定远县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 71,
            enumNameother: "定远县",
          },
          {
            fldEnumId: 9879,
            enumGroupGuid: "1",
            parentEnumValue: "3411",
            enumValue: "341126",
            enumName: "凤阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 72,
            enumNameother: "凤阳县",
          },
          {
            fldEnumId: 9881,
            enumGroupGuid: "1",
            parentEnumValue: "3411",
            enumValue: "341181",
            enumName: "天长市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 73,
            enumNameother: "天长市",
          },
          {
            fldEnumId: 9883,
            enumGroupGuid: "1",
            parentEnumValue: "3411",
            enumValue: "341182",
            enumName: "明光市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 74,
            enumNameother: "明光市",
          },
        ],
      },
      {
        fldEnumId: 9885,
        enumGroupGuid: "1",
        parentEnumValue: "34",
        enumValue: "3412",
        enumName: "阜阳市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 10,
        enumNameother: "阜阳市",
        children: [
          {
            fldEnumId: 9886,
            enumGroupGuid: "1",
            parentEnumValue: "3412",
            enumValue: "341200",
            enumName: "阜阳市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 75,
            enumNameother: "阜阳市",
          },
          {
            fldEnumId: 9888,
            enumGroupGuid: "1",
            parentEnumValue: "3412",
            enumValue: "341202",
            enumName: "颍州区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 76,
            enumNameother: "颍州区",
          },
          {
            fldEnumId: 9890,
            enumGroupGuid: "1",
            parentEnumValue: "3412",
            enumValue: "341203",
            enumName: "颍东区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 77,
            enumNameother: "颍东区",
          },
          {
            fldEnumId: 9892,
            enumGroupGuid: "1",
            parentEnumValue: "3412",
            enumValue: "341204",
            enumName: "颍泉区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 78,
            enumNameother: "颍泉区",
          },
          {
            fldEnumId: 9894,
            enumGroupGuid: "1",
            parentEnumValue: "3412",
            enumValue: "341221",
            enumName: "临泉县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 79,
            enumNameother: "临泉县",
          },
          {
            fldEnumId: 9896,
            enumGroupGuid: "1",
            parentEnumValue: "3412",
            enumValue: "341222",
            enumName: "太和县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 80,
            enumNameother: "太和县",
          },
          {
            fldEnumId: 9898,
            enumGroupGuid: "1",
            parentEnumValue: "3412",
            enumValue: "341225",
            enumName: "阜南县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 81,
            enumNameother: "阜南县",
          },
          {
            fldEnumId: 9900,
            enumGroupGuid: "1",
            parentEnumValue: "3412",
            enumValue: "341226",
            enumName: "颍上县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 82,
            enumNameother: "颍上县",
          },
          {
            fldEnumId: 9902,
            enumGroupGuid: "1",
            parentEnumValue: "3412",
            enumValue: "341282",
            enumName: "界首市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 83,
            enumNameother: "界首市",
          },
        ],
      },
      {
        fldEnumId: 9904,
        enumGroupGuid: "1",
        parentEnumValue: "34",
        enumValue: "3413",
        enumName: "宿州市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 11,
        enumNameother: "宿州市",
        children: [
          {
            fldEnumId: 9905,
            enumGroupGuid: "1",
            parentEnumValue: "3413",
            enumValue: "341300",
            enumName: "宿州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 84,
            enumNameother: "宿州市",
          },
          {
            fldEnumId: 9907,
            enumGroupGuid: "1",
            parentEnumValue: "3413",
            enumValue: "341302",
            enumName: "埇桥区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 85,
            enumNameother: "埇桥区",
          },
          {
            fldEnumId: 9909,
            enumGroupGuid: "1",
            parentEnumValue: "3413",
            enumValue: "341321",
            enumName: "砀山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 86,
            enumNameother: "砀山县",
          },
          {
            fldEnumId: 9911,
            enumGroupGuid: "1",
            parentEnumValue: "3413",
            enumValue: "341322",
            enumName: "萧县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 87,
            enumNameother: "萧县",
          },
          {
            fldEnumId: 9913,
            enumGroupGuid: "1",
            parentEnumValue: "3413",
            enumValue: "341323",
            enumName: "灵璧县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 88,
            enumNameother: "灵璧县",
          },
          {
            fldEnumId: 9915,
            enumGroupGuid: "1",
            parentEnumValue: "3413",
            enumValue: "341324",
            enumName: "泗县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 89,
            enumNameother: "泗县",
          },
        ],
      },
      {
        fldEnumId: 9917,
        enumGroupGuid: "1",
        parentEnumValue: "34",
        enumValue: "3414",
        enumName: "巢湖市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 12,
        enumNameother: "巢湖市",
        children: [
          {
            fldEnumId: 9918,
            enumGroupGuid: "1",
            parentEnumValue: "3414",
            enumValue: "341400",
            enumName: "巢湖市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 90,
            enumNameother: "巢湖市",
          },
          {
            fldEnumId: 9920,
            enumGroupGuid: "1",
            parentEnumValue: "3414",
            enumValue: "341402",
            enumName: "居巢区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 91,
            enumNameother: "居巢区",
          },
        ],
      },
      {
        fldEnumId: 9930,
        enumGroupGuid: "1",
        parentEnumValue: "34",
        enumValue: "3415",
        enumName: "六安市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 13,
        enumNameother: "六安市",
        children: [
          {
            fldEnumId: 130350,
            enumGroupGuid: "1",
            parentEnumValue: "3415",
            enumValue: "341504",
            enumName: "叶集区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 9931,
            enumGroupGuid: "1",
            parentEnumValue: "3415",
            enumValue: "341500",
            enumName: "六安市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 96,
            enumNameother: "六安市",
          },
          {
            fldEnumId: 9933,
            enumGroupGuid: "1",
            parentEnumValue: "3415",
            enumValue: "341502",
            enumName: "金安区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 97,
            enumNameother: "金安区",
          },
          {
            fldEnumId: 9935,
            enumGroupGuid: "1",
            parentEnumValue: "3415",
            enumValue: "341503",
            enumName: "裕安区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 98,
            enumNameother: "裕安区",
          },
          {
            fldEnumId: 9939,
            enumGroupGuid: "1",
            parentEnumValue: "3415",
            enumValue: "341522",
            enumName: "霍邱县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 100,
            enumNameother: "霍邱县",
          },
          {
            fldEnumId: 9941,
            enumGroupGuid: "1",
            parentEnumValue: "3415",
            enumValue: "341523",
            enumName: "舒城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 101,
            enumNameother: "舒城县",
          },
          {
            fldEnumId: 9943,
            enumGroupGuid: "1",
            parentEnumValue: "3415",
            enumValue: "341524",
            enumName: "金寨县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 102,
            enumNameother: "金寨县",
          },
          {
            fldEnumId: 9945,
            enumGroupGuid: "1",
            parentEnumValue: "3415",
            enumValue: "341525",
            enumName: "霍山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 103,
            enumNameother: "霍山县",
          },
        ],
      },
      {
        fldEnumId: 9947,
        enumGroupGuid: "1",
        parentEnumValue: "34",
        enumValue: "3416",
        enumName: "亳州市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 14,
        enumNameother: "亳州市",
        children: [
          {
            fldEnumId: 9948,
            enumGroupGuid: "1",
            parentEnumValue: "3416",
            enumValue: "341600",
            enumName: "亳州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 104,
            enumNameother: "亳州市",
          },
          {
            fldEnumId: 9950,
            enumGroupGuid: "1",
            parentEnumValue: "3416",
            enumValue: "341602",
            enumName: "谯城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 105,
            enumNameother: "谯城区",
          },
          {
            fldEnumId: 9952,
            enumGroupGuid: "1",
            parentEnumValue: "3416",
            enumValue: "341621",
            enumName: "涡阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 106,
            enumNameother: "涡阳县",
          },
          {
            fldEnumId: 9954,
            enumGroupGuid: "1",
            parentEnumValue: "3416",
            enumValue: "341622",
            enumName: "蒙城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 107,
            enumNameother: "蒙城县",
          },
          {
            fldEnumId: 9956,
            enumGroupGuid: "1",
            parentEnumValue: "3416",
            enumValue: "341623",
            enumName: "利辛县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 108,
            enumNameother: "利辛县",
          },
        ],
      },
      {
        fldEnumId: 9958,
        enumGroupGuid: "1",
        parentEnumValue: "34",
        enumValue: "3417",
        enumName: "池州市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 15,
        enumNameother: "池州市",
        children: [
          {
            fldEnumId: 9959,
            enumGroupGuid: "1",
            parentEnumValue: "3417",
            enumValue: "341700",
            enumName: "池州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 109,
            enumNameother: "池州市",
          },
          {
            fldEnumId: 9961,
            enumGroupGuid: "1",
            parentEnumValue: "3417",
            enumValue: "341702",
            enumName: "贵池区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 110,
            enumNameother: "贵池区",
          },
          {
            fldEnumId: 9963,
            enumGroupGuid: "1",
            parentEnumValue: "3417",
            enumValue: "341721",
            enumName: "东至县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 111,
            enumNameother: "东至县",
          },
          {
            fldEnumId: 9965,
            enumGroupGuid: "1",
            parentEnumValue: "3417",
            enumValue: "341722",
            enumName: "石台县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 112,
            enumNameother: "石台县",
          },
          {
            fldEnumId: 9967,
            enumGroupGuid: "1",
            parentEnumValue: "3417",
            enumValue: "341723",
            enumName: "青阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 113,
            enumNameother: "青阳县",
          },
        ],
      },
      {
        fldEnumId: 9969,
        enumGroupGuid: "1",
        parentEnumValue: "34",
        enumValue: "3418",
        enumName: "宣城市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 16,
        enumNameother: "宣城市",
        children: [
          {
            fldEnumId: 9970,
            enumGroupGuid: "1",
            parentEnumValue: "3418",
            enumValue: "341800",
            enumName: "宣城市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 114,
            enumNameother: "宣城市",
          },
          {
            fldEnumId: 9972,
            enumGroupGuid: "1",
            parentEnumValue: "3418",
            enumValue: "341802",
            enumName: "宣州区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 115,
            enumNameother: "宣州区",
          },
          {
            fldEnumId: 9974,
            enumGroupGuid: "1",
            parentEnumValue: "3418",
            enumValue: "341821",
            enumName: "郎溪县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 116,
            enumNameother: "郎溪县",
          },
          {
            fldEnumId: 9976,
            enumGroupGuid: "1",
            parentEnumValue: "3418",
            enumValue: "341822",
            enumName: "广德县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 117,
            enumNameother: "广德县",
          },
          {
            fldEnumId: 9978,
            enumGroupGuid: "1",
            parentEnumValue: "3418",
            enumValue: "341823",
            enumName: "泾县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 118,
            enumNameother: "泾县",
          },
          {
            fldEnumId: 9980,
            enumGroupGuid: "1",
            parentEnumValue: "3418",
            enumValue: "341824",
            enumName: "绩溪县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 119,
            enumNameother: "绩溪县",
          },
          {
            fldEnumId: 9982,
            enumGroupGuid: "1",
            parentEnumValue: "3418",
            enumValue: "341825",
            enumName: "旌德县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 120,
            enumNameother: "旌德县",
          },
          {
            fldEnumId: 9984,
            enumGroupGuid: "1",
            parentEnumValue: "3418",
            enumValue: "341881",
            enumName: "宁国市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 121,
            enumNameother: "宁国市",
          },
        ],
      },
    ],
  },
  {
    enumValue: "35",
    enumName: "福建省",
    children: [
      {
        fldEnumId: 9987,
        enumGroupGuid: "1",
        parentEnumValue: "35",
        enumValue: "3501",
        enumName: "福州市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        enumNameother: "福州市",
        children: [
          {
            fldEnumId: 9988,
            enumGroupGuid: "1",
            parentEnumValue: "3501",
            enumValue: "350100",
            enumName: "福州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
            enumNameother: "福州市",
          },
          {
            fldEnumId: 9990,
            enumGroupGuid: "1",
            parentEnumValue: "3501",
            enumValue: "350102",
            enumName: "鼓楼区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "鼓楼区",
          },
          {
            fldEnumId: 9992,
            enumGroupGuid: "1",
            parentEnumValue: "3501",
            enumValue: "350103",
            enumName: "台江区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 2,
            enumNameother: "台江区",
          },
          {
            fldEnumId: 9994,
            enumGroupGuid: "1",
            parentEnumValue: "3501",
            enumValue: "350104",
            enumName: "仓山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 3,
            enumNameother: "仓山区",
          },
          {
            fldEnumId: 9996,
            enumGroupGuid: "1",
            parentEnumValue: "3501",
            enumValue: "350105",
            enumName: "马尾区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 4,
            enumNameother: "马尾区",
          },
          {
            fldEnumId: 9998,
            enumGroupGuid: "1",
            parentEnumValue: "3501",
            enumValue: "350111",
            enumName: "晋安区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 5,
            enumNameother: "晋安区",
          },
          {
            fldEnumId: 10000,
            enumGroupGuid: "1",
            parentEnumValue: "3501",
            enumValue: "350121",
            enumName: "闽侯县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 6,
            enumNameother: "闽侯县",
          },
          {
            fldEnumId: 10002,
            enumGroupGuid: "1",
            parentEnumValue: "3501",
            enumValue: "350122",
            enumName: "连江县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 7,
            enumNameother: "连江县",
          },
          {
            fldEnumId: 10004,
            enumGroupGuid: "1",
            parentEnumValue: "3501",
            enumValue: "350123",
            enumName: "罗源县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 8,
            enumNameother: "罗源县",
          },
          {
            fldEnumId: 10006,
            enumGroupGuid: "1",
            parentEnumValue: "3501",
            enumValue: "350124",
            enumName: "闽清县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 9,
            enumNameother: "闽清县",
          },
          {
            fldEnumId: 10008,
            enumGroupGuid: "1",
            parentEnumValue: "3501",
            enumValue: "350125",
            enumName: "永泰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 10,
            enumNameother: "永泰县",
          },
          {
            fldEnumId: 10010,
            enumGroupGuid: "1",
            parentEnumValue: "3501",
            enumValue: "350128",
            enumName: "平潭县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 11,
            enumNameother: "平潭县",
          },
          {
            fldEnumId: 10012,
            enumGroupGuid: "1",
            parentEnumValue: "3501",
            enumValue: "350181",
            enumName: "福清市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 12,
            enumNameother: "福清市",
          },
          {
            fldEnumId: 10014,
            enumGroupGuid: "1",
            parentEnumValue: "3501",
            enumValue: "350182",
            enumName: "长乐市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 13,
            enumNameother: "长乐市",
          },
        ],
      },
      {
        fldEnumId: 10016,
        enumGroupGuid: "1",
        parentEnumValue: "35",
        enumValue: "3502",
        enumName: "厦门市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 1,
        enumNameother: "厦门市",
        children: [
          {
            fldEnumId: 10017,
            enumGroupGuid: "1",
            parentEnumValue: "3502",
            enumValue: "350200",
            enumName: "厦门市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 14,
            enumNameother: "厦门市",
          },
          {
            fldEnumId: 10019,
            enumGroupGuid: "1",
            parentEnumValue: "3502",
            enumValue: "350203",
            enumName: "思明区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 15,
            enumNameother: "思明区",
          },
          {
            fldEnumId: 10021,
            enumGroupGuid: "1",
            parentEnumValue: "3502",
            enumValue: "350205",
            enumName: "海沧区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 16,
            enumNameother: "海沧区",
          },
          {
            fldEnumId: 10023,
            enumGroupGuid: "1",
            parentEnumValue: "3502",
            enumValue: "350206",
            enumName: "湖里区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 17,
            enumNameother: "湖里区",
          },
          {
            fldEnumId: 10025,
            enumGroupGuid: "1",
            parentEnumValue: "3502",
            enumValue: "350211",
            enumName: "集美区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 18,
            enumNameother: "集美区",
          },
          {
            fldEnumId: 10027,
            enumGroupGuid: "1",
            parentEnumValue: "3502",
            enumValue: "350212",
            enumName: "同安区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 19,
            enumNameother: "同安区",
          },
          {
            fldEnumId: 10029,
            enumGroupGuid: "1",
            parentEnumValue: "3502",
            enumValue: "350213",
            enumName: "翔安区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 20,
            enumNameother: "翔安区",
          },
        ],
      },
      {
        fldEnumId: 10031,
        enumGroupGuid: "1",
        parentEnumValue: "35",
        enumValue: "3503",
        enumName: "莆田市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 2,
        enumNameother: "莆田市",
        children: [
          {
            fldEnumId: 10032,
            enumGroupGuid: "1",
            parentEnumValue: "3503",
            enumValue: "350300",
            enumName: "莆田市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 21,
            enumNameother: "莆田市",
          },
          {
            fldEnumId: 10034,
            enumGroupGuid: "1",
            parentEnumValue: "3503",
            enumValue: "350302",
            enumName: "城厢区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 22,
            enumNameother: "城厢区",
          },
          {
            fldEnumId: 10036,
            enumGroupGuid: "1",
            parentEnumValue: "3503",
            enumValue: "350303",
            enumName: "涵江区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 23,
            enumNameother: "涵江区",
          },
          {
            fldEnumId: 10038,
            enumGroupGuid: "1",
            parentEnumValue: "3503",
            enumValue: "350304",
            enumName: "荔城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 24,
            enumNameother: "荔城区",
          },
          {
            fldEnumId: 10040,
            enumGroupGuid: "1",
            parentEnumValue: "3503",
            enumValue: "350305",
            enumName: "秀屿区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 25,
            enumNameother: "秀屿区",
          },
          {
            fldEnumId: 10042,
            enumGroupGuid: "1",
            parentEnumValue: "3503",
            enumValue: "350322",
            enumName: "仙游县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 26,
            enumNameother: "仙游县",
          },
        ],
      },
      {
        fldEnumId: 10044,
        enumGroupGuid: "1",
        parentEnumValue: "35",
        enumValue: "3504",
        enumName: "三明市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 3,
        enumNameother: "三明市",
        children: [
          {
            fldEnumId: 10045,
            enumGroupGuid: "1",
            parentEnumValue: "3504",
            enumValue: "350400",
            enumName: "三明市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 27,
            enumNameother: "三明市",
          },
          {
            fldEnumId: 10047,
            enumGroupGuid: "1",
            parentEnumValue: "3504",
            enumValue: "350402",
            enumName: "梅列区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 28,
            enumNameother: "梅列区",
          },
          {
            fldEnumId: 10049,
            enumGroupGuid: "1",
            parentEnumValue: "3504",
            enumValue: "350403",
            enumName: "三元区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 29,
            enumNameother: "三元区",
          },
          {
            fldEnumId: 10051,
            enumGroupGuid: "1",
            parentEnumValue: "3504",
            enumValue: "350421",
            enumName: "明溪县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 30,
            enumNameother: "明溪县",
          },
          {
            fldEnumId: 10053,
            enumGroupGuid: "1",
            parentEnumValue: "3504",
            enumValue: "350423",
            enumName: "清流县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 31,
            enumNameother: "清流县",
          },
          {
            fldEnumId: 10055,
            enumGroupGuid: "1",
            parentEnumValue: "3504",
            enumValue: "350424",
            enumName: "宁化县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 32,
            enumNameother: "宁化县",
          },
          {
            fldEnumId: 10057,
            enumGroupGuid: "1",
            parentEnumValue: "3504",
            enumValue: "350425",
            enumName: "大田县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 33,
            enumNameother: "大田县",
          },
          {
            fldEnumId: 10059,
            enumGroupGuid: "1",
            parentEnumValue: "3504",
            enumValue: "350426",
            enumName: "尤溪县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 34,
            enumNameother: "尤溪县",
          },
          {
            fldEnumId: 10061,
            enumGroupGuid: "1",
            parentEnumValue: "3504",
            enumValue: "350427",
            enumName: "沙县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 35,
            enumNameother: "沙县",
          },
          {
            fldEnumId: 10063,
            enumGroupGuid: "1",
            parentEnumValue: "3504",
            enumValue: "350428",
            enumName: "将乐县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 36,
            enumNameother: "将乐县",
          },
          {
            fldEnumId: 10065,
            enumGroupGuid: "1",
            parentEnumValue: "3504",
            enumValue: "350429",
            enumName: "泰宁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 37,
            enumNameother: "泰宁县",
          },
          {
            fldEnumId: 10067,
            enumGroupGuid: "1",
            parentEnumValue: "3504",
            enumValue: "350430",
            enumName: "建宁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 38,
            enumNameother: "建宁县",
          },
          {
            fldEnumId: 10069,
            enumGroupGuid: "1",
            parentEnumValue: "3504",
            enumValue: "350481",
            enumName: "永安市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 39,
            enumNameother: "永安市",
          },
        ],
      },
      {
        fldEnumId: 10071,
        enumGroupGuid: "1",
        parentEnumValue: "35",
        enumValue: "3505",
        enumName: "泉州市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 4,
        enumNameother: "泉州市",
        children: [
          {
            fldEnumId: 10072,
            enumGroupGuid: "1",
            parentEnumValue: "3505",
            enumValue: "350500",
            enumName: "泉州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 40,
            enumNameother: "泉州市",
          },
          {
            fldEnumId: 10074,
            enumGroupGuid: "1",
            parentEnumValue: "3505",
            enumValue: "350502",
            enumName: "鲤城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 41,
            enumNameother: "鲤城区",
          },
          {
            fldEnumId: 10076,
            enumGroupGuid: "1",
            parentEnumValue: "3505",
            enumValue: "350503",
            enumName: "丰泽区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 42,
            enumNameother: "丰泽区",
          },
          {
            fldEnumId: 10078,
            enumGroupGuid: "1",
            parentEnumValue: "3505",
            enumValue: "350504",
            enumName: "洛江区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 43,
            enumNameother: "洛江区",
          },
          {
            fldEnumId: 10080,
            enumGroupGuid: "1",
            parentEnumValue: "3505",
            enumValue: "350505",
            enumName: "泉港区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 44,
            enumNameother: "泉港区",
          },
          {
            fldEnumId: 10082,
            enumGroupGuid: "1",
            parentEnumValue: "3505",
            enumValue: "350521",
            enumName: "惠安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 45,
            enumNameother: "惠安县",
          },
          {
            fldEnumId: 10084,
            enumGroupGuid: "1",
            parentEnumValue: "3505",
            enumValue: "350524",
            enumName: "安溪县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 46,
            enumNameother: "安溪县",
          },
          {
            fldEnumId: 10086,
            enumGroupGuid: "1",
            parentEnumValue: "3505",
            enumValue: "350525",
            enumName: "永春县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 47,
            enumNameother: "永春县",
          },
          {
            fldEnumId: 10088,
            enumGroupGuid: "1",
            parentEnumValue: "3505",
            enumValue: "350526",
            enumName: "德化县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 48,
            enumNameother: "德化县",
          },
          {
            fldEnumId: 10090,
            enumGroupGuid: "1",
            parentEnumValue: "3505",
            enumValue: "350527",
            enumName: "金门县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 49,
            enumNameother: "金门县",
          },
          {
            fldEnumId: 10092,
            enumGroupGuid: "1",
            parentEnumValue: "3505",
            enumValue: "350581",
            enumName: "石狮市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 50,
            enumNameother: "石狮市",
          },
          {
            fldEnumId: 10094,
            enumGroupGuid: "1",
            parentEnumValue: "3505",
            enumValue: "350582",
            enumName: "晋江市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 51,
            enumNameother: "晋江市",
          },
          {
            fldEnumId: 10096,
            enumGroupGuid: "1",
            parentEnumValue: "3505",
            enumValue: "350583",
            enumName: "南安市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 52,
            enumNameother: "南安市",
          },
        ],
      },
      {
        fldEnumId: 10098,
        enumGroupGuid: "1",
        parentEnumValue: "35",
        enumValue: "3506",
        enumName: "漳州市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 5,
        enumNameother: "漳州市",
        children: [
          {
            fldEnumId: 10099,
            enumGroupGuid: "1",
            parentEnumValue: "3506",
            enumValue: "350600",
            enumName: "漳州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 53,
            enumNameother: "漳州市",
          },
          {
            fldEnumId: 10101,
            enumGroupGuid: "1",
            parentEnumValue: "3506",
            enumValue: "350602",
            enumName: "芗城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 54,
            enumNameother: "芗城区",
          },
          {
            fldEnumId: 10103,
            enumGroupGuid: "1",
            parentEnumValue: "3506",
            enumValue: "350603",
            enumName: "龙文区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 55,
            enumNameother: "龙文区",
          },
          {
            fldEnumId: 10105,
            enumGroupGuid: "1",
            parentEnumValue: "3506",
            enumValue: "350622",
            enumName: "云霄县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 56,
            enumNameother: "云霄县",
          },
          {
            fldEnumId: 10107,
            enumGroupGuid: "1",
            parentEnumValue: "3506",
            enumValue: "350623",
            enumName: "漳浦县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 57,
            enumNameother: "漳浦县",
          },
          {
            fldEnumId: 10109,
            enumGroupGuid: "1",
            parentEnumValue: "3506",
            enumValue: "350624",
            enumName: "诏安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 58,
            enumNameother: "诏安县",
          },
          {
            fldEnumId: 10111,
            enumGroupGuid: "1",
            parentEnumValue: "3506",
            enumValue: "350625",
            enumName: "长泰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 59,
            enumNameother: "长泰县",
          },
          {
            fldEnumId: 10113,
            enumGroupGuid: "1",
            parentEnumValue: "3506",
            enumValue: "350626",
            enumName: "东山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 60,
            enumNameother: "东山县",
          },
          {
            fldEnumId: 10115,
            enumGroupGuid: "1",
            parentEnumValue: "3506",
            enumValue: "350627",
            enumName: "南靖县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 61,
            enumNameother: "南靖县",
          },
          {
            fldEnumId: 10117,
            enumGroupGuid: "1",
            parentEnumValue: "3506",
            enumValue: "350628",
            enumName: "平和县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 62,
            enumNameother: "平和县",
          },
          {
            fldEnumId: 10119,
            enumGroupGuid: "1",
            parentEnumValue: "3506",
            enumValue: "350629",
            enumName: "华安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 63,
            enumNameother: "华安县",
          },
          {
            fldEnumId: 10121,
            enumGroupGuid: "1",
            parentEnumValue: "3506",
            enumValue: "350681",
            enumName: "龙海市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 64,
            enumNameother: "龙海市",
          },
        ],
      },
      {
        fldEnumId: 10123,
        enumGroupGuid: "1",
        parentEnumValue: "35",
        enumValue: "3507",
        enumName: "南平市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 6,
        enumNameother: "南平市",
        children: [
          {
            fldEnumId: 10124,
            enumGroupGuid: "1",
            parentEnumValue: "3507",
            enumValue: "350700",
            enumName: "南平市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 65,
            enumNameother: "南平市",
          },
          {
            fldEnumId: 10126,
            enumGroupGuid: "1",
            parentEnumValue: "3507",
            enumValue: "350702",
            enumName: "延平区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 66,
            enumNameother: "延平区",
          },
          {
            fldEnumId: 10128,
            enumGroupGuid: "1",
            parentEnumValue: "3507",
            enumValue: "350721",
            enumName: "顺昌县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 67,
            enumNameother: "顺昌县",
          },
          {
            fldEnumId: 10130,
            enumGroupGuid: "1",
            parentEnumValue: "3507",
            enumValue: "350722",
            enumName: "浦城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 68,
            enumNameother: "浦城县",
          },
          {
            fldEnumId: 10132,
            enumGroupGuid: "1",
            parentEnumValue: "3507",
            enumValue: "350723",
            enumName: "光泽县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 69,
            enumNameother: "光泽县",
          },
          {
            fldEnumId: 10134,
            enumGroupGuid: "1",
            parentEnumValue: "3507",
            enumValue: "350724",
            enumName: "松溪县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 70,
            enumNameother: "松溪县",
          },
          {
            fldEnumId: 10136,
            enumGroupGuid: "1",
            parentEnumValue: "3507",
            enumValue: "350725",
            enumName: "政和县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 71,
            enumNameother: "政和县",
          },
          {
            fldEnumId: 10138,
            enumGroupGuid: "1",
            parentEnumValue: "3507",
            enumValue: "350781",
            enumName: "邵武市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 72,
            enumNameother: "邵武市",
          },
          {
            fldEnumId: 10140,
            enumGroupGuid: "1",
            parentEnumValue: "3507",
            enumValue: "350782",
            enumName: "武夷山市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 73,
            enumNameother: "武夷山市",
          },
          {
            fldEnumId: 10142,
            enumGroupGuid: "1",
            parentEnumValue: "3507",
            enumValue: "350783",
            enumName: "建瓯市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 74,
            enumNameother: "建瓯市",
          },
          {
            fldEnumId: 10144,
            enumGroupGuid: "1",
            parentEnumValue: "3507",
            enumValue: "350784",
            enumName: "建阳市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 75,
            enumNameother: "建阳市",
          },
        ],
      },
      {
        fldEnumId: 10146,
        enumGroupGuid: "1",
        parentEnumValue: "35",
        enumValue: "3508",
        enumName: "龙岩市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 7,
        enumNameother: "龙岩市",
        children: [
          {
            fldEnumId: 10147,
            enumGroupGuid: "1",
            parentEnumValue: "3508",
            enumValue: "350800",
            enumName: "龙岩市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 76,
            enumNameother: "龙岩市",
          },
          {
            fldEnumId: 10149,
            enumGroupGuid: "1",
            parentEnumValue: "3508",
            enumValue: "350802",
            enumName: "新罗区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 77,
            enumNameother: "新罗区",
          },
          {
            fldEnumId: 10151,
            enumGroupGuid: "1",
            parentEnumValue: "3508",
            enumValue: "350821",
            enumName: "长汀县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 78,
            enumNameother: "长汀县",
          },
          {
            fldEnumId: 10153,
            enumGroupGuid: "1",
            parentEnumValue: "3508",
            enumValue: "350822",
            enumName: "永定县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 79,
            enumNameother: "永定县",
          },
          {
            fldEnumId: 10155,
            enumGroupGuid: "1",
            parentEnumValue: "3508",
            enumValue: "350823",
            enumName: "上杭县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 80,
            enumNameother: "上杭县",
          },
          {
            fldEnumId: 10157,
            enumGroupGuid: "1",
            parentEnumValue: "3508",
            enumValue: "350824",
            enumName: "武平县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 81,
            enumNameother: "武平县",
          },
          {
            fldEnumId: 10159,
            enumGroupGuid: "1",
            parentEnumValue: "3508",
            enumValue: "350825",
            enumName: "连城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 82,
            enumNameother: "连城县",
          },
          {
            fldEnumId: 10161,
            enumGroupGuid: "1",
            parentEnumValue: "3508",
            enumValue: "350881",
            enumName: "漳平市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 83,
            enumNameother: "漳平市",
          },
        ],
      },
      {
        fldEnumId: 10163,
        enumGroupGuid: "1",
        parentEnumValue: "35",
        enumValue: "3509",
        enumName: "宁德市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 8,
        enumNameother: "宁德市",
        children: [
          {
            fldEnumId: 10164,
            enumGroupGuid: "1",
            parentEnumValue: "3509",
            enumValue: "350900",
            enumName: "宁德市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 84,
            enumNameother: "宁德市",
          },
          {
            fldEnumId: 10166,
            enumGroupGuid: "1",
            parentEnumValue: "3509",
            enumValue: "350902",
            enumName: "蕉城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 85,
            enumNameother: "蕉城区",
          },
          {
            fldEnumId: 10168,
            enumGroupGuid: "1",
            parentEnumValue: "3509",
            enumValue: "350921",
            enumName: "霞浦县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 86,
            enumNameother: "霞浦县",
          },
          {
            fldEnumId: 10170,
            enumGroupGuid: "1",
            parentEnumValue: "3509",
            enumValue: "350922",
            enumName: "古田县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 87,
            enumNameother: "古田县",
          },
          {
            fldEnumId: 10172,
            enumGroupGuid: "1",
            parentEnumValue: "3509",
            enumValue: "350923",
            enumName: "屏南县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 88,
            enumNameother: "屏南县",
          },
          {
            fldEnumId: 10174,
            enumGroupGuid: "1",
            parentEnumValue: "3509",
            enumValue: "350924",
            enumName: "寿宁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 89,
            enumNameother: "寿宁县",
          },
          {
            fldEnumId: 10176,
            enumGroupGuid: "1",
            parentEnumValue: "3509",
            enumValue: "350925",
            enumName: "周宁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 90,
            enumNameother: "周宁县",
          },
          {
            fldEnumId: 10178,
            enumGroupGuid: "1",
            parentEnumValue: "3509",
            enumValue: "350926",
            enumName: "柘荣县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 91,
            enumNameother: "柘荣县",
          },
          {
            fldEnumId: 10180,
            enumGroupGuid: "1",
            parentEnumValue: "3509",
            enumValue: "350981",
            enumName: "福安市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 92,
            enumNameother: "福安市",
          },
          {
            fldEnumId: 10182,
            enumGroupGuid: "1",
            parentEnumValue: "3509",
            enumValue: "350982",
            enumName: "福鼎市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 93,
            enumNameother: "福鼎市",
          },
        ],
      },
    ],
  },
  {
    enumValue: "36",
    enumName: "江西省",
    children: [
      {
        fldEnumId: 10185,
        enumGroupGuid: "1",
        parentEnumValue: "36",
        enumValue: "3601",
        enumName: "南昌市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        enumNameother: "南昌市",
        children: [
          {
            fldEnumId: 10186,
            enumGroupGuid: "1",
            parentEnumValue: "3601",
            enumValue: "360100",
            enumName: "南昌市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
            enumNameother: "南昌市",
          },
          {
            fldEnumId: 130193,
            enumGroupGuid: "1",
            parentEnumValue: "3601",
            enumValue: "360112",
            enumName: "新建区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130323,
            enumGroupGuid: "1",
            parentEnumValue: "3601",
            enumValue: "360113",
            enumName: "红谷滩区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 10188,
            enumGroupGuid: "1",
            parentEnumValue: "3601",
            enumValue: "360102",
            enumName: "东湖区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "东湖区",
          },
          {
            fldEnumId: 10190,
            enumGroupGuid: "1",
            parentEnumValue: "3601",
            enumValue: "360103",
            enumName: "西湖区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 2,
            enumNameother: "西湖区",
          },
          {
            fldEnumId: 10192,
            enumGroupGuid: "1",
            parentEnumValue: "3601",
            enumValue: "360104",
            enumName: "青云谱区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 3,
            enumNameother: "青云谱区",
          },
          {
            fldEnumId: 10194,
            enumGroupGuid: "1",
            parentEnumValue: "3601",
            enumValue: "360105",
            enumName: "湾里区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 4,
            enumNameother: "湾里区",
          },
          {
            fldEnumId: 10196,
            enumGroupGuid: "1",
            parentEnumValue: "3601",
            enumValue: "360111",
            enumName: "青山湖区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 5,
            enumNameother: "青山湖区",
          },
          {
            fldEnumId: 10198,
            enumGroupGuid: "1",
            parentEnumValue: "3601",
            enumValue: "360121",
            enumName: "南昌县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 6,
            enumNameother: "南昌县",
          },
          {
            fldEnumId: 10200,
            enumGroupGuid: "1",
            parentEnumValue: "3601",
            enumValue: "360122",
            enumName: "新建县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 7,
            enumNameother: "新建县",
          },
          {
            fldEnumId: 10202,
            enumGroupGuid: "1",
            parentEnumValue: "3601",
            enumValue: "360123",
            enumName: "安义县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 8,
            enumNameother: "安义县",
          },
          {
            fldEnumId: 10204,
            enumGroupGuid: "1",
            parentEnumValue: "3601",
            enumValue: "360124",
            enumName: "进贤县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 9,
            enumNameother: "进贤县",
          },
        ],
      },
      {
        fldEnumId: 10206,
        enumGroupGuid: "1",
        parentEnumValue: "36",
        enumValue: "3602",
        enumName: "景德镇市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 1,
        enumNameother: "景德镇市",
        children: [
          {
            fldEnumId: 10207,
            enumGroupGuid: "1",
            parentEnumValue: "3602",
            enumValue: "360200",
            enumName: "景德镇市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 10,
            enumNameother: "景德镇市",
          },
          {
            fldEnumId: 10209,
            enumGroupGuid: "1",
            parentEnumValue: "3602",
            enumValue: "360202",
            enumName: "昌江区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 11,
            enumNameother: "昌江区",
          },
          {
            fldEnumId: 10211,
            enumGroupGuid: "1",
            parentEnumValue: "3602",
            enumValue: "360203",
            enumName: "珠山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 12,
            enumNameother: "珠山区",
          },
          {
            fldEnumId: 10213,
            enumGroupGuid: "1",
            parentEnumValue: "3602",
            enumValue: "360222",
            enumName: "浮梁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 13,
            enumNameother: "浮梁县",
          },
          {
            fldEnumId: 10215,
            enumGroupGuid: "1",
            parentEnumValue: "3602",
            enumValue: "360281",
            enumName: "乐平市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 14,
            enumNameother: "乐平市",
          },
        ],
      },
      {
        fldEnumId: 10217,
        enumGroupGuid: "1",
        parentEnumValue: "36",
        enumValue: "3603",
        enumName: "萍乡市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 2,
        enumNameother: "萍乡市",
        children: [
          {
            fldEnumId: 10218,
            enumGroupGuid: "1",
            parentEnumValue: "3603",
            enumValue: "360300",
            enumName: "萍乡市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 15,
            enumNameother: "萍乡市",
          },
          {
            fldEnumId: 10220,
            enumGroupGuid: "1",
            parentEnumValue: "3603",
            enumValue: "360302",
            enumName: "安源区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 16,
            enumNameother: "安源区",
          },
          {
            fldEnumId: 10222,
            enumGroupGuid: "1",
            parentEnumValue: "3603",
            enumValue: "360313",
            enumName: "湘东区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 17,
            enumNameother: "湘东区",
          },
          {
            fldEnumId: 10224,
            enumGroupGuid: "1",
            parentEnumValue: "3603",
            enumValue: "360321",
            enumName: "莲花县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 18,
            enumNameother: "莲花县",
          },
          {
            fldEnumId: 10226,
            enumGroupGuid: "1",
            parentEnumValue: "3603",
            enumValue: "360322",
            enumName: "上栗县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 19,
            enumNameother: "上栗县",
          },
          {
            fldEnumId: 10228,
            enumGroupGuid: "1",
            parentEnumValue: "3603",
            enumValue: "360323",
            enumName: "芦溪县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 20,
            enumNameother: "芦溪县",
          },
        ],
      },
      {
        fldEnumId: 10230,
        enumGroupGuid: "1",
        parentEnumValue: "36",
        enumValue: "3604",
        enumName: "九江市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 3,
        enumNameother: "九江市",
        children: [
          {
            fldEnumId: 130194,
            enumGroupGuid: "1",
            parentEnumValue: "3604",
            enumValue: "360482",
            enumName: "共青城市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 10231,
            enumGroupGuid: "1",
            parentEnumValue: "3604",
            enumValue: "360400",
            enumName: "九江市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 21,
            enumNameother: "九江市",
          },
          {
            fldEnumId: 10233,
            enumGroupGuid: "1",
            parentEnumValue: "3604",
            enumValue: "360402",
            enumName: "庐山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 22,
            enumNameother: "庐山区",
          },
          {
            fldEnumId: 10235,
            enumGroupGuid: "1",
            parentEnumValue: "3604",
            enumValue: "360403",
            enumName: "浔阳区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 23,
            enumNameother: "浔阳区",
          },
          {
            fldEnumId: 10237,
            enumGroupGuid: "1",
            parentEnumValue: "3604",
            enumValue: "360421",
            enumName: "九江县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 24,
            enumNameother: "九江县",
          },
          {
            fldEnumId: 10239,
            enumGroupGuid: "1",
            parentEnumValue: "3604",
            enumValue: "360423",
            enumName: "武宁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 25,
            enumNameother: "武宁县",
          },
          {
            fldEnumId: 10241,
            enumGroupGuid: "1",
            parentEnumValue: "3604",
            enumValue: "360424",
            enumName: "修水县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 26,
            enumNameother: "修水县",
          },
          {
            fldEnumId: 10243,
            enumGroupGuid: "1",
            parentEnumValue: "3604",
            enumValue: "360425",
            enumName: "永修县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 27,
            enumNameother: "永修县",
          },
          {
            fldEnumId: 10245,
            enumGroupGuid: "1",
            parentEnumValue: "3604",
            enumValue: "360426",
            enumName: "德安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 28,
            enumNameother: "德安县",
          },
          {
            fldEnumId: 10247,
            enumGroupGuid: "1",
            parentEnumValue: "3604",
            enumValue: "360427",
            enumName: "星子县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 29,
            enumNameother: "星子县",
          },
          {
            fldEnumId: 10249,
            enumGroupGuid: "1",
            parentEnumValue: "3604",
            enumValue: "360428",
            enumName: "都昌县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 30,
            enumNameother: "都昌县",
          },
          {
            fldEnumId: 10251,
            enumGroupGuid: "1",
            parentEnumValue: "3604",
            enumValue: "360429",
            enumName: "湖口县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 31,
            enumNameother: "湖口县",
          },
          {
            fldEnumId: 10253,
            enumGroupGuid: "1",
            parentEnumValue: "3604",
            enumValue: "360430",
            enumName: "彭泽县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 32,
            enumNameother: "彭泽县",
          },
          {
            fldEnumId: 10255,
            enumGroupGuid: "1",
            parentEnumValue: "3604",
            enumValue: "360481",
            enumName: "瑞昌市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 33,
            enumNameother: "瑞昌市",
          },
        ],
      },
      {
        fldEnumId: 10257,
        enumGroupGuid: "1",
        parentEnumValue: "36",
        enumValue: "3605",
        enumName: "新余市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 4,
        enumNameother: "新余市",
        children: [
          {
            fldEnumId: 10258,
            enumGroupGuid: "1",
            parentEnumValue: "3605",
            enumValue: "360500",
            enumName: "新余市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 34,
            enumNameother: "新余市",
          },
          {
            fldEnumId: 10260,
            enumGroupGuid: "1",
            parentEnumValue: "3605",
            enumValue: "360502",
            enumName: "渝水区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 35,
            enumNameother: "渝水区",
          },
          {
            fldEnumId: 10262,
            enumGroupGuid: "1",
            parentEnumValue: "3605",
            enumValue: "360521",
            enumName: "分宜县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 36,
            enumNameother: "分宜县",
          },
        ],
      },
      {
        fldEnumId: 10264,
        enumGroupGuid: "1",
        parentEnumValue: "36",
        enumValue: "3606",
        enumName: "鹰潭市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 5,
        enumNameother: "鹰潭市",
        children: [
          {
            fldEnumId: 10265,
            enumGroupGuid: "1",
            parentEnumValue: "3606",
            enumValue: "360600",
            enumName: "鹰潭市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 37,
            enumNameother: "鹰潭市",
          },
          {
            fldEnumId: 10267,
            enumGroupGuid: "1",
            parentEnumValue: "3606",
            enumValue: "360602",
            enumName: "月湖区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 38,
            enumNameother: "月湖区",
          },
          {
            fldEnumId: 10269,
            enumGroupGuid: "1",
            parentEnumValue: "3606",
            enumValue: "360622",
            enumName: "余江县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 39,
            enumNameother: "余江县",
          },
          {
            fldEnumId: 10271,
            enumGroupGuid: "1",
            parentEnumValue: "3606",
            enumValue: "360681",
            enumName: "贵溪市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 40,
            enumNameother: "贵溪市",
          },
        ],
      },
      {
        fldEnumId: 10273,
        enumGroupGuid: "1",
        parentEnumValue: "36",
        enumValue: "3607",
        enumName: "赣州市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 6,
        enumNameother: "赣州市",
        children: [
          {
            fldEnumId: 10274,
            enumGroupGuid: "1",
            parentEnumValue: "3607",
            enumValue: "360700",
            enumName: "赣州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 41,
            enumNameother: "赣州市",
          },
          {
            fldEnumId: 10276,
            enumGroupGuid: "1",
            parentEnumValue: "3607",
            enumValue: "360702",
            enumName: "章贡区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 42,
            enumNameother: "章贡区",
          },
          {
            fldEnumId: 10278,
            enumGroupGuid: "1",
            parentEnumValue: "3607",
            enumValue: "360721",
            enumName: "赣县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 43,
            enumNameother: "赣县",
          },
          {
            fldEnumId: 10280,
            enumGroupGuid: "1",
            parentEnumValue: "3607",
            enumValue: "360722",
            enumName: "信丰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 44,
            enumNameother: "信丰县",
          },
          {
            fldEnumId: 10282,
            enumGroupGuid: "1",
            parentEnumValue: "3607",
            enumValue: "360723",
            enumName: "大余县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 45,
            enumNameother: "大余县",
          },
          {
            fldEnumId: 10284,
            enumGroupGuid: "1",
            parentEnumValue: "3607",
            enumValue: "360724",
            enumName: "上犹县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 46,
            enumNameother: "上犹县",
          },
          {
            fldEnumId: 10286,
            enumGroupGuid: "1",
            parentEnumValue: "3607",
            enumValue: "360725",
            enumName: "崇义县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 47,
            enumNameother: "崇义县",
          },
          {
            fldEnumId: 10288,
            enumGroupGuid: "1",
            parentEnumValue: "3607",
            enumValue: "360726",
            enumName: "安远县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 48,
            enumNameother: "安远县",
          },
          {
            fldEnumId: 10290,
            enumGroupGuid: "1",
            parentEnumValue: "3607",
            enumValue: "360727",
            enumName: "龙南县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 49,
            enumNameother: "龙南县",
          },
          {
            fldEnumId: 10292,
            enumGroupGuid: "1",
            parentEnumValue: "3607",
            enumValue: "360728",
            enumName: "定南县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 50,
            enumNameother: "定南县",
          },
          {
            fldEnumId: 10294,
            enumGroupGuid: "1",
            parentEnumValue: "3607",
            enumValue: "360729",
            enumName: "全南县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 51,
            enumNameother: "全南县",
          },
          {
            fldEnumId: 10296,
            enumGroupGuid: "1",
            parentEnumValue: "3607",
            enumValue: "360730",
            enumName: "宁都县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 52,
            enumNameother: "宁都县",
          },
          {
            fldEnumId: 10298,
            enumGroupGuid: "1",
            parentEnumValue: "3607",
            enumValue: "360731",
            enumName: "于都县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 53,
            enumNameother: "于都县",
          },
          {
            fldEnumId: 10300,
            enumGroupGuid: "1",
            parentEnumValue: "3607",
            enumValue: "360732",
            enumName: "兴国县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 54,
            enumNameother: "兴国县",
          },
          {
            fldEnumId: 10302,
            enumGroupGuid: "1",
            parentEnumValue: "3607",
            enumValue: "360733",
            enumName: "会昌县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 55,
            enumNameother: "会昌县",
          },
          {
            fldEnumId: 10304,
            enumGroupGuid: "1",
            parentEnumValue: "3607",
            enumValue: "360734",
            enumName: "寻乌县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 56,
            enumNameother: "寻乌县",
          },
          {
            fldEnumId: 10306,
            enumGroupGuid: "1",
            parentEnumValue: "3607",
            enumValue: "360735",
            enumName: "石城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 57,
            enumNameother: "石城县",
          },
          {
            fldEnumId: 10308,
            enumGroupGuid: "1",
            parentEnumValue: "3607",
            enumValue: "360781",
            enumName: "瑞金市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 58,
            enumNameother: "瑞金市",
          },
          {
            fldEnumId: 10310,
            enumGroupGuid: "1",
            parentEnumValue: "3607",
            enumValue: "360782",
            enumName: "南康市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 59,
            enumNameother: "南康市",
          },
        ],
      },
      {
        fldEnumId: 10312,
        enumGroupGuid: "1",
        parentEnumValue: "36",
        enumValue: "3608",
        enumName: "吉安市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 7,
        enumNameother: "吉安市",
        children: [
          {
            fldEnumId: 10313,
            enumGroupGuid: "1",
            parentEnumValue: "3608",
            enumValue: "360800",
            enumName: "吉安市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 60,
            enumNameother: "吉安市",
          },
          {
            fldEnumId: 10315,
            enumGroupGuid: "1",
            parentEnumValue: "3608",
            enumValue: "360802",
            enumName: "吉州区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 61,
            enumNameother: "吉州区",
          },
          {
            fldEnumId: 10317,
            enumGroupGuid: "1",
            parentEnumValue: "3608",
            enumValue: "360803",
            enumName: "青原区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 62,
            enumNameother: "青原区",
          },
          {
            fldEnumId: 10319,
            enumGroupGuid: "1",
            parentEnumValue: "3608",
            enumValue: "360821",
            enumName: "吉安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 63,
            enumNameother: "吉安县",
          },
          {
            fldEnumId: 10321,
            enumGroupGuid: "1",
            parentEnumValue: "3608",
            enumValue: "360822",
            enumName: "吉水县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 64,
            enumNameother: "吉水县",
          },
          {
            fldEnumId: 10323,
            enumGroupGuid: "1",
            parentEnumValue: "3608",
            enumValue: "360823",
            enumName: "峡江县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 65,
            enumNameother: "峡江县",
          },
          {
            fldEnumId: 10325,
            enumGroupGuid: "1",
            parentEnumValue: "3608",
            enumValue: "360824",
            enumName: "新干县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 66,
            enumNameother: "新干县",
          },
          {
            fldEnumId: 10327,
            enumGroupGuid: "1",
            parentEnumValue: "3608",
            enumValue: "360825",
            enumName: "永丰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 67,
            enumNameother: "永丰县",
          },
          {
            fldEnumId: 10329,
            enumGroupGuid: "1",
            parentEnumValue: "3608",
            enumValue: "360826",
            enumName: "泰和县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 68,
            enumNameother: "泰和县",
          },
          {
            fldEnumId: 10331,
            enumGroupGuid: "1",
            parentEnumValue: "3608",
            enumValue: "360827",
            enumName: "遂川县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 69,
            enumNameother: "遂川县",
          },
          {
            fldEnumId: 10333,
            enumGroupGuid: "1",
            parentEnumValue: "3608",
            enumValue: "360828",
            enumName: "万安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 70,
            enumNameother: "万安县",
          },
          {
            fldEnumId: 10335,
            enumGroupGuid: "1",
            parentEnumValue: "3608",
            enumValue: "360829",
            enumName: "安福县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 71,
            enumNameother: "安福县",
          },
          {
            fldEnumId: 10337,
            enumGroupGuid: "1",
            parentEnumValue: "3608",
            enumValue: "360830",
            enumName: "永新县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 72,
            enumNameother: "永新县",
          },
          {
            fldEnumId: 10339,
            enumGroupGuid: "1",
            parentEnumValue: "3608",
            enumValue: "360881",
            enumName: "井冈山市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 73,
            enumNameother: "井冈山市",
          },
        ],
      },
      {
        fldEnumId: 10341,
        enumGroupGuid: "1",
        parentEnumValue: "36",
        enumValue: "3609",
        enumName: "宜春市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 8,
        enumNameother: "宜春市",
        children: [
          {
            fldEnumId: 10342,
            enumGroupGuid: "1",
            parentEnumValue: "3609",
            enumValue: "360900",
            enumName: "宜春市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 74,
            enumNameother: "宜春市",
          },
          {
            fldEnumId: 10344,
            enumGroupGuid: "1",
            parentEnumValue: "3609",
            enumValue: "360902",
            enumName: "袁州区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 75,
            enumNameother: "袁州区",
          },
          {
            fldEnumId: 10346,
            enumGroupGuid: "1",
            parentEnumValue: "3609",
            enumValue: "360921",
            enumName: "奉新县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 76,
            enumNameother: "奉新县",
          },
          {
            fldEnumId: 10348,
            enumGroupGuid: "1",
            parentEnumValue: "3609",
            enumValue: "360922",
            enumName: "万载县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 77,
            enumNameother: "万载县",
          },
          {
            fldEnumId: 10350,
            enumGroupGuid: "1",
            parentEnumValue: "3609",
            enumValue: "360923",
            enumName: "上高县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 78,
            enumNameother: "上高县",
          },
          {
            fldEnumId: 10352,
            enumGroupGuid: "1",
            parentEnumValue: "3609",
            enumValue: "360924",
            enumName: "宜丰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 79,
            enumNameother: "宜丰县",
          },
          {
            fldEnumId: 10354,
            enumGroupGuid: "1",
            parentEnumValue: "3609",
            enumValue: "360925",
            enumName: "靖安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 80,
            enumNameother: "靖安县",
          },
          {
            fldEnumId: 10356,
            enumGroupGuid: "1",
            parentEnumValue: "3609",
            enumValue: "360926",
            enumName: "铜鼓县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 81,
            enumNameother: "铜鼓县",
          },
          {
            fldEnumId: 10358,
            enumGroupGuid: "1",
            parentEnumValue: "3609",
            enumValue: "360981",
            enumName: "丰城市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 82,
            enumNameother: "丰城市",
          },
          {
            fldEnumId: 10360,
            enumGroupGuid: "1",
            parentEnumValue: "3609",
            enumValue: "360982",
            enumName: "樟树市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 83,
            enumNameother: "樟树市",
          },
          {
            fldEnumId: 10362,
            enumGroupGuid: "1",
            parentEnumValue: "3609",
            enumValue: "360983",
            enumName: "高安市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 84,
            enumNameother: "高安市",
          },
        ],
      },
      {
        fldEnumId: 10364,
        enumGroupGuid: "1",
        parentEnumValue: "36",
        enumValue: "3610",
        enumName: "抚州市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 9,
        enumNameother: "抚州市",
        children: [
          {
            fldEnumId: 10365,
            enumGroupGuid: "1",
            parentEnumValue: "3610",
            enumValue: "361000",
            enumName: "抚州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 85,
            enumNameother: "抚州市",
          },
          {
            fldEnumId: 10367,
            enumGroupGuid: "1",
            parentEnumValue: "3610",
            enumValue: "361002",
            enumName: "临川区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 86,
            enumNameother: "临川区",
          },
          {
            fldEnumId: 10369,
            enumGroupGuid: "1",
            parentEnumValue: "3610",
            enumValue: "361021",
            enumName: "南城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 87,
            enumNameother: "南城县",
          },
          {
            fldEnumId: 10371,
            enumGroupGuid: "1",
            parentEnumValue: "3610",
            enumValue: "361022",
            enumName: "黎川县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 88,
            enumNameother: "黎川县",
          },
          {
            fldEnumId: 10373,
            enumGroupGuid: "1",
            parentEnumValue: "3610",
            enumValue: "361023",
            enumName: "南丰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 89,
            enumNameother: "南丰县",
          },
          {
            fldEnumId: 10375,
            enumGroupGuid: "1",
            parentEnumValue: "3610",
            enumValue: "361024",
            enumName: "崇仁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 90,
            enumNameother: "崇仁县",
          },
          {
            fldEnumId: 10377,
            enumGroupGuid: "1",
            parentEnumValue: "3610",
            enumValue: "361025",
            enumName: "乐安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 91,
            enumNameother: "乐安县",
          },
          {
            fldEnumId: 10379,
            enumGroupGuid: "1",
            parentEnumValue: "3610",
            enumValue: "361026",
            enumName: "宜黄县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 92,
            enumNameother: "宜黄县",
          },
          {
            fldEnumId: 10381,
            enumGroupGuid: "1",
            parentEnumValue: "3610",
            enumValue: "361027",
            enumName: "金溪县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 93,
            enumNameother: "金溪县",
          },
          {
            fldEnumId: 10383,
            enumGroupGuid: "1",
            parentEnumValue: "3610",
            enumValue: "361028",
            enumName: "资溪县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 94,
            enumNameother: "资溪县",
          },
          {
            fldEnumId: 10385,
            enumGroupGuid: "1",
            parentEnumValue: "3610",
            enumValue: "361029",
            enumName: "东乡县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 95,
            enumNameother: "东乡县",
          },
          {
            fldEnumId: 10387,
            enumGroupGuid: "1",
            parentEnumValue: "3610",
            enumValue: "361030",
            enumName: "广昌县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 96,
            enumNameother: "广昌县",
          },
        ],
      },
      {
        fldEnumId: 10389,
        enumGroupGuid: "1",
        parentEnumValue: "36",
        enumValue: "3611",
        enumName: "上饶市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 10,
        enumNameother: "上饶市",
        children: [
          {
            fldEnumId: 10390,
            enumGroupGuid: "1",
            parentEnumValue: "3611",
            enumValue: "361100",
            enumName: "上饶市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 97,
            enumNameother: "上饶市",
          },
          {
            fldEnumId: 10392,
            enumGroupGuid: "1",
            parentEnumValue: "3611",
            enumValue: "361102",
            enumName: "信州区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 98,
            enumNameother: "信州区",
          },
          {
            fldEnumId: 10394,
            enumGroupGuid: "1",
            parentEnumValue: "3611",
            enumValue: "361121",
            enumName: "上饶县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 99,
            enumNameother: "上饶县",
          },
          {
            fldEnumId: 10396,
            enumGroupGuid: "1",
            parentEnumValue: "3611",
            enumValue: "361122",
            enumName: "广丰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 100,
            enumNameother: "广丰县",
          },
          {
            fldEnumId: 10398,
            enumGroupGuid: "1",
            parentEnumValue: "3611",
            enumValue: "361123",
            enumName: "玉山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 101,
            enumNameother: "玉山县",
          },
          {
            fldEnumId: 10400,
            enumGroupGuid: "1",
            parentEnumValue: "3611",
            enumValue: "361124",
            enumName: "铅山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 102,
            enumNameother: "铅山县",
          },
          {
            fldEnumId: 10402,
            enumGroupGuid: "1",
            parentEnumValue: "3611",
            enumValue: "361125",
            enumName: "横峰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 103,
            enumNameother: "横峰县",
          },
          {
            fldEnumId: 10404,
            enumGroupGuid: "1",
            parentEnumValue: "3611",
            enumValue: "361126",
            enumName: "弋阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 104,
            enumNameother: "弋阳县",
          },
          {
            fldEnumId: 10406,
            enumGroupGuid: "1",
            parentEnumValue: "3611",
            enumValue: "361127",
            enumName: "余干县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 105,
            enumNameother: "余干县",
          },
          {
            fldEnumId: 10408,
            enumGroupGuid: "1",
            parentEnumValue: "3611",
            enumValue: "361128",
            enumName: "鄱阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 106,
            enumNameother: "鄱阳县",
          },
          {
            fldEnumId: 10410,
            enumGroupGuid: "1",
            parentEnumValue: "3611",
            enumValue: "361129",
            enumName: "万年县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 107,
            enumNameother: "万年县",
          },
          {
            fldEnumId: 10412,
            enumGroupGuid: "1",
            parentEnumValue: "3611",
            enumValue: "361130",
            enumName: "婺源县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 108,
            enumNameother: "婺源县",
          },
          {
            fldEnumId: 10414,
            enumGroupGuid: "1",
            parentEnumValue: "3611",
            enumValue: "361181",
            enumName: "德兴市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 109,
            enumNameother: "德兴市",
          },
        ],
      },
    ],
  },
  {
    enumValue: "37",
    enumName: "山东省",
    children: [
      {
        fldEnumId: 10417,
        enumGroupGuid: "1",
        parentEnumValue: "37",
        enumValue: "3701",
        enumName: "济南市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        enumNameother: "济南市",
        children: [
          {
            fldEnumId: 10418,
            enumGroupGuid: "1",
            parentEnumValue: "3701",
            enumValue: "370100",
            enumName: "济南市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
            enumNameother: "济南市",
          },
          {
            fldEnumId: 130314,
            enumGroupGuid: "1",
            parentEnumValue: "3701",
            enumValue: "370116",
            enumName: "莱芜区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130316,
            enumGroupGuid: "1",
            parentEnumValue: "3701",
            enumValue: "370117",
            enumName: "钢城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 10420,
            enumGroupGuid: "1",
            parentEnumValue: "3701",
            enumValue: "370102",
            enumName: "历下区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "历下区",
          },
          {
            fldEnumId: 10422,
            enumGroupGuid: "1",
            parentEnumValue: "3701",
            enumValue: "370103",
            enumName: "市中区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 2,
            enumNameother: "市中区",
          },
          {
            fldEnumId: 10424,
            enumGroupGuid: "1",
            parentEnumValue: "3701",
            enumValue: "370104",
            enumName: "槐荫区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 3,
            enumNameother: "槐荫区",
          },
          {
            fldEnumId: 10426,
            enumGroupGuid: "1",
            parentEnumValue: "3701",
            enumValue: "370105",
            enumName: "天桥区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 4,
            enumNameother: "天桥区",
          },
          {
            fldEnumId: 10428,
            enumGroupGuid: "1",
            parentEnumValue: "3701",
            enumValue: "370112",
            enumName: "历城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 5,
            enumNameother: "历城区",
          },
          {
            fldEnumId: 10430,
            enumGroupGuid: "1",
            parentEnumValue: "3701",
            enumValue: "370113",
            enumName: "长清区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 6,
            enumNameother: "长清区",
          },
          {
            fldEnumId: 10432,
            enumGroupGuid: "1",
            parentEnumValue: "3701",
            enumValue: "370124",
            enumName: "平阴县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 7,
            enumNameother: "平阴县",
          },
          {
            fldEnumId: 10434,
            enumGroupGuid: "1",
            parentEnumValue: "3701",
            enumValue: "370125",
            enumName: "济阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 8,
            enumNameother: "济阳县",
          },
          {
            fldEnumId: 10436,
            enumGroupGuid: "1",
            parentEnumValue: "3701",
            enumValue: "370126",
            enumName: "商河县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 9,
            enumNameother: "商河县",
          },
          {
            fldEnumId: 10438,
            enumGroupGuid: "1",
            parentEnumValue: "3701",
            enumValue: "370181",
            enumName: "章丘市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 10,
            enumNameother: "章丘市",
          },
        ],
      },
      {
        fldEnumId: 10440,
        enumGroupGuid: "1",
        parentEnumValue: "37",
        enumValue: "3702",
        enumName: "青岛市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 1,
        enumNameother: "青岛市",
        children: [
          {
            fldEnumId: 10441,
            enumGroupGuid: "1",
            parentEnumValue: "3702",
            enumValue: "370200",
            enumName: "青岛市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 11,
            enumNameother: "青岛市",
          },
          {
            fldEnumId: 10443,
            enumGroupGuid: "1",
            parentEnumValue: "3702",
            enumValue: "370202",
            enumName: "市南区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 12,
            enumNameother: "市南区",
          },
          {
            fldEnumId: 10445,
            enumGroupGuid: "1",
            parentEnumValue: "3702",
            enumValue: "370203",
            enumName: "市北区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 13,
            enumNameother: "市北区",
          },
          {
            fldEnumId: 10447,
            enumGroupGuid: "1",
            parentEnumValue: "3702",
            enumValue: "370205",
            enumName: "四方区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 14,
            enumNameother: "四方区",
          },
          {
            fldEnumId: 10449,
            enumGroupGuid: "1",
            parentEnumValue: "3702",
            enumValue: "370211",
            enumName: "黄岛区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 15,
            enumNameother: "黄岛区",
          },
          {
            fldEnumId: 10451,
            enumGroupGuid: "1",
            parentEnumValue: "3702",
            enumValue: "370212",
            enumName: "崂山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 16,
            enumNameother: "崂山区",
          },
          {
            fldEnumId: 10453,
            enumGroupGuid: "1",
            parentEnumValue: "3702",
            enumValue: "370213",
            enumName: "李沧区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 17,
            enumNameother: "李沧区",
          },
          {
            fldEnumId: 10455,
            enumGroupGuid: "1",
            parentEnumValue: "3702",
            enumValue: "370214",
            enumName: "城阳区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 18,
            enumNameother: "城阳区",
          },
          {
            fldEnumId: 10457,
            enumGroupGuid: "1",
            parentEnumValue: "3702",
            enumValue: "370281",
            enumName: "胶州市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 19,
            enumNameother: "胶州市",
          },
          {
            fldEnumId: 10459,
            enumGroupGuid: "1",
            parentEnumValue: "3702",
            enumValue: "370282",
            enumName: "即墨市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 20,
            enumNameother: "即墨市",
          },
          {
            fldEnumId: 10461,
            enumGroupGuid: "1",
            parentEnumValue: "3702",
            enumValue: "370283",
            enumName: "平度市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 21,
            enumNameother: "平度市",
          },
          {
            fldEnumId: 10463,
            enumGroupGuid: "1",
            parentEnumValue: "3702",
            enumValue: "370284",
            enumName: "胶南市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 22,
            enumNameother: "胶南市",
          },
          {
            fldEnumId: 10465,
            enumGroupGuid: "1",
            parentEnumValue: "3702",
            enumValue: "370285",
            enumName: "莱西市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 23,
            enumNameother: "莱西市",
          },
        ],
      },
      {
        fldEnumId: 10467,
        enumGroupGuid: "1",
        parentEnumValue: "37",
        enumValue: "3703",
        enumName: "淄博市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 2,
        enumNameother: "淄博市",
        children: [
          {
            fldEnumId: 10468,
            enumGroupGuid: "1",
            parentEnumValue: "3703",
            enumValue: "370300",
            enumName: "淄博市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 24,
            enumNameother: "淄博市",
          },
          {
            fldEnumId: 10470,
            enumGroupGuid: "1",
            parentEnumValue: "3703",
            enumValue: "370302",
            enumName: "淄川区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 25,
            enumNameother: "淄川区",
          },
          {
            fldEnumId: 10472,
            enumGroupGuid: "1",
            parentEnumValue: "3703",
            enumValue: "370303",
            enumName: "张店区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 26,
            enumNameother: "张店区",
          },
          {
            fldEnumId: 10474,
            enumGroupGuid: "1",
            parentEnumValue: "3703",
            enumValue: "370304",
            enumName: "博山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 27,
            enumNameother: "博山区",
          },
          {
            fldEnumId: 10476,
            enumGroupGuid: "1",
            parentEnumValue: "3703",
            enumValue: "370305",
            enumName: "临淄区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 28,
            enumNameother: "临淄区",
          },
          {
            fldEnumId: 10478,
            enumGroupGuid: "1",
            parentEnumValue: "3703",
            enumValue: "370306",
            enumName: "周村区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 29,
            enumNameother: "周村区",
          },
          {
            fldEnumId: 10480,
            enumGroupGuid: "1",
            parentEnumValue: "3703",
            enumValue: "370321",
            enumName: "桓台县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 30,
            enumNameother: "桓台县",
          },
          {
            fldEnumId: 10482,
            enumGroupGuid: "1",
            parentEnumValue: "3703",
            enumValue: "370322",
            enumName: "高青县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 31,
            enumNameother: "高青县",
          },
          {
            fldEnumId: 10484,
            enumGroupGuid: "1",
            parentEnumValue: "3703",
            enumValue: "370323",
            enumName: "沂源县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 32,
            enumNameother: "沂源县",
          },
        ],
      },
      {
        fldEnumId: 10486,
        enumGroupGuid: "1",
        parentEnumValue: "37",
        enumValue: "3704",
        enumName: "枣庄市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 3,
        enumNameother: "枣庄市",
        children: [
          {
            fldEnumId: 10487,
            enumGroupGuid: "1",
            parentEnumValue: "3704",
            enumValue: "370400",
            enumName: "枣庄市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 33,
            enumNameother: "枣庄市",
          },
          {
            fldEnumId: 10489,
            enumGroupGuid: "1",
            parentEnumValue: "3704",
            enumValue: "370402",
            enumName: "市中区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 34,
            enumNameother: "市中区",
          },
          {
            fldEnumId: 10491,
            enumGroupGuid: "1",
            parentEnumValue: "3704",
            enumValue: "370403",
            enumName: "薛城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 35,
            enumNameother: "薛城区",
          },
          {
            fldEnumId: 10493,
            enumGroupGuid: "1",
            parentEnumValue: "3704",
            enumValue: "370404",
            enumName: "峄城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 36,
            enumNameother: "峄城区",
          },
          {
            fldEnumId: 10495,
            enumGroupGuid: "1",
            parentEnumValue: "3704",
            enumValue: "370405",
            enumName: "台儿庄区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 37,
            enumNameother: "台儿庄区",
          },
          {
            fldEnumId: 10497,
            enumGroupGuid: "1",
            parentEnumValue: "3704",
            enumValue: "370406",
            enumName: "山亭区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 38,
            enumNameother: "山亭区",
          },
          {
            fldEnumId: 10499,
            enumGroupGuid: "1",
            parentEnumValue: "3704",
            enumValue: "370481",
            enumName: "滕州市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 39,
            enumNameother: "滕州市",
          },
        ],
      },
      {
        fldEnumId: 10501,
        enumGroupGuid: "1",
        parentEnumValue: "37",
        enumValue: "3705",
        enumName: "东营市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 4,
        enumNameother: "东营市",
        children: [
          {
            fldEnumId: 10502,
            enumGroupGuid: "1",
            parentEnumValue: "3705",
            enumValue: "370500",
            enumName: "东营市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 40,
            enumNameother: "东营市",
          },
          {
            fldEnumId: 10504,
            enumGroupGuid: "1",
            parentEnumValue: "3705",
            enumValue: "370502",
            enumName: "东营区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 41,
            enumNameother: "东营区",
          },
          {
            fldEnumId: 10506,
            enumGroupGuid: "1",
            parentEnumValue: "3705",
            enumValue: "370503",
            enumName: "河口区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 42,
            enumNameother: "河口区",
          },
          {
            fldEnumId: 10508,
            enumGroupGuid: "1",
            parentEnumValue: "3705",
            enumValue: "370521",
            enumName: "垦利县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 43,
            enumNameother: "垦利县",
          },
          {
            fldEnumId: 10510,
            enumGroupGuid: "1",
            parentEnumValue: "3705",
            enumValue: "370522",
            enumName: "利津县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 44,
            enumNameother: "利津县",
          },
          {
            fldEnumId: 10512,
            enumGroupGuid: "1",
            parentEnumValue: "3705",
            enumValue: "370523",
            enumName: "广饶县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 45,
            enumNameother: "广饶县",
          },
        ],
      },
      {
        fldEnumId: 10514,
        enumGroupGuid: "1",
        parentEnumValue: "37",
        enumValue: "3706",
        enumName: "烟台市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 5,
        enumNameother: "烟台市",
        children: [
          {
            fldEnumId: 10515,
            enumGroupGuid: "1",
            parentEnumValue: "3706",
            enumValue: "370600",
            enumName: "烟台市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 46,
            enumNameother: "烟台市",
          },
          {
            fldEnumId: 10517,
            enumGroupGuid: "1",
            parentEnumValue: "3706",
            enumValue: "370602",
            enumName: "芝罘区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 47,
            enumNameother: "芝罘区",
          },
          {
            fldEnumId: 10519,
            enumGroupGuid: "1",
            parentEnumValue: "3706",
            enumValue: "370611",
            enumName: "福山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 48,
            enumNameother: "福山区",
          },
          {
            fldEnumId: 10521,
            enumGroupGuid: "1",
            parentEnumValue: "3706",
            enumValue: "370612",
            enumName: "牟平区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 49,
            enumNameother: "牟平区",
          },
          {
            fldEnumId: 10523,
            enumGroupGuid: "1",
            parentEnumValue: "3706",
            enumValue: "370613",
            enumName: "莱山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 50,
            enumNameother: "莱山区",
          },
          {
            fldEnumId: 10525,
            enumGroupGuid: "1",
            parentEnumValue: "3706",
            enumValue: "370634",
            enumName: "长岛县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 51,
            enumNameother: "长岛县",
          },
          {
            fldEnumId: 10527,
            enumGroupGuid: "1",
            parentEnumValue: "3706",
            enumValue: "370681",
            enumName: "龙口市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 52,
            enumNameother: "龙口市",
          },
          {
            fldEnumId: 10529,
            enumGroupGuid: "1",
            parentEnumValue: "3706",
            enumValue: "370682",
            enumName: "莱阳市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 53,
            enumNameother: "莱阳市",
          },
          {
            fldEnumId: 10531,
            enumGroupGuid: "1",
            parentEnumValue: "3706",
            enumValue: "370683",
            enumName: "莱州市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 54,
            enumNameother: "莱州市",
          },
          {
            fldEnumId: 10533,
            enumGroupGuid: "1",
            parentEnumValue: "3706",
            enumValue: "370684",
            enumName: "蓬莱市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 55,
            enumNameother: "蓬莱市",
          },
          {
            fldEnumId: 10535,
            enumGroupGuid: "1",
            parentEnumValue: "3706",
            enumValue: "370685",
            enumName: "招远市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 56,
            enumNameother: "招远市",
          },
          {
            fldEnumId: 10537,
            enumGroupGuid: "1",
            parentEnumValue: "3706",
            enumValue: "370686",
            enumName: "栖霞市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 57,
            enumNameother: "栖霞市",
          },
          {
            fldEnumId: 10539,
            enumGroupGuid: "1",
            parentEnumValue: "3706",
            enumValue: "370687",
            enumName: "海阳市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 58,
            enumNameother: "海阳市",
          },
        ],
      },
      {
        fldEnumId: 10541,
        enumGroupGuid: "1",
        parentEnumValue: "37",
        enumValue: "3707",
        enumName: "潍坊市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 6,
        enumNameother: "潍坊市",
        children: [
          {
            fldEnumId: 10542,
            enumGroupGuid: "1",
            parentEnumValue: "3707",
            enumValue: "370700",
            enumName: "潍坊市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 59,
            enumNameother: "潍坊市",
          },
          {
            fldEnumId: 10544,
            enumGroupGuid: "1",
            parentEnumValue: "3707",
            enumValue: "370702",
            enumName: "潍城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 60,
            enumNameother: "潍城区",
          },
          {
            fldEnumId: 10546,
            enumGroupGuid: "1",
            parentEnumValue: "3707",
            enumValue: "370703",
            enumName: "寒亭区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 61,
            enumNameother: "寒亭区",
          },
          {
            fldEnumId: 10548,
            enumGroupGuid: "1",
            parentEnumValue: "3707",
            enumValue: "370704",
            enumName: "坊子区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 62,
            enumNameother: "坊子区",
          },
          {
            fldEnumId: 10550,
            enumGroupGuid: "1",
            parentEnumValue: "3707",
            enumValue: "370705",
            enumName: "奎文区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 63,
            enumNameother: "奎文区",
          },
          {
            fldEnumId: 10552,
            enumGroupGuid: "1",
            parentEnumValue: "3707",
            enumValue: "370724",
            enumName: "临朐县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 64,
            enumNameother: "临朐县",
          },
          {
            fldEnumId: 10554,
            enumGroupGuid: "1",
            parentEnumValue: "3707",
            enumValue: "370725",
            enumName: "昌乐县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 65,
            enumNameother: "昌乐县",
          },
          {
            fldEnumId: 10556,
            enumGroupGuid: "1",
            parentEnumValue: "3707",
            enumValue: "370781",
            enumName: "青州市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 66,
            enumNameother: "青州市",
          },
          {
            fldEnumId: 10558,
            enumGroupGuid: "1",
            parentEnumValue: "3707",
            enumValue: "370782",
            enumName: "诸城市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 67,
            enumNameother: "诸城市",
          },
          {
            fldEnumId: 10560,
            enumGroupGuid: "1",
            parentEnumValue: "3707",
            enumValue: "370783",
            enumName: "寿光市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 68,
            enumNameother: "寿光市",
          },
          {
            fldEnumId: 10562,
            enumGroupGuid: "1",
            parentEnumValue: "3707",
            enumValue: "370784",
            enumName: "安丘市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 69,
            enumNameother: "安丘市",
          },
          {
            fldEnumId: 10564,
            enumGroupGuid: "1",
            parentEnumValue: "3707",
            enumValue: "370785",
            enumName: "高密市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 70,
            enumNameother: "高密市",
          },
          {
            fldEnumId: 10566,
            enumGroupGuid: "1",
            parentEnumValue: "3707",
            enumValue: "370786",
            enumName: "昌邑市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 71,
            enumNameother: "昌邑市",
          },
        ],
      },
      {
        fldEnumId: 10568,
        enumGroupGuid: "1",
        parentEnumValue: "37",
        enumValue: "3708",
        enumName: "济宁市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 7,
        enumNameother: "济宁市",
        children: [
          {
            fldEnumId: 10569,
            enumGroupGuid: "1",
            parentEnumValue: "3708",
            enumValue: "370800",
            enumName: "济宁市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 72,
            enumNameother: "济宁市",
          },
          {
            fldEnumId: 10571,
            enumGroupGuid: "1",
            parentEnumValue: "3708",
            enumValue: "370802",
            enumName: "市中区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 73,
            enumNameother: "市中区",
          },
          {
            fldEnumId: 10573,
            enumGroupGuid: "1",
            parentEnumValue: "3708",
            enumValue: "370811",
            enumName: "任城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 74,
            enumNameother: "任城区",
          },
          {
            fldEnumId: 10575,
            enumGroupGuid: "1",
            parentEnumValue: "3708",
            enumValue: "370826",
            enumName: "微山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 75,
            enumNameother: "微山县",
          },
          {
            fldEnumId: 10577,
            enumGroupGuid: "1",
            parentEnumValue: "3708",
            enumValue: "370827",
            enumName: "鱼台县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 76,
            enumNameother: "鱼台县",
          },
          {
            fldEnumId: 10579,
            enumGroupGuid: "1",
            parentEnumValue: "3708",
            enumValue: "370828",
            enumName: "金乡县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 77,
            enumNameother: "金乡县",
          },
          {
            fldEnumId: 10581,
            enumGroupGuid: "1",
            parentEnumValue: "3708",
            enumValue: "370829",
            enumName: "嘉祥县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 78,
            enumNameother: "嘉祥县",
          },
          {
            fldEnumId: 10583,
            enumGroupGuid: "1",
            parentEnumValue: "3708",
            enumValue: "370830",
            enumName: "汶上县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 79,
            enumNameother: "汶上县",
          },
          {
            fldEnumId: 10585,
            enumGroupGuid: "1",
            parentEnumValue: "3708",
            enumValue: "370831",
            enumName: "泗水县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 80,
            enumNameother: "泗水县",
          },
          {
            fldEnumId: 10587,
            enumGroupGuid: "1",
            parentEnumValue: "3708",
            enumValue: "370832",
            enumName: "梁山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 81,
            enumNameother: "梁山县",
          },
          {
            fldEnumId: 10589,
            enumGroupGuid: "1",
            parentEnumValue: "3708",
            enumValue: "370881",
            enumName: "曲阜市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 82,
            enumNameother: "曲阜市",
          },
          {
            fldEnumId: 10591,
            enumGroupGuid: "1",
            parentEnumValue: "3708",
            enumValue: "370882",
            enumName: "兖州市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 83,
            enumNameother: "兖州市",
          },
          {
            fldEnumId: 10593,
            enumGroupGuid: "1",
            parentEnumValue: "3708",
            enumValue: "370883",
            enumName: "邹城市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 84,
            enumNameother: "邹城市",
          },
        ],
      },
      {
        fldEnumId: 10595,
        enumGroupGuid: "1",
        parentEnumValue: "37",
        enumValue: "3709",
        enumName: "泰安市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 8,
        enumNameother: "泰安市",
        children: [
          {
            fldEnumId: 10596,
            enumGroupGuid: "1",
            parentEnumValue: "3709",
            enumValue: "370900",
            enumName: "泰安市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 85,
            enumNameother: "泰安市",
          },
          {
            fldEnumId: 10598,
            enumGroupGuid: "1",
            parentEnumValue: "3709",
            enumValue: "370902",
            enumName: "泰山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 86,
            enumNameother: "泰山区",
          },
          {
            fldEnumId: 10600,
            enumGroupGuid: "1",
            parentEnumValue: "3709",
            enumValue: "370911",
            enumName: "岱岳区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 87,
            enumNameother: "岱岳区",
          },
          {
            fldEnumId: 10602,
            enumGroupGuid: "1",
            parentEnumValue: "3709",
            enumValue: "370921",
            enumName: "宁阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 88,
            enumNameother: "宁阳县",
          },
          {
            fldEnumId: 10604,
            enumGroupGuid: "1",
            parentEnumValue: "3709",
            enumValue: "370923",
            enumName: "东平县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 89,
            enumNameother: "东平县",
          },
          {
            fldEnumId: 10606,
            enumGroupGuid: "1",
            parentEnumValue: "3709",
            enumValue: "370982",
            enumName: "新泰市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 90,
            enumNameother: "新泰市",
          },
          {
            fldEnumId: 10608,
            enumGroupGuid: "1",
            parentEnumValue: "3709",
            enumValue: "370983",
            enumName: "肥城市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 91,
            enumNameother: "肥城市",
          },
        ],
      },
      {
        fldEnumId: 10610,
        enumGroupGuid: "1",
        parentEnumValue: "37",
        enumValue: "3710",
        enumName: "威海市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 9,
        enumNameother: "威海市",
        children: [
          {
            fldEnumId: 10611,
            enumGroupGuid: "1",
            parentEnumValue: "3710",
            enumValue: "371000",
            enumName: "威海市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 92,
            enumNameother: "威海市",
          },
          {
            fldEnumId: 10613,
            enumGroupGuid: "1",
            parentEnumValue: "3710",
            enumValue: "371002",
            enumName: "环翠区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 93,
            enumNameother: "环翠区",
          },
          {
            fldEnumId: 10615,
            enumGroupGuid: "1",
            parentEnumValue: "3710",
            enumValue: "371081",
            enumName: "文登市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 94,
            enumNameother: "文登市",
          },
          {
            fldEnumId: 10617,
            enumGroupGuid: "1",
            parentEnumValue: "3710",
            enumValue: "371082",
            enumName: "荣成市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 95,
            enumNameother: "荣成市",
          },
          {
            fldEnumId: 10619,
            enumGroupGuid: "1",
            parentEnumValue: "3710",
            enumValue: "371083",
            enumName: "乳山市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 96,
            enumNameother: "乳山市",
          },
        ],
      },
      {
        fldEnumId: 10621,
        enumGroupGuid: "1",
        parentEnumValue: "37",
        enumValue: "3711",
        enumName: "日照市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 10,
        enumNameother: "日照市",
        children: [
          {
            fldEnumId: 10622,
            enumGroupGuid: "1",
            parentEnumValue: "3711",
            enumValue: "371100",
            enumName: "日照市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 97,
            enumNameother: "日照市",
          },
          {
            fldEnumId: 10624,
            enumGroupGuid: "1",
            parentEnumValue: "3711",
            enumValue: "371102",
            enumName: "东港区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 98,
            enumNameother: "东港区",
          },
          {
            fldEnumId: 10626,
            enumGroupGuid: "1",
            parentEnumValue: "3711",
            enumValue: "371103",
            enumName: "岚山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 99,
            enumNameother: "岚山区",
          },
          {
            fldEnumId: 10628,
            enumGroupGuid: "1",
            parentEnumValue: "3711",
            enumValue: "371121",
            enumName: "五莲县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 100,
            enumNameother: "五莲县",
          },
          {
            fldEnumId: 10630,
            enumGroupGuid: "1",
            parentEnumValue: "3711",
            enumValue: "371122",
            enumName: "莒县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 101,
            enumNameother: "莒县",
          },
        ],
      },
      {
        fldEnumId: 10632,
        enumGroupGuid: "1",
        parentEnumValue: "37",
        enumValue: "3712",
        enumName: "莱芜市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 11,
        enumNameother: "莱芜市",
        children: [
          {
            fldEnumId: 10633,
            enumGroupGuid: "1",
            parentEnumValue: "3712",
            enumValue: "371200",
            enumName: "莱芜市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 102,
            enumNameother: "莱芜市",
          },
          {
            fldEnumId: 10635,
            enumGroupGuid: "1",
            parentEnumValue: "3712",
            enumValue: "371202",
            enumName: "莱城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 103,
            enumNameother: "莱城区",
          },
          {
            fldEnumId: 10637,
            enumGroupGuid: "1",
            parentEnumValue: "3712",
            enumValue: "371203",
            enumName: "钢城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 104,
            enumNameother: "钢城区",
          },
        ],
      },
      {
        fldEnumId: 10639,
        enumGroupGuid: "1",
        parentEnumValue: "37",
        enumValue: "3713",
        enumName: "临沂市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 12,
        enumNameother: "临沂市",
        children: [
          {
            fldEnumId: 10640,
            enumGroupGuid: "1",
            parentEnumValue: "3713",
            enumValue: "371300",
            enumName: "临沂市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 105,
            enumNameother: "临沂市",
          },
          {
            fldEnumId: 10642,
            enumGroupGuid: "1",
            parentEnumValue: "3713",
            enumValue: "371302",
            enumName: "兰山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 106,
            enumNameother: "兰山区",
          },
          {
            fldEnumId: 10644,
            enumGroupGuid: "1",
            parentEnumValue: "3713",
            enumValue: "371311",
            enumName: "罗庄区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 107,
            enumNameother: "罗庄区",
          },
          {
            fldEnumId: 10646,
            enumGroupGuid: "1",
            parentEnumValue: "3713",
            enumValue: "371312",
            enumName: "河东区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 108,
            enumNameother: "河东区",
          },
          {
            fldEnumId: 10648,
            enumGroupGuid: "1",
            parentEnumValue: "3713",
            enumValue: "371321",
            enumName: "沂南县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 109,
            enumNameother: "沂南县",
          },
          {
            fldEnumId: 10650,
            enumGroupGuid: "1",
            parentEnumValue: "3713",
            enumValue: "371322",
            enumName: "郯城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 110,
            enumNameother: "郯城县",
          },
          {
            fldEnumId: 10652,
            enumGroupGuid: "1",
            parentEnumValue: "3713",
            enumValue: "371323",
            enumName: "沂水县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 111,
            enumNameother: "沂水县",
          },
          {
            fldEnumId: 10654,
            enumGroupGuid: "1",
            parentEnumValue: "3713",
            enumValue: "371324",
            enumName: "苍山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 112,
            enumNameother: "苍山县",
          },
          {
            fldEnumId: 10656,
            enumGroupGuid: "1",
            parentEnumValue: "3713",
            enumValue: "371325",
            enumName: "费县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 113,
            enumNameother: "费县",
          },
          {
            fldEnumId: 10658,
            enumGroupGuid: "1",
            parentEnumValue: "3713",
            enumValue: "371326",
            enumName: "平邑县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 114,
            enumNameother: "平邑县",
          },
          {
            fldEnumId: 10660,
            enumGroupGuid: "1",
            parentEnumValue: "3713",
            enumValue: "371327",
            enumName: "莒南县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 115,
            enumNameother: "莒南县",
          },
          {
            fldEnumId: 10662,
            enumGroupGuid: "1",
            parentEnumValue: "3713",
            enumValue: "371328",
            enumName: "蒙阴县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 116,
            enumNameother: "蒙阴县",
          },
          {
            fldEnumId: 10664,
            enumGroupGuid: "1",
            parentEnumValue: "3713",
            enumValue: "371329",
            enumName: "临沭县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 117,
            enumNameother: "临沭县",
          },
        ],
      },
      {
        fldEnumId: 10666,
        enumGroupGuid: "1",
        parentEnumValue: "37",
        enumValue: "3714",
        enumName: "德州市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 13,
        enumNameother: "德州市",
        children: [
          {
            fldEnumId: 10667,
            enumGroupGuid: "1",
            parentEnumValue: "3714",
            enumValue: "371400",
            enumName: "德州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 118,
            enumNameother: "德州市",
          },
          {
            fldEnumId: 10669,
            enumGroupGuid: "1",
            parentEnumValue: "3714",
            enumValue: "371402",
            enumName: "德城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 119,
            enumNameother: "德城区",
          },
          {
            fldEnumId: 10671,
            enumGroupGuid: "1",
            parentEnumValue: "3714",
            enumValue: "371421",
            enumName: "陵县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 120,
            enumNameother: "陵县",
          },
          {
            fldEnumId: 10673,
            enumGroupGuid: "1",
            parentEnumValue: "3714",
            enumValue: "371422",
            enumName: "宁津县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 121,
            enumNameother: "宁津县",
          },
          {
            fldEnumId: 10675,
            enumGroupGuid: "1",
            parentEnumValue: "3714",
            enumValue: "371423",
            enumName: "庆云县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 122,
            enumNameother: "庆云县",
          },
          {
            fldEnumId: 10677,
            enumGroupGuid: "1",
            parentEnumValue: "3714",
            enumValue: "371424",
            enumName: "临邑县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 123,
            enumNameother: "临邑县",
          },
          {
            fldEnumId: 10679,
            enumGroupGuid: "1",
            parentEnumValue: "3714",
            enumValue: "371425",
            enumName: "齐河县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 124,
            enumNameother: "齐河县",
          },
          {
            fldEnumId: 10681,
            enumGroupGuid: "1",
            parentEnumValue: "3714",
            enumValue: "371426",
            enumName: "平原县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 125,
            enumNameother: "平原县",
          },
          {
            fldEnumId: 10683,
            enumGroupGuid: "1",
            parentEnumValue: "3714",
            enumValue: "371427",
            enumName: "夏津县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 126,
            enumNameother: "夏津县",
          },
          {
            fldEnumId: 10685,
            enumGroupGuid: "1",
            parentEnumValue: "3714",
            enumValue: "371428",
            enumName: "武城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 127,
            enumNameother: "武城县",
          },
          {
            fldEnumId: 10687,
            enumGroupGuid: "1",
            parentEnumValue: "3714",
            enumValue: "371481",
            enumName: "乐陵市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 128,
            enumNameother: "乐陵市",
          },
          {
            fldEnumId: 10689,
            enumGroupGuid: "1",
            parentEnumValue: "3714",
            enumValue: "371482",
            enumName: "禹城市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 129,
            enumNameother: "禹城市",
          },
        ],
      },
      {
        fldEnumId: 10691,
        enumGroupGuid: "1",
        parentEnumValue: "37",
        enumValue: "3715",
        enumName: "聊城市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 14,
        enumNameother: "聊城市",
        children: [
          {
            fldEnumId: 10692,
            enumGroupGuid: "1",
            parentEnumValue: "3715",
            enumValue: "371500",
            enumName: "聊城市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 130,
            enumNameother: "聊城市",
          },
          {
            fldEnumId: 10694,
            enumGroupGuid: "1",
            parentEnumValue: "3715",
            enumValue: "371502",
            enumName: "东昌府区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 131,
            enumNameother: "东昌府区",
          },
          {
            fldEnumId: 10696,
            enumGroupGuid: "1",
            parentEnumValue: "3715",
            enumValue: "371521",
            enumName: "阳谷县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 132,
            enumNameother: "阳谷县",
          },
          {
            fldEnumId: 10698,
            enumGroupGuid: "1",
            parentEnumValue: "3715",
            enumValue: "371522",
            enumName: "莘县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 133,
            enumNameother: "莘县",
          },
          {
            fldEnumId: 10700,
            enumGroupGuid: "1",
            parentEnumValue: "3715",
            enumValue: "371523",
            enumName: "茌平县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 134,
            enumNameother: "茌平县",
          },
          {
            fldEnumId: 10702,
            enumGroupGuid: "1",
            parentEnumValue: "3715",
            enumValue: "371524",
            enumName: "东阿县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 135,
            enumNameother: "东阿县",
          },
          {
            fldEnumId: 10704,
            enumGroupGuid: "1",
            parentEnumValue: "3715",
            enumValue: "371525",
            enumName: "冠县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 136,
            enumNameother: "冠县",
          },
          {
            fldEnumId: 10706,
            enumGroupGuid: "1",
            parentEnumValue: "3715",
            enumValue: "371526",
            enumName: "高唐县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 137,
            enumNameother: "高唐县",
          },
          {
            fldEnumId: 10708,
            enumGroupGuid: "1",
            parentEnumValue: "3715",
            enumValue: "371581",
            enumName: "临清市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 138,
            enumNameother: "临清市",
          },
        ],
      },
      {
        fldEnumId: 10710,
        enumGroupGuid: "1",
        parentEnumValue: "37",
        enumValue: "3716",
        enumName: "滨州市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 15,
        enumNameother: "滨州市",
        children: [
          {
            fldEnumId: 10711,
            enumGroupGuid: "1",
            parentEnumValue: "3716",
            enumValue: "371600",
            enumName: "滨州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 139,
            enumNameother: "滨州市",
          },
          {
            fldEnumId: 10713,
            enumGroupGuid: "1",
            parentEnumValue: "3716",
            enumValue: "371602",
            enumName: "滨城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 140,
            enumNameother: "滨城区",
          },
          {
            fldEnumId: 10715,
            enumGroupGuid: "1",
            parentEnumValue: "3716",
            enumValue: "371621",
            enumName: "惠民县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 141,
            enumNameother: "惠民县",
          },
          {
            fldEnumId: 10717,
            enumGroupGuid: "1",
            parentEnumValue: "3716",
            enumValue: "371622",
            enumName: "阳信县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 142,
            enumNameother: "阳信县",
          },
          {
            fldEnumId: 10719,
            enumGroupGuid: "1",
            parentEnumValue: "3716",
            enumValue: "371623",
            enumName: "无棣县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 143,
            enumNameother: "无棣县",
          },
          {
            fldEnumId: 10721,
            enumGroupGuid: "1",
            parentEnumValue: "3716",
            enumValue: "371624",
            enumName: "沾化县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 144,
            enumNameother: "沾化县",
          },
          {
            fldEnumId: 10723,
            enumGroupGuid: "1",
            parentEnumValue: "3716",
            enumValue: "371625",
            enumName: "博兴县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 145,
            enumNameother: "博兴县",
          },
          {
            fldEnumId: 10725,
            enumGroupGuid: "1",
            parentEnumValue: "3716",
            enumValue: "371626",
            enumName: "邹平县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 146,
            enumNameother: "邹平县",
          },
        ],
      },
      {
        fldEnumId: 10727,
        enumGroupGuid: "1",
        parentEnumValue: "37",
        enumValue: "3717",
        enumName: "菏泽市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 16,
        enumNameother: "菏泽市",
        children: [
          {
            fldEnumId: 10728,
            enumGroupGuid: "1",
            parentEnumValue: "3717",
            enumValue: "371700",
            enumName: "菏泽市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 147,
            enumNameother: "菏泽市",
          },
          {
            fldEnumId: 10730,
            enumGroupGuid: "1",
            parentEnumValue: "3717",
            enumValue: "371702",
            enumName: "牡丹区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 148,
            enumNameother: "牡丹区",
          },
          {
            fldEnumId: 10732,
            enumGroupGuid: "1",
            parentEnumValue: "3717",
            enumValue: "371721",
            enumName: "曹县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 149,
            enumNameother: "曹县",
          },
          {
            fldEnumId: 10734,
            enumGroupGuid: "1",
            parentEnumValue: "3717",
            enumValue: "371722",
            enumName: "单县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 150,
            enumNameother: "单县",
          },
          {
            fldEnumId: 10736,
            enumGroupGuid: "1",
            parentEnumValue: "3717",
            enumValue: "371723",
            enumName: "成武县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 151,
            enumNameother: "成武县",
          },
          {
            fldEnumId: 10738,
            enumGroupGuid: "1",
            parentEnumValue: "3717",
            enumValue: "371724",
            enumName: "巨野县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 152,
            enumNameother: "巨野县",
          },
          {
            fldEnumId: 10740,
            enumGroupGuid: "1",
            parentEnumValue: "3717",
            enumValue: "371725",
            enumName: "郓城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 153,
            enumNameother: "郓城县",
          },
          {
            fldEnumId: 10742,
            enumGroupGuid: "1",
            parentEnumValue: "3717",
            enumValue: "371726",
            enumName: "鄄城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 154,
            enumNameother: "鄄城县",
          },
          {
            fldEnumId: 10744,
            enumGroupGuid: "1",
            parentEnumValue: "3717",
            enumValue: "371727",
            enumName: "定陶县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 155,
            enumNameother: "定陶县",
          },
          {
            fldEnumId: 10746,
            enumGroupGuid: "1",
            parentEnumValue: "3717",
            enumValue: "371728",
            enumName: "东明县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 156,
            enumNameother: "东明县",
          },
        ],
      },
    ],
  },
  {
    enumValue: "41",
    enumName: "河南省",
    children: [
      {
        fldEnumId: 10749,
        enumGroupGuid: "1",
        parentEnumValue: "41",
        enumValue: "4101",
        enumName: "郑州市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        enumNameother: "郑州市",
        children: [
          {
            fldEnumId: 10750,
            enumGroupGuid: "1",
            parentEnumValue: "4101",
            enumValue: "410100",
            enumName: "郑州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
            enumNameother: "郑州市",
          },
          {
            fldEnumId: 10752,
            enumGroupGuid: "1",
            parentEnumValue: "4101",
            enumValue: "410102",
            enumName: "中原区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "中原区",
          },
          {
            fldEnumId: 10754,
            enumGroupGuid: "1",
            parentEnumValue: "4101",
            enumValue: "410103",
            enumName: "二七区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 2,
            enumNameother: "二七区",
          },
          {
            fldEnumId: 10756,
            enumGroupGuid: "1",
            parentEnumValue: "4101",
            enumValue: "410104",
            enumName: "管城回族区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 3,
            enumNameother: "管城回族区",
          },
          {
            fldEnumId: 10758,
            enumGroupGuid: "1",
            parentEnumValue: "4101",
            enumValue: "410105",
            enumName: "金水区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 4,
            enumNameother: "金水区",
          },
          {
            fldEnumId: 10760,
            enumGroupGuid: "1",
            parentEnumValue: "4101",
            enumValue: "410106",
            enumName: "上街区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 5,
            enumNameother: "上街区",
          },
          {
            fldEnumId: 10762,
            enumGroupGuid: "1",
            parentEnumValue: "4101",
            enumValue: "410108",
            enumName: "惠济区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 6,
            enumNameother: "惠济区",
          },
          {
            fldEnumId: 10764,
            enumGroupGuid: "1",
            parentEnumValue: "4101",
            enumValue: "410122",
            enumName: "中牟县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 7,
            enumNameother: "中牟县",
          },
          {
            fldEnumId: 10766,
            enumGroupGuid: "1",
            parentEnumValue: "4101",
            enumValue: "410181",
            enumName: "巩义市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 8,
            enumNameother: "巩义市",
          },
          {
            fldEnumId: 10768,
            enumGroupGuid: "1",
            parentEnumValue: "4101",
            enumValue: "410182",
            enumName: "荥阳市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 9,
            enumNameother: "荥阳市",
          },
          {
            fldEnumId: 10770,
            enumGroupGuid: "1",
            parentEnumValue: "4101",
            enumValue: "410183",
            enumName: "新密市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 10,
            enumNameother: "新密市",
          },
          {
            fldEnumId: 10772,
            enumGroupGuid: "1",
            parentEnumValue: "4101",
            enumValue: "410184",
            enumName: "新郑市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 11,
            enumNameother: "新郑市",
          },
          {
            fldEnumId: 10774,
            enumGroupGuid: "1",
            parentEnumValue: "4101",
            enumValue: "410185",
            enumName: "登封市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 12,
            enumNameother: "登封市",
          },
        ],
      },
      {
        fldEnumId: 130318,
        enumGroupGuid: "1",
        parentEnumValue: "41",
        enumValue: "4190",
        enumName: "省直辖县级行政区划",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        children: [
          {
            fldEnumId: 130195,
            enumGroupGuid: "1",
            parentEnumValue: "4190",
            enumValue: "410881",
            enumName: "济源市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130319,
            enumGroupGuid: "1",
            parentEnumValue: "4190",
            enumValue: "419000",
            enumName: "省直辖县级行政单位本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
        ],
      },
      {
        fldEnumId: 10776,
        enumGroupGuid: "1",
        parentEnumValue: "41",
        enumValue: "4102",
        enumName: "开封市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 1,
        enumNameother: "开封市",
        children: [
          {
            fldEnumId: 10777,
            enumGroupGuid: "1",
            parentEnumValue: "4102",
            enumValue: "410200",
            enumName: "开封市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 13,
            enumNameother: "开封市",
          },
          {
            fldEnumId: 10779,
            enumGroupGuid: "1",
            parentEnumValue: "4102",
            enumValue: "410202",
            enumName: "龙亭区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 14,
            enumNameother: "龙亭区",
          },
          {
            fldEnumId: 10781,
            enumGroupGuid: "1",
            parentEnumValue: "4102",
            enumValue: "410203",
            enumName: "顺河回族区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 15,
            enumNameother: "顺河回族区",
          },
          {
            fldEnumId: 10783,
            enumGroupGuid: "1",
            parentEnumValue: "4102",
            enumValue: "410204",
            enumName: "鼓楼区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 16,
            enumNameother: "鼓楼区",
          },
          {
            fldEnumId: 10785,
            enumGroupGuid: "1",
            parentEnumValue: "4102",
            enumValue: "410205",
            enumName: "禹王台区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 17,
            enumNameother: "禹王台区",
          },
          {
            fldEnumId: 10787,
            enumGroupGuid: "1",
            parentEnumValue: "4102",
            enumValue: "410211",
            enumName: "金明区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 18,
            enumNameother: "金明区",
          },
          {
            fldEnumId: 10789,
            enumGroupGuid: "1",
            parentEnumValue: "4102",
            enumValue: "410221",
            enumName: "杞县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 19,
            enumNameother: "杞县",
          },
          {
            fldEnumId: 10791,
            enumGroupGuid: "1",
            parentEnumValue: "4102",
            enumValue: "410222",
            enumName: "通许县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 20,
            enumNameother: "通许县",
          },
          {
            fldEnumId: 10793,
            enumGroupGuid: "1",
            parentEnumValue: "4102",
            enumValue: "410223",
            enumName: "尉氏县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 21,
            enumNameother: "尉氏县",
          },
          {
            fldEnumId: 10795,
            enumGroupGuid: "1",
            parentEnumValue: "4102",
            enumValue: "410224",
            enumName: "开封县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 22,
            enumNameother: "开封县",
          },
          {
            fldEnumId: 10797,
            enumGroupGuid: "1",
            parentEnumValue: "4102",
            enumValue: "410225",
            enumName: "兰考县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 23,
            enumNameother: "兰考县",
          },
        ],
      },
      {
        fldEnumId: 10799,
        enumGroupGuid: "1",
        parentEnumValue: "41",
        enumValue: "4103",
        enumName: "洛阳市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 2,
        enumNameother: "洛阳市",
        children: [
          {
            fldEnumId: 10800,
            enumGroupGuid: "1",
            parentEnumValue: "4103",
            enumValue: "410300",
            enumName: "洛阳市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 24,
            enumNameother: "洛阳市",
          },
          {
            fldEnumId: 10802,
            enumGroupGuid: "1",
            parentEnumValue: "4103",
            enumValue: "410302",
            enumName: "老城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 25,
            enumNameother: "老城区",
          },
          {
            fldEnumId: 10804,
            enumGroupGuid: "1",
            parentEnumValue: "4103",
            enumValue: "410303",
            enumName: "西工区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 26,
            enumNameother: "西工区",
          },
          {
            fldEnumId: 10806,
            enumGroupGuid: "1",
            parentEnumValue: "4103",
            enumValue: "410304",
            enumName: "瀍河回族区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 27,
            enumNameother: "瀍河回族区",
          },
          {
            fldEnumId: 10808,
            enumGroupGuid: "1",
            parentEnumValue: "4103",
            enumValue: "410305",
            enumName: "涧西区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 28,
            enumNameother: "涧西区",
          },
          {
            fldEnumId: 10810,
            enumGroupGuid: "1",
            parentEnumValue: "4103",
            enumValue: "410306",
            enumName: "吉利区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 29,
            enumNameother: "吉利区",
          },
          {
            fldEnumId: 10812,
            enumGroupGuid: "1",
            parentEnumValue: "4103",
            enumValue: "410311",
            enumName: "洛龙区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 30,
            enumNameother: "洛龙区",
          },
          {
            fldEnumId: 10814,
            enumGroupGuid: "1",
            parentEnumValue: "4103",
            enumValue: "410322",
            enumName: "孟津县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 31,
            enumNameother: "孟津县",
          },
          {
            fldEnumId: 10816,
            enumGroupGuid: "1",
            parentEnumValue: "4103",
            enumValue: "410323",
            enumName: "新安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 32,
            enumNameother: "新安县",
          },
          {
            fldEnumId: 10818,
            enumGroupGuid: "1",
            parentEnumValue: "4103",
            enumValue: "410324",
            enumName: "栾川县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 33,
            enumNameother: "栾川县",
          },
          {
            fldEnumId: 10820,
            enumGroupGuid: "1",
            parentEnumValue: "4103",
            enumValue: "410325",
            enumName: "嵩县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 34,
            enumNameother: "嵩县",
          },
          {
            fldEnumId: 10822,
            enumGroupGuid: "1",
            parentEnumValue: "4103",
            enumValue: "410326",
            enumName: "汝阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 35,
            enumNameother: "汝阳县",
          },
          {
            fldEnumId: 10824,
            enumGroupGuid: "1",
            parentEnumValue: "4103",
            enumValue: "410327",
            enumName: "宜阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 36,
            enumNameother: "宜阳县",
          },
          {
            fldEnumId: 10826,
            enumGroupGuid: "1",
            parentEnumValue: "4103",
            enumValue: "410328",
            enumName: "洛宁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 37,
            enumNameother: "洛宁县",
          },
          {
            fldEnumId: 10828,
            enumGroupGuid: "1",
            parentEnumValue: "4103",
            enumValue: "410329",
            enumName: "伊川县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 38,
            enumNameother: "伊川县",
          },
          {
            fldEnumId: 10830,
            enumGroupGuid: "1",
            parentEnumValue: "4103",
            enumValue: "410381",
            enumName: "偃师市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 39,
            enumNameother: "偃师市",
          },
        ],
      },
      {
        fldEnumId: 10832,
        enumGroupGuid: "1",
        parentEnumValue: "41",
        enumValue: "4104",
        enumName: "平顶山市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 3,
        enumNameother: "平顶山市",
        children: [
          {
            fldEnumId: 10833,
            enumGroupGuid: "1",
            parentEnumValue: "4104",
            enumValue: "410400",
            enumName: "平顶山市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 40,
            enumNameother: "平顶山市",
          },
          {
            fldEnumId: 10835,
            enumGroupGuid: "1",
            parentEnumValue: "4104",
            enumValue: "410402",
            enumName: "新华区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 41,
            enumNameother: "新华区",
          },
          {
            fldEnumId: 10837,
            enumGroupGuid: "1",
            parentEnumValue: "4104",
            enumValue: "410403",
            enumName: "卫东区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 42,
            enumNameother: "卫东区",
          },
          {
            fldEnumId: 10839,
            enumGroupGuid: "1",
            parentEnumValue: "4104",
            enumValue: "410404",
            enumName: "石龙区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 43,
            enumNameother: "石龙区",
          },
          {
            fldEnumId: 10841,
            enumGroupGuid: "1",
            parentEnumValue: "4104",
            enumValue: "410411",
            enumName: "湛河区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 44,
            enumNameother: "湛河区",
          },
          {
            fldEnumId: 10843,
            enumGroupGuid: "1",
            parentEnumValue: "4104",
            enumValue: "410421",
            enumName: "宝丰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 45,
            enumNameother: "宝丰县",
          },
          {
            fldEnumId: 10845,
            enumGroupGuid: "1",
            parentEnumValue: "4104",
            enumValue: "410422",
            enumName: "叶县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 46,
            enumNameother: "叶县",
          },
          {
            fldEnumId: 10847,
            enumGroupGuid: "1",
            parentEnumValue: "4104",
            enumValue: "410423",
            enumName: "鲁山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 47,
            enumNameother: "鲁山县",
          },
          {
            fldEnumId: 10849,
            enumGroupGuid: "1",
            parentEnumValue: "4104",
            enumValue: "410425",
            enumName: "郏县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 48,
            enumNameother: "郏县",
          },
          {
            fldEnumId: 10851,
            enumGroupGuid: "1",
            parentEnumValue: "4104",
            enumValue: "410481",
            enumName: "舞钢市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 49,
            enumNameother: "舞钢市",
          },
          {
            fldEnumId: 10853,
            enumGroupGuid: "1",
            parentEnumValue: "4104",
            enumValue: "410482",
            enumName: "汝州市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 50,
            enumNameother: "汝州市",
          },
        ],
      },
      {
        fldEnumId: 10855,
        enumGroupGuid: "1",
        parentEnumValue: "41",
        enumValue: "4105",
        enumName: "安阳市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 4,
        enumNameother: "安阳市",
        children: [
          {
            fldEnumId: 10856,
            enumGroupGuid: "1",
            parentEnumValue: "4105",
            enumValue: "410500",
            enumName: "安阳市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 51,
            enumNameother: "安阳市",
          },
          {
            fldEnumId: 10858,
            enumGroupGuid: "1",
            parentEnumValue: "4105",
            enumValue: "410502",
            enumName: "文峰区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 52,
            enumNameother: "文峰区",
          },
          {
            fldEnumId: 10860,
            enumGroupGuid: "1",
            parentEnumValue: "4105",
            enumValue: "410503",
            enumName: "北关区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 53,
            enumNameother: "北关区",
          },
          {
            fldEnumId: 10862,
            enumGroupGuid: "1",
            parentEnumValue: "4105",
            enumValue: "410505",
            enumName: "殷都区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 54,
            enumNameother: "殷都区",
          },
          {
            fldEnumId: 10864,
            enumGroupGuid: "1",
            parentEnumValue: "4105",
            enumValue: "410506",
            enumName: "龙安区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 55,
            enumNameother: "龙安区",
          },
          {
            fldEnumId: 10866,
            enumGroupGuid: "1",
            parentEnumValue: "4105",
            enumValue: "410522",
            enumName: "安阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 56,
            enumNameother: "安阳县",
          },
          {
            fldEnumId: 10868,
            enumGroupGuid: "1",
            parentEnumValue: "4105",
            enumValue: "410523",
            enumName: "汤阴县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 57,
            enumNameother: "汤阴县",
          },
          {
            fldEnumId: 10870,
            enumGroupGuid: "1",
            parentEnumValue: "4105",
            enumValue: "410526",
            enumName: "滑县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 58,
            enumNameother: "滑县",
          },
          {
            fldEnumId: 10872,
            enumGroupGuid: "1",
            parentEnumValue: "4105",
            enumValue: "410527",
            enumName: "内黄县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 59,
            enumNameother: "内黄县",
          },
          {
            fldEnumId: 10874,
            enumGroupGuid: "1",
            parentEnumValue: "4105",
            enumValue: "410581",
            enumName: "林州市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 60,
            enumNameother: "林州市",
          },
        ],
      },
      {
        fldEnumId: 10876,
        enumGroupGuid: "1",
        parentEnumValue: "41",
        enumValue: "4106",
        enumName: "鹤壁市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 5,
        enumNameother: "鹤壁市",
        children: [
          {
            fldEnumId: 10877,
            enumGroupGuid: "1",
            parentEnumValue: "4106",
            enumValue: "410600",
            enumName: "鹤壁市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 61,
            enumNameother: "鹤壁市",
          },
          {
            fldEnumId: 10879,
            enumGroupGuid: "1",
            parentEnumValue: "4106",
            enumValue: "410602",
            enumName: "鹤山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 62,
            enumNameother: "鹤山区",
          },
          {
            fldEnumId: 10881,
            enumGroupGuid: "1",
            parentEnumValue: "4106",
            enumValue: "410603",
            enumName: "山城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 63,
            enumNameother: "山城区",
          },
          {
            fldEnumId: 10883,
            enumGroupGuid: "1",
            parentEnumValue: "4106",
            enumValue: "410611",
            enumName: "淇滨区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 64,
            enumNameother: "淇滨区",
          },
          {
            fldEnumId: 10885,
            enumGroupGuid: "1",
            parentEnumValue: "4106",
            enumValue: "410621",
            enumName: "浚县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 65,
            enumNameother: "浚县",
          },
          {
            fldEnumId: 10887,
            enumGroupGuid: "1",
            parentEnumValue: "4106",
            enumValue: "410622",
            enumName: "淇县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 66,
            enumNameother: "淇县",
          },
        ],
      },
      {
        fldEnumId: 10889,
        enumGroupGuid: "1",
        parentEnumValue: "41",
        enumValue: "4107",
        enumName: "新乡市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 6,
        enumNameother: "新乡市",
        children: [
          {
            fldEnumId: 10890,
            enumGroupGuid: "1",
            parentEnumValue: "4107",
            enumValue: "410700",
            enumName: "新乡市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 67,
            enumNameother: "新乡市",
          },
          {
            fldEnumId: 10892,
            enumGroupGuid: "1",
            parentEnumValue: "4107",
            enumValue: "410702",
            enumName: "红旗区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 68,
            enumNameother: "红旗区",
          },
          {
            fldEnumId: 10894,
            enumGroupGuid: "1",
            parentEnumValue: "4107",
            enumValue: "410703",
            enumName: "卫滨区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 69,
            enumNameother: "卫滨区",
          },
          {
            fldEnumId: 10896,
            enumGroupGuid: "1",
            parentEnumValue: "4107",
            enumValue: "410704",
            enumName: "凤泉区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 70,
            enumNameother: "凤泉区",
          },
          {
            fldEnumId: 10898,
            enumGroupGuid: "1",
            parentEnumValue: "4107",
            enumValue: "410711",
            enumName: "牧野区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 71,
            enumNameother: "牧野区",
          },
          {
            fldEnumId: 10900,
            enumGroupGuid: "1",
            parentEnumValue: "4107",
            enumValue: "410721",
            enumName: "新乡县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 72,
            enumNameother: "新乡县",
          },
          {
            fldEnumId: 10902,
            enumGroupGuid: "1",
            parentEnumValue: "4107",
            enumValue: "410724",
            enumName: "获嘉县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 73,
            enumNameother: "获嘉县",
          },
          {
            fldEnumId: 10904,
            enumGroupGuid: "1",
            parentEnumValue: "4107",
            enumValue: "410725",
            enumName: "原阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 74,
            enumNameother: "原阳县",
          },
          {
            fldEnumId: 10906,
            enumGroupGuid: "1",
            parentEnumValue: "4107",
            enumValue: "410726",
            enumName: "延津县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 75,
            enumNameother: "延津县",
          },
          {
            fldEnumId: 10908,
            enumGroupGuid: "1",
            parentEnumValue: "4107",
            enumValue: "410727",
            enumName: "封丘县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 76,
            enumNameother: "封丘县",
          },
          {
            fldEnumId: 10910,
            enumGroupGuid: "1",
            parentEnumValue: "4107",
            enumValue: "410728",
            enumName: "长垣县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 77,
            enumNameother: "长垣县",
          },
          {
            fldEnumId: 10912,
            enumGroupGuid: "1",
            parentEnumValue: "4107",
            enumValue: "410781",
            enumName: "卫辉市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 78,
            enumNameother: "卫辉市",
          },
          {
            fldEnumId: 10914,
            enumGroupGuid: "1",
            parentEnumValue: "4107",
            enumValue: "410782",
            enumName: "辉县市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 79,
            enumNameother: "辉县市",
          },
        ],
      },
      {
        fldEnumId: 10916,
        enumGroupGuid: "1",
        parentEnumValue: "41",
        enumValue: "4108",
        enumName: "焦作市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 7,
        enumNameother: "焦作市",
        children: [
          {
            fldEnumId: 10917,
            enumGroupGuid: "1",
            parentEnumValue: "4108",
            enumValue: "410800",
            enumName: "焦作市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 80,
            enumNameother: "焦作市",
          },
          {
            fldEnumId: 10919,
            enumGroupGuid: "1",
            parentEnumValue: "4108",
            enumValue: "410802",
            enumName: "解放区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 81,
            enumNameother: "解放区",
          },
          {
            fldEnumId: 10921,
            enumGroupGuid: "1",
            parentEnumValue: "4108",
            enumValue: "410803",
            enumName: "中站区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 82,
            enumNameother: "中站区",
          },
          {
            fldEnumId: 10923,
            enumGroupGuid: "1",
            parentEnumValue: "4108",
            enumValue: "410804",
            enumName: "马村区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 83,
            enumNameother: "马村区",
          },
          {
            fldEnumId: 10925,
            enumGroupGuid: "1",
            parentEnumValue: "4108",
            enumValue: "410811",
            enumName: "山阳区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 84,
            enumNameother: "山阳区",
          },
          {
            fldEnumId: 10927,
            enumGroupGuid: "1",
            parentEnumValue: "4108",
            enumValue: "410821",
            enumName: "修武县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 85,
            enumNameother: "修武县",
          },
          {
            fldEnumId: 10929,
            enumGroupGuid: "1",
            parentEnumValue: "4108",
            enumValue: "410822",
            enumName: "博爱县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 86,
            enumNameother: "博爱县",
          },
          {
            fldEnumId: 10931,
            enumGroupGuid: "1",
            parentEnumValue: "4108",
            enumValue: "410823",
            enumName: "武陟县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 87,
            enumNameother: "武陟县",
          },
          {
            fldEnumId: 10933,
            enumGroupGuid: "1",
            parentEnumValue: "4108",
            enumValue: "410825",
            enumName: "温县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 88,
            enumNameother: "温县",
          },
          {
            fldEnumId: 10937,
            enumGroupGuid: "1",
            parentEnumValue: "4108",
            enumValue: "410882",
            enumName: "沁阳市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 90,
            enumNameother: "沁阳市",
          },
          {
            fldEnumId: 10939,
            enumGroupGuid: "1",
            parentEnumValue: "4108",
            enumValue: "410883",
            enumName: "孟州市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 91,
            enumNameother: "孟州市",
          },
        ],
      },
      {
        fldEnumId: 10941,
        enumGroupGuid: "1",
        parentEnumValue: "41",
        enumValue: "4109",
        enumName: "濮阳市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 8,
        enumNameother: "濮阳市",
        children: [
          {
            fldEnumId: 10942,
            enumGroupGuid: "1",
            parentEnumValue: "4109",
            enumValue: "410900",
            enumName: "濮阳市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 92,
            enumNameother: "濮阳市",
          },
          {
            fldEnumId: 10944,
            enumGroupGuid: "1",
            parentEnumValue: "4109",
            enumValue: "410902",
            enumName: "华龙区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 93,
            enumNameother: "华龙区",
          },
          {
            fldEnumId: 10946,
            enumGroupGuid: "1",
            parentEnumValue: "4109",
            enumValue: "410922",
            enumName: "清丰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 94,
            enumNameother: "清丰县",
          },
          {
            fldEnumId: 10948,
            enumGroupGuid: "1",
            parentEnumValue: "4109",
            enumValue: "410923",
            enumName: "南乐县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 95,
            enumNameother: "南乐县",
          },
          {
            fldEnumId: 10950,
            enumGroupGuid: "1",
            parentEnumValue: "4109",
            enumValue: "410926",
            enumName: "范县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 96,
            enumNameother: "范县",
          },
          {
            fldEnumId: 10952,
            enumGroupGuid: "1",
            parentEnumValue: "4109",
            enumValue: "410927",
            enumName: "台前县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 97,
            enumNameother: "台前县",
          },
          {
            fldEnumId: 10954,
            enumGroupGuid: "1",
            parentEnumValue: "4109",
            enumValue: "410928",
            enumName: "濮阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 98,
            enumNameother: "濮阳县",
          },
        ],
      },
      {
        fldEnumId: 10956,
        enumGroupGuid: "1",
        parentEnumValue: "41",
        enumValue: "4110",
        enumName: "许昌市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 9,
        enumNameother: "许昌市",
        children: [
          {
            fldEnumId: 130351,
            enumGroupGuid: "1",
            parentEnumValue: "4110",
            enumValue: "411003",
            enumName: "建安区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 10957,
            enumGroupGuid: "1",
            parentEnumValue: "4110",
            enumValue: "411000",
            enumName: "许昌市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 99,
            enumNameother: "许昌市",
          },
          {
            fldEnumId: 10959,
            enumGroupGuid: "1",
            parentEnumValue: "4110",
            enumValue: "411002",
            enumName: "魏都区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 100,
            enumNameother: "魏都区",
          },
          {
            fldEnumId: 10961,
            enumGroupGuid: "1",
            parentEnumValue: "4110",
            enumValue: "411023",
            enumName: "许昌县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 101,
            enumNameother: "许昌县",
          },
          {
            fldEnumId: 10963,
            enumGroupGuid: "1",
            parentEnumValue: "4110",
            enumValue: "411024",
            enumName: "鄢陵县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 102,
            enumNameother: "鄢陵县",
          },
          {
            fldEnumId: 10965,
            enumGroupGuid: "1",
            parentEnumValue: "4110",
            enumValue: "411025",
            enumName: "襄城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 103,
            enumNameother: "襄城县",
          },
          {
            fldEnumId: 10967,
            enumGroupGuid: "1",
            parentEnumValue: "4110",
            enumValue: "411081",
            enumName: "禹州市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 104,
            enumNameother: "禹州市",
          },
          {
            fldEnumId: 10969,
            enumGroupGuid: "1",
            parentEnumValue: "4110",
            enumValue: "411082",
            enumName: "长葛市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 105,
            enumNameother: "长葛市",
          },
        ],
      },
      {
        fldEnumId: 10971,
        enumGroupGuid: "1",
        parentEnumValue: "41",
        enumValue: "4111",
        enumName: "漯河市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 10,
        enumNameother: "漯河市",
        children: [
          {
            fldEnumId: 10972,
            enumGroupGuid: "1",
            parentEnumValue: "4111",
            enumValue: "411100",
            enumName: "漯河市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 106,
            enumNameother: "漯河市",
          },
          {
            fldEnumId: 10974,
            enumGroupGuid: "1",
            parentEnumValue: "4111",
            enumValue: "411102",
            enumName: "源汇区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 107,
            enumNameother: "源汇区",
          },
          {
            fldEnumId: 10976,
            enumGroupGuid: "1",
            parentEnumValue: "4111",
            enumValue: "411103",
            enumName: "郾城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 108,
            enumNameother: "郾城区",
          },
          {
            fldEnumId: 10978,
            enumGroupGuid: "1",
            parentEnumValue: "4111",
            enumValue: "411104",
            enumName: "召陵区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 109,
            enumNameother: "召陵区",
          },
          {
            fldEnumId: 10980,
            enumGroupGuid: "1",
            parentEnumValue: "4111",
            enumValue: "411121",
            enumName: "舞阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 110,
            enumNameother: "舞阳县",
          },
          {
            fldEnumId: 10982,
            enumGroupGuid: "1",
            parentEnumValue: "4111",
            enumValue: "411122",
            enumName: "临颍县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 111,
            enumNameother: "临颍县",
          },
        ],
      },
      {
        fldEnumId: 10984,
        enumGroupGuid: "1",
        parentEnumValue: "41",
        enumValue: "4112",
        enumName: "三门峡市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 11,
        enumNameother: "三门峡市",
        children: [
          {
            fldEnumId: 10985,
            enumGroupGuid: "1",
            parentEnumValue: "4112",
            enumValue: "411200",
            enumName: "三门峡市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 112,
            enumNameother: "三门峡市",
          },
          {
            fldEnumId: 10987,
            enumGroupGuid: "1",
            parentEnumValue: "4112",
            enumValue: "411202",
            enumName: "湖滨区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 113,
            enumNameother: "湖滨区",
          },
          {
            fldEnumId: 10989,
            enumGroupGuid: "1",
            parentEnumValue: "4112",
            enumValue: "411221",
            enumName: "渑池县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 114,
            enumNameother: "渑池县",
          },
          {
            fldEnumId: 10991,
            enumGroupGuid: "1",
            parentEnumValue: "4112",
            enumValue: "411222",
            enumName: "陕县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 115,
            enumNameother: "陕县",
          },
          {
            fldEnumId: 10993,
            enumGroupGuid: "1",
            parentEnumValue: "4112",
            enumValue: "411224",
            enumName: "卢氏县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 116,
            enumNameother: "卢氏县",
          },
          {
            fldEnumId: 10995,
            enumGroupGuid: "1",
            parentEnumValue: "4112",
            enumValue: "411281",
            enumName: "义马市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 117,
            enumNameother: "义马市",
          },
          {
            fldEnumId: 10997,
            enumGroupGuid: "1",
            parentEnumValue: "4112",
            enumValue: "411282",
            enumName: "灵宝市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 118,
            enumNameother: "灵宝市",
          },
        ],
      },
      {
        fldEnumId: 10999,
        enumGroupGuid: "1",
        parentEnumValue: "41",
        enumValue: "4113",
        enumName: "南阳市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 12,
        enumNameother: "南阳市",
        children: [
          {
            fldEnumId: 11000,
            enumGroupGuid: "1",
            parentEnumValue: "4113",
            enumValue: "411300",
            enumName: "南阳市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 119,
            enumNameother: "南阳市",
          },
          {
            fldEnumId: 11002,
            enumGroupGuid: "1",
            parentEnumValue: "4113",
            enumValue: "411302",
            enumName: "宛城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 120,
            enumNameother: "宛城区",
          },
          {
            fldEnumId: 11004,
            enumGroupGuid: "1",
            parentEnumValue: "4113",
            enumValue: "411303",
            enumName: "卧龙区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 121,
            enumNameother: "卧龙区",
          },
          {
            fldEnumId: 11006,
            enumGroupGuid: "1",
            parentEnumValue: "4113",
            enumValue: "411321",
            enumName: "南召县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 122,
            enumNameother: "南召县",
          },
          {
            fldEnumId: 11008,
            enumGroupGuid: "1",
            parentEnumValue: "4113",
            enumValue: "411322",
            enumName: "方城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 123,
            enumNameother: "方城县",
          },
          {
            fldEnumId: 11010,
            enumGroupGuid: "1",
            parentEnumValue: "4113",
            enumValue: "411323",
            enumName: "西峡县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 124,
            enumNameother: "西峡县",
          },
          {
            fldEnumId: 11012,
            enumGroupGuid: "1",
            parentEnumValue: "4113",
            enumValue: "411324",
            enumName: "镇平县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 125,
            enumNameother: "镇平县",
          },
          {
            fldEnumId: 11014,
            enumGroupGuid: "1",
            parentEnumValue: "4113",
            enumValue: "411325",
            enumName: "内乡县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 126,
            enumNameother: "内乡县",
          },
          {
            fldEnumId: 11016,
            enumGroupGuid: "1",
            parentEnumValue: "4113",
            enumValue: "411326",
            enumName: "淅川县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 127,
            enumNameother: "淅川县",
          },
          {
            fldEnumId: 11018,
            enumGroupGuid: "1",
            parentEnumValue: "4113",
            enumValue: "411327",
            enumName: "社旗县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 128,
            enumNameother: "社旗县",
          },
          {
            fldEnumId: 11020,
            enumGroupGuid: "1",
            parentEnumValue: "4113",
            enumValue: "411328",
            enumName: "唐河县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 129,
            enumNameother: "唐河县",
          },
          {
            fldEnumId: 11022,
            enumGroupGuid: "1",
            parentEnumValue: "4113",
            enumValue: "411329",
            enumName: "新野县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 130,
            enumNameother: "新野县",
          },
          {
            fldEnumId: 11024,
            enumGroupGuid: "1",
            parentEnumValue: "4113",
            enumValue: "411330",
            enumName: "桐柏县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 131,
            enumNameother: "桐柏县",
          },
          {
            fldEnumId: 11026,
            enumGroupGuid: "1",
            parentEnumValue: "4113",
            enumValue: "411381",
            enumName: "邓州市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 132,
            enumNameother: "邓州市",
          },
        ],
      },
      {
        fldEnumId: 11028,
        enumGroupGuid: "1",
        parentEnumValue: "41",
        enumValue: "4114",
        enumName: "商丘市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 13,
        enumNameother: "商丘市",
        children: [
          {
            fldEnumId: 11029,
            enumGroupGuid: "1",
            parentEnumValue: "4114",
            enumValue: "411400",
            enumName: "商丘市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 133,
            enumNameother: "商丘市",
          },
          {
            fldEnumId: 11031,
            enumGroupGuid: "1",
            parentEnumValue: "4114",
            enumValue: "411402",
            enumName: "梁园区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 134,
            enumNameother: "梁园区",
          },
          {
            fldEnumId: 11033,
            enumGroupGuid: "1",
            parentEnumValue: "4114",
            enumValue: "411403",
            enumName: "睢阳区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 135,
            enumNameother: "睢阳区",
          },
          {
            fldEnumId: 11035,
            enumGroupGuid: "1",
            parentEnumValue: "4114",
            enumValue: "411421",
            enumName: "民权县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 136,
            enumNameother: "民权县",
          },
          {
            fldEnumId: 11037,
            enumGroupGuid: "1",
            parentEnumValue: "4114",
            enumValue: "411422",
            enumName: "睢县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 137,
            enumNameother: "睢县",
          },
          {
            fldEnumId: 11039,
            enumGroupGuid: "1",
            parentEnumValue: "4114",
            enumValue: "411423",
            enumName: "宁陵县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 138,
            enumNameother: "宁陵县",
          },
          {
            fldEnumId: 11041,
            enumGroupGuid: "1",
            parentEnumValue: "4114",
            enumValue: "411424",
            enumName: "柘城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 139,
            enumNameother: "柘城县",
          },
          {
            fldEnumId: 11043,
            enumGroupGuid: "1",
            parentEnumValue: "4114",
            enumValue: "411425",
            enumName: "虞城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 140,
            enumNameother: "虞城县",
          },
          {
            fldEnumId: 11045,
            enumGroupGuid: "1",
            parentEnumValue: "4114",
            enumValue: "411426",
            enumName: "夏邑县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 141,
            enumNameother: "夏邑县",
          },
          {
            fldEnumId: 11047,
            enumGroupGuid: "1",
            parentEnumValue: "4114",
            enumValue: "411481",
            enumName: "永城市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 142,
            enumNameother: "永城市",
          },
        ],
      },
      {
        fldEnumId: 11049,
        enumGroupGuid: "1",
        parentEnumValue: "41",
        enumValue: "4115",
        enumName: "信阳市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 14,
        enumNameother: "信阳市",
        children: [
          {
            fldEnumId: 11050,
            enumGroupGuid: "1",
            parentEnumValue: "4115",
            enumValue: "411500",
            enumName: "信阳市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 143,
            enumNameother: "信阳市",
          },
          {
            fldEnumId: 11052,
            enumGroupGuid: "1",
            parentEnumValue: "4115",
            enumValue: "411502",
            enumName: "浉河区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 144,
            enumNameother: "浉河区",
          },
          {
            fldEnumId: 11054,
            enumGroupGuid: "1",
            parentEnumValue: "4115",
            enumValue: "411503",
            enumName: "平桥区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 145,
            enumNameother: "平桥区",
          },
          {
            fldEnumId: 11056,
            enumGroupGuid: "1",
            parentEnumValue: "4115",
            enumValue: "411521",
            enumName: "罗山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 146,
            enumNameother: "罗山县",
          },
          {
            fldEnumId: 11058,
            enumGroupGuid: "1",
            parentEnumValue: "4115",
            enumValue: "411522",
            enumName: "光山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 147,
            enumNameother: "光山县",
          },
          {
            fldEnumId: 11060,
            enumGroupGuid: "1",
            parentEnumValue: "4115",
            enumValue: "411523",
            enumName: "新县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 148,
            enumNameother: "新县",
          },
          {
            fldEnumId: 11062,
            enumGroupGuid: "1",
            parentEnumValue: "4115",
            enumValue: "411524",
            enumName: "商城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 149,
            enumNameother: "商城县",
          },
          {
            fldEnumId: 11064,
            enumGroupGuid: "1",
            parentEnumValue: "4115",
            enumValue: "411525",
            enumName: "固始县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 150,
            enumNameother: "固始县",
          },
          {
            fldEnumId: 11066,
            enumGroupGuid: "1",
            parentEnumValue: "4115",
            enumValue: "411526",
            enumName: "潢川县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 151,
            enumNameother: "潢川县",
          },
          {
            fldEnumId: 11068,
            enumGroupGuid: "1",
            parentEnumValue: "4115",
            enumValue: "411527",
            enumName: "淮滨县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 152,
            enumNameother: "淮滨县",
          },
          {
            fldEnumId: 11070,
            enumGroupGuid: "1",
            parentEnumValue: "4115",
            enumValue: "411528",
            enumName: "息县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 153,
            enumNameother: "息县",
          },
        ],
      },
      {
        fldEnumId: 11072,
        enumGroupGuid: "1",
        parentEnumValue: "41",
        enumValue: "4116",
        enumName: "周口市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 15,
        enumNameother: "周口市",
        children: [
          {
            fldEnumId: 11073,
            enumGroupGuid: "1",
            parentEnumValue: "4116",
            enumValue: "411600",
            enumName: "周口市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 154,
            enumNameother: "周口市",
          },
          {
            fldEnumId: 11075,
            enumGroupGuid: "1",
            parentEnumValue: "4116",
            enumValue: "411602",
            enumName: "川汇区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 155,
            enumNameother: "川汇区",
          },
          {
            fldEnumId: 11077,
            enumGroupGuid: "1",
            parentEnumValue: "4116",
            enumValue: "411621",
            enumName: "扶沟县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 156,
            enumNameother: "扶沟县",
          },
          {
            fldEnumId: 11079,
            enumGroupGuid: "1",
            parentEnumValue: "4116",
            enumValue: "411622",
            enumName: "西华县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 157,
            enumNameother: "西华县",
          },
          {
            fldEnumId: 11081,
            enumGroupGuid: "1",
            parentEnumValue: "4116",
            enumValue: "411623",
            enumName: "商水县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 158,
            enumNameother: "商水县",
          },
          {
            fldEnumId: 11083,
            enumGroupGuid: "1",
            parentEnumValue: "4116",
            enumValue: "411624",
            enumName: "沈丘县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 159,
            enumNameother: "沈丘县",
          },
          {
            fldEnumId: 11085,
            enumGroupGuid: "1",
            parentEnumValue: "4116",
            enumValue: "411625",
            enumName: "郸城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 160,
            enumNameother: "郸城县",
          },
          {
            fldEnumId: 11087,
            enumGroupGuid: "1",
            parentEnumValue: "4116",
            enumValue: "411626",
            enumName: "淮阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 161,
            enumNameother: "淮阳县",
          },
          {
            fldEnumId: 11089,
            enumGroupGuid: "1",
            parentEnumValue: "4116",
            enumValue: "411627",
            enumName: "太康县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 162,
            enumNameother: "太康县",
          },
          {
            fldEnumId: 11091,
            enumGroupGuid: "1",
            parentEnumValue: "4116",
            enumValue: "411628",
            enumName: "鹿邑县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 163,
            enumNameother: "鹿邑县",
          },
          {
            fldEnumId: 11093,
            enumGroupGuid: "1",
            parentEnumValue: "4116",
            enumValue: "411681",
            enumName: "项城市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 164,
            enumNameother: "项城市",
          },
        ],
      },
      {
        fldEnumId: 11095,
        enumGroupGuid: "1",
        parentEnumValue: "41",
        enumValue: "4117",
        enumName: "驻马店市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 16,
        enumNameother: "驻马店市",
        children: [
          {
            fldEnumId: 11096,
            enumGroupGuid: "1",
            parentEnumValue: "4117",
            enumValue: "411700",
            enumName: "驻马店市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 165,
            enumNameother: "驻马店市",
          },
          {
            fldEnumId: 11098,
            enumGroupGuid: "1",
            parentEnumValue: "4117",
            enumValue: "411702",
            enumName: "驿城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 166,
            enumNameother: "驿城区",
          },
          {
            fldEnumId: 11100,
            enumGroupGuid: "1",
            parentEnumValue: "4117",
            enumValue: "411721",
            enumName: "西平县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 167,
            enumNameother: "西平县",
          },
          {
            fldEnumId: 11102,
            enumGroupGuid: "1",
            parentEnumValue: "4117",
            enumValue: "411722",
            enumName: "上蔡县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 168,
            enumNameother: "上蔡县",
          },
          {
            fldEnumId: 11104,
            enumGroupGuid: "1",
            parentEnumValue: "4117",
            enumValue: "411723",
            enumName: "平舆县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 169,
            enumNameother: "平舆县",
          },
          {
            fldEnumId: 11106,
            enumGroupGuid: "1",
            parentEnumValue: "4117",
            enumValue: "411724",
            enumName: "正阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 170,
            enumNameother: "正阳县",
          },
          {
            fldEnumId: 11108,
            enumGroupGuid: "1",
            parentEnumValue: "4117",
            enumValue: "411725",
            enumName: "确山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 171,
            enumNameother: "确山县",
          },
          {
            fldEnumId: 11110,
            enumGroupGuid: "1",
            parentEnumValue: "4117",
            enumValue: "411726",
            enumName: "泌阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 172,
            enumNameother: "泌阳县",
          },
          {
            fldEnumId: 11112,
            enumGroupGuid: "1",
            parentEnumValue: "4117",
            enumValue: "411727",
            enumName: "汝南县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 173,
            enumNameother: "汝南县",
          },
          {
            fldEnumId: 11114,
            enumGroupGuid: "1",
            parentEnumValue: "4117",
            enumValue: "411728",
            enumName: "遂平县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 174,
            enumNameother: "遂平县",
          },
          {
            fldEnumId: 11116,
            enumGroupGuid: "1",
            parentEnumValue: "4117",
            enumValue: "411729",
            enumName: "新蔡县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 175,
            enumNameother: "新蔡县",
          },
        ],
      },
    ],
  },
  {
    enumValue: "42",
    enumName: "湖北省",
    children: [
      {
        fldEnumId: 11119,
        enumGroupGuid: "1",
        parentEnumValue: "42",
        enumValue: "4201",
        enumName: "武汉市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        enumNameother: "武汉市",
        children: [
          {
            fldEnumId: 11120,
            enumGroupGuid: "1",
            parentEnumValue: "4201",
            enumValue: "420100",
            enumName: "武汉市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
            enumNameother: "武汉市",
          },
          {
            fldEnumId: 11122,
            enumGroupGuid: "1",
            parentEnumValue: "4201",
            enumValue: "420102",
            enumName: "江岸区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "江岸区",
          },
          {
            fldEnumId: 11124,
            enumGroupGuid: "1",
            parentEnumValue: "4201",
            enumValue: "420103",
            enumName: "江汉区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 2,
            enumNameother: "江汉区",
          },
          {
            fldEnumId: 11126,
            enumGroupGuid: "1",
            parentEnumValue: "4201",
            enumValue: "420104",
            enumName: "硚口区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 3,
            enumNameother: "硚口区",
          },
          {
            fldEnumId: 11128,
            enumGroupGuid: "1",
            parentEnumValue: "4201",
            enumValue: "420105",
            enumName: "汉阳区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 4,
            enumNameother: "汉阳区",
          },
          {
            fldEnumId: 11130,
            enumGroupGuid: "1",
            parentEnumValue: "4201",
            enumValue: "420106",
            enumName: "武昌区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 5,
            enumNameother: "武昌区",
          },
          {
            fldEnumId: 11132,
            enumGroupGuid: "1",
            parentEnumValue: "4201",
            enumValue: "420107",
            enumName: "青山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 6,
            enumNameother: "青山区",
          },
          {
            fldEnumId: 11134,
            enumGroupGuid: "1",
            parentEnumValue: "4201",
            enumValue: "420111",
            enumName: "洪山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 7,
            enumNameother: "洪山区",
          },
          {
            fldEnumId: 11136,
            enumGroupGuid: "1",
            parentEnumValue: "4201",
            enumValue: "420112",
            enumName: "东西湖区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 8,
            enumNameother: "东西湖区",
          },
          {
            fldEnumId: 11138,
            enumGroupGuid: "1",
            parentEnumValue: "4201",
            enumValue: "420113",
            enumName: "汉南区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 9,
            enumNameother: "汉南区",
          },
          {
            fldEnumId: 11140,
            enumGroupGuid: "1",
            parentEnumValue: "4201",
            enumValue: "420114",
            enumName: "蔡甸区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 10,
            enumNameother: "蔡甸区",
          },
          {
            fldEnumId: 11142,
            enumGroupGuid: "1",
            parentEnumValue: "4201",
            enumValue: "420115",
            enumName: "江夏区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 11,
            enumNameother: "江夏区",
          },
          {
            fldEnumId: 11144,
            enumGroupGuid: "1",
            parentEnumValue: "4201",
            enumValue: "420116",
            enumName: "黄陂区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 12,
            enumNameother: "黄陂区",
          },
          {
            fldEnumId: 11146,
            enumGroupGuid: "1",
            parentEnumValue: "4201",
            enumValue: "420117",
            enumName: "新洲区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 13,
            enumNameother: "新洲区",
          },
        ],
      },
      {
        fldEnumId: 11148,
        enumGroupGuid: "1",
        parentEnumValue: "42",
        enumValue: "4202",
        enumName: "黄石市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 1,
        enumNameother: "黄石市",
        children: [
          {
            fldEnumId: 11149,
            enumGroupGuid: "1",
            parentEnumValue: "4202",
            enumValue: "420200",
            enumName: "黄石市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 14,
            enumNameother: "黄石市",
          },
          {
            fldEnumId: 11151,
            enumGroupGuid: "1",
            parentEnumValue: "4202",
            enumValue: "420202",
            enumName: "黄石港区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 15,
            enumNameother: "黄石港区",
          },
          {
            fldEnumId: 11153,
            enumGroupGuid: "1",
            parentEnumValue: "4202",
            enumValue: "420203",
            enumName: "西塞山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 16,
            enumNameother: "西塞山区",
          },
          {
            fldEnumId: 11155,
            enumGroupGuid: "1",
            parentEnumValue: "4202",
            enumValue: "420204",
            enumName: "下陆区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 17,
            enumNameother: "下陆区",
          },
          {
            fldEnumId: 11157,
            enumGroupGuid: "1",
            parentEnumValue: "4202",
            enumValue: "420205",
            enumName: "铁山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 18,
            enumNameother: "铁山区",
          },
          {
            fldEnumId: 11159,
            enumGroupGuid: "1",
            parentEnumValue: "4202",
            enumValue: "420222",
            enumName: "阳新县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 19,
            enumNameother: "阳新县",
          },
          {
            fldEnumId: 11161,
            enumGroupGuid: "1",
            parentEnumValue: "4202",
            enumValue: "420281",
            enumName: "大冶市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 20,
            enumNameother: "大冶市",
          },
        ],
      },
      {
        fldEnumId: 11163,
        enumGroupGuid: "1",
        parentEnumValue: "42",
        enumValue: "4203",
        enumName: "十堰市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 2,
        enumNameother: "十堰市",
        children: [
          {
            fldEnumId: 130322,
            enumGroupGuid: "1",
            parentEnumValue: "4203",
            enumValue: "420304",
            enumName: "郧阳区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 11164,
            enumGroupGuid: "1",
            parentEnumValue: "4203",
            enumValue: "420300",
            enumName: "十堰市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 21,
            enumNameother: "十堰市",
          },
          {
            fldEnumId: 11166,
            enumGroupGuid: "1",
            parentEnumValue: "4203",
            enumValue: "420302",
            enumName: "茅箭区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 22,
            enumNameother: "茅箭区",
          },
          {
            fldEnumId: 11168,
            enumGroupGuid: "1",
            parentEnumValue: "4203",
            enumValue: "420303",
            enumName: "张湾区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 23,
            enumNameother: "张湾区",
          },
          {
            fldEnumId: 11170,
            enumGroupGuid: "1",
            parentEnumValue: "4203",
            enumValue: "420321",
            enumName: "郧县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 24,
            enumNameother: "郧县",
          },
          {
            fldEnumId: 11172,
            enumGroupGuid: "1",
            parentEnumValue: "4203",
            enumValue: "420322",
            enumName: "郧西县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 25,
            enumNameother: "郧西县",
          },
          {
            fldEnumId: 11174,
            enumGroupGuid: "1",
            parentEnumValue: "4203",
            enumValue: "420323",
            enumName: "竹山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 26,
            enumNameother: "竹山县",
          },
          {
            fldEnumId: 11176,
            enumGroupGuid: "1",
            parentEnumValue: "4203",
            enumValue: "420324",
            enumName: "竹溪县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 27,
            enumNameother: "竹溪县",
          },
          {
            fldEnumId: 11178,
            enumGroupGuid: "1",
            parentEnumValue: "4203",
            enumValue: "420325",
            enumName: "房县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 28,
            enumNameother: "房县",
          },
          {
            fldEnumId: 11180,
            enumGroupGuid: "1",
            parentEnumValue: "4203",
            enumValue: "420381",
            enumName: "丹江口市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 29,
            enumNameother: "丹江口市",
          },
        ],
      },
      {
        fldEnumId: 11182,
        enumGroupGuid: "1",
        parentEnumValue: "42",
        enumValue: "4205",
        enumName: "宜昌市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 3,
        enumNameother: "宜昌市",
        children: [
          {
            fldEnumId: 11183,
            enumGroupGuid: "1",
            parentEnumValue: "4205",
            enumValue: "420500",
            enumName: "宜昌市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 30,
            enumNameother: "宜昌市",
          },
          {
            fldEnumId: 11185,
            enumGroupGuid: "1",
            parentEnumValue: "4205",
            enumValue: "420502",
            enumName: "西陵区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 31,
            enumNameother: "西陵区",
          },
          {
            fldEnumId: 11187,
            enumGroupGuid: "1",
            parentEnumValue: "4205",
            enumValue: "420503",
            enumName: "伍家岗区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 32,
            enumNameother: "伍家岗区",
          },
          {
            fldEnumId: 11189,
            enumGroupGuid: "1",
            parentEnumValue: "4205",
            enumValue: "420504",
            enumName: "点军区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 33,
            enumNameother: "点军区",
          },
          {
            fldEnumId: 11191,
            enumGroupGuid: "1",
            parentEnumValue: "4205",
            enumValue: "420505",
            enumName: "猇亭区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 34,
            enumNameother: "猇亭区",
          },
          {
            fldEnumId: 11193,
            enumGroupGuid: "1",
            parentEnumValue: "4205",
            enumValue: "420506",
            enumName: "夷陵区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 35,
            enumNameother: "夷陵区",
          },
          {
            fldEnumId: 11195,
            enumGroupGuid: "1",
            parentEnumValue: "4205",
            enumValue: "420525",
            enumName: "远安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 36,
            enumNameother: "远安县",
          },
          {
            fldEnumId: 11197,
            enumGroupGuid: "1",
            parentEnumValue: "4205",
            enumValue: "420526",
            enumName: "兴山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 37,
            enumNameother: "兴山县",
          },
          {
            fldEnumId: 11199,
            enumGroupGuid: "1",
            parentEnumValue: "4205",
            enumValue: "420527",
            enumName: "秭归县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 38,
            enumNameother: "秭归县",
          },
          {
            fldEnumId: 11201,
            enumGroupGuid: "1",
            parentEnumValue: "4205",
            enumValue: "420528",
            enumName: "长阳土家族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 39,
            enumNameother: "长阳土家族自治县",
          },
          {
            fldEnumId: 11203,
            enumGroupGuid: "1",
            parentEnumValue: "4205",
            enumValue: "420529",
            enumName: "五峰土家族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 40,
            enumNameother: "五峰土家族自治县",
          },
          {
            fldEnumId: 11205,
            enumGroupGuid: "1",
            parentEnumValue: "4205",
            enumValue: "420581",
            enumName: "宜都市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 41,
            enumNameother: "宜都市",
          },
          {
            fldEnumId: 11207,
            enumGroupGuid: "1",
            parentEnumValue: "4205",
            enumValue: "420582",
            enumName: "当阳市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 42,
            enumNameother: "当阳市",
          },
          {
            fldEnumId: 11209,
            enumGroupGuid: "1",
            parentEnumValue: "4205",
            enumValue: "420583",
            enumName: "枝江市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 43,
            enumNameother: "枝江市",
          },
        ],
      },
      {
        fldEnumId: 11211,
        enumGroupGuid: "1",
        parentEnumValue: "42",
        enumValue: "4206",
        enumName: "襄樊市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 4,
        enumNameother: "襄樊市",
        children: [
          {
            fldEnumId: 11212,
            enumGroupGuid: "1",
            parentEnumValue: "4206",
            enumValue: "420600",
            enumName: "襄樊市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 44,
            enumNameother: "襄樊市",
          },
          {
            fldEnumId: 11214,
            enumGroupGuid: "1",
            parentEnumValue: "4206",
            enumValue: "420602",
            enumName: "襄城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 45,
            enumNameother: "襄城区",
          },
          {
            fldEnumId: 11216,
            enumGroupGuid: "1",
            parentEnumValue: "4206",
            enumValue: "420606",
            enumName: "樊城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 46,
            enumNameother: "樊城区",
          },
          {
            fldEnumId: 11218,
            enumGroupGuid: "1",
            parentEnumValue: "4206",
            enumValue: "420607",
            enumName: "襄阳区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 47,
            enumNameother: "襄阳区",
          },
          {
            fldEnumId: 11220,
            enumGroupGuid: "1",
            parentEnumValue: "4206",
            enumValue: "420624",
            enumName: "南漳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 48,
            enumNameother: "南漳县",
          },
          {
            fldEnumId: 11222,
            enumGroupGuid: "1",
            parentEnumValue: "4206",
            enumValue: "420625",
            enumName: "谷城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 49,
            enumNameother: "谷城县",
          },
          {
            fldEnumId: 11224,
            enumGroupGuid: "1",
            parentEnumValue: "4206",
            enumValue: "420626",
            enumName: "保康县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 50,
            enumNameother: "保康县",
          },
          {
            fldEnumId: 11226,
            enumGroupGuid: "1",
            parentEnumValue: "4206",
            enumValue: "420682",
            enumName: "老河口市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 51,
            enumNameother: "老河口市",
          },
          {
            fldEnumId: 11228,
            enumGroupGuid: "1",
            parentEnumValue: "4206",
            enumValue: "420683",
            enumName: "枣阳市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 52,
            enumNameother: "枣阳市",
          },
          {
            fldEnumId: 11230,
            enumGroupGuid: "1",
            parentEnumValue: "4206",
            enumValue: "420684",
            enumName: "宜城市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 53,
            enumNameother: "宜城市",
          },
        ],
      },
      {
        fldEnumId: 11232,
        enumGroupGuid: "1",
        parentEnumValue: "42",
        enumValue: "4207",
        enumName: "鄂州市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 5,
        enumNameother: "鄂州市",
        children: [
          {
            fldEnumId: 11233,
            enumGroupGuid: "1",
            parentEnumValue: "4207",
            enumValue: "420700",
            enumName: "鄂州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 54,
            enumNameother: "鄂州市",
          },
          {
            fldEnumId: 11235,
            enumGroupGuid: "1",
            parentEnumValue: "4207",
            enumValue: "420702",
            enumName: "梁子湖区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 55,
            enumNameother: "梁子湖区",
          },
          {
            fldEnumId: 11237,
            enumGroupGuid: "1",
            parentEnumValue: "4207",
            enumValue: "420703",
            enumName: "华容区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 56,
            enumNameother: "华容区",
          },
          {
            fldEnumId: 11239,
            enumGroupGuid: "1",
            parentEnumValue: "4207",
            enumValue: "420704",
            enumName: "鄂城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 57,
            enumNameother: "鄂城区",
          },
        ],
      },
      {
        fldEnumId: 11241,
        enumGroupGuid: "1",
        parentEnumValue: "42",
        enumValue: "4208",
        enumName: "荆门市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 6,
        enumNameother: "荆门市",
        children: [
          {
            fldEnumId: 11242,
            enumGroupGuid: "1",
            parentEnumValue: "4208",
            enumValue: "420800",
            enumName: "荆门市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 58,
            enumNameother: "荆门市",
          },
          {
            fldEnumId: 11244,
            enumGroupGuid: "1",
            parentEnumValue: "4208",
            enumValue: "420802",
            enumName: "东宝区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 59,
            enumNameother: "东宝区",
          },
          {
            fldEnumId: 11246,
            enumGroupGuid: "1",
            parentEnumValue: "4208",
            enumValue: "420804",
            enumName: "掇刀区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 60,
            enumNameother: "掇刀区",
          },
          {
            fldEnumId: 11248,
            enumGroupGuid: "1",
            parentEnumValue: "4208",
            enumValue: "420821",
            enumName: "京山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 61,
            enumNameother: "京山县",
          },
          {
            fldEnumId: 11250,
            enumGroupGuid: "1",
            parentEnumValue: "4208",
            enumValue: "420822",
            enumName: "沙洋县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 62,
            enumNameother: "沙洋县",
          },
          {
            fldEnumId: 11252,
            enumGroupGuid: "1",
            parentEnumValue: "4208",
            enumValue: "420881",
            enumName: "钟祥市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 63,
            enumNameother: "钟祥市",
          },
        ],
      },
      {
        fldEnumId: 11254,
        enumGroupGuid: "1",
        parentEnumValue: "42",
        enumValue: "4209",
        enumName: "孝感市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 7,
        enumNameother: "孝感市",
        children: [
          {
            fldEnumId: 11255,
            enumGroupGuid: "1",
            parentEnumValue: "4209",
            enumValue: "420900",
            enumName: "孝感市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 64,
            enumNameother: "孝感市",
          },
          {
            fldEnumId: 11257,
            enumGroupGuid: "1",
            parentEnumValue: "4209",
            enumValue: "420902",
            enumName: "孝南区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 65,
            enumNameother: "孝南区",
          },
          {
            fldEnumId: 11259,
            enumGroupGuid: "1",
            parentEnumValue: "4209",
            enumValue: "420921",
            enumName: "孝昌县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 66,
            enumNameother: "孝昌县",
          },
          {
            fldEnumId: 11261,
            enumGroupGuid: "1",
            parentEnumValue: "4209",
            enumValue: "420922",
            enumName: "大悟县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 67,
            enumNameother: "大悟县",
          },
          {
            fldEnumId: 11263,
            enumGroupGuid: "1",
            parentEnumValue: "4209",
            enumValue: "420923",
            enumName: "云梦县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 68,
            enumNameother: "云梦县",
          },
          {
            fldEnumId: 11265,
            enumGroupGuid: "1",
            parentEnumValue: "4209",
            enumValue: "420981",
            enumName: "应城市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 69,
            enumNameother: "应城市",
          },
          {
            fldEnumId: 11267,
            enumGroupGuid: "1",
            parentEnumValue: "4209",
            enumValue: "420982",
            enumName: "安陆市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 70,
            enumNameother: "安陆市",
          },
          {
            fldEnumId: 11269,
            enumGroupGuid: "1",
            parentEnumValue: "4209",
            enumValue: "420984",
            enumName: "汉川市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 71,
            enumNameother: "汉川市",
          },
        ],
      },
      {
        fldEnumId: 11271,
        enumGroupGuid: "1",
        parentEnumValue: "42",
        enumValue: "4210",
        enumName: "荆州市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 8,
        enumNameother: "荆州市",
        children: [
          {
            fldEnumId: 11272,
            enumGroupGuid: "1",
            parentEnumValue: "4210",
            enumValue: "421000",
            enumName: "荆州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 72,
            enumNameother: "荆州市",
          },
          {
            fldEnumId: 11274,
            enumGroupGuid: "1",
            parentEnumValue: "4210",
            enumValue: "421002",
            enumName: "沙市区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 73,
            enumNameother: "沙市区",
          },
          {
            fldEnumId: 11276,
            enumGroupGuid: "1",
            parentEnumValue: "4210",
            enumValue: "421003",
            enumName: "荆州区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 74,
            enumNameother: "荆州区",
          },
          {
            fldEnumId: 11278,
            enumGroupGuid: "1",
            parentEnumValue: "4210",
            enumValue: "421022",
            enumName: "公安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 75,
            enumNameother: "公安县",
          },
          {
            fldEnumId: 11280,
            enumGroupGuid: "1",
            parentEnumValue: "4210",
            enumValue: "421023",
            enumName: "监利县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 76,
            enumNameother: "监利县",
          },
          {
            fldEnumId: 11282,
            enumGroupGuid: "1",
            parentEnumValue: "4210",
            enumValue: "421024",
            enumName: "江陵县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 77,
            enumNameother: "江陵县",
          },
          {
            fldEnumId: 11284,
            enumGroupGuid: "1",
            parentEnumValue: "4210",
            enumValue: "421081",
            enumName: "石首市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 78,
            enumNameother: "石首市",
          },
          {
            fldEnumId: 11286,
            enumGroupGuid: "1",
            parentEnumValue: "4210",
            enumValue: "421083",
            enumName: "洪湖市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 79,
            enumNameother: "洪湖市",
          },
          {
            fldEnumId: 11288,
            enumGroupGuid: "1",
            parentEnumValue: "4210",
            enumValue: "421087",
            enumName: "松滋市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 80,
            enumNameother: "松滋市",
          },
        ],
      },
      {
        fldEnumId: 11290,
        enumGroupGuid: "1",
        parentEnumValue: "42",
        enumValue: "4211",
        enumName: "黄冈市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 9,
        enumNameother: "黄冈市",
        children: [
          {
            fldEnumId: 11291,
            enumGroupGuid: "1",
            parentEnumValue: "4211",
            enumValue: "421100",
            enumName: "黄冈市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 81,
            enumNameother: "黄冈市",
          },
          {
            fldEnumId: 11293,
            enumGroupGuid: "1",
            parentEnumValue: "4211",
            enumValue: "421102",
            enumName: "黄州区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 82,
            enumNameother: "黄州区",
          },
          {
            fldEnumId: 11295,
            enumGroupGuid: "1",
            parentEnumValue: "4211",
            enumValue: "421121",
            enumName: "团风县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 83,
            enumNameother: "团风县",
          },
          {
            fldEnumId: 11297,
            enumGroupGuid: "1",
            parentEnumValue: "4211",
            enumValue: "421122",
            enumName: "红安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 84,
            enumNameother: "红安县",
          },
          {
            fldEnumId: 11299,
            enumGroupGuid: "1",
            parentEnumValue: "4211",
            enumValue: "421123",
            enumName: "罗田县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 85,
            enumNameother: "罗田县",
          },
          {
            fldEnumId: 11301,
            enumGroupGuid: "1",
            parentEnumValue: "4211",
            enumValue: "421124",
            enumName: "英山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 86,
            enumNameother: "英山县",
          },
          {
            fldEnumId: 11303,
            enumGroupGuid: "1",
            parentEnumValue: "4211",
            enumValue: "421125",
            enumName: "浠水县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 87,
            enumNameother: "浠水县",
          },
          {
            fldEnumId: 11305,
            enumGroupGuid: "1",
            parentEnumValue: "4211",
            enumValue: "421126",
            enumName: "蕲春县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 88,
            enumNameother: "蕲春县",
          },
          {
            fldEnumId: 11307,
            enumGroupGuid: "1",
            parentEnumValue: "4211",
            enumValue: "421127",
            enumName: "黄梅县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 89,
            enumNameother: "黄梅县",
          },
          {
            fldEnumId: 11309,
            enumGroupGuid: "1",
            parentEnumValue: "4211",
            enumValue: "421181",
            enumName: "麻城市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 90,
            enumNameother: "麻城市",
          },
          {
            fldEnumId: 11311,
            enumGroupGuid: "1",
            parentEnumValue: "4211",
            enumValue: "421182",
            enumName: "武穴市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 91,
            enumNameother: "武穴市",
          },
        ],
      },
      {
        fldEnumId: 11313,
        enumGroupGuid: "1",
        parentEnumValue: "42",
        enumValue: "4212",
        enumName: "咸宁市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 10,
        enumNameother: "咸宁市",
        children: [
          {
            fldEnumId: 11314,
            enumGroupGuid: "1",
            parentEnumValue: "4212",
            enumValue: "421200",
            enumName: "咸宁市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 92,
            enumNameother: "咸宁市",
          },
          {
            fldEnumId: 11316,
            enumGroupGuid: "1",
            parentEnumValue: "4212",
            enumValue: "421202",
            enumName: "咸安区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 93,
            enumNameother: "咸安区",
          },
          {
            fldEnumId: 11318,
            enumGroupGuid: "1",
            parentEnumValue: "4212",
            enumValue: "421221",
            enumName: "嘉鱼县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 94,
            enumNameother: "嘉鱼县",
          },
          {
            fldEnumId: 11320,
            enumGroupGuid: "1",
            parentEnumValue: "4212",
            enumValue: "421222",
            enumName: "通城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 95,
            enumNameother: "通城县",
          },
          {
            fldEnumId: 11322,
            enumGroupGuid: "1",
            parentEnumValue: "4212",
            enumValue: "421223",
            enumName: "崇阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 96,
            enumNameother: "崇阳县",
          },
          {
            fldEnumId: 11324,
            enumGroupGuid: "1",
            parentEnumValue: "4212",
            enumValue: "421224",
            enumName: "通山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 97,
            enumNameother: "通山县",
          },
          {
            fldEnumId: 11326,
            enumGroupGuid: "1",
            parentEnumValue: "4212",
            enumValue: "421281",
            enumName: "赤壁市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 98,
            enumNameother: "赤壁市",
          },
        ],
      },
      {
        fldEnumId: 11328,
        enumGroupGuid: "1",
        parentEnumValue: "42",
        enumValue: "4213",
        enumName: "随州市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 11,
        enumNameother: "随州市",
        children: [
          {
            fldEnumId: 130196,
            enumGroupGuid: "1",
            parentEnumValue: "4213",
            enumValue: "421303",
            enumName: "曾都区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130197,
            enumGroupGuid: "1",
            parentEnumValue: "4213",
            enumValue: "421321",
            enumName: "随县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 11329,
            enumGroupGuid: "1",
            parentEnumValue: "4213",
            enumValue: "421300",
            enumName: "随州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 99,
            enumNameother: "随州市",
          },
          {
            fldEnumId: 11331,
            enumGroupGuid: "1",
            parentEnumValue: "4213",
            enumValue: "421302",
            enumName: "曾都区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 100,
            enumNameother: "曾都区",
          },
          {
            fldEnumId: 11333,
            enumGroupGuid: "1",
            parentEnumValue: "4213",
            enumValue: "421381",
            enumName: "广水市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 101,
            enumNameother: "广水市",
          },
        ],
      },
      {
        fldEnumId: 11335,
        enumGroupGuid: "1",
        parentEnumValue: "42",
        enumValue: "4228",
        enumName: "恩施土家族苗族自治州",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 12,
        enumNameother: "恩施土家族苗族自治州",
        children: [
          {
            fldEnumId: 16292,
            enumGroupGuid: "1",
            parentEnumValue: "4228",
            enumValue: "422800",
            enumName: "恩施土家族苗族自治州本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 11336,
            enumGroupGuid: "1",
            parentEnumValue: "4228",
            enumValue: "422801",
            enumName: "恩施市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 102,
            enumNameother: "恩施市",
          },
          {
            fldEnumId: 11338,
            enumGroupGuid: "1",
            parentEnumValue: "4228",
            enumValue: "422802",
            enumName: "利川市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 103,
            enumNameother: "利川市",
          },
          {
            fldEnumId: 11340,
            enumGroupGuid: "1",
            parentEnumValue: "4228",
            enumValue: "422822",
            enumName: "建始县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 104,
            enumNameother: "建始县",
          },
          {
            fldEnumId: 11342,
            enumGroupGuid: "1",
            parentEnumValue: "4228",
            enumValue: "422823",
            enumName: "巴东县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 105,
            enumNameother: "巴东县",
          },
          {
            fldEnumId: 11344,
            enumGroupGuid: "1",
            parentEnumValue: "4228",
            enumValue: "422825",
            enumName: "宣恩县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 106,
            enumNameother: "宣恩县",
          },
          {
            fldEnumId: 11346,
            enumGroupGuid: "1",
            parentEnumValue: "4228",
            enumValue: "422826",
            enumName: "咸丰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 107,
            enumNameother: "咸丰县",
          },
          {
            fldEnumId: 11348,
            enumGroupGuid: "1",
            parentEnumValue: "4228",
            enumValue: "422827",
            enumName: "来凤县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 108,
            enumNameother: "来凤县",
          },
          {
            fldEnumId: 11350,
            enumGroupGuid: "1",
            parentEnumValue: "4228",
            enumValue: "422828",
            enumName: "鹤峰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 109,
            enumNameother: "鹤峰县",
          },
        ],
      },
      {
        fldEnumId: 11352,
        enumGroupGuid: "1",
        parentEnumValue: "42",
        enumValue: "4290",
        enumName: "省直辖县级行政单位",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 13,
        enumNameother: "省直辖县级行政单位",
        children: [
          {
            fldEnumId: 16294,
            enumGroupGuid: "1",
            parentEnumValue: "4290",
            enumValue: "429000",
            enumName: "省直辖县级行政单位本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 11353,
            enumGroupGuid: "1",
            parentEnumValue: "4290",
            enumValue: "429004",
            enumName: "仙桃市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 110,
            enumNameother: "仙桃市",
          },
          {
            fldEnumId: 11355,
            enumGroupGuid: "1",
            parentEnumValue: "4290",
            enumValue: "429005",
            enumName: "潜江市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 111,
            enumNameother: "潜江市",
          },
          {
            fldEnumId: 11357,
            enumGroupGuid: "1",
            parentEnumValue: "4290",
            enumValue: "429006",
            enumName: "天门市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 112,
            enumNameother: "天门市",
          },
          {
            fldEnumId: 11359,
            enumGroupGuid: "1",
            parentEnumValue: "4290",
            enumValue: "429021",
            enumName: "神农架林区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 113,
            enumNameother: "神农架林区",
          },
        ],
      },
    ],
  },
  {
    enumValue: "43",
    enumName: "湖南省",
    children: [
      {
        fldEnumId: 11362,
        enumGroupGuid: "1",
        parentEnumValue: "43",
        enumValue: "4301",
        enumName: "长沙市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        enumNameother: "长沙市",
        children: [
          {
            fldEnumId: 11363,
            enumGroupGuid: "1",
            parentEnumValue: "4301",
            enumValue: "430100",
            enumName: "长沙市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
            enumNameother: "长沙市",
          },
          {
            fldEnumId: 11365,
            enumGroupGuid: "1",
            parentEnumValue: "4301",
            enumValue: "430102",
            enumName: "芙蓉区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "芙蓉区",
          },
          {
            fldEnumId: 11367,
            enumGroupGuid: "1",
            parentEnumValue: "4301",
            enumValue: "430103",
            enumName: "天心区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 2,
            enumNameother: "天心区",
          },
          {
            fldEnumId: 11369,
            enumGroupGuid: "1",
            parentEnumValue: "4301",
            enumValue: "430104",
            enumName: "岳麓区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 3,
            enumNameother: "岳麓区",
          },
          {
            fldEnumId: 11371,
            enumGroupGuid: "1",
            parentEnumValue: "4301",
            enumValue: "430105",
            enumName: "开福区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 4,
            enumNameother: "开福区",
          },
          {
            fldEnumId: 11373,
            enumGroupGuid: "1",
            parentEnumValue: "4301",
            enumValue: "430111",
            enumName: "雨花区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 5,
            enumNameother: "雨花区",
          },
          {
            fldEnumId: 11375,
            enumGroupGuid: "1",
            parentEnumValue: "4301",
            enumValue: "430121",
            enumName: "长沙县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 6,
            enumNameother: "长沙县",
          },
          {
            fldEnumId: 11377,
            enumGroupGuid: "1",
            parentEnumValue: "4301",
            enumValue: "430122",
            enumName: "望城县",
            enumLevel: 0,
            enumStatus: 2,
            useFrequency: 0,
            enumOrder: 7,
            enumNameother: "望城县",
          },
          {
            fldEnumId: 130038,
            enumGroupGuid: "1",
            parentEnumValue: "4301",
            enumValue: "430112",
            enumName: "望城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 8,
            enumNameother: "望城区",
          },
          {
            fldEnumId: 11379,
            enumGroupGuid: "1",
            parentEnumValue: "4301",
            enumValue: "430124",
            enumName: "宁乡县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 8,
            enumNameother: "宁乡县",
          },
          {
            fldEnumId: 11381,
            enumGroupGuid: "1",
            parentEnumValue: "4301",
            enumValue: "430181",
            enumName: "浏阳市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 9,
            enumNameother: "浏阳市",
          },
        ],
      },
      {
        fldEnumId: 11383,
        enumGroupGuid: "1",
        parentEnumValue: "43",
        enumValue: "4302",
        enumName: "株洲市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 1,
        enumNameother: "株洲市",
        children: [
          {
            fldEnumId: 11384,
            enumGroupGuid: "1",
            parentEnumValue: "4302",
            enumValue: "430200",
            enumName: "株洲市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 10,
            enumNameother: "株洲市",
          },
          {
            fldEnumId: 11386,
            enumGroupGuid: "1",
            parentEnumValue: "4302",
            enumValue: "430202",
            enumName: "荷塘区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 11,
            enumNameother: "荷塘区",
          },
          {
            fldEnumId: 11388,
            enumGroupGuid: "1",
            parentEnumValue: "4302",
            enumValue: "430203",
            enumName: "芦淞区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 12,
            enumNameother: "芦淞区",
          },
          {
            fldEnumId: 11390,
            enumGroupGuid: "1",
            parentEnumValue: "4302",
            enumValue: "430204",
            enumName: "石峰区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 13,
            enumNameother: "石峰区",
          },
          {
            fldEnumId: 11392,
            enumGroupGuid: "1",
            parentEnumValue: "4302",
            enumValue: "430211",
            enumName: "天元区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 14,
            enumNameother: "天元区",
          },
          {
            fldEnumId: 11394,
            enumGroupGuid: "1",
            parentEnumValue: "4302",
            enumValue: "430221",
            enumName: "株洲县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 15,
            enumNameother: "株洲县",
          },
          {
            fldEnumId: 11396,
            enumGroupGuid: "1",
            parentEnumValue: "4302",
            enumValue: "430223",
            enumName: "攸县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 16,
            enumNameother: "攸县",
          },
          {
            fldEnumId: 11398,
            enumGroupGuid: "1",
            parentEnumValue: "4302",
            enumValue: "430224",
            enumName: "茶陵县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 17,
            enumNameother: "茶陵县",
          },
          {
            fldEnumId: 11400,
            enumGroupGuid: "1",
            parentEnumValue: "4302",
            enumValue: "430225",
            enumName: "炎陵县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 18,
            enumNameother: "炎陵县",
          },
          {
            fldEnumId: 11402,
            enumGroupGuid: "1",
            parentEnumValue: "4302",
            enumValue: "430281",
            enumName: "醴陵市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 19,
            enumNameother: "醴陵市",
          },
        ],
      },
      {
        fldEnumId: 11404,
        enumGroupGuid: "1",
        parentEnumValue: "43",
        enumValue: "4303",
        enumName: "湘潭市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 2,
        enumNameother: "湘潭市",
        children: [
          {
            fldEnumId: 11405,
            enumGroupGuid: "1",
            parentEnumValue: "4303",
            enumValue: "430300",
            enumName: "湘潭市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 20,
            enumNameother: "湘潭市",
          },
          {
            fldEnumId: 11407,
            enumGroupGuid: "1",
            parentEnumValue: "4303",
            enumValue: "430302",
            enumName: "雨湖区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 21,
            enumNameother: "雨湖区",
          },
          {
            fldEnumId: 11409,
            enumGroupGuid: "1",
            parentEnumValue: "4303",
            enumValue: "430304",
            enumName: "岳塘区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 22,
            enumNameother: "岳塘区",
          },
          {
            fldEnumId: 11411,
            enumGroupGuid: "1",
            parentEnumValue: "4303",
            enumValue: "430321",
            enumName: "湘潭县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 23,
            enumNameother: "湘潭县",
          },
          {
            fldEnumId: 11413,
            enumGroupGuid: "1",
            parentEnumValue: "4303",
            enumValue: "430381",
            enumName: "湘乡市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 24,
            enumNameother: "湘乡市",
          },
          {
            fldEnumId: 11415,
            enumGroupGuid: "1",
            parentEnumValue: "4303",
            enumValue: "430382",
            enumName: "韶山市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 25,
            enumNameother: "韶山市",
          },
        ],
      },
      {
        fldEnumId: 11417,
        enumGroupGuid: "1",
        parentEnumValue: "43",
        enumValue: "4304",
        enumName: "衡阳市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 3,
        enumNameother: "衡阳市",
        children: [
          {
            fldEnumId: 11418,
            enumGroupGuid: "1",
            parentEnumValue: "4304",
            enumValue: "430400",
            enumName: "衡阳市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 26,
            enumNameother: "衡阳市",
          },
          {
            fldEnumId: 11420,
            enumGroupGuid: "1",
            parentEnumValue: "4304",
            enumValue: "430405",
            enumName: "珠晖区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 27,
            enumNameother: "珠晖区",
          },
          {
            fldEnumId: 11422,
            enumGroupGuid: "1",
            parentEnumValue: "4304",
            enumValue: "430406",
            enumName: "雁峰区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 28,
            enumNameother: "雁峰区",
          },
          {
            fldEnumId: 11424,
            enumGroupGuid: "1",
            parentEnumValue: "4304",
            enumValue: "430407",
            enumName: "石鼓区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 29,
            enumNameother: "石鼓区",
          },
          {
            fldEnumId: 11426,
            enumGroupGuid: "1",
            parentEnumValue: "4304",
            enumValue: "430408",
            enumName: "蒸湘区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 30,
            enumNameother: "蒸湘区",
          },
          {
            fldEnumId: 11428,
            enumGroupGuid: "1",
            parentEnumValue: "4304",
            enumValue: "430412",
            enumName: "南岳区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 31,
            enumNameother: "南岳区",
          },
          {
            fldEnumId: 11430,
            enumGroupGuid: "1",
            parentEnumValue: "4304",
            enumValue: "430421",
            enumName: "衡阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 32,
            enumNameother: "衡阳县",
          },
          {
            fldEnumId: 11432,
            enumGroupGuid: "1",
            parentEnumValue: "4304",
            enumValue: "430422",
            enumName: "衡南县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 33,
            enumNameother: "衡南县",
          },
          {
            fldEnumId: 11434,
            enumGroupGuid: "1",
            parentEnumValue: "4304",
            enumValue: "430423",
            enumName: "衡山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 34,
            enumNameother: "衡山县",
          },
          {
            fldEnumId: 11436,
            enumGroupGuid: "1",
            parentEnumValue: "4304",
            enumValue: "430424",
            enumName: "衡东县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 35,
            enumNameother: "衡东县",
          },
          {
            fldEnumId: 11438,
            enumGroupGuid: "1",
            parentEnumValue: "4304",
            enumValue: "430426",
            enumName: "祁东县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 36,
            enumNameother: "祁东县",
          },
          {
            fldEnumId: 11440,
            enumGroupGuid: "1",
            parentEnumValue: "4304",
            enumValue: "430481",
            enumName: "耒阳市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 37,
            enumNameother: "耒阳市",
          },
          {
            fldEnumId: 11442,
            enumGroupGuid: "1",
            parentEnumValue: "4304",
            enumValue: "430482",
            enumName: "常宁市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 38,
            enumNameother: "常宁市",
          },
        ],
      },
      {
        fldEnumId: 11444,
        enumGroupGuid: "1",
        parentEnumValue: "43",
        enumValue: "4305",
        enumName: "邵阳市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 4,
        enumNameother: "邵阳市",
        children: [
          {
            fldEnumId: 11445,
            enumGroupGuid: "1",
            parentEnumValue: "4305",
            enumValue: "430500",
            enumName: "邵阳市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 39,
            enumNameother: "邵阳市",
          },
          {
            fldEnumId: 11447,
            enumGroupGuid: "1",
            parentEnumValue: "4305",
            enumValue: "430502",
            enumName: "双清区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 40,
            enumNameother: "双清区",
          },
          {
            fldEnumId: 11449,
            enumGroupGuid: "1",
            parentEnumValue: "4305",
            enumValue: "430503",
            enumName: "大祥区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 41,
            enumNameother: "大祥区",
          },
          {
            fldEnumId: 11451,
            enumGroupGuid: "1",
            parentEnumValue: "4305",
            enumValue: "430511",
            enumName: "北塔区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 42,
            enumNameother: "北塔区",
          },
          {
            fldEnumId: 11453,
            enumGroupGuid: "1",
            parentEnumValue: "4305",
            enumValue: "430521",
            enumName: "邵东县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 43,
            enumNameother: "邵东县",
          },
          {
            fldEnumId: 11455,
            enumGroupGuid: "1",
            parentEnumValue: "4305",
            enumValue: "430522",
            enumName: "新邵县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 44,
            enumNameother: "新邵县",
          },
          {
            fldEnumId: 11457,
            enumGroupGuid: "1",
            parentEnumValue: "4305",
            enumValue: "430523",
            enumName: "邵阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 45,
            enumNameother: "邵阳县",
          },
          {
            fldEnumId: 11459,
            enumGroupGuid: "1",
            parentEnumValue: "4305",
            enumValue: "430524",
            enumName: "隆回县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 46,
            enumNameother: "隆回县",
          },
          {
            fldEnumId: 11461,
            enumGroupGuid: "1",
            parentEnumValue: "4305",
            enumValue: "430525",
            enumName: "洞口县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 47,
            enumNameother: "洞口县",
          },
          {
            fldEnumId: 11463,
            enumGroupGuid: "1",
            parentEnumValue: "4305",
            enumValue: "430527",
            enumName: "绥宁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 48,
            enumNameother: "绥宁县",
          },
          {
            fldEnumId: 11465,
            enumGroupGuid: "1",
            parentEnumValue: "4305",
            enumValue: "430528",
            enumName: "新宁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 49,
            enumNameother: "新宁县",
          },
          {
            fldEnumId: 11467,
            enumGroupGuid: "1",
            parentEnumValue: "4305",
            enumValue: "430529",
            enumName: "城步苗族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 50,
            enumNameother: "城步苗族自治县",
          },
          {
            fldEnumId: 11469,
            enumGroupGuid: "1",
            parentEnumValue: "4305",
            enumValue: "430581",
            enumName: "武冈市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 51,
            enumNameother: "武冈市",
          },
        ],
      },
      {
        fldEnumId: 11471,
        enumGroupGuid: "1",
        parentEnumValue: "43",
        enumValue: "4306",
        enumName: "岳阳市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 5,
        enumNameother: "岳阳市",
        children: [
          {
            fldEnumId: 130333,
            enumGroupGuid: "1",
            parentEnumValue: "4306",
            enumValue: "430671",
            enumName: "岳阳市屈原管理区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 11472,
            enumGroupGuid: "1",
            parentEnumValue: "4306",
            enumValue: "430600",
            enumName: "岳阳市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 52,
            enumNameother: "岳阳市",
          },
          {
            fldEnumId: 11474,
            enumGroupGuid: "1",
            parentEnumValue: "4306",
            enumValue: "430602",
            enumName: "岳阳楼区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 53,
            enumNameother: "岳阳楼区",
          },
          {
            fldEnumId: 11476,
            enumGroupGuid: "1",
            parentEnumValue: "4306",
            enumValue: "430603",
            enumName: "云溪区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 54,
            enumNameother: "云溪区",
          },
          {
            fldEnumId: 11478,
            enumGroupGuid: "1",
            parentEnumValue: "4306",
            enumValue: "430611",
            enumName: "君山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 55,
            enumNameother: "君山区",
          },
          {
            fldEnumId: 11480,
            enumGroupGuid: "1",
            parentEnumValue: "4306",
            enumValue: "430621",
            enumName: "岳阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 56,
            enumNameother: "岳阳县",
          },
          {
            fldEnumId: 11482,
            enumGroupGuid: "1",
            parentEnumValue: "4306",
            enumValue: "430623",
            enumName: "华容县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 57,
            enumNameother: "华容县",
          },
          {
            fldEnumId: 11484,
            enumGroupGuid: "1",
            parentEnumValue: "4306",
            enumValue: "430624",
            enumName: "湘阴县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 58,
            enumNameother: "湘阴县",
          },
          {
            fldEnumId: 11486,
            enumGroupGuid: "1",
            parentEnumValue: "4306",
            enumValue: "430626",
            enumName: "平江县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 59,
            enumNameother: "平江县",
          },
          {
            fldEnumId: 11488,
            enumGroupGuid: "1",
            parentEnumValue: "4306",
            enumValue: "430681",
            enumName: "汨罗市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 60,
            enumNameother: "汨罗市",
          },
          {
            fldEnumId: 11490,
            enumGroupGuid: "1",
            parentEnumValue: "4306",
            enumValue: "430682",
            enumName: "临湘市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 61,
            enumNameother: "临湘市",
          },
        ],
      },
      {
        fldEnumId: 11492,
        enumGroupGuid: "1",
        parentEnumValue: "43",
        enumValue: "4307",
        enumName: "常德市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 6,
        enumNameother: "常德市",
        children: [
          {
            fldEnumId: 11493,
            enumGroupGuid: "1",
            parentEnumValue: "4307",
            enumValue: "430700",
            enumName: "常德市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 62,
            enumNameother: "常德市",
          },
          {
            fldEnumId: 11495,
            enumGroupGuid: "1",
            parentEnumValue: "4307",
            enumValue: "430702",
            enumName: "武陵区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 63,
            enumNameother: "武陵区",
          },
          {
            fldEnumId: 11497,
            enumGroupGuid: "1",
            parentEnumValue: "4307",
            enumValue: "430703",
            enumName: "鼎城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 64,
            enumNameother: "鼎城区",
          },
          {
            fldEnumId: 11499,
            enumGroupGuid: "1",
            parentEnumValue: "4307",
            enumValue: "430721",
            enumName: "安乡县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 65,
            enumNameother: "安乡县",
          },
          {
            fldEnumId: 11501,
            enumGroupGuid: "1",
            parentEnumValue: "4307",
            enumValue: "430722",
            enumName: "汉寿县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 66,
            enumNameother: "汉寿县",
          },
          {
            fldEnumId: 11503,
            enumGroupGuid: "1",
            parentEnumValue: "4307",
            enumValue: "430723",
            enumName: "澧县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 67,
            enumNameother: "澧县",
          },
          {
            fldEnumId: 11505,
            enumGroupGuid: "1",
            parentEnumValue: "4307",
            enumValue: "430724",
            enumName: "临澧县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 68,
            enumNameother: "临澧县",
          },
          {
            fldEnumId: 11507,
            enumGroupGuid: "1",
            parentEnumValue: "4307",
            enumValue: "430725",
            enumName: "桃源县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 69,
            enumNameother: "桃源县",
          },
          {
            fldEnumId: 11509,
            enumGroupGuid: "1",
            parentEnumValue: "4307",
            enumValue: "430726",
            enumName: "石门县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 70,
            enumNameother: "石门县",
          },
          {
            fldEnumId: 11511,
            enumGroupGuid: "1",
            parentEnumValue: "4307",
            enumValue: "430781",
            enumName: "津市市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 71,
            enumNameother: "津市市",
          },
        ],
      },
      {
        fldEnumId: 11513,
        enumGroupGuid: "1",
        parentEnumValue: "43",
        enumValue: "4308",
        enumName: "张家界市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 7,
        enumNameother: "张家界市",
        children: [
          {
            fldEnumId: 11514,
            enumGroupGuid: "1",
            parentEnumValue: "4308",
            enumValue: "430800",
            enumName: "张家界市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 72,
            enumNameother: "张家界市",
          },
          {
            fldEnumId: 11516,
            enumGroupGuid: "1",
            parentEnumValue: "4308",
            enumValue: "430802",
            enumName: "永定区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 73,
            enumNameother: "永定区",
          },
          {
            fldEnumId: 11518,
            enumGroupGuid: "1",
            parentEnumValue: "4308",
            enumValue: "430811",
            enumName: "武陵源区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 74,
            enumNameother: "武陵源区",
          },
          {
            fldEnumId: 11520,
            enumGroupGuid: "1",
            parentEnumValue: "4308",
            enumValue: "430821",
            enumName: "慈利县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 75,
            enumNameother: "慈利县",
          },
          {
            fldEnumId: 11522,
            enumGroupGuid: "1",
            parentEnumValue: "4308",
            enumValue: "430822",
            enumName: "桑植县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 76,
            enumNameother: "桑植县",
          },
        ],
      },
      {
        fldEnumId: 11524,
        enumGroupGuid: "1",
        parentEnumValue: "43",
        enumValue: "4309",
        enumName: "益阳市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 8,
        enumNameother: "益阳市",
        children: [
          {
            fldEnumId: 130362,
            enumGroupGuid: "1",
            parentEnumValue: "4309",
            enumValue: "430971",
            enumName: "益阳市大通湖管理区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 11525,
            enumGroupGuid: "1",
            parentEnumValue: "4309",
            enumValue: "430900",
            enumName: "益阳市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 77,
            enumNameother: "益阳市",
          },
          {
            fldEnumId: 11527,
            enumGroupGuid: "1",
            parentEnumValue: "4309",
            enumValue: "430902",
            enumName: "资阳区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 78,
            enumNameother: "资阳区",
          },
          {
            fldEnumId: 11529,
            enumGroupGuid: "1",
            parentEnumValue: "4309",
            enumValue: "430903",
            enumName: "赫山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 79,
            enumNameother: "赫山区",
          },
          {
            fldEnumId: 11531,
            enumGroupGuid: "1",
            parentEnumValue: "4309",
            enumValue: "430921",
            enumName: "南县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 80,
            enumNameother: "南县",
          },
          {
            fldEnumId: 11533,
            enumGroupGuid: "1",
            parentEnumValue: "4309",
            enumValue: "430922",
            enumName: "桃江县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 81,
            enumNameother: "桃江县",
          },
          {
            fldEnumId: 11535,
            enumGroupGuid: "1",
            parentEnumValue: "4309",
            enumValue: "430923",
            enumName: "安化县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 82,
            enumNameother: "安化县",
          },
          {
            fldEnumId: 11537,
            enumGroupGuid: "1",
            parentEnumValue: "4309",
            enumValue: "430981",
            enumName: "沅江市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 83,
            enumNameother: "沅江市",
          },
        ],
      },
      {
        fldEnumId: 11539,
        enumGroupGuid: "1",
        parentEnumValue: "43",
        enumValue: "4310",
        enumName: "郴州市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 9,
        enumNameother: "郴州市",
        children: [
          {
            fldEnumId: 11540,
            enumGroupGuid: "1",
            parentEnumValue: "4310",
            enumValue: "431000",
            enumName: "郴州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 84,
            enumNameother: "郴州市",
          },
          {
            fldEnumId: 11542,
            enumGroupGuid: "1",
            parentEnumValue: "4310",
            enumValue: "431002",
            enumName: "北湖区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 85,
            enumNameother: "北湖区",
          },
          {
            fldEnumId: 11544,
            enumGroupGuid: "1",
            parentEnumValue: "4310",
            enumValue: "431003",
            enumName: "苏仙区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 86,
            enumNameother: "苏仙区",
          },
          {
            fldEnumId: 11546,
            enumGroupGuid: "1",
            parentEnumValue: "4310",
            enumValue: "431021",
            enumName: "桂阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 87,
            enumNameother: "桂阳县",
          },
          {
            fldEnumId: 11548,
            enumGroupGuid: "1",
            parentEnumValue: "4310",
            enumValue: "431022",
            enumName: "宜章县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 88,
            enumNameother: "宜章县",
          },
          {
            fldEnumId: 11550,
            enumGroupGuid: "1",
            parentEnumValue: "4310",
            enumValue: "431023",
            enumName: "永兴县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 89,
            enumNameother: "永兴县",
          },
          {
            fldEnumId: 11552,
            enumGroupGuid: "1",
            parentEnumValue: "4310",
            enumValue: "431024",
            enumName: "嘉禾县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 90,
            enumNameother: "嘉禾县",
          },
          {
            fldEnumId: 11554,
            enumGroupGuid: "1",
            parentEnumValue: "4310",
            enumValue: "431025",
            enumName: "临武县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 91,
            enumNameother: "临武县",
          },
          {
            fldEnumId: 11556,
            enumGroupGuid: "1",
            parentEnumValue: "4310",
            enumValue: "431026",
            enumName: "汝城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 92,
            enumNameother: "汝城县",
          },
          {
            fldEnumId: 11558,
            enumGroupGuid: "1",
            parentEnumValue: "4310",
            enumValue: "431027",
            enumName: "桂东县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 93,
            enumNameother: "桂东县",
          },
          {
            fldEnumId: 11560,
            enumGroupGuid: "1",
            parentEnumValue: "4310",
            enumValue: "431028",
            enumName: "安仁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 94,
            enumNameother: "安仁县",
          },
          {
            fldEnumId: 11562,
            enumGroupGuid: "1",
            parentEnumValue: "4310",
            enumValue: "431081",
            enumName: "资兴市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 95,
            enumNameother: "资兴市",
          },
        ],
      },
      {
        fldEnumId: 11564,
        enumGroupGuid: "1",
        parentEnumValue: "43",
        enumValue: "4311",
        enumName: "永州市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 10,
        enumNameother: "永州市",
        children: [
          {
            fldEnumId: 11565,
            enumGroupGuid: "1",
            parentEnumValue: "4311",
            enumValue: "431100",
            enumName: "永州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 96,
            enumNameother: "永州市",
          },
          {
            fldEnumId: 11567,
            enumGroupGuid: "1",
            parentEnumValue: "4311",
            enumValue: "431102",
            enumName: "零陵区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 97,
            enumNameother: "零陵区",
          },
          {
            fldEnumId: 11569,
            enumGroupGuid: "1",
            parentEnumValue: "4311",
            enumValue: "431103",
            enumName: "冷水滩区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 98,
            enumNameother: "冷水滩区",
          },
          {
            fldEnumId: 11571,
            enumGroupGuid: "1",
            parentEnumValue: "4311",
            enumValue: "431121",
            enumName: "祁阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 99,
            enumNameother: "祁阳县",
          },
          {
            fldEnumId: 11573,
            enumGroupGuid: "1",
            parentEnumValue: "4311",
            enumValue: "431122",
            enumName: "东安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 100,
            enumNameother: "东安县",
          },
          {
            fldEnumId: 11575,
            enumGroupGuid: "1",
            parentEnumValue: "4311",
            enumValue: "431123",
            enumName: "双牌县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 101,
            enumNameother: "双牌县",
          },
          {
            fldEnumId: 11577,
            enumGroupGuid: "1",
            parentEnumValue: "4311",
            enumValue: "431124",
            enumName: "道县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 102,
            enumNameother: "道县",
          },
          {
            fldEnumId: 11579,
            enumGroupGuid: "1",
            parentEnumValue: "4311",
            enumValue: "431125",
            enumName: "江永县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 103,
            enumNameother: "江永县",
          },
          {
            fldEnumId: 11581,
            enumGroupGuid: "1",
            parentEnumValue: "4311",
            enumValue: "431126",
            enumName: "宁远县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 104,
            enumNameother: "宁远县",
          },
          {
            fldEnumId: 11583,
            enumGroupGuid: "1",
            parentEnumValue: "4311",
            enumValue: "431127",
            enumName: "蓝山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 105,
            enumNameother: "蓝山县",
          },
          {
            fldEnumId: 11585,
            enumGroupGuid: "1",
            parentEnumValue: "4311",
            enumValue: "431128",
            enumName: "新田县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 106,
            enumNameother: "新田县",
          },
          {
            fldEnumId: 11587,
            enumGroupGuid: "1",
            parentEnumValue: "4311",
            enumValue: "431129",
            enumName: "江华瑶族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 107,
            enumNameother: "江华瑶族自治县",
          },
        ],
      },
      {
        fldEnumId: 11589,
        enumGroupGuid: "1",
        parentEnumValue: "43",
        enumValue: "4312",
        enumName: "怀化市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 11,
        enumNameother: "怀化市",
        children: [
          {
            fldEnumId: 130360,
            enumGroupGuid: "1",
            parentEnumValue: "4312",
            enumValue: "431271",
            enumName: "怀化市洪江管理区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 11590,
            enumGroupGuid: "1",
            parentEnumValue: "4312",
            enumValue: "431200",
            enumName: "怀化市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 108,
            enumNameother: "怀化市",
          },
          {
            fldEnumId: 11592,
            enumGroupGuid: "1",
            parentEnumValue: "4312",
            enumValue: "431202",
            enumName: "鹤城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 109,
            enumNameother: "鹤城区",
          },
          {
            fldEnumId: 11594,
            enumGroupGuid: "1",
            parentEnumValue: "4312",
            enumValue: "431221",
            enumName: "中方县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 110,
            enumNameother: "中方县",
          },
          {
            fldEnumId: 11596,
            enumGroupGuid: "1",
            parentEnumValue: "4312",
            enumValue: "431222",
            enumName: "沅陵县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 111,
            enumNameother: "沅陵县",
          },
          {
            fldEnumId: 11598,
            enumGroupGuid: "1",
            parentEnumValue: "4312",
            enumValue: "431223",
            enumName: "辰溪县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 112,
            enumNameother: "辰溪县",
          },
          {
            fldEnumId: 11600,
            enumGroupGuid: "1",
            parentEnumValue: "4312",
            enumValue: "431224",
            enumName: "溆浦县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 113,
            enumNameother: "溆浦县",
          },
          {
            fldEnumId: 11602,
            enumGroupGuid: "1",
            parentEnumValue: "4312",
            enumValue: "431225",
            enumName: "会同县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 114,
            enumNameother: "会同县",
          },
          {
            fldEnumId: 11604,
            enumGroupGuid: "1",
            parentEnumValue: "4312",
            enumValue: "431226",
            enumName: "麻阳苗族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 115,
            enumNameother: "麻阳苗族自治县",
          },
          {
            fldEnumId: 11606,
            enumGroupGuid: "1",
            parentEnumValue: "4312",
            enumValue: "431227",
            enumName: "新晃侗族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 116,
            enumNameother: "新晃侗族自治县",
          },
          {
            fldEnumId: 11608,
            enumGroupGuid: "1",
            parentEnumValue: "4312",
            enumValue: "431228",
            enumName: "芷江侗族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 117,
            enumNameother: "芷江侗族自治县",
          },
          {
            fldEnumId: 11610,
            enumGroupGuid: "1",
            parentEnumValue: "4312",
            enumValue: "431229",
            enumName: "靖州苗族侗族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 118,
            enumNameother: "靖州苗族侗族自治县",
          },
          {
            fldEnumId: 11612,
            enumGroupGuid: "1",
            parentEnumValue: "4312",
            enumValue: "431230",
            enumName: "通道侗族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 119,
            enumNameother: "通道侗族自治县",
          },
          {
            fldEnumId: 11614,
            enumGroupGuid: "1",
            parentEnumValue: "4312",
            enumValue: "431281",
            enumName: "洪江市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 120,
            enumNameother: "洪江市",
          },
        ],
      },
      {
        fldEnumId: 11616,
        enumGroupGuid: "1",
        parentEnumValue: "43",
        enumValue: "4313",
        enumName: "娄底市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 12,
        enumNameother: "娄底市",
        children: [
          {
            fldEnumId: 11617,
            enumGroupGuid: "1",
            parentEnumValue: "4313",
            enumValue: "431300",
            enumName: "娄底市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 121,
            enumNameother: "娄底市",
          },
          {
            fldEnumId: 11619,
            enumGroupGuid: "1",
            parentEnumValue: "4313",
            enumValue: "431302",
            enumName: "娄星区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 122,
            enumNameother: "娄星区",
          },
          {
            fldEnumId: 11621,
            enumGroupGuid: "1",
            parentEnumValue: "4313",
            enumValue: "431321",
            enumName: "双峰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 123,
            enumNameother: "双峰县",
          },
          {
            fldEnumId: 11623,
            enumGroupGuid: "1",
            parentEnumValue: "4313",
            enumValue: "431322",
            enumName: "新化县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 124,
            enumNameother: "新化县",
          },
          {
            fldEnumId: 11625,
            enumGroupGuid: "1",
            parentEnumValue: "4313",
            enumValue: "431381",
            enumName: "冷水江市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 125,
            enumNameother: "冷水江市",
          },
          {
            fldEnumId: 11627,
            enumGroupGuid: "1",
            parentEnumValue: "4313",
            enumValue: "431382",
            enumName: "涟源市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 126,
            enumNameother: "涟源市",
          },
        ],
      },
      {
        fldEnumId: 11629,
        enumGroupGuid: "1",
        parentEnumValue: "43",
        enumValue: "4331",
        enumName: "湘西土家族苗族自治州",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 13,
        enumNameother: "湘西土家族苗族自治州",
        children: [
          {
            fldEnumId: 16254,
            enumGroupGuid: "1",
            parentEnumValue: "4331",
            enumValue: "433100",
            enumName: "湘西土家族苗族自治州本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 11630,
            enumGroupGuid: "1",
            parentEnumValue: "4331",
            enumValue: "433101",
            enumName: "吉首市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 127,
            enumNameother: "吉首市",
          },
          {
            fldEnumId: 11632,
            enumGroupGuid: "1",
            parentEnumValue: "4331",
            enumValue: "433122",
            enumName: "泸溪县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 128,
            enumNameother: "泸溪县",
          },
          {
            fldEnumId: 11634,
            enumGroupGuid: "1",
            parentEnumValue: "4331",
            enumValue: "433123",
            enumName: "凤凰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 129,
            enumNameother: "凤凰县",
          },
          {
            fldEnumId: 11636,
            enumGroupGuid: "1",
            parentEnumValue: "4331",
            enumValue: "433124",
            enumName: "花垣县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 130,
            enumNameother: "花垣县",
          },
          {
            fldEnumId: 11638,
            enumGroupGuid: "1",
            parentEnumValue: "4331",
            enumValue: "433125",
            enumName: "保靖县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 131,
            enumNameother: "保靖县",
          },
          {
            fldEnumId: 11640,
            enumGroupGuid: "1",
            parentEnumValue: "4331",
            enumValue: "433126",
            enumName: "古丈县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 132,
            enumNameother: "古丈县",
          },
          {
            fldEnumId: 11642,
            enumGroupGuid: "1",
            parentEnumValue: "4331",
            enumValue: "433127",
            enumName: "永顺县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 133,
            enumNameother: "永顺县",
          },
          {
            fldEnumId: 11644,
            enumGroupGuid: "1",
            parentEnumValue: "4331",
            enumValue: "433130",
            enumName: "龙山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 134,
            enumNameother: "龙山县",
          },
        ],
      },
    ],
  },
  {
    enumValue: "44",
    enumName: "广东省",
    children: [
      {
        fldEnumId: 11647,
        enumGroupGuid: "1",
        parentEnumValue: "44",
        enumValue: "4401",
        enumName: "广州市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        enumNameother: "广州市",
        children: [
          {
            fldEnumId: 11648,
            enumGroupGuid: "1",
            parentEnumValue: "4401",
            enumValue: "440100",
            enumName: "广州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
            enumNameother: "广州市",
          },
          {
            fldEnumId: 11650,
            enumGroupGuid: "1",
            parentEnumValue: "4401",
            enumValue: "440103",
            enumName: "荔湾区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "荔湾区",
          },
          {
            fldEnumId: 11652,
            enumGroupGuid: "1",
            parentEnumValue: "4401",
            enumValue: "440104",
            enumName: "越秀区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 2,
            enumNameother: "越秀区",
          },
          {
            fldEnumId: 11654,
            enumGroupGuid: "1",
            parentEnumValue: "4401",
            enumValue: "440105",
            enumName: "海珠区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 3,
            enumNameother: "海珠区",
          },
          {
            fldEnumId: 11656,
            enumGroupGuid: "1",
            parentEnumValue: "4401",
            enumValue: "440106",
            enumName: "天河区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 4,
            enumNameother: "天河区",
          },
          {
            fldEnumId: 11658,
            enumGroupGuid: "1",
            parentEnumValue: "4401",
            enumValue: "440111",
            enumName: "白云区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 5,
            enumNameother: "白云区",
          },
          {
            fldEnumId: 11660,
            enumGroupGuid: "1",
            parentEnumValue: "4401",
            enumValue: "440112",
            enumName: "黄埔区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 6,
            enumNameother: "黄埔区",
          },
          {
            fldEnumId: 11662,
            enumGroupGuid: "1",
            parentEnumValue: "4401",
            enumValue: "440113",
            enumName: "番禺区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 7,
            enumNameother: "番禺区",
          },
          {
            fldEnumId: 11664,
            enumGroupGuid: "1",
            parentEnumValue: "4401",
            enumValue: "440114",
            enumName: "花都区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 8,
            enumNameother: "花都区",
          },
          {
            fldEnumId: 11666,
            enumGroupGuid: "1",
            parentEnumValue: "4401",
            enumValue: "440115",
            enumName: "南沙区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 9,
            enumNameother: "南沙区",
          },
          {
            fldEnumId: 11668,
            enumGroupGuid: "1",
            parentEnumValue: "4401",
            enumValue: "440116",
            enumName: "萝岗区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 10,
            enumNameother: "萝岗区",
          },
          {
            fldEnumId: 11670,
            enumGroupGuid: "1",
            parentEnumValue: "4401",
            enumValue: "440183",
            enumName: "增城市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 11,
            enumNameother: "增城市",
          },
          {
            fldEnumId: 11672,
            enumGroupGuid: "1",
            parentEnumValue: "4401",
            enumValue: "440184",
            enumName: "从化市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 12,
            enumNameother: "从化市",
          },
        ],
      },
      {
        fldEnumId: 11674,
        enumGroupGuid: "1",
        parentEnumValue: "44",
        enumValue: "4402",
        enumName: "韶关市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 1,
        enumNameother: "韶关市",
        children: [
          {
            fldEnumId: 11675,
            enumGroupGuid: "1",
            parentEnumValue: "4402",
            enumValue: "440200",
            enumName: "韶关市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 13,
            enumNameother: "韶关市",
          },
          {
            fldEnumId: 11677,
            enumGroupGuid: "1",
            parentEnumValue: "4402",
            enumValue: "440203",
            enumName: "武江区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 14,
            enumNameother: "武江区",
          },
          {
            fldEnumId: 11679,
            enumGroupGuid: "1",
            parentEnumValue: "4402",
            enumValue: "440204",
            enumName: "浈江区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 15,
            enumNameother: "浈江区",
          },
          {
            fldEnumId: 11681,
            enumGroupGuid: "1",
            parentEnumValue: "4402",
            enumValue: "440205",
            enumName: "曲江区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 16,
            enumNameother: "曲江区",
          },
          {
            fldEnumId: 11683,
            enumGroupGuid: "1",
            parentEnumValue: "4402",
            enumValue: "440222",
            enumName: "始兴县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 17,
            enumNameother: "始兴县",
          },
          {
            fldEnumId: 11685,
            enumGroupGuid: "1",
            parentEnumValue: "4402",
            enumValue: "440224",
            enumName: "仁化县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 18,
            enumNameother: "仁化县",
          },
          {
            fldEnumId: 11687,
            enumGroupGuid: "1",
            parentEnumValue: "4402",
            enumValue: "440229",
            enumName: "翁源县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 19,
            enumNameother: "翁源县",
          },
          {
            fldEnumId: 11689,
            enumGroupGuid: "1",
            parentEnumValue: "4402",
            enumValue: "440232",
            enumName: "乳源瑶族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 20,
            enumNameother: "乳源瑶族自治县",
          },
          {
            fldEnumId: 11691,
            enumGroupGuid: "1",
            parentEnumValue: "4402",
            enumValue: "440233",
            enumName: "新丰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 21,
            enumNameother: "新丰县",
          },
          {
            fldEnumId: 11693,
            enumGroupGuid: "1",
            parentEnumValue: "4402",
            enumValue: "440281",
            enumName: "乐昌市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 22,
            enumNameother: "乐昌市",
          },
          {
            fldEnumId: 11695,
            enumGroupGuid: "1",
            parentEnumValue: "4402",
            enumValue: "440282",
            enumName: "南雄市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 23,
            enumNameother: "南雄市",
          },
        ],
      },
      {
        fldEnumId: 11697,
        enumGroupGuid: "1",
        parentEnumValue: "44",
        enumValue: "4403",
        enumName: "深圳市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 2,
        enumNameother: "深圳市",
        children: [
          {
            fldEnumId: 130198,
            enumGroupGuid: "1",
            parentEnumValue: "4403",
            enumValue: "440309",
            enumName: "龙华区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130199,
            enumGroupGuid: "1",
            parentEnumValue: "4403",
            enumValue: "440310",
            enumName: "坪山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130340,
            enumGroupGuid: "1",
            parentEnumValue: "4403",
            enumValue: "440311",
            enumName: "光明区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 11698,
            enumGroupGuid: "1",
            parentEnumValue: "4403",
            enumValue: "440300",
            enumName: "深圳市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 24,
            enumNameother: "深圳市",
          },
          {
            fldEnumId: 11700,
            enumGroupGuid: "1",
            parentEnumValue: "4403",
            enumValue: "440303",
            enumName: "罗湖区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 25,
            enumNameother: "罗湖区",
          },
          {
            fldEnumId: 11702,
            enumGroupGuid: "1",
            parentEnumValue: "4403",
            enumValue: "440304",
            enumName: "福田区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 26,
            enumNameother: "福田区",
          },
          {
            fldEnumId: 11704,
            enumGroupGuid: "1",
            parentEnumValue: "4403",
            enumValue: "440305",
            enumName: "南山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 27,
            enumNameother: "南山区",
          },
          {
            fldEnumId: 11706,
            enumGroupGuid: "1",
            parentEnumValue: "4403",
            enumValue: "440306",
            enumName: "宝安区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 28,
            enumNameother: "宝安区",
          },
          {
            fldEnumId: 11708,
            enumGroupGuid: "1",
            parentEnumValue: "4403",
            enumValue: "440307",
            enumName: "龙岗区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 29,
            enumNameother: "龙岗区",
          },
          {
            fldEnumId: 11710,
            enumGroupGuid: "1",
            parentEnumValue: "4403",
            enumValue: "440308",
            enumName: "盐田区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 30,
            enumNameother: "盐田区",
          },
        ],
      },
      {
        fldEnumId: 11712,
        enumGroupGuid: "1",
        parentEnumValue: "44",
        enumValue: "4404",
        enumName: "珠海市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 3,
        enumNameother: "珠海市",
        children: [
          {
            fldEnumId: 11713,
            enumGroupGuid: "1",
            parentEnumValue: "4404",
            enumValue: "440400",
            enumName: "珠海市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 31,
            enumNameother: "珠海市",
          },
          {
            fldEnumId: 11715,
            enumGroupGuid: "1",
            parentEnumValue: "4404",
            enumValue: "440402",
            enumName: "香洲区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 32,
            enumNameother: "香洲区",
          },
          {
            fldEnumId: 11717,
            enumGroupGuid: "1",
            parentEnumValue: "4404",
            enumValue: "440403",
            enumName: "斗门区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 33,
            enumNameother: "斗门区",
          },
          {
            fldEnumId: 11719,
            enumGroupGuid: "1",
            parentEnumValue: "4404",
            enumValue: "440404",
            enumName: "金湾区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 34,
            enumNameother: "金湾区",
          },
        ],
      },
      {
        fldEnumId: 11721,
        enumGroupGuid: "1",
        parentEnumValue: "44",
        enumValue: "4405",
        enumName: "汕头市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 4,
        enumNameother: "汕头市",
        children: [
          {
            fldEnumId: 11722,
            enumGroupGuid: "1",
            parentEnumValue: "4405",
            enumValue: "440500",
            enumName: "汕头市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 35,
            enumNameother: "汕头市",
          },
          {
            fldEnumId: 11724,
            enumGroupGuid: "1",
            parentEnumValue: "4405",
            enumValue: "440507",
            enumName: "龙湖区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 36,
            enumNameother: "龙湖区",
          },
          {
            fldEnumId: 11726,
            enumGroupGuid: "1",
            parentEnumValue: "4405",
            enumValue: "440511",
            enumName: "金平区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 37,
            enumNameother: "金平区",
          },
          {
            fldEnumId: 11728,
            enumGroupGuid: "1",
            parentEnumValue: "4405",
            enumValue: "440512",
            enumName: "濠江区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 38,
            enumNameother: "濠江区",
          },
          {
            fldEnumId: 11730,
            enumGroupGuid: "1",
            parentEnumValue: "4405",
            enumValue: "440513",
            enumName: "潮阳区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 39,
            enumNameother: "潮阳区",
          },
          {
            fldEnumId: 11732,
            enumGroupGuid: "1",
            parentEnumValue: "4405",
            enumValue: "440514",
            enumName: "潮南区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 40,
            enumNameother: "潮南区",
          },
          {
            fldEnumId: 11734,
            enumGroupGuid: "1",
            parentEnumValue: "4405",
            enumValue: "440515",
            enumName: "澄海区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 41,
            enumNameother: "澄海区",
          },
          {
            fldEnumId: 11736,
            enumGroupGuid: "1",
            parentEnumValue: "4405",
            enumValue: "440523",
            enumName: "南澳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 42,
            enumNameother: "南澳县",
          },
        ],
      },
      {
        fldEnumId: 11738,
        enumGroupGuid: "1",
        parentEnumValue: "44",
        enumValue: "4406",
        enumName: "佛山市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 5,
        enumNameother: "佛山市",
        children: [
          {
            fldEnumId: 11739,
            enumGroupGuid: "1",
            parentEnumValue: "4406",
            enumValue: "440600",
            enumName: "佛山市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 43,
            enumNameother: "佛山市",
          },
          {
            fldEnumId: 11741,
            enumGroupGuid: "1",
            parentEnumValue: "4406",
            enumValue: "440604",
            enumName: "禅城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 44,
            enumNameother: "禅城区",
          },
          {
            fldEnumId: 11743,
            enumGroupGuid: "1",
            parentEnumValue: "4406",
            enumValue: "440605",
            enumName: "南海区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 45,
            enumNameother: "南海区",
          },
          {
            fldEnumId: 11745,
            enumGroupGuid: "1",
            parentEnumValue: "4406",
            enumValue: "440606",
            enumName: "顺德区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 46,
            enumNameother: "顺德区",
          },
          {
            fldEnumId: 11747,
            enumGroupGuid: "1",
            parentEnumValue: "4406",
            enumValue: "440607",
            enumName: "三水区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 47,
            enumNameother: "三水区",
          },
          {
            fldEnumId: 11749,
            enumGroupGuid: "1",
            parentEnumValue: "4406",
            enumValue: "440608",
            enumName: "高明区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 48,
            enumNameother: "高明区",
          },
        ],
      },
      {
        fldEnumId: 11751,
        enumGroupGuid: "1",
        parentEnumValue: "44",
        enumValue: "4407",
        enumName: "江门市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 6,
        enumNameother: "江门市",
        children: [
          {
            fldEnumId: 11752,
            enumGroupGuid: "1",
            parentEnumValue: "4407",
            enumValue: "440700",
            enumName: "江门市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 49,
            enumNameother: "江门市",
          },
          {
            fldEnumId: 11754,
            enumGroupGuid: "1",
            parentEnumValue: "4407",
            enumValue: "440703",
            enumName: "蓬江区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 50,
            enumNameother: "蓬江区",
          },
          {
            fldEnumId: 11756,
            enumGroupGuid: "1",
            parentEnumValue: "4407",
            enumValue: "440704",
            enumName: "江海区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 51,
            enumNameother: "江海区",
          },
          {
            fldEnumId: 11758,
            enumGroupGuid: "1",
            parentEnumValue: "4407",
            enumValue: "440705",
            enumName: "新会区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 52,
            enumNameother: "新会区",
          },
          {
            fldEnumId: 11760,
            enumGroupGuid: "1",
            parentEnumValue: "4407",
            enumValue: "440781",
            enumName: "台山市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 53,
            enumNameother: "台山市",
          },
          {
            fldEnumId: 11762,
            enumGroupGuid: "1",
            parentEnumValue: "4407",
            enumValue: "440783",
            enumName: "开平市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 54,
            enumNameother: "开平市",
          },
          {
            fldEnumId: 11764,
            enumGroupGuid: "1",
            parentEnumValue: "4407",
            enumValue: "440784",
            enumName: "鹤山市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 55,
            enumNameother: "鹤山市",
          },
          {
            fldEnumId: 11766,
            enumGroupGuid: "1",
            parentEnumValue: "4407",
            enumValue: "440785",
            enumName: "恩平市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 56,
            enumNameother: "恩平市",
          },
        ],
      },
      {
        fldEnumId: 11768,
        enumGroupGuid: "1",
        parentEnumValue: "44",
        enumValue: "4408",
        enumName: "湛江市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 7,
        enumNameother: "湛江市",
        children: [
          {
            fldEnumId: 11769,
            enumGroupGuid: "1",
            parentEnumValue: "4408",
            enumValue: "440800",
            enumName: "湛江市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 57,
            enumNameother: "湛江市",
          },
          {
            fldEnumId: 11771,
            enumGroupGuid: "1",
            parentEnumValue: "4408",
            enumValue: "440802",
            enumName: "赤坎区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 58,
            enumNameother: "赤坎区",
          },
          {
            fldEnumId: 11773,
            enumGroupGuid: "1",
            parentEnumValue: "4408",
            enumValue: "440803",
            enumName: "霞山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 59,
            enumNameother: "霞山区",
          },
          {
            fldEnumId: 11775,
            enumGroupGuid: "1",
            parentEnumValue: "4408",
            enumValue: "440804",
            enumName: "坡头区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 60,
            enumNameother: "坡头区",
          },
          {
            fldEnumId: 11777,
            enumGroupGuid: "1",
            parentEnumValue: "4408",
            enumValue: "440811",
            enumName: "麻章区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 61,
            enumNameother: "麻章区",
          },
          {
            fldEnumId: 11779,
            enumGroupGuid: "1",
            parentEnumValue: "4408",
            enumValue: "440823",
            enumName: "遂溪县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 62,
            enumNameother: "遂溪县",
          },
          {
            fldEnumId: 11781,
            enumGroupGuid: "1",
            parentEnumValue: "4408",
            enumValue: "440825",
            enumName: "徐闻县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 63,
            enumNameother: "徐闻县",
          },
          {
            fldEnumId: 11783,
            enumGroupGuid: "1",
            parentEnumValue: "4408",
            enumValue: "440881",
            enumName: "廉江市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 64,
            enumNameother: "廉江市",
          },
          {
            fldEnumId: 11785,
            enumGroupGuid: "1",
            parentEnumValue: "4408",
            enumValue: "440882",
            enumName: "雷州市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 65,
            enumNameother: "雷州市",
          },
          {
            fldEnumId: 11787,
            enumGroupGuid: "1",
            parentEnumValue: "4408",
            enumValue: "440883",
            enumName: "吴川市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 66,
            enumNameother: "吴川市",
          },
        ],
      },
      {
        fldEnumId: 11789,
        enumGroupGuid: "1",
        parentEnumValue: "44",
        enumValue: "4409",
        enumName: "茂名市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 8,
        enumNameother: "茂名市",
        children: [
          {
            fldEnumId: 11790,
            enumGroupGuid: "1",
            parentEnumValue: "4409",
            enumValue: "440900",
            enumName: "茂名市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 67,
            enumNameother: "茂名市",
          },
          {
            fldEnumId: 11792,
            enumGroupGuid: "1",
            parentEnumValue: "4409",
            enumValue: "440902",
            enumName: "茂南区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 68,
            enumNameother: "茂南区",
          },
          {
            fldEnumId: 11794,
            enumGroupGuid: "1",
            parentEnumValue: "4409",
            enumValue: "440903",
            enumName: "茂港区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 69,
            enumNameother: "茂港区",
          },
          {
            fldEnumId: 11796,
            enumGroupGuid: "1",
            parentEnumValue: "4409",
            enumValue: "440923",
            enumName: "电白县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 70,
            enumNameother: "电白县",
          },
          {
            fldEnumId: 11798,
            enumGroupGuid: "1",
            parentEnumValue: "4409",
            enumValue: "440981",
            enumName: "高州市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 71,
            enumNameother: "高州市",
          },
          {
            fldEnumId: 11800,
            enumGroupGuid: "1",
            parentEnumValue: "4409",
            enumValue: "440982",
            enumName: "化州市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 72,
            enumNameother: "化州市",
          },
          {
            fldEnumId: 11802,
            enumGroupGuid: "1",
            parentEnumValue: "4409",
            enumValue: "440983",
            enumName: "信宜市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 73,
            enumNameother: "信宜市",
          },
        ],
      },
      {
        fldEnumId: 11804,
        enumGroupGuid: "1",
        parentEnumValue: "44",
        enumValue: "4412",
        enumName: "肇庆市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 9,
        enumNameother: "肇庆市",
        children: [
          {
            fldEnumId: 11805,
            enumGroupGuid: "1",
            parentEnumValue: "4412",
            enumValue: "441200",
            enumName: "肇庆市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 74,
            enumNameother: "肇庆市",
          },
          {
            fldEnumId: 11807,
            enumGroupGuid: "1",
            parentEnumValue: "4412",
            enumValue: "441202",
            enumName: "端州区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 75,
            enumNameother: "端州区",
          },
          {
            fldEnumId: 11809,
            enumGroupGuid: "1",
            parentEnumValue: "4412",
            enumValue: "441203",
            enumName: "鼎湖区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 76,
            enumNameother: "鼎湖区",
          },
          {
            fldEnumId: 11811,
            enumGroupGuid: "1",
            parentEnumValue: "4412",
            enumValue: "441223",
            enumName: "广宁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 77,
            enumNameother: "广宁县",
          },
          {
            fldEnumId: 11813,
            enumGroupGuid: "1",
            parentEnumValue: "4412",
            enumValue: "441224",
            enumName: "怀集县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 78,
            enumNameother: "怀集县",
          },
          {
            fldEnumId: 11815,
            enumGroupGuid: "1",
            parentEnumValue: "4412",
            enumValue: "441225",
            enumName: "封开县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 79,
            enumNameother: "封开县",
          },
          {
            fldEnumId: 11817,
            enumGroupGuid: "1",
            parentEnumValue: "4412",
            enumValue: "441226",
            enumName: "德庆县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 80,
            enumNameother: "德庆县",
          },
          {
            fldEnumId: 11819,
            enumGroupGuid: "1",
            parentEnumValue: "4412",
            enumValue: "441283",
            enumName: "高要市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 81,
            enumNameother: "高要市",
          },
          {
            fldEnumId: 11821,
            enumGroupGuid: "1",
            parentEnumValue: "4412",
            enumValue: "441284",
            enumName: "四会市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 82,
            enumNameother: "四会市",
          },
        ],
      },
      {
        fldEnumId: 11823,
        enumGroupGuid: "1",
        parentEnumValue: "44",
        enumValue: "4413",
        enumName: "惠州市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 10,
        enumNameother: "惠州市",
        children: [
          {
            fldEnumId: 11824,
            enumGroupGuid: "1",
            parentEnumValue: "4413",
            enumValue: "441300",
            enumName: "惠州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 83,
            enumNameother: "惠州市",
          },
          {
            fldEnumId: 11826,
            enumGroupGuid: "1",
            parentEnumValue: "4413",
            enumValue: "441302",
            enumName: "惠城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 84,
            enumNameother: "惠城区",
          },
          {
            fldEnumId: 11828,
            enumGroupGuid: "1",
            parentEnumValue: "4413",
            enumValue: "441303",
            enumName: "惠阳区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 85,
            enumNameother: "惠阳区",
          },
          {
            fldEnumId: 11830,
            enumGroupGuid: "1",
            parentEnumValue: "4413",
            enumValue: "441322",
            enumName: "博罗县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 86,
            enumNameother: "博罗县",
          },
          {
            fldEnumId: 11832,
            enumGroupGuid: "1",
            parentEnumValue: "4413",
            enumValue: "441323",
            enumName: "惠东县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 87,
            enumNameother: "惠东县",
          },
          {
            fldEnumId: 11834,
            enumGroupGuid: "1",
            parentEnumValue: "4413",
            enumValue: "441324",
            enumName: "龙门县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 88,
            enumNameother: "龙门县",
          },
        ],
      },
      {
        fldEnumId: 11836,
        enumGroupGuid: "1",
        parentEnumValue: "44",
        enumValue: "4414",
        enumName: "梅州市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 11,
        enumNameother: "梅州市",
        children: [
          {
            fldEnumId: 11837,
            enumGroupGuid: "1",
            parentEnumValue: "4414",
            enumValue: "441400",
            enumName: "梅州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 89,
            enumNameother: "梅州市",
          },
          {
            fldEnumId: 11839,
            enumGroupGuid: "1",
            parentEnumValue: "4414",
            enumValue: "441402",
            enumName: "梅江区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 90,
            enumNameother: "梅江区",
          },
          {
            fldEnumId: 11841,
            enumGroupGuid: "1",
            parentEnumValue: "4414",
            enumValue: "441421",
            enumName: "梅县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 91,
            enumNameother: "梅县",
          },
          {
            fldEnumId: 11843,
            enumGroupGuid: "1",
            parentEnumValue: "4414",
            enumValue: "441422",
            enumName: "大埔县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 92,
            enumNameother: "大埔县",
          },
          {
            fldEnumId: 11845,
            enumGroupGuid: "1",
            parentEnumValue: "4414",
            enumValue: "441423",
            enumName: "丰顺县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 93,
            enumNameother: "丰顺县",
          },
          {
            fldEnumId: 11847,
            enumGroupGuid: "1",
            parentEnumValue: "4414",
            enumValue: "441424",
            enumName: "五华县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 94,
            enumNameother: "五华县",
          },
          {
            fldEnumId: 11849,
            enumGroupGuid: "1",
            parentEnumValue: "4414",
            enumValue: "441426",
            enumName: "平远县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 95,
            enumNameother: "平远县",
          },
          {
            fldEnumId: 11851,
            enumGroupGuid: "1",
            parentEnumValue: "4414",
            enumValue: "441427",
            enumName: "蕉岭县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 96,
            enumNameother: "蕉岭县",
          },
          {
            fldEnumId: 11853,
            enumGroupGuid: "1",
            parentEnumValue: "4414",
            enumValue: "441481",
            enumName: "兴宁市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 97,
            enumNameother: "兴宁市",
          },
        ],
      },
      {
        fldEnumId: 11855,
        enumGroupGuid: "1",
        parentEnumValue: "44",
        enumValue: "4415",
        enumName: "汕尾市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 12,
        enumNameother: "汕尾市",
        children: [
          {
            fldEnumId: 11856,
            enumGroupGuid: "1",
            parentEnumValue: "4415",
            enumValue: "441500",
            enumName: "汕尾市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 98,
            enumNameother: "汕尾市",
          },
          {
            fldEnumId: 11858,
            enumGroupGuid: "1",
            parentEnumValue: "4415",
            enumValue: "441502",
            enumName: "城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 99,
            enumNameother: "城区",
          },
          {
            fldEnumId: 11860,
            enumGroupGuid: "1",
            parentEnumValue: "4415",
            enumValue: "441521",
            enumName: "海丰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 100,
            enumNameother: "海丰县",
          },
          {
            fldEnumId: 11862,
            enumGroupGuid: "1",
            parentEnumValue: "4415",
            enumValue: "441523",
            enumName: "陆河县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 101,
            enumNameother: "陆河县",
          },
          {
            fldEnumId: 11864,
            enumGroupGuid: "1",
            parentEnumValue: "4415",
            enumValue: "441581",
            enumName: "陆丰市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 102,
            enumNameother: "陆丰市",
          },
        ],
      },
      {
        fldEnumId: 11866,
        enumGroupGuid: "1",
        parentEnumValue: "44",
        enumValue: "4416",
        enumName: "河源市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 13,
        enumNameother: "河源市",
        children: [
          {
            fldEnumId: 11867,
            enumGroupGuid: "1",
            parentEnumValue: "4416",
            enumValue: "441600",
            enumName: "河源市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 103,
            enumNameother: "河源市",
          },
          {
            fldEnumId: 11869,
            enumGroupGuid: "1",
            parentEnumValue: "4416",
            enumValue: "441602",
            enumName: "源城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 104,
            enumNameother: "源城区",
          },
          {
            fldEnumId: 11871,
            enumGroupGuid: "1",
            parentEnumValue: "4416",
            enumValue: "441621",
            enumName: "紫金县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 105,
            enumNameother: "紫金县",
          },
          {
            fldEnumId: 11873,
            enumGroupGuid: "1",
            parentEnumValue: "4416",
            enumValue: "441622",
            enumName: "龙川县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 106,
            enumNameother: "龙川县",
          },
          {
            fldEnumId: 11875,
            enumGroupGuid: "1",
            parentEnumValue: "4416",
            enumValue: "441623",
            enumName: "连平县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 107,
            enumNameother: "连平县",
          },
          {
            fldEnumId: 11877,
            enumGroupGuid: "1",
            parentEnumValue: "4416",
            enumValue: "441624",
            enumName: "和平县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 108,
            enumNameother: "和平县",
          },
          {
            fldEnumId: 11879,
            enumGroupGuid: "1",
            parentEnumValue: "4416",
            enumValue: "441625",
            enumName: "东源县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 109,
            enumNameother: "东源县",
          },
        ],
      },
      {
        fldEnumId: 11881,
        enumGroupGuid: "1",
        parentEnumValue: "44",
        enumValue: "4417",
        enumName: "阳江市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 14,
        enumNameother: "阳江市",
        children: [
          {
            fldEnumId: 11882,
            enumGroupGuid: "1",
            parentEnumValue: "4417",
            enumValue: "441700",
            enumName: "阳江市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 110,
            enumNameother: "阳江市",
          },
          {
            fldEnumId: 11884,
            enumGroupGuid: "1",
            parentEnumValue: "4417",
            enumValue: "441702",
            enumName: "江城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 111,
            enumNameother: "江城区",
          },
          {
            fldEnumId: 11886,
            enumGroupGuid: "1",
            parentEnumValue: "4417",
            enumValue: "441721",
            enumName: "阳西县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 112,
            enumNameother: "阳西县",
          },
          {
            fldEnumId: 11888,
            enumGroupGuid: "1",
            parentEnumValue: "4417",
            enumValue: "441723",
            enumName: "阳东县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 113,
            enumNameother: "阳东县",
          },
          {
            fldEnumId: 11890,
            enumGroupGuid: "1",
            parentEnumValue: "4417",
            enumValue: "441781",
            enumName: "阳春市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 114,
            enumNameother: "阳春市",
          },
        ],
      },
      {
        fldEnumId: 11892,
        enumGroupGuid: "1",
        parentEnumValue: "44",
        enumValue: "4418",
        enumName: "清远市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 15,
        enumNameother: "清远市",
        children: [
          {
            fldEnumId: 11893,
            enumGroupGuid: "1",
            parentEnumValue: "4418",
            enumValue: "441800",
            enumName: "清远市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 115,
            enumNameother: "清远市",
          },
          {
            fldEnumId: 11895,
            enumGroupGuid: "1",
            parentEnumValue: "4418",
            enumValue: "441802",
            enumName: "清城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 116,
            enumNameother: "清城区",
          },
          {
            fldEnumId: 11897,
            enumGroupGuid: "1",
            parentEnumValue: "4418",
            enumValue: "441821",
            enumName: "佛冈县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 117,
            enumNameother: "佛冈县",
          },
          {
            fldEnumId: 11899,
            enumGroupGuid: "1",
            parentEnumValue: "4418",
            enumValue: "441823",
            enumName: "阳山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 118,
            enumNameother: "阳山县",
          },
          {
            fldEnumId: 11901,
            enumGroupGuid: "1",
            parentEnumValue: "4418",
            enumValue: "441825",
            enumName: "连山壮族瑶族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 119,
            enumNameother: "连山壮族瑶族自治县",
          },
          {
            fldEnumId: 11903,
            enumGroupGuid: "1",
            parentEnumValue: "4418",
            enumValue: "441826",
            enumName: "连南瑶族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 120,
            enumNameother: "连南瑶族自治县",
          },
          {
            fldEnumId: 11905,
            enumGroupGuid: "1",
            parentEnumValue: "4418",
            enumValue: "441827",
            enumName: "清新县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 121,
            enumNameother: "清新县",
          },
          {
            fldEnumId: 11907,
            enumGroupGuid: "1",
            parentEnumValue: "4418",
            enumValue: "441881",
            enumName: "英德市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 122,
            enumNameother: "英德市",
          },
          {
            fldEnumId: 11909,
            enumGroupGuid: "1",
            parentEnumValue: "4418",
            enumValue: "441882",
            enumName: "连州市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 123,
            enumNameother: "连州市",
          },
        ],
      },
      {
        fldEnumId: 11911,
        enumGroupGuid: "1",
        parentEnumValue: "44",
        enumValue: "4419",
        enumName: "东莞市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 16,
        enumNameother: "东莞市",
        children: [
          {
            fldEnumId: 16129,
            enumGroupGuid: "1",
            parentEnumValue: "4419",
            enumValue: "441900",
            enumName: "东莞市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
          },
        ],
      },
      {
        fldEnumId: 11912,
        enumGroupGuid: "1",
        parentEnumValue: "44",
        enumValue: "4420",
        enumName: "中山市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 17,
        enumNameother: "中山市",
        children: [
          {
            fldEnumId: 16131,
            enumGroupGuid: "1",
            parentEnumValue: "4420",
            enumValue: "442000",
            enumName: "中山市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
          },
        ],
      },
      {
        fldEnumId: 11913,
        enumGroupGuid: "1",
        parentEnumValue: "44",
        enumValue: "4451",
        enumName: "潮州市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 18,
        enumNameother: "潮州市",
        children: [
          {
            fldEnumId: 11914,
            enumGroupGuid: "1",
            parentEnumValue: "4451",
            enumValue: "445100",
            enumName: "潮州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 124,
            enumNameother: "潮州市",
          },
          {
            fldEnumId: 11916,
            enumGroupGuid: "1",
            parentEnumValue: "4451",
            enumValue: "445102",
            enumName: "湘桥区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 125,
            enumNameother: "湘桥区",
          },
          {
            fldEnumId: 11918,
            enumGroupGuid: "1",
            parentEnumValue: "4451",
            enumValue: "445121",
            enumName: "潮安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 126,
            enumNameother: "潮安县",
          },
          {
            fldEnumId: 11920,
            enumGroupGuid: "1",
            parentEnumValue: "4451",
            enumValue: "445122",
            enumName: "饶平县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 127,
            enumNameother: "饶平县",
          },
        ],
      },
      {
        fldEnumId: 11922,
        enumGroupGuid: "1",
        parentEnumValue: "44",
        enumValue: "4452",
        enumName: "揭阳市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 19,
        enumNameother: "揭阳市",
        children: [
          {
            fldEnumId: 11923,
            enumGroupGuid: "1",
            parentEnumValue: "4452",
            enumValue: "445200",
            enumName: "揭阳市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 128,
            enumNameother: "揭阳市",
          },
          {
            fldEnumId: 11925,
            enumGroupGuid: "1",
            parentEnumValue: "4452",
            enumValue: "445202",
            enumName: "榕城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 129,
            enumNameother: "榕城区",
          },
          {
            fldEnumId: 11927,
            enumGroupGuid: "1",
            parentEnumValue: "4452",
            enumValue: "445221",
            enumName: "揭东县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 130,
            enumNameother: "揭东县",
          },
          {
            fldEnumId: 11929,
            enumGroupGuid: "1",
            parentEnumValue: "4452",
            enumValue: "445222",
            enumName: "揭西县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 131,
            enumNameother: "揭西县",
          },
          {
            fldEnumId: 11931,
            enumGroupGuid: "1",
            parentEnumValue: "4452",
            enumValue: "445224",
            enumName: "惠来县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 132,
            enumNameother: "惠来县",
          },
          {
            fldEnumId: 11933,
            enumGroupGuid: "1",
            parentEnumValue: "4452",
            enumValue: "445281",
            enumName: "普宁市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 133,
            enumNameother: "普宁市",
          },
        ],
      },
      {
        fldEnumId: 11935,
        enumGroupGuid: "1",
        parentEnumValue: "44",
        enumValue: "4453",
        enumName: "云浮市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 20,
        enumNameother: "云浮市",
        children: [
          {
            fldEnumId: 11936,
            enumGroupGuid: "1",
            parentEnumValue: "4453",
            enumValue: "445300",
            enumName: "云浮市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 134,
            enumNameother: "云浮市",
          },
          {
            fldEnumId: 11938,
            enumGroupGuid: "1",
            parentEnumValue: "4453",
            enumValue: "445302",
            enumName: "云城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 135,
            enumNameother: "云城区",
          },
          {
            fldEnumId: 11940,
            enumGroupGuid: "1",
            parentEnumValue: "4453",
            enumValue: "445321",
            enumName: "新兴县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 136,
            enumNameother: "新兴县",
          },
          {
            fldEnumId: 11942,
            enumGroupGuid: "1",
            parentEnumValue: "4453",
            enumValue: "445322",
            enumName: "郁南县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 137,
            enumNameother: "郁南县",
          },
          {
            fldEnumId: 11944,
            enumGroupGuid: "1",
            parentEnumValue: "4453",
            enumValue: "445323",
            enumName: "云安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 138,
            enumNameother: "云安县",
          },
          {
            fldEnumId: 11946,
            enumGroupGuid: "1",
            parentEnumValue: "4453",
            enumValue: "445381",
            enumName: "罗定市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 139,
            enumNameother: "罗定市",
          },
        ],
      },
    ],
  },
  {
    enumValue: "45",
    enumName: "广西壮族",
    children: [
      {
        fldEnumId: 11949,
        enumGroupGuid: "1",
        parentEnumValue: "45",
        enumValue: "4501",
        enumName: "南宁市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        enumNameother: "南宁市",
        children: [
          {
            fldEnumId: 11950,
            enumGroupGuid: "1",
            parentEnumValue: "4501",
            enumValue: "450100",
            enumName: "南宁市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
            enumNameother: "南宁市",
          },
          {
            fldEnumId: 11952,
            enumGroupGuid: "1",
            parentEnumValue: "4501",
            enumValue: "450102",
            enumName: "兴宁区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "兴宁区",
          },
          {
            fldEnumId: 11954,
            enumGroupGuid: "1",
            parentEnumValue: "4501",
            enumValue: "450103",
            enumName: "青秀区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 2,
            enumNameother: "青秀区",
          },
          {
            fldEnumId: 11956,
            enumGroupGuid: "1",
            parentEnumValue: "4501",
            enumValue: "450105",
            enumName: "江南区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 3,
            enumNameother: "江南区",
          },
          {
            fldEnumId: 11958,
            enumGroupGuid: "1",
            parentEnumValue: "4501",
            enumValue: "450107",
            enumName: "西乡塘区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 4,
            enumNameother: "西乡塘区",
          },
          {
            fldEnumId: 11960,
            enumGroupGuid: "1",
            parentEnumValue: "4501",
            enumValue: "450108",
            enumName: "良庆区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 5,
            enumNameother: "良庆区",
          },
          {
            fldEnumId: 11962,
            enumGroupGuid: "1",
            parentEnumValue: "4501",
            enumValue: "450109",
            enumName: "邕宁区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 6,
            enumNameother: "邕宁区",
          },
          {
            fldEnumId: 11964,
            enumGroupGuid: "1",
            parentEnumValue: "4501",
            enumValue: "450122",
            enumName: "武鸣县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 7,
            enumNameother: "武鸣县",
          },
          {
            fldEnumId: 11966,
            enumGroupGuid: "1",
            parentEnumValue: "4501",
            enumValue: "450123",
            enumName: "隆安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 8,
            enumNameother: "隆安县",
          },
          {
            fldEnumId: 11968,
            enumGroupGuid: "1",
            parentEnumValue: "4501",
            enumValue: "450124",
            enumName: "马山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 9,
            enumNameother: "马山县",
          },
          {
            fldEnumId: 11970,
            enumGroupGuid: "1",
            parentEnumValue: "4501",
            enumValue: "450125",
            enumName: "上林县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 10,
            enumNameother: "上林县",
          },
          {
            fldEnumId: 11972,
            enumGroupGuid: "1",
            parentEnumValue: "4501",
            enumValue: "450126",
            enumName: "宾阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 11,
            enumNameother: "宾阳县",
          },
          {
            fldEnumId: 11974,
            enumGroupGuid: "1",
            parentEnumValue: "4501",
            enumValue: "450127",
            enumName: "横县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 12,
            enumNameother: "横县",
          },
        ],
      },
      {
        fldEnumId: 11976,
        enumGroupGuid: "1",
        parentEnumValue: "45",
        enumValue: "4502",
        enumName: "柳州市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 1,
        enumNameother: "柳州市",
        children: [
          {
            fldEnumId: 130344,
            enumGroupGuid: "1",
            parentEnumValue: "4502",
            enumValue: "450206",
            enumName: "柳江区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 11977,
            enumGroupGuid: "1",
            parentEnumValue: "4502",
            enumValue: "450200",
            enumName: "柳州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 13,
            enumNameother: "柳州市",
          },
          {
            fldEnumId: 11979,
            enumGroupGuid: "1",
            parentEnumValue: "4502",
            enumValue: "450202",
            enumName: "城中区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 14,
            enumNameother: "城中区",
          },
          {
            fldEnumId: 11981,
            enumGroupGuid: "1",
            parentEnumValue: "4502",
            enumValue: "450203",
            enumName: "鱼峰区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 15,
            enumNameother: "鱼峰区",
          },
          {
            fldEnumId: 11983,
            enumGroupGuid: "1",
            parentEnumValue: "4502",
            enumValue: "450204",
            enumName: "柳南区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 16,
            enumNameother: "柳南区",
          },
          {
            fldEnumId: 11985,
            enumGroupGuid: "1",
            parentEnumValue: "4502",
            enumValue: "450205",
            enumName: "柳北区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 17,
            enumNameother: "柳北区",
          },
          {
            fldEnumId: 11987,
            enumGroupGuid: "1",
            parentEnumValue: "4502",
            enumValue: "450221",
            enumName: "柳江县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 18,
            enumNameother: "柳江县",
          },
          {
            fldEnumId: 11989,
            enumGroupGuid: "1",
            parentEnumValue: "4502",
            enumValue: "450222",
            enumName: "柳城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 19,
            enumNameother: "柳城县",
          },
          {
            fldEnumId: 11991,
            enumGroupGuid: "1",
            parentEnumValue: "4502",
            enumValue: "450223",
            enumName: "鹿寨县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 20,
            enumNameother: "鹿寨县",
          },
          {
            fldEnumId: 11993,
            enumGroupGuid: "1",
            parentEnumValue: "4502",
            enumValue: "450224",
            enumName: "融安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 21,
            enumNameother: "融安县",
          },
          {
            fldEnumId: 11995,
            enumGroupGuid: "1",
            parentEnumValue: "4502",
            enumValue: "450225",
            enumName: "融水苗族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 22,
            enumNameother: "融水苗族自治县",
          },
          {
            fldEnumId: 11997,
            enumGroupGuid: "1",
            parentEnumValue: "4502",
            enumValue: "450226",
            enumName: "三江侗族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 23,
            enumNameother: "三江侗族自治县",
          },
        ],
      },
      {
        fldEnumId: 11999,
        enumGroupGuid: "1",
        parentEnumValue: "45",
        enumValue: "4503",
        enumName: "桂林市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 2,
        enumNameother: "桂林市",
        children: [
          {
            fldEnumId: 12000,
            enumGroupGuid: "1",
            parentEnumValue: "4503",
            enumValue: "450300",
            enumName: "桂林市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 24,
            enumNameother: "桂林市",
          },
          {
            fldEnumId: 12002,
            enumGroupGuid: "1",
            parentEnumValue: "4503",
            enumValue: "450302",
            enumName: "秀峰区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 25,
            enumNameother: "秀峰区",
          },
          {
            fldEnumId: 12004,
            enumGroupGuid: "1",
            parentEnumValue: "4503",
            enumValue: "450303",
            enumName: "叠彩区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 26,
            enumNameother: "叠彩区",
          },
          {
            fldEnumId: 12006,
            enumGroupGuid: "1",
            parentEnumValue: "4503",
            enumValue: "450304",
            enumName: "象山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 27,
            enumNameother: "象山区",
          },
          {
            fldEnumId: 12008,
            enumGroupGuid: "1",
            parentEnumValue: "4503",
            enumValue: "450305",
            enumName: "七星区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 28,
            enumNameother: "七星区",
          },
          {
            fldEnumId: 12010,
            enumGroupGuid: "1",
            parentEnumValue: "4503",
            enumValue: "450311",
            enumName: "雁山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 29,
            enumNameother: "雁山区",
          },
          {
            fldEnumId: 12012,
            enumGroupGuid: "1",
            parentEnumValue: "4503",
            enumValue: "450321",
            enumName: "阳朔县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 30,
            enumNameother: "阳朔县",
          },
          {
            fldEnumId: 12014,
            enumGroupGuid: "1",
            parentEnumValue: "4503",
            enumValue: "450322",
            enumName: "临桂县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 31,
            enumNameother: "临桂县",
          },
          {
            fldEnumId: 12016,
            enumGroupGuid: "1",
            parentEnumValue: "4503",
            enumValue: "450323",
            enumName: "灵川县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 32,
            enumNameother: "灵川县",
          },
          {
            fldEnumId: 12018,
            enumGroupGuid: "1",
            parentEnumValue: "4503",
            enumValue: "450324",
            enumName: "全州县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 33,
            enumNameother: "全州县",
          },
          {
            fldEnumId: 12020,
            enumGroupGuid: "1",
            parentEnumValue: "4503",
            enumValue: "450325",
            enumName: "兴安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 34,
            enumNameother: "兴安县",
          },
          {
            fldEnumId: 12022,
            enumGroupGuid: "1",
            parentEnumValue: "4503",
            enumValue: "450326",
            enumName: "永福县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 35,
            enumNameother: "永福县",
          },
          {
            fldEnumId: 12024,
            enumGroupGuid: "1",
            parentEnumValue: "4503",
            enumValue: "450327",
            enumName: "灌阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 36,
            enumNameother: "灌阳县",
          },
          {
            fldEnumId: 12026,
            enumGroupGuid: "1",
            parentEnumValue: "4503",
            enumValue: "450328",
            enumName: "龙胜各族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 37,
            enumNameother: "龙胜各族自治县",
          },
          {
            fldEnumId: 12028,
            enumGroupGuid: "1",
            parentEnumValue: "4503",
            enumValue: "450329",
            enumName: "资源县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 38,
            enumNameother: "资源县",
          },
          {
            fldEnumId: 12030,
            enumGroupGuid: "1",
            parentEnumValue: "4503",
            enumValue: "450330",
            enumName: "平乐县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 39,
            enumNameother: "平乐县",
          },
          {
            fldEnumId: 12032,
            enumGroupGuid: "1",
            parentEnumValue: "4503",
            enumValue: "450331",
            enumName: "荔蒲县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 40,
            enumNameother: "荔蒲县",
          },
          {
            fldEnumId: 12034,
            enumGroupGuid: "1",
            parentEnumValue: "4503",
            enumValue: "450332",
            enumName: "恭城瑶族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 41,
            enumNameother: "恭城瑶族自治县",
          },
        ],
      },
      {
        fldEnumId: 12036,
        enumGroupGuid: "1",
        parentEnumValue: "45",
        enumValue: "4504",
        enumName: "梧州市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 3,
        enumNameother: "梧州市",
        children: [
          {
            fldEnumId: 130200,
            enumGroupGuid: "1",
            parentEnumValue: "4504",
            enumValue: "450406",
            enumName: "龙圩区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 12037,
            enumGroupGuid: "1",
            parentEnumValue: "4504",
            enumValue: "450400",
            enumName: "梧州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 42,
            enumNameother: "梧州市",
          },
          {
            fldEnumId: 12039,
            enumGroupGuid: "1",
            parentEnumValue: "4504",
            enumValue: "450403",
            enumName: "万秀区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 43,
            enumNameother: "万秀区",
          },
          {
            fldEnumId: 12041,
            enumGroupGuid: "1",
            parentEnumValue: "4504",
            enumValue: "450404",
            enumName: "蝶山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 44,
            enumNameother: "蝶山区",
          },
          {
            fldEnumId: 12043,
            enumGroupGuid: "1",
            parentEnumValue: "4504",
            enumValue: "450405",
            enumName: "长洲区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 45,
            enumNameother: "长洲区",
          },
          {
            fldEnumId: 12045,
            enumGroupGuid: "1",
            parentEnumValue: "4504",
            enumValue: "450421",
            enumName: "苍梧县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 46,
            enumNameother: "苍梧县",
          },
          {
            fldEnumId: 12047,
            enumGroupGuid: "1",
            parentEnumValue: "4504",
            enumValue: "450422",
            enumName: "藤县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 47,
            enumNameother: "藤县",
          },
          {
            fldEnumId: 12049,
            enumGroupGuid: "1",
            parentEnumValue: "4504",
            enumValue: "450423",
            enumName: "蒙山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 48,
            enumNameother: "蒙山县",
          },
          {
            fldEnumId: 12051,
            enumGroupGuid: "1",
            parentEnumValue: "4504",
            enumValue: "450481",
            enumName: "岑溪市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 49,
            enumNameother: "岑溪市",
          },
        ],
      },
      {
        fldEnumId: 12053,
        enumGroupGuid: "1",
        parentEnumValue: "45",
        enumValue: "4505",
        enumName: "北海市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 4,
        enumNameother: "北海市",
        children: [
          {
            fldEnumId: 12054,
            enumGroupGuid: "1",
            parentEnumValue: "4505",
            enumValue: "450500",
            enumName: "北海市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 50,
            enumNameother: "北海市",
          },
          {
            fldEnumId: 12056,
            enumGroupGuid: "1",
            parentEnumValue: "4505",
            enumValue: "450502",
            enumName: "海城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 51,
            enumNameother: "海城区",
          },
          {
            fldEnumId: 12058,
            enumGroupGuid: "1",
            parentEnumValue: "4505",
            enumValue: "450503",
            enumName: "银海区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 52,
            enumNameother: "银海区",
          },
          {
            fldEnumId: 12060,
            enumGroupGuid: "1",
            parentEnumValue: "4505",
            enumValue: "450512",
            enumName: "铁山港区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 53,
            enumNameother: "铁山港区",
          },
          {
            fldEnumId: 12062,
            enumGroupGuid: "1",
            parentEnumValue: "4505",
            enumValue: "450521",
            enumName: "合浦县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 54,
            enumNameother: "合浦县",
          },
        ],
      },
      {
        fldEnumId: 12064,
        enumGroupGuid: "1",
        parentEnumValue: "45",
        enumValue: "4506",
        enumName: "防城港市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 5,
        enumNameother: "防城港市",
        children: [
          {
            fldEnumId: 12065,
            enumGroupGuid: "1",
            parentEnumValue: "4506",
            enumValue: "450600",
            enumName: "防城港市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 55,
            enumNameother: "防城港市",
          },
          {
            fldEnumId: 12067,
            enumGroupGuid: "1",
            parentEnumValue: "4506",
            enumValue: "450602",
            enumName: "港口区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 56,
            enumNameother: "港口区",
          },
          {
            fldEnumId: 12069,
            enumGroupGuid: "1",
            parentEnumValue: "4506",
            enumValue: "450603",
            enumName: "防城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 57,
            enumNameother: "防城区",
          },
          {
            fldEnumId: 12071,
            enumGroupGuid: "1",
            parentEnumValue: "4506",
            enumValue: "450621",
            enumName: "上思县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 58,
            enumNameother: "上思县",
          },
          {
            fldEnumId: 12073,
            enumGroupGuid: "1",
            parentEnumValue: "4506",
            enumValue: "450681",
            enumName: "东兴市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 59,
            enumNameother: "东兴市",
          },
        ],
      },
      {
        fldEnumId: 12075,
        enumGroupGuid: "1",
        parentEnumValue: "45",
        enumValue: "4507",
        enumName: "钦州市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 6,
        enumNameother: "钦州市",
        children: [
          {
            fldEnumId: 12076,
            enumGroupGuid: "1",
            parentEnumValue: "4507",
            enumValue: "450700",
            enumName: "钦州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 60,
            enumNameother: "钦州市",
          },
          {
            fldEnumId: 12078,
            enumGroupGuid: "1",
            parentEnumValue: "4507",
            enumValue: "450702",
            enumName: "钦南区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 61,
            enumNameother: "钦南区",
          },
          {
            fldEnumId: 12080,
            enumGroupGuid: "1",
            parentEnumValue: "4507",
            enumValue: "450703",
            enumName: "钦北区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 62,
            enumNameother: "钦北区",
          },
          {
            fldEnumId: 12082,
            enumGroupGuid: "1",
            parentEnumValue: "4507",
            enumValue: "450721",
            enumName: "灵山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 63,
            enumNameother: "灵山县",
          },
          {
            fldEnumId: 12084,
            enumGroupGuid: "1",
            parentEnumValue: "4507",
            enumValue: "450722",
            enumName: "浦北县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 64,
            enumNameother: "浦北县",
          },
        ],
      },
      {
        fldEnumId: 12086,
        enumGroupGuid: "1",
        parentEnumValue: "45",
        enumValue: "4508",
        enumName: "贵港市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 7,
        enumNameother: "贵港市",
        children: [
          {
            fldEnumId: 12087,
            enumGroupGuid: "1",
            parentEnumValue: "4508",
            enumValue: "450800",
            enumName: "贵港市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 65,
            enumNameother: "贵港市",
          },
          {
            fldEnumId: 12089,
            enumGroupGuid: "1",
            parentEnumValue: "4508",
            enumValue: "450802",
            enumName: "港北区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 66,
            enumNameother: "港北区",
          },
          {
            fldEnumId: 12091,
            enumGroupGuid: "1",
            parentEnumValue: "4508",
            enumValue: "450803",
            enumName: "港南区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 67,
            enumNameother: "港南区",
          },
          {
            fldEnumId: 12093,
            enumGroupGuid: "1",
            parentEnumValue: "4508",
            enumValue: "450804",
            enumName: "覃塘区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 68,
            enumNameother: "覃塘区",
          },
          {
            fldEnumId: 12095,
            enumGroupGuid: "1",
            parentEnumValue: "4508",
            enumValue: "450821",
            enumName: "平南县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 69,
            enumNameother: "平南县",
          },
          {
            fldEnumId: 12097,
            enumGroupGuid: "1",
            parentEnumValue: "4508",
            enumValue: "450881",
            enumName: "桂平市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 70,
            enumNameother: "桂平市",
          },
        ],
      },
      {
        fldEnumId: 12099,
        enumGroupGuid: "1",
        parentEnumValue: "45",
        enumValue: "4509",
        enumName: "玉林市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 8,
        enumNameother: "玉林市",
        children: [
          {
            fldEnumId: 12100,
            enumGroupGuid: "1",
            parentEnumValue: "4509",
            enumValue: "450900",
            enumName: "玉林市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 71,
            enumNameother: "玉林市",
          },
          {
            fldEnumId: 12102,
            enumGroupGuid: "1",
            parentEnumValue: "4509",
            enumValue: "450902",
            enumName: "玉州区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 72,
            enumNameother: "玉州区",
          },
          {
            fldEnumId: 12104,
            enumGroupGuid: "1",
            parentEnumValue: "4509",
            enumValue: "450921",
            enumName: "容县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 73,
            enumNameother: "容县",
          },
          {
            fldEnumId: 12106,
            enumGroupGuid: "1",
            parentEnumValue: "4509",
            enumValue: "450922",
            enumName: "陆川县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 74,
            enumNameother: "陆川县",
          },
          {
            fldEnumId: 12108,
            enumGroupGuid: "1",
            parentEnumValue: "4509",
            enumValue: "450923",
            enumName: "博白县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 75,
            enumNameother: "博白县",
          },
          {
            fldEnumId: 12110,
            enumGroupGuid: "1",
            parentEnumValue: "4509",
            enumValue: "450924",
            enumName: "兴业县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 76,
            enumNameother: "兴业县",
          },
          {
            fldEnumId: 12112,
            enumGroupGuid: "1",
            parentEnumValue: "4509",
            enumValue: "450981",
            enumName: "北流市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 77,
            enumNameother: "北流市",
          },
        ],
      },
      {
        fldEnumId: 12114,
        enumGroupGuid: "1",
        parentEnumValue: "45",
        enumValue: "4510",
        enumName: "百色市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 9,
        enumNameother: "百色市",
        children: [
          {
            fldEnumId: 12115,
            enumGroupGuid: "1",
            parentEnumValue: "4510",
            enumValue: "451000",
            enumName: "百色市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 78,
            enumNameother: "百色市",
          },
          {
            fldEnumId: 12117,
            enumGroupGuid: "1",
            parentEnumValue: "4510",
            enumValue: "451002",
            enumName: "右江区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 79,
            enumNameother: "右江区",
          },
          {
            fldEnumId: 12119,
            enumGroupGuid: "1",
            parentEnumValue: "4510",
            enumValue: "451021",
            enumName: "田阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 80,
            enumNameother: "田阳县",
          },
          {
            fldEnumId: 12121,
            enumGroupGuid: "1",
            parentEnumValue: "4510",
            enumValue: "451022",
            enumName: "田东县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 81,
            enumNameother: "田东县",
          },
          {
            fldEnumId: 12123,
            enumGroupGuid: "1",
            parentEnumValue: "4510",
            enumValue: "451023",
            enumName: "平果县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 82,
            enumNameother: "平果县",
          },
          {
            fldEnumId: 12125,
            enumGroupGuid: "1",
            parentEnumValue: "4510",
            enumValue: "451024",
            enumName: "德保县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 83,
            enumNameother: "德保县",
          },
          {
            fldEnumId: 12127,
            enumGroupGuid: "1",
            parentEnumValue: "4510",
            enumValue: "451025",
            enumName: "靖西县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 84,
            enumNameother: "靖西县",
          },
          {
            fldEnumId: 12129,
            enumGroupGuid: "1",
            parentEnumValue: "4510",
            enumValue: "451026",
            enumName: "那坡县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 85,
            enumNameother: "那坡县",
          },
          {
            fldEnumId: 12131,
            enumGroupGuid: "1",
            parentEnumValue: "4510",
            enumValue: "451027",
            enumName: "凌云县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 86,
            enumNameother: "凌云县",
          },
          {
            fldEnumId: 12133,
            enumGroupGuid: "1",
            parentEnumValue: "4510",
            enumValue: "451028",
            enumName: "乐业县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 87,
            enumNameother: "乐业县",
          },
          {
            fldEnumId: 12135,
            enumGroupGuid: "1",
            parentEnumValue: "4510",
            enumValue: "451029",
            enumName: "田林县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 88,
            enumNameother: "田林县",
          },
          {
            fldEnumId: 12137,
            enumGroupGuid: "1",
            parentEnumValue: "4510",
            enumValue: "451030",
            enumName: "西林县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 89,
            enumNameother: "西林县",
          },
          {
            fldEnumId: 12139,
            enumGroupGuid: "1",
            parentEnumValue: "4510",
            enumValue: "451031",
            enumName: "隆林各族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 90,
            enumNameother: "隆林各族自治县",
          },
        ],
      },
      {
        fldEnumId: 12141,
        enumGroupGuid: "1",
        parentEnumValue: "45",
        enumValue: "4511",
        enumName: "贺州市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 10,
        enumNameother: "贺州市",
        children: [
          {
            fldEnumId: 12142,
            enumGroupGuid: "1",
            parentEnumValue: "4511",
            enumValue: "451100",
            enumName: "贺州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 91,
            enumNameother: "贺州市",
          },
          {
            fldEnumId: 12144,
            enumGroupGuid: "1",
            parentEnumValue: "4511",
            enumValue: "451102",
            enumName: "八步区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 92,
            enumNameother: "八步区",
          },
          {
            fldEnumId: 12146,
            enumGroupGuid: "1",
            parentEnumValue: "4511",
            enumValue: "451121",
            enumName: "昭平县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 93,
            enumNameother: "昭平县",
          },
          {
            fldEnumId: 12148,
            enumGroupGuid: "1",
            parentEnumValue: "4511",
            enumValue: "451122",
            enumName: "钟山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 94,
            enumNameother: "钟山县",
          },
          {
            fldEnumId: 12150,
            enumGroupGuid: "1",
            parentEnumValue: "4511",
            enumValue: "451123",
            enumName: "富川瑶族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 95,
            enumNameother: "富川瑶族自治县",
          },
        ],
      },
      {
        fldEnumId: 12152,
        enumGroupGuid: "1",
        parentEnumValue: "45",
        enumValue: "4512",
        enumName: "河池市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 11,
        enumNameother: "河池市",
        children: [
          {
            fldEnumId: 12153,
            enumGroupGuid: "1",
            parentEnumValue: "4512",
            enumValue: "451200",
            enumName: "河池市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 96,
            enumNameother: "河池市",
          },
          {
            fldEnumId: 12155,
            enumGroupGuid: "1",
            parentEnumValue: "4512",
            enumValue: "451202",
            enumName: "金城江区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 97,
            enumNameother: "金城江区",
          },
          {
            fldEnumId: 12157,
            enumGroupGuid: "1",
            parentEnumValue: "4512",
            enumValue: "451221",
            enumName: "南丹县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 98,
            enumNameother: "南丹县",
          },
          {
            fldEnumId: 12159,
            enumGroupGuid: "1",
            parentEnumValue: "4512",
            enumValue: "451222",
            enumName: "天峨县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 99,
            enumNameother: "天峨县",
          },
          {
            fldEnumId: 12161,
            enumGroupGuid: "1",
            parentEnumValue: "4512",
            enumValue: "451223",
            enumName: "凤山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 100,
            enumNameother: "凤山县",
          },
          {
            fldEnumId: 12163,
            enumGroupGuid: "1",
            parentEnumValue: "4512",
            enumValue: "451224",
            enumName: "东兰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 101,
            enumNameother: "东兰县",
          },
          {
            fldEnumId: 12165,
            enumGroupGuid: "1",
            parentEnumValue: "4512",
            enumValue: "451225",
            enumName: "罗城仫佬族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 102,
            enumNameother: "罗城仫佬族自治县",
          },
          {
            fldEnumId: 12167,
            enumGroupGuid: "1",
            parentEnumValue: "4512",
            enumValue: "451226",
            enumName: "环江毛南族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 103,
            enumNameother: "环江毛南族自治县",
          },
          {
            fldEnumId: 12169,
            enumGroupGuid: "1",
            parentEnumValue: "4512",
            enumValue: "451227",
            enumName: "巴马瑶族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 104,
            enumNameother: "巴马瑶族自治县",
          },
          {
            fldEnumId: 12171,
            enumGroupGuid: "1",
            parentEnumValue: "4512",
            enumValue: "451228",
            enumName: "都安瑶族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 105,
            enumNameother: "都安瑶族自治县",
          },
          {
            fldEnumId: 12173,
            enumGroupGuid: "1",
            parentEnumValue: "4512",
            enumValue: "451229",
            enumName: "大化瑶族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 106,
            enumNameother: "大化瑶族自治县",
          },
          {
            fldEnumId: 12175,
            enumGroupGuid: "1",
            parentEnumValue: "4512",
            enumValue: "451281",
            enumName: "宜州市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 107,
            enumNameother: "宜州市",
          },
        ],
      },
      {
        fldEnumId: 12177,
        enumGroupGuid: "1",
        parentEnumValue: "45",
        enumValue: "4513",
        enumName: "来宾市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 12,
        enumNameother: "来宾市",
        children: [
          {
            fldEnumId: 12178,
            enumGroupGuid: "1",
            parentEnumValue: "4513",
            enumValue: "451300",
            enumName: "来宾市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 108,
            enumNameother: "来宾市",
          },
          {
            fldEnumId: 12180,
            enumGroupGuid: "1",
            parentEnumValue: "4513",
            enumValue: "451302",
            enumName: "兴宾区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 109,
            enumNameother: "兴宾区",
          },
          {
            fldEnumId: 12182,
            enumGroupGuid: "1",
            parentEnumValue: "4513",
            enumValue: "451321",
            enumName: "忻城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 110,
            enumNameother: "忻城县",
          },
          {
            fldEnumId: 12184,
            enumGroupGuid: "1",
            parentEnumValue: "4513",
            enumValue: "451322",
            enumName: "象州县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 111,
            enumNameother: "象州县",
          },
          {
            fldEnumId: 12186,
            enumGroupGuid: "1",
            parentEnumValue: "4513",
            enumValue: "451323",
            enumName: "武宣县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 112,
            enumNameother: "武宣县",
          },
          {
            fldEnumId: 12188,
            enumGroupGuid: "1",
            parentEnumValue: "4513",
            enumValue: "451324",
            enumName: "金秀瑶族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 113,
            enumNameother: "金秀瑶族自治县",
          },
          {
            fldEnumId: 12190,
            enumGroupGuid: "1",
            parentEnumValue: "4513",
            enumValue: "451381",
            enumName: "合山市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 114,
            enumNameother: "合山市",
          },
        ],
      },
      {
        fldEnumId: 12192,
        enumGroupGuid: "1",
        parentEnumValue: "45",
        enumValue: "4514",
        enumName: "崇左市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 13,
        enumNameother: "崇左市",
        children: [
          {
            fldEnumId: 12193,
            enumGroupGuid: "1",
            parentEnumValue: "4514",
            enumValue: "451400",
            enumName: "崇左市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 115,
            enumNameother: "崇左市",
          },
          {
            fldEnumId: 12195,
            enumGroupGuid: "1",
            parentEnumValue: "4514",
            enumValue: "451402",
            enumName: "江洲区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 116,
            enumNameother: "江洲区",
          },
          {
            fldEnumId: 12197,
            enumGroupGuid: "1",
            parentEnumValue: "4514",
            enumValue: "451421",
            enumName: "扶绥县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 117,
            enumNameother: "扶绥县",
          },
          {
            fldEnumId: 12199,
            enumGroupGuid: "1",
            parentEnumValue: "4514",
            enumValue: "451422",
            enumName: "宁明县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 118,
            enumNameother: "宁明县",
          },
          {
            fldEnumId: 12201,
            enumGroupGuid: "1",
            parentEnumValue: "4514",
            enumValue: "451423",
            enumName: "龙州县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 119,
            enumNameother: "龙州县",
          },
          {
            fldEnumId: 12203,
            enumGroupGuid: "1",
            parentEnumValue: "4514",
            enumValue: "451424",
            enumName: "大新县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 120,
            enumNameother: "大新县",
          },
          {
            fldEnumId: 12205,
            enumGroupGuid: "1",
            parentEnumValue: "4514",
            enumValue: "451425",
            enumName: "天等县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 121,
            enumNameother: "天等县",
          },
          {
            fldEnumId: 12207,
            enumGroupGuid: "1",
            parentEnumValue: "4514",
            enumValue: "451481",
            enumName: "凭祥市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 122,
            enumNameother: "凭祥市",
          },
        ],
      },
    ],
  },
  {
    enumValue: "46",
    enumName: "海南省",
    children: [
      {
        fldEnumId: 12210,
        enumGroupGuid: "1",
        parentEnumValue: "46",
        enumValue: "4601",
        enumName: "海口市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        enumNameother: "海口市",
        children: [
          {
            fldEnumId: 12211,
            enumGroupGuid: "1",
            parentEnumValue: "4601",
            enumValue: "460100",
            enumName: "海口市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
            enumNameother: "海口市",
          },
          {
            fldEnumId: 12213,
            enumGroupGuid: "1",
            parentEnumValue: "4601",
            enumValue: "460105",
            enumName: "秀英区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "秀英区",
          },
          {
            fldEnumId: 12215,
            enumGroupGuid: "1",
            parentEnumValue: "4601",
            enumValue: "460106",
            enumName: "龙华区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 2,
            enumNameother: "龙华区",
          },
          {
            fldEnumId: 12217,
            enumGroupGuid: "1",
            parentEnumValue: "4601",
            enumValue: "460107",
            enumName: "琼山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 3,
            enumNameother: "琼山区",
          },
          {
            fldEnumId: 12219,
            enumGroupGuid: "1",
            parentEnumValue: "4601",
            enumValue: "460108",
            enumName: "美兰区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 4,
            enumNameother: "美兰区",
          },
        ],
      },
      {
        fldEnumId: 130352,
        enumGroupGuid: "1",
        parentEnumValue: "46",
        enumValue: "4603",
        enumName: "三沙市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        children: [
          {
            fldEnumId: 130321,
            enumGroupGuid: "1",
            parentEnumValue: "4603",
            enumValue: "460300",
            enumName: "三沙市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130201,
            enumGroupGuid: "1",
            parentEnumValue: "4603",
            enumValue: "469031",
            enumName: "西沙群岛",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130202,
            enumGroupGuid: "1",
            parentEnumValue: "4603",
            enumValue: "469032",
            enumName: "南沙群岛",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130203,
            enumGroupGuid: "1",
            parentEnumValue: "4603",
            enumValue: "469033",
            enumName: "中沙群岛的岛礁及其海域",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
        ],
      },
      {
        fldEnumId: 12221,
        enumGroupGuid: "1",
        parentEnumValue: "46",
        enumValue: "4602",
        enumName: "三亚市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 1,
        enumNameother: "三亚市",
        children: [
          {
            fldEnumId: 12222,
            enumGroupGuid: "1",
            parentEnumValue: "4602",
            enumValue: "460200",
            enumName: "三亚市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 5,
            enumNameother: "三亚市",
          },
        ],
      },
      {
        fldEnumId: 12224,
        enumGroupGuid: "1",
        parentEnumValue: "46",
        enumValue: "4690",
        enumName: "省直辖县级行政单位",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 2,
        enumNameother: "省直辖县级行政单位",
        children: [
          {
            fldEnumId: 16298,
            enumGroupGuid: "1",
            parentEnumValue: "4690",
            enumValue: "469000",
            enumName: "省直辖县级行政单位本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 12225,
            enumGroupGuid: "1",
            parentEnumValue: "4690",
            enumValue: "469001",
            enumName: "五指山市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 6,
            enumNameother: "五指山市",
          },
          {
            fldEnumId: 12227,
            enumGroupGuid: "1",
            parentEnumValue: "4690",
            enumValue: "469002",
            enumName: "琼海市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 7,
            enumNameother: "琼海市",
          },
          {
            fldEnumId: 12229,
            enumGroupGuid: "1",
            parentEnumValue: "4690",
            enumValue: "469003",
            enumName: "儋州市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 8,
            enumNameother: "儋州市",
          },
          {
            fldEnumId: 12231,
            enumGroupGuid: "1",
            parentEnumValue: "4690",
            enumValue: "469005",
            enumName: "文昌市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 9,
            enumNameother: "文昌市",
          },
          {
            fldEnumId: 12233,
            enumGroupGuid: "1",
            parentEnumValue: "4690",
            enumValue: "469006",
            enumName: "万宁市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 10,
            enumNameother: "万宁市",
          },
          {
            fldEnumId: 12235,
            enumGroupGuid: "1",
            parentEnumValue: "4690",
            enumValue: "469007",
            enumName: "东方市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 11,
            enumNameother: "东方市",
          },
          {
            fldEnumId: 12237,
            enumGroupGuid: "1",
            parentEnumValue: "4690",
            enumValue: "469021",
            enumName: "定安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 12,
            enumNameother: "定安县",
          },
          {
            fldEnumId: 12239,
            enumGroupGuid: "1",
            parentEnumValue: "4690",
            enumValue: "469022",
            enumName: "屯昌县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 13,
            enumNameother: "屯昌县",
          },
          {
            fldEnumId: 12241,
            enumGroupGuid: "1",
            parentEnumValue: "4690",
            enumValue: "469023",
            enumName: "澄迈县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 14,
            enumNameother: "澄迈县",
          },
          {
            fldEnumId: 12243,
            enumGroupGuid: "1",
            parentEnumValue: "4690",
            enumValue: "469024",
            enumName: "临高县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 15,
            enumNameother: "临高县",
          },
          {
            fldEnumId: 12245,
            enumGroupGuid: "1",
            parentEnumValue: "4690",
            enumValue: "469025",
            enumName: "白沙黎族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 16,
            enumNameother: "白沙黎族自治县",
          },
          {
            fldEnumId: 12247,
            enumGroupGuid: "1",
            parentEnumValue: "4690",
            enumValue: "469026",
            enumName: "昌江黎族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 17,
            enumNameother: "昌江黎族自治县",
          },
          {
            fldEnumId: 12249,
            enumGroupGuid: "1",
            parentEnumValue: "4690",
            enumValue: "469027",
            enumName: "乐东黎族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 18,
            enumNameother: "乐东黎族自治县",
          },
          {
            fldEnumId: 12251,
            enumGroupGuid: "1",
            parentEnumValue: "4690",
            enumValue: "469028",
            enumName: "陵水黎族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 19,
            enumNameother: "陵水黎族自治县",
          },
          {
            fldEnumId: 12253,
            enumGroupGuid: "1",
            parentEnumValue: "4690",
            enumValue: "469029",
            enumName: "保亭黎族苗族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 20,
            enumNameother: "保亭黎族苗族自治县",
          },
          {
            fldEnumId: 12255,
            enumGroupGuid: "1",
            parentEnumValue: "4690",
            enumValue: "469030",
            enumName: "琼中黎族苗族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 21,
            enumNameother: "琼中黎族苗族自治县",
          },
        ],
      },
    ],
  },
  {
    enumValue: "50",
    enumName: "重庆市",
    enumDesc: "zdcs",
    children: [
      {
        fldEnumId: 12264,
        enumGroupGuid: "1",
        parentEnumValue: "50",
        enumValue: "5001",
        enumName: "重庆市市辖区",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        enumNameother: "市辖区",
        children: [
          {
            fldEnumId: 16139,
            enumGroupGuid: "1",
            parentEnumValue: "5001",
            enumValue: "500100",
            enumName: "重庆市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 12265,
            enumGroupGuid: "1",
            parentEnumValue: "5001",
            enumValue: "500101",
            enumName: "万州区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
            enumNameother: "万州区",
          },
          {
            fldEnumId: 130205,
            enumGroupGuid: "1",
            parentEnumValue: "5001",
            enumValue: "500223",
            enumName: "潼南区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130206,
            enumGroupGuid: "1",
            parentEnumValue: "5001",
            enumValue: "500226",
            enumName: "荣昌区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130204,
            enumGroupGuid: "1",
            parentEnumValue: "5001",
            enumValue: "500227",
            enumName: "璧山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 12267,
            enumGroupGuid: "1",
            parentEnumValue: "5001",
            enumValue: "500102",
            enumName: "涪陵区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "涪陵区",
          },
          {
            fldEnumId: 12269,
            enumGroupGuid: "1",
            parentEnumValue: "5001",
            enumValue: "500103",
            enumName: "渝中区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 2,
            enumNameother: "渝中区",
          },
          {
            fldEnumId: 12271,
            enumGroupGuid: "1",
            parentEnumValue: "5001",
            enumValue: "500104",
            enumName: "大渡口区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 3,
            enumNameother: "大渡口区",
          },
          {
            fldEnumId: 12273,
            enumGroupGuid: "1",
            parentEnumValue: "5001",
            enumValue: "500105",
            enumName: "江北区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 4,
            enumNameother: "江北区",
          },
          {
            fldEnumId: 12275,
            enumGroupGuid: "1",
            parentEnumValue: "5001",
            enumValue: "500106",
            enumName: "沙坪坝区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 5,
            enumNameother: "沙坪坝区",
          },
          {
            fldEnumId: 12277,
            enumGroupGuid: "1",
            parentEnumValue: "5001",
            enumValue: "500107",
            enumName: "九龙坡区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 6,
            enumNameother: "九龙坡区",
          },
          {
            fldEnumId: 12279,
            enumGroupGuid: "1",
            parentEnumValue: "5001",
            enumValue: "500108",
            enumName: "南岸区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 7,
            enumNameother: "南岸区",
          },
          {
            fldEnumId: 12281,
            enumGroupGuid: "1",
            parentEnumValue: "5001",
            enumValue: "500109",
            enumName: "北碚区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 8,
            enumNameother: "北碚区",
          },
          {
            fldEnumId: 12283,
            enumGroupGuid: "1",
            parentEnumValue: "5001",
            enumValue: "500110",
            enumName: "万盛区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 9,
            enumNameother: "万盛区",
          },
          {
            fldEnumId: 12285,
            enumGroupGuid: "1",
            parentEnumValue: "5001",
            enumValue: "500111",
            enumName: "双桥区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 10,
            enumNameother: "双桥区",
          },
          {
            fldEnumId: 12287,
            enumGroupGuid: "1",
            parentEnumValue: "5001",
            enumValue: "500112",
            enumName: "渝北区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 11,
            enumNameother: "渝北区",
          },
          {
            fldEnumId: 12289,
            enumGroupGuid: "1",
            parentEnumValue: "5001",
            enumValue: "500113",
            enumName: "巴南区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 12,
            enumNameother: "巴南区",
          },
          {
            fldEnumId: 12291,
            enumGroupGuid: "1",
            parentEnumValue: "5001",
            enumValue: "500114",
            enumName: "黔江区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 13,
            enumNameother: "黔江区",
          },
          {
            fldEnumId: 12293,
            enumGroupGuid: "1",
            parentEnumValue: "5001",
            enumValue: "500115",
            enumName: "长寿区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 14,
            enumNameother: "长寿区",
          },
          {
            fldEnumId: 12295,
            enumGroupGuid: "1",
            parentEnumValue: "5001",
            enumValue: "500116",
            enumName: "江津区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 15,
            enumNameother: "江津区",
          },
          {
            fldEnumId: 12297,
            enumGroupGuid: "1",
            parentEnumValue: "5001",
            enumValue: "500117",
            enumName: "合川区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 16,
            enumNameother: "合川区",
          },
          {
            fldEnumId: 12299,
            enumGroupGuid: "1",
            parentEnumValue: "5001",
            enumValue: "500118",
            enumName: "永川区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 17,
            enumNameother: "永川区",
          },
          {
            fldEnumId: 12301,
            enumGroupGuid: "1",
            parentEnumValue: "5001",
            enumValue: "500119",
            enumName: "南川区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 18,
            enumNameother: "南川区",
          },
        ],
      },
      {
        fldEnumId: 12303,
        enumGroupGuid: "1",
        parentEnumValue: "50",
        enumValue: "5002",
        enumName: "重庆市所辖县",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 1,
        enumNameother: "所辖县",
        children: [
          {
            fldEnumId: 12304,
            enumGroupGuid: "1",
            parentEnumValue: "5002",
            enumValue: "500222",
            enumName: "綦江县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 19,
            enumNameother: "綦江县",
          },
          {
            fldEnumId: 12308,
            enumGroupGuid: "1",
            parentEnumValue: "5002",
            enumValue: "500224",
            enumName: "铜梁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 21,
            enumNameother: "铜梁县",
          },
          {
            fldEnumId: 12310,
            enumGroupGuid: "1",
            parentEnumValue: "5002",
            enumValue: "500225",
            enumName: "大足县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 22,
            enumNameother: "大足县",
          },
          {
            fldEnumId: 12316,
            enumGroupGuid: "1",
            parentEnumValue: "5002",
            enumValue: "500228",
            enumName: "梁平县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 25,
            enumNameother: "梁平县",
          },
          {
            fldEnumId: 12318,
            enumGroupGuid: "1",
            parentEnumValue: "5002",
            enumValue: "500229",
            enumName: "城口县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 26,
            enumNameother: "城口县",
          },
          {
            fldEnumId: 12320,
            enumGroupGuid: "1",
            parentEnumValue: "5002",
            enumValue: "500230",
            enumName: "丰都县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 27,
            enumNameother: "丰都县",
          },
          {
            fldEnumId: 12322,
            enumGroupGuid: "1",
            parentEnumValue: "5002",
            enumValue: "500231",
            enumName: "垫江县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 28,
            enumNameother: "垫江县",
          },
          {
            fldEnumId: 12324,
            enumGroupGuid: "1",
            parentEnumValue: "5002",
            enumValue: "500232",
            enumName: "武隆县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 29,
            enumNameother: "武隆县",
          },
          {
            fldEnumId: 12326,
            enumGroupGuid: "1",
            parentEnumValue: "5002",
            enumValue: "500233",
            enumName: "忠县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 30,
            enumNameother: "忠县",
          },
          {
            fldEnumId: 12328,
            enumGroupGuid: "1",
            parentEnumValue: "5002",
            enumValue: "500234",
            enumName: "开县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 31,
            enumNameother: "开县",
          },
          {
            fldEnumId: 12330,
            enumGroupGuid: "1",
            parentEnumValue: "5002",
            enumValue: "500235",
            enumName: "云阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 32,
            enumNameother: "云阳县",
          },
          {
            fldEnumId: 12332,
            enumGroupGuid: "1",
            parentEnumValue: "5002",
            enumValue: "500236",
            enumName: "奉节县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 33,
            enumNameother: "奉节县",
          },
          {
            fldEnumId: 12334,
            enumGroupGuid: "1",
            parentEnumValue: "5002",
            enumValue: "500237",
            enumName: "巫山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 34,
            enumNameother: "巫山县",
          },
          {
            fldEnumId: 12336,
            enumGroupGuid: "1",
            parentEnumValue: "5002",
            enumValue: "500238",
            enumName: "巫溪县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 35,
            enumNameother: "巫溪县",
          },
          {
            fldEnumId: 12338,
            enumGroupGuid: "1",
            parentEnumValue: "5002",
            enumValue: "500240",
            enumName: "石柱土家族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 36,
            enumNameother: "石柱土家族自治县",
          },
          {
            fldEnumId: 12340,
            enumGroupGuid: "1",
            parentEnumValue: "5002",
            enumValue: "500241",
            enumName: "秀山土家族苗族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 37,
            enumNameother: "秀山土家族苗族自治县",
          },
          {
            fldEnumId: 12342,
            enumGroupGuid: "1",
            parentEnumValue: "5002",
            enumValue: "500242",
            enumName: "酉阳土家族苗族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 38,
            enumNameother: "酉阳土家族苗族自治县",
          },
          {
            fldEnumId: 12344,
            enumGroupGuid: "1",
            parentEnumValue: "5002",
            enumValue: "500243",
            enumName: "彭水苗族土家族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 39,
            enumNameother: "彭水苗族土家族自治县",
          },
        ],
      },
    ],
  },
  {
    enumValue: "51",
    enumName: "四川省",
    children: [
      {
        fldEnumId: 12347,
        enumGroupGuid: "1",
        parentEnumValue: "51",
        enumValue: "5101",
        enumName: "成都市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        enumNameother: "成都市",
        children: [
          {
            fldEnumId: 12348,
            enumGroupGuid: "1",
            parentEnumValue: "5101",
            enumValue: "510100",
            enumName: "成都市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
            enumNameother: "成都市",
          },
          {
            fldEnumId: 130312,
            enumGroupGuid: "1",
            parentEnumValue: "5101",
            enumValue: "510117",
            enumName: "郫都区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130346,
            enumGroupGuid: "1",
            parentEnumValue: "5101",
            enumValue: "510185",
            enumName: "简阳市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130207,
            enumGroupGuid: "1",
            parentEnumValue: "5101",
            enumValue: "512081",
            enumName: "简阳市(撤)",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 12350,
            enumGroupGuid: "1",
            parentEnumValue: "5101",
            enumValue: "510104",
            enumName: "锦江区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "锦江区",
          },
          {
            fldEnumId: 12352,
            enumGroupGuid: "1",
            parentEnumValue: "5101",
            enumValue: "510105",
            enumName: "青羊区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 2,
            enumNameother: "青羊区",
          },
          {
            fldEnumId: 12354,
            enumGroupGuid: "1",
            parentEnumValue: "5101",
            enumValue: "510106",
            enumName: "金牛区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 3,
            enumNameother: "金牛区",
          },
          {
            fldEnumId: 12356,
            enumGroupGuid: "1",
            parentEnumValue: "5101",
            enumValue: "510107",
            enumName: "武侯区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 4,
            enumNameother: "武侯区",
          },
          {
            fldEnumId: 12358,
            enumGroupGuid: "1",
            parentEnumValue: "5101",
            enumValue: "510108",
            enumName: "成华区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 5,
            enumNameother: "成华区",
          },
          {
            fldEnumId: 12360,
            enumGroupGuid: "1",
            parentEnumValue: "5101",
            enumValue: "510112",
            enumName: "龙泉驿区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 6,
            enumNameother: "龙泉驿区",
          },
          {
            fldEnumId: 12362,
            enumGroupGuid: "1",
            parentEnumValue: "5101",
            enumValue: "510113",
            enumName: "青白江区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 7,
            enumNameother: "青白江区",
          },
          {
            fldEnumId: 12364,
            enumGroupGuid: "1",
            parentEnumValue: "5101",
            enumValue: "510114",
            enumName: "新都区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 8,
            enumNameother: "新都区",
          },
          {
            fldEnumId: 12366,
            enumGroupGuid: "1",
            parentEnumValue: "5101",
            enumValue: "510115",
            enumName: "温江区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 9,
            enumNameother: "温江区",
          },
          {
            fldEnumId: 12368,
            enumGroupGuid: "1",
            parentEnumValue: "5101",
            enumValue: "510121",
            enumName: "金堂县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 10,
            enumNameother: "金堂县",
          },
          {
            fldEnumId: 12370,
            enumGroupGuid: "1",
            parentEnumValue: "5101",
            enumValue: "510122",
            enumName: "双流县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 11,
            enumNameother: "双流县",
          },
          {
            fldEnumId: 12372,
            enumGroupGuid: "1",
            parentEnumValue: "5101",
            enumValue: "510124",
            enumName: "郫县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 12,
            enumNameother: "郫县",
          },
          {
            fldEnumId: 12374,
            enumGroupGuid: "1",
            parentEnumValue: "5101",
            enumValue: "510129",
            enumName: "大邑县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 13,
            enumNameother: "大邑县",
          },
          {
            fldEnumId: 12376,
            enumGroupGuid: "1",
            parentEnumValue: "5101",
            enumValue: "510131",
            enumName: "蒲江县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 14,
            enumNameother: "蒲江县",
          },
          {
            fldEnumId: 12378,
            enumGroupGuid: "1",
            parentEnumValue: "5101",
            enumValue: "510132",
            enumName: "新津县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 15,
            enumNameother: "新津县",
          },
          {
            fldEnumId: 12380,
            enumGroupGuid: "1",
            parentEnumValue: "5101",
            enumValue: "510181",
            enumName: "都江堰市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 16,
            enumNameother: "都江堰市",
          },
          {
            fldEnumId: 12382,
            enumGroupGuid: "1",
            parentEnumValue: "5101",
            enumValue: "510182",
            enumName: "彭州市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 17,
            enumNameother: "彭州市",
          },
          {
            fldEnumId: 12384,
            enumGroupGuid: "1",
            parentEnumValue: "5101",
            enumValue: "510183",
            enumName: "邛崃市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 18,
            enumNameother: "邛崃市",
          },
          {
            fldEnumId: 12386,
            enumGroupGuid: "1",
            parentEnumValue: "5101",
            enumValue: "510184",
            enumName: "崇州市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 19,
            enumNameother: "崇州市",
          },
        ],
      },
      {
        fldEnumId: 12388,
        enumGroupGuid: "1",
        parentEnumValue: "51",
        enumValue: "5103",
        enumName: "自贡市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 1,
        enumNameother: "自贡市",
        children: [
          {
            fldEnumId: 12389,
            enumGroupGuid: "1",
            parentEnumValue: "5103",
            enumValue: "510300",
            enumName: "自贡市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 20,
            enumNameother: "自贡市",
          },
          {
            fldEnumId: 12391,
            enumGroupGuid: "1",
            parentEnumValue: "5103",
            enumValue: "510302",
            enumName: "自流井区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 21,
            enumNameother: "自流井区",
          },
          {
            fldEnumId: 12393,
            enumGroupGuid: "1",
            parentEnumValue: "5103",
            enumValue: "510303",
            enumName: "贡井区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 22,
            enumNameother: "贡井区",
          },
          {
            fldEnumId: 12395,
            enumGroupGuid: "1",
            parentEnumValue: "5103",
            enumValue: "510304",
            enumName: "大安区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 23,
            enumNameother: "大安区",
          },
          {
            fldEnumId: 12397,
            enumGroupGuid: "1",
            parentEnumValue: "5103",
            enumValue: "510311",
            enumName: "沿滩区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 24,
            enumNameother: "沿滩区",
          },
          {
            fldEnumId: 12399,
            enumGroupGuid: "1",
            parentEnumValue: "5103",
            enumValue: "510321",
            enumName: "荣县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 25,
            enumNameother: "荣县",
          },
          {
            fldEnumId: 12401,
            enumGroupGuid: "1",
            parentEnumValue: "5103",
            enumValue: "510322",
            enumName: "富顺县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 26,
            enumNameother: "富顺县",
          },
        ],
      },
      {
        fldEnumId: 12403,
        enumGroupGuid: "1",
        parentEnumValue: "51",
        enumValue: "5104",
        enumName: "攀枝花市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 2,
        enumNameother: "攀枝花市",
        children: [
          {
            fldEnumId: 12404,
            enumGroupGuid: "1",
            parentEnumValue: "5104",
            enumValue: "510400",
            enumName: "攀枝花市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 27,
            enumNameother: "攀枝花市",
          },
          {
            fldEnumId: 12406,
            enumGroupGuid: "1",
            parentEnumValue: "5104",
            enumValue: "510402",
            enumName: "东区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 28,
            enumNameother: "东区",
          },
          {
            fldEnumId: 12408,
            enumGroupGuid: "1",
            parentEnumValue: "5104",
            enumValue: "510403",
            enumName: "西区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 29,
            enumNameother: "西区",
          },
          {
            fldEnumId: 12410,
            enumGroupGuid: "1",
            parentEnumValue: "5104",
            enumValue: "510411",
            enumName: "仁和区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 30,
            enumNameother: "仁和区",
          },
          {
            fldEnumId: 12412,
            enumGroupGuid: "1",
            parentEnumValue: "5104",
            enumValue: "510421",
            enumName: "米易县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 31,
            enumNameother: "米易县",
          },
          {
            fldEnumId: 12414,
            enumGroupGuid: "1",
            parentEnumValue: "5104",
            enumValue: "510422",
            enumName: "盐边县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 32,
            enumNameother: "盐边县",
          },
        ],
      },
      {
        fldEnumId: 12416,
        enumGroupGuid: "1",
        parentEnumValue: "51",
        enumValue: "5105",
        enumName: "泸州市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 3,
        enumNameother: "泸州市",
        children: [
          {
            fldEnumId: 12417,
            enumGroupGuid: "1",
            parentEnumValue: "5105",
            enumValue: "510500",
            enumName: "泸州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 33,
            enumNameother: "泸州市",
          },
          {
            fldEnumId: 12419,
            enumGroupGuid: "1",
            parentEnumValue: "5105",
            enumValue: "510502",
            enumName: "江阳区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 34,
            enumNameother: "江阳区",
          },
          {
            fldEnumId: 12421,
            enumGroupGuid: "1",
            parentEnumValue: "5105",
            enumValue: "510503",
            enumName: "纳溪区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 35,
            enumNameother: "纳溪区",
          },
          {
            fldEnumId: 12423,
            enumGroupGuid: "1",
            parentEnumValue: "5105",
            enumValue: "510504",
            enumName: "龙马潭区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 36,
            enumNameother: "龙马潭区",
          },
          {
            fldEnumId: 12425,
            enumGroupGuid: "1",
            parentEnumValue: "5105",
            enumValue: "510521",
            enumName: "泸县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 37,
            enumNameother: "泸县",
          },
          {
            fldEnumId: 12427,
            enumGroupGuid: "1",
            parentEnumValue: "5105",
            enumValue: "510522",
            enumName: "合江县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 38,
            enumNameother: "合江县",
          },
          {
            fldEnumId: 12429,
            enumGroupGuid: "1",
            parentEnumValue: "5105",
            enumValue: "510524",
            enumName: "叙永县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 39,
            enumNameother: "叙永县",
          },
          {
            fldEnumId: 12431,
            enumGroupGuid: "1",
            parentEnumValue: "5105",
            enumValue: "510525",
            enumName: "古蔺县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 40,
            enumNameother: "古蔺县",
          },
        ],
      },
      {
        fldEnumId: 12433,
        enumGroupGuid: "1",
        parentEnumValue: "51",
        enumValue: "5106",
        enumName: "德阳市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 4,
        enumNameother: "德阳市",
        children: [
          {
            fldEnumId: 12434,
            enumGroupGuid: "1",
            parentEnumValue: "5106",
            enumValue: "510600",
            enumName: "德阳市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 41,
            enumNameother: "德阳市",
          },
          {
            fldEnumId: 12436,
            enumGroupGuid: "1",
            parentEnumValue: "5106",
            enumValue: "510603",
            enumName: "旌阳区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 42,
            enumNameother: "旌阳区",
          },
          {
            fldEnumId: 12438,
            enumGroupGuid: "1",
            parentEnumValue: "5106",
            enumValue: "510623",
            enumName: "中江县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 43,
            enumNameother: "中江县",
          },
          {
            fldEnumId: 12440,
            enumGroupGuid: "1",
            parentEnumValue: "5106",
            enumValue: "510626",
            enumName: "罗江县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 44,
            enumNameother: "罗江县",
          },
          {
            fldEnumId: 12442,
            enumGroupGuid: "1",
            parentEnumValue: "5106",
            enumValue: "510681",
            enumName: "广汉市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 45,
            enumNameother: "广汉市",
          },
          {
            fldEnumId: 12444,
            enumGroupGuid: "1",
            parentEnumValue: "5106",
            enumValue: "510682",
            enumName: "什邡市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 46,
            enumNameother: "什邡市",
          },
          {
            fldEnumId: 12446,
            enumGroupGuid: "1",
            parentEnumValue: "5106",
            enumValue: "510683",
            enumName: "绵竹市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 47,
            enumNameother: "绵竹市",
          },
        ],
      },
      {
        fldEnumId: 12448,
        enumGroupGuid: "1",
        parentEnumValue: "51",
        enumValue: "5107",
        enumName: "绵阳市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 5,
        enumNameother: "绵阳市",
        children: [
          {
            fldEnumId: 12449,
            enumGroupGuid: "1",
            parentEnumValue: "5107",
            enumValue: "510700",
            enumName: "绵阳市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 48,
            enumNameother: "绵阳市",
          },
          {
            fldEnumId: 12451,
            enumGroupGuid: "1",
            parentEnumValue: "5107",
            enumValue: "510703",
            enumName: "涪城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 49,
            enumNameother: "涪城区",
          },
          {
            fldEnumId: 12453,
            enumGroupGuid: "1",
            parentEnumValue: "5107",
            enumValue: "510704",
            enumName: "游仙区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 50,
            enumNameother: "游仙区",
          },
          {
            fldEnumId: 12455,
            enumGroupGuid: "1",
            parentEnumValue: "5107",
            enumValue: "510722",
            enumName: "三台县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 51,
            enumNameother: "三台县",
          },
          {
            fldEnumId: 12457,
            enumGroupGuid: "1",
            parentEnumValue: "5107",
            enumValue: "510723",
            enumName: "盐亭县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 52,
            enumNameother: "盐亭县",
          },
          {
            fldEnumId: 12459,
            enumGroupGuid: "1",
            parentEnumValue: "5107",
            enumValue: "510724",
            enumName: "安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 53,
            enumNameother: "安县",
          },
          {
            fldEnumId: 12461,
            enumGroupGuid: "1",
            parentEnumValue: "5107",
            enumValue: "510725",
            enumName: "梓潼县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 54,
            enumNameother: "梓潼县",
          },
          {
            fldEnumId: 12463,
            enumGroupGuid: "1",
            parentEnumValue: "5107",
            enumValue: "510726",
            enumName: "北川羌族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 55,
            enumNameother: "北川羌族自治县",
          },
          {
            fldEnumId: 12465,
            enumGroupGuid: "1",
            parentEnumValue: "5107",
            enumValue: "510727",
            enumName: "平武县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 56,
            enumNameother: "平武县",
          },
          {
            fldEnumId: 12467,
            enumGroupGuid: "1",
            parentEnumValue: "5107",
            enumValue: "510781",
            enumName: "江油市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 57,
            enumNameother: "江油市",
          },
        ],
      },
      {
        fldEnumId: 12469,
        enumGroupGuid: "1",
        parentEnumValue: "51",
        enumValue: "5108",
        enumName: "广元市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 6,
        enumNameother: "广元市",
        children: [
          {
            fldEnumId: 12470,
            enumGroupGuid: "1",
            parentEnumValue: "5108",
            enumValue: "510800",
            enumName: "广元市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 58,
            enumNameother: "广元市",
          },
          {
            fldEnumId: 12472,
            enumGroupGuid: "1",
            parentEnumValue: "5108",
            enumValue: "510802",
            enumName: "市中区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 59,
            enumNameother: "市中区",
          },
          {
            fldEnumId: 12474,
            enumGroupGuid: "1",
            parentEnumValue: "5108",
            enumValue: "510811",
            enumName: "元坝区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 60,
            enumNameother: "元坝区",
          },
          {
            fldEnumId: 12476,
            enumGroupGuid: "1",
            parentEnumValue: "5108",
            enumValue: "510812",
            enumName: "朝天区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 61,
            enumNameother: "朝天区",
          },
          {
            fldEnumId: 12478,
            enumGroupGuid: "1",
            parentEnumValue: "5108",
            enumValue: "510821",
            enumName: "旺苍县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 62,
            enumNameother: "旺苍县",
          },
          {
            fldEnumId: 12480,
            enumGroupGuid: "1",
            parentEnumValue: "5108",
            enumValue: "510822",
            enumName: "青川县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 63,
            enumNameother: "青川县",
          },
          {
            fldEnumId: 12482,
            enumGroupGuid: "1",
            parentEnumValue: "5108",
            enumValue: "510823",
            enumName: "剑阁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 64,
            enumNameother: "剑阁县",
          },
          {
            fldEnumId: 12484,
            enumGroupGuid: "1",
            parentEnumValue: "5108",
            enumValue: "510824",
            enumName: "苍溪县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 65,
            enumNameother: "苍溪县",
          },
        ],
      },
      {
        fldEnumId: 12486,
        enumGroupGuid: "1",
        parentEnumValue: "51",
        enumValue: "5109",
        enumName: "遂宁市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 7,
        enumNameother: "遂宁市",
        children: [
          {
            fldEnumId: 12487,
            enumGroupGuid: "1",
            parentEnumValue: "5109",
            enumValue: "510900",
            enumName: "遂宁市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 66,
            enumNameother: "遂宁市",
          },
          {
            fldEnumId: 12489,
            enumGroupGuid: "1",
            parentEnumValue: "5109",
            enumValue: "510903",
            enumName: "船山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 67,
            enumNameother: "船山区",
          },
          {
            fldEnumId: 12491,
            enumGroupGuid: "1",
            parentEnumValue: "5109",
            enumValue: "510904",
            enumName: "安居区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 68,
            enumNameother: "安居区",
          },
          {
            fldEnumId: 12493,
            enumGroupGuid: "1",
            parentEnumValue: "5109",
            enumValue: "510921",
            enumName: "蓬溪县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 69,
            enumNameother: "蓬溪县",
          },
          {
            fldEnumId: 12495,
            enumGroupGuid: "1",
            parentEnumValue: "5109",
            enumValue: "510922",
            enumName: "射洪县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 70,
            enumNameother: "射洪县",
          },
          {
            fldEnumId: 12497,
            enumGroupGuid: "1",
            parentEnumValue: "5109",
            enumValue: "510923",
            enumName: "大英县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 71,
            enumNameother: "大英县",
          },
        ],
      },
      {
        fldEnumId: 12499,
        enumGroupGuid: "1",
        parentEnumValue: "51",
        enumValue: "5110",
        enumName: "内江市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 8,
        enumNameother: "内江市",
        children: [
          {
            fldEnumId: 12500,
            enumGroupGuid: "1",
            parentEnumValue: "5110",
            enumValue: "511000",
            enumName: "内江市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 72,
            enumNameother: "内江市",
          },
          {
            fldEnumId: 12502,
            enumGroupGuid: "1",
            parentEnumValue: "5110",
            enumValue: "511002",
            enumName: "市中区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 73,
            enumNameother: "市中区",
          },
          {
            fldEnumId: 12504,
            enumGroupGuid: "1",
            parentEnumValue: "5110",
            enumValue: "511011",
            enumName: "东兴区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 74,
            enumNameother: "东兴区",
          },
          {
            fldEnumId: 12506,
            enumGroupGuid: "1",
            parentEnumValue: "5110",
            enumValue: "511024",
            enumName: "威远县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 75,
            enumNameother: "威远县",
          },
          {
            fldEnumId: 12508,
            enumGroupGuid: "1",
            parentEnumValue: "5110",
            enumValue: "511025",
            enumName: "资中县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 76,
            enumNameother: "资中县",
          },
          {
            fldEnumId: 12510,
            enumGroupGuid: "1",
            parentEnumValue: "5110",
            enumValue: "511028",
            enumName: "隆昌县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 77,
            enumNameother: "隆昌县",
          },
        ],
      },
      {
        fldEnumId: 12512,
        enumGroupGuid: "1",
        parentEnumValue: "51",
        enumValue: "5111",
        enumName: "乐山市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 9,
        enumNameother: "乐山市",
        children: [
          {
            fldEnumId: 12513,
            enumGroupGuid: "1",
            parentEnumValue: "5111",
            enumValue: "511100",
            enumName: "乐山市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 78,
            enumNameother: "乐山市",
          },
          {
            fldEnumId: 12515,
            enumGroupGuid: "1",
            parentEnumValue: "5111",
            enumValue: "511102",
            enumName: "市中区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 79,
            enumNameother: "市中区",
          },
          {
            fldEnumId: 12517,
            enumGroupGuid: "1",
            parentEnumValue: "5111",
            enumValue: "511111",
            enumName: "沙湾区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 80,
            enumNameother: "沙湾区",
          },
          {
            fldEnumId: 12519,
            enumGroupGuid: "1",
            parentEnumValue: "5111",
            enumValue: "511112",
            enumName: "五通桥区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 81,
            enumNameother: "五通桥区",
          },
          {
            fldEnumId: 12521,
            enumGroupGuid: "1",
            parentEnumValue: "5111",
            enumValue: "511113",
            enumName: "金口河区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 82,
            enumNameother: "金口河区",
          },
          {
            fldEnumId: 12523,
            enumGroupGuid: "1",
            parentEnumValue: "5111",
            enumValue: "511123",
            enumName: "犍为县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 83,
            enumNameother: "犍为县",
          },
          {
            fldEnumId: 12525,
            enumGroupGuid: "1",
            parentEnumValue: "5111",
            enumValue: "511124",
            enumName: "井研县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 84,
            enumNameother: "井研县",
          },
          {
            fldEnumId: 12527,
            enumGroupGuid: "1",
            parentEnumValue: "5111",
            enumValue: "511126",
            enumName: "夹江县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 85,
            enumNameother: "夹江县",
          },
          {
            fldEnumId: 12529,
            enumGroupGuid: "1",
            parentEnumValue: "5111",
            enumValue: "511129",
            enumName: "沐川县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 86,
            enumNameother: "沐川县",
          },
          {
            fldEnumId: 12531,
            enumGroupGuid: "1",
            parentEnumValue: "5111",
            enumValue: "511132",
            enumName: "峨边彝族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 87,
            enumNameother: "峨边彝族自治县",
          },
          {
            fldEnumId: 12533,
            enumGroupGuid: "1",
            parentEnumValue: "5111",
            enumValue: "511133",
            enumName: "马边彝族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 88,
            enumNameother: "马边彝族自治县",
          },
          {
            fldEnumId: 12535,
            enumGroupGuid: "1",
            parentEnumValue: "5111",
            enumValue: "511181",
            enumName: "峨眉山市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 89,
            enumNameother: "峨眉山市",
          },
        ],
      },
      {
        fldEnumId: 12537,
        enumGroupGuid: "1",
        parentEnumValue: "51",
        enumValue: "5113",
        enumName: "南充市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 10,
        enumNameother: "南充市",
        children: [
          {
            fldEnumId: 12538,
            enumGroupGuid: "1",
            parentEnumValue: "5113",
            enumValue: "511300",
            enumName: "南充市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 90,
            enumNameother: "南充市",
          },
          {
            fldEnumId: 12540,
            enumGroupGuid: "1",
            parentEnumValue: "5113",
            enumValue: "511302",
            enumName: "顺庆区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 91,
            enumNameother: "顺庆区",
          },
          {
            fldEnumId: 12542,
            enumGroupGuid: "1",
            parentEnumValue: "5113",
            enumValue: "511303",
            enumName: "高坪区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 92,
            enumNameother: "高坪区",
          },
          {
            fldEnumId: 12544,
            enumGroupGuid: "1",
            parentEnumValue: "5113",
            enumValue: "511304",
            enumName: "嘉陵区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 93,
            enumNameother: "嘉陵区",
          },
          {
            fldEnumId: 12546,
            enumGroupGuid: "1",
            parentEnumValue: "5113",
            enumValue: "511321",
            enumName: "南部县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 94,
            enumNameother: "南部县",
          },
          {
            fldEnumId: 12548,
            enumGroupGuid: "1",
            parentEnumValue: "5113",
            enumValue: "511322",
            enumName: "营山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 95,
            enumNameother: "营山县",
          },
          {
            fldEnumId: 12550,
            enumGroupGuid: "1",
            parentEnumValue: "5113",
            enumValue: "511323",
            enumName: "蓬安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 96,
            enumNameother: "蓬安县",
          },
          {
            fldEnumId: 12552,
            enumGroupGuid: "1",
            parentEnumValue: "5113",
            enumValue: "511324",
            enumName: "仪陇县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 97,
            enumNameother: "仪陇县",
          },
          {
            fldEnumId: 12554,
            enumGroupGuid: "1",
            parentEnumValue: "5113",
            enumValue: "511325",
            enumName: "西充县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 98,
            enumNameother: "西充县",
          },
          {
            fldEnumId: 12556,
            enumGroupGuid: "1",
            parentEnumValue: "5113",
            enumValue: "511381",
            enumName: "阆中市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 99,
            enumNameother: "阆中市",
          },
        ],
      },
      {
        fldEnumId: 12558,
        enumGroupGuid: "1",
        parentEnumValue: "51",
        enumValue: "5114",
        enumName: "眉山市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 11,
        enumNameother: "眉山市",
        children: [
          {
            fldEnumId: 12559,
            enumGroupGuid: "1",
            parentEnumValue: "5114",
            enumValue: "511400",
            enumName: "眉山市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 100,
            enumNameother: "眉山市",
          },
          {
            fldEnumId: 12561,
            enumGroupGuid: "1",
            parentEnumValue: "5114",
            enumValue: "511402",
            enumName: "东坡区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 101,
            enumNameother: "东坡区",
          },
          {
            fldEnumId: 12563,
            enumGroupGuid: "1",
            parentEnumValue: "5114",
            enumValue: "511421",
            enumName: "仁寿县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 102,
            enumNameother: "仁寿县",
          },
          {
            fldEnumId: 12565,
            enumGroupGuid: "1",
            parentEnumValue: "5114",
            enumValue: "511422",
            enumName: "彭山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 103,
            enumNameother: "彭山县",
          },
          {
            fldEnumId: 12567,
            enumGroupGuid: "1",
            parentEnumValue: "5114",
            enumValue: "511423",
            enumName: "洪雅县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 104,
            enumNameother: "洪雅县",
          },
          {
            fldEnumId: 12569,
            enumGroupGuid: "1",
            parentEnumValue: "5114",
            enumValue: "511424",
            enumName: "丹棱县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 105,
            enumNameother: "丹棱县",
          },
          {
            fldEnumId: 12571,
            enumGroupGuid: "1",
            parentEnumValue: "5114",
            enumValue: "511425",
            enumName: "青神县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 106,
            enumNameother: "青神县",
          },
        ],
      },
      {
        fldEnumId: 12573,
        enumGroupGuid: "1",
        parentEnumValue: "51",
        enumValue: "5115",
        enumName: "宜宾市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 12,
        enumNameother: "宜宾市",
        children: [
          {
            fldEnumId: 12574,
            enumGroupGuid: "1",
            parentEnumValue: "5115",
            enumValue: "511500",
            enumName: "宜宾市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 107,
            enumNameother: "宜宾市",
          },
          {
            fldEnumId: 12576,
            enumGroupGuid: "1",
            parentEnumValue: "5115",
            enumValue: "511502",
            enumName: "翠屏区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 108,
            enumNameother: "翠屏区",
          },
          {
            fldEnumId: 12578,
            enumGroupGuid: "1",
            parentEnumValue: "5115",
            enumValue: "511521",
            enumName: "宜宾县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 109,
            enumNameother: "宜宾县",
          },
          {
            fldEnumId: 12580,
            enumGroupGuid: "1",
            parentEnumValue: "5115",
            enumValue: "511522",
            enumName: "南溪县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 110,
            enumNameother: "南溪县",
          },
          {
            fldEnumId: 12582,
            enumGroupGuid: "1",
            parentEnumValue: "5115",
            enumValue: "511523",
            enumName: "江安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 111,
            enumNameother: "江安县",
          },
          {
            fldEnumId: 12584,
            enumGroupGuid: "1",
            parentEnumValue: "5115",
            enumValue: "511524",
            enumName: "长宁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 112,
            enumNameother: "长宁县",
          },
          {
            fldEnumId: 12586,
            enumGroupGuid: "1",
            parentEnumValue: "5115",
            enumValue: "511525",
            enumName: "高县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 113,
            enumNameother: "高县",
          },
          {
            fldEnumId: 12588,
            enumGroupGuid: "1",
            parentEnumValue: "5115",
            enumValue: "511526",
            enumName: "珙县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 114,
            enumNameother: "珙县",
          },
          {
            fldEnumId: 12590,
            enumGroupGuid: "1",
            parentEnumValue: "5115",
            enumValue: "511527",
            enumName: "筠连县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 115,
            enumNameother: "筠连县",
          },
          {
            fldEnumId: 12592,
            enumGroupGuid: "1",
            parentEnumValue: "5115",
            enumValue: "511528",
            enumName: "兴文县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 116,
            enumNameother: "兴文县",
          },
          {
            fldEnumId: 12594,
            enumGroupGuid: "1",
            parentEnumValue: "5115",
            enumValue: "511529",
            enumName: "屏山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 117,
            enumNameother: "屏山县",
          },
        ],
      },
      {
        fldEnumId: 12596,
        enumGroupGuid: "1",
        parentEnumValue: "51",
        enumValue: "5116",
        enumName: "广安市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 13,
        enumNameother: "广安市",
        children: [
          {
            fldEnumId: 130208,
            enumGroupGuid: "1",
            parentEnumValue: "5116",
            enumValue: "511603",
            enumName: "前锋区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 12597,
            enumGroupGuid: "1",
            parentEnumValue: "5116",
            enumValue: "511600",
            enumName: "广安市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 118,
            enumNameother: "广安市",
          },
          {
            fldEnumId: 12599,
            enumGroupGuid: "1",
            parentEnumValue: "5116",
            enumValue: "511602",
            enumName: "广安区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 119,
            enumNameother: "广安区",
          },
          {
            fldEnumId: 12601,
            enumGroupGuid: "1",
            parentEnumValue: "5116",
            enumValue: "511621",
            enumName: "岳池县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 120,
            enumNameother: "岳池县",
          },
          {
            fldEnumId: 12603,
            enumGroupGuid: "1",
            parentEnumValue: "5116",
            enumValue: "511622",
            enumName: "武胜县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 121,
            enumNameother: "武胜县",
          },
          {
            fldEnumId: 12605,
            enumGroupGuid: "1",
            parentEnumValue: "5116",
            enumValue: "511623",
            enumName: "邻水县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 122,
            enumNameother: "邻水县",
          },
          {
            fldEnumId: 12607,
            enumGroupGuid: "1",
            parentEnumValue: "5116",
            enumValue: "511681",
            enumName: "华蓥市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 123,
            enumNameother: "华蓥市",
          },
        ],
      },
      {
        fldEnumId: 12609,
        enumGroupGuid: "1",
        parentEnumValue: "51",
        enumValue: "5117",
        enumName: "达州市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 14,
        enumNameother: "达州市",
        children: [
          {
            fldEnumId: 12610,
            enumGroupGuid: "1",
            parentEnumValue: "5117",
            enumValue: "511700",
            enumName: "达州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 124,
            enumNameother: "达州市",
          },
          {
            fldEnumId: 12612,
            enumGroupGuid: "1",
            parentEnumValue: "5117",
            enumValue: "511702",
            enumName: "通川区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 125,
            enumNameother: "通川区",
          },
          {
            fldEnumId: 12614,
            enumGroupGuid: "1",
            parentEnumValue: "5117",
            enumValue: "511721",
            enumName: "达县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 126,
            enumNameother: "达县",
          },
          {
            fldEnumId: 12616,
            enumGroupGuid: "1",
            parentEnumValue: "5117",
            enumValue: "511722",
            enumName: "宣汉县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 127,
            enumNameother: "宣汉县",
          },
          {
            fldEnumId: 12618,
            enumGroupGuid: "1",
            parentEnumValue: "5117",
            enumValue: "511723",
            enumName: "开江县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 128,
            enumNameother: "开江县",
          },
          {
            fldEnumId: 12620,
            enumGroupGuid: "1",
            parentEnumValue: "5117",
            enumValue: "511724",
            enumName: "大竹县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 129,
            enumNameother: "大竹县",
          },
          {
            fldEnumId: 12622,
            enumGroupGuid: "1",
            parentEnumValue: "5117",
            enumValue: "511725",
            enumName: "渠县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 130,
            enumNameother: "渠县",
          },
          {
            fldEnumId: 12624,
            enumGroupGuid: "1",
            parentEnumValue: "5117",
            enumValue: "511781",
            enumName: "万源市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 131,
            enumNameother: "万源市",
          },
        ],
      },
      {
        fldEnumId: 12626,
        enumGroupGuid: "1",
        parentEnumValue: "51",
        enumValue: "5118",
        enumName: "雅安市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 15,
        enumNameother: "雅安市",
        children: [
          {
            fldEnumId: 12627,
            enumGroupGuid: "1",
            parentEnumValue: "5118",
            enumValue: "511800",
            enumName: "雅安市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 132,
            enumNameother: "雅安市",
          },
          {
            fldEnumId: 12629,
            enumGroupGuid: "1",
            parentEnumValue: "5118",
            enumValue: "511802",
            enumName: "雨城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 133,
            enumNameother: "雨城区",
          },
          {
            fldEnumId: 12631,
            enumGroupGuid: "1",
            parentEnumValue: "5118",
            enumValue: "511821",
            enumName: "名山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 134,
            enumNameother: "名山县",
          },
          {
            fldEnumId: 12633,
            enumGroupGuid: "1",
            parentEnumValue: "5118",
            enumValue: "511822",
            enumName: "荥经县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 135,
            enumNameother: "荥经县",
          },
          {
            fldEnumId: 12635,
            enumGroupGuid: "1",
            parentEnumValue: "5118",
            enumValue: "511823",
            enumName: "汉源县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 136,
            enumNameother: "汉源县",
          },
          {
            fldEnumId: 12637,
            enumGroupGuid: "1",
            parentEnumValue: "5118",
            enumValue: "511824",
            enumName: "石棉县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 137,
            enumNameother: "石棉县",
          },
          {
            fldEnumId: 12639,
            enumGroupGuid: "1",
            parentEnumValue: "5118",
            enumValue: "511825",
            enumName: "天全县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 138,
            enumNameother: "天全县",
          },
          {
            fldEnumId: 12641,
            enumGroupGuid: "1",
            parentEnumValue: "5118",
            enumValue: "511826",
            enumName: "芦山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 139,
            enumNameother: "芦山县",
          },
          {
            fldEnumId: 12643,
            enumGroupGuid: "1",
            parentEnumValue: "5118",
            enumValue: "511827",
            enumName: "宝兴县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 140,
            enumNameother: "宝兴县",
          },
        ],
      },
      {
        fldEnumId: 12645,
        enumGroupGuid: "1",
        parentEnumValue: "51",
        enumValue: "5119",
        enumName: "巴中市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 16,
        enumNameother: "巴中市",
        children: [
          {
            fldEnumId: 130209,
            enumGroupGuid: "1",
            parentEnumValue: "5119",
            enumValue: "511903",
            enumName: "恩阳区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 12646,
            enumGroupGuid: "1",
            parentEnumValue: "5119",
            enumValue: "511900",
            enumName: "巴中市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 141,
            enumNameother: "巴中市",
          },
          {
            fldEnumId: 12648,
            enumGroupGuid: "1",
            parentEnumValue: "5119",
            enumValue: "511902",
            enumName: "巴州区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 142,
            enumNameother: "巴州区",
          },
          {
            fldEnumId: 12650,
            enumGroupGuid: "1",
            parentEnumValue: "5119",
            enumValue: "511921",
            enumName: "通江县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 143,
            enumNameother: "通江县",
          },
          {
            fldEnumId: 12652,
            enumGroupGuid: "1",
            parentEnumValue: "5119",
            enumValue: "511922",
            enumName: "南江县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 144,
            enumNameother: "南江县",
          },
          {
            fldEnumId: 12654,
            enumGroupGuid: "1",
            parentEnumValue: "5119",
            enumValue: "511923",
            enumName: "平昌县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 145,
            enumNameother: "平昌县",
          },
        ],
      },
      {
        fldEnumId: 12656,
        enumGroupGuid: "1",
        parentEnumValue: "51",
        enumValue: "5120",
        enumName: "资阳市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 17,
        enumNameother: "资阳市",
        children: [
          {
            fldEnumId: 12657,
            enumGroupGuid: "1",
            parentEnumValue: "5120",
            enumValue: "512000",
            enumName: "资阳市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 146,
            enumNameother: "资阳市",
          },
          {
            fldEnumId: 12659,
            enumGroupGuid: "1",
            parentEnumValue: "5120",
            enumValue: "512002",
            enumName: "雁江区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 147,
            enumNameother: "雁江区",
          },
          {
            fldEnumId: 12661,
            enumGroupGuid: "1",
            parentEnumValue: "5120",
            enumValue: "512021",
            enumName: "安岳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 148,
            enumNameother: "安岳县",
          },
          {
            fldEnumId: 12663,
            enumGroupGuid: "1",
            parentEnumValue: "5120",
            enumValue: "512022",
            enumName: "乐至县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 149,
            enumNameother: "乐至县",
          },
        ],
      },
      {
        fldEnumId: 12667,
        enumGroupGuid: "1",
        parentEnumValue: "51",
        enumValue: "5132",
        enumName: "阿坝藏族羌族自治州",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 18,
        enumNameother: "阿坝藏族羌族自治州",
        children: [
          {
            fldEnumId: 16168,
            enumGroupGuid: "1",
            parentEnumValue: "5132",
            enumValue: "513200",
            enumName: "阿坝藏族羌族自治州本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 12668,
            enumGroupGuid: "1",
            parentEnumValue: "5132",
            enumValue: "513221",
            enumName: "汶川县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 151,
            enumNameother: "汶川县",
          },
          {
            fldEnumId: 12670,
            enumGroupGuid: "1",
            parentEnumValue: "5132",
            enumValue: "513222",
            enumName: "理县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 152,
            enumNameother: "理县",
          },
          {
            fldEnumId: 12672,
            enumGroupGuid: "1",
            parentEnumValue: "5132",
            enumValue: "513223",
            enumName: "茂县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 153,
            enumNameother: "茂县",
          },
          {
            fldEnumId: 12674,
            enumGroupGuid: "1",
            parentEnumValue: "5132",
            enumValue: "513224",
            enumName: "松潘县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 154,
            enumNameother: "松潘县",
          },
          {
            fldEnumId: 12676,
            enumGroupGuid: "1",
            parentEnumValue: "5132",
            enumValue: "513225",
            enumName: "九寨沟县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 155,
            enumNameother: "九寨沟县",
          },
          {
            fldEnumId: 12678,
            enumGroupGuid: "1",
            parentEnumValue: "5132",
            enumValue: "513226",
            enumName: "金川县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 156,
            enumNameother: "金川县",
          },
          {
            fldEnumId: 12680,
            enumGroupGuid: "1",
            parentEnumValue: "5132",
            enumValue: "513227",
            enumName: "小金县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 157,
            enumNameother: "小金县",
          },
          {
            fldEnumId: 12682,
            enumGroupGuid: "1",
            parentEnumValue: "5132",
            enumValue: "513228",
            enumName: "黑水县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 158,
            enumNameother: "黑水县",
          },
          {
            fldEnumId: 12684,
            enumGroupGuid: "1",
            parentEnumValue: "5132",
            enumValue: "513229",
            enumName: "马尔康县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 159,
            enumNameother: "马尔康县",
          },
          {
            fldEnumId: 12686,
            enumGroupGuid: "1",
            parentEnumValue: "5132",
            enumValue: "513230",
            enumName: "壤塘县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 160,
            enumNameother: "壤塘县",
          },
          {
            fldEnumId: 12688,
            enumGroupGuid: "1",
            parentEnumValue: "5132",
            enumValue: "513231",
            enumName: "阿坝县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 161,
            enumNameother: "阿坝县",
          },
          {
            fldEnumId: 12690,
            enumGroupGuid: "1",
            parentEnumValue: "5132",
            enumValue: "513232",
            enumName: "若尔盖县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 162,
            enumNameother: "若尔盖县",
          },
          {
            fldEnumId: 12692,
            enumGroupGuid: "1",
            parentEnumValue: "5132",
            enumValue: "513233",
            enumName: "红原县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 163,
            enumNameother: "红原县",
          },
        ],
      },
      {
        fldEnumId: 12694,
        enumGroupGuid: "1",
        parentEnumValue: "51",
        enumValue: "5133",
        enumName: "甘孜藏族自治州",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 19,
        enumNameother: "甘孜藏族自治州",
        children: [
          {
            fldEnumId: 16288,
            enumGroupGuid: "1",
            parentEnumValue: "5133",
            enumValue: "513300",
            enumName: "甘孜藏族自治州本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 12695,
            enumGroupGuid: "1",
            parentEnumValue: "5133",
            enumValue: "513321",
            enumName: "康定县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 164,
            enumNameother: "康定县",
          },
          {
            fldEnumId: 12697,
            enumGroupGuid: "1",
            parentEnumValue: "5133",
            enumValue: "513322",
            enumName: "泸定县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 165,
            enumNameother: "泸定县",
          },
          {
            fldEnumId: 12699,
            enumGroupGuid: "1",
            parentEnumValue: "5133",
            enumValue: "513323",
            enumName: "丹巴县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 166,
            enumNameother: "丹巴县",
          },
          {
            fldEnumId: 12701,
            enumGroupGuid: "1",
            parentEnumValue: "5133",
            enumValue: "513324",
            enumName: "九龙县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 167,
            enumNameother: "九龙县",
          },
          {
            fldEnumId: 12703,
            enumGroupGuid: "1",
            parentEnumValue: "5133",
            enumValue: "513325",
            enumName: "雅江县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 168,
            enumNameother: "雅江县",
          },
          {
            fldEnumId: 12705,
            enumGroupGuid: "1",
            parentEnumValue: "5133",
            enumValue: "513326",
            enumName: "道孚县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 169,
            enumNameother: "道孚县",
          },
          {
            fldEnumId: 12707,
            enumGroupGuid: "1",
            parentEnumValue: "5133",
            enumValue: "513327",
            enumName: "炉霍县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 170,
            enumNameother: "炉霍县",
          },
          {
            fldEnumId: 12709,
            enumGroupGuid: "1",
            parentEnumValue: "5133",
            enumValue: "513328",
            enumName: "甘孜县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 171,
            enumNameother: "甘孜县",
          },
          {
            fldEnumId: 12711,
            enumGroupGuid: "1",
            parentEnumValue: "5133",
            enumValue: "513329",
            enumName: "新龙县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 172,
            enumNameother: "新龙县",
          },
          {
            fldEnumId: 12713,
            enumGroupGuid: "1",
            parentEnumValue: "5133",
            enumValue: "513330",
            enumName: "德格县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 173,
            enumNameother: "德格县",
          },
          {
            fldEnumId: 12715,
            enumGroupGuid: "1",
            parentEnumValue: "5133",
            enumValue: "513331",
            enumName: "白玉县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 174,
            enumNameother: "白玉县",
          },
          {
            fldEnumId: 12717,
            enumGroupGuid: "1",
            parentEnumValue: "5133",
            enumValue: "513332",
            enumName: "石渠县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 175,
            enumNameother: "石渠县",
          },
          {
            fldEnumId: 12719,
            enumGroupGuid: "1",
            parentEnumValue: "5133",
            enumValue: "513333",
            enumName: "色达县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 176,
            enumNameother: "色达县",
          },
          {
            fldEnumId: 12721,
            enumGroupGuid: "1",
            parentEnumValue: "5133",
            enumValue: "513334",
            enumName: "理塘县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 177,
            enumNameother: "理塘县",
          },
          {
            fldEnumId: 12723,
            enumGroupGuid: "1",
            parentEnumValue: "5133",
            enumValue: "513335",
            enumName: "巴塘县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 178,
            enumNameother: "巴塘县",
          },
          {
            fldEnumId: 12725,
            enumGroupGuid: "1",
            parentEnumValue: "5133",
            enumValue: "513336",
            enumName: "乡城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 179,
            enumNameother: "乡城县",
          },
          {
            fldEnumId: 12727,
            enumGroupGuid: "1",
            parentEnumValue: "5133",
            enumValue: "513337",
            enumName: "稻城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 180,
            enumNameother: "稻城县",
          },
          {
            fldEnumId: 12729,
            enumGroupGuid: "1",
            parentEnumValue: "5133",
            enumValue: "513338",
            enumName: "得荣县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 181,
            enumNameother: "得荣县",
          },
        ],
      },
      {
        fldEnumId: 12731,
        enumGroupGuid: "1",
        parentEnumValue: "51",
        enumValue: "5134",
        enumName: "凉山彝族自治州",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 20,
        enumNameother: "凉山彝族自治州",
        children: [
          {
            fldEnumId: 16290,
            enumGroupGuid: "1",
            parentEnumValue: "5134",
            enumValue: "513400",
            enumName: "凉山彝族自治州本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 12732,
            enumGroupGuid: "1",
            parentEnumValue: "5134",
            enumValue: "513401",
            enumName: "西昌市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 182,
            enumNameother: "西昌市",
          },
          {
            fldEnumId: 12734,
            enumGroupGuid: "1",
            parentEnumValue: "5134",
            enumValue: "513422",
            enumName: "木里藏族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 183,
            enumNameother: "木里藏族自治县",
          },
          {
            fldEnumId: 12736,
            enumGroupGuid: "1",
            parentEnumValue: "5134",
            enumValue: "513423",
            enumName: "盐源县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 184,
            enumNameother: "盐源县",
          },
          {
            fldEnumId: 12738,
            enumGroupGuid: "1",
            parentEnumValue: "5134",
            enumValue: "513424",
            enumName: "德昌县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 185,
            enumNameother: "德昌县",
          },
          {
            fldEnumId: 12740,
            enumGroupGuid: "1",
            parentEnumValue: "5134",
            enumValue: "513425",
            enumName: "会理县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 186,
            enumNameother: "会理县",
          },
          {
            fldEnumId: 12742,
            enumGroupGuid: "1",
            parentEnumValue: "5134",
            enumValue: "513426",
            enumName: "会东县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 187,
            enumNameother: "会东县",
          },
          {
            fldEnumId: 12744,
            enumGroupGuid: "1",
            parentEnumValue: "5134",
            enumValue: "513427",
            enumName: "宁南县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 188,
            enumNameother: "宁南县",
          },
          {
            fldEnumId: 12746,
            enumGroupGuid: "1",
            parentEnumValue: "5134",
            enumValue: "513428",
            enumName: "普格县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 189,
            enumNameother: "普格县",
          },
          {
            fldEnumId: 12748,
            enumGroupGuid: "1",
            parentEnumValue: "5134",
            enumValue: "513429",
            enumName: "布拖县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 190,
            enumNameother: "布拖县",
          },
          {
            fldEnumId: 12750,
            enumGroupGuid: "1",
            parentEnumValue: "5134",
            enumValue: "513430",
            enumName: "金阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 191,
            enumNameother: "金阳县",
          },
          {
            fldEnumId: 12752,
            enumGroupGuid: "1",
            parentEnumValue: "5134",
            enumValue: "513431",
            enumName: "昭觉县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 192,
            enumNameother: "昭觉县",
          },
          {
            fldEnumId: 12754,
            enumGroupGuid: "1",
            parentEnumValue: "5134",
            enumValue: "513432",
            enumName: "喜德县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 193,
            enumNameother: "喜德县",
          },
          {
            fldEnumId: 12756,
            enumGroupGuid: "1",
            parentEnumValue: "5134",
            enumValue: "513433",
            enumName: "冕宁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 194,
            enumNameother: "冕宁县",
          },
          {
            fldEnumId: 12758,
            enumGroupGuid: "1",
            parentEnumValue: "5134",
            enumValue: "513434",
            enumName: "越西县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 195,
            enumNameother: "越西县",
          },
          {
            fldEnumId: 12760,
            enumGroupGuid: "1",
            parentEnumValue: "5134",
            enumValue: "513435",
            enumName: "甘洛县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 196,
            enumNameother: "甘洛县",
          },
          {
            fldEnumId: 12762,
            enumGroupGuid: "1",
            parentEnumValue: "5134",
            enumValue: "513436",
            enumName: "美姑县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 197,
            enumNameother: "美姑县",
          },
          {
            fldEnumId: 12764,
            enumGroupGuid: "1",
            parentEnumValue: "5134",
            enumValue: "513437",
            enumName: "雷波县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 198,
            enumNameother: "雷波县",
          },
        ],
      },
    ],
  },
  {
    enumValue: "52",
    enumName: "贵州省",
    children: [
      {
        fldEnumId: 12767,
        enumGroupGuid: "1",
        parentEnumValue: "52",
        enumValue: "5201",
        enumName: "贵阳市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        enumNameother: "贵阳市",
        children: [
          {
            fldEnumId: 12768,
            enumGroupGuid: "1",
            parentEnumValue: "5201",
            enumValue: "520100",
            enumName: "贵阳市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
            enumNameother: "贵阳市",
          },
          {
            fldEnumId: 12770,
            enumGroupGuid: "1",
            parentEnumValue: "5201",
            enumValue: "520102",
            enumName: "南明区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "南明区",
          },
          {
            fldEnumId: 12772,
            enumGroupGuid: "1",
            parentEnumValue: "5201",
            enumValue: "520103",
            enumName: "云岩区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 2,
            enumNameother: "云岩区",
          },
          {
            fldEnumId: 12774,
            enumGroupGuid: "1",
            parentEnumValue: "5201",
            enumValue: "520111",
            enumName: "花溪区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 3,
            enumNameother: "花溪区",
          },
          {
            fldEnumId: 12776,
            enumGroupGuid: "1",
            parentEnumValue: "5201",
            enumValue: "520112",
            enumName: "乌当区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 4,
            enumNameother: "乌当区",
          },
          {
            fldEnumId: 12778,
            enumGroupGuid: "1",
            parentEnumValue: "5201",
            enumValue: "520113",
            enumName: "白云区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 5,
            enumNameother: "白云区",
          },
          {
            fldEnumId: 12780,
            enumGroupGuid: "1",
            parentEnumValue: "5201",
            enumValue: "520114",
            enumName: "小河区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 6,
            enumNameother: "小河区",
          },
          {
            fldEnumId: 12782,
            enumGroupGuid: "1",
            parentEnumValue: "5201",
            enumValue: "520121",
            enumName: "开阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 7,
            enumNameother: "开阳县",
          },
          {
            fldEnumId: 12784,
            enumGroupGuid: "1",
            parentEnumValue: "5201",
            enumValue: "520122",
            enumName: "息烽县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 8,
            enumNameother: "息烽县",
          },
          {
            fldEnumId: 12786,
            enumGroupGuid: "1",
            parentEnumValue: "5201",
            enumValue: "520123",
            enumName: "修文县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 9,
            enumNameother: "修文县",
          },
          {
            fldEnumId: 12788,
            enumGroupGuid: "1",
            parentEnumValue: "5201",
            enumValue: "520181",
            enumName: "清镇市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 10,
            enumNameother: "清镇市",
          },
        ],
      },
      {
        fldEnumId: 130385,
        enumGroupGuid: "1",
        parentEnumValue: "52",
        enumValue: "5205",
        enumName: "毕节市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        children: [
          {
            fldEnumId: 130310,
            enumGroupGuid: "1",
            parentEnumValue: "5205",
            enumValue: "520500",
            enumName: "毕节市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130383,
            enumGroupGuid: "1",
            parentEnumValue: "5205",
            enumValue: "520502",
            enumName: "七星关区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130210,
            enumGroupGuid: "1",
            parentEnumValue: "5205",
            enumValue: "522422",
            enumName: "大方县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130211,
            enumGroupGuid: "1",
            parentEnumValue: "5205",
            enumValue: "522423",
            enumName: "黔西县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130212,
            enumGroupGuid: "1",
            parentEnumValue: "5205",
            enumValue: "522424",
            enumName: "金沙县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130213,
            enumGroupGuid: "1",
            parentEnumValue: "5205",
            enumValue: "522425",
            enumName: "织金县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130214,
            enumGroupGuid: "1",
            parentEnumValue: "5205",
            enumValue: "522426",
            enumName: "纳雍县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130215,
            enumGroupGuid: "1",
            parentEnumValue: "5205",
            enumValue: "522427",
            enumName: "威宁彝族回族苗族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130216,
            enumGroupGuid: "1",
            parentEnumValue: "5205",
            enumValue: "522428",
            enumName: "赫章县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
        ],
      },
      {
        fldEnumId: 130355,
        enumGroupGuid: "1",
        parentEnumValue: "52",
        enumValue: "5206",
        enumName: "铜仁市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        children: [
          {
            fldEnumId: 130308,
            enumGroupGuid: "1",
            parentEnumValue: "5206",
            enumValue: "520600",
            enumName: "铜仁市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130307,
            enumGroupGuid: "1",
            parentEnumValue: "5206",
            enumValue: "520602",
            enumName: "碧江区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130217,
            enumGroupGuid: "1",
            parentEnumValue: "5206",
            enumValue: "522222",
            enumName: "江口县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130218,
            enumGroupGuid: "1",
            parentEnumValue: "5206",
            enumValue: "522223",
            enumName: "玉屏侗族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130219,
            enumGroupGuid: "1",
            parentEnumValue: "5206",
            enumValue: "522224",
            enumName: "石阡县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130220,
            enumGroupGuid: "1",
            parentEnumValue: "5206",
            enumValue: "522225",
            enumName: "思南县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130221,
            enumGroupGuid: "1",
            parentEnumValue: "5206",
            enumValue: "522226",
            enumName: "印江土家族苗族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130222,
            enumGroupGuid: "1",
            parentEnumValue: "5206",
            enumValue: "522227",
            enumName: "德江县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130223,
            enumGroupGuid: "1",
            parentEnumValue: "5206",
            enumValue: "522228",
            enumName: "沿河土家族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130224,
            enumGroupGuid: "1",
            parentEnumValue: "5206",
            enumValue: "522229",
            enumName: "松桃苗族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
        ],
      },
      {
        fldEnumId: 12790,
        enumGroupGuid: "1",
        parentEnumValue: "52",
        enumValue: "5202",
        enumName: "六盘水市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 1,
        enumNameother: "六盘水市",
        children: [
          {
            fldEnumId: 16172,
            enumGroupGuid: "1",
            parentEnumValue: "5202",
            enumValue: "520200",
            enumName: "六盘水市本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 12791,
            enumGroupGuid: "1",
            parentEnumValue: "5202",
            enumValue: "520201",
            enumName: "钟山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 11,
            enumNameother: "钟山区",
          },
          {
            fldEnumId: 12793,
            enumGroupGuid: "1",
            parentEnumValue: "5202",
            enumValue: "520203",
            enumName: "六枝特区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 12,
            enumNameother: "六枝特区",
          },
          {
            fldEnumId: 12795,
            enumGroupGuid: "1",
            parentEnumValue: "5202",
            enumValue: "520221",
            enumName: "水城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 13,
            enumNameother: "水城县",
          },
          {
            fldEnumId: 12797,
            enumGroupGuid: "1",
            parentEnumValue: "5202",
            enumValue: "520222",
            enumName: "盘县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 14,
            enumNameother: "盘县",
          },
        ],
      },
      {
        fldEnumId: 12799,
        enumGroupGuid: "1",
        parentEnumValue: "52",
        enumValue: "5203",
        enumName: "遵义市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 2,
        enumNameother: "遵义市",
        children: [
          {
            fldEnumId: 12800,
            enumGroupGuid: "1",
            parentEnumValue: "5203",
            enumValue: "520300",
            enumName: "遵义市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 15,
            enumNameother: "遵义市",
          },
          {
            fldEnumId: 12802,
            enumGroupGuid: "1",
            parentEnumValue: "5203",
            enumValue: "520302",
            enumName: "红花岗区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 16,
            enumNameother: "红花岗区",
          },
          {
            fldEnumId: 12804,
            enumGroupGuid: "1",
            parentEnumValue: "5203",
            enumValue: "520303",
            enumName: "汇川区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 17,
            enumNameother: "汇川区",
          },
          {
            fldEnumId: 12806,
            enumGroupGuid: "1",
            parentEnumValue: "5203",
            enumValue: "520321",
            enumName: "遵义县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 18,
            enumNameother: "遵义县",
          },
          {
            fldEnumId: 12808,
            enumGroupGuid: "1",
            parentEnumValue: "5203",
            enumValue: "520322",
            enumName: "桐梓县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 19,
            enumNameother: "桐梓县",
          },
          {
            fldEnumId: 12810,
            enumGroupGuid: "1",
            parentEnumValue: "5203",
            enumValue: "520323",
            enumName: "绥阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 20,
            enumNameother: "绥阳县",
          },
          {
            fldEnumId: 12812,
            enumGroupGuid: "1",
            parentEnumValue: "5203",
            enumValue: "520324",
            enumName: "正安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 21,
            enumNameother: "正安县",
          },
          {
            fldEnumId: 12814,
            enumGroupGuid: "1",
            parentEnumValue: "5203",
            enumValue: "520325",
            enumName: "道真仡佬族苗族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 22,
            enumNameother: "道真仡佬族苗族自治县",
          },
          {
            fldEnumId: 12816,
            enumGroupGuid: "1",
            parentEnumValue: "5203",
            enumValue: "520326",
            enumName: "务川仡佬族苗族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 23,
            enumNameother: "务川仡佬族苗族自治县",
          },
          {
            fldEnumId: 12818,
            enumGroupGuid: "1",
            parentEnumValue: "5203",
            enumValue: "520327",
            enumName: "凤冈县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 24,
            enumNameother: "凤冈县",
          },
          {
            fldEnumId: 12820,
            enumGroupGuid: "1",
            parentEnumValue: "5203",
            enumValue: "520328",
            enumName: "湄潭县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 25,
            enumNameother: "湄潭县",
          },
          {
            fldEnumId: 12822,
            enumGroupGuid: "1",
            parentEnumValue: "5203",
            enumValue: "520329",
            enumName: "余庆县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 26,
            enumNameother: "余庆县",
          },
          {
            fldEnumId: 12824,
            enumGroupGuid: "1",
            parentEnumValue: "5203",
            enumValue: "520330",
            enumName: "习水县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 27,
            enumNameother: "习水县",
          },
          {
            fldEnumId: 12826,
            enumGroupGuid: "1",
            parentEnumValue: "5203",
            enumValue: "520381",
            enumName: "赤水市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 28,
            enumNameother: "赤水市",
          },
          {
            fldEnumId: 12828,
            enumGroupGuid: "1",
            parentEnumValue: "5203",
            enumValue: "520382",
            enumName: "仁怀市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 29,
            enumNameother: "仁怀市",
          },
        ],
      },
      {
        fldEnumId: 12830,
        enumGroupGuid: "1",
        parentEnumValue: "52",
        enumValue: "5204",
        enumName: "安顺市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 3,
        enumNameother: "安顺市",
        children: [
          {
            fldEnumId: 12831,
            enumGroupGuid: "1",
            parentEnumValue: "5204",
            enumValue: "520400",
            enumName: "安顺市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 30,
            enumNameother: "安顺市",
          },
          {
            fldEnumId: 12833,
            enumGroupGuid: "1",
            parentEnumValue: "5204",
            enumValue: "520402",
            enumName: "西秀区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 31,
            enumNameother: "西秀区",
          },
          {
            fldEnumId: 12835,
            enumGroupGuid: "1",
            parentEnumValue: "5204",
            enumValue: "520421",
            enumName: "平坝县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 32,
            enumNameother: "平坝县",
          },
          {
            fldEnumId: 12837,
            enumGroupGuid: "1",
            parentEnumValue: "5204",
            enumValue: "520422",
            enumName: "普定县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 33,
            enumNameother: "普定县",
          },
          {
            fldEnumId: 12839,
            enumGroupGuid: "1",
            parentEnumValue: "5204",
            enumValue: "520423",
            enumName: "镇宁布依族苗族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 34,
            enumNameother: "镇宁布依族苗族自治县",
          },
          {
            fldEnumId: 12841,
            enumGroupGuid: "1",
            parentEnumValue: "5204",
            enumValue: "520424",
            enumName: "关岭布依族苗族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 35,
            enumNameother: "关岭布依族苗族自治县",
          },
          {
            fldEnumId: 12843,
            enumGroupGuid: "1",
            parentEnumValue: "5204",
            enumValue: "520425",
            enumName: "紫云苗族布依族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 36,
            enumNameother: "紫云苗族布依族自治县",
          },
        ],
      },
      {
        fldEnumId: 12845,
        enumGroupGuid: "1",
        parentEnumValue: "52",
        enumValue: "5222",
        enumName: "铜仁地区",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 4,
        enumNameother: "铜仁地区",
        children: [
          {
            fldEnumId: 16174,
            enumGroupGuid: "1",
            parentEnumValue: "5222",
            enumValue: "522200",
            enumName: "铜仁地区本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 12846,
            enumGroupGuid: "1",
            parentEnumValue: "5222",
            enumValue: "522201",
            enumName: "铜仁市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 37,
            enumNameother: "铜仁市",
          },
          {
            fldEnumId: 12864,
            enumGroupGuid: "1",
            parentEnumValue: "5222",
            enumValue: "522230",
            enumName: "万山特区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 46,
            enumNameother: "万山特区",
          },
        ],
      },
      {
        fldEnumId: 12866,
        enumGroupGuid: "1",
        parentEnumValue: "52",
        enumValue: "5223",
        enumName: "黔西南布依族苗族自治州",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 5,
        enumNameother: "黔西南布依族苗族自治州",
        children: [
          {
            fldEnumId: 16176,
            enumGroupGuid: "1",
            parentEnumValue: "5223",
            enumValue: "522300",
            enumName: "黔西南布依族苗族自治州本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 12867,
            enumGroupGuid: "1",
            parentEnumValue: "5223",
            enumValue: "522301",
            enumName: "兴义市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 47,
            enumNameother: "兴义市",
          },
          {
            fldEnumId: 12869,
            enumGroupGuid: "1",
            parentEnumValue: "5223",
            enumValue: "522322",
            enumName: "兴仁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 48,
            enumNameother: "兴仁县",
          },
          {
            fldEnumId: 12871,
            enumGroupGuid: "1",
            parentEnumValue: "5223",
            enumValue: "522323",
            enumName: "普安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 49,
            enumNameother: "普安县",
          },
          {
            fldEnumId: 12873,
            enumGroupGuid: "1",
            parentEnumValue: "5223",
            enumValue: "522324",
            enumName: "晴隆县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 50,
            enumNameother: "晴隆县",
          },
          {
            fldEnumId: 12875,
            enumGroupGuid: "1",
            parentEnumValue: "5223",
            enumValue: "522325",
            enumName: "贞丰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 51,
            enumNameother: "贞丰县",
          },
          {
            fldEnumId: 12877,
            enumGroupGuid: "1",
            parentEnumValue: "5223",
            enumValue: "522326",
            enumName: "望谟县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 52,
            enumNameother: "望谟县",
          },
          {
            fldEnumId: 12879,
            enumGroupGuid: "1",
            parentEnumValue: "5223",
            enumValue: "522327",
            enumName: "册亨县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 53,
            enumNameother: "册亨县",
          },
          {
            fldEnumId: 12881,
            enumGroupGuid: "1",
            parentEnumValue: "5223",
            enumValue: "522328",
            enumName: "安龙县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 54,
            enumNameother: "安龙县",
          },
        ],
      },
      {
        fldEnumId: 12883,
        enumGroupGuid: "1",
        parentEnumValue: "52",
        enumValue: "5224",
        enumName: "毕节地区",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 6,
        enumNameother: "毕节地区",
        children: [
          {
            fldEnumId: 16178,
            enumGroupGuid: "1",
            parentEnumValue: "5224",
            enumValue: "522400",
            enumName: "毕节地区本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 12884,
            enumGroupGuid: "1",
            parentEnumValue: "5224",
            enumValue: "522401",
            enumName: "毕节市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 55,
            enumNameother: "毕节市",
          },
        ],
      },
      {
        fldEnumId: 12900,
        enumGroupGuid: "1",
        parentEnumValue: "52",
        enumValue: "5226",
        enumName: "黔东南苗族侗族自治州",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 7,
        enumNameother: "黔东南苗族侗族自治州",
        children: [
          {
            fldEnumId: 16284,
            enumGroupGuid: "1",
            parentEnumValue: "5226",
            enumValue: "522600",
            enumName: "黔东南苗族侗族自治州本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 12901,
            enumGroupGuid: "1",
            parentEnumValue: "5226",
            enumValue: "522601",
            enumName: "凯里市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 63,
            enumNameother: "凯里市",
          },
          {
            fldEnumId: 12903,
            enumGroupGuid: "1",
            parentEnumValue: "5226",
            enumValue: "522622",
            enumName: "黄平县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 64,
            enumNameother: "黄平县",
          },
          {
            fldEnumId: 12905,
            enumGroupGuid: "1",
            parentEnumValue: "5226",
            enumValue: "522623",
            enumName: "施秉县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 65,
            enumNameother: "施秉县",
          },
          {
            fldEnumId: 12907,
            enumGroupGuid: "1",
            parentEnumValue: "5226",
            enumValue: "522624",
            enumName: "三穗县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 66,
            enumNameother: "三穗县",
          },
          {
            fldEnumId: 12909,
            enumGroupGuid: "1",
            parentEnumValue: "5226",
            enumValue: "522625",
            enumName: "镇远县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 67,
            enumNameother: "镇远县",
          },
          {
            fldEnumId: 12911,
            enumGroupGuid: "1",
            parentEnumValue: "5226",
            enumValue: "522626",
            enumName: "岑巩县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 68,
            enumNameother: "岑巩县",
          },
          {
            fldEnumId: 12913,
            enumGroupGuid: "1",
            parentEnumValue: "5226",
            enumValue: "522627",
            enumName: "天柱县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 69,
            enumNameother: "天柱县",
          },
          {
            fldEnumId: 12915,
            enumGroupGuid: "1",
            parentEnumValue: "5226",
            enumValue: "522628",
            enumName: "锦屏县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 70,
            enumNameother: "锦屏县",
          },
          {
            fldEnumId: 12917,
            enumGroupGuid: "1",
            parentEnumValue: "5226",
            enumValue: "522629",
            enumName: "剑河县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 71,
            enumNameother: "剑河县",
          },
          {
            fldEnumId: 12919,
            enumGroupGuid: "1",
            parentEnumValue: "5226",
            enumValue: "522630",
            enumName: "台江县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 72,
            enumNameother: "台江县",
          },
          {
            fldEnumId: 12921,
            enumGroupGuid: "1",
            parentEnumValue: "5226",
            enumValue: "522631",
            enumName: "黎平县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 73,
            enumNameother: "黎平县",
          },
          {
            fldEnumId: 12923,
            enumGroupGuid: "1",
            parentEnumValue: "5226",
            enumValue: "522632",
            enumName: "榕江县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 74,
            enumNameother: "榕江县",
          },
          {
            fldEnumId: 12925,
            enumGroupGuid: "1",
            parentEnumValue: "5226",
            enumValue: "522633",
            enumName: "从江县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 75,
            enumNameother: "从江县",
          },
          {
            fldEnumId: 12927,
            enumGroupGuid: "1",
            parentEnumValue: "5226",
            enumValue: "522634",
            enumName: "雷山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 76,
            enumNameother: "雷山县",
          },
          {
            fldEnumId: 12929,
            enumGroupGuid: "1",
            parentEnumValue: "5226",
            enumValue: "522635",
            enumName: "麻江县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 77,
            enumNameother: "麻江县",
          },
          {
            fldEnumId: 12931,
            enumGroupGuid: "1",
            parentEnumValue: "5226",
            enumValue: "522636",
            enumName: "丹寨县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 78,
            enumNameother: "丹寨县",
          },
        ],
      },
      {
        fldEnumId: 12933,
        enumGroupGuid: "1",
        parentEnumValue: "52",
        enumValue: "5227",
        enumName: "黔南布依族苗族自治州",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 8,
        enumNameother: "黔南布依族苗族自治州",
        children: [
          {
            fldEnumId: 16286,
            enumGroupGuid: "1",
            parentEnumValue: "5227",
            enumValue: "522700",
            enumName: "黔南布依族苗族自治州本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 12934,
            enumGroupGuid: "1",
            parentEnumValue: "5227",
            enumValue: "522701",
            enumName: "都匀市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 79,
            enumNameother: "都匀市",
          },
          {
            fldEnumId: 12936,
            enumGroupGuid: "1",
            parentEnumValue: "5227",
            enumValue: "522702",
            enumName: "福泉市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 80,
            enumNameother: "福泉市",
          },
          {
            fldEnumId: 12938,
            enumGroupGuid: "1",
            parentEnumValue: "5227",
            enumValue: "522722",
            enumName: "荔波县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 81,
            enumNameother: "荔波县",
          },
          {
            fldEnumId: 12940,
            enumGroupGuid: "1",
            parentEnumValue: "5227",
            enumValue: "522723",
            enumName: "贵定县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 82,
            enumNameother: "贵定县",
          },
          {
            fldEnumId: 12942,
            enumGroupGuid: "1",
            parentEnumValue: "5227",
            enumValue: "522725",
            enumName: "瓮安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 83,
            enumNameother: "瓮安县",
          },
          {
            fldEnumId: 12944,
            enumGroupGuid: "1",
            parentEnumValue: "5227",
            enumValue: "522726",
            enumName: "独山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 84,
            enumNameother: "独山县",
          },
          {
            fldEnumId: 12946,
            enumGroupGuid: "1",
            parentEnumValue: "5227",
            enumValue: "522727",
            enumName: "平塘县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 85,
            enumNameother: "平塘县",
          },
          {
            fldEnumId: 12948,
            enumGroupGuid: "1",
            parentEnumValue: "5227",
            enumValue: "522728",
            enumName: "罗甸县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 86,
            enumNameother: "罗甸县",
          },
          {
            fldEnumId: 12950,
            enumGroupGuid: "1",
            parentEnumValue: "5227",
            enumValue: "522729",
            enumName: "长顺县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 87,
            enumNameother: "长顺县",
          },
          {
            fldEnumId: 12952,
            enumGroupGuid: "1",
            parentEnumValue: "5227",
            enumValue: "522730",
            enumName: "龙里县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 88,
            enumNameother: "龙里县",
          },
          {
            fldEnumId: 12954,
            enumGroupGuid: "1",
            parentEnumValue: "5227",
            enumValue: "522731",
            enumName: "惠水县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 89,
            enumNameother: "惠水县",
          },
          {
            fldEnumId: 12956,
            enumGroupGuid: "1",
            parentEnumValue: "5227",
            enumValue: "522732",
            enumName: "三都水族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 90,
            enumNameother: "三都水族自治县",
          },
        ],
      },
    ],
  },
  {
    enumValue: "53",
    enumName: "云南省",
    children: [
      {
        fldEnumId: 12959,
        enumGroupGuid: "1",
        parentEnumValue: "53",
        enumValue: "5301",
        enumName: "昆明市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        enumNameother: "昆明市",
        children: [
          {
            fldEnumId: 12960,
            enumGroupGuid: "1",
            parentEnumValue: "5301",
            enumValue: "530100",
            enumName: "昆明市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
            enumNameother: "昆明市",
          },
          {
            fldEnumId: 12962,
            enumGroupGuid: "1",
            parentEnumValue: "5301",
            enumValue: "530102",
            enumName: "五华区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "五华区",
          },
          {
            fldEnumId: 12964,
            enumGroupGuid: "1",
            parentEnumValue: "5301",
            enumValue: "530103",
            enumName: "盘龙区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 2,
            enumNameother: "盘龙区",
          },
          {
            fldEnumId: 12966,
            enumGroupGuid: "1",
            parentEnumValue: "5301",
            enumValue: "530111",
            enumName: "官渡区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 3,
            enumNameother: "官渡区",
          },
          {
            fldEnumId: 12968,
            enumGroupGuid: "1",
            parentEnumValue: "5301",
            enumValue: "530112",
            enumName: "西山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 4,
            enumNameother: "西山区",
          },
          {
            fldEnumId: 12970,
            enumGroupGuid: "1",
            parentEnumValue: "5301",
            enumValue: "530113",
            enumName: "东川区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 5,
            enumNameother: "东川区",
          },
          {
            fldEnumId: 12972,
            enumGroupGuid: "1",
            parentEnumValue: "5301",
            enumValue: "530121",
            enumName: "呈贡县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 6,
            enumNameother: "呈贡县",
          },
          {
            fldEnumId: 12974,
            enumGroupGuid: "1",
            parentEnumValue: "5301",
            enumValue: "530122",
            enumName: "晋宁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 7,
            enumNameother: "晋宁县",
          },
          {
            fldEnumId: 12976,
            enumGroupGuid: "1",
            parentEnumValue: "5301",
            enumValue: "530124",
            enumName: "富民县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 8,
            enumNameother: "富民县",
          },
          {
            fldEnumId: 12978,
            enumGroupGuid: "1",
            parentEnumValue: "5301",
            enumValue: "530125",
            enumName: "宜良县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 9,
            enumNameother: "宜良县",
          },
          {
            fldEnumId: 12980,
            enumGroupGuid: "1",
            parentEnumValue: "5301",
            enumValue: "530126",
            enumName: "石林彝族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 10,
            enumNameother: "石林彝族自治县",
          },
          {
            fldEnumId: 12982,
            enumGroupGuid: "1",
            parentEnumValue: "5301",
            enumValue: "530127",
            enumName: "嵩明县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 11,
            enumNameother: "嵩明县",
          },
          {
            fldEnumId: 12984,
            enumGroupGuid: "1",
            parentEnumValue: "5301",
            enumValue: "530128",
            enumName: "禄劝彝族苗族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 12,
            enumNameother: "禄劝彝族苗族自治县",
          },
          {
            fldEnumId: 12986,
            enumGroupGuid: "1",
            parentEnumValue: "5301",
            enumValue: "530129",
            enumName: "寻甸回族彝族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 13,
            enumNameother: "寻甸回族彝族自治县",
          },
          {
            fldEnumId: 12988,
            enumGroupGuid: "1",
            parentEnumValue: "5301",
            enumValue: "530181",
            enumName: "安宁市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 14,
            enumNameother: "安宁市",
          },
        ],
      },
      {
        fldEnumId: 12990,
        enumGroupGuid: "1",
        parentEnumValue: "53",
        enumValue: "5303",
        enumName: "曲靖市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 1,
        enumNameother: "曲靖市",
        children: [
          {
            fldEnumId: 12991,
            enumGroupGuid: "1",
            parentEnumValue: "5303",
            enumValue: "530300",
            enumName: "曲靖市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 15,
            enumNameother: "曲靖市",
          },
          {
            fldEnumId: 12993,
            enumGroupGuid: "1",
            parentEnumValue: "5303",
            enumValue: "530302",
            enumName: "麒麟区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 16,
            enumNameother: "麒麟区",
          },
          {
            fldEnumId: 12995,
            enumGroupGuid: "1",
            parentEnumValue: "5303",
            enumValue: "530321",
            enumName: "马龙县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 17,
            enumNameother: "马龙县",
          },
          {
            fldEnumId: 12997,
            enumGroupGuid: "1",
            parentEnumValue: "5303",
            enumValue: "530322",
            enumName: "陆良县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 18,
            enumNameother: "陆良县",
          },
          {
            fldEnumId: 12999,
            enumGroupGuid: "1",
            parentEnumValue: "5303",
            enumValue: "530323",
            enumName: "师宗县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 19,
            enumNameother: "师宗县",
          },
          {
            fldEnumId: 13001,
            enumGroupGuid: "1",
            parentEnumValue: "5303",
            enumValue: "530324",
            enumName: "罗平县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 20,
            enumNameother: "罗平县",
          },
          {
            fldEnumId: 13003,
            enumGroupGuid: "1",
            parentEnumValue: "5303",
            enumValue: "530325",
            enumName: "富源县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 21,
            enumNameother: "富源县",
          },
          {
            fldEnumId: 13005,
            enumGroupGuid: "1",
            parentEnumValue: "5303",
            enumValue: "530326",
            enumName: "会泽县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 22,
            enumNameother: "会泽县",
          },
          {
            fldEnumId: 13007,
            enumGroupGuid: "1",
            parentEnumValue: "5303",
            enumValue: "530328",
            enumName: "沾益县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 23,
            enumNameother: "沾益县",
          },
          {
            fldEnumId: 13009,
            enumGroupGuid: "1",
            parentEnumValue: "5303",
            enumValue: "530381",
            enumName: "宣威市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 24,
            enumNameother: "宣威市",
          },
        ],
      },
      {
        fldEnumId: 13011,
        enumGroupGuid: "1",
        parentEnumValue: "53",
        enumValue: "5304",
        enumName: "玉溪市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 2,
        enumNameother: "玉溪市",
        children: [
          {
            fldEnumId: 13012,
            enumGroupGuid: "1",
            parentEnumValue: "5304",
            enumValue: "530400",
            enumName: "玉溪市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 25,
            enumNameother: "玉溪市",
          },
          {
            fldEnumId: 13014,
            enumGroupGuid: "1",
            parentEnumValue: "5304",
            enumValue: "530402",
            enumName: "红塔区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 26,
            enumNameother: "红塔区",
          },
          {
            fldEnumId: 13016,
            enumGroupGuid: "1",
            parentEnumValue: "5304",
            enumValue: "530421",
            enumName: "江川县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 27,
            enumNameother: "江川县",
          },
          {
            fldEnumId: 13018,
            enumGroupGuid: "1",
            parentEnumValue: "5304",
            enumValue: "530422",
            enumName: "澄江县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 28,
            enumNameother: "澄江县",
          },
          {
            fldEnumId: 13020,
            enumGroupGuid: "1",
            parentEnumValue: "5304",
            enumValue: "530423",
            enumName: "通海县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 29,
            enumNameother: "通海县",
          },
          {
            fldEnumId: 13022,
            enumGroupGuid: "1",
            parentEnumValue: "5304",
            enumValue: "530424",
            enumName: "华宁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 30,
            enumNameother: "华宁县",
          },
          {
            fldEnumId: 13024,
            enumGroupGuid: "1",
            parentEnumValue: "5304",
            enumValue: "530425",
            enumName: "易门县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 31,
            enumNameother: "易门县",
          },
          {
            fldEnumId: 13026,
            enumGroupGuid: "1",
            parentEnumValue: "5304",
            enumValue: "530426",
            enumName: "峨山彝族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 32,
            enumNameother: "峨山彝族自治县",
          },
          {
            fldEnumId: 13028,
            enumGroupGuid: "1",
            parentEnumValue: "5304",
            enumValue: "530427",
            enumName: "新平彝族傣族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 33,
            enumNameother: "新平彝族傣族自治县",
          },
          {
            fldEnumId: 13030,
            enumGroupGuid: "1",
            parentEnumValue: "5304",
            enumValue: "530428",
            enumName: "元江哈尼族彝族傣族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 34,
            enumNameother: "元江哈尼族彝族傣族自治县",
          },
        ],
      },
      {
        fldEnumId: 13032,
        enumGroupGuid: "1",
        parentEnumValue: "53",
        enumValue: "5305",
        enumName: "保山市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 3,
        enumNameother: "保山市",
        children: [
          {
            fldEnumId: 13033,
            enumGroupGuid: "1",
            parentEnumValue: "5305",
            enumValue: "530500",
            enumName: "保山市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 35,
            enumNameother: "保山市",
          },
          {
            fldEnumId: 13035,
            enumGroupGuid: "1",
            parentEnumValue: "5305",
            enumValue: "530502",
            enumName: "隆阳区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 36,
            enumNameother: "隆阳区",
          },
          {
            fldEnumId: 13037,
            enumGroupGuid: "1",
            parentEnumValue: "5305",
            enumValue: "530521",
            enumName: "施甸县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 37,
            enumNameother: "施甸县",
          },
          {
            fldEnumId: 13039,
            enumGroupGuid: "1",
            parentEnumValue: "5305",
            enumValue: "530522",
            enumName: "腾冲县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 38,
            enumNameother: "腾冲县",
          },
          {
            fldEnumId: 13041,
            enumGroupGuid: "1",
            parentEnumValue: "5305",
            enumValue: "530523",
            enumName: "龙陵县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 39,
            enumNameother: "龙陵县",
          },
          {
            fldEnumId: 13043,
            enumGroupGuid: "1",
            parentEnumValue: "5305",
            enumValue: "530524",
            enumName: "昌宁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 40,
            enumNameother: "昌宁县",
          },
        ],
      },
      {
        fldEnumId: 13045,
        enumGroupGuid: "1",
        parentEnumValue: "53",
        enumValue: "5306",
        enumName: "昭通市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 4,
        enumNameother: "昭通市",
        children: [
          {
            fldEnumId: 13046,
            enumGroupGuid: "1",
            parentEnumValue: "5306",
            enumValue: "530600",
            enumName: "昭通市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 41,
            enumNameother: "昭通市",
          },
          {
            fldEnumId: 13048,
            enumGroupGuid: "1",
            parentEnumValue: "5306",
            enumValue: "530602",
            enumName: "昭阳区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 42,
            enumNameother: "昭阳区",
          },
          {
            fldEnumId: 13050,
            enumGroupGuid: "1",
            parentEnumValue: "5306",
            enumValue: "530621",
            enumName: "鲁甸县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 43,
            enumNameother: "鲁甸县",
          },
          {
            fldEnumId: 13052,
            enumGroupGuid: "1",
            parentEnumValue: "5306",
            enumValue: "530622",
            enumName: "巧家县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 44,
            enumNameother: "巧家县",
          },
          {
            fldEnumId: 13054,
            enumGroupGuid: "1",
            parentEnumValue: "5306",
            enumValue: "530623",
            enumName: "盐津县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 45,
            enumNameother: "盐津县",
          },
          {
            fldEnumId: 13056,
            enumGroupGuid: "1",
            parentEnumValue: "5306",
            enumValue: "530624",
            enumName: "大关县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 46,
            enumNameother: "大关县",
          },
          {
            fldEnumId: 13058,
            enumGroupGuid: "1",
            parentEnumValue: "5306",
            enumValue: "530625",
            enumName: "永善县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 47,
            enumNameother: "永善县",
          },
          {
            fldEnumId: 13060,
            enumGroupGuid: "1",
            parentEnumValue: "5306",
            enumValue: "530626",
            enumName: "绥江县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 48,
            enumNameother: "绥江县",
          },
          {
            fldEnumId: 13062,
            enumGroupGuid: "1",
            parentEnumValue: "5306",
            enumValue: "530627",
            enumName: "镇雄县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 49,
            enumNameother: "镇雄县",
          },
          {
            fldEnumId: 13064,
            enumGroupGuid: "1",
            parentEnumValue: "5306",
            enumValue: "530628",
            enumName: "彝良县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 50,
            enumNameother: "彝良县",
          },
          {
            fldEnumId: 13066,
            enumGroupGuid: "1",
            parentEnumValue: "5306",
            enumValue: "530629",
            enumName: "威信县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 51,
            enumNameother: "威信县",
          },
          {
            fldEnumId: 13068,
            enumGroupGuid: "1",
            parentEnumValue: "5306",
            enumValue: "530630",
            enumName: "水富县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 52,
            enumNameother: "水富县",
          },
        ],
      },
      {
        fldEnumId: 13070,
        enumGroupGuid: "1",
        parentEnumValue: "53",
        enumValue: "5307",
        enumName: "丽江市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 5,
        enumNameother: "丽江市",
        children: [
          {
            fldEnumId: 13071,
            enumGroupGuid: "1",
            parentEnumValue: "5307",
            enumValue: "530700",
            enumName: "丽江市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 53,
            enumNameother: "丽江市",
          },
          {
            fldEnumId: 13073,
            enumGroupGuid: "1",
            parentEnumValue: "5307",
            enumValue: "530702",
            enumName: "古城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 54,
            enumNameother: "古城区",
          },
          {
            fldEnumId: 13075,
            enumGroupGuid: "1",
            parentEnumValue: "5307",
            enumValue: "530721",
            enumName: "玉龙纳西族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 55,
            enumNameother: "玉龙纳西族自治县",
          },
          {
            fldEnumId: 13077,
            enumGroupGuid: "1",
            parentEnumValue: "5307",
            enumValue: "530722",
            enumName: "永胜县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 56,
            enumNameother: "永胜县",
          },
          {
            fldEnumId: 13079,
            enumGroupGuid: "1",
            parentEnumValue: "5307",
            enumValue: "530723",
            enumName: "华坪县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 57,
            enumNameother: "华坪县",
          },
          {
            fldEnumId: 13081,
            enumGroupGuid: "1",
            parentEnumValue: "5307",
            enumValue: "530724",
            enumName: "宁蒗彝族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 58,
            enumNameother: "宁蒗彝族自治县",
          },
        ],
      },
      {
        fldEnumId: 13083,
        enumGroupGuid: "1",
        parentEnumValue: "53",
        enumValue: "5308",
        enumName: "普洱市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 6,
        enumNameother: "普洱市",
        children: [
          {
            fldEnumId: 13084,
            enumGroupGuid: "1",
            parentEnumValue: "5308",
            enumValue: "530800",
            enumName: "普洱市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 59,
            enumNameother: "普洱市",
          },
          {
            fldEnumId: 13086,
            enumGroupGuid: "1",
            parentEnumValue: "5308",
            enumValue: "530802",
            enumName: "思茅区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 60,
            enumNameother: "思茅区",
          },
          {
            fldEnumId: 13088,
            enumGroupGuid: "1",
            parentEnumValue: "5308",
            enumValue: "530821",
            enumName: "宁洱哈尼族彝族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 61,
            enumNameother: "宁洱哈尼族彝族自治县",
          },
          {
            fldEnumId: 13090,
            enumGroupGuid: "1",
            parentEnumValue: "5308",
            enumValue: "530822",
            enumName: "墨江哈尼族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 62,
            enumNameother: "墨江哈尼族自治县",
          },
          {
            fldEnumId: 13092,
            enumGroupGuid: "1",
            parentEnumValue: "5308",
            enumValue: "530823",
            enumName: "景东彝族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 63,
            enumNameother: "景东彝族自治县",
          },
          {
            fldEnumId: 13094,
            enumGroupGuid: "1",
            parentEnumValue: "5308",
            enumValue: "530824",
            enumName: "景谷傣族彝族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 64,
            enumNameother: "景谷傣族彝族自治县",
          },
          {
            fldEnumId: 13096,
            enumGroupGuid: "1",
            parentEnumValue: "5308",
            enumValue: "530825",
            enumName: "镇沅彝族哈尼族拉祜族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 65,
            enumNameother: "镇沅彝族哈尼族拉祜族自治县",
          },
          {
            fldEnumId: 13098,
            enumGroupGuid: "1",
            parentEnumValue: "5308",
            enumValue: "530826",
            enumName: "江城哈尼族彝族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 66,
            enumNameother: "江城哈尼族彝族自治县",
          },
          {
            fldEnumId: 13100,
            enumGroupGuid: "1",
            parentEnumValue: "5308",
            enumValue: "530827",
            enumName: "孟连傣族拉祜族佤族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 67,
            enumNameother: "孟连傣族拉祜族佤族自治县",
          },
          {
            fldEnumId: 13102,
            enumGroupGuid: "1",
            parentEnumValue: "5308",
            enumValue: "530828",
            enumName: "澜沧拉祜族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 68,
            enumNameother: "澜沧拉祜族自治县",
          },
          {
            fldEnumId: 13104,
            enumGroupGuid: "1",
            parentEnumValue: "5308",
            enumValue: "530829",
            enumName: "西盟佤族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 69,
            enumNameother: "西盟佤族自治县",
          },
        ],
      },
      {
        fldEnumId: 13106,
        enumGroupGuid: "1",
        parentEnumValue: "53",
        enumValue: "5309",
        enumName: "临沧市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 7,
        enumNameother: "临沧市",
        children: [
          {
            fldEnumId: 13107,
            enumGroupGuid: "1",
            parentEnumValue: "5309",
            enumValue: "530900",
            enumName: "临沧市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 70,
            enumNameother: "临沧市",
          },
          {
            fldEnumId: 13109,
            enumGroupGuid: "1",
            parentEnumValue: "5309",
            enumValue: "530902",
            enumName: "临翔区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 71,
            enumNameother: "临翔区",
          },
          {
            fldEnumId: 13111,
            enumGroupGuid: "1",
            parentEnumValue: "5309",
            enumValue: "530921",
            enumName: "凤庆县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 72,
            enumNameother: "凤庆县",
          },
          {
            fldEnumId: 13113,
            enumGroupGuid: "1",
            parentEnumValue: "5309",
            enumValue: "530922",
            enumName: "云县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 73,
            enumNameother: "云县",
          },
          {
            fldEnumId: 13115,
            enumGroupGuid: "1",
            parentEnumValue: "5309",
            enumValue: "530923",
            enumName: "永德县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 74,
            enumNameother: "永德县",
          },
          {
            fldEnumId: 13117,
            enumGroupGuid: "1",
            parentEnumValue: "5309",
            enumValue: "530924",
            enumName: "镇康县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 75,
            enumNameother: "镇康县",
          },
          {
            fldEnumId: 13119,
            enumGroupGuid: "1",
            parentEnumValue: "5309",
            enumValue: "530925",
            enumName: "双江拉祜族佤族布朗族傣族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 76,
            enumNameother: "双江拉祜族佤族布朗族傣族自治县",
          },
          {
            fldEnumId: 13121,
            enumGroupGuid: "1",
            parentEnumValue: "5309",
            enumValue: "530926",
            enumName: "耿马傣族佤族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 77,
            enumNameother: "耿马傣族佤族自治县",
          },
          {
            fldEnumId: 13123,
            enumGroupGuid: "1",
            parentEnumValue: "5309",
            enumValue: "530927",
            enumName: "沧源佤族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 78,
            enumNameother: "沧源佤族自治县",
          },
        ],
      },
      {
        fldEnumId: 13125,
        enumGroupGuid: "1",
        parentEnumValue: "53",
        enumValue: "5323",
        enumName: "楚雄彝族自治州",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 8,
        enumNameother: "楚雄彝族自治州",
        children: [
          {
            fldEnumId: 16270,
            enumGroupGuid: "1",
            parentEnumValue: "5323",
            enumValue: "532300",
            enumName: "楚雄彝族自治州本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 13126,
            enumGroupGuid: "1",
            parentEnumValue: "5323",
            enumValue: "532301",
            enumName: "楚雄市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 79,
            enumNameother: "楚雄市",
          },
          {
            fldEnumId: 13128,
            enumGroupGuid: "1",
            parentEnumValue: "5323",
            enumValue: "532322",
            enumName: "双柏县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 80,
            enumNameother: "双柏县",
          },
          {
            fldEnumId: 13130,
            enumGroupGuid: "1",
            parentEnumValue: "5323",
            enumValue: "532323",
            enumName: "牟定县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 81,
            enumNameother: "牟定县",
          },
          {
            fldEnumId: 13132,
            enumGroupGuid: "1",
            parentEnumValue: "5323",
            enumValue: "532324",
            enumName: "南华县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 82,
            enumNameother: "南华县",
          },
          {
            fldEnumId: 13134,
            enumGroupGuid: "1",
            parentEnumValue: "5323",
            enumValue: "532325",
            enumName: "姚安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 83,
            enumNameother: "姚安县",
          },
          {
            fldEnumId: 13136,
            enumGroupGuid: "1",
            parentEnumValue: "5323",
            enumValue: "532326",
            enumName: "大姚县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 84,
            enumNameother: "大姚县",
          },
          {
            fldEnumId: 13138,
            enumGroupGuid: "1",
            parentEnumValue: "5323",
            enumValue: "532327",
            enumName: "永仁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 85,
            enumNameother: "永仁县",
          },
          {
            fldEnumId: 13140,
            enumGroupGuid: "1",
            parentEnumValue: "5323",
            enumValue: "532328",
            enumName: "元谋县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 86,
            enumNameother: "元谋县",
          },
          {
            fldEnumId: 13142,
            enumGroupGuid: "1",
            parentEnumValue: "5323",
            enumValue: "532329",
            enumName: "武定县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 87,
            enumNameother: "武定县",
          },
          {
            fldEnumId: 13144,
            enumGroupGuid: "1",
            parentEnumValue: "5323",
            enumValue: "532331",
            enumName: "禄丰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 88,
            enumNameother: "禄丰县",
          },
        ],
      },
      {
        fldEnumId: 13146,
        enumGroupGuid: "1",
        parentEnumValue: "53",
        enumValue: "5325",
        enumName: "红河哈尼族彝族自治州",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 9,
        enumNameother: "红河哈尼族彝族自治州",
        children: [
          {
            fldEnumId: 16272,
            enumGroupGuid: "1",
            parentEnumValue: "5325",
            enumValue: "532500",
            enumName: "红河哈尼族彝族自治州本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 13147,
            enumGroupGuid: "1",
            parentEnumValue: "5325",
            enumValue: "532501",
            enumName: "个旧市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 89,
            enumNameother: "个旧市",
          },
          {
            fldEnumId: 13149,
            enumGroupGuid: "1",
            parentEnumValue: "5325",
            enumValue: "532502",
            enumName: "开远市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 90,
            enumNameother: "开远市",
          },
          {
            fldEnumId: 13151,
            enumGroupGuid: "1",
            parentEnumValue: "5325",
            enumValue: "532522",
            enumName: "蒙自县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 91,
            enumNameother: "蒙自县",
          },
          {
            fldEnumId: 13153,
            enumGroupGuid: "1",
            parentEnumValue: "5325",
            enumValue: "532523",
            enumName: "屏边苗族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 92,
            enumNameother: "屏边苗族自治县",
          },
          {
            fldEnumId: 13155,
            enumGroupGuid: "1",
            parentEnumValue: "5325",
            enumValue: "532524",
            enumName: "建水县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 93,
            enumNameother: "建水县",
          },
          {
            fldEnumId: 13157,
            enumGroupGuid: "1",
            parentEnumValue: "5325",
            enumValue: "532525",
            enumName: "石屏县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 94,
            enumNameother: "石屏县",
          },
          {
            fldEnumId: 13159,
            enumGroupGuid: "1",
            parentEnumValue: "5325",
            enumValue: "532526",
            enumName: "弥勒县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 95,
            enumNameother: "弥勒县",
          },
          {
            fldEnumId: 13161,
            enumGroupGuid: "1",
            parentEnumValue: "5325",
            enumValue: "532527",
            enumName: "泸西县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 96,
            enumNameother: "泸西县",
          },
          {
            fldEnumId: 13163,
            enumGroupGuid: "1",
            parentEnumValue: "5325",
            enumValue: "532528",
            enumName: "元阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 97,
            enumNameother: "元阳县",
          },
          {
            fldEnumId: 13165,
            enumGroupGuid: "1",
            parentEnumValue: "5325",
            enumValue: "532529",
            enumName: "红河县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 98,
            enumNameother: "红河县",
          },
          {
            fldEnumId: 13167,
            enumGroupGuid: "1",
            parentEnumValue: "5325",
            enumValue: "532530",
            enumName: "金平苗族瑶族傣族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 99,
            enumNameother: "金平苗族瑶族傣族自治县",
          },
          {
            fldEnumId: 13169,
            enumGroupGuid: "1",
            parentEnumValue: "5325",
            enumValue: "532531",
            enumName: "绿春县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 100,
            enumNameother: "绿春县",
          },
          {
            fldEnumId: 13171,
            enumGroupGuid: "1",
            parentEnumValue: "5325",
            enumValue: "532532",
            enumName: "河口瑶族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 101,
            enumNameother: "河口瑶族自治县",
          },
        ],
      },
      {
        fldEnumId: 13173,
        enumGroupGuid: "1",
        parentEnumValue: "53",
        enumValue: "5326",
        enumName: "文山壮族苗族自治州",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 10,
        enumNameother: "文山壮族苗族自治州",
        children: [
          {
            fldEnumId: 16274,
            enumGroupGuid: "1",
            parentEnumValue: "5326",
            enumValue: "532600",
            enumName: "文山壮族苗族自治州本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 13174,
            enumGroupGuid: "1",
            parentEnumValue: "5326",
            enumValue: "532621",
            enumName: "文山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 102,
            enumNameother: "文山县",
          },
          {
            fldEnumId: 13176,
            enumGroupGuid: "1",
            parentEnumValue: "5326",
            enumValue: "532622",
            enumName: "砚山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 103,
            enumNameother: "砚山县",
          },
          {
            fldEnumId: 13178,
            enumGroupGuid: "1",
            parentEnumValue: "5326",
            enumValue: "532623",
            enumName: "西畴县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 104,
            enumNameother: "西畴县",
          },
          {
            fldEnumId: 13180,
            enumGroupGuid: "1",
            parentEnumValue: "5326",
            enumValue: "532624",
            enumName: "麻栗坡县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 105,
            enumNameother: "麻栗坡县",
          },
          {
            fldEnumId: 13182,
            enumGroupGuid: "1",
            parentEnumValue: "5326",
            enumValue: "532625",
            enumName: "马关县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 106,
            enumNameother: "马关县",
          },
          {
            fldEnumId: 13184,
            enumGroupGuid: "1",
            parentEnumValue: "5326",
            enumValue: "532626",
            enumName: "丘北县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 107,
            enumNameother: "丘北县",
          },
          {
            fldEnumId: 13186,
            enumGroupGuid: "1",
            parentEnumValue: "5326",
            enumValue: "532627",
            enumName: "广南县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 108,
            enumNameother: "广南县",
          },
          {
            fldEnumId: 13188,
            enumGroupGuid: "1",
            parentEnumValue: "5326",
            enumValue: "532628",
            enumName: "富宁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 109,
            enumNameother: "富宁县",
          },
        ],
      },
      {
        fldEnumId: 13190,
        enumGroupGuid: "1",
        parentEnumValue: "53",
        enumValue: "5328",
        enumName: "西双版纳傣族自治州",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 11,
        enumNameother: "西双版纳傣族自治州",
        children: [
          {
            fldEnumId: 16276,
            enumGroupGuid: "1",
            parentEnumValue: "5328",
            enumValue: "532800",
            enumName: "西双版纳傣族自治州本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 13191,
            enumGroupGuid: "1",
            parentEnumValue: "5328",
            enumValue: "532801",
            enumName: "景洪市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 110,
            enumNameother: "景洪市",
          },
          {
            fldEnumId: 13193,
            enumGroupGuid: "1",
            parentEnumValue: "5328",
            enumValue: "532822",
            enumName: "勐海县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 111,
            enumNameother: "勐海县",
          },
          {
            fldEnumId: 13195,
            enumGroupGuid: "1",
            parentEnumValue: "5328",
            enumValue: "532823",
            enumName: "勐腊县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 112,
            enumNameother: "勐腊县",
          },
        ],
      },
      {
        fldEnumId: 13197,
        enumGroupGuid: "1",
        parentEnumValue: "53",
        enumValue: "5329",
        enumName: "大理白族自治州",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 12,
        enumNameother: "大理白族自治州",
        children: [
          {
            fldEnumId: 16278,
            enumGroupGuid: "1",
            parentEnumValue: "5329",
            enumValue: "532900",
            enumName: "大理白族自治州本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 13198,
            enumGroupGuid: "1",
            parentEnumValue: "5329",
            enumValue: "532901",
            enumName: "大理市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 113,
            enumNameother: "大理市",
          },
          {
            fldEnumId: 13200,
            enumGroupGuid: "1",
            parentEnumValue: "5329",
            enumValue: "532922",
            enumName: "漾濞彝族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 114,
            enumNameother: "漾濞彝族自治县",
          },
          {
            fldEnumId: 13202,
            enumGroupGuid: "1",
            parentEnumValue: "5329",
            enumValue: "532923",
            enumName: "祥云县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 115,
            enumNameother: "祥云县",
          },
          {
            fldEnumId: 13204,
            enumGroupGuid: "1",
            parentEnumValue: "5329",
            enumValue: "532924",
            enumName: "宾川县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 116,
            enumNameother: "宾川县",
          },
          {
            fldEnumId: 13206,
            enumGroupGuid: "1",
            parentEnumValue: "5329",
            enumValue: "532925",
            enumName: "弥渡县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 117,
            enumNameother: "弥渡县",
          },
          {
            fldEnumId: 13208,
            enumGroupGuid: "1",
            parentEnumValue: "5329",
            enumValue: "532926",
            enumName: "南涧彝族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 118,
            enumNameother: "南涧彝族自治县",
          },
          {
            fldEnumId: 13210,
            enumGroupGuid: "1",
            parentEnumValue: "5329",
            enumValue: "532927",
            enumName: "巍山彝族回族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 119,
            enumNameother: "巍山彝族回族自治县",
          },
          {
            fldEnumId: 13212,
            enumGroupGuid: "1",
            parentEnumValue: "5329",
            enumValue: "532928",
            enumName: "永平县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 120,
            enumNameother: "永平县",
          },
          {
            fldEnumId: 13214,
            enumGroupGuid: "1",
            parentEnumValue: "5329",
            enumValue: "532929",
            enumName: "云龙县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 121,
            enumNameother: "云龙县",
          },
          {
            fldEnumId: 13216,
            enumGroupGuid: "1",
            parentEnumValue: "5329",
            enumValue: "532930",
            enumName: "洱源县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 122,
            enumNameother: "洱源县",
          },
          {
            fldEnumId: 13218,
            enumGroupGuid: "1",
            parentEnumValue: "5329",
            enumValue: "532931",
            enumName: "剑川县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 123,
            enumNameother: "剑川县",
          },
          {
            fldEnumId: 13220,
            enumGroupGuid: "1",
            parentEnumValue: "5329",
            enumValue: "532932",
            enumName: "鹤庆县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 124,
            enumNameother: "鹤庆县",
          },
        ],
      },
      {
        fldEnumId: 13222,
        enumGroupGuid: "1",
        parentEnumValue: "53",
        enumValue: "5331",
        enumName: "德宏傣族景颇族自治州",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 13,
        enumNameother: "德宏傣族景颇族自治州",
        children: [
          {
            fldEnumId: 16244,
            enumGroupGuid: "1",
            parentEnumValue: "5331",
            enumValue: "533100",
            enumName: "德宏傣族景颇族自治州本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 13223,
            enumGroupGuid: "1",
            parentEnumValue: "5331",
            enumValue: "533102",
            enumName: "瑞丽市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 125,
            enumNameother: "瑞丽市",
          },
          {
            fldEnumId: 13225,
            enumGroupGuid: "1",
            parentEnumValue: "5331",
            enumValue: "533103",
            enumName: "潞西市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 126,
            enumNameother: "潞西市",
          },
          {
            fldEnumId: 13227,
            enumGroupGuid: "1",
            parentEnumValue: "5331",
            enumValue: "533122",
            enumName: "梁河县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 127,
            enumNameother: "梁河县",
          },
          {
            fldEnumId: 13229,
            enumGroupGuid: "1",
            parentEnumValue: "5331",
            enumValue: "533123",
            enumName: "盈江县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 128,
            enumNameother: "盈江县",
          },
          {
            fldEnumId: 13231,
            enumGroupGuid: "1",
            parentEnumValue: "5331",
            enumValue: "533124",
            enumName: "陇川县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 129,
            enumNameother: "陇川县",
          },
        ],
      },
      {
        fldEnumId: 13233,
        enumGroupGuid: "1",
        parentEnumValue: "53",
        enumValue: "5333",
        enumName: "怒江傈僳族自治州",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 14,
        enumNameother: "怒江傈僳族自治州",
        children: [
          {
            fldEnumId: 16246,
            enumGroupGuid: "1",
            parentEnumValue: "5333",
            enumValue: "533300",
            enumName: "怒江傈僳族自治州本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 13234,
            enumGroupGuid: "1",
            parentEnumValue: "5333",
            enumValue: "533321",
            enumName: "泸水县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 130,
            enumNameother: "泸水县",
          },
          {
            fldEnumId: 13236,
            enumGroupGuid: "1",
            parentEnumValue: "5333",
            enumValue: "533323",
            enumName: "福贡县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 131,
            enumNameother: "福贡县",
          },
          {
            fldEnumId: 13238,
            enumGroupGuid: "1",
            parentEnumValue: "5333",
            enumValue: "533324",
            enumName: "贡山独龙族怒族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 132,
            enumNameother: "贡山独龙族怒族自治县",
          },
          {
            fldEnumId: 13240,
            enumGroupGuid: "1",
            parentEnumValue: "5333",
            enumValue: "533325",
            enumName: "兰坪白族普米族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 133,
            enumNameother: "兰坪白族普米族自治县",
          },
        ],
      },
      {
        fldEnumId: 13242,
        enumGroupGuid: "1",
        parentEnumValue: "53",
        enumValue: "5334",
        enumName: "迪庆藏族自治州",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 15,
        enumNameother: "迪庆藏族自治州",
        children: [
          {
            fldEnumId: 16248,
            enumGroupGuid: "1",
            parentEnumValue: "5334",
            enumValue: "533400",
            enumName: "迪庆藏族自治州本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 13243,
            enumGroupGuid: "1",
            parentEnumValue: "5334",
            enumValue: "533421",
            enumName: "香格里拉县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 134,
            enumNameother: "香格里拉县",
          },
          {
            fldEnumId: 13245,
            enumGroupGuid: "1",
            parentEnumValue: "5334",
            enumValue: "533422",
            enumName: "德钦县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 135,
            enumNameother: "德钦县",
          },
          {
            fldEnumId: 13247,
            enumGroupGuid: "1",
            parentEnumValue: "5334",
            enumValue: "533423",
            enumName: "维西傈僳族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 136,
            enumNameother: "维西傈僳族自治县",
          },
        ],
      },
    ],
  },
  {
    enumValue: "54",
    enumName: "西藏",
    children: [
      {
        fldEnumId: 13250,
        enumGroupGuid: "1",
        parentEnumValue: "54",
        enumValue: "5401",
        enumName: "拉萨市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        enumNameother: "拉萨市",
        children: [
          {
            fldEnumId: 13251,
            enumGroupGuid: "1",
            parentEnumValue: "5401",
            enumValue: "540100",
            enumName: "拉萨市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
            enumNameother: "拉萨市",
          },
          {
            fldEnumId: 13253,
            enumGroupGuid: "1",
            parentEnumValue: "5401",
            enumValue: "540102",
            enumName: "城关区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "城关区",
          },
          {
            fldEnumId: 13255,
            enumGroupGuid: "1",
            parentEnumValue: "5401",
            enumValue: "540121",
            enumName: "林周县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 2,
            enumNameother: "林周县",
          },
          {
            fldEnumId: 13257,
            enumGroupGuid: "1",
            parentEnumValue: "5401",
            enumValue: "540122",
            enumName: "当雄县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 3,
            enumNameother: "当雄县",
          },
          {
            fldEnumId: 13259,
            enumGroupGuid: "1",
            parentEnumValue: "5401",
            enumValue: "540123",
            enumName: "尼木县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 4,
            enumNameother: "尼木县",
          },
          {
            fldEnumId: 13261,
            enumGroupGuid: "1",
            parentEnumValue: "5401",
            enumValue: "540124",
            enumName: "曲水县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 5,
            enumNameother: "曲水县",
          },
          {
            fldEnumId: 13263,
            enumGroupGuid: "1",
            parentEnumValue: "5401",
            enumValue: "540125",
            enumName: "堆龙德庆县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 6,
            enumNameother: "堆龙德庆县",
          },
          {
            fldEnumId: 13265,
            enumGroupGuid: "1",
            parentEnumValue: "5401",
            enumValue: "540126",
            enumName: "达孜县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 7,
            enumNameother: "达孜县",
          },
          {
            fldEnumId: 13267,
            enumGroupGuid: "1",
            parentEnumValue: "5401",
            enumValue: "540127",
            enumName: "墨竹工卡县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 8,
            enumNameother: "墨竹工卡县",
          },
        ],
      },
      {
        fldEnumId: 130379,
        enumGroupGuid: "1",
        parentEnumValue: "54",
        enumValue: "5402",
        enumName: "日喀则市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        children: [
          {
            fldEnumId: 130302,
            enumGroupGuid: "1",
            parentEnumValue: "5402",
            enumValue: "540202",
            enumName: "桑珠孜区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130239,
            enumGroupGuid: "1",
            parentEnumValue: "5402",
            enumValue: "542322",
            enumName: "南木林县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130240,
            enumGroupGuid: "1",
            parentEnumValue: "5402",
            enumValue: "542323",
            enumName: "江孜县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130241,
            enumGroupGuid: "1",
            parentEnumValue: "5402",
            enumValue: "542324",
            enumName: "定日县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130242,
            enumGroupGuid: "1",
            parentEnumValue: "5402",
            enumValue: "542325",
            enumName: "萨迦县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130243,
            enumGroupGuid: "1",
            parentEnumValue: "5402",
            enumValue: "542326",
            enumName: "拉孜县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130244,
            enumGroupGuid: "1",
            parentEnumValue: "5402",
            enumValue: "542327",
            enumName: "昂仁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130245,
            enumGroupGuid: "1",
            parentEnumValue: "5402",
            enumValue: "542328",
            enumName: "谢通门县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130246,
            enumGroupGuid: "1",
            parentEnumValue: "5402",
            enumValue: "542329",
            enumName: "白朗县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130247,
            enumGroupGuid: "1",
            parentEnumValue: "5402",
            enumValue: "542330",
            enumName: "仁布县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130248,
            enumGroupGuid: "1",
            parentEnumValue: "5402",
            enumValue: "542331",
            enumName: "康马县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130249,
            enumGroupGuid: "1",
            parentEnumValue: "5402",
            enumValue: "542332",
            enumName: "定结县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130250,
            enumGroupGuid: "1",
            parentEnumValue: "5402",
            enumValue: "542333",
            enumName: "仲巴县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130251,
            enumGroupGuid: "1",
            parentEnumValue: "5402",
            enumValue: "542334",
            enumName: "亚东县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130252,
            enumGroupGuid: "1",
            parentEnumValue: "5402",
            enumValue: "542335",
            enumName: "吉隆县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130225,
            enumGroupGuid: "1",
            parentEnumValue: "5402",
            enumValue: "542336",
            enumName: "聂拉木县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130226,
            enumGroupGuid: "1",
            parentEnumValue: "5402",
            enumValue: "542337",
            enumName: "萨嘎县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130227,
            enumGroupGuid: "1",
            parentEnumValue: "5402",
            enumValue: "542338",
            enumName: "岗巴县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
        ],
      },
      {
        fldEnumId: 130297,
        enumGroupGuid: "1",
        parentEnumValue: "54",
        enumValue: "5403",
        enumName: "昌都市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        children: [
          {
            fldEnumId: 130298,
            enumGroupGuid: "1",
            parentEnumValue: "5403",
            enumValue: "540302",
            enumName: "卡若区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130228,
            enumGroupGuid: "1",
            parentEnumValue: "5403",
            enumValue: "542122",
            enumName: "江达县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130229,
            enumGroupGuid: "1",
            parentEnumValue: "5403",
            enumValue: "542123",
            enumName: "贡觉县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130230,
            enumGroupGuid: "1",
            parentEnumValue: "5403",
            enumValue: "542124",
            enumName: "类乌齐县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130231,
            enumGroupGuid: "1",
            parentEnumValue: "5403",
            enumValue: "542125",
            enumName: "丁青县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130232,
            enumGroupGuid: "1",
            parentEnumValue: "5403",
            enumValue: "542126",
            enumName: "察雅县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130233,
            enumGroupGuid: "1",
            parentEnumValue: "5403",
            enumValue: "542127",
            enumName: "八宿县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130234,
            enumGroupGuid: "1",
            parentEnumValue: "5403",
            enumValue: "542128",
            enumName: "左贡县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130235,
            enumGroupGuid: "1",
            parentEnumValue: "5403",
            enumValue: "542129",
            enumName: "芒康县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130236,
            enumGroupGuid: "1",
            parentEnumValue: "5403",
            enumValue: "542132",
            enumName: "洛隆县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130237,
            enumGroupGuid: "1",
            parentEnumValue: "5403",
            enumValue: "542133",
            enumName: "边坝县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
        ],
      },
      {
        fldEnumId: 130304,
        enumGroupGuid: "1",
        parentEnumValue: "54",
        enumValue: "5404",
        enumName: "林芝市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        children: [
          {
            fldEnumId: 130382,
            enumGroupGuid: "1",
            parentEnumValue: "5404",
            enumValue: "540402",
            enumName: "巴宜区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130238,
            enumGroupGuid: "1",
            parentEnumValue: "5404",
            enumValue: "542622",
            enumName: "工布江达县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130253,
            enumGroupGuid: "1",
            parentEnumValue: "5404",
            enumValue: "542623",
            enumName: "米林县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130254,
            enumGroupGuid: "1",
            parentEnumValue: "5404",
            enumValue: "542624",
            enumName: "墨脱县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130255,
            enumGroupGuid: "1",
            parentEnumValue: "5404",
            enumValue: "542625",
            enumName: "波密县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130256,
            enumGroupGuid: "1",
            parentEnumValue: "5404",
            enumValue: "542626",
            enumName: "察隅县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130257,
            enumGroupGuid: "1",
            parentEnumValue: "5404",
            enumValue: "542627",
            enumName: "朗县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
        ],
      },
      {
        fldEnumId: 130299,
        enumGroupGuid: "1",
        parentEnumValue: "54",
        enumValue: "5405",
        enumName: "山南市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        children: [
          {
            fldEnumId: 130300,
            enumGroupGuid: "1",
            parentEnumValue: "5405",
            enumValue: "540500",
            enumName: "山南市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130258,
            enumGroupGuid: "1",
            parentEnumValue: "5405",
            enumValue: "542221",
            enumName: "乃东区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130259,
            enumGroupGuid: "1",
            parentEnumValue: "5405",
            enumValue: "542222",
            enumName: "扎囊县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130260,
            enumGroupGuid: "1",
            parentEnumValue: "5405",
            enumValue: "542223",
            enumName: "贡嘎县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130261,
            enumGroupGuid: "1",
            parentEnumValue: "5405",
            enumValue: "542224",
            enumName: "桑日县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130262,
            enumGroupGuid: "1",
            parentEnumValue: "5405",
            enumValue: "542225",
            enumName: "琼结县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130263,
            enumGroupGuid: "1",
            parentEnumValue: "5405",
            enumValue: "542226",
            enumName: "曲松县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130264,
            enumGroupGuid: "1",
            parentEnumValue: "5405",
            enumValue: "542227",
            enumName: "措美县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130265,
            enumGroupGuid: "1",
            parentEnumValue: "5405",
            enumValue: "542228",
            enumName: "洛扎县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130266,
            enumGroupGuid: "1",
            parentEnumValue: "5405",
            enumValue: "542229",
            enumName: "加查县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130267,
            enumGroupGuid: "1",
            parentEnumValue: "5405",
            enumValue: "542231",
            enumName: "隆子县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130268,
            enumGroupGuid: "1",
            parentEnumValue: "5405",
            enumValue: "542232",
            enumName: "错那县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130269,
            enumGroupGuid: "1",
            parentEnumValue: "5405",
            enumValue: "542233",
            enumName: "浪卡子县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
        ],
      },
      {
        fldEnumId: 13269,
        enumGroupGuid: "1",
        parentEnumValue: "54",
        enumValue: "5421",
        enumName: "昌都地区",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 1,
        enumNameother: "昌都地区",
        children: [
          {
            fldEnumId: 16250,
            enumGroupGuid: "1",
            parentEnumValue: "5421",
            enumValue: "542100",
            enumName: "昌都地区本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 13270,
            enumGroupGuid: "1",
            parentEnumValue: "5421",
            enumValue: "542121",
            enumName: "昌都县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 9,
            enumNameother: "昌都县",
          },
        ],
      },
      {
        fldEnumId: 13292,
        enumGroupGuid: "1",
        parentEnumValue: "54",
        enumValue: "5422",
        enumName: "山南地区",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 2,
        enumNameother: "山南地区",
        children: [
          {
            fldEnumId: 16252,
            enumGroupGuid: "1",
            parentEnumValue: "5422",
            enumValue: "542200",
            enumName: "山南地区本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
        ],
      },
      {
        fldEnumId: 13317,
        enumGroupGuid: "1",
        parentEnumValue: "54",
        enumValue: "5423",
        enumName: "日喀则地区",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 3,
        enumNameother: "日喀则地区",
        children: [
          {
            fldEnumId: 16232,
            enumGroupGuid: "1",
            parentEnumValue: "5423",
            enumValue: "542300",
            enumName: "日喀则地区本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 13318,
            enumGroupGuid: "1",
            parentEnumValue: "5423",
            enumValue: "542301",
            enumName: "日喀则市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 32,
            enumNameother: "日喀则市",
          },
        ],
      },
      {
        fldEnumId: 13354,
        enumGroupGuid: "1",
        parentEnumValue: "54",
        enumValue: "5424",
        enumName: "那曲地区",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 4,
        enumNameother: "那曲地区",
        children: [
          {
            fldEnumId: 16234,
            enumGroupGuid: "1",
            parentEnumValue: "5424",
            enumValue: "542400",
            enumName: "那曲地区本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 13355,
            enumGroupGuid: "1",
            parentEnumValue: "5424",
            enumValue: "542421",
            enumName: "那曲县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 50,
            enumNameother: "那曲县",
          },
          {
            fldEnumId: 13357,
            enumGroupGuid: "1",
            parentEnumValue: "5424",
            enumValue: "542422",
            enumName: "嘉黎县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 51,
            enumNameother: "嘉黎县",
          },
          {
            fldEnumId: 13359,
            enumGroupGuid: "1",
            parentEnumValue: "5424",
            enumValue: "542423",
            enumName: "比如县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 52,
            enumNameother: "比如县",
          },
          {
            fldEnumId: 13361,
            enumGroupGuid: "1",
            parentEnumValue: "5424",
            enumValue: "542424",
            enumName: "聂荣县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 53,
            enumNameother: "聂荣县",
          },
          {
            fldEnumId: 13363,
            enumGroupGuid: "1",
            parentEnumValue: "5424",
            enumValue: "542425",
            enumName: "安多县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 54,
            enumNameother: "安多县",
          },
          {
            fldEnumId: 13365,
            enumGroupGuid: "1",
            parentEnumValue: "5424",
            enumValue: "542426",
            enumName: "申扎县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 55,
            enumNameother: "申扎县",
          },
          {
            fldEnumId: 13367,
            enumGroupGuid: "1",
            parentEnumValue: "5424",
            enumValue: "542427",
            enumName: "索县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 56,
            enumNameother: "索县",
          },
          {
            fldEnumId: 13369,
            enumGroupGuid: "1",
            parentEnumValue: "5424",
            enumValue: "542428",
            enumName: "班戈县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 57,
            enumNameother: "班戈县",
          },
          {
            fldEnumId: 13371,
            enumGroupGuid: "1",
            parentEnumValue: "5424",
            enumValue: "542429",
            enumName: "巴青县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 58,
            enumNameother: "巴青县",
          },
          {
            fldEnumId: 13373,
            enumGroupGuid: "1",
            parentEnumValue: "5424",
            enumValue: "542430",
            enumName: "尼玛县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 59,
            enumNameother: "尼玛县",
          },
        ],
      },
      {
        fldEnumId: 13375,
        enumGroupGuid: "1",
        parentEnumValue: "54",
        enumValue: "5425",
        enumName: "阿里地区",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 5,
        enumNameother: "阿里地区",
        children: [
          {
            fldEnumId: 16236,
            enumGroupGuid: "1",
            parentEnumValue: "5425",
            enumValue: "542500",
            enumName: "阿里地区本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 13376,
            enumGroupGuid: "1",
            parentEnumValue: "5425",
            enumValue: "542521",
            enumName: "普兰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 60,
            enumNameother: "普兰县",
          },
          {
            fldEnumId: 13378,
            enumGroupGuid: "1",
            parentEnumValue: "5425",
            enumValue: "542522",
            enumName: "札达县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 61,
            enumNameother: "札达县",
          },
          {
            fldEnumId: 13380,
            enumGroupGuid: "1",
            parentEnumValue: "5425",
            enumValue: "542523",
            enumName: "噶尔县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 62,
            enumNameother: "噶尔县",
          },
          {
            fldEnumId: 13382,
            enumGroupGuid: "1",
            parentEnumValue: "5425",
            enumValue: "542524",
            enumName: "日土县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 63,
            enumNameother: "日土县",
          },
          {
            fldEnumId: 13385,
            enumGroupGuid: "1",
            parentEnumValue: "5425",
            enumValue: "542525",
            enumName: "革吉县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 64,
            enumNameother: "革吉县",
          },
          {
            fldEnumId: 13389,
            enumGroupGuid: "1",
            parentEnumValue: "5425",
            enumValue: "542526",
            enumName: "改则县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 65,
            enumNameother: "改则县",
          },
          {
            fldEnumId: 13393,
            enumGroupGuid: "1",
            parentEnumValue: "5425",
            enumValue: "542527",
            enumName: "措勤县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 66,
            enumNameother: "措勤县",
          },
        ],
      },
      {
        fldEnumId: 13397,
        enumGroupGuid: "1",
        parentEnumValue: "54",
        enumValue: "5426",
        enumName: "林芝地区",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 6,
        enumNameother: "林芝地区",
        children: [
          {
            fldEnumId: 16238,
            enumGroupGuid: "1",
            parentEnumValue: "5426",
            enumValue: "542600",
            enumName: "林芝地区本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 13399,
            enumGroupGuid: "1",
            parentEnumValue: "5426",
            enumValue: "542621",
            enumName: "林芝县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 67,
            enumNameother: "林芝县",
          },
        ],
      },
    ],
  },
  {
    enumValue: "61",
    enumName: "陕西省",
    children: [
      {
        fldEnumId: 13428,
        enumGroupGuid: "1",
        parentEnumValue: "61",
        enumValue: "6101",
        enumName: "西安市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        enumNameother: "西安市",
        children: [
          {
            fldEnumId: 13430,
            enumGroupGuid: "1",
            parentEnumValue: "6101",
            enumValue: "610100",
            enumName: "西安市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
            enumNameother: "西安市",
          },
          {
            fldEnumId: 13433,
            enumGroupGuid: "1",
            parentEnumValue: "6101",
            enumValue: "610102",
            enumName: "新城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "新城区",
          },
          {
            fldEnumId: 13438,
            enumGroupGuid: "1",
            parentEnumValue: "6101",
            enumValue: "610103",
            enumName: "碑林区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 2,
            enumNameother: "碑林区",
          },
          {
            fldEnumId: 13442,
            enumGroupGuid: "1",
            parentEnumValue: "6101",
            enumValue: "610104",
            enumName: "莲湖区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 3,
            enumNameother: "莲湖区",
          },
          {
            fldEnumId: 13446,
            enumGroupGuid: "1",
            parentEnumValue: "6101",
            enumValue: "610111",
            enumName: "灞桥区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 4,
            enumNameother: "灞桥区",
          },
          {
            fldEnumId: 13450,
            enumGroupGuid: "1",
            parentEnumValue: "6101",
            enumValue: "610112",
            enumName: "未央区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 5,
            enumNameother: "未央区",
          },
          {
            fldEnumId: 13453,
            enumGroupGuid: "1",
            parentEnumValue: "6101",
            enumValue: "610113",
            enumName: "雁塔区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 6,
            enumNameother: "雁塔区",
          },
          {
            fldEnumId: 13458,
            enumGroupGuid: "1",
            parentEnumValue: "6101",
            enumValue: "610114",
            enumName: "阎良区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 7,
            enumNameother: "阎良区",
          },
          {
            fldEnumId: 13462,
            enumGroupGuid: "1",
            parentEnumValue: "6101",
            enumValue: "610115",
            enumName: "临潼区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 8,
            enumNameother: "临潼区",
          },
          {
            fldEnumId: 13466,
            enumGroupGuid: "1",
            parentEnumValue: "6101",
            enumValue: "610116",
            enumName: "长安区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 9,
            enumNameother: "长安区",
          },
          {
            fldEnumId: 13470,
            enumGroupGuid: "1",
            parentEnumValue: "6101",
            enumValue: "610122",
            enumName: "蓝田县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 10,
            enumNameother: "蓝田县",
          },
          {
            fldEnumId: 13475,
            enumGroupGuid: "1",
            parentEnumValue: "6101",
            enumValue: "610124",
            enumName: "周至县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 11,
            enumNameother: "周至县",
          },
          {
            fldEnumId: 13479,
            enumGroupGuid: "1",
            parentEnumValue: "6101",
            enumValue: "610125",
            enumName: "户县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 12,
            enumNameother: "户县",
          },
          {
            fldEnumId: 13483,
            enumGroupGuid: "1",
            parentEnumValue: "6101",
            enumValue: "610126",
            enumName: "高陵县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 13,
            enumNameother: "高陵县",
          },
        ],
      },
      {
        fldEnumId: 13487,
        enumGroupGuid: "1",
        parentEnumValue: "61",
        enumValue: "6102",
        enumName: "铜川市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 1,
        enumNameother: "铜川市",
        children: [
          {
            fldEnumId: 13489,
            enumGroupGuid: "1",
            parentEnumValue: "6102",
            enumValue: "610200",
            enumName: "铜川市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 14,
            enumNameother: "铜川市",
          },
          {
            fldEnumId: 13493,
            enumGroupGuid: "1",
            parentEnumValue: "6102",
            enumValue: "610202",
            enumName: "王益区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 15,
            enumNameother: "王益区",
          },
          {
            fldEnumId: 13497,
            enumGroupGuid: "1",
            parentEnumValue: "6102",
            enumValue: "610203",
            enumName: "印台区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 16,
            enumNameother: "印台区",
          },
          {
            fldEnumId: 13501,
            enumGroupGuid: "1",
            parentEnumValue: "6102",
            enumValue: "610204",
            enumName: "耀州区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 17,
            enumNameother: "耀州区",
          },
          {
            fldEnumId: 13505,
            enumGroupGuid: "1",
            parentEnumValue: "6102",
            enumValue: "610222",
            enumName: "宜君县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 18,
            enumNameother: "宜君县",
          },
        ],
      },
      {
        fldEnumId: 13509,
        enumGroupGuid: "1",
        parentEnumValue: "61",
        enumValue: "6103",
        enumName: "宝鸡市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 2,
        enumNameother: "宝鸡市",
        children: [
          {
            fldEnumId: 13511,
            enumGroupGuid: "1",
            parentEnumValue: "6103",
            enumValue: "610300",
            enumName: "宝鸡市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 19,
            enumNameother: "宝鸡市",
          },
          {
            fldEnumId: 13515,
            enumGroupGuid: "1",
            parentEnumValue: "6103",
            enumValue: "610302",
            enumName: "渭滨区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 20,
            enumNameother: "渭滨区",
          },
          {
            fldEnumId: 13519,
            enumGroupGuid: "1",
            parentEnumValue: "6103",
            enumValue: "610303",
            enumName: "金台区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 21,
            enumNameother: "金台区",
          },
          {
            fldEnumId: 13523,
            enumGroupGuid: "1",
            parentEnumValue: "6103",
            enumValue: "610304",
            enumName: "陈仓区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 22,
            enumNameother: "陈仓区",
          },
          {
            fldEnumId: 13527,
            enumGroupGuid: "1",
            parentEnumValue: "6103",
            enumValue: "610322",
            enumName: "凤翔县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 23,
            enumNameother: "凤翔县",
          },
          {
            fldEnumId: 13531,
            enumGroupGuid: "1",
            parentEnumValue: "6103",
            enumValue: "610323",
            enumName: "岐山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 24,
            enumNameother: "岐山县",
          },
          {
            fldEnumId: 13535,
            enumGroupGuid: "1",
            parentEnumValue: "6103",
            enumValue: "610324",
            enumName: "扶风县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 25,
            enumNameother: "扶风县",
          },
          {
            fldEnumId: 13539,
            enumGroupGuid: "1",
            parentEnumValue: "6103",
            enumValue: "610326",
            enumName: "眉县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 26,
            enumNameother: "眉县",
          },
          {
            fldEnumId: 13543,
            enumGroupGuid: "1",
            parentEnumValue: "6103",
            enumValue: "610327",
            enumName: "陇县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 27,
            enumNameother: "陇县",
          },
          {
            fldEnumId: 13547,
            enumGroupGuid: "1",
            parentEnumValue: "6103",
            enumValue: "610328",
            enumName: "千阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 28,
            enumNameother: "千阳县",
          },
          {
            fldEnumId: 13551,
            enumGroupGuid: "1",
            parentEnumValue: "6103",
            enumValue: "610329",
            enumName: "麟游县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 29,
            enumNameother: "麟游县",
          },
          {
            fldEnumId: 13555,
            enumGroupGuid: "1",
            parentEnumValue: "6103",
            enumValue: "610330",
            enumName: "凤县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 30,
            enumNameother: "凤县",
          },
          {
            fldEnumId: 13559,
            enumGroupGuid: "1",
            parentEnumValue: "6103",
            enumValue: "610331",
            enumName: "太白县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 31,
            enumNameother: "太白县",
          },
        ],
      },
      {
        fldEnumId: 13563,
        enumGroupGuid: "1",
        parentEnumValue: "61",
        enumValue: "6104",
        enumName: "咸阳市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 3,
        enumNameother: "咸阳市",
        children: [
          {
            fldEnumId: 13565,
            enumGroupGuid: "1",
            parentEnumValue: "6104",
            enumValue: "610400",
            enumName: "咸阳市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 32,
            enumNameother: "咸阳市",
          },
          {
            fldEnumId: 13569,
            enumGroupGuid: "1",
            parentEnumValue: "6104",
            enumValue: "610402",
            enumName: "秦都区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 33,
            enumNameother: "秦都区",
          },
          {
            fldEnumId: 13573,
            enumGroupGuid: "1",
            parentEnumValue: "6104",
            enumValue: "610403",
            enumName: "杨凌区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 34,
            enumNameother: "杨凌区",
          },
          {
            fldEnumId: 13577,
            enumGroupGuid: "1",
            parentEnumValue: "6104",
            enumValue: "610404",
            enumName: "渭城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 35,
            enumNameother: "渭城区",
          },
          {
            fldEnumId: 13580,
            enumGroupGuid: "1",
            parentEnumValue: "6104",
            enumValue: "610422",
            enumName: "三原县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 36,
            enumNameother: "三原县",
          },
          {
            fldEnumId: 13584,
            enumGroupGuid: "1",
            parentEnumValue: "6104",
            enumValue: "610423",
            enumName: "泾阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 37,
            enumNameother: "泾阳县",
          },
          {
            fldEnumId: 13588,
            enumGroupGuid: "1",
            parentEnumValue: "6104",
            enumValue: "610424",
            enumName: "乾县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 38,
            enumNameother: "乾县",
          },
          {
            fldEnumId: 13592,
            enumGroupGuid: "1",
            parentEnumValue: "6104",
            enumValue: "610425",
            enumName: "礼泉县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 39,
            enumNameother: "礼泉县",
          },
          {
            fldEnumId: 13596,
            enumGroupGuid: "1",
            parentEnumValue: "6104",
            enumValue: "610426",
            enumName: "永寿县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 40,
            enumNameother: "永寿县",
          },
          {
            fldEnumId: 13600,
            enumGroupGuid: "1",
            parentEnumValue: "6104",
            enumValue: "610427",
            enumName: "彬县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 41,
            enumNameother: "彬县",
          },
          {
            fldEnumId: 13604,
            enumGroupGuid: "1",
            parentEnumValue: "6104",
            enumValue: "610428",
            enumName: "长武县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 42,
            enumNameother: "长武县",
          },
          {
            fldEnumId: 13608,
            enumGroupGuid: "1",
            parentEnumValue: "6104",
            enumValue: "610429",
            enumName: "旬邑县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 43,
            enumNameother: "旬邑县",
          },
          {
            fldEnumId: 13612,
            enumGroupGuid: "1",
            parentEnumValue: "6104",
            enumValue: "610430",
            enumName: "淳化县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 44,
            enumNameother: "淳化县",
          },
          {
            fldEnumId: 13617,
            enumGroupGuid: "1",
            parentEnumValue: "6104",
            enumValue: "610431",
            enumName: "武功县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 45,
            enumNameother: "武功县",
          },
          {
            fldEnumId: 13621,
            enumGroupGuid: "1",
            parentEnumValue: "6104",
            enumValue: "610481",
            enumName: "兴平市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 46,
            enumNameother: "兴平市",
          },
        ],
      },
      {
        fldEnumId: 13625,
        enumGroupGuid: "1",
        parentEnumValue: "61",
        enumValue: "6105",
        enumName: "渭南市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 4,
        enumNameother: "渭南市",
        children: [
          {
            fldEnumId: 13627,
            enumGroupGuid: "1",
            parentEnumValue: "6105",
            enumValue: "610500",
            enumName: "渭南市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 47,
            enumNameother: "渭南市",
          },
          {
            fldEnumId: 13631,
            enumGroupGuid: "1",
            parentEnumValue: "6105",
            enumValue: "610502",
            enumName: "临渭区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 48,
            enumNameother: "临渭区",
          },
          {
            fldEnumId: 13635,
            enumGroupGuid: "1",
            parentEnumValue: "6105",
            enumValue: "610521",
            enumName: "华县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 49,
            enumNameother: "华县",
          },
          {
            fldEnumId: 13639,
            enumGroupGuid: "1",
            parentEnumValue: "6105",
            enumValue: "610522",
            enumName: "潼关县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 50,
            enumNameother: "潼关县",
          },
          {
            fldEnumId: 13643,
            enumGroupGuid: "1",
            parentEnumValue: "6105",
            enumValue: "610523",
            enumName: "大荔县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 51,
            enumNameother: "大荔县",
          },
          {
            fldEnumId: 13647,
            enumGroupGuid: "1",
            parentEnumValue: "6105",
            enumValue: "610524",
            enumName: "合阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 52,
            enumNameother: "合阳县",
          },
          {
            fldEnumId: 13650,
            enumGroupGuid: "1",
            parentEnumValue: "6105",
            enumValue: "610525",
            enumName: "澄城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 53,
            enumNameother: "澄城县",
          },
          {
            fldEnumId: 13654,
            enumGroupGuid: "1",
            parentEnumValue: "6105",
            enumValue: "610526",
            enumName: "蒲城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 54,
            enumNameother: "蒲城县",
          },
          {
            fldEnumId: 13658,
            enumGroupGuid: "1",
            parentEnumValue: "6105",
            enumValue: "610527",
            enumName: "白水县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 55,
            enumNameother: "白水县",
          },
          {
            fldEnumId: 13662,
            enumGroupGuid: "1",
            parentEnumValue: "6105",
            enumValue: "610528",
            enumName: "富平县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 56,
            enumNameother: "富平县",
          },
          {
            fldEnumId: 13667,
            enumGroupGuid: "1",
            parentEnumValue: "6105",
            enumValue: "610581",
            enumName: "韩城市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 57,
            enumNameother: "韩城市",
          },
          {
            fldEnumId: 13671,
            enumGroupGuid: "1",
            parentEnumValue: "6105",
            enumValue: "610582",
            enumName: "华阴市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 58,
            enumNameother: "华阴市",
          },
        ],
      },
      {
        fldEnumId: 13675,
        enumGroupGuid: "1",
        parentEnumValue: "61",
        enumValue: "6106",
        enumName: "延安市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 5,
        enumNameother: "延安市",
        children: [
          {
            fldEnumId: 13678,
            enumGroupGuid: "1",
            parentEnumValue: "6106",
            enumValue: "610600",
            enumName: "延安市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 59,
            enumNameother: "延安市",
          },
          {
            fldEnumId: 13682,
            enumGroupGuid: "1",
            parentEnumValue: "6106",
            enumValue: "610602",
            enumName: "宝塔区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 60,
            enumNameother: "宝塔区",
          },
          {
            fldEnumId: 13686,
            enumGroupGuid: "1",
            parentEnumValue: "6106",
            enumValue: "610621",
            enumName: "延长县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 61,
            enumNameother: "延长县",
          },
          {
            fldEnumId: 13690,
            enumGroupGuid: "1",
            parentEnumValue: "6106",
            enumValue: "610622",
            enumName: "延川县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 62,
            enumNameother: "延川县",
          },
          {
            fldEnumId: 13694,
            enumGroupGuid: "1",
            parentEnumValue: "6106",
            enumValue: "610623",
            enumName: "子长县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 63,
            enumNameother: "子长县",
          },
          {
            fldEnumId: 13698,
            enumGroupGuid: "1",
            parentEnumValue: "6106",
            enumValue: "610624",
            enumName: "安塞县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 64,
            enumNameother: "安塞县",
          },
          {
            fldEnumId: 13702,
            enumGroupGuid: "1",
            parentEnumValue: "6106",
            enumValue: "610625",
            enumName: "志丹县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 65,
            enumNameother: "志丹县",
          },
          {
            fldEnumId: 13705,
            enumGroupGuid: "1",
            parentEnumValue: "6106",
            enumValue: "610626",
            enumName: "吴起县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 66,
            enumNameother: "吴起县",
          },
          {
            fldEnumId: 13710,
            enumGroupGuid: "1",
            parentEnumValue: "6106",
            enumValue: "610627",
            enumName: "甘泉县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 67,
            enumNameother: "甘泉县",
          },
          {
            fldEnumId: 13715,
            enumGroupGuid: "1",
            parentEnumValue: "6106",
            enumValue: "610628",
            enumName: "富县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 68,
            enumNameother: "富县",
          },
          {
            fldEnumId: 13719,
            enumGroupGuid: "1",
            parentEnumValue: "6106",
            enumValue: "610629",
            enumName: "洛川县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 69,
            enumNameother: "洛川县",
          },
          {
            fldEnumId: 13723,
            enumGroupGuid: "1",
            parentEnumValue: "6106",
            enumValue: "610630",
            enumName: "宜川县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 70,
            enumNameother: "宜川县",
          },
          {
            fldEnumId: 13727,
            enumGroupGuid: "1",
            parentEnumValue: "6106",
            enumValue: "610631",
            enumName: "黄龙县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 71,
            enumNameother: "黄龙县",
          },
          {
            fldEnumId: 13730,
            enumGroupGuid: "1",
            parentEnumValue: "6106",
            enumValue: "610632",
            enumName: "黄陵县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 72,
            enumNameother: "黄陵县",
          },
        ],
      },
      {
        fldEnumId: 13735,
        enumGroupGuid: "1",
        parentEnumValue: "61",
        enumValue: "6107",
        enumName: "汉中市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 6,
        enumNameother: "汉中市",
        children: [
          {
            fldEnumId: 13737,
            enumGroupGuid: "1",
            parentEnumValue: "6107",
            enumValue: "610700",
            enumName: "汉中市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 73,
            enumNameother: "汉中市",
          },
          {
            fldEnumId: 13741,
            enumGroupGuid: "1",
            parentEnumValue: "6107",
            enumValue: "610702",
            enumName: "汉台区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 74,
            enumNameother: "汉台区",
          },
          {
            fldEnumId: 13745,
            enumGroupGuid: "1",
            parentEnumValue: "6107",
            enumValue: "610721",
            enumName: "南郑县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 75,
            enumNameother: "南郑县",
          },
          {
            fldEnumId: 13749,
            enumGroupGuid: "1",
            parentEnumValue: "6107",
            enumValue: "610722",
            enumName: "城固县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 76,
            enumNameother: "城固县",
          },
          {
            fldEnumId: 13753,
            enumGroupGuid: "1",
            parentEnumValue: "6107",
            enumValue: "610723",
            enumName: "洋县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 77,
            enumNameother: "洋县",
          },
          {
            fldEnumId: 13757,
            enumGroupGuid: "1",
            parentEnumValue: "6107",
            enumValue: "610724",
            enumName: "西乡县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 78,
            enumNameother: "西乡县",
          },
          {
            fldEnumId: 13761,
            enumGroupGuid: "1",
            parentEnumValue: "6107",
            enumValue: "610725",
            enumName: "勉县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 79,
            enumNameother: "勉县",
          },
          {
            fldEnumId: 13764,
            enumGroupGuid: "1",
            parentEnumValue: "6107",
            enumValue: "610726",
            enumName: "宁强县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 80,
            enumNameother: "宁强县",
          },
          {
            fldEnumId: 13768,
            enumGroupGuid: "1",
            parentEnumValue: "6107",
            enumValue: "610727",
            enumName: "略阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 81,
            enumNameother: "略阳县",
          },
          {
            fldEnumId: 13772,
            enumGroupGuid: "1",
            parentEnumValue: "6107",
            enumValue: "610728",
            enumName: "镇巴县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 82,
            enumNameother: "镇巴县",
          },
          {
            fldEnumId: 13776,
            enumGroupGuid: "1",
            parentEnumValue: "6107",
            enumValue: "610729",
            enumName: "留坝县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 83,
            enumNameother: "留坝县",
          },
          {
            fldEnumId: 13780,
            enumGroupGuid: "1",
            parentEnumValue: "6107",
            enumValue: "610730",
            enumName: "佛坪县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 84,
            enumNameother: "佛坪县",
          },
        ],
      },
      {
        fldEnumId: 13784,
        enumGroupGuid: "1",
        parentEnumValue: "61",
        enumValue: "6108",
        enumName: "榆林市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 7,
        enumNameother: "榆林市",
        children: [
          {
            fldEnumId: 13786,
            enumGroupGuid: "1",
            parentEnumValue: "6108",
            enumValue: "610800",
            enumName: "榆林市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 85,
            enumNameother: "榆林市",
          },
          {
            fldEnumId: 13790,
            enumGroupGuid: "1",
            parentEnumValue: "6108",
            enumValue: "610802",
            enumName: "榆阳区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 86,
            enumNameother: "榆阳区",
          },
          {
            fldEnumId: 13794,
            enumGroupGuid: "1",
            parentEnumValue: "6108",
            enumValue: "610821",
            enumName: "神木县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 87,
            enumNameother: "神木县",
          },
          {
            fldEnumId: 13798,
            enumGroupGuid: "1",
            parentEnumValue: "6108",
            enumValue: "610822",
            enumName: "府谷县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 88,
            enumNameother: "府谷县",
          },
          {
            fldEnumId: 13802,
            enumGroupGuid: "1",
            parentEnumValue: "6108",
            enumValue: "610823",
            enumName: "横山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 89,
            enumNameother: "横山县",
          },
          {
            fldEnumId: 13806,
            enumGroupGuid: "1",
            parentEnumValue: "6108",
            enumValue: "610824",
            enumName: "靖边县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 90,
            enumNameother: "靖边县",
          },
          {
            fldEnumId: 13810,
            enumGroupGuid: "1",
            parentEnumValue: "6108",
            enumValue: "610825",
            enumName: "定边县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 91,
            enumNameother: "定边县",
          },
          {
            fldEnumId: 13814,
            enumGroupGuid: "1",
            parentEnumValue: "6108",
            enumValue: "610826",
            enumName: "绥德县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 92,
            enumNameother: "绥德县",
          },
          {
            fldEnumId: 13818,
            enumGroupGuid: "1",
            parentEnumValue: "6108",
            enumValue: "610827",
            enumName: "米脂县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 93,
            enumNameother: "米脂县",
          },
          {
            fldEnumId: 13822,
            enumGroupGuid: "1",
            parentEnumValue: "6108",
            enumValue: "610828",
            enumName: "佳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 94,
            enumNameother: "佳县",
          },
          {
            fldEnumId: 13827,
            enumGroupGuid: "1",
            parentEnumValue: "6108",
            enumValue: "610829",
            enumName: "吴堡县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 95,
            enumNameother: "吴堡县",
          },
          {
            fldEnumId: 13831,
            enumGroupGuid: "1",
            parentEnumValue: "6108",
            enumValue: "610830",
            enumName: "清涧县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 96,
            enumNameother: "清涧县",
          },
          {
            fldEnumId: 13835,
            enumGroupGuid: "1",
            parentEnumValue: "6108",
            enumValue: "610831",
            enumName: "子洲县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 97,
            enumNameother: "子洲县",
          },
        ],
      },
      {
        fldEnumId: 13838,
        enumGroupGuid: "1",
        parentEnumValue: "61",
        enumValue: "6109",
        enumName: "安康市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 8,
        enumNameother: "安康市",
        children: [
          {
            fldEnumId: 13840,
            enumGroupGuid: "1",
            parentEnumValue: "6109",
            enumValue: "610900",
            enumName: "安康市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 98,
            enumNameother: "安康市",
          },
          {
            fldEnumId: 13844,
            enumGroupGuid: "1",
            parentEnumValue: "6109",
            enumValue: "610902",
            enumName: "汉滨区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 99,
            enumNameother: "汉滨区",
          },
          {
            fldEnumId: 13848,
            enumGroupGuid: "1",
            parentEnumValue: "6109",
            enumValue: "610921",
            enumName: "汉阴县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 100,
            enumNameother: "汉阴县",
          },
          {
            fldEnumId: 13852,
            enumGroupGuid: "1",
            parentEnumValue: "6109",
            enumValue: "610922",
            enumName: "石泉县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 101,
            enumNameother: "石泉县",
          },
          {
            fldEnumId: 13856,
            enumGroupGuid: "1",
            parentEnumValue: "6109",
            enumValue: "610923",
            enumName: "宁陕县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 102,
            enumNameother: "宁陕县",
          },
          {
            fldEnumId: 13860,
            enumGroupGuid: "1",
            parentEnumValue: "6109",
            enumValue: "610924",
            enumName: "紫阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 103,
            enumNameother: "紫阳县",
          },
          {
            fldEnumId: 13864,
            enumGroupGuid: "1",
            parentEnumValue: "6109",
            enumValue: "610925",
            enumName: "岚皋县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 104,
            enumNameother: "岚皋县",
          },
          {
            fldEnumId: 13868,
            enumGroupGuid: "1",
            parentEnumValue: "6109",
            enumValue: "610926",
            enumName: "平利县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 105,
            enumNameother: "平利县",
          },
          {
            fldEnumId: 13872,
            enumGroupGuid: "1",
            parentEnumValue: "6109",
            enumValue: "610927",
            enumName: "镇坪县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 106,
            enumNameother: "镇坪县",
          },
          {
            fldEnumId: 13876,
            enumGroupGuid: "1",
            parentEnumValue: "6109",
            enumValue: "610928",
            enumName: "旬阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 107,
            enumNameother: "旬阳县",
          },
          {
            fldEnumId: 13880,
            enumGroupGuid: "1",
            parentEnumValue: "6109",
            enumValue: "610929",
            enumName: "白河县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 108,
            enumNameother: "白河县",
          },
        ],
      },
      {
        fldEnumId: 13884,
        enumGroupGuid: "1",
        parentEnumValue: "61",
        enumValue: "6110",
        enumName: "商洛市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 9,
        enumNameother: "商洛市",
        children: [
          {
            fldEnumId: 13886,
            enumGroupGuid: "1",
            parentEnumValue: "6110",
            enumValue: "611000",
            enumName: "商洛市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 109,
            enumNameother: "商洛市",
          },
          {
            fldEnumId: 13890,
            enumGroupGuid: "1",
            parentEnumValue: "6110",
            enumValue: "611002",
            enumName: "商州区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 110,
            enumNameother: "商州区",
          },
          {
            fldEnumId: 13894,
            enumGroupGuid: "1",
            parentEnumValue: "6110",
            enumValue: "611021",
            enumName: "洛南县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 111,
            enumNameother: "洛南县",
          },
          {
            fldEnumId: 13898,
            enumGroupGuid: "1",
            parentEnumValue: "6110",
            enumValue: "611022",
            enumName: "丹凤县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 112,
            enumNameother: "丹凤县",
          },
          {
            fldEnumId: 13902,
            enumGroupGuid: "1",
            parentEnumValue: "6110",
            enumValue: "611023",
            enumName: "商南县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 113,
            enumNameother: "商南县",
          },
          {
            fldEnumId: 13906,
            enumGroupGuid: "1",
            parentEnumValue: "6110",
            enumValue: "611024",
            enumName: "山阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 114,
            enumNameother: "山阳县",
          },
          {
            fldEnumId: 13910,
            enumGroupGuid: "1",
            parentEnumValue: "6110",
            enumValue: "611025",
            enumName: "镇安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 115,
            enumNameother: "镇安县",
          },
          {
            fldEnumId: 13914,
            enumGroupGuid: "1",
            parentEnumValue: "6110",
            enumValue: "611026",
            enumName: "柞水县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 116,
            enumNameother: "柞水县",
          },
        ],
      },
    ],
  },
  {
    enumValue: "62",
    enumName: "甘肃省",
    children: [
      {
        fldEnumId: 13920,
        enumGroupGuid: "1",
        parentEnumValue: "62",
        enumValue: "6201",
        enumName: "兰州市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        enumNameother: "兰州市",
        children: [
          {
            fldEnumId: 13922,
            enumGroupGuid: "1",
            parentEnumValue: "6201",
            enumValue: "620100",
            enumName: "兰州市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
            enumNameother: "兰州市",
          },
          {
            fldEnumId: 13926,
            enumGroupGuid: "1",
            parentEnumValue: "6201",
            enumValue: "620102",
            enumName: "城关区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "城关区",
          },
          {
            fldEnumId: 13930,
            enumGroupGuid: "1",
            parentEnumValue: "6201",
            enumValue: "620103",
            enumName: "七里河区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 2,
            enumNameother: "七里河区",
          },
          {
            fldEnumId: 13934,
            enumGroupGuid: "1",
            parentEnumValue: "6201",
            enumValue: "620104",
            enumName: "西固区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 3,
            enumNameother: "西固区",
          },
          {
            fldEnumId: 13938,
            enumGroupGuid: "1",
            parentEnumValue: "6201",
            enumValue: "620105",
            enumName: "安宁区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 4,
            enumNameother: "安宁区",
          },
          {
            fldEnumId: 13942,
            enumGroupGuid: "1",
            parentEnumValue: "6201",
            enumValue: "620111",
            enumName: "红古区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 5,
            enumNameother: "红古区",
          },
          {
            fldEnumId: 13946,
            enumGroupGuid: "1",
            parentEnumValue: "6201",
            enumValue: "620121",
            enumName: "永登县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 6,
            enumNameother: "永登县",
          },
          {
            fldEnumId: 13950,
            enumGroupGuid: "1",
            parentEnumValue: "6201",
            enumValue: "620122",
            enumName: "皋兰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 7,
            enumNameother: "皋兰县",
          },
          {
            fldEnumId: 13954,
            enumGroupGuid: "1",
            parentEnumValue: "6201",
            enumValue: "620123",
            enumName: "榆中县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 8,
            enumNameother: "榆中县",
          },
        ],
      },
      {
        fldEnumId: 13958,
        enumGroupGuid: "1",
        parentEnumValue: "62",
        enumValue: "6202",
        enumName: "嘉峪关市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 1,
        enumNameother: "嘉峪关市",
        children: [
          {
            fldEnumId: 13960,
            enumGroupGuid: "1",
            parentEnumValue: "6202",
            enumValue: "620200",
            enumName: "嘉峪关市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 9,
            enumNameother: "嘉峪关市",
          },
        ],
      },
      {
        fldEnumId: 13964,
        enumGroupGuid: "1",
        parentEnumValue: "62",
        enumValue: "6203",
        enumName: "金昌市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 2,
        enumNameother: "金昌市",
        children: [
          {
            fldEnumId: 13967,
            enumGroupGuid: "1",
            parentEnumValue: "6203",
            enumValue: "620300",
            enumName: "金昌市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 10,
            enumNameother: "金昌市",
          },
          {
            fldEnumId: 13971,
            enumGroupGuid: "1",
            parentEnumValue: "6203",
            enumValue: "620302",
            enumName: "金川区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 11,
            enumNameother: "金川区",
          },
          {
            fldEnumId: 13975,
            enumGroupGuid: "1",
            parentEnumValue: "6203",
            enumValue: "620321",
            enumName: "永昌县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 12,
            enumNameother: "永昌县",
          },
        ],
      },
      {
        fldEnumId: 13979,
        enumGroupGuid: "1",
        parentEnumValue: "62",
        enumValue: "6204",
        enumName: "白银市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 3,
        enumNameother: "白银市",
        children: [
          {
            fldEnumId: 13980,
            enumGroupGuid: "1",
            parentEnumValue: "6204",
            enumValue: "620400",
            enumName: "白银市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 13,
            enumNameother: "白银市",
          },
          {
            fldEnumId: 13984,
            enumGroupGuid: "1",
            parentEnumValue: "6204",
            enumValue: "620402",
            enumName: "白银区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 14,
            enumNameother: "白银区",
          },
          {
            fldEnumId: 13988,
            enumGroupGuid: "1",
            parentEnumValue: "6204",
            enumValue: "620403",
            enumName: "平川区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 15,
            enumNameother: "平川区",
          },
          {
            fldEnumId: 13993,
            enumGroupGuid: "1",
            parentEnumValue: "6204",
            enumValue: "620421",
            enumName: "靖远县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 16,
            enumNameother: "靖远县",
          },
          {
            fldEnumId: 13997,
            enumGroupGuid: "1",
            parentEnumValue: "6204",
            enumValue: "620422",
            enumName: "会宁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 17,
            enumNameother: "会宁县",
          },
          {
            fldEnumId: 14002,
            enumGroupGuid: "1",
            parentEnumValue: "6204",
            enumValue: "620423",
            enumName: "景泰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 18,
            enumNameother: "景泰县",
          },
        ],
      },
      {
        fldEnumId: 14006,
        enumGroupGuid: "1",
        parentEnumValue: "62",
        enumValue: "6205",
        enumName: "天水市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 4,
        enumNameother: "天水市",
        children: [
          {
            fldEnumId: 14008,
            enumGroupGuid: "1",
            parentEnumValue: "6205",
            enumValue: "620500",
            enumName: "天水市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 19,
            enumNameother: "天水市",
          },
          {
            fldEnumId: 14012,
            enumGroupGuid: "1",
            parentEnumValue: "6205",
            enumValue: "620502",
            enumName: "秦州区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 20,
            enumNameother: "秦州区",
          },
          {
            fldEnumId: 14016,
            enumGroupGuid: "1",
            parentEnumValue: "6205",
            enumValue: "620503",
            enumName: "麦积区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 21,
            enumNameother: "麦积区",
          },
          {
            fldEnumId: 14020,
            enumGroupGuid: "1",
            parentEnumValue: "6205",
            enumValue: "620521",
            enumName: "清水县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 22,
            enumNameother: "清水县",
          },
          {
            fldEnumId: 14024,
            enumGroupGuid: "1",
            parentEnumValue: "6205",
            enumValue: "620522",
            enumName: "秦安县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 23,
            enumNameother: "秦安县",
          },
          {
            fldEnumId: 14028,
            enumGroupGuid: "1",
            parentEnumValue: "6205",
            enumValue: "620523",
            enumName: "甘谷县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 24,
            enumNameother: "甘谷县",
          },
          {
            fldEnumId: 14032,
            enumGroupGuid: "1",
            parentEnumValue: "6205",
            enumValue: "620524",
            enumName: "武山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 25,
            enumNameother: "武山县",
          },
          {
            fldEnumId: 14037,
            enumGroupGuid: "1",
            parentEnumValue: "6205",
            enumValue: "620525",
            enumName: "张家川回族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 26,
            enumNameother: "张家川回族自治县",
          },
        ],
      },
      {
        fldEnumId: 14041,
        enumGroupGuid: "1",
        parentEnumValue: "62",
        enumValue: "6206",
        enumName: "武威市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 5,
        enumNameother: "武威市",
        children: [
          {
            fldEnumId: 14043,
            enumGroupGuid: "1",
            parentEnumValue: "6206",
            enumValue: "620600",
            enumName: "武威市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 27,
            enumNameother: "武威市",
          },
          {
            fldEnumId: 14047,
            enumGroupGuid: "1",
            parentEnumValue: "6206",
            enumValue: "620602",
            enumName: "凉州区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 28,
            enumNameother: "凉州区",
          },
          {
            fldEnumId: 14051,
            enumGroupGuid: "1",
            parentEnumValue: "6206",
            enumValue: "620621",
            enumName: "民勤县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 29,
            enumNameother: "民勤县",
          },
          {
            fldEnumId: 14056,
            enumGroupGuid: "1",
            parentEnumValue: "6206",
            enumValue: "620622",
            enumName: "古浪县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 30,
            enumNameother: "古浪县",
          },
          {
            fldEnumId: 14060,
            enumGroupGuid: "1",
            parentEnumValue: "6206",
            enumValue: "620623",
            enumName: "天祝藏族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 31,
            enumNameother: "天祝藏族自治县",
          },
        ],
      },
      {
        fldEnumId: 14064,
        enumGroupGuid: "1",
        parentEnumValue: "62",
        enumValue: "6207",
        enumName: "张掖市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 6,
        enumNameother: "张掖市",
        children: [
          {
            fldEnumId: 14066,
            enumGroupGuid: "1",
            parentEnumValue: "6207",
            enumValue: "620700",
            enumName: "张掖市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 32,
            enumNameother: "张掖市",
          },
          {
            fldEnumId: 14070,
            enumGroupGuid: "1",
            parentEnumValue: "6207",
            enumValue: "620702",
            enumName: "甘州区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 33,
            enumNameother: "甘州区",
          },
          {
            fldEnumId: 14075,
            enumGroupGuid: "1",
            parentEnumValue: "6207",
            enumValue: "620721",
            enumName: "肃南裕固族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 34,
            enumNameother: "肃南裕固族自治县",
          },
          {
            fldEnumId: 14079,
            enumGroupGuid: "1",
            parentEnumValue: "6207",
            enumValue: "620722",
            enumName: "民乐县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 35,
            enumNameother: "民乐县",
          },
          {
            fldEnumId: 14083,
            enumGroupGuid: "1",
            parentEnumValue: "6207",
            enumValue: "620723",
            enumName: "临泽县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 36,
            enumNameother: "临泽县",
          },
          {
            fldEnumId: 14087,
            enumGroupGuid: "1",
            parentEnumValue: "6207",
            enumValue: "620724",
            enumName: "高台县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 37,
            enumNameother: "高台县",
          },
          {
            fldEnumId: 14092,
            enumGroupGuid: "1",
            parentEnumValue: "6207",
            enumValue: "620725",
            enumName: "山丹县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 38,
            enumNameother: "山丹县",
          },
        ],
      },
      {
        fldEnumId: 14096,
        enumGroupGuid: "1",
        parentEnumValue: "62",
        enumValue: "6208",
        enumName: "平凉市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 7,
        enumNameother: "平凉市",
        children: [
          {
            fldEnumId: 14098,
            enumGroupGuid: "1",
            parentEnumValue: "6208",
            enumValue: "620800",
            enumName: "平凉市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 39,
            enumNameother: "平凉市",
          },
          {
            fldEnumId: 14102,
            enumGroupGuid: "1",
            parentEnumValue: "6208",
            enumValue: "620802",
            enumName: "崆峒区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 40,
            enumNameother: "崆峒区",
          },
          {
            fldEnumId: 14106,
            enumGroupGuid: "1",
            parentEnumValue: "6208",
            enumValue: "620821",
            enumName: "泾川县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 41,
            enumNameother: "泾川县",
          },
          {
            fldEnumId: 14110,
            enumGroupGuid: "1",
            parentEnumValue: "6208",
            enumValue: "620822",
            enumName: "灵台县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 42,
            enumNameother: "灵台县",
          },
          {
            fldEnumId: 14114,
            enumGroupGuid: "1",
            parentEnumValue: "6208",
            enumValue: "620823",
            enumName: "崇信县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 43,
            enumNameother: "崇信县",
          },
          {
            fldEnumId: 14118,
            enumGroupGuid: "1",
            parentEnumValue: "6208",
            enumValue: "620824",
            enumName: "华亭县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 44,
            enumNameother: "华亭县",
          },
          {
            fldEnumId: 14122,
            enumGroupGuid: "1",
            parentEnumValue: "6208",
            enumValue: "620825",
            enumName: "庄浪县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 45,
            enumNameother: "庄浪县",
          },
          {
            fldEnumId: 14126,
            enumGroupGuid: "1",
            parentEnumValue: "6208",
            enumValue: "620826",
            enumName: "静宁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 46,
            enumNameother: "静宁县",
          },
        ],
      },
      {
        fldEnumId: 14129,
        enumGroupGuid: "1",
        parentEnumValue: "62",
        enumValue: "6209",
        enumName: "酒泉市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 8,
        enumNameother: "酒泉市",
        children: [
          {
            fldEnumId: 14131,
            enumGroupGuid: "1",
            parentEnumValue: "6209",
            enumValue: "620900",
            enumName: "酒泉市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 47,
            enumNameother: "酒泉市",
          },
          {
            fldEnumId: 14135,
            enumGroupGuid: "1",
            parentEnumValue: "6209",
            enumValue: "620902",
            enumName: "肃州区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 48,
            enumNameother: "肃州区",
          },
          {
            fldEnumId: 14139,
            enumGroupGuid: "1",
            parentEnumValue: "6209",
            enumValue: "620921",
            enumName: "金塔县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 49,
            enumNameother: "金塔县",
          },
          {
            fldEnumId: 14143,
            enumGroupGuid: "1",
            parentEnumValue: "6209",
            enumValue: "620922",
            enumName: "瓜州县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 50,
            enumNameother: "瓜州县",
          },
          {
            fldEnumId: 14147,
            enumGroupGuid: "1",
            parentEnumValue: "6209",
            enumValue: "620923",
            enumName: "肃北蒙古族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 51,
            enumNameother: "肃北蒙古族自治县",
          },
          {
            fldEnumId: 14151,
            enumGroupGuid: "1",
            parentEnumValue: "6209",
            enumValue: "620924",
            enumName: "阿克塞哈萨克族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 52,
            enumNameother: "阿克塞哈萨克族自治县",
          },
          {
            fldEnumId: 14154,
            enumGroupGuid: "1",
            parentEnumValue: "6209",
            enumValue: "620981",
            enumName: "玉门市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 53,
            enumNameother: "玉门市",
          },
          {
            fldEnumId: 14158,
            enumGroupGuid: "1",
            parentEnumValue: "6209",
            enumValue: "620982",
            enumName: "敦煌市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 54,
            enumNameother: "敦煌市",
          },
        ],
      },
      {
        fldEnumId: 14162,
        enumGroupGuid: "1",
        parentEnumValue: "62",
        enumValue: "6210",
        enumName: "庆阳市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 9,
        enumNameother: "庆阳市",
        children: [
          {
            fldEnumId: 14164,
            enumGroupGuid: "1",
            parentEnumValue: "6210",
            enumValue: "621000",
            enumName: "庆阳市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 55,
            enumNameother: "庆阳市",
          },
          {
            fldEnumId: 14168,
            enumGroupGuid: "1",
            parentEnumValue: "6210",
            enumValue: "621002",
            enumName: "西峰区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 56,
            enumNameother: "西峰区",
          },
          {
            fldEnumId: 14171,
            enumGroupGuid: "1",
            parentEnumValue: "6210",
            enumValue: "621021",
            enumName: "庆城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 57,
            enumNameother: "庆城县",
          },
          {
            fldEnumId: 14175,
            enumGroupGuid: "1",
            parentEnumValue: "6210",
            enumValue: "621022",
            enumName: "环县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 58,
            enumNameother: "环县",
          },
          {
            fldEnumId: 14179,
            enumGroupGuid: "1",
            parentEnumValue: "6210",
            enumValue: "621023",
            enumName: "华池县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 59,
            enumNameother: "华池县",
          },
          {
            fldEnumId: 14183,
            enumGroupGuid: "1",
            parentEnumValue: "6210",
            enumValue: "621024",
            enumName: "合水县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 60,
            enumNameother: "合水县",
          },
          {
            fldEnumId: 14188,
            enumGroupGuid: "1",
            parentEnumValue: "6210",
            enumValue: "621025",
            enumName: "正宁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 61,
            enumNameother: "正宁县",
          },
          {
            fldEnumId: 14192,
            enumGroupGuid: "1",
            parentEnumValue: "6210",
            enumValue: "621026",
            enumName: "宁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 62,
            enumNameother: "宁县",
          },
          {
            fldEnumId: 14196,
            enumGroupGuid: "1",
            parentEnumValue: "6210",
            enumValue: "621027",
            enumName: "镇原县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 63,
            enumNameother: "镇原县",
          },
        ],
      },
      {
        fldEnumId: 14199,
        enumGroupGuid: "1",
        parentEnumValue: "62",
        enumValue: "6211",
        enumName: "定西市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 10,
        enumNameother: "定西市",
        children: [
          {
            fldEnumId: 14201,
            enumGroupGuid: "1",
            parentEnumValue: "6211",
            enumValue: "621100",
            enumName: "定西市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 64,
            enumNameother: "定西市",
          },
          {
            fldEnumId: 14205,
            enumGroupGuid: "1",
            parentEnumValue: "6211",
            enumValue: "621102",
            enumName: "安定区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 65,
            enumNameother: "安定区",
          },
          {
            fldEnumId: 14210,
            enumGroupGuid: "1",
            parentEnumValue: "6211",
            enumValue: "621121",
            enumName: "通渭县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 66,
            enumNameother: "通渭县",
          },
          {
            fldEnumId: 14214,
            enumGroupGuid: "1",
            parentEnumValue: "6211",
            enumValue: "621122",
            enumName: "陇西县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 67,
            enumNameother: "陇西县",
          },
          {
            fldEnumId: 14218,
            enumGroupGuid: "1",
            parentEnumValue: "6211",
            enumValue: "621123",
            enumName: "渭源县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 68,
            enumNameother: "渭源县",
          },
          {
            fldEnumId: 14222,
            enumGroupGuid: "1",
            parentEnumValue: "6211",
            enumValue: "621124",
            enumName: "临洮县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 69,
            enumNameother: "临洮县",
          },
          {
            fldEnumId: 14227,
            enumGroupGuid: "1",
            parentEnumValue: "6211",
            enumValue: "621125",
            enumName: "漳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 70,
            enumNameother: "漳县",
          },
          {
            fldEnumId: 14231,
            enumGroupGuid: "1",
            parentEnumValue: "6211",
            enumValue: "621126",
            enumName: "岷县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 71,
            enumNameother: "岷县",
          },
        ],
      },
      {
        fldEnumId: 14234,
        enumGroupGuid: "1",
        parentEnumValue: "62",
        enumValue: "6212",
        enumName: "陇南市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 11,
        enumNameother: "陇南市",
        children: [
          {
            fldEnumId: 14236,
            enumGroupGuid: "1",
            parentEnumValue: "6212",
            enumValue: "621200",
            enumName: "陇南市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 72,
            enumNameother: "陇南市",
          },
          {
            fldEnumId: 14240,
            enumGroupGuid: "1",
            parentEnumValue: "6212",
            enumValue: "621202",
            enumName: "武都区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 73,
            enumNameother: "武都区",
          },
          {
            fldEnumId: 14244,
            enumGroupGuid: "1",
            parentEnumValue: "6212",
            enumValue: "621221",
            enumName: "成县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 74,
            enumNameother: "成县",
          },
          {
            fldEnumId: 14248,
            enumGroupGuid: "1",
            parentEnumValue: "6212",
            enumValue: "621222",
            enumName: "文县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 75,
            enumNameother: "文县",
          },
          {
            fldEnumId: 14253,
            enumGroupGuid: "1",
            parentEnumValue: "6212",
            enumValue: "621223",
            enumName: "宕昌县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 76,
            enumNameother: "宕昌县",
          },
          {
            fldEnumId: 14258,
            enumGroupGuid: "1",
            parentEnumValue: "6212",
            enumValue: "621224",
            enumName: "康县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 77,
            enumNameother: "康县",
          },
          {
            fldEnumId: 14262,
            enumGroupGuid: "1",
            parentEnumValue: "6212",
            enumValue: "621225",
            enumName: "西和县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 78,
            enumNameother: "西和县",
          },
          {
            fldEnumId: 14266,
            enumGroupGuid: "1",
            parentEnumValue: "6212",
            enumValue: "621226",
            enumName: "礼县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 79,
            enumNameother: "礼县",
          },
          {
            fldEnumId: 14270,
            enumGroupGuid: "1",
            parentEnumValue: "6212",
            enumValue: "621227",
            enumName: "徽县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 80,
            enumNameother: "徽县",
          },
          {
            fldEnumId: 14273,
            enumGroupGuid: "1",
            parentEnumValue: "6212",
            enumValue: "621228",
            enumName: "两当县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 81,
            enumNameother: "两当县",
          },
        ],
      },
      {
        fldEnumId: 14277,
        enumGroupGuid: "1",
        parentEnumValue: "62",
        enumValue: "6229",
        enumName: "临夏回族自治州",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 12,
        enumNameother: "临夏回族自治州",
        children: [
          {
            fldEnumId: 16240,
            enumGroupGuid: "1",
            parentEnumValue: "6229",
            enumValue: "622900",
            enumName: "临夏回族自治州本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 14280,
            enumGroupGuid: "1",
            parentEnumValue: "6229",
            enumValue: "622901",
            enumName: "临夏市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 82,
            enumNameother: "临夏市",
          },
          {
            fldEnumId: 14284,
            enumGroupGuid: "1",
            parentEnumValue: "6229",
            enumValue: "622921",
            enumName: "临夏县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 83,
            enumNameother: "临夏县",
          },
          {
            fldEnumId: 14288,
            enumGroupGuid: "1",
            parentEnumValue: "6229",
            enumValue: "622922",
            enumName: "康乐县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 84,
            enumNameother: "康乐县",
          },
          {
            fldEnumId: 14293,
            enumGroupGuid: "1",
            parentEnumValue: "6229",
            enumValue: "622923",
            enumName: "永靖县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 85,
            enumNameother: "永靖县",
          },
          {
            fldEnumId: 14297,
            enumGroupGuid: "1",
            parentEnumValue: "6229",
            enumValue: "622924",
            enumName: "广河县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 86,
            enumNameother: "广河县",
          },
          {
            fldEnumId: 14301,
            enumGroupGuid: "1",
            parentEnumValue: "6229",
            enumValue: "622925",
            enumName: "和政县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 87,
            enumNameother: "和政县",
          },
          {
            fldEnumId: 14305,
            enumGroupGuid: "1",
            parentEnumValue: "6229",
            enumValue: "622926",
            enumName: "东乡族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 88,
            enumNameother: "东乡族自治县",
          },
          {
            fldEnumId: 14309,
            enumGroupGuid: "1",
            parentEnumValue: "6229",
            enumValue: "622927",
            enumName: "积石山保安族东乡族撒拉族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 89,
            enumNameother: "积石山保安族东乡族撒拉族自治县",
          },
        ],
      },
      {
        fldEnumId: 14313,
        enumGroupGuid: "1",
        parentEnumValue: "62",
        enumValue: "6230",
        enumName: "甘南藏族自治州",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 13,
        enumNameother: "甘南藏族自治州",
        children: [
          {
            fldEnumId: 16242,
            enumGroupGuid: "1",
            parentEnumValue: "6230",
            enumValue: "623000",
            enumName: "甘南藏族自治州本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 14315,
            enumGroupGuid: "1",
            parentEnumValue: "6230",
            enumValue: "623001",
            enumName: "合作市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 90,
            enumNameother: "合作市",
          },
          {
            fldEnumId: 14319,
            enumGroupGuid: "1",
            parentEnumValue: "6230",
            enumValue: "623021",
            enumName: "临潭县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 91,
            enumNameother: "临潭县",
          },
          {
            fldEnumId: 14323,
            enumGroupGuid: "1",
            parentEnumValue: "6230",
            enumValue: "623022",
            enumName: "卓尼县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 92,
            enumNameother: "卓尼县",
          },
          {
            fldEnumId: 14327,
            enumGroupGuid: "1",
            parentEnumValue: "6230",
            enumValue: "623023",
            enumName: "舟曲县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 93,
            enumNameother: "舟曲县",
          },
          {
            fldEnumId: 14331,
            enumGroupGuid: "1",
            parentEnumValue: "6230",
            enumValue: "623024",
            enumName: "迭部县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 94,
            enumNameother: "迭部县",
          },
          {
            fldEnumId: 14335,
            enumGroupGuid: "1",
            parentEnumValue: "6230",
            enumValue: "623025",
            enumName: "玛曲县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 95,
            enumNameother: "玛曲县",
          },
          {
            fldEnumId: 14339,
            enumGroupGuid: "1",
            parentEnumValue: "6230",
            enumValue: "623026",
            enumName: "碌曲县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 96,
            enumNameother: "碌曲县",
          },
          {
            fldEnumId: 14343,
            enumGroupGuid: "1",
            parentEnumValue: "6230",
            enumValue: "623027",
            enumName: "夏河县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 97,
            enumNameother: "夏河县",
          },
        ],
      },
    ],
  },
  {
    enumValue: "63",
    enumName: "青海省",
    children: [
      {
        fldEnumId: 14350,
        enumGroupGuid: "1",
        parentEnumValue: "63",
        enumValue: "6301",
        enumName: "西宁市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        enumNameother: "西宁市",
        children: [
          {
            fldEnumId: 14352,
            enumGroupGuid: "1",
            parentEnumValue: "6301",
            enumValue: "630100",
            enumName: "西宁市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
            enumNameother: "西宁市",
          },
          {
            fldEnumId: 14356,
            enumGroupGuid: "1",
            parentEnumValue: "6301",
            enumValue: "630102",
            enumName: "城东区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "城东区",
          },
          {
            fldEnumId: 14359,
            enumGroupGuid: "1",
            parentEnumValue: "6301",
            enumValue: "630103",
            enumName: "城中区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 2,
            enumNameother: "城中区",
          },
          {
            fldEnumId: 14363,
            enumGroupGuid: "1",
            parentEnumValue: "6301",
            enumValue: "630104",
            enumName: "城西区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 3,
            enumNameother: "城西区",
          },
          {
            fldEnumId: 14367,
            enumGroupGuid: "1",
            parentEnumValue: "6301",
            enumValue: "630105",
            enumName: "城北区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 4,
            enumNameother: "城北区",
          },
          {
            fldEnumId: 14371,
            enumGroupGuid: "1",
            parentEnumValue: "6301",
            enumValue: "630121",
            enumName: "大通回族土族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 5,
            enumNameother: "大通回族土族自治县",
          },
          {
            fldEnumId: 14375,
            enumGroupGuid: "1",
            parentEnumValue: "6301",
            enumValue: "630122",
            enumName: "湟中县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 6,
            enumNameother: "湟中县",
          },
          {
            fldEnumId: 14379,
            enumGroupGuid: "1",
            parentEnumValue: "6301",
            enumValue: "630123",
            enumName: "湟源县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 7,
            enumNameother: "湟源县",
          },
        ],
      },
      {
        fldEnumId: 130358,
        enumGroupGuid: "1",
        parentEnumValue: "63",
        enumValue: "6302",
        enumName: "海东市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        children: [
          {
            fldEnumId: 130359,
            enumGroupGuid: "1",
            parentEnumValue: "6302",
            enumValue: "630200",
            enumName: "海东市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130271,
            enumGroupGuid: "1",
            parentEnumValue: "6302",
            enumValue: "632121",
            enumName: "平安区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130272,
            enumGroupGuid: "1",
            parentEnumValue: "6302",
            enumValue: "632122",
            enumName: "民和回族土族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130270,
            enumGroupGuid: "1",
            parentEnumValue: "6302",
            enumValue: "632123",
            enumName: "乐都区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130273,
            enumGroupGuid: "1",
            parentEnumValue: "6302",
            enumValue: "632126",
            enumName: "互助土族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130274,
            enumGroupGuid: "1",
            parentEnumValue: "6302",
            enumValue: "632127",
            enumName: "化隆回族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130275,
            enumGroupGuid: "1",
            parentEnumValue: "6302",
            enumValue: "632128",
            enumName: "循化撒拉族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
        ],
      },
      {
        fldEnumId: 14383,
        enumGroupGuid: "1",
        parentEnumValue: "63",
        enumValue: "6321",
        enumName: "海东地区",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 1,
        enumNameother: "海东地区",
        children: [
          {
            fldEnumId: 16256,
            enumGroupGuid: "1",
            parentEnumValue: "6321",
            enumValue: "632100",
            enumName: "海东地区本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
        ],
      },
      {
        fldEnumId: 14409,
        enumGroupGuid: "1",
        parentEnumValue: "63",
        enumValue: "6322",
        enumName: "海北藏族自治州",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 2,
        enumNameother: "海北藏族自治州",
        children: [
          {
            fldEnumId: 16258,
            enumGroupGuid: "1",
            parentEnumValue: "6322",
            enumValue: "632200",
            enumName: "海北藏族自治州本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 14411,
            enumGroupGuid: "1",
            parentEnumValue: "6322",
            enumValue: "632221",
            enumName: "门源回族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 14,
            enumNameother: "门源回族自治县",
          },
          {
            fldEnumId: 14415,
            enumGroupGuid: "1",
            parentEnumValue: "6322",
            enumValue: "632222",
            enumName: "祁连县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 15,
            enumNameother: "祁连县",
          },
          {
            fldEnumId: 14419,
            enumGroupGuid: "1",
            parentEnumValue: "6322",
            enumValue: "632223",
            enumName: "海晏县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 16,
            enumNameother: "海晏县",
          },
          {
            fldEnumId: 14423,
            enumGroupGuid: "1",
            parentEnumValue: "6322",
            enumValue: "632224",
            enumName: "刚察县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 17,
            enumNameother: "刚察县",
          },
        ],
      },
      {
        fldEnumId: 14427,
        enumGroupGuid: "1",
        parentEnumValue: "63",
        enumValue: "6323",
        enumName: "黄南藏族自治州",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 3,
        enumNameother: "黄南藏族自治州",
        children: [
          {
            fldEnumId: 16260,
            enumGroupGuid: "1",
            parentEnumValue: "6323",
            enumValue: "632300",
            enumName: "黄南藏族自治州本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 14429,
            enumGroupGuid: "1",
            parentEnumValue: "6323",
            enumValue: "632321",
            enumName: "同仁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 18,
            enumNameother: "同仁县",
          },
          {
            fldEnumId: 14433,
            enumGroupGuid: "1",
            parentEnumValue: "6323",
            enumValue: "632322",
            enumName: "尖扎县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 19,
            enumNameother: "尖扎县",
          },
          {
            fldEnumId: 14437,
            enumGroupGuid: "1",
            parentEnumValue: "6323",
            enumValue: "632323",
            enumName: "泽库县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 20,
            enumNameother: "泽库县",
          },
          {
            fldEnumId: 14441,
            enumGroupGuid: "1",
            parentEnumValue: "6323",
            enumValue: "632324",
            enumName: "河南蒙古族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 21,
            enumNameother: "河南蒙古族自治县",
          },
        ],
      },
      {
        fldEnumId: 14445,
        enumGroupGuid: "1",
        parentEnumValue: "63",
        enumValue: "6325",
        enumName: "海南藏族自治州",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 4,
        enumNameother: "海南藏族自治州",
        children: [
          {
            fldEnumId: 16262,
            enumGroupGuid: "1",
            parentEnumValue: "6325",
            enumValue: "632500",
            enumName: "海南藏族自治州本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 14447,
            enumGroupGuid: "1",
            parentEnumValue: "6325",
            enumValue: "632521",
            enumName: "共和县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 22,
            enumNameother: "共和县",
          },
          {
            fldEnumId: 14451,
            enumGroupGuid: "1",
            parentEnumValue: "6325",
            enumValue: "632522",
            enumName: "同德县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 23,
            enumNameother: "同德县",
          },
          {
            fldEnumId: 14455,
            enumGroupGuid: "1",
            parentEnumValue: "6325",
            enumValue: "632523",
            enumName: "贵德县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 24,
            enumNameother: "贵德县",
          },
          {
            fldEnumId: 14459,
            enumGroupGuid: "1",
            parentEnumValue: "6325",
            enumValue: "632524",
            enumName: "兴海县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 25,
            enumNameother: "兴海县",
          },
          {
            fldEnumId: 14463,
            enumGroupGuid: "1",
            parentEnumValue: "6325",
            enumValue: "632525",
            enumName: "贵南县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 26,
            enumNameother: "贵南县",
          },
        ],
      },
      {
        fldEnumId: 14467,
        enumGroupGuid: "1",
        parentEnumValue: "63",
        enumValue: "6326",
        enumName: "果洛藏族自治州",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 5,
        enumNameother: "果洛藏族自治州",
        children: [
          {
            fldEnumId: 16264,
            enumGroupGuid: "1",
            parentEnumValue: "6326",
            enumValue: "632600",
            enumName: "果洛藏族自治州本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 14469,
            enumGroupGuid: "1",
            parentEnumValue: "6326",
            enumValue: "632621",
            enumName: "玛沁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 27,
            enumNameother: "玛沁县",
          },
          {
            fldEnumId: 14473,
            enumGroupGuid: "1",
            parentEnumValue: "6326",
            enumValue: "632622",
            enumName: "班玛县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 28,
            enumNameother: "班玛县",
          },
          {
            fldEnumId: 14477,
            enumGroupGuid: "1",
            parentEnumValue: "6326",
            enumValue: "632623",
            enumName: "甘德县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 29,
            enumNameother: "甘德县",
          },
          {
            fldEnumId: 14481,
            enumGroupGuid: "1",
            parentEnumValue: "6326",
            enumValue: "632624",
            enumName: "达日县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 30,
            enumNameother: "达日县",
          },
          {
            fldEnumId: 14485,
            enumGroupGuid: "1",
            parentEnumValue: "6326",
            enumValue: "632625",
            enumName: "久治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 31,
            enumNameother: "久治县",
          },
          {
            fldEnumId: 14489,
            enumGroupGuid: "1",
            parentEnumValue: "6326",
            enumValue: "632626",
            enumName: "玛多县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 32,
            enumNameother: "玛多县",
          },
        ],
      },
      {
        fldEnumId: 14493,
        enumGroupGuid: "1",
        parentEnumValue: "63",
        enumValue: "6327",
        enumName: "玉树藏族自治州",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 6,
        enumNameother: "玉树藏族自治州",
        children: [
          {
            fldEnumId: 16266,
            enumGroupGuid: "1",
            parentEnumValue: "6327",
            enumValue: "632700",
            enumName: "玉树藏族自治州本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 14495,
            enumGroupGuid: "1",
            parentEnumValue: "6327",
            enumValue: "632721",
            enumName: "玉树县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 33,
            enumNameother: "玉树县",
          },
          {
            fldEnumId: 14499,
            enumGroupGuid: "1",
            parentEnumValue: "6327",
            enumValue: "632722",
            enumName: "杂多县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 34,
            enumNameother: "杂多县",
          },
          {
            fldEnumId: 14503,
            enumGroupGuid: "1",
            parentEnumValue: "6327",
            enumValue: "632723",
            enumName: "称多县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 35,
            enumNameother: "称多县",
          },
          {
            fldEnumId: 14507,
            enumGroupGuid: "1",
            parentEnumValue: "6327",
            enumValue: "632724",
            enumName: "治多县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 36,
            enumNameother: "治多县",
          },
          {
            fldEnumId: 14511,
            enumGroupGuid: "1",
            parentEnumValue: "6327",
            enumValue: "632725",
            enumName: "囊谦县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 37,
            enumNameother: "囊谦县",
          },
          {
            fldEnumId: 14515,
            enumGroupGuid: "1",
            parentEnumValue: "6327",
            enumValue: "632726",
            enumName: "曲麻莱县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 38,
            enumNameother: "曲麻莱县",
          },
        ],
      },
      {
        fldEnumId: 14519,
        enumGroupGuid: "1",
        parentEnumValue: "63",
        enumValue: "6328",
        enumName: "海西蒙古族藏族自治州",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 7,
        enumNameother: "海西蒙古族藏族自治州",
        children: [
          {
            fldEnumId: 16268,
            enumGroupGuid: "1",
            parentEnumValue: "6328",
            enumValue: "632800",
            enumName: "海西蒙古族藏族自治州本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130280,
            enumGroupGuid: "1",
            parentEnumValue: "6328",
            enumValue: "632803",
            enumName: "茫崖市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130191,
            enumGroupGuid: "1",
            parentEnumValue: "6328",
            enumValue: "632857",
            enumName: "大柴旦行政委员会",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 14521,
            enumGroupGuid: "1",
            parentEnumValue: "6328",
            enumValue: "632801",
            enumName: "格尔木市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 39,
            enumNameother: "格尔木市",
          },
          {
            fldEnumId: 14524,
            enumGroupGuid: "1",
            parentEnumValue: "6328",
            enumValue: "632802",
            enumName: "德令哈市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 40,
            enumNameother: "德令哈市",
          },
          {
            fldEnumId: 14528,
            enumGroupGuid: "1",
            parentEnumValue: "6328",
            enumValue: "632821",
            enumName: "乌兰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 41,
            enumNameother: "乌兰县",
          },
          {
            fldEnumId: 14532,
            enumGroupGuid: "1",
            parentEnumValue: "6328",
            enumValue: "632822",
            enumName: "都兰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 42,
            enumNameother: "都兰县",
          },
          {
            fldEnumId: 14536,
            enumGroupGuid: "1",
            parentEnumValue: "6328",
            enumValue: "632823",
            enumName: "天峻县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 43,
            enumNameother: "天峻县",
          },
        ],
      },
    ],
  },
  {
    enumValue: "64",
    enumName: "宁夏回族",
    children: [
      {
        fldEnumId: 14542,
        enumGroupGuid: "1",
        parentEnumValue: "64",
        enumValue: "6401",
        enumName: "银川市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        enumNameother: "银川市",
        children: [
          {
            fldEnumId: 14544,
            enumGroupGuid: "1",
            parentEnumValue: "6401",
            enumValue: "640100",
            enumName: "银川市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
            enumNameother: "银川市",
          },
          {
            fldEnumId: 14548,
            enumGroupGuid: "1",
            parentEnumValue: "6401",
            enumValue: "640104",
            enumName: "兴庆区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "兴庆区",
          },
          {
            fldEnumId: 14553,
            enumGroupGuid: "1",
            parentEnumValue: "6401",
            enumValue: "640105",
            enumName: "西夏区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 2,
            enumNameother: "西夏区",
          },
          {
            fldEnumId: 14557,
            enumGroupGuid: "1",
            parentEnumValue: "6401",
            enumValue: "640106",
            enumName: "金凤区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 3,
            enumNameother: "金凤区",
          },
          {
            fldEnumId: 14561,
            enumGroupGuid: "1",
            parentEnumValue: "6401",
            enumValue: "640121",
            enumName: "永宁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 4,
            enumNameother: "永宁县",
          },
          {
            fldEnumId: 14565,
            enumGroupGuid: "1",
            parentEnumValue: "6401",
            enumValue: "640122",
            enumName: "贺兰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 5,
            enumNameother: "贺兰县",
          },
          {
            fldEnumId: 14568,
            enumGroupGuid: "1",
            parentEnumValue: "6401",
            enumValue: "640181",
            enumName: "灵武市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 6,
            enumNameother: "灵武市",
          },
        ],
      },
      {
        fldEnumId: 14572,
        enumGroupGuid: "1",
        parentEnumValue: "64",
        enumValue: "6402",
        enumName: "石嘴山市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 1,
        enumNameother: "石嘴山市",
        children: [
          {
            fldEnumId: 14574,
            enumGroupGuid: "1",
            parentEnumValue: "6402",
            enumValue: "640200",
            enumName: "石嘴山市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 7,
            enumNameother: "石嘴山市",
          },
          {
            fldEnumId: 14578,
            enumGroupGuid: "1",
            parentEnumValue: "6402",
            enumValue: "640202",
            enumName: "大武口区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 8,
            enumNameother: "大武口区",
          },
          {
            fldEnumId: 14582,
            enumGroupGuid: "1",
            parentEnumValue: "6402",
            enumValue: "640205",
            enumName: "惠农区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 9,
            enumNameother: "惠农区",
          },
          {
            fldEnumId: 14586,
            enumGroupGuid: "1",
            parentEnumValue: "6402",
            enumValue: "640221",
            enumName: "平罗县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 10,
            enumNameother: "平罗县",
          },
        ],
      },
      {
        fldEnumId: 14590,
        enumGroupGuid: "1",
        parentEnumValue: "64",
        enumValue: "6403",
        enumName: "吴忠市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 2,
        enumNameother: "吴忠市",
        children: [
          {
            fldEnumId: 130276,
            enumGroupGuid: "1",
            parentEnumValue: "6403",
            enumValue: "640303",
            enumName: "红寺堡区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 14592,
            enumGroupGuid: "1",
            parentEnumValue: "6403",
            enumValue: "640300",
            enumName: "吴忠市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 11,
            enumNameother: "吴忠市",
          },
          {
            fldEnumId: 14595,
            enumGroupGuid: "1",
            parentEnumValue: "6403",
            enumValue: "640302",
            enumName: "利通区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 12,
            enumNameother: "利通区",
          },
          {
            fldEnumId: 14599,
            enumGroupGuid: "1",
            parentEnumValue: "6403",
            enumValue: "640323",
            enumName: "盐池县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 13,
            enumNameother: "盐池县",
          },
          {
            fldEnumId: 14603,
            enumGroupGuid: "1",
            parentEnumValue: "6403",
            enumValue: "640324",
            enumName: "同心县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 14,
            enumNameother: "同心县",
          },
          {
            fldEnumId: 14607,
            enumGroupGuid: "1",
            parentEnumValue: "6403",
            enumValue: "640381",
            enumName: "青铜峡市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 15,
            enumNameother: "青铜峡市",
          },
        ],
      },
      {
        fldEnumId: 14611,
        enumGroupGuid: "1",
        parentEnumValue: "64",
        enumValue: "6404",
        enumName: "固原市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 3,
        enumNameother: "固原市",
        children: [
          {
            fldEnumId: 14614,
            enumGroupGuid: "1",
            parentEnumValue: "6404",
            enumValue: "640400",
            enumName: "固原市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 16,
            enumNameother: "固原市",
          },
          {
            fldEnumId: 14618,
            enumGroupGuid: "1",
            parentEnumValue: "6404",
            enumValue: "640402",
            enumName: "原州区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 17,
            enumNameother: "原州区",
          },
          {
            fldEnumId: 14622,
            enumGroupGuid: "1",
            parentEnumValue: "6404",
            enumValue: "640422",
            enumName: "西吉县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 18,
            enumNameother: "西吉县",
          },
          {
            fldEnumId: 14627,
            enumGroupGuid: "1",
            parentEnumValue: "6404",
            enumValue: "640423",
            enumName: "隆德县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 19,
            enumNameother: "隆德县",
          },
          {
            fldEnumId: 14631,
            enumGroupGuid: "1",
            parentEnumValue: "6404",
            enumValue: "640424",
            enumName: "泾源县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 20,
            enumNameother: "泾源县",
          },
          {
            fldEnumId: 14635,
            enumGroupGuid: "1",
            parentEnumValue: "6404",
            enumValue: "640425",
            enumName: "彭阳县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 21,
            enumNameother: "彭阳县",
          },
        ],
      },
      {
        fldEnumId: 14639,
        enumGroupGuid: "1",
        parentEnumValue: "64",
        enumValue: "6405",
        enumName: "中卫市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 4,
        enumNameother: "中卫市",
        children: [
          {
            fldEnumId: 14641,
            enumGroupGuid: "1",
            parentEnumValue: "6405",
            enumValue: "640500",
            enumName: "中卫市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 22,
            enumNameother: "中卫市",
          },
          {
            fldEnumId: 14645,
            enumGroupGuid: "1",
            parentEnumValue: "6405",
            enumValue: "640502",
            enumName: "沙坡头区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 23,
            enumNameother: "沙坡头区",
          },
          {
            fldEnumId: 14649,
            enumGroupGuid: "1",
            parentEnumValue: "6405",
            enumValue: "640521",
            enumName: "中宁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 24,
            enumNameother: "中宁县",
          },
          {
            fldEnumId: 14653,
            enumGroupGuid: "1",
            parentEnumValue: "6405",
            enumValue: "640522",
            enumName: "海原县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 25,
            enumNameother: "海原县",
          },
        ],
      },
    ],
  },
  {
    enumValue: "65",
    enumName: "新疆维吾尔",
    children: [
      {
        fldEnumId: 14659,
        enumGroupGuid: "1",
        parentEnumValue: "65",
        enumValue: "6501",
        enumName: "乌鲁木齐市",
        enumLevel: 0,
        enumDesc: "zdcs",
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 0,
        enumNameother: "乌鲁木齐市",
        children: [
          {
            fldEnumId: 14661,
            enumGroupGuid: "1",
            parentEnumValue: "6501",
            enumValue: "650100",
            enumName: "乌鲁木齐市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
            enumNameother: "乌鲁木齐市",
          },
          {
            fldEnumId: 14666,
            enumGroupGuid: "1",
            parentEnumValue: "6501",
            enumValue: "650102",
            enumName: "天山区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 1,
            enumNameother: "天山区",
          },
          {
            fldEnumId: 14670,
            enumGroupGuid: "1",
            parentEnumValue: "6501",
            enumValue: "650103",
            enumName: "沙依巴克区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 2,
            enumNameother: "沙依巴克区",
          },
          {
            fldEnumId: 14674,
            enumGroupGuid: "1",
            parentEnumValue: "6501",
            enumValue: "650104",
            enumName: "新市区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 3,
            enumNameother: "新市区",
          },
          {
            fldEnumId: 14679,
            enumGroupGuid: "1",
            parentEnumValue: "6501",
            enumValue: "650105",
            enumName: "水磨沟区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 4,
            enumNameother: "水磨沟区",
          },
          {
            fldEnumId: 14682,
            enumGroupGuid: "1",
            parentEnumValue: "6501",
            enumValue: "650106",
            enumName: "头屯河区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 5,
            enumNameother: "头屯河区",
          },
          {
            fldEnumId: 14686,
            enumGroupGuid: "1",
            parentEnumValue: "6501",
            enumValue: "650107",
            enumName: "达坂城区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 6,
            enumNameother: "达坂城区",
          },
          {
            fldEnumId: 14690,
            enumGroupGuid: "1",
            parentEnumValue: "6501",
            enumValue: "650109",
            enumName: "米东区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 7,
            enumNameother: "米东区",
          },
          {
            fldEnumId: 14695,
            enumGroupGuid: "1",
            parentEnumValue: "6501",
            enumValue: "650121",
            enumName: "乌鲁木齐县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 8,
            enumNameother: "乌鲁木齐县",
          },
        ],
      },
      {
        fldEnumId: 14699,
        enumGroupGuid: "1",
        parentEnumValue: "65",
        enumValue: "6502",
        enumName: "克拉玛依市",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 1,
        enumNameother: "克拉玛依市",
        children: [
          {
            fldEnumId: 14701,
            enumGroupGuid: "1",
            parentEnumValue: "6502",
            enumValue: "650200",
            enumName: "克拉玛依市本级",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 9,
            enumNameother: "克拉玛依市",
          },
          {
            fldEnumId: 14705,
            enumGroupGuid: "1",
            parentEnumValue: "6502",
            enumValue: "650202",
            enumName: "独山子区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 10,
            enumNameother: "独山子区",
          },
          {
            fldEnumId: 14709,
            enumGroupGuid: "1",
            parentEnumValue: "6502",
            enumValue: "650203",
            enumName: "克拉玛依区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 11,
            enumNameother: "克拉玛依区",
          },
          {
            fldEnumId: 14713,
            enumGroupGuid: "1",
            parentEnumValue: "6502",
            enumValue: "650204",
            enumName: "白碱滩区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 12,
            enumNameother: "白碱滩区",
          },
          {
            fldEnumId: 14717,
            enumGroupGuid: "1",
            parentEnumValue: "6502",
            enumValue: "650205",
            enumName: "乌尔禾区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 13,
            enumNameother: "乌尔禾区",
          },
        ],
      },
      {
        fldEnumId: 14721,
        enumGroupGuid: "1",
        parentEnumValue: "65",
        enumValue: "6521",
        enumName: "吐鲁番地区",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 2,
        enumNameother: "吐鲁番地区",
        children: [
          {
            fldEnumId: 16218,
            enumGroupGuid: "1",
            parentEnumValue: "6521",
            enumValue: "652100",
            enumName: "吐鲁番地区本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 14723,
            enumGroupGuid: "1",
            parentEnumValue: "6521",
            enumValue: "652101",
            enumName: "吐鲁番市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 14,
            enumNameother: "吐鲁番市",
          },
          {
            fldEnumId: 14727,
            enumGroupGuid: "1",
            parentEnumValue: "6521",
            enumValue: "652122",
            enumName: "鄯善县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 15,
            enumNameother: "鄯善县",
          },
          {
            fldEnumId: 14731,
            enumGroupGuid: "1",
            parentEnumValue: "6521",
            enumValue: "652123",
            enumName: "托克逊县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 16,
            enumNameother: "托克逊县",
          },
        ],
      },
      {
        fldEnumId: 14734,
        enumGroupGuid: "1",
        parentEnumValue: "65",
        enumValue: "6522",
        enumName: "哈密地区",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 3,
        enumNameother: "哈密地区",
        children: [
          {
            fldEnumId: 130357,
            enumGroupGuid: "1",
            parentEnumValue: "6522",
            enumValue: "650502",
            enumName: "伊州区",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 16220,
            enumGroupGuid: "1",
            parentEnumValue: "6522",
            enumValue: "652200",
            enumName: "哈密地区本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 14736,
            enumGroupGuid: "1",
            parentEnumValue: "6522",
            enumValue: "652201",
            enumName: "哈密市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 17,
            enumNameother: "哈密市",
          },
          {
            fldEnumId: 14740,
            enumGroupGuid: "1",
            parentEnumValue: "6522",
            enumValue: "652222",
            enumName: "巴里坤哈萨克自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 18,
            enumNameother: "巴里坤哈萨克自治县",
          },
          {
            fldEnumId: 14744,
            enumGroupGuid: "1",
            parentEnumValue: "6522",
            enumValue: "652223",
            enumName: "伊吾县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 19,
            enumNameother: "伊吾县",
          },
        ],
      },
      {
        fldEnumId: 14748,
        enumGroupGuid: "1",
        parentEnumValue: "65",
        enumValue: "6523",
        enumName: "昌吉回族自治州",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 4,
        enumNameother: "昌吉回族自治州",
        children: [
          {
            fldEnumId: 16222,
            enumGroupGuid: "1",
            parentEnumValue: "6523",
            enumValue: "652300",
            enumName: "昌吉回族自治州本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 14750,
            enumGroupGuid: "1",
            parentEnumValue: "6523",
            enumValue: "652301",
            enumName: "昌吉市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 20,
            enumNameother: "昌吉市",
          },
          {
            fldEnumId: 14754,
            enumGroupGuid: "1",
            parentEnumValue: "6523",
            enumValue: "652302",
            enumName: "阜康市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 21,
            enumNameother: "阜康市",
          },
          {
            fldEnumId: 14758,
            enumGroupGuid: "1",
            parentEnumValue: "6523",
            enumValue: "652323",
            enumName: "呼图壁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 22,
            enumNameother: "呼图壁县",
          },
          {
            fldEnumId: 14762,
            enumGroupGuid: "1",
            parentEnumValue: "6523",
            enumValue: "652324",
            enumName: "玛纳斯县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 23,
            enumNameother: "玛纳斯县",
          },
          {
            fldEnumId: 14766,
            enumGroupGuid: "1",
            parentEnumValue: "6523",
            enumValue: "652325",
            enumName: "奇台县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 24,
            enumNameother: "奇台县",
          },
          {
            fldEnumId: 14770,
            enumGroupGuid: "1",
            parentEnumValue: "6523",
            enumValue: "652327",
            enumName: "吉木萨尔县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 25,
            enumNameother: "吉木萨尔县",
          },
          {
            fldEnumId: 14775,
            enumGroupGuid: "1",
            parentEnumValue: "6523",
            enumValue: "652328",
            enumName: "木垒哈萨克自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 26,
            enumNameother: "木垒哈萨克自治县",
          },
        ],
      },
      {
        fldEnumId: 14778,
        enumGroupGuid: "1",
        parentEnumValue: "65",
        enumValue: "6527",
        enumName: "博尔塔拉蒙古自治州",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 5,
        enumNameother: "博尔塔拉蒙古自治州",
        children: [
          {
            fldEnumId: 16204,
            enumGroupGuid: "1",
            parentEnumValue: "6527",
            enumValue: "652700",
            enumName: "博尔塔拉蒙古自治州本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130329,
            enumGroupGuid: "1",
            parentEnumValue: "6527",
            enumValue: "652702",
            enumName: "阿拉山口市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 14781,
            enumGroupGuid: "1",
            parentEnumValue: "6527",
            enumValue: "652701",
            enumName: "博乐市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 27,
            enumNameother: "博乐市",
          },
          {
            fldEnumId: 14786,
            enumGroupGuid: "1",
            parentEnumValue: "6527",
            enumValue: "652722",
            enumName: "精河县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 28,
            enumNameother: "精河县",
          },
          {
            fldEnumId: 14790,
            enumGroupGuid: "1",
            parentEnumValue: "6527",
            enumValue: "652723",
            enumName: "温泉县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 29,
            enumNameother: "温泉县",
          },
        ],
      },
      {
        fldEnumId: 14794,
        enumGroupGuid: "1",
        parentEnumValue: "65",
        enumValue: "6528",
        enumName: "巴音郭楞蒙古自治州",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 6,
        enumNameother: "巴音郭楞蒙古自治州",
        children: [
          {
            fldEnumId: 16206,
            enumGroupGuid: "1",
            parentEnumValue: "6528",
            enumValue: "652800",
            enumName: "巴音郭楞蒙古自治州本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 14796,
            enumGroupGuid: "1",
            parentEnumValue: "6528",
            enumValue: "652801",
            enumName: "库尔勒市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 30,
            enumNameother: "库尔勒市",
          },
          {
            fldEnumId: 14799,
            enumGroupGuid: "1",
            parentEnumValue: "6528",
            enumValue: "652822",
            enumName: "轮台县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 31,
            enumNameother: "轮台县",
          },
          {
            fldEnumId: 14803,
            enumGroupGuid: "1",
            parentEnumValue: "6528",
            enumValue: "652823",
            enumName: "尉犁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 32,
            enumNameother: "尉犁县",
          },
          {
            fldEnumId: 14807,
            enumGroupGuid: "1",
            parentEnumValue: "6528",
            enumValue: "652824",
            enumName: "若羌县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 33,
            enumNameother: "若羌县",
          },
          {
            fldEnumId: 14811,
            enumGroupGuid: "1",
            parentEnumValue: "6528",
            enumValue: "652825",
            enumName: "且末县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 34,
            enumNameother: "且末县",
          },
          {
            fldEnumId: 14815,
            enumGroupGuid: "1",
            parentEnumValue: "6528",
            enumValue: "652826",
            enumName: "焉耆回族自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 35,
            enumNameother: "焉耆回族自治县",
          },
          {
            fldEnumId: 14819,
            enumGroupGuid: "1",
            parentEnumValue: "6528",
            enumValue: "652827",
            enumName: "和静县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 36,
            enumNameother: "和静县",
          },
          {
            fldEnumId: 14823,
            enumGroupGuid: "1",
            parentEnumValue: "6528",
            enumValue: "652828",
            enumName: "和硕县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 37,
            enumNameother: "和硕县",
          },
          {
            fldEnumId: 14827,
            enumGroupGuid: "1",
            parentEnumValue: "6528",
            enumValue: "652829",
            enumName: "博湖县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 38,
            enumNameother: "博湖县",
          },
        ],
      },
      {
        fldEnumId: 14831,
        enumGroupGuid: "1",
        parentEnumValue: "65",
        enumValue: "6529",
        enumName: "阿克苏地区",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 7,
        enumNameother: "阿克苏地区",
        children: [
          {
            fldEnumId: 16208,
            enumGroupGuid: "1",
            parentEnumValue: "6529",
            enumValue: "652900",
            enumName: "阿克苏地区本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 14833,
            enumGroupGuid: "1",
            parentEnumValue: "6529",
            enumValue: "652901",
            enumName: "阿克苏市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 39,
            enumNameother: "阿克苏市",
          },
          {
            fldEnumId: 14836,
            enumGroupGuid: "1",
            parentEnumValue: "6529",
            enumValue: "652922",
            enumName: "温宿县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 40,
            enumNameother: "温宿县",
          },
          {
            fldEnumId: 14840,
            enumGroupGuid: "1",
            parentEnumValue: "6529",
            enumValue: "652923",
            enumName: "库车县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 41,
            enumNameother: "库车县",
          },
          {
            fldEnumId: 14844,
            enumGroupGuid: "1",
            parentEnumValue: "6529",
            enumValue: "652924",
            enumName: "沙雅县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 42,
            enumNameother: "沙雅县",
          },
          {
            fldEnumId: 14849,
            enumGroupGuid: "1",
            parentEnumValue: "6529",
            enumValue: "652925",
            enumName: "新和县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 43,
            enumNameother: "新和县",
          },
          {
            fldEnumId: 14853,
            enumGroupGuid: "1",
            parentEnumValue: "6529",
            enumValue: "652926",
            enumName: "拜城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 44,
            enumNameother: "拜城县",
          },
          {
            fldEnumId: 14857,
            enumGroupGuid: "1",
            parentEnumValue: "6529",
            enumValue: "652927",
            enumName: "乌什县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 45,
            enumNameother: "乌什县",
          },
          {
            fldEnumId: 14862,
            enumGroupGuid: "1",
            parentEnumValue: "6529",
            enumValue: "652928",
            enumName: "阿瓦提县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 46,
            enumNameother: "阿瓦提县",
          },
          {
            fldEnumId: 14866,
            enumGroupGuid: "1",
            parentEnumValue: "6529",
            enumValue: "652929",
            enumName: "柯坪县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 47,
            enumNameother: "柯坪县",
          },
        ],
      },
      {
        fldEnumId: 14870,
        enumGroupGuid: "1",
        parentEnumValue: "65",
        enumValue: "6530",
        enumName: "克孜勒苏柯尔克孜自治州",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 8,
        enumNameother: "克孜勒苏柯尔克孜自治州",
        children: [
          {
            fldEnumId: 16210,
            enumGroupGuid: "1",
            parentEnumValue: "6530",
            enumValue: "653000",
            enumName: "克孜勒苏柯尔克孜自治州本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 14872,
            enumGroupGuid: "1",
            parentEnumValue: "6530",
            enumValue: "653001",
            enumName: "阿图什市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 48,
            enumNameother: "阿图什市",
          },
          {
            fldEnumId: 14876,
            enumGroupGuid: "1",
            parentEnumValue: "6530",
            enumValue: "653022",
            enumName: "阿克陶县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 49,
            enumNameother: "阿克陶县",
          },
          {
            fldEnumId: 14880,
            enumGroupGuid: "1",
            parentEnumValue: "6530",
            enumValue: "653023",
            enumName: "阿合奇县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 50,
            enumNameother: "阿合奇县",
          },
          {
            fldEnumId: 14884,
            enumGroupGuid: "1",
            parentEnumValue: "6530",
            enumValue: "653024",
            enumName: "乌恰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 51,
            enumNameother: "乌恰县",
          },
        ],
      },
      {
        fldEnumId: 14888,
        enumGroupGuid: "1",
        parentEnumValue: "65",
        enumValue: "6531",
        enumName: "喀什地区",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 9,
        enumNameother: "喀什地区",
        children: [
          {
            fldEnumId: 16212,
            enumGroupGuid: "1",
            parentEnumValue: "6531",
            enumValue: "653100",
            enumName: "喀什地区本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 14891,
            enumGroupGuid: "1",
            parentEnumValue: "6531",
            enumValue: "653101",
            enumName: "喀什市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 52,
            enumNameother: "喀什市",
          },
          {
            fldEnumId: 14895,
            enumGroupGuid: "1",
            parentEnumValue: "6531",
            enumValue: "653121",
            enumName: "疏附县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 53,
            enumNameother: "疏附县",
          },
          {
            fldEnumId: 14899,
            enumGroupGuid: "1",
            parentEnumValue: "6531",
            enumValue: "653122",
            enumName: "疏勒县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 54,
            enumNameother: "疏勒县",
          },
          {
            fldEnumId: 14903,
            enumGroupGuid: "1",
            parentEnumValue: "6531",
            enumValue: "653123",
            enumName: "英吉沙县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 55,
            enumNameother: "英吉沙县",
          },
          {
            fldEnumId: 14906,
            enumGroupGuid: "1",
            parentEnumValue: "6531",
            enumValue: "653124",
            enumName: "泽普县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 56,
            enumNameother: "泽普县",
          },
          {
            fldEnumId: 14910,
            enumGroupGuid: "1",
            parentEnumValue: "6531",
            enumValue: "653125",
            enumName: "莎车县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 57,
            enumNameother: "莎车县",
          },
          {
            fldEnumId: 14915,
            enumGroupGuid: "1",
            parentEnumValue: "6531",
            enumValue: "653126",
            enumName: "叶城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 58,
            enumNameother: "叶城县",
          },
          {
            fldEnumId: 14919,
            enumGroupGuid: "1",
            parentEnumValue: "6531",
            enumValue: "653127",
            enumName: "麦盖提县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 59,
            enumNameother: "麦盖提县",
          },
          {
            fldEnumId: 14923,
            enumGroupGuid: "1",
            parentEnumValue: "6531",
            enumValue: "653128",
            enumName: "岳普湖县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 60,
            enumNameother: "岳普湖县",
          },
          {
            fldEnumId: 14927,
            enumGroupGuid: "1",
            parentEnumValue: "6531",
            enumValue: "653129",
            enumName: "伽师县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 61,
            enumNameother: "伽师县",
          },
          {
            fldEnumId: 14931,
            enumGroupGuid: "1",
            parentEnumValue: "6531",
            enumValue: "653130",
            enumName: "巴楚县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 62,
            enumNameother: "巴楚县",
          },
          {
            fldEnumId: 14936,
            enumGroupGuid: "1",
            parentEnumValue: "6531",
            enumValue: "653131",
            enumName: "塔什库尔干塔吉克自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 63,
            enumNameother: "塔什库尔干塔吉克自治县",
          },
        ],
      },
      {
        fldEnumId: 14940,
        enumGroupGuid: "1",
        parentEnumValue: "65",
        enumValue: "6532",
        enumName: "和田地区",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 10,
        enumNameother: "和田地区",
        children: [
          {
            fldEnumId: 16214,
            enumGroupGuid: "1",
            parentEnumValue: "6532",
            enumValue: "653200",
            enumName: "和田地区本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 14941,
            enumGroupGuid: "1",
            parentEnumValue: "6532",
            enumValue: "653201",
            enumName: "和田市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 64,
            enumNameother: "和田市",
          },
          {
            fldEnumId: 14945,
            enumGroupGuid: "1",
            parentEnumValue: "6532",
            enumValue: "653221",
            enumName: "和田县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 65,
            enumNameother: "和田县",
          },
          {
            fldEnumId: 14950,
            enumGroupGuid: "1",
            parentEnumValue: "6532",
            enumValue: "653222",
            enumName: "墨玉县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 66,
            enumNameother: "墨玉县",
          },
          {
            fldEnumId: 14954,
            enumGroupGuid: "1",
            parentEnumValue: "6532",
            enumValue: "653223",
            enumName: "皮山县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 67,
            enumNameother: "皮山县",
          },
          {
            fldEnumId: 14957,
            enumGroupGuid: "1",
            parentEnumValue: "6532",
            enumValue: "653224",
            enumName: "洛浦县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 68,
            enumNameother: "洛浦县",
          },
          {
            fldEnumId: 14962,
            enumGroupGuid: "1",
            parentEnumValue: "6532",
            enumValue: "653225",
            enumName: "策勒县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 69,
            enumNameother: "策勒县",
          },
          {
            fldEnumId: 14966,
            enumGroupGuid: "1",
            parentEnumValue: "6532",
            enumValue: "653226",
            enumName: "于田县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 70,
            enumNameother: "于田县",
          },
          {
            fldEnumId: 14969,
            enumGroupGuid: "1",
            parentEnumValue: "6532",
            enumValue: "653227",
            enumName: "民丰县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 71,
            enumNameother: "民丰县",
          },
        ],
      },
      {
        fldEnumId: 14974,
        enumGroupGuid: "1",
        parentEnumValue: "65",
        enumValue: "6540",
        enumName: "伊犁哈萨克自治州",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 11,
        enumNameother: "伊犁哈萨克自治州",
        children: [
          {
            fldEnumId: 16280,
            enumGroupGuid: "1",
            parentEnumValue: "6540",
            enumValue: "654000",
            enumName: "伊犁哈萨克自治州本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 130343,
            enumGroupGuid: "1",
            parentEnumValue: "6540",
            enumValue: "654004",
            enumName: "霍尔果斯市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 14976,
            enumGroupGuid: "1",
            parentEnumValue: "6540",
            enumValue: "654002",
            enumName: "伊宁市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 72,
            enumNameother: "伊宁市",
          },
          {
            fldEnumId: 14979,
            enumGroupGuid: "1",
            parentEnumValue: "6540",
            enumValue: "654003",
            enumName: "奎屯市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 73,
            enumNameother: "奎屯市",
          },
          {
            fldEnumId: 14983,
            enumGroupGuid: "1",
            parentEnumValue: "6540",
            enumValue: "654021",
            enumName: "伊宁县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 74,
            enumNameother: "伊宁县",
          },
          {
            fldEnumId: 14988,
            enumGroupGuid: "1",
            parentEnumValue: "6540",
            enumValue: "654022",
            enumName: "察布查尔锡伯自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 75,
            enumNameother: "察布查尔锡伯自治县",
          },
          {
            fldEnumId: 14993,
            enumGroupGuid: "1",
            parentEnumValue: "6540",
            enumValue: "654023",
            enumName: "霍城县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 76,
            enumNameother: "霍城县",
          },
          {
            fldEnumId: 14997,
            enumGroupGuid: "1",
            parentEnumValue: "6540",
            enumValue: "654024",
            enumName: "巩留县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 77,
            enumNameother: "巩留县",
          },
          {
            fldEnumId: 15000,
            enumGroupGuid: "1",
            parentEnumValue: "6540",
            enumValue: "654025",
            enumName: "新源县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 78,
            enumNameother: "新源县",
          },
          {
            fldEnumId: 15004,
            enumGroupGuid: "1",
            parentEnumValue: "6540",
            enumValue: "654026",
            enumName: "昭苏县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 79,
            enumNameother: "昭苏县",
          },
          {
            fldEnumId: 15008,
            enumGroupGuid: "1",
            parentEnumValue: "6540",
            enumValue: "654027",
            enumName: "特克斯县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 80,
            enumNameother: "特克斯县",
          },
          {
            fldEnumId: 15013,
            enumGroupGuid: "1",
            parentEnumValue: "6540",
            enumValue: "654028",
            enumName: "尼勒克县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 81,
            enumNameother: "尼勒克县",
          },
        ],
      },
      {
        fldEnumId: 15017,
        enumGroupGuid: "1",
        parentEnumValue: "65",
        enumValue: "6542",
        enumName: "塔城地区",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 12,
        enumNameother: "塔城地区",
        children: [
          {
            fldEnumId: 16282,
            enumGroupGuid: "1",
            parentEnumValue: "6542",
            enumValue: "654200",
            enumName: "塔城地区本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 15019,
            enumGroupGuid: "1",
            parentEnumValue: "6542",
            enumValue: "654201",
            enumName: "塔城市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 82,
            enumNameother: "塔城市",
          },
          {
            fldEnumId: 15023,
            enumGroupGuid: "1",
            parentEnumValue: "6542",
            enumValue: "654202",
            enumName: "乌苏市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 83,
            enumNameother: "乌苏市",
          },
          {
            fldEnumId: 15027,
            enumGroupGuid: "1",
            parentEnumValue: "6542",
            enumValue: "654221",
            enumName: "额敏县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 84,
            enumNameother: "额敏县",
          },
          {
            fldEnumId: 15030,
            enumGroupGuid: "1",
            parentEnumValue: "6542",
            enumValue: "654223",
            enumName: "沙湾县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 85,
            enumNameother: "沙湾县",
          },
          {
            fldEnumId: 15034,
            enumGroupGuid: "1",
            parentEnumValue: "6542",
            enumValue: "654224",
            enumName: "托里县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 86,
            enumNameother: "托里县",
          },
          {
            fldEnumId: 15039,
            enumGroupGuid: "1",
            parentEnumValue: "6542",
            enumValue: "654225",
            enumName: "裕民县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 87,
            enumNameother: "裕民县",
          },
          {
            fldEnumId: 15043,
            enumGroupGuid: "1",
            parentEnumValue: "6542",
            enumValue: "654226",
            enumName: "和布克赛尔蒙古自治县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 88,
            enumNameother: "和布克赛尔蒙古自治县",
          },
        ],
      },
      {
        fldEnumId: 15047,
        enumGroupGuid: "1",
        parentEnumValue: "65",
        enumValue: "6543",
        enumName: "阿勒泰地区",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 13,
        enumNameother: "阿勒泰地区",
        children: [
          {
            fldEnumId: 16216,
            enumGroupGuid: "1",
            parentEnumValue: "6543",
            enumValue: "654300",
            enumName: "阿勒泰地区本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 15048,
            enumGroupGuid: "1",
            parentEnumValue: "6543",
            enumValue: "654301",
            enumName: "阿勒泰市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 89,
            enumNameother: "阿勒泰市",
          },
          {
            fldEnumId: 15052,
            enumGroupGuid: "1",
            parentEnumValue: "6543",
            enumValue: "654321",
            enumName: "布尔津县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 90,
            enumNameother: "布尔津县",
          },
          {
            fldEnumId: 15056,
            enumGroupGuid: "1",
            parentEnumValue: "6543",
            enumValue: "654322",
            enumName: "富蕴县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 91,
            enumNameother: "富蕴县",
          },
          {
            fldEnumId: 15061,
            enumGroupGuid: "1",
            parentEnumValue: "6543",
            enumValue: "654323",
            enumName: "福海县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 92,
            enumNameother: "福海县",
          },
          {
            fldEnumId: 15065,
            enumGroupGuid: "1",
            parentEnumValue: "6543",
            enumValue: "654324",
            enumName: "哈巴河县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 93,
            enumNameother: "哈巴河县",
          },
          {
            fldEnumId: 15069,
            enumGroupGuid: "1",
            parentEnumValue: "6543",
            enumValue: "654325",
            enumName: "青河县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 94,
            enumNameother: "青河县",
          },
          {
            fldEnumId: 15073,
            enumGroupGuid: "1",
            parentEnumValue: "6543",
            enumValue: "654326",
            enumName: "吉木乃县",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 95,
            enumNameother: "吉木乃县",
          },
        ],
      },
      {
        fldEnumId: 15076,
        enumGroupGuid: "1",
        parentEnumValue: "65",
        enumValue: "6590",
        enumName: "自治区直辖县级行政单位",
        enumLevel: 0,
        enumStatus: 1,
        useFrequency: 0,
        enumOrder: 14,
        enumNameother: "自治区直辖县级行政单位",
        children: [
          {
            fldEnumId: 16230,
            enumGroupGuid: "1",
            parentEnumValue: "6590",
            enumValue: "659000",
            enumName: "自治区直辖县级行政单位本级",
            enumLevel: 3,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 0,
          },
          {
            fldEnumId: 15078,
            enumGroupGuid: "1",
            parentEnumValue: "6590",
            enumValue: "659001",
            enumName: "石河子市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 96,
            enumNameother: "石河子市",
          },
          {
            fldEnumId: 15083,
            enumGroupGuid: "1",
            parentEnumValue: "6590",
            enumValue: "659002",
            enumName: "阿拉尔市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 97,
            enumNameother: "阿拉尔市",
          },
          {
            fldEnumId: 15087,
            enumGroupGuid: "1",
            parentEnumValue: "6590",
            enumValue: "659003",
            enumName: "图木舒克市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 98,
            enumNameother: "图木舒克市",
          },
          {
            fldEnumId: 15091,
            enumGroupGuid: "1",
            parentEnumValue: "6590",
            enumValue: "659004",
            enumName: "五家渠市",
            enumLevel: 0,
            enumStatus: 1,
            useFrequency: 0,
            enumOrder: 99,
            enumNameother: "五家渠市",
          },
        ],
      },
    ],
  },
];
