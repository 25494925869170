import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { districts } from "./districts";
import { uses } from "./uses";
import { methods } from "./methods";

import "./App.css";

import { FormEventHandler } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Layout } from "./layout";
import {
  landchinaWsUrl,
  landchinaMsgCrawling,
  landchinaMsgFindEstimatedTime,
  downloadHostUrl,
} from "./consts";
import { io } from "socket.io-client";
import { CircularProgress } from "@mui/material";
import { LandchinaParamInput, toLandchinaParamDTO } from "./utils";
import { format } from "date-fns";

interface AppState {
  variables: Partial<LandchinaParamInput>;
  stage:
    | "Standby"
    | "WaitingEstimate"
    | "FinishEstimate"
    | "WaitingCrawling"
    | "FinishCrawling"
    | "Error";
  message?: string;
  reportUrl?: string;
  dialog: {
    isDialogOpen: boolean;
    dialogTitle?: string;
    dialogContent?: string | React.ReactNode;
  };
}

type AppReducerAction =
  | {
      type: AppState["stage"];
    }
  | {
      type: "setVariables";
      payload: AppState["variables"];
    }
  | {
      type: "setDialog";
      payload: AppState["dialog"];
    };

const appInitState: AppState = {
  variables: {},
  stage: "Standby",
  dialog: {
    isDialogOpen: false,
  },
};

const appReducer: React.Reducer<AppState, AppReducerAction> = (
  state,
  action
) => {
  switch (action.type) {
    case "setVariables":
      return {
        ...state,
        variables: {
          ...state.variables,
          ...action.payload,
        },
      };
    case "setDialog":
      return { ...state, dialog: action.payload };
    case "Standby":
    case "WaitingEstimate":
    case "WaitingCrawling":
    case "FinishEstimate":
    case "FinishCrawling":
    case "Error":
      return { ...state, stage: action.type };
    default:
      return state;
  }
};

export default function LandchinaApplication() {
  const socket = React.useMemo(() => io(landchinaWsUrl), []);
  const [appState, dispatch] = React.useReducer(appReducer, appInitState);
  const { variables, dialog } = appState;

  const handleChange: React.ChangeEventHandler<
    HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement
  > = (e) => {
    dispatch({
      type: "setVariables",
      payload: {
        [e.target.name]: e.target.value,
      },
    });
  };
  socket.on("exception", (error) => {
    dispatch({
      type: "Error",
    });
    dispatch({
      type: "setDialog",
      payload: {
        isDialogOpen: true,
        dialogTitle: "Error",
        dialogContent: error.message,
      },
    });
  });
  const estimateSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    dispatch({ type: "WaitingEstimate" });
    dispatch({
      type: "setDialog",
      payload: {
        isDialogOpen: true,
      },
    });
    const dto = toLandchinaParamDTO(variables);
    socket.emit(landchinaMsgFindEstimatedTime, dto, (estimatedTime: number) => {
      dispatch({
        type: "FinishEstimate",
      });
      dispatch({
        type: "setDialog",
        payload: {
          isDialogOpen: true,
          dialogTitle: "Estimated Time",
          dialogContent: `estimate it will need ${estimatedTime} mins`,
        },
      });
    });
    return false;
    // if (open === false) {
    //   //crawl data
    //   socket.emit("crawling", data, (resData: string) => {
    //     console.log(socket.id);
    //     setDownloadRequest(resData);
    //     setLoadingOpen(false);
    //     setFinishOpen(true);
    //   });

    //   socket.on("exception", (err) => {
    //     console.log(err.message);
    //     if (err.message !== "Cancelled request") {
    //       // when error is not from cancelling by user
    //       setLoadingOpen(false);
    //       setCrawlingError(true);
    //     }
    //   });
    // } else {
    //   //find estimated time
    // }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Layout>
        <form onSubmit={estimateSubmit}>
          <div
            style={{
              marginTop: "60px",
            }}
          >
            <div>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel>行政区1</InputLabel>
                <Select
                  name="province"
                  onChange={(e: any) => handleChange(e)}
                  label="行政区1"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {districts.map((d, i) => (
                    <MenuItem key={i} value={d.enumValue}>
                      {d.enumName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel>行政区2</InputLabel>
                <Select
                  name="district"
                  onChange={(e: any) => handleChange(e)}
                  label="行政区2"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {districts
                    .find((d) => d.enumValue === variables.province)
                    ?.children.map((d) => (
                      <MenuItem value={d.enumValue}>{d.enumName}</MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel>行政区3</InputLabel>
                <Select
                  name="area"
                  onChange={(e: any) => handleChange(e)}
                  label="area"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {districts
                    .find((d) => d.enumValue === variables.province)
                    ?.children.find((c) => c.enumValue === variables.district)
                    ?.children.map((a) => (
                      <MenuItem value={a.enumValue}>{a.enumName}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel>土地用途1</InputLabel>
                <Select
                  name="landusageFirstLevel"
                  onChange={(e: any) => handleChange(e)}
                  label="土地用途"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {uses.map((u, i) => (
                    <MenuItem key={i} value={u.id}>
                      {u.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel>土地用途2</InputLabel>
                <Select
                  name="landusageSecondLevel"
                  onChange={(e: any) => handleChange(e)}
                  label="土地用途2"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {uses
                    .find((u) => u.id === variables.landusageFirstLevel)
                    ?.children.map((c) => (
                      <MenuItem value={c.id}>{c.label}</MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel>土地用途3</InputLabel>
                <Select
                  name="landusageThridLevel"
                  onChange={(e: any) => handleChange(e)}
                  label="土地用途3"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {uses
                    .find((u) => u.id === variables.landusageFirstLevel)
                    ?.children.find(
                      (sc) => sc.id === variables.landusageSecondLevel
                    )
                    ?.children?.map((tc) => (
                      <MenuItem value={tc.id}>{tc.label}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel>供应方式</InputLabel>
                <Select onChange={(e: any) => handleChange(e)} label="供应方式">
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {methods.map((m, i) => (
                    <MenuItem key={i} value={m.value}>
                      {m.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "10px",
              }}
            >
              <FormControl
                style={{
                  marginRight: "10px",
                }}
              >
                <Stack spacing={3}>
                  <DatePicker
                    label="startDate"
                    inputFormat="yyyy-MM-dd"
                    value={variables.startDate || null}
                    onChange={(data, keyboardInputValue) => {
                      if (data) {
                        format(new Date(data), "yyyy-MM-dd");
                        dispatch({
                          type: "setVariables",
                          payload: {
                            startDate: data,
                          },
                        });
                      }
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </FormControl>

              <FormControl>
                <Stack spacing={3}>
                  <DatePicker
                    label="endDate"
                    inputFormat="yyyy-MM-dd"
                    value={variables.endDate || null}
                    onChange={(data, keyboardInputValue) => {
                      if (data) {
                        format(new Date(data), "yyyy-MM-dd");
                        dispatch({
                          type: "setVariables",
                          payload: {
                            endDate: data,
                          },
                        });
                      }
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </FormControl>
            </div>
            <div>
              <TextField
                style={{
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
                label="电子监管号"
                value={variables.code}
                onChange={handleChange}
                variant="outlined"
              />
              <TextField
                label="土地坐落"
                value={variables.location}
                onChange={handleChange}
                variant="outlined"
              />
            </div>
            <div>
              <Button
                variant="outlined"
                type="submit"
                disabled={appState.stage !== "Standby"}
              >
                Submit
              </Button>
              <Dialog
                open={dialog.isDialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                disablePortal
                disableEscapeKeyDown
              >
                {appState.stage === "WaitingEstimate" ||
                appState.stage === "WaitingCrawling" ? (
                  <DialogContent>
                    <CircularProgress />
                  </DialogContent>
                ) : (
                  <React.Fragment>
                    <DialogTitle>{dialog.dialogTitle}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {dialog.dialogContent}
                      </DialogContentText>
                    </DialogContent>
                  </React.Fragment>
                )}
                <DialogActions>
                  <Button
                    onClick={() => {
                      socket.emit("cancel");
                      dispatch({
                        type: "Standby",
                      });
                      dispatch({
                        type: "setDialog",
                        payload: {
                          isDialogOpen: false,
                        },
                      });
                    }}
                  >
                    Cancel
                  </Button>
                  {appState.stage === "FinishEstimate" && (
                    <Button
                      onClick={() => {
                        dispatch({
                          type: "WaitingCrawling",
                        });
                        const dto = toLandchinaParamDTO(variables);
                        socket.emit(
                          landchinaMsgCrawling,
                          dto,
                          (reportUrl: string) => {
                            dispatch({ type: "FinishCrawling" });
                            dispatch({
                              type: "setDialog",
                              payload: {
                                isDialogOpen: true,
                                dialogTitle: "Report",
                                dialogContent: (
                                  <a href={`${downloadHostUrl}/${reportUrl}`}>
                                    download
                                  </a>
                                ),
                              },
                            });
                          }
                        );
                      }}
                    >
                      Start crawling
                    </Button>
                  )}
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </form>
      </Layout>
    </LocalizationProvider>
  );
}
